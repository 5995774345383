<div class="d-flex flex-column">
  <div>
    <div class="section-title">Build Object</div>
    <div class="d-flex flex-column align-items-end">
      <a class="d-flex justify-content-end section-item"
        [class]="{ 'current-page': currentPage.includes('indicator-type'),
                   'section-item-disabled': disableMenuItem('indicator-type') }"
        (click)="onSelectMenu('indicator-type-1')">Object Type</a>
		
      <a *ngIf="!isGuidedReport" class="d-flex justify-content-end section-item"
        [class]="{ 'current-page': currentPage.includes('additional-info'),
                   'section-item-disabled': disableMenuItem('additional-info') }"
        (click)="onSelectMenu('additional-info-1')">Object Info</a>
      
      <a *ngIf="isGuidedReport" class="d-flex justify-content-end section-item" (click)="grSubMenuHandler()">
        <img src="./assets/arrow-chevron-down.svg" alt=""
                    width="20px" height="20px" class="d-inline-block align-top fav-color me-1"
                    [class]="{'gr_submenu_arrow_expanded': expendGrSubmenu, 'gr_submenu_arrow_collapsed': !expendGrSubmenu}">
        <span>Object Info</span>
      </a>

      <div *ngIf="isGuidedReport && expendGrSubmenu" class="w-100 section-submenu-item">
        <a class="d-flex ps-3 section-item"
          [class]="{'current-page': currentPage.includes('who-individual'), 'disabled': disableGrSubmenuItem('who-individual')}"
          (click)="onSelectMenu('who-individual')">
          <span>Who</span>
          <span *ngIf="!disableGrSubmenuItem('who-individual') && this.guidedService.cartLengths['who-individual'] > 0">
            {{this.guidedService.cartLengths["who-individual"]}}</span>
        </a>
      </div>

      <div *ngIf="isGuidedReport && expendGrSubmenu" class="w-100 section-submenu-item">
        <a class="d-flex ps-3 section-item"
          [class]="{'current-page': currentPage === 'how-event-detection', 'disabled': disableGrSubmenuItem('how-event-detection')}"
          (click)="!disableGrSubmenuItem('how-event-detection') && onSelectMenu('how-event-detection')">
          <span>How - Detection</span>
          <span *ngIf="!disableGrSubmenuItem('how-event-detection') && this.guidedService.cartLengths['how-event-detection'] > 0">
            {{this.guidedService.cartLengths["how-event-detection"]}}</span>
        </a>
      </div>

      <div *ngIf="isGuidedReport && expendGrSubmenu" class="w-100 section-submenu-item">
        <a class="d-flex ps-3 section-item"
          [class]="{ 'current-page': currentPage.includes('how-observable'), 'disabled': disableGrSubmenuItem('how-observable')}"
          (click)="!disableGrSubmenuItem('how-observable') && onSelectMenu('how-observable')">
          <span>How - Observable</span>
          <span *ngIf="!disableGrSubmenuItem('how-observable') && this.guidedService.cartLengths['how-observable'] > 0">
            {{this.guidedService.cartLengths["how-observable"]}}</span>
        </a>
      </div>

      <div *ngIf="isGuidedReport && expendGrSubmenu" class="w-100 section-submenu-item">
        <a class="d-flex ps-3 section-item"
          [class]="{'current-page': currentPage.includes('how-pattern-builder'), 'disabled': disableGrSubmenuItem('how-pattern-builder')}"
          (click)="!disableGrSubmenuItem('how-pattern-builder') && onSelectMenu('how-pattern-builder')">
          <span>How - Query</span>
          <span *ngIf="!disableGrSubmenuItem('how-pattern-builder') && this.guidedService.cartLengths['how-pattern-builder'] > 0">
            {{this.guidedService.cartLengths["how-pattern-builder"]}}</span>
        </a>
      </div>

      <div *ngIf="isGuidedReport && expendGrSubmenu" class="w-100 section-submenu-item">
        <a class="d-flex ps-3 section-item"
          [class]="{ 'current-page': currentPage.includes('how-ttp'), 'disabled': disableGrSubmenuItem('how-ttp')}"
          (click)="!disableGrSubmenuItem('how-ttp') && onSelectMenu('how-ttp')">
          <span>How - TTP</span>
          <span *ngIf="!disableGrSubmenuItem('how-ttp') && this.guidedService.cartLengths['how-ttp'] > 0">{{this.guidedService.cartLengths["how-ttp"]}}</span>
        </a>
      </div>

      <div *ngIf="isGuidedReport && expendGrSubmenu" class="w-100 section-submenu-item">
        <a class="d-flex ps-3 section-item"
          [class]="{ 'current-page': currentPage.includes('how-cwe'), 'disabled': disableGrSubmenuItem('how-cwe')}"
          (click)="!disableGrSubmenuItem('how-cwe') && onSelectMenu('how-cwe')">
          <span>How - CWE</span>
          <span *ngIf="!disableGrSubmenuItem('how-cwe') && this.guidedService.cartLengths['how-cwe'] > 0">{{this.guidedService.cartLengths["how-cwe"]}}</span>
        </a>
      </div>

      <!-- <div *ngIf="isGuidedReport && expendGrSubmenu" class="w-100 section-submenu-item">
        <a class="d-flex justify-content-start ps-3 section-item"
          [class]="{'current-page': currentPage === 'how-event', 'disabled': disableGrSubmenuItem('how-event')}"
          (click)="!disableGrSubmenuItem('how-event') && onSelectMenu('how-event')">
          <span>How - Event Details</span>
        </a>
      </div>

      <div *ngIf="isGuidedReport && expendGrSubmenu" class="w-100 section-submenu-item">
        <a class="d-flex justify-content-start ps-3 section-item"
          [class]="{'current-page': currentPage.includes('when-event'), 'disabled': disableGrSubmenuItem('when-event')}"
          (click)="!disableGrSubmenuItem('when-event') && onSelectMenu('when-event')">
          <span>When - Event Times</span>
        </a>
      </div> -->

      <div *ngIf="isGuidedReport && expendGrSubmenu" class="w-100 section-submenu-item">
        <a class="d-flex ps-3 section-item"
          [class]="{'current-page': currentPage.includes('what-impact'), 'disabled': disableGrSubmenuItem('what-impact')}"
          (click)="!disableGrSubmenuItem('what-impact') && onSelectMenu('what-impact')">
          <span>What - Impact</span>
          <span *ngIf="!disableGrSubmenuItem('what-impact') && this.guidedService.cartLengths['what-impact'] > 0">
            {{this.guidedService.cartLengths["what-impact"]}}</span>
        </a>
      </div>

      <div *ngIf="isGuidedReport && expendGrSubmenu" class="w-100 section-submenu-item">
        <a class="d-flex ps-3 section-item"
          [class]="{'current-page': currentPage.includes('where-location'), 'disabled': disableGrSubmenuItem('where-location')}"
          (click)="!disableGrSubmenuItem('where-location') && onSelectMenu('where-location')">
          <span>Where - Location</span>
          <span *ngIf="!disableGrSubmenuItem('where-location') && this.guidedService.cartLengths['where-location'] > 0">
            {{this.guidedService.cartLengths["where-location"]}}</span>
        </a>
      </div>

      <!-- <div *ngIf="isGuidedReport && expendGrSubmenu" class="w-100 section-submenu-item">
        <a class="d-flex justify-content-start ps-3 section-item"
          [class]="{'current-page': currentPage.includes('why-common-behaviors'), 'disabled': disableGrSubmenuItem('why-common-behaviors')}"
          (click)="!disableGrSubmenuItem('why-common-behaviors') && onSelectMenu('why-common-behaviors')">
          <span>Why - Behaviors</span>
        </a>
      </div> -->

      <!-- <div *ngIf="isGuidedReport && expendGrSubmenu" class="w-100 section-submenu-item">
        <a class="d-flex justify-content-start ps-3 section-item"
          [class]="{'current-page': currentPage.includes('why-attacker'), 'disabled': disableGrSubmenuItem('why-attacker')}"
          (click)="!disableGrSubmenuItem('why-attacker') && onSelectMenu('why-attacker')">
          <span>Why - Attacker</span>
        </a>
      </div> -->

      <div *ngIf="isGuidedReport && expendGrSubmenu" class="w-100 section-submenu-item">
        <a class="d-flex justify-content-start ps-3 section-item"
          [class]="{'current-page': currentPage.includes('report-assessment'), 'disabled': disableGrSubmenuItem('report-assessment')}"
          (click)="!disableGrSubmenuItem('report-assessment') && onSelectMenu('report-assessment')">
          <span>Report- Assessment</span>
        </a>
      </div>
      

      <a class="d-flex justify-content-end section-item"
        [class]="{ 'current-page': currentPage.includes('relationship'),
                   'section-item-disabled': disableMenuItem('relationship') }"
        (click)="onSelectMenu('relationship-1')">Relationship</a>

      <a class="d-flex justify-content-end section-item"
        [class]="{ 'current-page': currentPage.includes('object-addition'),
                   'section-item-disabled': disableMenuItem('object-addition') }"
        (click)="onSelectMenu('object-addition')">Object Addition</a>

      <a class="d-flex justify-content-end section-item"
        [class]="{ 'current-page': currentPage === 'build-review',
		           'section-item-disabled': disableMenuItem('build-review') }"
        (click)="onSelectMenu('build-review')">Bundle Overview</a>
    </div>
  </div>
  <div>
    <div class="section-title">Publish Object</div>
    <div class="d-flex flex-column align-items-end">
      <a class="d-flex justify-content-end section-item"
        [class]="{ 'current-page': currentPage === 'tlp-type',
            'section-item-disabled': disableMenuItem('tlp-type') }"
        (click)="onSelectMenu('tlp-type')">Objects Without TLP</a>
      <a class="d-flex justify-content-end section-item"
        [class]="{ 'current-page': currentPage.includes('publish-type'),
				   'section-item-disabled': disableMenuItem('publish-type') }"
        (click)="onSelectMenu('publish-type-1')">TAXII Server</a>
      <a class="d-flex justify-content-end section-item"
        [class]="{ 'current-page': currentPage === 'publish-review',
				   'section-item-disabled': disableMenuItem('publish-review') }"
        (click)="onSelectMenu('publish-review')">Review</a>
    </div>
  </div>
  <div>
    <div class="section-title pt-1 pb-1"
      [class]="{ 'current-page': currentPage === 'publish-success',
	  			 'section-item-disabled': disableMenuItem('publish-success') }">
      Publish Status
    </div>
  </div>
</div>