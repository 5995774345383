import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { StixService } from './stix-service.service';
import { AnnouncementService } from "./announcement-service/announcement-service.service";
import { faAngleDown, faAngleLeft, faCog, faBan, faPaperPlane, faDownload, faEdit, faSearch, faUser, faUserTie, faBell } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import changelog from './changelog.json';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import release_notes from 'src/assets/IMX_release_Notes.json';
import { FluentdService } from './fluentd/fluentd.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigComponent } from './config/config.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('toastMessage') spanElement: ElementRef
  @ViewChild("config", { static: true }) configComponent: ConfigComponent;

  atHome: boolean;
  changelog = changelog;
  changelogkeys = Object.keys(changelog).sort();
  currentPage = '';
  currentUrl = '';
  editorType: string = null;
  environment = environment;

  faAngleDown = faAngleDown;
  faAngleLeft = faAngleLeft;
  faBan = faBan;
  faCog = faCog;
  faDownload = faDownload;
  faPaperPlane = faPaperPlane;
  faEdit = faEdit;
  faSearch = faSearch;
  faUser = faUser;
  faUserTie = faUserTie;
  faBell = faBell;

  isDropdownOpen = false;
  isJsonBodyExpanded = false;
  isGuided: boolean;
  siteTitle: String = environment.siteTitle;
  userRoles = [];
  release_notes = [];
  note_versions = [];
  current_version = {
    version: '',
    releaseDate: '',
    description: '',
    newFeatures: [],
    video: '',
    video_url: ''
  };
  assetsPath = 'assets';
  videoPath = '';
  isInitialGuided = false;
  support = {
    loading: false
  };

  /* Support Request Vars */
  // Request Object - stores all attrs for the request
  supportRequest = {
    cachedObjects: null,
    currentBundle: null,
    customObjects: null,
    description: null,
    id: null,
    issueType: null,
    favorites: null,
    labels: null,
    savedBundles: null,
  };
  supportLoading = false;
  supportPage;
  supportLabel = ''; // Label for header in modal
  supportType = ''; // Type of support request this is

  fullScreenMode: boolean = false;

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }

  get isAdmin(): boolean {
    if (environment.keycloak === false)
      return true;

    if (this.userRoles.length === 0)
      return false;

    if (this.userRoles.includes(environment.taxiiServer.adminRoleName))
      return true;

    return false;
  }

  constructor(
    public announcementService: AnnouncementService,
    private authService: AuthService,
    private httpClient: HttpClient,
    public stixService: StixService,
    private router: Router, public modalService: NgbModal,
    private keycloak: KeycloakService,
    private fluentd: FluentdService) {
    this.editorType = 'bundle';
    this.release_notes = release_notes["IMX Release Notes"];

    for (let version of this.release_notes) {
      this.note_versions.push(version.version);

      let current_date = new Date(version.releaseDate);
      let newest_date = new Date(this.current_version.releaseDate);
      console.log(this.current_version)

      if (this.current_version.version === '') {
        this.current_version = version;
        this.videoPath = `${this.assetsPath}/${version.video}`
      }
      else if (current_date.getTime() > newest_date.getTime()) {
        this.current_version = version;
        this.videoPath = `${this.assetsPath}/${version.video}`
      }
    }
  }

  ngOnInit(): void {
    // console.log(window.innerWidth);
    if (localStorage.getItem("isGuided") === null) {
      // this.stixService.setGuided(environment.guidedUI);
      this.stixService.setGuided(environment.editorType === 'guided' ? true : false);
      this.isGuided = environment.editorType === 'guided' ? true : false;
      this.editorType = this.isGuided ? 'guided' : 'bundle';
    } else {
      this.isGuided = this.stixService.getGuided();
      this.editorType = this.isGuided ? 'guided' : 'bundle';
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        let urlArray = this.currentUrl.split("/");
        let logInfo = {
          action: 'page',
          description: 'User has navigated to a new page',
          new_page: this.currentUrl
        }
        this.fluentd.logEvent(logInfo, { status: 200 });

        this.currentPage = "";
        if (urlArray[1]) this.currentPage = `${urlArray[1]}`;
        switch (this.currentPage) {
          case 'bundle':
          case 'add-component':
          case 'custom-object':
          case 'guided':
            if (this.currentPage === 'bundle') {
              this.changeEditor(false);
              this.isGuided = false;
            } else if (this.currentPage === 'guided') {
              this.changeEditor(true);
              this.isGuided = true;
            }
            this.currentPage = 'Bundle Editor';
            break;
          case 'query-stix':
            this.currentPage = 'Search'; break;
          case 'reporting':
            this.currentPage = 'Analytics'; break;
          case 'home':
            this.currentPage = 'Home'; break;
          case 'admin-main':
            this.currentPage = 'Admin Main'; break;
          case 'config':
            this.currentPage = 'Config'; break;
          case 'my-profile':
            this.currentPage = 'Profile'; break;
          default:
            this.currentPage = '';
        }

        (((this.currentUrl === '/home') || (this.currentUrl === '/')) ? this.atHome = true : this.atHome = false);
      }
    });

    if (environment.keycloak === true)
      this.userRoles = this.keycloak.getUserRoles();

    this.stixService.getData().subscribe(data => {
      switch (data.type) {
        case 'full-screen':
          this.fullScreenMode = data.value;
          break;
      }
    })
  }

  changeEditor(event) {
    if (event) {
      this.stixService.setGuided(true);
      this.editorType = 'guided';
      this.router.navigate(['/guided']);
    } else {
      this.stixService.setGuided(false);
      this.editorType = 'bundle';
      this.configComponent.initialize();
      this.stixService.refreshRootCollections('can_write');
      this.router.navigate(['/bundle']);
    }
  }

  isSupportSubmitDisabled(): boolean {
    return this.supportType === '' || !this.supportRequest.description || this.support.loading;
  }

  /**
   * Returns appropriate display name based on current TAXII Server url.
   */
  getServerDisplay(): string {
    let ret = '';

    switch (this.stixService.taxiiServerType) {
      case "a1": ret = "Default (Analyst1)"; break;
      case "raw": ret = "Default (Ingest)"; break;
      case "default":
      case "sandbox": ret = "Default"; break;
      case "ais": ret = "AIS"; break;
      case "custom": ret = "Custom"; break;
      default: return "Error";
    }

    if (this.stixService.apiRootProcessingError != '') {
      ret = ret + " - CONNECTION ERROR";
    }

    return ret;
  }

  logout(): void {
    //Logout for Keycloak currently not working
    this.keycloak.logout();

    //Logout for Cognito
    // this.authService.logout();
    // this.router.navigate(['/login']);
  }

  redirectToAlerts(){
    if(this.router.url !== '/my-profile'){
      this.router.navigateByUrl('/my-profile', { state: { activeTab: 'alerts'} });
    } else {
      this.router.navigateByUrl('/my-profile', { state: { activeTab: 'alerts'} });
    }
  }

  openLargeSubModal(modal) {
    this.modalService.open(modal, { size: 'xl' });
  }

  openSupportModal(modal, type: string) {
    this.supportRequest = {
      cachedObjects: null,
      currentBundle: null,
      customObjects: null,
      description: type == "Submit an Issue" ? "Please provide details to our team of the problem you are having." : "Please provide details to our team of the feature you would like to see added to IMX.",
      id: null,
      issueType: null,
      favorites: null,
      labels: null,
      savedBundles: null,
    };
    this.support.loading = false;
    console.debug(`Support Type Selected: ${type}`);
    this.supportLabel = type;
    this.supportType = type;
    this.modalService.open(modal, { size: 'xl' })
  }

  submitSupportRequest() {
    this.support.loading = true;
    // const url = `${environment.taxiiServer.url}${environment.supportServerConfig.path}`;
    let url = `${environment.imxServer.url}${environment.supportServerConfig.path}${this.supportType}/`;
    switch (environment.environment) {
      case "local": {
        url += "local";
        break;
      }
      case "dev":
      case "ten-dev": {
        url += "dev";
        break;
      }
      case "beta": {
        url += "beta";
        break;
      }
      case "guided": {
        url += "guided";
        break;
      }
      case "staging": {
        url += "staging";
        break;
      }
      case "prod": {
        url += "prod";
        break;
      }
      default: {
        console.warn(`IMX Server ERROR - Cannot match environment to logging profile: ${environment.environment}`);
      }
    }

    let postHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(this.environment.imxServer.user + ":" + this.environment.imxServer.pass)}`,
      })
    };

    console.log(this.supportType);

    if (this.supportType == 'Issue') {
      // Grab Cached Objects
      const cachedObjects = this.stixService.bundle;

      // Grab Current Bundle
      const currentBundle = JSON.stringify(this.stixService.bundle);

      // Grab Saved Bundles
      const savedBundles = this.stixService.savedBundles;

      // Grab Favorites 
      const favorites = localStorage.getItem("favorite-attributes");

      // Grab Custom Objects
      const customObjects = this.stixService.getCustomObjects();

      this.supportRequest.cachedObjects = cachedObjects ? cachedObjects : "";
      this.supportRequest.currentBundle = currentBundle ? currentBundle : "";
      this.supportRequest.customObjects = customObjects ? customObjects : "";
      this.supportRequest.favorites = favorites ? favorites : "";
      this.supportRequest.id = environment.supportServerConfig.projectId;
      this.supportRequest.issueType = "BUG";
      this.supportRequest.labels = environment.supportServerConfig.bugLabel;

      this.supportRequest.savedBundles = savedBundles ? savedBundles : "";

      console.debug(this.supportRequest);

      console.debug(url);

      this.httpClient.post(url, JSON.stringify(this.supportRequest), postHeaders).toPromise().then((result) => {
        console.debug(result);
        if (!result.hasOwnProperty('iid'))
          this.announcementService.show("Failed to Send Support Request", "Unable to send request. Please check you connection and try again.", "error", false);
        else
          this.announcementService.show("Support Request Sent", `Thank you for your feedback. The DFTA team will look into your request. Check your ticket status out!`, "success", false, null, result["web_url"] ? true : false, result["web_url"] ? result["web_url"] : "");

        this.modalService.dismissAll();
      }).catch((err) => {
        console.error("There was an error submitting your request");
        console.error(err);
        this.announcementService.show("Failed to Send Support Request", "Unable to send request. Please check you connection and try again.", "error", false);
        this.modalService.dismissAll();
      })
    } else if (this.supportType === 'Feature') {
      let featureRequest = {
        description: `User sent request from path: ${this.currentUrl}. ${this.supportRequest.description}`,
        page: this.currentUrl,
        id: environment.supportServerConfig.projectId,
        issueType: this.supportType.toUpperCase(),
        labels: environment.supportServerConfig.featureLabel
      }
      console.debug(this.supportRequest);
      console.debug(url);

      this.httpClient.post(url, JSON.stringify(featureRequest), postHeaders).toPromise().then((result) => {
        console.debug(result);
        if (!result.hasOwnProperty('iid'))
          this.announcementService.show("Failed to Send Support Request", "Unable to send request. Please check you connection and try again.", "error", false);
        else
          this.announcementService.show("Support Request Sent", `Thank you for your feedback. The DFTA team will look into your request. Check your ticket status out!`, "success", false, null, result["web_url"] ? true : false, result["web_url"] ? result["web_url"] : "");

        this.modalService.dismissAll();
      }).catch((err) => {
        console.error("There was an error submitting your request");
        console.error(err);
        // this.support.toasts.push(tempToast);
        this.announcementService.show("Failed to Send Support Request", "Unable to send request. Please check you connection and try again.", "error", false);
        this.modalService.dismissAll();
      }).finally(() => {
        this.supportLabel = '';
        this.supportType = '';
        this.support.loading = false;
      })
    } else
      console.error(`Support type not recognized: ${this.supportType}`);
  }

  selectAll(event: KeyboardEvent): void {
    if ((event.key === 'a' && event.metaKey) || (event.key === 'a' && event.ctrlKey)) {
      const span = this.spanElement.nativeElement;
      const range = document.createRange();
      range.selectNodeContents(span);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      event.preventDefault();
    }
  }

  versionChanged(version) {
    console.log(version);
    for (let release of this.release_notes) {
      if (release.version === version) {
        this.current_version = release;
        this.videoPath = `./assets/${release.video}`;
        break;
      }
    }
  }
}
