import { Component, OnInit, ComponentRef, ViewChild, ElementRef, ViewChildren, QueryList, TemplateRef } from '@angular/core';
import { StixService } from '../stix-service.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError, TimeoutError, Subscription } from 'rxjs';
import { AdditionalFiltersComponent } from '../query-stix/additional-filters/additional-filters.component';
import { faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import { AnnouncementService } from '../announcement-service/announcement-service.service';
import { faUser, faBan, faArrowLeft, faFile, faSave, faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FluentdService } from '../fluentd/fluentd.service';
import { CustomTaxiiComponent } from './custom-taxii/custom-taxii.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {
  faConnect = faWaveSquare;

  faUser = faUser;
  faBan = faBan;
  faArrowLeft = faArrowLeft;
  faFile = faFile;
  faSave = faSave;
  faTrash = faTrash;
  faSpinner = faSpinner;

  selectedAPIRoot = null;
  APIRoots = [];
  aisUrl = environment.aisTaxiiProxy.url;
  selectedCollection;
  saved;
  taxiiServerSelection: string = ""; //Determines server display name after selecting from dropdown
  showCustomTaxii = false;
  customTaxiiType = '';
  generateCert: boolean;
  certPassword: string;
  crtFile: any;
  keyFile: any;
  pemFile: any;
  connectionError: string = '';
  connectionErrorURL: string = '';
  customTaxiiArray = [];
  isNewCustom: boolean;
  certName: string = '';
  orgName: string = '';
  userRoles = [];
  customTaxiiRef: MatDialogRef<CustomTaxiiComponent>;

  private imxHeaders = new HttpHeaders()
  .set('Authorization', `Basic ${btoa(environment.imxServer.user + ":" + environment.imxServer.pass)}`);

  taxiiServer = {
    url: environment.taxiiServer.url,
    username: environment.taxiiServer.username,
    password: environment.taxiiServer.password,
    certificate: null,
    apiRoot: '',
    childRoot: '',
    availableCollections: [],
    collection: null
  };

  customInfo: {
    url: string,
    username: string,
    password: string,
    cert: string | File,
    key: string | File,
    roles: string[],
    useCert: boolean,
    pem: string | File,
    certPassword: string
  }
  taxiiServerAuthType = this.stixService.taxiiServerAuthType;
  taxiiServerType = this.stixService.taxiiServerType;
  isLoadingRoots: boolean = false;
  moveoutCoverCollections: boolean = false;
  addFilters: boolean = false;
  componentsReferences = Array<ComponentRef<AdditionalFiltersComponent>>()
  isLoadingStix: boolean = false;

  isLoading = false;
  serverLatency: number;

  constructor(
    public stixService: StixService,
    private httpClient: HttpClient,
    private announcementService: AnnouncementService,
    private fluentd: FluentdService,
    private customTaxii: MatDialog
  ) {
    this.initialize();
  }

  ngOnInit(): void {
    this.stixService.getData().subscribe(data => {
      switch (data.type) {
        case 'config-loading':
          this.isLoading = data.value;
          break;
      }
    })
  }

  initialize() {
    const serverType = localStorage.getItem("taxiiServerType") ? localStorage.getItem("taxiiServerType") : 'default';
    this.serverSettingsChanged(serverType);
    this.saved = true;
    if (serverType != "ais"){
      this.retrieveCustomInfo();
    }
  }

  serverSettingsChanged(type: string): void {
    console.debug(this.taxiiServer);
    this.isLoading = false;
    this.saved = false;
    this.taxiiServerType = type;
    //this.stixService.updateTaxiiServer('legacy', type);

    if (type === "default") {
      this.taxiiServerSelection = "Default";
      this.stixService.publishToA1 = false;
      this.taxiiServer.url = environment.taxiiServer.url;
      this.taxiiServer.username = environment.taxiiServer.username;
      this.taxiiServer.password = environment.taxiiServer.password;
      this.taxiiServer.apiRoot = null;
      this.taxiiServer.childRoot = null;
      this.taxiiServer.collection = null;
    } else if (type === "a1" || type === "raw") {
      this.taxiiServerSelection = "Default";
      let collection = null;
      this.stixService.publishToA1 = true;
      console.log(this.stixService.apiRoots);
      this.stixService.apiRoots.find(r => {
        collection = r.collections.find(c =>
          c.id ===
          (type === "a1" ? environment.taxiiServer.a1CollectionId
            : type === "raw" ? environment.taxiiServer.a1RawCollectionId
              : null)
        )
        return !!collection;
      });

      this.taxiiServer.url = environment.taxiiServer.url;
      this.taxiiServer.username = environment.taxiiServer.username;
      this.taxiiServer.password = environment.taxiiServer.password;
      this.taxiiServer.apiRoot = environment.taxiiServer.a1ApiRoot;
      this.taxiiServer.childRoot = environment.taxiiServer.a1ChildRoot;
      this.taxiiServer.collection = {
        "id": environment.taxiiServer.a1CollectionId,
        "title": collection ? collection.title : '',
      };
    } else if (type === "custom") {
      this.taxiiServerSelection = "Custom";
      this.stixService.publishToA1 = false;
      this.taxiiServer.url = "";
      this.taxiiServer.username = "";
      this.taxiiServer.password = "";
      this.taxiiServer.apiRoot = null;
      this.taxiiServer.childRoot = null;
      this.taxiiServer.collection = null;
    } else if (type === "ais") {
      this.taxiiServerSelection = "AIS";
      this.stixService.publishToA1 = false;
      this.taxiiServer.url = environment.aisTaxiiProxy.url;
      this.taxiiServer.username = environment.aisTaxiiProxy.username;
      this.taxiiServer.password = environment.aisTaxiiProxy.password;
      this.taxiiServer.apiRoot = null;
      this.taxiiServer.childRoot = null;
      this.taxiiServer.collection = null;
    } else {
      this.taxiiServer.url = "";
      this.taxiiServer.username = "";
      this.taxiiServer.password = "";
      this.APIRoots = [];
      this.selectedAPIRoot = null;
      this.selectedCollection = null;
      this.taxiiServer.apiRoot = null;
      this.taxiiServer.childRoot = null;
      this.taxiiServer.collection = null;
    }
    console.debug("After server change");
    console.debug(this.taxiiServer);
    if (type != "custom"){
      this.saveConfig();
    }
  }

  openCustomTaxiiModal(id = null) {
    if (id === '')
      return;
    this.stixService.publishToA1 = false;
    this.taxiiServerSelection = "Custom";
    this.taxiiServer.apiRoot = null;
    this.taxiiServer.childRoot = null;
    this.taxiiServer.collection = null;
    this.generateCert = undefined;

    if (id !== null) {
      this.isNewCustom = false;
      // this.modalRef = this.modalService.open(modal, { ariaLabelledBy: 'custom-taxii-modal-title', size: 'xl', windowClass: 'custom-taxii-modal' })
      // this.modalRef.result.finally(() => {
      //   this.customTaxiiType = '';
      //   this.connectionError = '';
      //   this.connectionErrorURL = '';
      // });
      this.customTaxiiRef = this.customTaxii.open(CustomTaxiiComponent, {
        data: {
          taxiiServer: this.taxiiServer,
          id: id,
          isNewCustom: this.isNewCustom,
          customTaxiiArray: this.customTaxiiArray
        },
        minHeight: '200px',
        maxHeight: '70vh',
        width: `${window.innerWidth / 1.5}px`,
        panelClass: 'custom-taxii-dialog',
        position: {
          top: '100px'
        }
      });

      this.customTaxiiRef.componentInstance.save.subscribe((config) => {
        this.stixService.useCert = config.useCert;
        this.stixService.taxiiServer.password = config.password;
        this.stixService.taxiiServer.username = config.username;
        this.stixService.taxiiServer.url = config.url;
        this.saveConfig();
      })

      this.customTaxiiRef.componentInstance.delete.subscribe((taxiiUrl) =>{
        this.customTaxiiArray = this.customTaxiiArray.filter(elem => elem !== taxiiUrl);
      });

      this.customTaxiiRef.afterClosed().subscribe(() => {
        this.customTaxiiType = '';
        this.connectionError = '';
        this.connectionErrorURL = '';
      })
    } else {
      this.taxiiServer.url = "";
      this.taxiiServer.username = "";
      this.taxiiServer.password = "";

      this.isNewCustom = true;
      this.customTaxiiRef = this.customTaxii.open(CustomTaxiiComponent, {
        data: {
          taxiiServer: this.taxiiServer,
          isNewCustom: this.isNewCustom,
          customTaxiiArray: this.customTaxiiArray
        },
        minHeight: '200px',
        maxHeight: '70vh',
        width: `${window.innerWidth / 1.5}px`,
        panelClass: 'custom-taxii-dialog',
        position: {
          top: '100px'
        }
      });

      this.customTaxiiRef.componentInstance.save.subscribe((config) => {
        this.stixService.useCert = config.useCert;
        this.stixService.taxiiServer.password = config.password;
        this.stixService.taxiiServer.username = config.username;
        this.stixService.taxiiServer.url = config.url;

        this.saveConfig();
      })

      this.customTaxiiRef.afterClosed().subscribe(() => {
        this.customTaxiiType = '';
        this.connectionError = '';
        this.connectionErrorURL = '';
      })
    }

  }

  saveConfig() {
    this.stixService.taxiiServer.username = this.taxiiServer.username;
    this.stixService.taxiiServer.password = this.taxiiServer.password;
    this.stixService.taxiiServer.url = this.taxiiServer.url;
    this.stixService.apiRoots2 = [];
    this.stixService.apiRootDetails = [];
    this.saved = true;
    this.stixService.taxiiServerAuthType = this.taxiiServerAuthType;
    this.stixService.taxiiServerType = this.taxiiServerType;

    if (this.taxiiServerType === 'custom') {
      if (this.stixService.useCert) {
        this.stixService.updateTaxiiServer(
          'legacy',
          'custom',
          this.taxiiServer.url
        )
      } else {
        this.stixService.updateTaxiiServer(
          'legacy',
          'custom',
          this.taxiiServer.url,
          this.taxiiServer.username,
          this.taxiiServer.password,
        )
      }
      this.stixService.getAPIRoots('custom');
    } else {
      this.stixService.updateTaxiiServer('legacy', this.taxiiServerType);
      this.stixService.getAPIRoots();
      this.stixService.updateLatency(this.announcementService);
    }
    console.info(this.stixService.apiRoots2);

    const configEvent = {
      type: 'config-loading',
      value: true,
    }
    this.stixService.sendData(configEvent);
  }

  isConnectDisabled(): boolean {
    // Custom TAXII Server
    // Check for url/username/password
    if (this.stixService.apiRootProcessingError != ''){
      this.saved = false;
    }
    
    if (this.taxiiServerType === 'custom' && (!this.taxiiServer.url || !this.taxiiServer.username || !this.taxiiServer.password)) {
      console.debug("Custom taxii server without url/username/password");
      return true;
    }

    return false;
  }

  retrieveCustomInfo() {
    const httpHeaders = new HttpHeaders()
    .set('Authorization', `Basic ${btoa(environment.imxServer.user + ":" + environment.imxServer.pass)}`);

    this.httpClient.get(`${environment.imxServer.url}/taxii`, { headers: httpHeaders }).subscribe((resp: any) => {
      resp?.hits?.hits.forEach(elem => this.customTaxiiArray.push(elem._id));
    });
  }
  private httpHeaders = new HttpHeaders()
    .set('Accept', 'application/taxii+json;version=2.1')
    .set('Authorization', `Basic ${btoa(this.stixService.taxiiServer.username + ":" + this.stixService.taxiiServer.password)}`);


  /* getAPIRootInformation(url: string, api_root?: string): Observable<unknown> {
    if (url.endsWith('taxii2/')) {
      url = url.substring(0, url.length - 7);
    }
    return this.httpClient.get(url + api_root + "/", { headers: this.httpHeaders });
  } */
}
