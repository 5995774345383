import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { StixService } from "src/app/stix-service.service";
import {
  faPlus,
  faTrash,
  faInfoCircle,
  faBan,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import { DetectionRuleObject } from "./detection-rule";


@Component({
  selector: "detection-rule-dialog",
  templateUrl: "./detection-rule-dialog.component.html",
  styleUrls: ["./detection-rule-dialog.component.css"],
})
export class DetectionRuleDialogComponent implements OnInit {
  faPlus = faPlus;
  faTrash = faTrash;
  faInfoCircle = faInfoCircle;
  faBan = faBan;
  faFileImport = faFileImport;

  errors = {};

  currRule = new DetectionRuleObject("");
  currFunction;

  methodIds: any[] = this.stixService.bundle.objects.filter(o => o.id.includes('malware-method--'));
  filteredMethodIds = this.methodIds;

  detectionToolOV = [
    "capa",
    "cape",
    "manual",
    "sigma",
    "yara",
  ]

  constructor(
    public stixService: StixService,
    public dialogRef: MatDialogRef<DetectionRuleDialogComponent>
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onX(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    for (let prop in this.currRule) {
      if (this.currRule[prop].length == 0) {
        delete this.currRule[prop];
      }
    }

    this.dialogRef.close(this.currRule);
  }

  ngOnInit(): void {}

  isDialogValid(): boolean {
    if (this.currRule.detect_ref.length > 0) {
      let regex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
      if (!regex.test(this.currRule.detect_ref) || !this.currRule.detect_ref.startsWith("malware-method--")) {
        this.errors["detect_ref"] = "Must be a valid ID of type malware-method";
      }
      else {
        delete this.errors["detect_ref"];
      }
    }
    if (this.currRule.url.length > 0) {
      const urlRegex = new RegExp('^(?:[A-Za-z][A-Za-z0-9+.-]*:\/{2})+(?:(?:[A-Za-z0-9-._~]|%[A-Fa-f0-9]{2})+(?::([A-Za-z0-9-._~]?|[%][A-Fa-f0-9]{2})+)?@)?(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.){1,126}[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?(?::[0-9]+)?(?:\/(?:[A-Za-z0-9-._~]|%[A-Fa-f0-9]{2})*)*(?:\\?(?:[A-Za-z0-9-._~]+(?:=(?:[A-Za-z0-9-._~+]|%[A-Fa-f0-9]{2})+)?)(?:&|;[A-Za-z0-9-._~]+(?:=(?:[A-Za-z0-9-._~+]|%[A-Fa-f0-9]{2})+)?)*)?$');
      const lenRegex = new RegExp('^.*\/{2}([^\/~]*~)*([^\/~]{1,63})~[^\/~]*(\/.*)*$');
      
      if (!urlRegex.test(this.currRule.url)) {
        this.errors["url"] = "Must be a valid URL (i.e. https://www.example.com)"
      }
      else if (!lenRegex.test(this.currRule.url.replace(/\./g, "~"))) {
        this.errors["url"] = "Must be a valid URL (domain must have at most 63 characters)"
      }
      else {
        delete this.errors["url"];
      }
    }
    else if (this.currRule.rule.length == 0) {
      this.errors["form"] = "Rule or URL required";
      return false;
    }
    this.errors["form"] = "";

    return this.currRule.rule_type.length > 0;
  }

  filterRefs(type: string) {
    switch (type) {
      case 'detect_ref':
        this.filteredMethodIds = this.methodIds.filter(elem => elem.id.includes(this.currRule.detect_ref.trim()));
        break;
    }
  }
}
