<div class="dialog">
    <div class="form-group mt-2">
        <div class="modal-header">
            <h4 class="modal-title" id="publish-modal-title">
                <span>Object Marking References</span>
                <span *ngIf="stixService.guidedUI">&nbsp;(TLP Color)</span>
            </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="onX()">
                <span aria-hidden="true"></span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group mt-2">
                <div class="input-group mb-2">
                    <span class="input-group-text">
                        <div class="tooltipright"><b>{{ currentStringValidator.displayName }}</b>
                            <span class="toolttext">Common references in dropdown -<br>use the box on the right for custom
                                entries.</span>
                        </div>
                    </span>
                    <select *ngIf="this.env.tlpConfig.enabledTlpVersions.length > 1" type="text" class="form-control  form-select col-2" (change)="getTlpOptions($event)">
                        <option value='' selected></option>
                        <option *ngFor="let ver of this.env.tlpConfig.enabledTlpVersions" value="{{ver.value}}">{{ver.label}}</option>         
                    </select>
                    <input class="form-control col-2" *ngIf="this.env.tlpConfig.enabledTlpVersions.length == 1" disabled value="{{this.env.tlpConfig.enabledTlpVersions[0].label}}">
                    <!-- (change)="addStringElement()" -->
                    <select type="text" class="form-control form-select col-2" [(ngModel)]="this.currentString" (change)="onTlpChange($event)">
                        <option value='' selected></option>
                        <option *ngFor="let tlp of tlp_options" [value]="tlp.value">{{tlp.key}}</option>
                        <!--ng-container *ngFor="let obj of stixService.bundle.objects">
                            <option *ngIf="obj.id.includes('marking-definition--')" [value]="obj.id">
                                {{obj.id}}
                            </option>
                        </ng-container-->
                    </select>
                    <input class="form-control" placeholder="{{ currentStringValidator.placeholder }}"
                        [(ngModel)]="this.currentString" [pattern]="currentStringValidator.regexValidation" type="text"
                        [disabled]="disableMarkingDefInput">
                    <!--button class="btn btn-fa-sm btn-primary" type="button" (click)="addStringElement()"
                        [disabled]="currentString == ''">
                        <fa-icon [icon]="faPlus"></fa-icon>
                    </button-->
                </div>
            </div>
            {{ this.tlpDescription }}
        </div>
        <div class="modal-footer d-flex">
            <button type="button" class="btn btn-sm btn-secondary" (click)="onX()">
                <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
                Cancel
            </button>
            <button type="button" class="btn btn-sm btn-success ms-2" (click)="onConfirm()" [disabled]="this.currentString == ''">
                <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                Add
            </button>
        </div>
    </div>
    
    
    
    
    
    