<div class="d-flex flex-column justify-content-start align-items-center content-wrapper">
    <div class="d-flex flex-row justify-content-around align-items-end gr-top-width">
        <div class="w-75 ps-5 d-flex align-items-end">
            <div class="mb-3 me-3">
                  <img class="card-img" src="./assets/plus.svg" class="center" width="60" height="60" />
            </div>
            <span class="title-main-size3">ANALYST ASSESSMENT?</span>
        </div>

        <div class="total-cart-wrapper total-cart-subtitle-position mb-4">
            <app-total-guided-cart (redirectCart)="redirectCart($event)" (syncCart)="syncCart($event)"></app-total-guided-cart>
        </div>
    </div>

    <div class="horizonal-rule-top"></div>

    <div class="d-flex justify-content-center align-items-start w-80">
        <div class="mt-5 d-flex flex-column justify-content-start gr-help-section me-3">
            <h6 class="gr-help-section-title">Almost done!</h6>
            <div class="gr-help-section-content">
                We are now at a crucial stage where your final assessment is needed to encapsulate the entirety of the incident. <br><br>
                Please include any relevant details such as:<br>
                &#x2022; The nature and extent of the cybersecurity event.<br>
                &#x2022; Key vulnerabilities exploited.<br>
                &#x2022; The effectiveness of the response measures taken.<br>
                &#x2022; Recommendations for preventing similar incidents in the future.<br>
                &#x2022; Any other observations or insights that you deem important. <br>
                &#x2022; Any external references that may support your assessment. <br><br>
                Feel free to elaborate in as much detail as necessary, as your free-text analysis will offer a comprehensive view of the event.
            </div>
        </div>
        <div class="mt-5 d-flex flex-column align-items-center gr-outter-wrapper">
            
            <div class="d-flex align-items-center gr-input-wrapper ps-3 pe-3 mt-2">
                <span class="w-25" title="Report name">Report Name</span>
                <input type="text" class="form-control w-75 noborder" [(ngModel)]="reportName" placeholder="Enter a name or label. One will be autogenerated if not provided."/>
            </div>

            <!-- <div class="d-flex align-items-center gr-input-wrapper ps-3 pe-3 position-relative mt-4">
                <span class="w-25" title="Time Zone">Time Zone</span>
                <select type="text" class="noborder" style="width: 88%; margin-left: 3%" (change)="setTimeZone($event)">
                    <option selected style="color: #999" value="">Select Time Zone</option>
                    <option *ngFor="let tz of timeZones" value="{{tz}}">{{guidedService.showTimezoneAndOffset(tz)}}</option>         
                </select>
                <span class="position-relative" style="top: 1px; right: 0px">
                    <fa-icon class=" pe-1" style="color: #91a4bb" [icon]="faClock"></fa-icon>
                </span>
            </div>

            <div class="d-flex align-items-center gr-input-wrapper ps-3 pe-3 position-relative mt-4">
                <span class="w-25" title="Published Date and Time">Published</span>
                <input placeholder="Date Published" class="form-control noborder" style="width: 88%; margin-left: 1.8%" [(ngModel)]="published" id="grPublished"
                    [owlDateTimeTrigger]="grDtPicker" [owlDateTime]="grDtPicker">
                <owl-date-time #grDtPicker></owl-date-time>
                <span class="position-relative" style="top: 1px; right: 0px">
                    <fa-icon class=" pe-1" style="color: #91a4bb" [icon]="faClock"></fa-icon>
                </span>
            </div> -->

            <div class="d-flex flex-column align-items-left gr-input-wrapper p-3 mt-4">
                <div class="d-flex">
                    <span class="w-25" title="Report Details">Details</span>
                    <textarea class="form-control w-75 noborder" name="event details" id="event-details" rows="8"
                        placeholder="Add details"
                        [(ngModel)]="reportDetails"></textarea>
                </div>
            </div>

            <div class="d-flex flex-column align-items-left gr-input-wrapper px-3 pt-2 mt-4 mb-4">
                <app-external-references class="w-100"></app-external-references>
            </div>

            <div class="w-100 d-flex justify-content-end mt-3 pe-4 mb-2">
                <div class="d-flex align-items-center mt-2 me-3 assist-text" (click)="showAssistance.emit()">
                    <h6 class="mt-3 me-1">Need Help</h6>
                    <fa-icon class="faQuestionCircle" [icon]="faQuestionCircle"></fa-icon>
                </div>
            </div>
        </div>
    </div>

    <app-add-component #addComponent style="display: none"
        [objectSelectionInput]="objectSelection"
    >
    </app-add-component>
</div>