<div class="d-flex flex-column justify-content-start align-items-center content-wrapper">
    <div class="d-flex flex-row justify-content-around align-items-end gr-top-width">
        <div class="w-75 ps-5 d-flex align-items-end">
            <div class="mb-3">
                <img class="card-img" src="./assets/sword.svg" class="center" width="80" height="80" />
            </div>
            <span class="title-main-size3">HOW?</span>
        </div>

        <div class="total-cart-wrapper total-cart-subtitle-position mb-4">
            <app-total-guided-cart (redirectCart)="redirectCart($event)" (syncCart)="syncCart($event)"></app-total-guided-cart>
        </div>
    </div>

    <div class="horizonal-rule-top"></div>

    <div class="d-flex justify-content-center align-items-start w-80">
        <div class="mt-5 d-flex flex-column justify-content-start gr-help-section me-3">
            <h6 class="gr-help-section-title">Adding Detection Strings</h6>
            <div class="gr-help-section-content">
                If you have a detection string, select the Pattern Type that was used. 
                <br><br>
                If it is a STIX pattern, you will be guided through the STIX pattern building process. Once you are done with the Pattern Builder, the pattern built will automatically populate the Detection String field on this page and you may continue.
                <br><br>
                If it is another pattern type, paste the string into the box below the type selector. Note that this string will not be validated.
                <br><br>
                Please note if you provide your own pattern, it won't be validated. 
                <br><br>
                If you do not have a detection string to add, proceed to the next page.
            </div>
        </div>
        <div class="mt-5 test d-flex flex-column align-items-center gr-outter-wrapper">  
            <div class="d-flex align-items-center gr-input-wrapper ps-3 pe-3 mt-3">
                <span class="w-100 fw-bold" title="">Do you have a detection string to add? If yes, add it; if no, continue.</span>
            </div>
            <div class="d-flex align-items-center gr-input-wrapper ps-3 pe-3 mt-3">
                <span class="w-25">String Label
                    <span class="guided-tip">
                        <fa-icon class="ps-1" [icon]="faInfoCircle"></fa-icon>
                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('string_label')"></span>
                    </span>
                </span>
                <input type="text" placeholder="Enter a label for your data string or one will be auto generated based on time" class="form-control w-75 noborder" [(ngModel)]="name" (input)="checkContinue(false)">
            </div>
            <div class="d-flex align-items-center gr-input-wrapper ps-3 pe-3 mt-3">
                <span class="w-25">Pattern Type<span class="text-danger ms-1">*</span>
                    <span class="guided-tip">
                        <fa-icon class="ps-1" [icon]="faInfoCircle"></fa-icon>
                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('pattern_type')"></span>
                    </span>
                </span>
                <select class="w-75 form-control form-select noborder" type="text" [(ngModel)]="toolSelection" (change)="checkContinue(false); openPatternBuilder();">
                    <option disabled selected style="color: #999" value="">Select Pattern Type</option>
                    <option *ngFor="let option of toolOptions">{{option}}</option>
                </select>
            </div>
            <div class="d-flex align-items-center gr-input-wrapper ps-3 pe-3 mt-3">
                <!--span class="w-25">Pattern&nbsp;<span class="text-danger">*</span><fa-icon *ngIf="toolSelection === 'STIX'" style="vertical-align: top;" [icon]="faEdit" class="ms-2 patternEditor" [ngClass]="{'pattern-editor-can-edit': patternEdit === true}" (click)="setPatternEditMode()"></fa-icon></span-->
                <span class="w-25">Pattern<span class="text-danger ms-1">*</span>
                    <span class="guided-tip">
                        <fa-icon class="ps-1" [icon]="faInfoCircle"></fa-icon>
                        <fa-icon *ngIf="toolSelection === 'STIX'" style="vertical-align: top;" [icon]="faEdit" class="ms-2 patternEditor" [ngClass]="{'pattern-editor-can-edit': patternEdit === true}" (click)="setPatternEditMode()"></fa-icon>
                            <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('pattern')"></span>
                    </span>
                </span>
                <textarea *ngIf="toolSelection !== 'STIX'" [disabled]="!toolSelection" class="mt-2 mb-2 form-control w-75 gr-input-wrapper-small" placeholder="Add Pattern" rows="6" wrap="soft" [(ngModel)]="details" (input)="checkContinue(false)"></textarea>
                <!-- <textarea *ngIf="toolSelection === 'STIX'" [disabled]="!this.details" class="mt-2 mb-2 form-control w-75 gr-input-wrapper-small" placeholder="Add Pattern (click here to open Pattern Builder)" rows="6" wrap="soft" [(ngModel)]="details" (input)="checkContinue(false)"></textarea> -->
                <textarea [readonly]="patternEdit === false" *ngIf="toolSelection === 'STIX'" [ngClass]="{ 'pattern-box-disabled': patternEdit === false }" class="mt-2 mb-2 form-control w-75 gr-input-wrapper-small pattern-box" placeholder="Click here to open the Pattern Builder. You can click on the pencil to manually update a pattern." rows="6" wrap="soft" [(ngModel)]="details" (click)="openPatternBuilder()" (input)="checkContinue(false)"></textarea>
            </div>
            <div style="width: 50vw;" class="d-flex align-items-center mt-3">
                <div class="d-flex align-items-center gr-input-wrapper ps-3 pe-3">
                    <span class="w-25">First Observed
                        <span class="guided-tip">
                            <fa-icon class="ps-1" [icon]="faInfoCircle"></fa-icon>
                            <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('first_observed')"></span>
                        </span>
                    </span>
                    <div class="w-75 d-flex">
                        <div style="position: relative;" class="w-75">
                            <input class="form-control noborder" placeholder="When was this first seen?" [max]="last_seen" [owlDateTimeTrigger]="dtPicker2" [owlDateTime]="dtPicker2" [(ngModel)]="firstObserved" (ngModelChange)="checkObserved('first')" [disabled]="disableObserved" (input)="checkContinue(true)">
                            <button class="clear-button">
                                <p class="clear-button-text" *ngIf="firstObserved !== ''" (click)="clearObserved('first')">X</p>
                            </button>
                        </div>
                        <owl-date-time [startAt]="lastDateStart" #dtPicker2 (afterPickerClosed)="checkContinue(true)"></owl-date-time>
                        <select class="w-25 ms-3 form-control form-select noborder"type="text" [(ngModel)]="firstObservedTimeZone" (change)="checkContinue(false)" [disabled]="disableObserved">
                            <option selected style="color: #999" value="">Select Time Zone</option>
                            <option *ngFor="let tz of timeZoneOptions" value="{{tz}}">{{guidedService.showTimezoneAndOffset(tz)}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div style="width: 50vw;" class="d-flex align-items-center mt-3">
                <div class="d-flex align-items-center gr-input-wrapper ps-3 pe-3">
                    <span class="w-25">Last Observed
                        <span class="guided-tip">
                            <fa-icon class="ps-1" [icon]="faInfoCircle"></fa-icon>
                            <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('last_observed')"></span>
                        </span>
                    </span>
                    <div class="w-75 d-flex">
                        <div style="position: relative;" class="w-75">
                            <input class="form-control noborder" placeholder="When did you last see it?" [min]="first_seen" [owlDateTimeTrigger]="dtPicker3" [owlDateTime]="dtPicker3" [(ngModel)]="lastObserved" (ngModelChange)="checkObserved('last')" [disabled]="disableObserved" (input)="checkContinue(true)">
                            <button class="clear-button">
                                <p class="clear-button-text" *ngIf="lastObserved !== ''" (click)="clearObserved('last')">X</p>
                            </button>
                        </div>
                        <owl-date-time [startAt]="firstDateStart" #dtPicker3 (afterPickerClosed)="checkContinue(true)"></owl-date-time>
                        <select class="w-25 ms-3 form-control  form-select noborder"type="text" [(ngModel)]="lastObservedTimeZone" (change)="checkContinue(false)" [disabled]="disableObserved">
                            <option selected style="color: #999" value="">Select Time Zone</option>
                            <option *ngFor="let tz of timeZoneOptions" value="{{tz}}">{{guidedService.showTimezoneAndOffset(tz)}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center gr-input-wrapper ps-3 pe-3 mt-3">
                <span class="w-25">Number Observed
                    <span class="guided-tip">
                        <fa-icon class="ps-1" [icon]="faInfoCircle"></fa-icon>
                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('number_observed')"></span>
                    </span>
                </span>
                <input type="number" min="0" placeholder="How many times have you seen it occur?" class="w-75 form-control noborder" [(ngModel)]="numberObserved" (input)="checkContinue(false)" [disabled]="disableObserved">
            </div>
            <app-guided-cart class="w-100" #guidedCart [currentComponent]="component" [disableCartEvent]="disableCartEmitter" [newItemEvent]="newItemEmitter" [editModeEvent]="editModeEmitter" [syncCartEvent]="syncCartEmitter" [getEditItemEvent]="getEditItemEmitter" (showAssistance)="showAssistanceTrigger()" (addItem)="addCartItem($event)" (updateItem)="editCartItem($event)"></app-guided-cart>
        </div>
    </div>
</div>

<!-- <app-add-component #appAddComponent style="display: none" [objectSelectionInput]="objectSelection"></app-add-component> -->