export const ENTITY_TYPE_OV = [
  "computers-mobile",
  "computers-personal",
  "computers-server",
  "customer",
  "customer-individual",
  "customer-organization",
  "domain-controller",
  "employee",
  "group",
  "ics-actuator",
  "ics-engineering-workstation",
  "ics-historian",
  "ics-hmi",
  "ics-other",
  "ics-plc",
  "ics-safety-system",
  "ics-sensor",
  "individual",
  "network-device",
  "organization",
  "system",
  "vehicles",
]