<div style="max-height: 93vh; overflow-y: auto">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ messageObj?.title + ' > ' + messageObj.menu }}</h4>
    <button type="button" class="btn-close" aria-label="Close" data-dismiss="modal" (click)="close()">
      <span aria-hidden="true"></span>
    </button>
  </div>

  <div class="modal-body extensions-modal-body">
    <div *ngFor="let msg of messageObj?.messages; let index = index">
      <div *ngIf="showTextOnly(index)">
        <strong><span [innerHTML]="msg"></span></strong>
      </div>
      <div *ngIf="!showTextOnly(index)" class="d-flex">
        <fa-icon class="me-2" [icon]="faArrowAltCircleRight">
        </fa-icon>
        <span [innerHTML]="msg"></span>
      </div>
    </div>
    <div>
      <div *ngIf="videoPath">
        <video controls style="width:100%">
          <source [src]="videoPath" type="video/mp4">
        </video>
      </div>
    </div>
    <div class="item-action">
      <button *ngIf="showButton('cancel')" type="button" class="btn btn-secondary me-3" (click)="close()">
        CANCEL
      </button>
      <div *ngIf="scriptPath" class="me-2">
        <a class="btn btn-primary" [href]="scriptPath" target="_blank">
          <fa-icon [icon]="faDownload"></fa-icon>
          Download Video Script
        </a>
      </div>
      <button *ngIf="showButton('ok')" type="button" class="btn btn-success" (click)="confirm()">OK
      </button>
    </div>
  </div>
</div>