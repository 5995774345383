<div class="d-flex align-items-center col-4" [ngClass]="{'mt-1':stixService.guidedUI}">
    <div class="col-4">
        <p class="stix-title" [title]="tooltip">Extensions</p>
    </div>
    <div class="ms-3" [ngClass]="{'pe-0':!stixService.guidedUI}">
        <div class="float-end">
            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                <button class="btn btn-fa-sm btn-primary mt-1" type="button" *ngIf="!isAddingExtensions"
                    (click)="openDialog(extensionBuilder)">
                    <fa-icon [icon]="faPlus"></fa-icon>
                </button>
            </ng-container>
            <!-- <button class="btn btn-fa-sm btn-outline-secondary fa-ban-sm" type="button" *ngIf="isAddingExtensions"
                (click)="addOrCancel()">
                <fa-icon [icon]="faBan"></fa-icon>
                Cancel
            </button> -->
        </div>
    </div>
</div>

<div class="form-group mt-2" *ngIf="!isAddingExtensions && this.stixService.extensions.length > 0">
    <div *ngFor="let extension of this.stixService.extensions; index as i">
        <div class="d-flex form-control header">
            <span class="col-11 pt-2 ps-3" (click)="toggleShow(extension)">{{getHeading(extension)}}</span>
            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation && (stixService.currentType != 'impact' || !getHeading(extension).endsWith('-ext'))" class="col-1">
                <div>
                    <button type="button" class="btn btn-fa btn-primary"
                        (click)="editExtensions(extension, extensionBuilder)">
                        <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                    <button type="button" class="btn btn-fa btn-danger" style="margin-left: 10px;"
                        (click)="deleteExtensions(extension)">
                        <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="isVisible(extension)">
            <ng-container *ngIf="propType(extension) == 'prop'">
                <div class="form-control">
                <div class="row mb-1" *ngFor="let prop of getProps(extension)">
                    <div class="col-12">
                        <div class="d-flex">
                            <ng-template [ngIf]="!isObject(prop[1])" [ngIfElse]="objProp">
                                <ng-template [ngIf]="('' + prop[1]).length < 200" [ngIfElse]="largeProp">
                                    <span
                                        class="form-control dropdown"
                                        id="{{prop[0]}}"
                                        style="height: 31px;background-color: #21252908 !important; font-size: smaller;"
                                        readonly
                                    ><b>{{prop[0]}}:</b> {{prop[1]}}</span>
                                    <!-- <button
                                        class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                        type="button"
                                        (click)="deleteProp(extension, prop[0])"
                                    >
                                    X
                                    </button> -->
                                </ng-template>
                                <ng-template #largeProp>
                                    <span
                                        class="form-control dropdown"
                                        style="height: 125px; background-color: #21252908 !important; font-size: smaller; overflow-wrap: break-word; overflow-y: scroll;"
                                        readonly
                                    ><b>{{prop[0]}}:</b><br>&#13;&#10;{{prop[1]}}</span>
                                    <!-- <button
                                        class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                        type="button"
                                        (click)="deleteProp(extension, prop[0])"
                                        style="height: 125px"
                                    >
                                    X
                                    </button> -->
                                </ng-template>
                            </ng-template>
                            <ng-template #objProp>
                                <span
                                    class="form-control dropdown"
                                    style="height: 125px; background-color: #21252908 !important; font-size: smaller; overflow-wrap: break-word; overflow-y: scroll; white-space: pre-wrap;"
                                    readonly
                                ><b>{{prop[0]}}:</b><br>&#13;&#10;{{prop[1] | json}}</span>
                                <!-- <button
                                    class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                    type="button"
                                    (click)="stixService.deleteProp(prop[0])"
                                    style="height: 125px"
                                >
                                X
                                </button> -->
                            </ng-template>
                        </div>
                    </div>
                </div>
                </div>
            </ng-container>
            <pre *ngIf="propType(extension) == 'pre'" class="form-control" style="font-size: smaller;">{{ extension | json }}</pre>
        </ng-container>
    </div>
</div>

<div class="form-group mt-2" *ngIf="isAddingExtensions">
    <div class="row">
        <div class="col">
            <div class="input-group mb-2 float-end">
                <span class="input-group-text">
                    <div class="tooltipright"><b>Extension ID</b>
                        <span class="toolttext">Select the ID of the extension or predefined extension (if
                            available).<br>Each ID may only be used ONCE.<br>Definitions with no Property Extensions are
                            excluded</span>
                    </div>
                </span>
                <select type="text" class="form-control form-select" [(ngModel)]="this.currentExtension.extension_id"
                    name="extension_id" id="extension_id" (change)="getTypes()">
                    <option value=''></option>
                    <ng-container *ngFor="let obj of stixService.bundle.objects">
                        <option *ngIf="obj.id.includes('extension-definition--') && this.hasProperties(obj)"
                            [value]="obj['id']">
                            {{obj.name}} ({{obj.id}})
                        </option>
                    </ng-container>
                    <option *ngIf="stixService.currentType == 'file'" value="archive-ext">Archive File Extension
                        (archive-ext)</option>
                    <option *ngIf="stixService.currentType == 'file'" value="pdf-ext">PDF File Extension
                        (pdf-ext)</option>
                    <option *ngIf="stixService.currentType == 'file'" value="ntfs-ext">NTFS File Extension
                        (ntfs-ext)</option>
                    <option *ngIf="stixService.currentType == 'file'" value="raster-image-ext">Raster Image File
                        Extension (raster-image-ext)</option>
                    <option *ngIf="stixService.currentType == 'file'" value="windows-pebinary-ext">Windows PE Binary
                        File Extension (windows-pebinary-ext)</option>
                    <option *ngIf="stixService.currentType == 'network-traffic'" value="http-request-ext">HTTP Request
                        Extension (http-request-ext)</option>
                    <option *ngIf="stixService.currentType == 'network-traffic'" value="icmp-ext">ICMP Extension
                        (icmp-ext)</option>
                    <option *ngIf="stixService.currentType == 'network-traffic'" value="socket-ext">Network Socket
                        Extension (socket-ext)</option>
                    <option *ngIf="stixService.currentType == 'network-traffic'" value="tcp-ext">TCP Extension
                        (tcp-ext)</option>
                    <option *ngIf="stixService.currentType == 'process'" value="windows-process-ext">Windows Process
                        Extension (windows-process-ext)</option>
                    <option *ngIf="stixService.currentType == 'process'" value="windows-service-ext">Windows Service
                        Extension (windows-service-ext)</option>
                    <option *ngIf="stixService.currentType == 'user-account'" value="unix-account-ext">Unix Account
                        Extension (unix-account-ext)</option>
                    <option *ngIf="stixService.currentType == 'x509-certificate'" value="x509_v3_extensions">
                        X.509 V3 Extensions (x509_v3_extensions)</option>
                    <option *ngIf="stixService.currentType == 'impact'" value="availability-ext">Availability Extension
                        (availability-ext)</option>
                    <option *ngIf="stixService.currentType == 'impact'" value="confidentiality-ext">Confidentiality Extension
                        (confidentiality-ext)</option>
                    <option *ngIf="stixService.currentType == 'impact'" value="external-ext">External Extension
                        (external-ext)</option>
                    <option *ngIf="stixService.currentType == 'impact'" value="integrity-ext">Integrity Extension
                        (integrity-ext)</option>
                    <option *ngIf="stixService.currentType == 'impact'" value="monetary-ext">Monetary Extension
                        (monetary-ext)</option>
                    <option *ngIf="stixService.currentType == 'impact'" value="physical-ext">Physical Extension
                        (physical-ext)</option>
                    <option *ngIf="stixService.currentType == 'impact'" value="traceability-ext">Traceability Extension
                        (traceability-ext)</option>
                </select>
                <button class="btn btn-fa-sm btn-primary config-button" type="button"
                    (click)="extensionModal(extensionBuilder)"
                    [disabled]="!validID() || this.currentExtension.extension_id == '' || (!predefined && this.currentExtension.extension_type == '')">
                    <fa-icon [icon]="faPlus"></fa-icon>
                    <!-- Configure  -->
                </button>
            </div>
            {{ this.errorMessage }}
            <ng-container *ngIf="!predefined && this.errorMessage == ''">
                <div class="input-group mb-2">
                    <span class="input-group-text">
                        <div class="tooltipright"><b>Extension Type</b>
                            <span class="toolttext">Extension types can currently only be configured one at a
                                time.</span>
                        </div>
                    </span>
                    <select type="text" class="form-control form-select" [(ngModel)]="this.currentExtension.extension_type"
                        name="extension_type" id="extension_type">
                        <ng-container *ngFor="let opt of type_options">
                            <option [value]="opt">
                                {{opt}}
                            </option>
                        </ng-container>
                    </select>
                </div>
            </ng-container>
        </div>
        <div>
            <!-- <button class="btn btn-sm btn-primary config-button" type="button" (click)="extensionModal(extensionBuilder)"
                [disabled]="!validID() || this.currentExtension.extension_id == '' || (!predefined && this.currentExtension.extension_type == '')">
                Configure
            </button> -->
        </div>
    </div>
</div>

<ng-template #extensionBuilder let-modal id="extensionBuilder" class="test">
    <div class="modal-header">
        <h2 class="modal-title" id="modal-title">{{this.currentExtension.extension_type}}</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"></span>
        </button>
    </div>
    <div class="modal-body extensions-modal-body">
        <!--<div class="row">
                <div class="col-12 text-center mb-3">
                    <h3 class="stix-pattern-builder-steps">Configure {{this.currentExtension.extension_type}}</h3>
                </div>
            </div>-->

        <ng-template [ngIf]="!predefined" [ngIfElse]="elseBlock">
            <!-- Property Extensions -->
            <!-- <ng-template [ngIf]="this.currentExtension.extension_type == 'Property Extension'"
                [ngIfElse]="propElseBlock">
                <div class="col-md-12">
                    <h4>Add your properties here - be sure to add them in the exact order</h4>
                    <hr>
                    <div class="input-group mb-2 col-11">
                        <span class="input-group-text">
                            <b>Property</b>
                        </span>
                        <input class="form-control" [(ngModel)]="property" type="text">
                        <span class="tab"></span>
                    </div>
                    <div class="input-group mb-2 col-12">
                        <span class="input-group-text">
                            <b>Value</b>
                        </span>
                        <input class="form-control" [(ngModel)]="value" type="text">
                        <span class="tab"></span>
                        <div class="col-1">
                            <button class="btn btn-sm btn-primary " type="button" (click)="addProperty()"
                                [disabled]="this.property == '' || this.value == ''">
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="this.currentExtension.extensions.size > 0" class="col-12 text-start mt-3">
                    <h4>Your Properties</h4>
                    <hr>
                    <div class="form-group mt-2 tab">
                        <li *ngFor="let x of this.currentExtension.extensions.keys()">
                            {{x}}: {{this.currentExtension.extensions.get(x)}}
                            <button class="btn btn-sm btn-danger " type="button" (click)="deleteProperty(x)"
                                style="height:40px;width:auto;background-color:rgb(210, 0, 0)">
                                Delete
                            </button>
                        </li>
                    </div>
                </div>
            </ng-template>

            <!-- Toplevel Property Extensions --
            <ng-template #propElseBlock> -->
            <div class="col-md-12">
                <h4>Add your properties here - be sure to add them in the exact order</h4>
                <hr>
                <!-- <ng-container *ngFor="let prop of toplevel_props">
                        <div class="input-group mb-2">
                            <span class="input-group-text">
                                <b>{{toUpper(prop)}}</b>
                            </span>
                            <input class="form-control" [(ngModel)]="this.currentExtension[prop]" type="text">
                        </div>
                    </ng-container> -->
                <!-- <ng-container *ngIf="!this.stixService.customObjReadOnly"> -->
                <span class="tab"></span>
                <h4>Create Custom Property</h4>
                <div class="row">
                    <div class="col-7">
                        <div class="input-group mb-2">
                            <span class="input-group-text">
                                <b>Property Name</b>
                            </span>
                            <ng-template [ngIf]="this.currentExtension.extension_type == 'Property Extension'"
                                [ngIfElse]="propElseBlock">
                                <input class="form-control" [(ngModel)]="customProp['name']" type="text">
                                <ng-container class="mb-2" *ngIf="customProp['name'] != '' && !validPropertyName()">
                                    <br><span class="tab">{{nameError}}</span>
                                </ng-container>
                            </ng-template>
                            <ng-template #propElseBlock>
                                <input class="form-control" [(ngModel)]="customProp['name']" type="text">
                                <select class="form-control form-select" [(ngModel)]="customProp['name']" type="text">
                                    <ng-container *ngFor="let prop of toplevel_props">
                                        <option [value]=prop>{{prop}}</option>
                                    </ng-container>
                                </select>
                                <ng-container class="mb-2" *ngIf="customProp['name'] != '' && !validPropertyName()">
                                    <br><span class="tab">{{nameError}}</span>
                                </ng-container>
                            </ng-template>
                            <!-- <input class="form-control" [(ngModel)]="customProp['name']" type="text"> -->
                        </div>
                        <div class="mb-2"
                            *ngIf="this.currentExtension['name'] != '' && this.modalError.get('customName')">
                            <span class="tab">
                                {{this.modalError.get('customName')}}
                            </span>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="input-group mb-2">
                            <span class="input-group-text">
                                <b>Property Type</b>
                            </span>
                            <select type="text" class="form-control form-select" [(ngModel)]="customProp['type']">
                                <option value=""></option>
                                <option value="text">Text</option>
                                <option value="boolean">Boolean</option>
                                <option value="integer">Integer</option>
                                <option value="datetime">Datetime</option>
                                <option value="array">Array</option>
                                <option value="dict">Dictionary</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-1">
                        <button class="btn btn-fa-sm btn-primary " type="button"
                            (click)="createProperty(collection)" [disabled]="customProp['type'] == '' || customProp['name'] == ''
                                    || ((customProp['type'] == 'text' && customProp['text'] == '') 
                                        || (customProp['type'] == 'boolean' && customProp['boolean'] == '')
                                        || (customProp['type'] == 'integer' && customProp['integer'] == '')
                                        || (customProp['type'] == 'datetime' && customProp['datetime'] == ''))">
                            <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                            <!-- Add -->
                        </button>
                    </div>
                </div>
                <ng-container [ngSwitch]="this.customProp['type']">
                    <!-- Mappings are used for each type (text vs boolean vs etc) so that state is 
                                saved if they switch to a previous type before submitting -->
                    <div *ngSwitchCase="'text'" class="row">
                        <div class="input-group mb-2 col-11">
                            <span class="input-group-text">
                                <b>Property Value</b>
                            </span>
                            <textarea class="form-control" [(ngModel)]="customProp['text']" type="text"></textarea>
                        </div>
                    </div>
                    <div *ngSwitchCase="'boolean'" class="row">
                        <div class="input-group mb-2 col-6">
                            <span class="input-group-text">
                                <b>Property Value</b>
                            </span>
                            <select class="form-control form-select dropdown" [(ngModel)]="customProp['boolean']">
                                <option value="" selected></option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </div>
                    </div>
                    <div *ngSwitchCase="'integer'" class="row">
                        <div class="input-group mb-2 col-6">
                            <span class="input-group-text">
                                <b>Property Value</b>
                            </span>
                            <input class="form-control" [(ngModel)]="customProp['integer']" type="number">
                        </div>
                    </div>
                    <div *ngSwitchCase="'datetime'" class="row">
                        <div class="input-group mb-2 col-6">
                            <span class="input-group-text">
                                <b>Property Value</b>
                            </span>
                            <input class="form-control" [(ngModel)]="customProp['datetime']"
                                [owlDateTimeTrigger]="dtPickerCustom" [owlDateTime]="dtPickerCustom">
                            <owl-date-time #dtPickerCustom></owl-date-time>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="toplevel && toplevel_props.length == 0"> ERROR: There are no Extension Properties
                    associated with this Extension Definition</ng-container>


                <!-- Messed up logic first time - this is only present when there is a custom property -->
                <ng-container
                    *ngIf="!((!this.customStringPropKeys || this.customStringPropKeys.length == 0) && (!this.customArrPropKeys || this.customArrPropKeys.length == 0))">
                    <span class="tab"></span>
                    <h4>Your Custom Properties</h4>
                    <hr>
                    <div class="row" id="questions">
                        <span class="tab">
                            <div class="form-group mt-2">
                                <li *ngFor="let x of this.customStringPropKeys">
                                    {{x}}: {{this.currentExtension[x]}}
                                    <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                        (click)="deleteExtensionProperty(x)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </div>
                        </span>
                    </div>
                    <div *ngIf="this.customArrPropKeys && this.customArrPropKeys.length > 0" class="col-12 p-0">
                        <div class="form-group mt-2 bump">
                            <accordion>
                                <accordion-group *ngFor="let x of this.customArrPropKeys" [heading]="x"
                                    [panelClass]="'accordion-panel-class'">
                                    <ng-container>
                                        <div *ngIf="!this.stixService.customObjReadOnly">
                                            <button type="button" class="btn btn-fa btn-primary"
                                                (click)="this.editCollection(x, collection)">
                                                <fa-icon [icon]="faEdit"></fa-icon>
                                            </button>
                                            <button type="button" class="btn btn-fa btn-red" style="margin-left: 10px;"
                                                (click)="deleteExtensionProperty(x)">
                                                <fa-icon [icon]="faTrash"></fa-icon>
                                            </button>
                                        </div>
                                    </ng-container>
                                    <pre>{{ this.currentExtension[x] | json }}</pre>
                                </accordion-group>
                            </accordion>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-template>

        <ng-template #elseBlock>
            <ng-container [ngSwitch]="this.currentExtension.extension_id">
                <!-- Archive File Extension -->
                <div *ngSwitchCase="'archive-ext'" class="col-md-12">
                    <!--<h4>Add your properties here - be sure to add them in the exact order</h4>-->
                    <span class="tab"></span>

                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Comment</b>
                        </span>
                        <textarea class="form-control" [(ngModel)]="this.currentExtension.comment"
                            type="text"></textarea>
                    </div>

                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Contains Refs*</b>
                        </span>
                        <select class="form-control form-select dropdown" [(ngModel)]="this.currentArrString['ext_contains_refs']">
                            <option value="" selected></option>
                            <ng-container *ngFor="let opt of this.stixService.bundle.objects">
                                <ng-container *ngIf="opt.id.match(objectRegex)">
                                    <option *ngIf="['file','directory'].indexOf(opt.id.match(objectRegex)[1]) != -1"
                                        [value]="opt['id']">
                                        {{opt.id}}
                                    </option>
                                </ng-container>
                            </ng-container>
                        </select>
                        <input class="form-control" [(ngModel)]="this.currentArrString['ext_contains_refs']"
                            type="text">
                        <span class="tab"></span>
                        <div class="col-1">
                            <button class="btn btn-fa-sm btn-primary " type="button"
                                (click)="addString(this.currentArrString['ext_contains_refs'], 'ext_contains_refs')"
                                [disabled]="this.currentArrString['ext_contains_refs'] == undefined || this.currentArrString['ext_contains_refs'] == '' || !validType(['file','directory'], this.currentArrString['ext_contains_refs'], 'ext_contains_refs')">
                                <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                <!-- Add -->
                            </button>
                        </div>
                    </div>

                    <div class="mb-2"
                        *ngIf="this.currentArrString['ext_contains_refs'] != '' && this.validationError.get('ext_contains_refs')">
                        <span class="tab">
                            {{this.validationError.get('ext_contains_refs')}}
                        </span>
                    </div>

                    <div class="row" id="questions">
                        <span class="tab">
                            <div class="form-group mt-2">
                                <li *ngFor="let x of this.stixService.stringArrays.get('ext_contains_refs')">
                                    {{x}}
                                    <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                        (click)="deleteString(x, 'ext_contains_refs')">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </div>
                        </span>
                    </div>
                </div>

                <!-- NTFS File Extension -->
                <div *ngSwitchCase="'ntfs-ext'" class="col-md-12">
                    <!--<h4>Add your properties here - be sure to add them in the exact order</h4>-->
                    <span class="tab"></span>

                    <ng-container *ngIf="this.page == 'base'">
                        <div class="input-group mb-2">
                            <span class="input-group-text">
                                <b>Security ID</b>
                            </span>
                            <input class="form-control" [(ngModel)]="this.currentExtension.sid" type="text">
                        </div>

                        <div class="input-group mb-2">
                            <span class="input-group-text">
                                <b>Alternate Data Streams</b>
                            </span>
                            <input class="form-control"
                                value="Use the button on the right to create an Alternate Data Stream" type="text"
                                readonly>
                            <span class="tab"></span>
                            <div class="col-1">
                                <button class="btn btn-sm btn-primary add-button" type="button"
                                    (click)="creatingSubObject('alternate_data_streams')">
                                    <fa-icon [icon]="faPlusCircle" class="pe-1"></fa-icon>
                                    Create
                                </button>
                            </div>
                        </div>

                        <div class="form-group mt-2">
                            <accordion>
                                <accordion-group *ngFor="let x of this.currentExtension.alternate_data_streams"
                                    [heading]="x['name']" [panelClass]="'accordion-panel-class'">
                                    <ng-container>
                                        <div>
                                            <button type="button" class="btn btn-fa btn-primary"
                                                (click)="this.editSubObject(x, 'alternate_data_streams')">
                                                <fa-icon [icon]="faEdit"></fa-icon>
                                            </button>
                                            <button type="button" class="btn btn-fa btn-red" style="margin-left: 10px;"
                                                (click)="deleteSubObject(x, 'alternate_data_streams')">
                                                <fa-icon [icon]="faTrash"></fa-icon>
                                            </button>
                                        </div>
                                    </ng-container>
                                    <pre>{{ x | json }}</pre>
                                </accordion-group>
                            </accordion>
                        </div>

                        <div class="row" id="questions">
                            <span class="tab">
                                <div class="form-group mt-2">
                                    <li *ngFor="let x of this.stixService.stringArrays.get('alternate_data_streams')">
                                        {{x}}
                                        <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                            (click)="deleteString(x, 'alternate_data_streams')">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </li>
                                </div>
                            </span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="this.page == 'creatingStream'">
                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Name*</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['name']" type="text">
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Size</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['size']" type="number"
                                        (change)="positive(this.subObject['size'], 'size')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['size'] != '' && this.validationError.get('size')">
                                    <span class="tab">
                                        {{this.validationError.get('size')}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <div class="input-group mb-2">
                                    <span class="input-group-text"><b>Hash Type</b>
                                    </span>
                                    <select type="text" class="form-control form-select"
                                        [(ngModel)]="this.currentArrString['hash_type']">
                                        <option *ngFor="let item of hashOptions" [value]="item.value">{{item.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-7">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Hash Value</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.currentArrString['hash_value']"
                                        type="text">
                                </div>
                            </div>
                            <div class="col-1">
                                <button class="btn btn-fa-sm btn-primary " type="button"
                                    (click)="addHash(this.currentArrString['hash_type'], this.currentArrString['hash_value'])"
                                    [disabled]="this.currentArrString['hash_value'] == undefined || this.currentArrString['hash_value'] == ''">
                                    <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                    <!-- Add -->
                                </button>
                            </div>
                        </div>

                        <div class="row"
                            *ngIf="this.currentArrString['hash_type'] != '' && this.currentArrString['hash_value'] != '' && this.validationError.get('hashes')">
                            <span class="tab">
                                {{this.validationError.get('hashes')}}
                            </span>
                        </div>

                        <div class="row" id="questions">
                            <span class="tab">
                                <div class="form-group mt-2">
                                    <li *ngFor="let x of this.hashes.keys()">
                                        {{x}}: {{this.hashes.get(x)}}
                                        <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                            (click)="deleteHash(x)">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </li>
                                </div>
                            </span>
                        </div>
                    </ng-container>
                </div>

                <!-- PDF File Extension -->
                <div *ngSwitchCase="'pdf-ext'" class="col-md-12">
                    <span class="tab"></span>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Version</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.version" type="text">
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Is Optimized</b>
                                </span>
                                <select class="form-control form-select dropdown" [(ngModel)]="this.currentExtension.is_optimized">
                                    <option value="" selected></option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>First Document ID</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.pdfid0" type="text">
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Second Document ID</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.pdfid1" type="text">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2 col-11">
                                <span class="input-group-text">
                                    <b>Document Info Key</b>
                                </span>
                                <input class="form-control"
                                    [(ngModel)]="this.currentArrString['document_info_dict_key']" type="text">
                                <span class="tab"></span>
                            </div>
                            <div class="input-group mb-2 col-12">
                                <span class="input-group-text">
                                    <b>Document Info Value</b>
                                </span>
                                <input class="form-control"
                                    [(ngModel)]="this.currentArrString['document_info_dict_value']" type="text">
                                <span class="tab"></span>
                                <div class="col-1">
                                    <button class="btn btn-fa-sm btn-primary " type="button"
                                        (click)="addDict('document_info_dict')"
                                        [disabled]="this.currentArrString['document_info_dict_key'] == undefined || this.currentArrString['document_info_dict_value'] == undefined || this.currentArrString['document_info_dict_key'] == '' || this.currentArrString['document_info_dict_value'] == ''">
                                        <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                        <!-- Add -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row bump" id="questions">
                        <span class="tab">
                            <div class="form-group mt-2">
                                <li *ngFor="let x of this.stixService.stringArrays.get('document_info_dict')">
                                    {{x}}
                                    <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                        (click)="deleteString(x, 'document_info_dict')">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </div>
                        </span>
                    </div>
                </div>

                <!-- Raster Image File Extension -->
                <div *ngSwitchCase="'raster-image-ext'" class="col-md-12">
                    <span class="tab"></span>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Image Height</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.image_height"
                                    type="number">
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Image Width</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.image_width"
                                    type="number">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Bits Per Pixel</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.bits_per_pixel"
                                    type="number">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2 col-11">
                                <span class="input-group-text">
                                    <b>EXIF Tags Key</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentArrString['exif_tags_key']"
                                    type="text">
                                <span class="tab"></span>
                            </div>
                            <div class="input-group mb-2 col-12">
                                <span class="input-group-text">
                                    <b>EXIF Tags Value</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentArrString['exif_tags_value']"
                                    type="text">
                                <span class="tab"></span>
                                <div class="col-1">
                                    <button class="btn btn-fa-sm btn-primary " type="button"
                                        (click)="addDict('exif_tags')"
                                        [disabled]="this.currentArrString['exif_tags_key'] == undefined || this.currentArrString['exif_tags_value'] == undefined || this.currentArrString['exif_tags_key'] == '' || this.currentArrString['exif_tags_value'] == ''">
                                        <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                        <!-- Add -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row bump" id="questions">
                        <span class="tab">
                            <div class="form-group mt-2">
                                <li *ngFor="let x of this.stixService.stringArrays.get('exif_tags')">
                                    {{x}}
                                    <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                        (click)="deleteString(x, 'exif_tags')">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </div>
                        </span>
                    </div>
                </div>

                <!-- Windows PE Binary File Extension -->
                <div *ngSwitchCase="'windows-pebinary-ext'" class="col-md-12">
                    <span class="tab"></span>

                    <ng-container *ngIf="this.page == 'base'">
                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>PE Binary Type*</b>
                                    </span>
                                    <select class="form-control form-select dropdown" [(ngModel)]="this.currentExtension.pe_type">
                                        <option value="" selected></option>
                                        <option value="dll">dll</option>
                                        <option value="exe">exe</option>
                                        <option value="sys">sys</option>
                                    </select>
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Special Import Hash</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.currentExtension.imphash" type="text">
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Machine Hex</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.currentExtension.machine_hex"
                                        type="text" (change)="isHex(this.currentExtension.machine_hex, 'machine_hex')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.currentExtension.machine_hex != '' && this.validationError.get('machine_hex')">
                                    <span class="tab">
                                        {{this.validationError.get('machine_hex')}}
                                    </span>
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Number Of Sections</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.currentExtension.number_of_sections "
                                        type="number"
                                        (change)="positive(this.currentExtension.number_of_sections, 'number_of_sections')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.currentExtension.number_of_sections != '' && this.validationError.get('number_of_sections')">
                                    <span class="tab">
                                        {{this.validationError.get('number_of_sections')}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Time Date Stamp</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.currentExtension.time_date_stamp"
                                        [owlDateTimeTrigger]="dtPicker1" [owlDateTime]="dtPicker1">
                                    <owl-date-time #dtPicker1></owl-date-time>
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Pointer to Symbol Table Hex</b>
                                    </span>
                                    <input class="form-control"
                                        [(ngModel)]="this.currentExtension.pointer_to_symbol_table_hex " type="text"
                                        (change)="isHex(this.currentExtension.pointer_to_symbol_table_hex , 'pointer_to_symbol_table_hex ')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.currentExtension.pointer_to_symbol_table_hex  != '' && this.validationError.get('pointer_to_symbol_table_hex ')">
                                    <span class="tab">
                                        {{this.validationError.get('pointer_to_symbol_table_hex ')}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Number of Symbols</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.currentExtension.number_of_symbols"
                                        type="number"
                                        (change)="positive(this.currentExtension.number_of_symbols, 'number_of_symbols')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.currentExtension.number_of_symbols != '' && this.validationError.get('number_of_symbols')">
                                    <span class="tab">
                                        {{this.validationError.get('number_of_symbols')}}
                                    </span>
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Size of Optional Header</b>
                                    </span>
                                    <input class="form-control"
                                        [(ngModel)]="this.currentExtension.size_of_optional_header " type="number"
                                        (change)="positive(this.currentExtension.size_of_optional_header, 'size_of_optional_header')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.currentExtension.size_of_optional_header != '' && this.validationError.get('size_of_optional_header')">
                                    <span class="tab">
                                        {{this.validationError.get('size_of_optional_header')}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Characteristics Hex</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.currentExtension.characteristics_hex"
                                        type="text"
                                        (change)="isHex(this.currentExtension.characteristics_hex, 'characteristics_hex')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.currentExtension.characteristics_hex != '' && this.validationError.get('characteristics_hex')">
                                    <span class="tab">
                                        {{this.validationError.get('characteristics_hex')}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <!-- Hashes -->
                        <div class="row">
                            <div class="col-4">
                                <div class="input-group mb-2">
                                    <span class="input-group-text"><b>Hash Type</b>
                                    </span>
                                    <select type="text" class="form-control form-select"
                                        [(ngModel)]="this.currentArrString['file_header_hashes_hash_type']">
                                        <option *ngFor="let item of hashOptions" [value]="item.value">{{item.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Hash Value</b>
                                    </span>
                                    <input class="form-control"
                                        [(ngModel)]="this.currentArrString['file_header_hashes_hash_value']"
                                        type="text">
                                </div>
                            </div>
                            <div class="col-1"></div>
                            <div class="col-1">
                                <button class="btn btn-fa-sm btn-primary " type="button"
                                    (click)="addHash(this.currentArrString['file_header_hashes_hash_type'], this.currentArrString['file_header_hashes_hash_value'], 'file_header_hashes')"
                                    [disabled]="this.currentArrString['file_header_hashes_hash_value'] == undefined || this.currentArrString['file_header_hashes_hash_value'] == ''">
                                    <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                    <!-- Add -->
                                </button>
                            </div>
                        </div>

                        <div class="row"
                            *ngIf="this.currentArrString['file_header_hashes_hash_type'] != '' && this.currentArrString['file_header_hashes_hash_value'] != '' && this.validationError.get('file_header_hashes')">
                            <span class="tab">
                                {{this.validationError.get('file_header_hashes')}}
                            </span>
                        </div>

                        <div class="row" id="questions">
                            <span class="tab">
                                <div class="form-group mt-2">
                                    <li *ngFor="let x of this.currentExtension.file_header_hashes.keys()">
                                        {{x}}: {{this.currentExtension.file_header_hashes.get(x)}}
                                        <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                            (click)="deleteHash(x)">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </li>
                                </div>
                            </span>
                        </div>

                        <!-- Optional Header -->
                        <div class="input-group mb-2">
                            <span class="input-group-text">
                                <b>Optional Header</b>
                            </span>
                            <input class="form-control" value="Use the button on the right to create an Optional Header"
                                type="text" readonly>
                            <span class="tab"></span>
                            <div class="bump">
                                <button class="btn btn-sm btn-primary add-button" type="button"
                                    (click)="creatingSubObject('optional_header')" [disabled]="isError()">
                                    <fa-icon [icon]="faPlusCircle" class="pe-1"></fa-icon>
                                    Create
                                </button>
                            </div>
                        </div>

                        <div class="form-group mt-2">
                            <accordion>
                                <accordion-group *ngIf="this.currentExtension.optional_header != undefined"
                                    heading="Optional Header" [panelClass]="'accordion-panel-class'">
                                    <ng-container>
                                        <div>
                                            <button type="button" class="btn btn-fa btn-primary"
                                                (click)="this.editSubObject(this.currentExtension.optional_header, 'optional_header')">
                                                <fa-icon [icon]="faEdit"></fa-icon>
                                            </button>
                                            <button type="button" class="btn btn-fa btn-red" style="margin-left: 10px;"
                                                (click)="deleteSubObject(this.currentExtension.optional_header, 'optional_header')">
                                                <fa-icon [icon]="faTrash"></fa-icon>
                                            </button>
                                        </div>
                                    </ng-container>
                                    <pre>{{ this.currentExtension.optional_header | json }}</pre>
                                </accordion-group>
                            </accordion>
                        </div>

                        <!-- Sections -->
                        <div class="input-group mb-2">
                            <span class="input-group-text">
                                <b>Sections</b>
                            </span>
                            <input class="form-control" value="Use the button on the right to create a Section"
                                type="text" readonly>
                            <span class="tab"></span>
                            <div class="bump">
                                <button class="btn btn-sm btn-primary add-button" type="button"
                                    (click)="creatingSubObject('sections')" [disabled]="isError()">
                                    <fa-icon [icon]="faPlusCircle" class="pe-1"></fa-icon>
                                    Create
                                </button>
                            </div>
                        </div>

                        <div *ngIf="this.currentExtension.sections && this.currentExtension.sections.length > 0"
                            class="form-group mt-2">
                            <accordion>
                                <accordion-group *ngFor="let x of this.currentExtension.sections" [heading]="x['name']"
                                    [panelClass]="'accordion-panel-class'">
                                    <ng-container>
                                        <div>
                                            <button type="button" class="btn btn-fa btn-primary"
                                                (click)="this.editSubObject(x, 'sections')">
                                                <fa-icon [icon]="faEdit"></fa-icon>
                                            </button>
                                            <button type="button" class="btn btn-fa btn-red" style="margin-left: 10px;"
                                                (click)="deleteSubObject(x, 'sections')">
                                                <fa-icon [icon]="faTrash"></fa-icon>
                                            </button>
                                        </div>
                                    </ng-container>
                                    <pre>{{ x | json }}</pre>
                                </accordion-group>
                            </accordion>
                        </div>
                    </ng-container>

                    <!-- Creating Header -->
                    <ng-container *ngIf="this.page == 'creatingHeader'">
                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Magic Hex</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['magic_hex']" type="text"
                                        (change)="isHex(this.subObject['magic_hex'], 'magic_hex')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['magic_hex'] != '' && this.validationError.get('magic_hex')">
                                    <span class="tab">
                                        {{this.validationError.get('magic_hex')}}
                                    </span>
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Major Linker Version</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['major_linker_version']"
                                        type="number">
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Minor Linker Version</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['minor_linker_version']"
                                        type="number">
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Size of Code</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['size_of_code']"
                                        type="number"
                                        (change)="positive(this.subObject['size_of_code'], 'size_of_code')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['size_of_code'] != '' && this.validationError.get('size_of_code')">
                                    <span class="tab">
                                        {{this.validationError.get('size_of_code')}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Size of Initialized Data</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['size_of_initialized_data']"
                                        type="number"
                                        (change)="positive(this.subObject['size_of_initialized_data'], 'size_of_initialized_data')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['size_of_initialized_data'] != '' && this.validationError.get('size_of_initialized_data')">
                                    <span class="tab">
                                        {{this.validationError.get('size_of_initialized_data')}}
                                    </span>
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Size of Unitialized Data</b>
                                    </span>
                                    <input class="form-control"
                                        [(ngModel)]="this.subObject['size_of_uninitialized_data']" type="number"
                                        (change)="positive(this.subObject['size_of_uninitialized_data'], 'size_of_uninitialized_data')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['size_of_uninitialized_data'] != '' && this.validationError.get('size_of_uninitialized_data')">
                                    <span class="tab">
                                        {{this.validationError.get('size_of_uninitialized_data')}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Address of Entry Point</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['address_of_entry_point']"
                                        type="number">
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Base of Code</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['base_of_code']"
                                        type="number">
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Base of Data</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['base_of_data']"
                                        type="number">
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Image Base</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['image_base']"
                                        type="number">
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Section Alignment</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['section_alignment']"
                                        type="number">
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>File Alignment</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['file_alignment']"
                                        type="number">
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Major OS Version</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['major_os_version']"
                                        type="number">
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Minor OS Version</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['minor_os_version']"
                                        type="number">
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Major Image Version</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['major_image_version']"
                                        type="number">
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Minor Image Version</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['minor_image_version']"
                                        type="number">
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Major Subsystem Version</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['major_subsystem_version']"
                                        type="number">
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Minor Subsystem Version</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['minor_subsystem_version']"
                                        type="number">
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Win32 Version Value Hex</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['win32_version_value_hex']"
                                        type="text"
                                        (change)="isHex(this.subObject['win32_version_value_hex'], 'win32_version_value_hex')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['win32_version_value_hex'] != '' && this.validationError.get('win32_version_value_hex')">
                                    <span class="tab">
                                        {{this.validationError.get('win32_version_value_hex')}}
                                    </span>
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Size of Image</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['size_of_image']"
                                        type="number"
                                        (change)="positive(this.subObject['size_of_image'], 'size_of_image')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['size_of_image'] != '' && this.validationError.get('size_of_image')">
                                    <span class="tab">
                                        {{this.validationError.get('size_of_image')}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Size of Headers</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['size_of_headers']"
                                        type="number"
                                        (change)="positive(this.subObject['size_of_headers'], 'size_of_headers')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['size_of_headers'] != '' && this.validationError.get('size_of_headers')">
                                    <span class="tab">
                                        {{this.validationError.get('size_of_headers')}}
                                    </span>
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Checksum Hex</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['checksum_hex']" type="text"
                                        (change)="isHex(this.subObject['checksum_hex'], 'checksum_hex')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['checksum_hex'] != '' && this.validationError.get('checksum_hex')">
                                    <span class="tab">
                                        {{this.validationError.get('checksum_hex')}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Subsystem Hex</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['subsystem_hex']"
                                        type="text" (change)="isHex(this.subObject['subsystem_hex'], 'subsystem_hex')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['subsystem_hex'] != '' && this.validationError.get('subsystem_hex')">
                                    <span class="tab">
                                        {{this.validationError.get('subsystem_hex')}}
                                    </span>
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>DLL Characteristics Hex</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['dll_characteristics_hex']"
                                        type="text"
                                        (change)="isHex(this.subObject['dll_characteristics_hex'], 'dll_characteristics_hex')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['dll_characteristics_hex'] != '' && this.validationError.get('dll_characteristics_hex')">
                                    <span class="tab">
                                        {{this.validationError.get('dll_characteristics_hex')}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Size of Stack Reserve</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['size_of_stack_reserve']"
                                        type="number"
                                        (change)="positive(this.subObject['size_of_stack_reserve'], 'size_of_stack_reserve')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['size_of_stack_reserve'] != '' && this.validationError.get('size_of_stack_reserve')">
                                    <span class="tab">
                                        {{this.validationError.get('size_of_stack_reserve')}}
                                    </span>
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Size of Stack Commit</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['size_of_stack_commit']"
                                        type="number"
                                        (change)="positive(this.subObject['size_of_stack_commit'], 'size_of_stack_commit')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['size_of_stack_commit'] != '' && this.validationError.get('size_of_stack_commit')">
                                    <span class="tab">
                                        {{this.validationError.get('size_of_stack_commit')}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Size of Heap Reserve</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['size_of_heap_reserve']"
                                        type="number"
                                        (change)="positive(this.subObject['size_of_heap_reserve'], 'size_of_heap_reserve')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['size_of_heap_reserve'] != '' && this.validationError.get('size_of_heap_reserve')">
                                    <span class="tab">
                                        {{this.validationError.get('size_of_heap_reserve')}}
                                    </span>
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Size of Heap Commit</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['size_of_heap_commit']"
                                        type="number"
                                        (change)="positive(this.subObject['size_of_heap_commit'], 'size_of_heap_commit')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['size_of_heap_commit'] != '' && this.validationError.get('size_of_heap_commit')">
                                    <span class="tab">
                                        {{this.validationError.get('size_of_heap_commit')}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Loader Flags Hex</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['loader_flags_hex']"
                                        type="text"
                                        (change)="isHex(this.subObject['loader_flags_hex'], 'loader_flags_hex')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['loader_flags_hex'] != '' && this.validationError.get('loader_flags_hex')">
                                    <span class="tab">
                                        {{this.validationError.get('loader_flags_hex')}}
                                    </span>
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Number of RVA and Sizes</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['number_of_rva_and_sizes']"
                                        type="number">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <div class="input-group mb-2">
                                    <span class="input-group-text"><b>Hash Type</b>
                                    </span>
                                    <select type="text" class="form-control form-select"
                                        [(ngModel)]="this.currentArrString['hash_type']">
                                        <option *ngFor="let item of hashOptions" [value]="item.value">{{item.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-7">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Hash Value</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.currentArrString['hash_value']"
                                        type="text">
                                </div>
                            </div>
                            <div class="col-1">
                                <button class="btn btn-fa-sm btn-primary " type="button"
                                    (click)="addHash(this.currentArrString['hash_type'], this.currentArrString['hash_value'])"
                                    [disabled]="this.currentArrString['hash_value'] == undefined || this.currentArrString['hash_value'] == ''">
                                    <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                    <!-- Add -->
                                </button>
                            </div>
                        </div>

                        <div class="row"
                            *ngIf="this.currentArrString['hash_type'] != '' && this.currentArrString['hash_value'] != '' && this.validationError.get('hashes')">
                            <span class="tab">
                                {{this.validationError.get('hashes')}}
                            </span>
                        </div>

                        <div class="row" id="questions">
                            <span class="tab">
                                <div class="form-group mt-2">
                                    <li *ngFor="let x of this.hashes.keys()">
                                        {{x}}: {{this.hashes.get(x)}}
                                        <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                            (click)="deleteHash(x)">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </li>
                                </div>
                            </span>
                        </div>
                    </ng-container>

                    <!-- Creating Section -->
                    <ng-container *ngIf="this.page == 'creatingSection'">
                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Name*</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['name']" type="text">
                                </div>
                            </div>
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Size</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['size']" type="number"
                                        (change)="positive(this.subObject['size'], 'size')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['size'] != '' && this.validationError.get('size')">
                                    <span class="tab">
                                        {{this.validationError.get('size')}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row bump">
                            <div class="p-0 col-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Entropy</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.subObject['entropy']" type="number"
                                        (change)="entropy(this.subObject['entropy'], 'entropy')">
                                </div>
                                <div class="mb-2"
                                    *ngIf="this.subObject['entropy'] != '' && this.validationError.get('entropy')">
                                    <span class="tab">
                                        {{this.validationError.get('entropy')}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <div class="input-group mb-2">
                                    <span class="input-group-text"><b>Hash Type</b>
                                    </span>
                                    <select type="text" class="form-control form-select"
                                        [(ngModel)]="this.currentArrString['hash_type']">
                                        <option *ngFor="let item of hashOptions" [value]="item.value">{{item.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-7">
                                <div class="input-group mb-2">
                                    <span class="input-group-text">
                                        <b>Hash Value</b>
                                    </span>
                                    <input class="form-control" [(ngModel)]="this.currentArrString['hash_value']"
                                        type="text">
                                </div>
                            </div>
                            <div class="col-1">
                                <button class="btn btn-fa-sm btn-primary " type="button"
                                    (click)="addHash(this.currentArrString['hash_type'], this.currentArrString['hash_value'])"
                                    [disabled]="this.currentArrString['hash_value'] == undefined || this.currentArrString['hash_value'] == ''">
                                    <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                    <!-- Add -->
                                </button>
                            </div>
                        </div>

                        <div class="row"
                            *ngIf="this.currentArrString['hash_type'] != '' && this.currentArrString['hash_value'] != '' && this.validationError.get('hashes')">
                            <span class="tab">
                                {{this.validationError.get('hashes')}}
                            </span>
                        </div>

                        <div class="row" id="questions">
                            <span class="tab">
                                <div class="form-group mt-2">
                                    <li *ngFor="let x of this.hashes.keys()">
                                        {{x}}: {{this.hashes.get(x)}}
                                        <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                            (click)="deleteHash(x)">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </li>
                                </div>
                            </span>
                        </div>
                    </ng-container>
                </div>

                <!-- HTTP Request Extension (Network Traffic)-->
                <div *ngSwitchCase="'http-request-ext'" class="col-md-12">
                    <span class="tab"></span>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Request Method*</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.request_method"
                                    type="string"
                                    (change)="isLower(this.currentExtension.request_method, 'request_method')">
                            </div>
                            <div class="mb-2"
                                *ngIf="this.currentExtension.request_method != '' && this.validationError.get('request_method')">
                                <span class="tab">
                                    {{this.validationError.get('request_method')}}
                                </span>
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Request Value*</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.request_value"
                                    type="string">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Request Version</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.request_version"
                                    type="string"
                                    (change)="isLower(this.currentExtension.request_version, 'request_version')">
                            </div>
                            <div class="mb-2"
                                *ngIf="this.currentExtension.request_version != '' && this.validationError.get('request_version')">
                                <span class="tab">
                                    {{this.validationError.get('request_version')}}
                                </span>
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Message Body Length</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.message_body_length"
                                    type="number">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Message Body Data Ref</b>
                                </span>
                                <select class="form-control dropdown form-select"
                                    [(ngModel)]="this.currentExtension.message_body_data_ref">
                                    <option value="" selected></option>
                                    <ng-container *ngFor="let opt of this.stixService.bundle.objects">
                                        <ng-container *ngIf="opt.id.match(objectRegex)">
                                            <option *ngIf="['artifact'].indexOf(opt.id.match(objectRegex)[1]) != -1"
                                                [value]="opt['id']">
                                                {{opt.id}}
                                            </option>
                                        </ng-container>
                                    </ng-container>
                                </select>
                                <input class="form-control" [(ngModel)]="this.currentExtension.message_body_data_ref"
                                    type="string"
                                    (change)="validType(['artifact'], this.currentExtension.message_body_data_ref, 'message_body_data_ref')">
                            </div>
                            <div class="mb-2"
                                *ngIf="this.currentExtension.message_body_data_ref != '' && this.validationError.get('message_body_data_ref')">
                                <span class="tab">
                                    {{this.validationError.get('message_body_data_ref')}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2 col-11">
                                <span class="input-group-text">
                                    <b>Request Header Key</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentArrString['request_header_key']"
                                    type="text">
                                <span class="tab"></span>
                            </div>
                            <div class="input-group mb-2 col-12">
                                <span class="input-group-text">
                                    <b>Request Header Value</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentArrString['request_header_value']"
                                    type="text">
                                <span class="tab"></span>
                                <div class="col-1">
                                    <button class="btn btn-fa-sm btn-primary " type="button"
                                        (click)="addDict('request_header')"
                                        [disabled]="this.currentArrString['request_header_key'] == undefined || this.currentArrString['request_header_value'] == undefined || this.currentArrString['request_header_key'] == '' || this.currentArrString['request_header_value'] == ''">
                                        <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                        <!-- Add -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row bump" id="questions">
                        <span class="tab">
                            <div class="form-group mt-2">
                                <li *ngFor="let x of this.stixService.stringArrays.get('request_header')">
                                    {{x}}
                                    <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                        (click)="deleteString(x, 'request_header')">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </div>
                        </span>
                    </div>
                </div>

                <!-- ICMP Extension (Network Traffic)-->
                <div *ngSwitchCase="'icmp-ext'" class="col-md-12">
                    <span class="tab"></span>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>ICMP Type Hex*</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.icmp_type_hex"
                                    type="text" (change)="isHex(this.currentExtension.icmp_type_hex, 'icmp_type_hex')">
                            </div>
                            <div class="mb-2"
                                *ngIf="this.currentExtension.icmp_type_hex != '' && this.validationError.get('icmp_type_hex')">
                                <span class="tab">
                                    {{this.validationError.get('icmp_type_hex')}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>ICMP Code Hex*</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.icmp_code_hex"
                                    type="text" (change)="isHex(this.currentExtension.icmp_code_hex, 'icmp_code_hex')">
                            </div>
                            <div class="mb-2"
                                *ngIf="this.currentExtension.icmp_code_hex != '' && this.validationError.get('icmp_code_hex')">
                                <span class="tab">
                                    {{this.validationError.get('icmp_code_hex')}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Network Socket Extension (Network Traffic)-->
                <div *ngSwitchCase="'socket-ext'" class="col-md-12">
                    <span class="tab"></span>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Address Family*</b>
                                </span>
                                <select class="form-control dropdown form-select"
                                    [(ngModel)]="this.currentExtension.address_family">
                                    <option value="" selected></option>
                                    <option value="AF_UNSPEC">AF_UNSPEC</option>
                                    <option value="AF_INET">AF_INET</option>
                                    <option value="AF_IPX">AF_IPX</option>
                                    <option value="AF_APPLETALK">AF_APPLETALK</option>
                                    <option value="AF_NETBIOS">AF_NETBIOS</option>
                                    <option value="AF_INET6">AF_INET6</option>
                                    <option value="AF_IRDA">AF_IRDA</option>
                                    <option value="AF_BTH">AF_BTH</option>
                                </select>
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Socket Type</b>
                                </span>
                                <select class="form-control dropdown form-select" [(ngModel)]="this.currentExtension.socket_type">
                                    <option value="" selected></option>
                                    <option value="SOCK_STREAM">SOCK_STREAM</option>
                                    <option value="SOCK_DGRAM">SOCK_DGRAM</option>
                                    <option value="SOCK_RAW">SOCK_RAW</option>
                                    <option value="SOCK_RDM">SOCK_RDM</option>
                                    <option value="SOCK_SEQPACKET">SOCK_SEQPACKET</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Is Blocking</b>
                                </span>
                                <select class="form-control dropdown form-select" [(ngModel)]="this.currentExtension.is_blocking">
                                    <option value="" selected></option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Is Listening</b>
                                </span>
                                <select class="form-control dropdown form-select" [(ngModel)]="this.currentExtension.is_listening">
                                    <option value="" selected></option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Socket Descriptor</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.socket_descriptor"
                                    type="number"
                                    (change)="positive(this.currentExtension.socket_descriptor, 'socket_descriptor')">
                            </div>
                            <div class="mb-2"
                                *ngIf="this.currentExtension.socket_descriptor != '' && this.validationError.get('socket_descriptor')">
                                <span class="tab">
                                    {{this.validationError.get('socket_descriptor')}}
                                </span>
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Socket Handle</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.socket_handle"
                                    type="number">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-11">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Options Key</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentArrString['options_key']"
                                    type="text">
                                <span class="tab"></span>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Options Value</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentArrString['options_value']"
                                    type="number">
                                <span class="tab"></span>
                                <div class="col-1">
                                    <button class="btn btn-fa-sm-sm btn-primary " type="button"
                                        (click)="addDict('options')"
                                        [disabled]="this.currentArrString['options_value'] == undefined ||  this.currentArrString['options_value'] == '' || !positive(this.currentArrString['options_value'], 'options_value') || this.currentArrString['options_key'] == undefined || this.currentArrString['options_key'] == ''">
                                        <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                        <!-- Add -->
                                    </button>
                                </div>
                            </div>
                            <div class="mb-2"
                                *ngIf="this.currentArrString['options_value'] != '' && this.validationError.get('options_value')">
                                <span class="tab">
                                    {{this.validationError.get('options_value')}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row bump" id="questions">
                        <span class="tab">
                            <div class="form-group mt-2">
                                <li *ngFor="let x of this.stixService.stringArrays.get('options')">
                                    {{x}}
                                    <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                        (click)="deleteString(x, 'options')">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </div>
                        </span>
                    </div>
                </div>

                <!-- TCP Extension (Network Traffic)-->
                <div *ngSwitchCase="'tcp-ext'" class="col-md-12">
                    <span class="tab"></span>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>SRC Flags Hex</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.src_flags_hex"
                                    type="text" (change)="isHex(this.currentExtension.src_flags_hex, 'src_flags_hex')">
                            </div>
                            <div class="mb-2"
                                *ngIf="this.currentExtension.src_flags_hex != '' && this.validationError.get('src_flags_hex')">
                                <span class="tab">
                                    {{this.validationError.get('src_flags_hex')}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>DST Flags Hex</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.dst_flags_hex"
                                    type="text" (change)="isHex(this.currentExtension.dst_flags_hex, 'dst_flags_hex')">
                            </div>
                            <div class="mb-2"
                                *ngIf="this.currentExtension.dst_flags_hex != '' && this.validationError.get('dst_flags_hex')">
                                <span class="tab">
                                    {{this.validationError.get('dst_flags_hex')}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Windows Process Request Extension (Process)-->
                <div *ngSwitchCase="'windows-process-ext'" class="col-md-12">
                    <span class="tab"></span>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>ASLR Enabled</b>
                                </span>
                                <select class="form-control dropdown form-select" [(ngModel)]="this.currentExtension.aslr_enabled">
                                    <option value="" selected></option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>DEP Enabled</b>
                                </span>
                                <select class="form-control dropdown form-select" [(ngModel)]="this.currentExtension.dep_enabled">
                                    <option value="" selected></option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Priority</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.priority" type="text"
                                    (change)="priority(this.currentExtension.priority, 'priority')">
                            </div>
                            <div class="mb-2"
                                *ngIf="this.currentExtension.priority != '' && this.validationError.get('priority')">
                                <span class="tab">
                                    {{this.validationError.get('priority')}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Owner SID</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.owner_sid" type="text">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Window Title</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.window_title"
                                    type="text">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Integrity Level</b>
                                </span>
                                <select class="form-control dropdown form-select"
                                    [(ngModel)]="this.currentExtension.integrity_level">
                                    <option value="" selected></option>
                                    <option value="low">Low</option>
                                    <option value="medium">Medium</option>
                                    <option value="high">High</option>
                                    <option value="system">System</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Startup Info Key</b>
                                </span>
                                <select class="form-control dropdown form-select"
                                    [(ngModel)]="this.currentArrString['startup_info_key']"
                                    (change)="validateStartupValue()">
                                    <option value="" selected></option>
                                    <option value="cb">cb</option>
                                    <option value="lpDesktop">lpDesktop</option>
                                    <option value="lpTitle">lpTitle</option>
                                    <option value="dwX">dwX</option>
                                    <option value="dwY">dwY</option>
                                    <option value="dwXSize">dwXSize</option>
                                    <option value="dwYSize">dwYSize</option>
                                    <option value="dwxCountChars">dwxCountChars</option>
                                    <option value="dwyCountChars">dwyCountChars</option>
                                    <option value="dwFillAttribute">dwFillAttribute</option>
                                    <option value="dwFlags">dwFlags</option>
                                    <option value="wShowWindow">wShowWindow</option>
                                    <option value="hStdInput">hStdInput</option>
                                    <option value="hStdOutput">hStdOutput</option>
                                    <option value="hStdError">hStdError</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Startup Info Value</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentArrString['startup_info_value']"
                                    type="text" (change)="validateStartupValue()">
                                <div class="col-1">
                                    <button class="btn btn-fa-sm btn-primary " type="button"
                                        (click)="addDict('startup_info')"
                                        [disabled]="this.currentArrString['startup_info_key'] == undefined || this.currentArrString['startup_info_value'] == undefined || this.currentArrString['startup_info_key'] == '' || this.currentArrString['startup_info_value'] == '' || errorMessage.length > 0">
                                        <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                        <!-- Add -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            {{ this.errorMessage }}
                        </div>
                    </div>

                    <div class="row bump" id="questions">
                        <span class="tab">
                            <div class="form-group mt-2">
                                <li *ngFor="let x of this.stixService.stringArrays.get('startup_info')">
                                    {{x}}
                                    <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                        (click)="deleteString(x, 'startup_info')">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </div>
                        </span>
                    </div>
                </div>

                <!-- Windows Service Extension (Process)-->
                <div *ngSwitchCase="'windows-service-ext'" class="col-md-12">
                    <span class="tab"></span>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Service Name</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.service_name"
                                    type="text">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Display Name</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.display_name"
                                    type="text">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Group Name</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.group_name" type="text">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Start Type</b>
                                </span>
                                <select class="form-control dropdown form-select" [(ngModel)]="this.currentExtension.start_type">
                                    <option value="" selected></option>
                                    <option value="SERVICE_AUTO_START">SERVICE_AUTO_START</option>
                                    <option value="SERVICE_BOOT_START">SERVICE_BOOT_START</option>
                                    <option value="SERVICE_DEMAND_START">SERVICE_DEMAND_START</option>
                                    <option value="SERVICE_DISABLED">SERVICE_DISABLED</option>
                                    <option value="SERVICE_SYSTEM_ALERT">SERVICE_SYSTEM_ALERT</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Service Type</b>
                                </span>
                                <select class="form-control dropdown form-select" [(ngModel)]="this.currentExtension.service_type">
                                    <option value="" selected></option>
                                    <option value="SERVICE_KERNEL_DRIVER">SERVICE_KERNEL_DRIVER</option>
                                    <option value="SERVICE_FILE_SYSTEM_DRIVER">SERVICE_FILE_SYSTEM_DRIVER</option>
                                    <option value="SERVICE_WIN32_OWN_PROCESS">SERVICE_WIN32_OWN_PROCESS</option>
                                    <option value="SERVICE_WIN32_SHARE_PROCESS">SERVICE_WIN32_SHARE_PROCESS</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Service Status</b>
                                </span>
                                <select class="form-control dropdown form-select"
                                    [(ngModel)]="this.currentExtension.service_status">
                                    <option value="" selected></option>
                                    <option value="SERVICE_CONTINUE_PENDING">SERVICE_CONTINUE_PENDING</option>
                                    <option value="SERVICE_PAUSE_PENDING">SERVICE_PAUSE_PENDING</option>
                                    <option value="SERVICE_PAUSED">SERVICE_PAUSED</option>
                                    <option value="SERVICE_RUNNING">SERVICE_RUNNING</option>
                                    <option value="SERVICE_START_PENDING">SERVICE_START_PENDING</option>
                                    <option value="SERVICE_STOP_PENDING">SERVICE_STOP_PENDING</option>
                                    <option value="SERVICE_STOPPED">SERVICE_STOPPED</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- Descriptions String Array -->
                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Descriptions</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentArrString['descriptions']"
                                    type="text">
                                <span class="tab">
                                </span>
                                <div class="col-1">
                                    <button class="btn btn-fa-sm btn-primary " type="button"
                                        (click)="addString(this.currentArrString['descriptions'], 'descriptions')"
                                        [disabled]="this.currentArrString['descriptions'] == undefined || this.currentArrString['descriptions'] == ''">
                                        <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                        <!-- Add -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row bump" id="questions">
                        <span class="tab">
                            <div class="form-group mt-2">
                                <li *ngFor="let x of this.stixService.stringArrays.get('descriptions')">
                                    {{x}}
                                    <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                        (click)="deleteString(x, 'descriptions')">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </div>
                        </span>
                    </div>

                    <!-- Service DLL Reference Array - Type: File -->
                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text"
                                    (change)="validType(['file'], this.currentArrString['service_dll_refs'], 'service_dll_refs')">
                                    <b>Service DLL Refs</b>
                                </span>
                                <select class="form-control dropdown form-select"
                                    [(ngModel)]="this.currentArrString['service_dll_refs']">
                                    <option value="" selected></option>
                                    <ng-container *ngFor="let opt of this.stixService.bundle.objects">
                                        <ng-container *ngIf="opt.id.match(objectRegex)">
                                            <option *ngIf="['file'].indexOf(opt.id.match(objectRegex)[1]) != -1"
                                                [value]="opt['id']">
                                                {{opt.id}}
                                            </option>
                                        </ng-container>
                                    </ng-container>
                                </select>
                                <input class="form-control" [(ngModel)]="this.currentArrString['service_dll_refs']"
                                    type="text">
                                <span class="tab">
                                </span>
                                <div class="col-1">
                                    <button class="btn btn-fa-sm btn-primary " type="button"
                                        (click)="addString(this.currentArrString['service_dll_refs'], 'service_dll_refs')"
                                        [disabled]="this.currentArrString['service_dll_refs'] == undefined || this.currentArrString['service_dll_refs'] == '' || !validType(['file'], this.currentArrString['service_dll_refs'], 'service_dll_refs')">
                                        <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                        <!-- Add -->
                                    </button>
                                </div>
                            </div>

                            <div class="mb-2"
                                *ngIf="this.currentArrString['service_dll_refs'] != '' && this.validationError.get('service_dll_refs')">
                                <span class="tab">
                                    {{this.validationError.get('service_dll_refs')}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row bump" id="questions">
                        <span class="tab">
                            <div class="form-group mt-2">
                                <li *ngFor="let x of this.stixService.stringArrays.get('service_dll_refs')">
                                    {{x}}
                                    <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                        (click)="deleteString(x, 'service_dll_refs')">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </div>
                        </span>
                    </div>
                </div>

                <!-- Unix Account Extension (User Account)-->
                <div *ngSwitchCase="'unix-account-ext'" class="col-md-12">
                    <span class="tab"></span>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Group ID</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.gid" type="number">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Home Directory</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.home_dir" type="text">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Shell</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.shell" type="text">
                            </div>
                        </div>
                    </div>

                    <!-- Groups String Array -->
                    <div class="row bump">
                        <div class="p-0 col-12">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Groups</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentArrString['groups']" type="text">
                                <span class="tab">
                                </span>
                                <div class="col-1">
                                    <button class="btn btn-fa-sm btn-primary " type="button"
                                        (click)="addString(this.currentArrString['groups'], 'groups')"
                                        [disabled]="this.currentArrString['groups'] == undefined || this.currentArrString['groups'] == ''">
                                        <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                        <!-- Add -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row bump" id="questions">
                        <span class="tab">
                            <div class="form-group mt-2">
                                <li *ngFor="let x of this.stixService.stringArrays.get('groups')">
                                    {{x}}
                                    <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                        (click)="deleteString(x, 'groups')">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </div>
                        </span>
                    </div>
                </div>

                <!-- X.509 V3 Extensions (X.509 Certificate)-->
                <div *ngSwitchCase="'x509_v3_extensions'" class="col-md-12">
                    <span class="tab"></span>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Basic Constraints</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.basic_constraints"
                                    type="text">
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Name Constraints</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.name_constraints"
                                    type="text">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Policy Constraints</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.policy_constraints"
                                    type="text">
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Key Usage</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.key_usage" type="text">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Extended Key Usage</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.extended_key_usage"
                                    type="text">
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Subject Key Identifier</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.subject_key_identifier"
                                    type="text">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Authority Key Identifier</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.authority_key_identifier"
                                    type="text">
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Subject Alternative Name</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.subject_alternative_name"
                                    type="text">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Issuer Alternative Name</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.issuer_alternative_name"
                                    type="text">
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Subject Directory Attributes</b>
                                </span>
                                <input class="form-control"
                                    [(ngModel)]="this.currentExtension.subject_directory_attributes" type="text">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>CRL Distribution Points</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.crl_distribution_points"
                                    type="text">
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Inhibit Any Policy</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.inhibit_any_policy"
                                    type="text">
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Private Key Usage Period Not Before</b>
                                </span>
                                <input class="form-control"
                                    [(ngModel)]="this.currentExtension.private_key_usage_period_not_before"
                                    [owlDateTimeTrigger]="dtPicker1" [owlDateTime]="dtPicker1">
                                <owl-date-time #dtPicker1></owl-date-time>
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Private Key Usage Period Not After</b>
                                </span>
                                <input class="form-control"
                                    [(ngModel)]="this.currentExtension.private_key_usage_period_not_after"
                                    [owlDateTimeTrigger]="dtPicker2" [owlDateTime]="dtPicker2"
                                    (change)="isAfter(this.currentExtension.private_key_usage_period_not_before, this.currentExtension.private_key_usage_period_not_after, 'private_key_usage_period_not_after')">
                                <owl-date-time #dtPicker2></owl-date-time>
                            </div>
                            <div class="mb-2"
                                *ngIf="this.currentExtension.private_key_usage_period_not_after != '' && this.validationError.get('private_key_usage_period_not_after')">
                                <span class="tab">
                                    {{this.validationError.get('private_key_usage_period_not_after')}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row bump">
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Certificate Policies</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.certificate_policies"
                                    type="text">
                            </div>
                        </div>
                        <div class="p-0 col-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text">
                                    <b>Policy Mappings</b>
                                </span>
                                <input class="form-control" [(ngModel)]="this.currentExtension.policy_mappings"
                                    type="text">
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </div>


    <div class="modal-footer">
        <ng-container *ngIf="this.page == 'base'">
            <button type="button" class="btn btn-sm btn-secondary" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
                Cancel
            </button>
            <!-- [disabled]="!isAddEnabled()" -->
            <button class="btn btn-sm btn-success" type="button" (click)="addExtension()" [disabled]="!isAddEnabled()">
                <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                Add Extension
            </button>
        </ng-container>
        <ng-container *ngIf="this.page != 'base'">
            <button type="button" class="btn btn-sm btn-secondary" (click)="creatingSubObject('base')">
                <fa-icon [icon]="faArrowLeft" class="pe-1"></fa-icon>
                Back
            </button>
            <button class="btn btn-wsm btn-success" type="button" (click)="addSubObject()"
                [disabled]="((!positive(this.subObject['size'], 'size') || !this.subObject['name'] || this.subObject['name'] == '') && this.subObjectType != 'optional_header') || (this.subObjectType == 'optional_header' && emptySubObject(['hashes']))">
                <ng-container [ngSwitch]="this.subObjectType">
                    <ng-container *ngSwitchCase="'alternate_data_streams'">
                        <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                        Add Alternate Data Stream
                    </ng-container>
                    <ng-container *ngSwitchCase="'optional_header'">
                        <fa-icon [icon]="faSave" class="pe-1"></fa-icon>
                        Save Optional Header
                    </ng-container>
                    <ng-container *ngSwitchCase="'sections'">
                        <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                        Add Section
                    </ng-container>
                </ng-container>
            </button>
        </ng-container>
    </div>
</ng-template>


<ng-template #collection let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-title">Configure {{this.customProp['name']}}</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"></span>
        </button>
    </div>

    <div class="modal-body extensions-modal-body">
        <div class="col-md-12">
            <span class="tab"></span>

            <!-- String Array -->
            <ng-container *ngIf="this.customProp['type'] == 'array'">
                <div class="row bump">
                    <div class="p-0 col-12">
                        <div class="input-group mb-2">
                            <span class="input-group-text">
                                <b>Value</b>
                            </span>
                            <input class="form-control" [(ngModel)]="this.customProp['value']" type="text">
                            <span class="tab">
                            </span>
                            <div class="col-1">
                                <button class="btn btn-fa-sm btn-primary " type="button"
                                    (click)="addSubModalString(this.customProp['value'], 'customArray')"
                                    [disabled]="this.customProp['value'] == ''">
                                    <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                    <!-- Add -->
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" id="questions">
                    <span class="tab">
                        <div class="form-group mt-2">
                            <li *ngFor="let x of this.customProp.get('customArray')">
                                {{x}}
                                <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                    (click)="deleteSubModalString(x, 'customArray')">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </div>
                    </span>
                </div>
            </ng-container>

            <!-- String Dict -->
            <ng-container *ngIf="this.customProp['type'] == 'dict'">
                <div class="row">
                    <div class="p-0 col-12">
                        <div class="input-group mb-2 col-11">
                            <span class="input-group-text">
                                <b>Key</b>
                            </span>
                            <input class="form-control" [(ngModel)]="this.customProp['key']" type="text">
                            <span class="tab"></span>
                        </div>
                        <div class="input-group mb-2 col-12">
                            <span class="input-group-text">
                                <b>Value</b>
                            </span>
                            <input class="form-control" [(ngModel)]="this.customProp['value']" type="text">
                            <span class="tab"></span>
                            <div class="col-1">
                                <button class="btn btn-fa-sm btn-primary " type="button"
                                    (click)="addSubModalDictString(this.customProp['key'], this.customProp['value'], 'customDict')"
                                    [disabled]="this.customProp['key'] == '' || this.customProp['value'] == ''">
                                    <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                    <!-- Add -->
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" id="questions">
                    <span class="tab">
                        <div class="form-group mt-2">
                            <li *ngFor="let x of this.customProp.get('customDict').keys()">
                                {{x}}: {{this.customProp.get('customDict').get(x)}}
                                <button type="button" class="btn btn-fa-xsm btn-red trash-can"
                                    (click)="deleteSubModalString(x, 'customDict')">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </div>
                    </span>
                </div>
            </ng-container>

        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-md btn-secondary" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            Cancel
        </button>
        <button class="btn btn-md btn-success" type="button" (click)="createCollection()"
            [disabled]="this.customProp.get('customArray').length == 0 && this.customProp.get('customDict').size == 0">
            Create Property
        </button>
    </div>
</ng-template>