<form (ngSubmit)="addComponent()" [formGroup]="form">
    <div class="row">
        <div class="col-12">
            <div class="row ps-2 pe-2" *ngIf="errorMessage">
                <div class="col-12 text-center mb-1 warning-message">{{ errorMessage }}</div>     
            </div>
            <div class="row pe-2" [ngClass]="{'ps-2 mb-2': stixService.guidedUI}" *ngIf="showObjectMarkingReference()" style="padding-top: 0;">
                <!-- <hr *ngIf="hasGranularMarkings  || hasExternalReferences || hasContents"
                    class="section-separator"> -->
                <hr class="section-separator" style="margin-top: 8px;">
                <app-string-array [key]="'object_marking_refs'" *ngIf="hasObjectMarkingReferences" (stringArrayOutput)="setObjectMarkingReferences($event)"
                    [class]="topColumnProperties"></app-string-array>
                <app-string-array [key]="'labels'" *ngIf="ifLabels()" (stringArrayOutput)="setLabels($event)"
                    [class]="topColumnProperties"></app-string-array>
                <hr *ngIf="!stixService.guidedUI" class="section-separator" style="margin-bottom: 4px; margin-top: 1rem;">
            </div>
            <div class="row pe-2" [ngClass]="{'ps-2': stixService.guidedUI}" *ngIf="!showObjectMarkingReference() && stixService.guidedUI" style="padding-top: 0;">
                <hr class="section-separator" style="margin-top: 8px; margin-bottom: 16px;">
            </div>
            <div class="row">
                <div *ngFor="let question of questions" [ngClass]="{'ps-2 pe-2 w-100 display-flex': stixService.guidedUI, 'p-0': !stixService.guidedUI}" [class]="questionWrapperClass(question)">
                    <button
                        *ngIf="stixService.guidedUI && showFavIconType(question) === 'plus' && !(stixService.newObject && question.key === 'revoked')"
                        type="button" class="btn btn-fa-sm btn-outline-danger d-flex justify-content-center ms-0 me-2"
                        title="Click to add favorite" (click)="updateFav(question)">
                        <fa-icon [icon]="faHeart" (click)="updateFav(question)" alt="" width="30px" height="20"
                            class="d-inline-block align-top"></fa-icon>
                    </button>
                    <button
                        *ngIf="stixService.guidedUI && showFavIconType(question) === 'minus' && !(stixService.newObject && question.key === 'revoked')"
                        type="button" class="btn btn-fa-sm btn-danger d-flex justify-content-center ms-0 me-2"
                        title="Click to remove favorite" (click)="updateFav(question)">
                        <img src="./assets/heart-circle-minus-solid.svg" (click)="updateFav(question)" alt=""
                            width="30px" height="20" class="d-inline-block align-top fav-color ms-1">
                    </button>
                    <app-question (change)="isAddEnabled()" (input)="isAddEnabled()" (isAddEnabled)="isAddEnabled()" (checkDuplicates)="checkDuplicatesCallback()" *ngIf="!(stixService.newObject && question.key === 'revoked')" [ngClass]="{'w-100': stixService.guidedUI}" [question]="question" [form]="form" [componentMap]="componentMap" [displayName]="displayName"></app-question>
                </div>
            </div>
            <div class="row ps-2 pe-2" *ngIf="this.question_type === 'incident'">
                <hr class="section-separator" style="margin-top: 4px" />
                <div class="col-12 ps-0 pe-0" style="float: left; margin-bottom: 14px">
                    <incident-core-extension class="w-100"></incident-core-extension>
                </div>
            </div>
            <div class="row ps-2 pe-2" *ngIf="this.question_type === 'malware'">
                <hr class="section-separator" style="margin-top: 4px" />
                <div class="col-12 ps-0 pe-0" style="float: left; margin-bottom: 14px">
                    <malware-corpus class="w-100"></malware-corpus>
                </div>
            </div>
            <div class="row pe-2 pb-3" [ngClass]="{'ps-2': stixService.guidedUI}">
                <hr class="section-separator" style="margin-top: 4px;" *ngIf="!stixService.guidedUI">
                <div style="float:left" *ngIf="hasGranularMarkings">
                    <app-granular-markings class="w-100" [ngClass]="{'mt-2': stixService.guidedUI}"></app-granular-markings>
                </div>
            </div>
            <div class="row pe-2 pb-3" [ngClass]="{'ps-2': stixService.guidedUI}">
                <div style="float:left" *ngIf="hasExternalReferences">
                    <!-- <hr class="section-separator"> -->
                    <app-external-references class="w-100" [ngClass]="{'mt-2': stixService.guidedUI}"></app-external-references>
                </div>
            </div>
            <div class="row pe-2 pb-1" [ngClass]="{'ps-2': stixService.guidedUI}">

                <div *ngIf="hasExtensions">
                    <!-- <hr *ngIf="hasGranularMarkings  || hasExternalReferences || hasContents || hasObjectMarkingReferences"
                        class="section-separator"> -->
                    <app-extensions class="w-100" [ngClass]="{'mt-2': stixService.guidedUI}"></app-extensions>
                </div>

                <hr *ngIf="stixService.guidedUI" [class]="questionWrapperClass(null, true)" class="section-separator" style="margin-bottom: 8px;">
                <div *ngIf="!stixService.guidedUI && stixService.toplevel.length > 0" class="row pt-4">
                    <div class="input-group">
                        <p class="stix-title pt-2 pb-2"> Top Level Properties</p>
                        <div class="p-0 pt-1 float-end ms-3">
                            <button *ngIf="!this.toplevelEnabled" type="button"
                                class="btn btn-fa-sm btn-collapse btn-outline-primary" (click)="this.toplevelEnabled = !this.toplevelEnabled">
                                <fa-icon [icon]="faAngleDoubleDown"></fa-icon>
                            </button>
                            <button *ngIf="this.toplevelEnabled" type="button"
                                class="btn btn-fa-sm btn-collapse btn-outline-secondary" (click)="this.toplevelEnabled = !this.toplevelEnabled">
                                <fa-icon [icon]="faAngleDoubleUp"></fa-icon>
                            </button>
                        </div>
                        <!-- <div class="col-6 ps-4">
                            <div class="input-group search-bar">
                                <input class="form-control search-input"
                                    (keydown.shift.enter)="search(true)" (keydown.enter)="search()"
                                    (keydown.escape)="clearSearch()" placeholder="Type to Search..."
                                    [formControlName]="toplevelSearch">

                                <div>
                                    <p class="search-results" *ngIf="toplevelSearch !== ''">{{toplevelResults}}</p>
                                </div>
                                <!-- <button class="clear-button" [disabled]="toplevelSearch === ''" (click)="toplevelSearch = ''">
                                    <p class="clear-button-text" *ngIf="toplevelSearch == ''">X</p>
                                </button> --
                            </div>
                        </div> -->
                    </div>
                    <div class="row mb-1" *ngFor="let prop of stixService.toplevel">
                        <div *ngIf="toplevelEnabled" class="col-12">
                            <div class="d-flex">
                                <ng-template [ngIf]="!isObject(prop[1])" [ngIfElse]="objProp">
                                    <ng-template [ngIf]="('' + prop[1]).length < 200" [ngIfElse]="largeProp">
                                        <span
                                            class="form-control dropdown add-left no-border-right"
                                            id="{{prop[0]}}"
                                            style="height: 31px;background-color: #21252908 !important; font-size: smaller;"
                                            readonly
                                        ><b>{{prop[0]}}:</b> {{prop[1]}}</span>
                                        <button
                                            class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                            type="button"
                                            (click)="stixService.deleteProp(prop[0])"
                                        >
                                        X
                                        </button>
                                    </ng-template>
                                    <ng-template #largeProp>
                                        <span
                                            class="form-control dropdown add-left no-border-right"
                                            style="height: 125px; background-color: #21252908 !important; font-size: smaller; overflow-wrap: break-word; overflow-y: scroll;"
                                            readonly
                                        ><b>{{prop[0]}}:</b><br>&#13;&#10;{{prop[1]}}</span>
                                        <button
                                            class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                            type="button"
                                            (click)="stixService.deleteProp(prop[0])"
                                            style="height: 125px"
                                        >
                                        X
                                        </button>
                                    </ng-template>
                                </ng-template>
                                <ng-template #objProp>
                                    <span
                                        class="form-control dropdown add-left no-border-right"
                                        style="height: 125px; background-color: #21252908 !important; font-size: smaller; overflow-wrap: break-word; overflow-y: scroll; white-space: pre-wrap;"
                                        readonly
                                    ><b>{{prop[0]}}:</b><br>&#13;&#10;{{prop[1] | json}}</span>
                                    <button
                                        class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                        type="button"
                                        (click)="stixService.deleteProp(prop[0])"
                                        style="height: 125px"
                                    >
                                    X
                                    </button>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <hr *ngIf="!stixService.guidedUI" class="section-separator" style="margin-bottom: 8px; margin-top: 1rem;">
            </div>
        </div>
    </div>
    <div class="row" [ngClass]="{'add-to-bundle': !stixService.guidedUI}">
        <div class="col text-end p-0 pe-2">
            <button [class]="{'move-out-of-view': stixService.guidedUI}"
                type="button" class="btn btn-lg btn-secondary component-button text me-2" (click)="cancel()" style="width: 175px !important">
                <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
                Cancel
            </button>   
            <!-- <button *ngIf="showClose" type="button" class="btn btn-lg btn-secondary component-button text me-2"
                (click)="closeWindow()">
                <fa-icon [icon]="faClose" class="pe-1"></fa-icon>
                Close
            </button>        -->
            <button [class]="{'move-out-of-view': stixService.guidedUI}"
                type="button" class="btn btn-lg btn-success component-button text" (click)="addComponent()"
                [disabled]="!addEnabled || !isFormDataChanged() || !isIceValid || !isMBEValid" style="width: 175px !important; will-change: transform, opacity;">
                <fa-icon [icon]="faAddToBundle" class="pe-1"></fa-icon>
                Add to Bundle
            </button>
        </div>
    </div>
</form>