export class IncidentCoreExtension {

    /* determination: string;
    extension_type: string;
    investigation_status: string; */
    curr: any;

    constructor(curr?: any) {
        this.curr = curr || null;
    }
}