<div class="ps-3 pb-3 d-flex justify-content-between">
    <div>
        <button class="btn btn-sm btn-outline-primary me-2" [ngClass]="{'btn-primary': activeTable == 'history', 'btn-outline-primary': activeTable != 'history'}" [disabled]="isLoading || activeTable == 'history'" (click)="setTable('history')">
            <fa-icon [icon]="faBell" class="pe-1"></fa-icon>
            History
        </button>
        <button class="btn btn-sm btn-outline-primary" [ngClass]="{'btn-primary': activeTable == 'subscriptions', 'btn-outline-primary': activeTable != 'subscriptions'}" [disabled]="isLoading || activeTable == 'subscriptions'" (click)="setTable('subscriptions')">
            <fa-icon [icon]="faCog" class="pe-1"></fa-icon>
            Subscriptions
        </button>
    </div>
</div>
<div *ngIf="!isLoading && stixService.imxServerConnectionError != ''" class="col-12 text-center">
    <p class="text-danger">**{{stixService.imxServerConnectionError}}**</p>
</div>
<div *ngIf="!isLoading && stixService.imxServerConnectionError == '' && activeTable == 'history'">
    <app-alert-history></app-alert-history>
</div>
<div *ngIf="!isLoading && stixService.imxServerConnectionError == '' && activeTable == 'subscriptions'">
    <app-alert-subscriptions></app-alert-subscriptions>
</div>