import { Component, OnInit, OnDestroy, Input, ViewEncapsulation, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faInfoCircle, faTrash, faEdit, faArrowDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { STIX_OBJECTS } from 'src/app/models/stix-objects';
import { StixService } from 'src/app/stix-service.service';
import { QuestionBase } from '../question-base';
import { ComparisonExpression } from './pattern-builder/comparison-expression';
import { ObservationExpression } from './pattern-builder/observation-expression';
import { StixExpression } from './pattern-builder/stix-expression';
import { StixPattern } from './pattern-builder/stix-pattern';
import { CustomObjectComponent } from '../../custom-objects/custom-object.component';
import { SCO_LIST } from "../../models/sco_list";
import { Bundle } from '../../models/bundle';
import { Content } from 'src/app/models/content';
import { COMPONENT_MAP } from 'src/app/models/component-map';
import { LANGUAGES } from 'src/app/models/languages';
import { TLP_OPTIONS } from '../../tlpMarkingDef';
import { HashArrayQuestion } from '../question-types/question-hash-array';
import { FormModel } from '../form-model';
import { HybridArrayQuestion } from '../question-types/question-hybrid-array';
import { OpenVocabArrayQuestion } from '../question-types/question-ov-array';
import { ProtocolArrayQuestion } from '../question-types/question-protocol-array';
import { ReferenceArrayQuestion } from '../question-types/question-reference-array';
import { StringArrayQuestion } from '../question-types/question-string-array';
import { MIME } from 'src/app/models/mime';
import { v4 as uuid } from "uuid";
import { ContentsDialogComponent } from 'src/app/dialogs/contents-dialog/contents-dialog.component';
import { ObjectQuestion } from '../../dynamic-form-component/question-types/question-object';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import tooltipHelper from './tooltipHelper/tooltipHelper';
import { TooltipStringArrayQuestion } from '../question-types/question-tooltip-string-array';
// import { Reference } from '@angular/compiler/src/render3/r3_ast';
import { RefArrayQuestion } from '../question-types/question-ref-array';
import { StringDictQuestion } from '../question-types/question-string-dict';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IdentityQuestion } from '../question-types/question-identity';
import { RefQuestion } from '../question-types/question-ref';
import { HybridQuestion } from '../question-types/question-hybrid';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { faArrowRight, faBan, faArrowLeft, faSave, faUndo, faPlusCircle, faTools } from '@fortawesome/free-solid-svg-icons';
import { PatternBuilderComponent } from 'src/app/dialogs/pattern-builder/pattern-builder.component';
import { MultipartDialogComponent } from 'src/app/dialogs/multipart-dialog/multipart-dialog.component';
import { WindowsValueDialogComponent } from 'src/app/dialogs/windows-value-dialog/windows-value-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import * as moment_tz from 'moment-timezone';
import { DeciderServiceService } from 'src/app/decider-service/decider-service.service';
import { MimeQuestion } from '../question-types/question-mime';

@Component({
  selector: 'app-question',
  templateUrl: './app-question.component.html',
  styleUrls: ['./app-question.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicFormQuestionComponent implements OnInit, OnDestroy {
  @Input() question!: QuestionBase<any>;
  @Input() form!: UntypedFormGroup;
  @Input() componentMap: Map<String, Object>;
  @Input() displayName: string;
  @Output("isAddEnabled") isAddEnabled: EventEmitter<any> = new EventEmitter(); 
  @Output() checkDuplicates = new EventEmitter<any>();

  nrSelect = false;

  // CONSTANTS
  COMPARISON_OPERATORS = ["=", "!=", ">", "<", "<=", ">=", "IN", "LIKE", "MATCHES"];
  OBSERVATION_EXPRESSION_QUALIFIERS = ['', 'WITHIN', 'REPEATS', 'START'];
  COMPARISON_EXPRESSION_OPERATORS = ['AND', 'OR'];
  OBSERVATION_EXPRESSION_OPERATORS = ['AND', 'OR', 'FOLLOWEDBY'];

  // FONT AWESOME ICONS
  faArrowDown = faArrowDown;
  faTrash = faTrash;
  faPlus = faPlus;
  faEdit = faEdit;
  faSave = faSave;
  faBan = faBan;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faUndo = faUndo;
  faPlusCircle = faPlusCircle;
  faDecider = faTools;

  comparisonConstant: any = null;
  comparisonExpressions: ComparisonExpression[] = [];
  currObjectPath = null;
  currPropertyName: string = null;
  newString: string = '';
  newString2: string = '';
  newString3: string = '';
  relString: string = '';
  objectPaths = STIX_OBJECTS[1]["objects"];
  observationExpressionBuilder: ObservationExpression = null;
  observationExpressions: ObservationExpression[] = [];
  patternExpressions: ObservationExpression[] = [];
  page = 1;
  pageSize = 4;
  selectedComparisonOperator = '=';
  selectedObservationOperator = 'AND';
  selectedStixPatternBuilder: StixPattern = null;
  validationMessage: string = '';
  new_version: boolean;
  object_type: string;
  currentModalObject = new Map();
  editedModalObject: any;
  modalProps = new Map();
  modalError = new Map();
  customObjType = '';
  languages = LANGUAGES;
  objReadonly = false;    // Deprecated
  ids: any[] = [];
  customProp: any = new Map();
  customStringPropKeys: string[] = [];
  customArrPropKeys: string[] = [];
  dataOptions: string[] = ['REG_NONE', 'REG_SZ', 'REG_EXPAND_SZ', 'REG_BINARY', 'REG_DWORD', 'REG_DWORD_BIG_ENDIAN',
    'REG_DWORD_LITTLE_ENDIAN', 'REG_LINK', 'REG_MULTI_SZ', ' REG_RESOURCE_LIST', 'REG_FULL_RESOURCE_DESCRIPTION',
    'REG_RESOURCE_REQUIREMENTS_LIST', 'REG_QWORD', 'REG_INVALID_TYPE']; 
  // langOptions = LANGUAGES;
  // fieldNameOptions: QuestionBase<any>[] = [];

  timeZoneOptions: any[] = [];
  moment = moment;
  moment_tz = moment_tz;


  /** Object Properties */
  isTypeReadOnly: boolean = true;
  isSpecVersionReadOnly: boolean = true;
  isIDReadOnly: boolean = true;
  SCO_LIST = SCO_LIST;
  TLP_OPTIONS = TLP_OPTIONS;
  MIME = MIME;
  malwareAnalysisRegex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
  objectRegex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
  hashError = '';
  dictError = '';
  revocation: boolean = false;
  description = '';
  modalRef: any;
  faInfoCircle = faInfoCircle;
  showInfoIcon = false;
  mockNames = [];
  bundle: Bundle;
  scoBundleObjects = [];
  scoBundleObjectSelected = null;
  tooltip: string;
  propertyNames: string[] = [];
  filteredBundleObj = [];
  loadedFile: File;
  loadedFileName = '';
  schema: any;
  message = '';
  modalEnabled = false;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private http: HttpClient,
    public modalService: NgbModal,
    public stixService: StixService,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    public dynamicForm: DynamicFormComponent,
    public patternBuilderDialog: MatDialog,
    public multipartDialog: MatDialog,
    public windowsValueDialog: MatDialog,
    private decider: DeciderServiceService
  ) { }

  ngOnChanges(change: SimpleChanges): void {
    if (this.stixService.guidedUI) {
      if (this.question.key === 'source_ref'
        || this.question.key === 'target_ref') {
        this.question.relString = this.question.value;
      }

      if (this.question.key === 'relationship_type' && this.question.arrOptions.length === 0) {
        this.question.arrOptions = this.stixService.relationshipArrOptions;
      }

      if (this.question.key === 'sighting_of_ref') {
        this.question.relString = this.question.value;
      }
    }
    
    const missingAttributes = localStorage.getItem("missing-attributes");
    if (this.question.key === 'spec_version'
      && missingAttributes.includes('spec_version')) {
      this.isSpecVersionReadOnly = false;
      this.question.value = ""
      this.isAddEnabled.emit();
    }
  }

  ngOnInit(): void {
    this.object_type = this.form.value.type;

    const missingAttributes = localStorage.getItem("missing-attributes");
    if (this.question.key === 'spec_version'
      && missingAttributes.includes('spec_version')) {
      this.isSpecVersionReadOnly = false;
      this.question.value = ""
      this.isAddEnabled.emit();
    }

    if (history.state.data && history.state.data.new_version && history.state.data.new_version == true) {
      this.new_version = true;
    }

    if (this.question.autoSelectDropdown && this.question.options && this.question.options.length == 1)
      this.form.controls[this.question.key].setValue(this.question.options[0].key);


    if (this.question.key.toLowerCase() === 'confidence') {
      this.description = 'The confidence property reflects the object creator\'s trust in the accuracy of their data. The confidence value MUST be a number in the range of 0-100.';
      this.showInfoIcon = true;
    }

    this.activatedRoute.queryParams
      .subscribe(params => {
        this.revocation = history.state.data && history.state.data.revocation
          ? history.state.data.revocation
          : params.revocation;
      })

    //if (this.question.key.toLowerCase() === 'confidence') {
    //  this.showInfoIcon = true;
    //}

    this.checkLanguageContents();

    this.mockNames = []
    this.bundle = this.stixService.bundle;

    if (this.scoBundleObjects.length === 0) {
      this.objectPaths.forEach(object => {
        const bundleObj = this.bundle.objects.find(bundleObj => bundleObj && (bundleObj.type === object.type));
        if (bundleObj && !this.scoBundleObjects.some(x => x.type === object.type)) {
          let tmpBundleObj = Object.assign({}, bundleObj);
          tmpBundleObj['displayName'] = object.displayName;
          this.scoBundleObjects.push(tmpBundleObj);
        } else {
          this.scoBundleObjects.push(object);
        }
      })
    }

    // For Language Content Only: Copy modified timestamp of selected Object Reference to Object Modified field
    if (this.object_type === 'language-content') {
      this.form.controls['object_ref'].valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(v => {
        let timestamp = this.bundle.objects.find(obj => obj.id === v)?.modified;
        this.form.controls['object_modified'].setValue(timestamp);      
      });
    }

    //Populate Reference dropdown
    if (this.question instanceof MimeQuestion || this.question instanceof RefQuestion || this.question instanceof HybridQuestion) {
      if (this.question.value) this.question.relString = this.question.value;
    }

    //Populate Marking Definition, Defninition Type dropdown
    if (this.question.key.toLowerCase() === 'definition_type') {
      if (this.question.value) this.question.relString = this.question.value
    }
    
    // Populate Created by Ref dropdown
    if (this.question.key.toLowerCase() === 'created_by_ref') {
      let identities = this.stixService.populateCreatedByRef();
      //for (let identity of identities) 
      this.question.options.push({ key: environment.cisaIdentity.id, value: environment.cisaIdentity.name });
      if (identities.length === 1) this.question.relString = this.question.options[0].key;
      if (this.question.value) this.question.relString = this.question.value;
    }

    // Populate Sighting of Ref dropdown
    if (this.question.key.toLowerCase() === 'sighting_of_ref') {
      let sightings = this.stixService.populateSightingOfRef();
      for (let sighting of sightings) {
        if (!this.question.readonly) this.question.options.push({ key: sighting, value: sighting });
      }
      if (this.question.options.length === 1) this.question.relString = this.question.options[0].key;
      if (this.question.value) this.question.relString = this.question.value;     
    }
    
    this.tooltip = tooltipHelper(this.question, this.displayName, this.object_type);

    if (this.tooltip) {
      this.description = '';
    }

    if (this.stixService.currentType == 'email-message' && (this.currentModalObject['body'] == undefined || this.currentModalObject['body'] == '') && (this.currentModalObject['body_raw_ref'] == undefined || this.currentModalObject['body_raw_ref'] == ''))
      this.modalError.set('object', 'Either Body or Body Raw Ref is required ');

    if (this.object_type === 'marking-definition') {
      const new_version_objects = JSON.parse(localStorage.getItem("new_version_objects"));
      if (new_version_objects?.length > 0
        && new_version_objects.includes(this.form.value.id)) {
          this.question.readonly = true;
      }
    }

    this.checkDuplicates.emit();

    // Test
    // setTimeout(() => {
    //   this.openModal('stixPatternBuilder')
    // }, 500)
  }

  mapBundleObjToSCOObj(bundleObj) {
    const objectPath = this.objectPaths.find(obj => obj.type === bundleObj.type);
    this.filteredBundleObj = this.bundle.objects.filter(elem => elem.type === objectPath?.type);
    this.scoBundleObjectSelected = this.filteredBundleObj.length === 1 ? this.filteredBundleObj[0] : null;
    this.comparisonConstant = null;
    this.propertyNames = [];
    this.currPropertyName = '';
    this.updatePropertyNames(objectPath);
  }

  checkLanguageContents() {
    if (this.question.controlType === 'language-contents') {
      if (!ObjectQuestion.validatorFn(this.form.getRawValue()).valid) {
        this.question.placeholder = 'Please provide the Object Reference ID';
        this.question.readonly = true;
      } else {
        this.question.placeholder = '--> Use button on the right to create Contents -->';
        this.question.readonly = false;
      }
    }
  }

  set observationQualifierData1(data1: string) {
    this.selectedStixPatternBuilder.setQualifierData(this.selectedStixPatternBuilder.qualifierForDropdown, data1, 1);
  }

  set observationQualifierData2(data2: string) {
    this.selectedStixPatternBuilder.setQualifierData(this.selectedStixPatternBuilder.qualifierForDropdown, data2, 2);
  }

  get isValid() {
    const currValue = this.form.getRawValue()[this.question.key];

    if (this.question.key === 'contents') {
      this.checkLanguageContents(); 
      return true; 
    } else if(((currValue && currValue.length === 0) || currValue === "") && !this.question.validatorFn)
      return true;

    // Check Custom Validation Function
    if (this.form.dirty && this.question.validatorFn) {
      let validationResult = this.question.validatorFn!(this.form.getRawValue());
      if (typeof validationResult.errorMessage !== 'undefined')
        this.validationMessage = `**${validationResult.errorMessage}**`;      
      return validationResult.valid;
    }

    // Basic Required Validation
    if (this.question.required && !currValue) {
      this.validationMessage = '';//`${this.question.label} is required`;
      return false;
    }
    this.validationMessage = '';

    //this.checkLanguageContents();

    // No Validation
    return true;
  }

  /** Add selected comparison expression to the builder view for construction */
  addComparisonExpression(): void {
    this.comparisonExpressions.push(new ComparisonExpression(`${this.currObjectPath.type}:${this.currPropertyName}`, this.selectedComparisonOperator, this.comparisonConstant));

    this.currObjectPath = null;
    this.selectedComparisonOperator = '=';
    this.comparisonConstant = null;
  }

  /**
   * This will add the comparison expression to the array once the user has added the appropriate operators
   * 
   * @param comparisonExpression constructed comparison expression
   */
  addComparisonExpressionToObservationExpression(comparisonExpression: StixExpression): void {
    if (!this.observationExpressionBuilder)
      this.observationExpressionBuilder = new ObservationExpression();

    this.observationExpressionBuilder.addExpression(comparisonExpression);
  }

  /**
   * Add selected observation expression to the builder view for construction
   */
  addObservationExpression(): void {
    this.observationExpressions.push(this.observationExpressionBuilder);
    this.observationExpressionBuilder = null;
  }

  /**
   * Add selected observation expression to the builder view for construction
   */
  addPatternExpression(): void {
    this.patternExpressions.push(this.observationExpressionBuilder);
    this.observationExpressionBuilder = null;
  }

  /**
   * Final step - add observation expression to the stix builder for the final qualifiers prior to completion
   * 
   * @param observationExpression previously constructed observation expression
   */
  addObservationExpressionToStixPattern(observationExpression: ObservationExpression): void {
    this.selectedStixPatternBuilder = new StixPattern();
    this.selectedStixPatternBuilder.observationExpression = observationExpression;
  }

  addString(): void {
    if (this.dynamicForm.question_type === 'report' && this.question.key === 'object_refs' && this.newString === 'Add all objects') {
      const objects = this.getBundleObjects();
      const currentId = this.stixService.currentID;
      if (this.stixService.stringArrays.has(this.question.key)) {
        let newStringArray = this.stixService.stringArrays.get(this.question.key);
        for (const object of objects) {
          if (!newStringArray.includes(object.id) && object.id !== currentId) {
            newStringArray.push(object.id);
          }
        }
        this.stixService.stringArrays.set(this.question.key, newStringArray);
      } else {
        this.stixService.stringArrays.set(this.question.key, objects.map((elem) => elem.id).filter((elem => elem.id !== currentId)));
      }
    } else {
      if (this.stixService.stringArrays.has(this.question.key)) {
        let newStringArray = this.stixService.stringArrays.get(this.question.key)!;
        if (newStringArray.indexOf(this.newString) == -1) {
          newStringArray.push(this.newString);
          this.stixService.stringArrays.set(this.question.key, newStringArray);
        }
      } else
        this.stixService.stringArrays.set(this.question.key, [this.newString]);
    }

    this.newString = '';
  }

  addString3(): void {
    if (this.stixService.stringArrays.has(this.question.key)) {
      let newStringArray = this.stixService.stringArrays.get(this.question.key)!;
      console.log(this.newString3);
      newStringArray.push(this.newString3);
      this.stixService.stringArrays.set(this.question.key, newStringArray);
    } else {
      console.log('wrong');
      this.stixService.stringArrays.set(this.question.key, [this.newString3]);
    }

    this.newString3 = '';
  }

  isHashEnabled(): boolean {
    const MD5Regex = new RegExp('^[a-fA-F0-9]{32}$')
    const SHA1Regex = new RegExp('^[0-9a-f]{5,40}$')
    const SHA256Regex = new RegExp('^[A-Fa-f0-9]{64}$')
    const SHA512Regex = new RegExp('^[a-f0-9]{128}$')
    //SHA3 Regexes are identical
    const SSDEEPRegex = new RegExp('^(\d{3}):(\w*)\+(\w*):(\w*)$')
    const TLSHRegex = new RegExp('^T1[a-fA-F0-9]{70}$')

    let type = this.question.relString;
    let hashValue = this.newString;
    if (hashValue == '' || type == '') {
      this.hashError = '';
      return false;
    }

    let s = type + ": " + hashValue;
    let defined = false;
    let newStringArray;
    if (this.stixService.stringArrays.has(this.question.key)) {
      newStringArray = this.stixService.stringArrays.get(this.question.key)!;
      defined = true;
    }

    if (defined) {
      for (let currentString of newStringArray) {
        const regex = new RegExp('^(.*):(.*)$')
        const matches = currentString.match(regex);
        if (type == matches[1]) {
          this.hashError = 'Warning: hash exists for this hash type - delete existing hash first to overwrite.';
          return false;
        }
      }
    }

    if (type == 'MD5')
      if (!MD5Regex.test(hashValue)) {
        this.hashError = "MD5 Hash Value must be properly formatted";
        return false;
      }
    if (type == 'SHA-1')
      if (!SHA1Regex.test(hashValue)) {
        this.hashError = "SHA-1 Hash Value must be properly formatted";
        return false;
      }
    if (type == 'SHA-256')
      if (!SHA256Regex.test(hashValue)) {
        this.hashError = "SHA-256 Hash Value must be properly formatted";
        return false;
      }
    if (type == 'SHA-512')
      if (!SHA512Regex.test(hashValue)) {
        this.hashError = "SHA-512 Hash Value must be properly formatted";
        return false;
      }
    if (type == 'SHA3-256')
      if (!SHA256Regex.test(hashValue)) {
        this.hashError = "SHA3-256 Hash Value must be properly formatted";
        return false;
      }
    if (type == 'SHA3-512')
      if (!SHA512Regex.test(hashValue)) {
        this.hashError = "SHA3-512 Hash Value must be properly formatted";
        return false;
      }
    if (type == 'SSDEEP')
      if (!SSDEEPRegex.test(hashValue)) {
        this.hashError = "SSDEEP Hash Value must be properly formatted";
        return false;
      }
    if (type == 'TLSH')
      if (!TLSHRegex.test(hashValue)) {
        this.hashError = "TLSH Hash Value must be properly formatted";
        return false;
      }

    //this.currentExternalReference.hashes[type] = value;

    this.hashError = '';
    return true;
  }

  isDictEnabled(): boolean {
    let type = this.question.relString;
    let value = this.newString;

    if (this.question.key === 'additional_header_fields') {
      return true;
    }

    if (type.includes(" ")){
      this.dictError = 'Key may not include whitespace';
      return false;
    }

    let s = type + ": " + value;
    let defined = false;
    let newStringArray;
    if (this.stixService.stringArrays.has(this.question.key)) {
      newStringArray = this.stixService.stringArrays.get(this.question.key)!;
      defined = true;
    }

    if (defined) {
      for (let currentString of newStringArray) {
        const regex = new RegExp('^(.*):(.*)$')
        const matches = currentString.match(regex);
        if (type == matches[1]) {
          this.dictError = 'Warning: Value exists for this type - delete existing value entry first to overwrite.';
          return false;
        }
      }
    }

    this.dictError = '';
    return true;
  }

  addDict(): void {
    let s = this.question.relString + ": " + this.newString;
    let defined = false;
    let newStringArray;
    if (this.stixService.stringArrays.has(this.question.key)) {
      newStringArray = this.stixService.stringArrays.get(this.question.key)!;
      defined = true;
    }

    //let s = this.question.relString + ": " + this.newString;
    if (defined) {
      if (newStringArray.indexOf(s) == -1) {
        newStringArray.push(s);
        this.stixService.stringArrays.set(this.question.key, newStringArray);
      }
    } else
      this.stixService.stringArrays.set(this.question.key, [s]);

    this.newString = '';

    this.checkDuplicates.emit();
  }

  addKillChain(cartItems: any[] = []): void {
    let str = ``;
    if(cartItems.length > 0){
      for(let item of cartItems){
        str = `${item.kill_chain_phase.phase_name}: ${item.kill_chain_phase.kill_chain_name}`;
        item['str'] = str;

        if (this.stixService.stringArrays.has('killChain')) {
          let newStringArray = this.stixService.stringArrays.get('killChain')!;
          if (newStringArray.indexOf(str) == -1) {
            newStringArray.push(str);
            this.stixService.stringArrays.set('killChain', newStringArray);
          }
        } else {
          this.stixService.stringArrays.set('killChain', [str]);
        }

        let found = false;
        for(let ref of this.stixService.externalReferences){
          if(ref.external_id === item.external_id){
            found = true;
            break;
          }
        }
        if(found === false){
          this.stixService.externalReferences.push(item.external_reference);
        }
      }
      
    } else {
      str = this.newString + ': ' + this.question.relString;

      if (this.stixService.stringArrays.has('killChain')) {
        let newStringArray = this.stixService.stringArrays.get('killChain')!;
        if (newStringArray.indexOf(str) == -1) {
          newStringArray.push(str);
          this.stixService.stringArrays.set('killChain', newStringArray);
        }
      } else {
        this.stixService.stringArrays.set('killChain', [str]);
      }

      this.question.relString = '';
      this.newString = '';
    }

    this.dynamicForm.isAddEnabled();
    this.stixService.kill_chainTrack = true;
  }

  async openDeciderTool(): Promise<void> {
    document.cookie = `imx_attack_patterns=; Max-Age=0; domain=${environment.deciderDomain};`;
    let result = await this.decider.openDeciderTool();
    document.cookie = `imx_attack_patterns=; Max-Age=0; domain=${environment.deciderDomain};`;

    this.addKillChain(result);
  }

  syncKillChain(): void {
    /*console.log(this.newString2)
    let temp = this.newString2;
    console.log(this.newString2)

    console.log(this.question.arrOptions)
    if (this.question.arrOptions.includes(this.newString))
      this.newString2 = 'lockheed-martin-cyber-kill-chain';
    else
      this.newString2 = temp;
    console.log(this.newString2)*/

    if (this.question.arrOptions.includes(this.newString))
      this.question.relString = 'lockheed-martin-cyber-kill-chain';
  }


  deleteString(myobj: any, mykey: any) {
    let curr = this.stixService.stringArrays.get(mykey);
    curr = curr.filter(obj => obj !== myobj);
    this.stixService.stringArrays.set(mykey, curr);
    if (mykey == 'extension_types') {
      if (myobj.substring(0, 4) == 'new-') {
        let regex = new RegExp(/^new-s[drc]o \((.*)\).*$/); // Extracts the ID
        let matches = myobj.match(regex);
        this.stixService.modalObjectArray = this.stixService.modalObjectArray.filter(obj => obj['id'] != matches[1]);
      }
      if (this.stixService.isEditing) {
        let id = this.stixService.currentID;
        let currObject = this.stixService.getObjectFromID(id);
        this.stixService.removeExtensionDefChildren(id, currObject, myobj, true);
      }
    } else if (mykey === 'killChain'){
      this.dynamicForm.isAddEnabled();
    }

    this.checkDuplicates.emit();
  }

  getPropertyNames(): string[] {
    return this.propertyNames;
  }  

  populatePropertyNames(): string[] {
    let propertyNames = [];
    if (this.currObjectPath) {
      const currentObject = COMPONENT_MAP.get(this.currObjectPath.type) as FormModel;
      let questions = currentObject.getQuestions();
      for (let i = 0; i < questions.length; i++) {
        if (questions[i].key === 'type' 
          || questions[i].key === 'id' 
          || questions[i].key === 'spec_version'
          || questions[i].key === 'defanged'
          || questions[i].key.includes('_ref')
          || (questions[0].value === 'email-message' && questions[i].key === 'body')) 
            continue;
        let propertyName = this.buildPropertyNames(questions[i], '')[0];
        if (!propertyNames.includes(propertyName)) {
          propertyNames = propertyNames.concat(propertyName);
        }        
      }
    }
    //this.currPropertyName = propertyNames[0];
    return propertyNames;
  }

  setPropertyNames(): void {
    if (!this.scoBundleObjectSelected) {
      this.propertyNames = this.populatePropertyNames();
      return;
    }

    let propertyNames = [];

    if (this.currObjectPath) {
      const currentObject = COMPONENT_MAP.get(this.currObjectPath.type) as FormModel;
      let questions = currentObject.getQuestions();
      for (let i = 0; i < questions.length; i++) {
        if (this.scoBundleObjectSelected[questions[i].key] === undefined)
          continue;

        if (questions[i].key === 'type' 
          || questions[i].key === 'id' 
          || questions[i].key === 'spec_version'
          || questions[i].key === 'defanged') 
            continue;

        if (questions[0].value === 'email-message') 
          if (questions[i].key === 'body'
            || questions[i].key === 'body_multipart'
            || questions[i].key === 'additional_header_fields'
            || questions[i].key === 'raw_email_ref')
              continue;
        
        if (questions[0].value === 'windows-registry-key')
          if (questions[i].key === 'values')
            continue;
        
        if (questions[0].value === 'software')
          if (questions[i].key === 'languages')
            continue;
            
        if ((questions[0].value !== 'email-message' && questions[0].value !== 'network-traffic') && questions[i].key.includes('_ref')) 
          continue;

        if (questions[0].value === 'network-traffic')
          if (questions[i].key === 'start'
            || questions[i].key === 'end'
            || questions[i].key === 'is_active')
              continue;

        let propertyName = this.buildPropertyNames(questions[i], '');
        if (!propertyNames.includes(propertyName)) {
          propertyNames = propertyNames.concat(propertyName);
        }
        // Omitted below per instruction: remove ref/refs from the dropdown list for pattern builder.
        // If the key is a reference to another object, we need to add the appropriate parameters
        // if (questions[i].key.includes('_ref')) {
        //   for (let k = 0; k < questions[i].allowedRefsMap.length; k++) {
        //     const allowedRef = this.componentMap.get(questions[i].allowedRefsMap[k]) as FormModel;
        //     if (allowedRef) {
        //       const allowedRefQuestions = allowedRef.getQuestions();
        //       for (let l = 0; l < allowedRefQuestions.length; l++) {
        //         propertyName = this.buildPropertyNames(allowedRefQuestions[l], questions[i].key)[0];
        //         if (!propertyNames.includes(propertyName)) {
        //           propertyNames = propertyNames.concat(propertyName);
        //         }
        //       }
        //     }
        //   }
        // }
      }
    }

    this.propertyNames = propertyNames;
  }

  private buildPropertyNames(question: QuestionBase<any>, baseType: string): string[] {
    let refValues = [];
    console.log('QUESTIONS: ', question, this.scoBundleObjectSelected)
    // Only include single quotes if the property has a dash ('-') or full stop ('.')
    let formattedQuestion = question.key;
    if (question.key.includes('-') || question.key.includes('.'))
      formattedQuestion = `'${formattedQuestion}'`;

    if (baseType)
      formattedQuestion = `${baseType}.${formattedQuestion}`;

    if (this.scoBundleObjectSelected) {
      switch (question.constructor) {
        case HashArrayQuestion: {
          let typedQuestion = question as HashArrayQuestion;
          for (let j = 0; j < typedQuestion.hashes.length; j++) {
            if (this.scoBundleObjectSelected.hashes[typedQuestion.hashes[j]] === undefined)
              continue;
            // Only include single quotes if the property has a dash ('-') or full stop ('.')
            if (typedQuestion.hashes[j].includes('-') || typedQuestion.hashes[j].includes('-'))
              refValues.push(`${formattedQuestion}.${typedQuestion.hashes[j]}`);
            else
              refValues.push(`${formattedQuestion}.${typedQuestion.hashes[j]}`);
          }

          break;
        }
        case OpenVocabArrayQuestion:
        case ProtocolArrayQuestion: {
          let typedQuestion = question as ProtocolArrayQuestion;

          for (let j = 0; j < typedQuestion.options.length; j++) {
            if (!this.scoBundleObjectSelected.protocols.includes(typedQuestion.options[j].key))
              continue;
            let formattedOption = typedQuestion.options[j].key;
            if (formattedOption.includes('-') || formattedOption.includes('.'))
              formattedOption = `'${formattedOption}'`;

            refValues.push(`${formattedQuestion}[${j}].${formattedOption}`)
          }

          break;
        }
        case ReferenceArrayQuestion:
        case StringArrayQuestion:
        case TooltipStringArrayQuestion: {
          let typedQuestion = question as TooltipStringArrayQuestion;
          this.scoBundleObjectSelected[typedQuestion.key].forEach((elem, index) => {
            refValues.push(`${formattedQuestion}.${elem}`);
          });

          break;
        }
        case RefArrayQuestion: {
          let typedQuestion = question as RefArrayQuestion;
          for (let j = 0; j < this.scoBundleObjectSelected[typedQuestion.key].length; j++) {
            let formattedOption = this.scoBundleObjectSelected[typedQuestion.key][j]
            console.log(formattedOption)
            refValues.push(`${formattedQuestion}.${formattedOption}`)
          }

          break;
        }
        case ReferenceArrayQuestion:
        case HybridArrayQuestion: {
          // No values are in the array so we are using the preselector for all items
          let typedQuestion = question as HybridArrayQuestion;
          this.scoBundleObjectSelected[typedQuestion.key].forEach(elem => {
            refValues.push(`${formattedQuestion}.${elem}`)
          });

          break;
        }
        case StringDictQuestion: {
          let typedQuestion = question as StringDictQuestion;
          const currSelected = this.scoBundleObjectSelected[typedQuestion.key]
          for (const key in currSelected) {
            refValues.push(`${formattedQuestion}.${key}`)
          }
          break;
        }
        default:
          refValues.push(`${formattedQuestion}`);
      }
    } else {
          refValues.push(`${formattedQuestion}`);
    }
    console.log(refValues);
    return refValues;
  }

  /**
   * This will open the modal responsible for building stix patterns
   * 
   * @param modal modal content sent in from the local variable in the HTML (might want to data bind this to a typescript variable but for now it's good)
   */
  openModal(modal, editing?): void {
    if (this.stixService.currentType != 'extension-definition' && !editing) {
      this.currentModalObject = new Map();
    }
    console.log(modal);
    switch(modal){
      case 'stixPatternBuilder':
        var patternRef = this.patternBuilderDialog.open(PatternBuilderComponent, {
          data: {},
          minHeight: '30vh',
          maxHeight: `80vh`,//`${window.innerHeight}px`,
          width: `${window.innerWidth}px`,
          maxWidth: '95vw',
          position: {
            top: "100px"
          }
        });
  
        patternRef.afterClosed().subscribe(result => {
            if (result){
              this.form.controls['pattern'].setValue(result.pattern);
            }
        });
        break;
      case 'body_multipart':
        let tempRefs = this.getEmailMessageAllowedRefs();
        let multipartData = {
          allowedRefs: tempRefs,
          mode: 'regular',
          body: '',
          body_raw_ref: '',
          content_type: '',
          content_disposition: ''
        };

        if(editing){
          for(let field in this.currentModalObject){
            multipartData[field] = this.currentModalObject[field];
          }
        }

        var multipartRef = this.multipartDialog.open(MultipartDialogComponent, {
          data: multipartData,
          minHeight: '200px',
          maxHeight: '600px',
          width: `${window.innerWidth / 1.25}px`,
          position: {
            top: '200px'
          }
        });
  
        multipartRef.afterClosed().subscribe(result => {
            if (result){
              for(let field in result){
                this.currentModalObject[field] = result[field];
              }

              this.addModalObject('body_multipart'); 
              this.isAddEnabled.emit();
            }
        });
        break;
      case 'windows_value':
        let windowsValueData = {
          mode: 'regular',
          name: '',
          data: '',
          data_type: ''
        };

        if(editing){
          for(let field in this.currentModalObject){
            windowsValueData[field] = this.currentModalObject[field];
          }
        }
        var windowsValueRef = this.windowsValueDialog.open(WindowsValueDialogComponent, {
          data: windowsValueData,
          minHeight: '150px',
          maxHeight: '600px',
          width: `${window.innerWidth / 1.25}px`,
          position: {
            top: '200px'
          }
        });
  
        windowsValueRef.afterClosed().subscribe(result => {
            if (result){
              for(let field in result){
                this.currentModalObject[field] = result[field];
              }

              this.addModalObject('windows_value'); 
              this.isAddEnabled.emit();
            }
        });
        break;
    }
    
  }

  getEmailMessageAllowedRefs() {
    let result = this.stixService.bundle.objects;
    return result.filter(obj => obj.type === "artifact" || obj.type === "file");
  }

  /**
   * If a comparison expression is created, the user can select to remove it prior to moving on if they've made a mistake
   * 
   * @param index index of the comparison expression in the array to remove
   */
  removeComparisonExpression(index: number): void {
    this.comparisonExpressions.splice(index, 1);
  }

  /**
   * Reset stix pattern builder modal for new creation if user selects it again
   */
  resetStixPatternBuilder(): void {
    this.comparisonConstant = null;
    this.comparisonExpressions = [];
    this.currObjectPath = null;
    this.observationExpressionBuilder = null;
    this.observationExpressions = [];
    this.patternExpressions = [];
    this.page = 1;
    this.pageSize = 4;
    this.selectedComparisonOperator = '=';
    this.selectedObservationOperator = 'AND';
    this.selectedStixPatternBuilder = null;
    this.validationMessage = '';
    this.scoBundleObjectSelected = null;
  }

  /**
   * Add stix pattern to form/question
   */
  saveStixPattern(): void {
    this.form.controls['pattern'].setValue(this.selectedStixPatternBuilder.getDisplayForStixPattern().trim());
    this.modalService.dismissAll();
    this.resetStixPatternBuilder();
  }

  updatePropertyNames(objectPath: any): void {
    this.currObjectPath = objectPath;
    this.setPropertyNames();
    let propertyNames = this.getPropertyNames();
    if (propertyNames.length > 1) {
      this.currPropertyName = propertyNames[0];
      this.comparisonConstant = this.scoBundleObjectSelected[propertyNames[0]]
    }
  }

  typeInBundle() {
    return this.currObjectPath ? this.bundle.objects.some(x => x.type === this.currObjectPath.type) : false;
  }

  getBundleObjects() {
    let result = [];
    let objs = this.stixService.bundle.objects.concat(this.stixService.customObjects);

    switch (this.question.key) {
      case 'creator_user_ref':
        return result.concat(objs.filter(o => o.id.includes('user-account')));
      case 'installed_software_refs':
        return result.concat(objs.filter(o => o.id.includes('software')));
      case 'object_refs':
        this.sortBundleObjects();
        if (this.object_type === 'observed-data') {
          return result.concat(objs.filter(o => {
            return SCO_LIST.includes(o.type) || o.type === 'relationship' || o.type === 'sighting'
          }));
        }
        
        // if(this.stixService.bundle.objects && this.stixService.bundle.objects.length === 1 && this.stixService.bundle.objects[0] === null) return [];
        return result.concat(objs);
      default:
        this.sortBundleObjects();
        return result.concat(objs);
    }
  }

  openTooltipModal(modal) {
    this.modalRef = this.modalService.open(modal);
  }

  openLargeSubModal(modal) {
    this.modalRef = this.modalService.open(modal, { size: 'xl' });
  }

  openCustomObjModal(modal){
    console.log("hm")
    this.modalService.open(modal, { ariaLabelledBy: 'custom-obj-modal-title', windowClass: 'custom-obj-modal' }).result.finally(() => {
      console.log("Custom Obj Created");
    });
    console.log("Heh")
  }

  closeTooltip() {
    this.modalRef.close();
  }

  /**
   * Add current object being created in modal to parent
   */
  isModalAddEnabled(): boolean {
    if (this.currentModalObject['body_raw_ref'])
      this.validType(['artifact', 'file'], this.currentModalObject['body_raw_ref'], 'body_raw_ref');

    if (this.stixService.currentType == 'email-message' && (this.currentModalObject['body'] == undefined || this.currentModalObject['body'] == '') && (this.currentModalObject['body_raw_ref'] == undefined || this.currentModalObject['body_raw_ref'] == ''))
      this.modalError.set('object', 'Either Body or Body Raw Ref is required ');
    else
      this.modalError.delete('object');

    for (let key of this.modalError.keys()) {
      if (!this.currentModalObject[key] && key != 'object' && key != 'customName') // Removes the error if the field is now empty
        this.modalError.delete(key);

      // Returns false if there is a validation error on a field
      if (this.modalError.get(key) && !(this.modalError.get(key).includes('WARNING:')) && !(this.modalError.get(key).includes('hashes'))) {
        return this.modalEnabled = false;
      }
    }

    if (this.isEmpty(this.currentModalObject))
      return this.modalEnabled = false;

    return this.modalEnabled = true;
  }
  
  addModalObject(type: string): void {
    if (this.editedModalObject) {
      this.deleteModalObject(this.editedModalObject);
      this.editedModalObject = undefined;
    }

    //this.form.controls['pattern'].setValue(this.selectedStixPatternBuilder.getDisplayForStixPattern().trim());
    if (this.customObjType.length > 0) {  // If it is a custom object
      let currType = this.newString + ' (' + this.currentModalObject['id'] + ')';
      if (this.stixService.stringArrays.has(this.question.key)) {
        let newStringArray = this.stixService.stringArrays.get(this.question.key)!;
        newStringArray.push(currType);
        this.stixService.stringArrays.set(this.question.key, newStringArray);
      } else
        this.stixService.stringArrays.set(this.question.key, [currType]);
      this.newString = '';
      this.customObjType = '';

      if (this.currentModalObject.get('modal_labels')) {
        this.currentModalObject['labels'] = this.currentModalObject.get('modal_labels');
        this.currentModalObject.delete('modal_labels');
      }
      if (this.currentModalObject['revoked'])
        this.currentModalObject['revoked'] = JSON.parse(this.currentModalObject['revoked']);
      if (this.currentModalObject['defanged'])
        this.currentModalObject['defanged'] = JSON.parse(this.currentModalObject['defanged']);
    }


    console.log(this.currentModalObject);
    this.stixService.modalObjectArray.push(this.currentModalObject);
    this.modalService.dismissAll();
    this.modalEnabled = false;
  }

  editModalObject(currObject: any, modal: any, index: number = null): void {
    this.currentModalObject = new Map();
    this.editedModalObject = currObject;
    console.log(currObject);

    if (this.stixService.currentType == 'extension-definition') {
      let regex = new RegExp(/^.*\"extension_type\":\"(new-...).*$/);
      let matches = JSON.stringify(currObject).match(regex);
      if (matches) {
        this.newString = matches[1];

        let t = '';
        switch (this.newString) {
          case 'new-sdo': t = 'SDO'; break;
          case 'new-sco': t = 'SCO'; break;
          case 'new-sro': t = 'SRO'; break;
        }
        this.customObjType = t;
      }
      else {
        console.error("ERROR: Not a valid Custom Object Type (must be sdo/sro/sco)");
        return;
      }
    }

    for (let x in currObject) {
      console.log(x);
      this.currentModalObject[x] = currObject[x];
    }

    if (modal === 'language_contents') {
      this.openLanguageModal(this.editedModalObject, index);
    } else {
      this.openModal(`${modal}`, true);
    }


  }

  deleteModalObject(currObject: any): void {
    this.stixService.modalObjectArray = this.stixService.modalObjectArray.filter(obj => obj !== currObject);
    if (JSON.stringify(currObject).includes('id')) {
      let regex = new RegExp(/^.*"id": "(.*)",.*$/);
      console.log(currObject['id']);
      let curr = this.stixService.stringArrays.get('extension_types') || [];
      curr = curr.filter(obj => !obj.includes(currObject['id']));
      this.stixService.stringArrays.set('extension_types', curr);
    }
    this.stixService.contents = this.stixService.contents.filter(obj => obj !== currObject);
  }

  isEmpty(currObject: any, arrays?: string[]): boolean {
    /* for (let i in arrays) {
      if ((arrays[i].includes('hashes') && this.hashes.size > 0) || (this.stixService.stringArrays.get(arrays[i]) || []).length > 0)
        return false;
    } */
    for (let prop in currObject) {
      let p = currObject[prop];
      /* if (prop == 'private_key_usage_period_not_before' || prop == 'private_key_usage_period_not_after' || prop == 'time_date_stamp') {
        p = Date.parse(p);
      } */
      if (p && p != '' && prop != 'extension_id' && prop != 'extension_type' && (typeof p == 'string' || typeof p == 'number' || typeof p == 'boolean')) {
        //console.log(prop)
        return false;
      }
    }
    return true;
  }

  /**
   * Input Validation Functions
   */
  validType(types: any, currString: string, currObject: string): boolean {
    if (currString == '') // Does not run on (change) when empty -- DOES NOT WORK
      this.modalError.delete(currObject);
    if (!(types instanceof Array))
      types = [types];

    if (currString.match(this.objectRegex)) {
      if ((types.indexOf((currString.match(this.objectRegex) || ['asdf', 'asdf'])[1]) != -1)) {
        this.modalError.delete(currObject);
        return true;
      }
      else
        this.modalError.set(currObject, 'Object must be a UUID of type ' + types);
    }
    else
      this.modalError.set(currObject, 'Must be a valid UUID (i.e. [object type]--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)');
    return false;
  }

  getBody_MultipartHeading(curr: any): string {
    let heading = '';
    if (curr['body']) {
      heading = curr['body'];
      if (curr['body_raw_ref'])
        heading += ';  ' + curr['body_raw_ref'];
    }
    else
      heading = curr['body_raw_ref'];

    return heading;
  }

  getExtTypeOpt(options: { key: string, value: string }[]): { key: string, value: string }[] {
    let ret = options;
    if (this.stixService.stringArrays.has(this.question.key)) {
      for (let x of this.stixService.stringArrays.get(this.question.key)) {
        if (x.includes('new-')) {
          x = x.substring(0, 7);
        }
        ret = ret.filter(obj => obj.key != x);
      }
    }
    return ret;
  }

  addExtensionType(modal: any): void {
    this.currentModalObject = new Map();
    this.editedModalObject = undefined;
    let currType = this.newString;

    // Handles Property Extensions
    if (currType.includes('extension')) {
      if (this.stixService.stringArrays.has(this.question.key)) {
        let newStringArray = this.stixService.stringArrays.get(this.question.key)!;
        if (newStringArray.indexOf(currType) == -1) {
          newStringArray.push(currType);
          this.stixService.stringArrays.set(this.question.key, newStringArray);
        }
      } else
        this.stixService.stringArrays.set(this.question.key, [currType]);
      //this.question.options = this.question.options.filter(obj => obj.key != this.newString);
      this.newString = '';
      return;
    }


    /**
     * Handles New Objects
     */

    // Opens corresponding modal
    let t = '';
    switch (this.newString) {
      case 'new-sdo': t = 'SDO'; break;
      case 'new-sco': t = 'SCO'; break;
      case 'new-sro': t = 'SRO'; break;
    }
    this.customObjType = t;
    if (t != '')
      this.openCustomObjModal(modal);
    else
      console.log('ERROR: NOT A VALID EXTENSION TYPE');

    // Resets properties
    //this.newString = '';
    this.customStringPropKeys = [];
    this.customArrPropKeys = [];
    this.currentModalObject = new Map();
    this.customProp = new Map();

    this.currentModalObject['type'] = '';
    this.currentModalObject['id'] = `[type]--${uuid()}`;
    this.currentModalObject['spec_version'] = "2.1";

    if (this.customObjType != 'SCO') {
      // Sets to empty strings for validation purposes (so undefined does no have to be checked)
      this.customProp['type'] = '';
      this.customProp['name'] = '';
      this.customProp['text'] = '';
      this.customProp['boolean'] = '';
      this.customProp['integer'] = '';
      this.customProp['datetime'] = '';

      // Sets datetime fields to current
      let date = (new Date()).toISOString();
      this.currentModalObject['created'] = date;
      this.currentModalObject['modified'] = date;

      // Retrieves and sets (if applicable) Created by Ref Identities
      let ids = JSON.parse(JSON.stringify({ "arr": [] }));
      if (localStorage.getItem("identities")) {
        try {
          ids = JSON.parse(localStorage.getItem("identities")!) || JSON.parse(JSON.stringify({ "arr": [] }));
        } catch (e) {
          console.error("Error parsing saved identities with message: ", e);
          localStorage.removeItem("identities");
        }
      }

      this.ids = ids.arr;
      const temp = this.stixService.bundle.objects;

      for (var i = 0; i < temp.length; i++)
        if (temp[i].id.includes('identity--'))
          if (!this.ids.find(a => a.id === temp[i].id))
            this.ids.push(temp[i]);

      if (this.ids.length == 1 && this.currentModalObject['created_by_ref'] == undefined || (this.currentModalObject['created_by_ref'] == ''))
        this.currentModalObject['created_by_ref'] = this.ids[0].id;
    }

    // Sets extensions to include the current extension
    this.currentModalObject['extensions'] = new Map();
    this.currentModalObject['extensions'][this.stixService.parentExtensionID] = { "extension_type": this.newString };
  }

  isCustomObjAddEnabled(): boolean {
    //console.log(this.stixService.customObjReadOnly);
    let ret = this.checkType(this.currentModalObject['type']);
    // this.changeDetector.detectChanges();

    for (let key of this.modalError.keys()) {
      if (!this.currentModalObject[key] && key != 'object' && key != 'customName') // Removes the error if the field is now empty
        this.modalError.delete(key);

      // Returns false if there is a validation error on a field
      if (this.modalError.get(key) && !(this.modalError.get(key).includes('WARNING:')) && !(this.modalError.get(key).includes('hashes'))) {
        ret = false;
      }
    }
    //ret == ret && !emptyObj();
    return ret;
  }

  checkType(currType: string): boolean {
    if (currType == undefined || currType == '') {
      this.modalError.delete('type');
      return false;
    }
    const lowerRegex = new RegExp(/^([a-z0-9]*-*)*$/);
    if (!lowerRegex.test(currType)) {
      this.modalError.set('type', 'Type may only contain lowercase letters, numbers, and hyphens (-)');
      return false;
    }
    const doubleHyphenRegex = new RegExp(/^.*--.*$/);
    if (doubleHyphenRegex.test(currType)) {
      this.modalError.set('type', 'Type may not have two consecutive hypens (--)');
      return false;
    }

    if (currType.length < 3) {
      this.modalError.set('type', 'Type must have a minimum length of 3 characters');
      return false;
    }

    if (currType.length > 250) {
      this.modalError.set('type', 'Type must have a maximum length of 250 characters');
      return false;
    }

    const idRegex = new RegExp(/^(.*)(--.*)$/);
    let matches = this.currentModalObject['id'].match(idRegex);
    if (matches) {
      this.currentModalObject['id'] = currType + matches[2];
    }
    else
      console.log('ERROR: INVALID ID');

    this.modalError.delete('type');
    return true;
  }

  checkName(currName: string): boolean {
    if (currName == undefined || currName == '') {
      this.modalError.delete('customName');
      return false;
    }
    const lowerRegex = new RegExp(/^([a-z0-9]*_*)*$/);
    if (!lowerRegex.test(currName)) {
      this.modalError.set('customName', 'Name may only contain lowercase letters, numbers, and underscores (_)');
      return false;
    }

    if (currName.length < 3) {
      this.modalError.set('customName', 'Name must have a minimum length of 3 characters');
      return false;
    }

    if (currName.length > 250) {
      this.modalError.set('customName', 'Name must have a maximum length of 250 characters');
      return false;
    }

    this.modalError.delete('customName');
    return true;
  }


  /**
   * String Array/Dict Functions
   */
  addModalString(currString: string, currArray: string): void {
    if (this.currentModalObject.has(currArray)) {
      let newStringArray = this.currentModalObject.get(currArray)!;
      if (newStringArray.indexOf(currString) == -1) {
        newStringArray.push(currString);
        this.currentModalObject.set(currArray, newStringArray);
      }
    } else
      this.currentModalObject.set(currArray, [currString]);

    if (this.modalProps[currArray] == currString)
      this.modalProps[currArray] = '';
  }

  deleteModalString(currString: string, currArray: string) {
    let curr = this.currentModalObject.get(currArray);
    curr = curr.filter(obj => obj !== currString);
    if (curr.length > 0)
      this.currentModalObject.set(currArray, curr);
    else
      this.currentModalObject.delete(currArray);
  }

  addSubModalString(currString: string, currArray: string): void {
    if (this.customProp.has(currArray)) {
      let newStringArray = this.customProp.get(currArray)!;
      if (newStringArray.indexOf(currString) == -1) {
        newStringArray.push(currString);
        this.customProp.set(currArray, newStringArray);
      }
    } else
      this.customProp.set(currArray, [currString]);

    if (this.customProp['value'] == currString)
      this.customProp['value'] = '';
  }

  addSubModalDictString(currKey: string, currValue: string, currDict: string): void {
    this.customProp.get(currDict).set(currKey, currValue);
    this.customProp.get(currDict)[currKey] = currValue;

    if (this.customProp['value'] == currValue) {
      this.customProp['key'] = '';
      this.customProp['value'] = '';
    }
  }
  deleteSubModalDictString(currKey: string, currDict: string) {
    this.customProp.get(currDict).delete(currKey);
    delete this.customProp.get(currDict)[currKey];
  }

  deleteSubModalString(currString: string, currArray: string) {
    let curr = this.customProp.get(currArray);
    curr = curr.filter(obj => obj !== currString);
    if (curr.length > 0)
      this.customProp.set(currArray, curr);
    else
      this.customProp.delete(currArray);
  }

  createProperty(modal?: any): void {
    let type = this.customProp['type'];
    let name = this.customProp['name'];
    if (this.currentModalObject[name]) {
      this.modalError.set('customName', 'Property with this name already exists');
      return;
    }
    else
      this.modalError.delete('customName');

    if (!this.checkName(name))
      return;

    if (type == 'array' || type == 'dict') {
      this.customProp.set('customArray', []);
      this.customProp['key'] = '';
      this.customProp['value'] = '';
      this.customProp.set('customDict', new Map());
      this.openLargeSubModal(modal);
    }
    else {
      if (type == 'text')
        this.currentModalObject[name] = this.customProp['text'];
      else if (type == 'boolean')
        this.currentModalObject[name] = JSON.parse(this.customProp['boolean']);
      else if (type == 'integer')
        this.currentModalObject[name] = parseInt(this.customProp['integer']) || undefined;
      else if (type == 'datetime')
        this.currentModalObject[name] = this.customProp['datetime'];
      this.customStringPropKeys.push(name);
      this.customProp['type'] = '';
      this.customProp['name'] = '';
      this.customProp['text'] = '';
      this.customProp['boolean'] = '';
      this.customProp['integer'] = '';
      this.customProp['datetime'] = '';
    }
  }

  createSubProperty(): void {
    let type = this.customProp['type'];
    let name = this.customProp['name'];
    if (type == 'array') {
      this.currentModalObject[name] = this.customProp.get('customArray');
    }
    else if (type == 'dict') {
      this.currentModalObject[name] = this.customProp.get('customDict');
    }

    if (!this.customArrPropKeys.includes(name))
      this.customArrPropKeys.push(name);

    this.customProp['type'] = '';
    this.customProp['name'] = '';
    this.customProp['text'] = '';
    this.customProp['boolean'] = '';
    this.customProp['datetime'] = '';
    this.closeTooltip();
  }

  deleteProperty(currString: string): void {
    this.currentModalObject[currString] = undefined;
    this.customStringPropKeys = this.customStringPropKeys.filter(obj => obj !== currString);
    this.customArrPropKeys = this.customArrPropKeys.filter(obj => obj !== currString);
  }

  openLanguageModal(editModalObject: any = null, index: number = null) {
    this.modalRef = this.modalService.open(ContentsDialogComponent, { ariaLabelledBy: 'pattern-builder-modal-title', 
    windowClass: 'pattern-builder-modal', backdrop : 'static', keyboard : false });

    this.modalRef.componentInstance.form = this.form;

    setTimeout(() => {
      if (editModalObject) {
        const data = {
          data: this.editedModalObject,
          index: index,
        }
        this.stixService.sendData(data);
      }
    }, 300)

    this.modalRef.result.finally(() => {
      this.stixService.modalObjectArray = this.stixService.contents;
    })
  }

  getContentsDisplay(content: Content): any {
    return JSON.parse(`{ ${content.toString()} }`);
  }

  deleteExpression(compExpIndex = null, observExpIndex = null, patternExpIndex = null) {
    if (compExpIndex !== null) {
      this.comparisonExpressions.splice(compExpIndex, 1);
    }

    if (observExpIndex !== null) {
      this.observationExpressions.splice(observExpIndex, 1);
    }

    if (patternExpIndex !== null) {
      this.patternExpressions.splice(patternExpIndex, 1);
    }
  }

  disableContinue() {
    switch (this.page) {
      case 1:
        return this.comparisonExpressions.length === 0 && this.observationExpressions.length === 0;
      case 2:
        return this.observationExpressions.length === 0;
      case 3:
        return this.patternExpressions.length === 0;
      default:
        return false;
    }
  }

  disableSaveStixPattern() {
    if (this.selectedStixPatternBuilder) {
      if (this.selectedStixPatternBuilder.qualifierForDropdown === 'START'
        && (this.selectedStixPatternBuilder.getQualifier.startDate === undefined
          || this.selectedStixPatternBuilder.getQualifier.endDate === undefined)) {
        return true;
      }
      if (this.selectedStixPatternBuilder.qualifierForDropdown === 'WITHIN'
        && this.selectedStixPatternBuilder.getQualifier.seconds === undefined) {
        return true;
      }
      if (this.selectedStixPatternBuilder.qualifierForDropdown === 'REPEATS'
        && this.selectedStixPatternBuilder.getQualifier.times === undefined) {
        return true;
      }
    }

    if (this.page == this.pageSize
      && !this.selectedStixPatternBuilder) {
      return true;
    }

    return false;
  }

  showSetOperator() {
    if ((this.currObjectPath.type === 'ipv4-addr' && this.currPropertyName === 'value')
      || (this.currObjectPath.type === 'ipv6-addr' && this.currPropertyName === 'value')
      || (this.currObjectPath.type === 'network-traffic' && this.currPropertyName === 'dst_ref.value')
      || (this.currObjectPath.type === 'network-traffic' && this.currPropertyName === 'src_ref.value')
      || (this.currObjectPath.type === 'domain-name' && this.currPropertyName === 'resolves_to_refs.value')
    ) {
      return true;
    }

    return false;
  }

  clickContinue() {
    this.selectedStixPatternBuilder = null;
  }

  updateExpressionConstant(event, component = null) {
    if (component !== null)
      this.scoBundleObjectSelected = component;
    this.setPropertyNames();
    let firstPropertyName = this.currPropertyName, secondPropertyName: string;
    if (this.currPropertyName?.includes('.')) {
      [firstPropertyName, secondPropertyName] = this.currPropertyName.split('.');
      if (firstPropertyName.includes('['))
        firstPropertyName = firstPropertyName.split('[')[0]
    }
    if (this.scoBundleObjectSelected[firstPropertyName] === undefined) {
      this.currPropertyName = this.getPropertyNames()[0];
      firstPropertyName = this.currPropertyName;
      secondPropertyName = '';
    }
    if (this.scoBundleObjectSelected && firstPropertyName) {
      if (Array.isArray(this.scoBundleObjectSelected[firstPropertyName])) {
        this.comparisonConstant = secondPropertyName;
        return;
      }
      // this.comparisonConstant = this.scoBundleObjectSelected[event.target.value];
      this.comparisonConstant = secondPropertyName 
        ? this.scoBundleObjectSelected[firstPropertyName][secondPropertyName] 
        : this.scoBundleObjectSelected[this.currPropertyName];
    }
  }

  /**
     * Get display name from each object either the name or a predefined field/format
     */
  getObjDisplayName(opt: any): string {
    let displayString = '';
    switch(opt.type) {
      case 'malware-analysis':
        return opt.id;

      case 'artifact': 
        displayString = (opt.url? opt.url : '') + (opt.payload_bin? opt.payload_bin : '');
        break;

      case 'autonomous-system':
        displayString = opt.number + (opt.name? ' (' + opt.name + ')' : '');
        break;

      case 'directory':
        displayString = opt.path;
        break;

      case 'email-message':
        displayString = opt.subject? opt.subject : 'No Subject Included';
        break;

      case 'file': 
        if (opt.name) displayString = opt.name;
        else if (opt.hashes) displayString = Object.keys(opt.hashes)[0] + ': ' + opt.hashes[Object.keys(opt.hashes)[0]];
        else displayString = '';
        break;     

      case 'domain-name':
      case 'email-addr':
      case 'ipv4-addr':
      case 'ipv6-addr':
      case 'mac-addr':
      case 'url':
        displayString = opt.value;
        break;
      
      case 'process':
        if (opt.pid) displayString = opt.pid;
        else if (opt.cwd) displayString = opt.cwd;
        else displayString = '';
        break;       
      
      /* case 'relationship': 
        if (opt.source_ref && opt.target_ref) {
            let sourceRefObject = this.stixService.bundle.objects.filter(obj => obj.id === opt.source_ref);
            let targetRefObject = this.stixService.bundle.objects.filter(obj => obj.id === opt.target_ref);
            if (sourceRefObject.length > 0 && targetRefObject.length > 0)
              displayString = this.getObjDisplayName(sourceRefObject[0]).split('|')[0].trim()+ ' -> ' 
              + this.getObjDisplayName(targetRefObject[0]).split('|')[0].trim();
            else
              displayString = '';
        } else displayString = '';
        break; */  
        
      case 'relationship':
        if (opt.source_ref && opt.target_ref) {
            let sourceRefObject = this.stixService.bundle.objects.filter(obj => obj.id === opt.source_ref);
            let targetRefObject = this.stixService.bundle.objects.filter(obj => obj.id === opt.target_ref);
            let sourceDisplay = sourceRefObject.length > 0 ? this.getObjDisplayName(sourceRefObject[0]).split('|')[0].trim() : opt.source_ref;
            let targetDisplay = targetRefObject.length > 0 ? this.getObjDisplayName(targetRefObject[0]).split('|')[0].trim() : opt.target_ref;
            let relationship_type = opt.relationship_type;
            displayString = `${sourceDisplay} ${relationship_type} ${targetDisplay}`;
        } else
            displayString = `<NO NAME> (${opt.id})`;
        break;
      
      
      case 'impact':
          displayString = opt.impact_category ? opt.impact_category.charAt(0).toUpperCase() + opt.impact_category.slice(1) : `(${opt.id})`;
          break;
      case 'user-account': 
        if (opt.user_id) displayString = opt.user_id;
        else if (opt.account_login) displayString = opt.account_login;  
        else if (opt.display_name) displayString = opt.display_name; 
        else displayString = '';
        break;

      case 'windows-registry-key': 
        if (opt.key) displayString = opt.key;
        else displayString = '';
        break;

      case 'x509-certificate':
        if (opt.subject) displayString = opt.subject;
        else if (opt.serial_number) displayString = opt.serial_number;
        else if (opt.hashes) displayString = Object.keys(opt.hashes)[0] + ': ' + opt.hashes[Object.keys(opt.hashes)[0]];
        else if (opt.issuer) displayString = opt.issuer;
        else displayString = '';
        break;

      case 'attack-pattern':
      case 'campaign': 
      case 'course-of-action': 
      case 'grouping':
      case 'identity':
      case 'incident':
      case 'indicator':
      case 'infrastructure':
      case 'intrusion-set': 
      case 'location': 
      case 'malware':
      case 'note':
      case 'observed-data':
      case 'opinion': 
      case 'report':
      case 'threat-actor': 
      case 'tool': 
      case 'vulnerability':      
      case 'sighting':      
      case 'mutex':
      case 'network-traffic':      
      case 'software': 
      case 'language-content': 
      case 'marking-definition':
      case 'extension-definition':
      case 'event':
      case 'task':
      case 'malware-behavior':
      case 'malware-method':
      case 'malware-objective':
        displayString = opt.name
        break;

      default:
        displayString = opt.type;
    }
    if (displayString && displayString.length > 100) displayString = `${displayString.substring(0, 97) + '...'}`;
    if (displayString) return displayString + ' | ' + opt.id;
    else return '<NO NAME> | ' + opt.id;
  }  

  /**
   * Sort bundle objects with the order predefined in STIX_OBJECTS. Custom Objects attached at last.
   */
  sortBundleObjects() {
    let objOrder = [];
    for (let stixObj of STIX_OBJECTS) {
      for (let obj of stixObj.objects)
        objOrder.push(obj.type);        
    }
    this.stixService.bundle.objects.sort((a, b) => {
      let i = objOrder.indexOf(a.type) === -1? 99 : objOrder.indexOf(a.type);
      let j = objOrder.indexOf(b.type) === -1? 99 : objOrder.indexOf(b.type);
      return i - j;
    });
  }

  questionLabel(question) {
    if (this.stixService.guidedUI) {
      let label = question.label;
      if (label[label.length - 1] === '*') {
        label = label.slice(0, label.length - 1);
      }
      return label;
    }
    return question.label;
  }
  
  onChooseFileHandler(event) {
    if (event.target.files[0]) {
      const allowedExtensions = /(\.json)$/i;
      if (!allowedExtensions.exec(event.target.files[0].name)) {
        this.message = 'Please select a json (*.json) file to upload.';
      } else {
        this.loadedFile = event.target.files[0];
        this.loadedFileName = this.loadedFile.name;
      }
    }
    const fileReader = new FileReader();    
    fileReader.onload = () => {
        this.schema = JSON.parse(fileReader.result as string);        
    }
    fileReader.readAsText(this.loadedFile, "UTF-8");
    fileReader.onerror = (error) => {
        this.message = 'File reading error occurred. Please check your file.'       
    }
  }

  onLoadFile(): Promise<any> {
    this.message = '';
    const url = environment.extensionSchema.url + this.schema?.title + '/';
    const username = environment.extensionSchema.username;
    const password = environment.extensionSchema.password;   
    return new Promise((resolve, reject) => {
      if ( url && username && password ) {
        let push_headers = {
          headers: new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Basic " + btoa(`${username}:${password}`)
          })
        };

        this.http.post(url, this.schema, push_headers).toPromise().then((result) => {
            this.form.controls['schema'].setValue(result);
            this.loadedFile = null;  
            resolve(result);
          }).catch((err) => {            
            console.log(err);
            this.message = this.uploadSchemaErrorHandler(err.message);            
            reject(err);
          });
      } else {        
        reject("missing url/username/password");
      }
    });
  }

  uploadSchemaErrorHandler(message = '') {
    if (message.includes('0 Unknown')) 
      return 'certificate not trusted';
    if (message.includes('undefined')) 
      return 'Your schema is missing a TITLE property. Please double check your file.';
    if (message.includes('409 Conflict')) 
      return 'A schema with duplicate title has been uploaded already. Please choose a different file to upload.';
    if (message.includes('400 Bad Request')) 
      return 'Some properties in the schema could not be processed by the server. Please double check your file.'
    return '';
  }

  /**
   * Custom Objects - Edit Collection
   */
  editCollection(currCollection: any, modal: any): void {
    let currObj = this.currentModalObject[currCollection];
    this.customProp['name'] = currCollection;
    this.customProp['value'] = '';

    if (currObj.length) {  // If it is an array
      this.customProp.set('customArray', []);
      this.customProp['type'] = 'array';
      for (let x of currObj) {
        this.addSubModalString(x, 'customArray');
      }
    }
    else { // If it is a dict
      this.customProp['key'] = '';
      this.customProp['type'] = 'dict';
      this.customProp.set('customDict', new Map());

      let curr = JSON.stringify(currObj);
      curr = curr.substring(1, curr.length - 2);  // Removes bracket and trailing quote (fencepost problem)
      let split = curr.split('",');
      for (let i of split) {
        const key = i.slice(1, i.indexOf(':') - 1); // Removes Preceding and trailing quote
        const value = i.slice(i.indexOf(':') + 2);  // Removes Preceding quote

        this.addSubModalDictString(key, value, 'customDict');
      }
    }

    this.openLargeSubModal(modal);
  }

  isMultipart() {
    this.question.value = this.form.controls['is_multipart'].value;
    if (this.question.value[0] === 'true') {
      this.form.controls['body'].disable(); 
      this.form.controls['body_multipart'].enable();
    } else {
      this.form.controls['body'].enable(); 
      this.form.controls['body_multipart'].disable();
    }
  }

  setHashType() {
    this.question.relString = this.form.controls['hashes'].value;
  }

  inputUpdate(event) {
    this.checkDuplicates.emit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  updateTimezone(field: string, timezone) {
    this.stixService.timezones[field] = timezone;
  }

}