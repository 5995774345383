<div class="container-fluid pb-5">
    <!--
    <div class="text-center pt-5" id="loadingOverlay">        
        <h5 class="me-4">Loading Reporting Components...</h5>
        <div>
            <img src="./assets/IMX_Icon_White_WithBackground.png" alt="" width="150" height="75" class="loading-logo">
        </div>
    </div>
    -->
    <div class="text-center pt-5">
        <body>
            <iframe src="https://taxii.dfta.awpc.peratonpartners.com:5601/app/dashboards#/view/6a329470-2ec8-11ed-ac60-35990bce1153?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-1y%2Fd%2Cto%3Anow))&show-query-input=true&show-time-filter=true" height="680px" width="100%" scrolling="yes"></iframe>
          </body>
    </div>       
    
    <div class="text-center pt-5 d-none" id="errorOverlay">
        <div class="alert alert-danger">
            An error occurred displaying the Quicksight Dashboard. See your system administrator.
        </div>
    </div>
    <div id="dashboardContainer" class="h-100 w-100 pt-5 d-none text-center"></div>
</div>