<div style="display: block; height: 800px; padding: 100px; padding-top: 50px">
    <div>
        <h5>TAXII Server Information</h5>
        <p>Enter the TAXII server you wish to publish to, query to, query from;
            or leave the default values to submit to IMX's pre-configured TAXII server.</p>
    </div>

    <div class="mb-3 col-6 p-0">
        <span id="password">Select TAXII Server</span>
        <br>
        <div class="d-flex">
            <div class="dropdown">
                <button class="btn btn-outline-primary dropdown-toggle mt-3" style="width: 250px" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ taxiiServerSelection }}
                </button>
                <div class="dropdown-menu">
                    <button class="dropdown-item" (click)="serverSettingsChanged('default')">
                        Default
                    </button>
                    <button class="dropdown-item" (click)="serverSettingsChanged('custom')">
                        Custom
                    </button>
                    <button class="dropdown-item" (click)="serverSettingsChanged('ais')">
                        AIS
                    </button>
                </div>
            </div>
            <div class="mt-3 ms-5">
                <button type="button" *ngIf="taxiiServerType !== 'custom'" class="btn btn-primary btn-sm query-button"
                    [disabled]="stixService.isLoading || this.stixService.apiRootProcessingError == ''"
                    (click)="saveConfig();" style="font-size: 1rem;">
                    <fa-icon [icon]="faConnect" class="pe-1"></fa-icon>
                    Connect
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="taxiiServerType === 'custom'" class="col-6 p-0 pb-4" style="padding-top: 15px !important;">
        <div class="dropdown">
            <button class="btn btn-outline-primary dropdown-toggle mt-3" style="width: 250px" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Select a TAXII Server
            </button>
            <div class="dropdown-menu" style="overflow-x: auto; max-height: 300px;">
                <button class="dropdown-item" (click)="openCustomTaxiiModal()">
                    Add a new custom TAXII Server
                </button>
                <button class="dropdown-item" *ngFor="let customTaxii of customTaxiiArray" (click)="openCustomTaxiiModal(customTaxii)">
                    {{customTaxii}}
                </button>
            </div>
        </div>
    </div>

    <!-- <div *ngIf="showCustomTaxii === true && taxiiServerType === 'custom'" class="col-6 p-0 pb-4" style="padding-top: 35px !important;">
        <div class="mb-3">
            <span id="taxii-server-url">TAXII Server URL</span>
            <input type="url" name="taxii-server-url" minlength="8" [(ngModel)]="this.taxiiServer.url"
                placeholder="TAXII Server URL" required class="form-control t1t-left" aria-label="TAXII Server URL"
                aria-describedby="taxii-server-url" (change)="saved = false">
        </div> -->
        <!-- <div class="btn-group" role="group">
        <button type="button" class="btn btn-outline-secondary" style="height:31px;"
            [class]="{'active': taxiiServerAuthType === 'cert'}"
            (click)="taxiiServerAuthType = 'cert'; taxiiServer.username = ''; taxiiServer.password  = ''; saved = false">
            Certificate Auth
        </button>
            <button type="button" class="btn btn-outline-secondary" style="height:31px;"
            [class]="{'active': taxiiServerAuthType === 'keycloak'}"
            (click)="taxiiServerAuthType = 'keycloak'; taxiiServer.certificate = ''; taxiiServer.username = ''; taxiiServer.password  = ''">
            Keycloak Auth
        </button>
        <button type="button" class="btn btn-outline-secondary" style="height:31px;"
                [class]="{'active': taxiiServerAuthType === 'basic'}"
                (click)="taxiiServerAuthType = 'basic'; taxiiServer.certificate = ''; saved = false">
                Basic Auth
        </button>
    </div> -->
        <!-- <div class="input-group mb-3" *ngIf="taxiiServerAuthType === 'keycloak'"></div>
    <div class="input-group mb-3 mt-3" style="height:31px;" *ngIf="taxiiServerAuthType === 'cert'">
        <div class="custom-file">
            <input type="file" class="custom-file-input" id="inputGroupFile02"
                name="taxii-server-certificate" minlength="8" [(ngModel)]="taxiiServer.certificate"
                placeholder="TAXII Server Certificate" required aria-label="TAXII Server Certificate"
                aria-describedby="taxii-server-certificate">
            <label class="custom-file-label" for="inputGroupFile02"
                aria-describedby="inputGroupFileAddon02">{{ taxiiServer.certificate ?
                taxiiServer.certificate : 'TAXII Server Certificate' }}</label>
        </div>
    </div> -->
        <!-- <div class="mb-3 mt-3" *ngIf="customTaxiiType === 'basic'" style="height:31px;">
            <span id="taxii-server-username">TAXII Server Username</span>
            <input type="text" ngModel name="taxii-server-username" #passwordInput="ngModel" minlength="8"
                [(ngModel)]="taxiiServer.username" placeholder="TAXII Server Username" required
                class="form-control t1t-left" aria-label="TAXII Server Username"
                aria-describedby="taxii-server-username" (change)="saved = false">
        </div>
        <div class="mb-3" *ngIf="customTaxiiType === 'basic'" style="height:31px; padding-top:30px;">
            <span id="taxii-server-url">TAXII Server Password</span>
            <input type="password" ngModel name="taxii-server-password" #passwordInput="ngModel" minlength="8"
                [(ngModel)]="taxiiServer.password" placeholder="TAXII Server Password" required
                class="form-control t1t-left" aria-label="TAXII Server Password"
                aria-describedby="taxii-server-password" (change)="saved = false">
        </div> -->
        <!-- <button *ngIf="taxiiServerType === 'custom'" type="button" class="btn btn-sm btn-primary"
        style="margin-bottom: 10px;" (click)="getAPIRoots('custom')"
        [disabled]="isConnectDisabled() || isPublishing">
        Connect
    </button> -->

    <!-- </div> -->

    <div class="mb-3 col-6 p-0 pt-5" *ngIf="this.isLoadingRoots == true && this.stixService.latency.serverLatency">
        <p>Average response time: {{this.stixService.latency.serverLatency}}</p>
    </div>
    
    <div class="mb-3 col-6 p-0 pt-5" *ngIf="this.isLoadingRoots == false">
        <p *ngIf="this.stixService.apiRootProcessingError == ''">Currently connected to {{this.stixService.taxiiServer.url ==
            aisUrl ? this.stixService.prod ? "https://ais2.cisa.dhs.gov/taxii2/" :
            "https://ais2-pfte.taxii.us-cert.gov/" : this.stixService.taxiiServer.url}}</p>
        <ng-container *ngIf="this.stixService.apiRootProcessingError != ''">
            <p style="font-weight: bold;">Failed to connect to {{this.stixService.taxiiServer.url ==
                aisUrl ? this.stixService.prod ? "https://ais2.cisa.dhs.gov/taxii2/" :
                "https://ais2-pfte.taxii.us-cert.gov/" : this.stixService.taxiiServer.url}}</p>
            {{this.stixService.apiRootProcessingError}}
            <ng-container *ngIf="this.stixService.apiRootProcessingErrorURL != ''"><br>URL: {{this.stixService.apiRootProcessingErrorURL}}</ng-container>
        </ng-container>
    </div>
</div>