<div class="dialog">
    <div class="modal-header">
        <h2 class="modal-title" id="modal-title">Configure Body Multipart</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="cancelModal()">
            <span aria-hidden="true"></span>
        </button>
    </div>

    <div class="modal-body extensions-modal-body">
        <div class="col-md-12">
            <div class="warning-message text-center mb-2">{{this.modalError}}</div>
            <div class="row bump">
                <div class="p-0 col-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Body</b>
                        </span>
                        <input class="form-control" (input)="isModalAddEnabled()" [(ngModel)]="body" type="text">
                    </div>
                </div>
                <div class="p-0 col-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Body Raw Ref</b>
                        </span>
                        <select class="form-control dropdown form-select" (change)="isModalAddEnabled()" [(ngModel)]="body_raw_ref">
                            <option value="" selected></option>
                            <ng-container *ngFor="let opt of this.ref_options">
                                <!-- *ngIf="opt.id.match(objectRegex)" -->
                                <!-- *ngIf="['artifact','file'].indexOf(opt.id.match(objectRegex)[1]) != -1" -->
                                <ng-container >
                                    <option 
                                        [value]="opt['id']">
                                        {{ getObjDisplayName(opt) }}
                                    </option>
                                </ng-container>
                            </ng-container>
                        </select>
                        <input class="form-control" (input)="isModalAddEnabled()" [(ngModel)]="body_raw_ref" type="text">
                    </div>
                    <div class="mb-2"
                        *ngIf="body_raw_ref != '' && body_ref_error">
                        <span class="warning-message">
                            {{body_ref_error}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row bump">
                <div class="p-0 col-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Content Type</b>
                        </span>
                        <input class="form-control" (input)="isModalAddEnabled()" [(ngModel)]="content_type" type="text">
                    </div>
                </div>
                <div class="p-0 col-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Content Disposition</b>
                        </span>
                        <input class="form-control" (input)="isModalAddEnabled()" [(ngModel)]="content_disposition"
                            type="text">
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="modal-footer">
        
        <button type="button" class="btn btn-sm btn-secondary" aria-label="Close"
            (click)="cancelModal()">
            <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
            Cancel
        </button>
        <button class="btn btn-sm btn-success ms-2" [disabled]="!addEnabled" type="button"
            (click)="onAdd()">
            <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
            Add
        </button>
    </div>
</div>
