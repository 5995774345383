<div class="dialog">
    <div class="modal-header">
        <h2 class="modal-title" id="pattern-builder-modal-title">STIX Pattern Builder</h2>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="cancelModal()">
            <span aria-hidden="true"></span>
        </button>
    </div>
    <div class="modal-body">
        <!-- Comparison Expression Builder -->
        <div class="row" *ngIf="page === 1">
            <div class="col-12 text-center mb-3">
                <h3 class="stix-pattern-builder-steps">Step 1: Create Comparison Expressions</h3>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Cyber Observable Object Types
                    </button>
                    <div *ngIf="scoBundleObjects?.length > 0" class="dropdown-menu">
                        <a class="dropdown-item" *ngFor="let obj of scoBundleObjects"
                            (click)="mapBundleObjToSCOObj(obj)">{{ obj.displayName }}</a>
                    </div>
                </div>
                <hr>

                <accordion *ngIf="currObjectPath" class="mb-3">
                    <accordion-group
                        *ngFor="let component of filteredBundleObj"
                        [heading]="stixService.getComponentDisplay(component)"
                        (isOpenChange)="updateExpressionConstant($event, component)">
                        <div class="row ms-3">
                            <pre>{{ stixService.getJsonDisplayForComponent(component) | json }}</pre>
                        </div>
                    </accordion-group>
                </accordion>

                <div *ngIf="!scoBundleObjectSelected && !currObjectPath">
                    Please select a Cyber Observable Object type.
                </div>

                <div *ngIf="currObjectPath && !typeInBundle()">
                    Please create expression with type <strong>{{ currObjectPath?.displayName }}</strong>. This object
                    type doesn't exist in current bundle.
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <h4>Create Comparison Expression</h4>
                <hr>
                <div class="comparison-expression-builder" *ngIf="!currObjectPath">
                    <div class="col-6 offset-3">
                        Select a Cyber Observable Object to begin creating a comparison expression
                    </div>
                </div>
                <div *ngIf="currObjectPath">
                    <div class="row">
                        <div *ngIf="selectedComparisonOperator !== 'EXISTS'" class="col-lg-4 col-md-4 col-12">
                            <div>Object Path</div>
                            <input type="text" class="form-control" disabled
                                value="{{currObjectPath.type}}{{currPropertyName ? ':' + currPropertyName : ''}}">
                            <div>Property Name</div>
                            <select class="form-control dropdown form-select" [(ngModel)]="currPropertyName"
                                (change)="updateExpressionConstant($event)" *ngIf="typeInBundle()">
                                <option *ngFor="let propertyName of propertyNames" [value]="propertyName">
                                    {{ propertyName }}
                                </option>
                            </select>
                            <select class="form-control dropdown form-select" [(ngModel)]="currPropertyName"
                                (change)="updateExpressionConstant($event)" *ngIf="!typeInBundle()">
                                <option hidden value></option>
                                <option *ngFor="let propertyName of propertyNames" [value]="propertyName">
                                    {{ propertyName }}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="selectedComparisonOperator === 'EXISTS'" class="col-lg-4 col-md-4 col-12">
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                            <div>Comparison Operator</div>
                            <select class="form-control dropdown form-select" [(ngModel)]="selectedComparisonOperator">
                                <option *ngFor="let comparisonOperator of COMPARISON_OPERATORS"
                                    [value]="comparisonOperator">
                                    {{ comparisonOperator }}
                                </option>
                                <option *ngIf="showSetOperator()" [value]="'ISSUBSET'">ISSUBSET</option>
                                <option *ngIf="showSetOperator()" [value]="'ISSUPERSET'">ISSUPERSET</option>
                                <option [value]="'EXISTS'">EXISTS</option>
                            </select>
                        </div>
                        <div *ngIf="selectedComparisonOperator === 'EXISTS'" class="col-lg-4 col-md-4 col-12">
                            <div>Object Path</div>
                            <input type="text" class="form-control" disabled
                                value="{{currObjectPath.type}}{{currPropertyName ? ':' + currPropertyName : ''}}">
                            <div>Property Name</div>
                            <select class="form-control dropdown form-select" [(ngModel)]="currPropertyName" *ngIf="typeInBundle()">
                                <option *ngFor="let propertyName of propertyNames" [value]="propertyName">
                                    {{ propertyName }}
                                </option>
                            </select>
                            <select class="form-control dropdown form-select" [(ngModel)]="currPropertyName" *ngIf="!typeInBundle()">
                                <option hidden value></option>
                                <option *ngFor="let propertyName of propertyNames" [value]="propertyName">
                                    {{ propertyName }}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="selectedComparisonOperator !== 'EXISTS'" class="col-lg-4 col-md-4 col-12">
                            <div>Constant</div>
                            <input type="text" class="form-control" [(ngModel)]="comparisonConstant"
                                placeholder="Enter your comparison value">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col text-end">
                            <button type="button" class="btn btn-sm btn-primary" [disabled]="selectedComparisonOperator !== 'EXISTS' && comparisonConstant === null || currPropertyName === ''"
                                (click)="addComparisonExpression()">
                                <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-start mt-3" *ngIf="comparisonExpressions.length > 0">
                <h4>Your Comparison Expressions</h4>
                <hr>
                <div>
                    <ul id="questions">
                        <li *ngFor="let comparisonExpression of comparisonExpressions; let compExpIndx = index;">
                            {{ comparisonExpression.getDisplayForExpression() }}
                            <button type="button" class="btn btn-fa-xsm btn-danger ms-2"
                                (click)="deleteExpression(compExpIndx)">
                                <fa-icon [icon]="faTrash"></fa-icon>

                                <!-- *ngIf="!(question.readonly || this.stixService.revoked)" -->
                            </button>
                        </li>
                    </ul>

                    <!-- <button  *ngFor="let comparisonExpression of comparisonExpressions; let compExpIndx = index;" type="button"
                        class="btn btn-sm btn-primary m-1"
                        disabled>
                        {{ comparisonExpression.getDisplayForExpression() }}
                        <button type="button" class="close expression" data-dismiss="modal" aria-label="Close" (click)="deleteExpression(compExpIndx)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </button> -->
                </div>
            </div>
        </div>

        <!-- Single Observation Expression Builder -->
        <div class="row" *ngIf="page === 2">
            <div class="col-12 text-center mb-3">
                <h3 class="stix-pattern-builder-steps">Step 2: Create Observation Expressions</h3>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <h4>
                            Select Comparison Expression(s)
                        </h4>
                        <hr>
                        <div>
                            <ul id="questions">
                                <li
                                    *ngFor="let comparisonExpression of comparisonExpressions; let compExpIndx = index;">
                                    {{ comparisonExpression.getDisplayForExpression() }}
                                    <button type="button" class="btn btn-fa-xsm btn-primary ms-2"
                                        (click)="addComparisonExpressionToObservationExpression(comparisonExpression)">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                    <button type="button" class="btn btn-fa-xsm btn-danger ms-1"
                                        (click)="deleteExpression(compExpIndx)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </ul>
                            <!-- <button *ngFor="let comparisonExpression of comparisonExpressions; let compExpIndx = index" type="button"
                                class="btn btn-sm btn-primary m-1"
                                (click)="addComparisonExpressionToObservationExpression(comparisonExpression)">
                                {{ comparisonExpression.getDisplayForExpression() }}
                                <button type="button" class="close expression" data-dismiss="modal" aria-label="Close" (click)="deleteExpression(compExpIndx)">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </button> -->
                        </div>
                        <h4 *ngIf="observationExpressions.length !== 0" class="mt-4">
                            Observation Expressions
                        </h4>
                        <hr>
                        <div>
                            <ul id="questions">
                                <li
                                    *ngFor="let observationExpression of observationExpressions; let observExpIndx = index">
                                    {{ observationExpression.showEntireExpressionChain(true) }}
                                    <button type="button" class="btn btn-fa-xsm btn-danger ms-2"
                                        (click)="deleteExpression(null, observExpIndx)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </ul>
                            <!-- <button *ngFor="let observationExpression of observationExpressions; let observExpIndx = index" type="button"
                                class="btn btn-sm btn-primary m-1"
                                (click)="addComparisonExpressionToObservationExpression(observationExpression)"
                                disabled>
                                {{ observationExpression.showEntireExpressionChain(true) }}
                                <button type="button" class="close expression" data-dismiss="modal" aria-label="Close" (click)="deleteExpression(null, observExpIndx)">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </button> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <h4>Create Observation Expression</h4>
                        <hr>
                        <div class="comparison-expression-builder" style="padding-top: 55px;"
                            *ngIf="!observationExpressionBuilder">
                            <div class="col-6 offset-3">
                                Click on a comparison expression to begin creating an observation expression
                            </div>
                        </div>
                        <div *ngIf="observationExpressionBuilder">
                            <div class="mt-3">
                                <span class="me-2">[</span>
                                <div class="d-inline"
                                    *ngFor="let comparisonExpression of observationExpressionBuilder.getExpressionArray(); let i = index">
                                    <span class="badge bg-secondary me-2 stix_pattern">
                                        {{ comparisonExpression.getDisplayForExpression() }}
                                    </span>
                                    <div class="d-inline text-center me-2"
                                        *ngIf="(i+1) < observationExpressionBuilder.getExpressionArray().length">
                                        <select class="form-control form-select dropdown d-inline comparison-operator-dropdown"
                                            [(ngModel)]="comparisonExpression.comparisonOperator">
                                            <option *ngFor="let observationOperator of getComparisonOperator(i)"
                                                [value]="observationOperator">
                                                {{ observationOperator }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <span>]</span>
                            </div>
                            <div *ngIf="this.observationExpressionError.length > 0" class="warning-message pt-2">{{this.observationExpressionError }}</div>
                            <hr>
                            <div class="text-end">
                                <button class="btn btn-sm btn-secondary me-3"
                                    (click)="observationExpressionBuilder = null; comparisonExpressionObjectTypes = []">
                                    <fa-icon [icon]="faUndo" class="pe-1"></fa-icon>
                                    Clear
                                </button>
                                <button class="btn btn-sm btn-primary" (click)="addObservationExpression()">
                                    <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Multiple Observation Expression Builder -->
        <div class="row" *ngIf="page === 3">
            <div class="col-12 text-center mb-3">
                <h3 class="stix-pattern-builder-steps">Step 3: Combine Observation Expressions</h3>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <h4 *ngIf="observationExpressions.length !== 0" class="mt-4">
                            Select Observation Expressions
                        </h4>
                        <hr>
                        <div>
                            <ul id="questions">
                                <li
                                    *ngFor="let observationExpression of observationExpressions; let observExpIndx = index">
                                    {{ observationExpression.showEntireExpressionChain(true) }}
                                    <button type="button" class="btn btn-fa-xsm btn-primary ms-2"
                                        (click)="addComparisonExpressionToObservationExpression(observationExpression)">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                    <button type="button" class="btn btn-fa-xsm btn-danger ms-1"
                                        (click)="deleteExpression(null, observExpIndx)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </ul>
                            <!-- <button *ngFor="let observationExpression of observationExpressions; let observExpIndx = index" type="button"
                                class="btn btn-sm btn-primary m-1"
                                (click)="addComparisonExpressionToObservationExpression(observationExpression)"
                                >
                                {{ observationExpression.showEntireExpressionChain(true) }}
                                <button type="button" class="close expression" data-dismiss="modal" aria-label="Close" (click)="deleteExpression(null, observExpIndx)">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </button> -->
                        </div>

                        <h4 *ngIf="patternExpressions?.length > 0" class="mt-4">
                            Pattern
                        </h4>
                        <hr>
                        <div>
                            <ul id="questions">
                                <li *ngFor="let patternExpression of patternExpressions; let patternExpIndx = index">
                                    {{ patternExpression.showEntireExpressionChain(true) }}
                                    <button type="button" class="btn btn-fa-xsm btn-danger ms-2"
                                        (click)="deleteExpression(null, null, patternExpIndx)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </ul>
                            <!-- <button *ngFor="let patternExpression of patternExpressions; let patternExpIndx = index" type="button"
                                class="btn btn-sm btn-primary m-1"
                                (click)="addComparisonExpressionToObservationExpression(patternExpression)"
                                >
                                {{ patternExpression.showEntireExpressionChain(true) }}
                                <button type="button" class="close expression" data-dismiss="modal" aria-label="Close" (click)="deleteExpression(null, null, patternExpIndx)">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </button> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <h4>Combine Observation Expressions</h4>
                        <hr>
                        <div class="comparison-expression-builder" style="padding-top: 55px;"
                            *ngIf="!observationExpressionBuilder">
                            <div class="col-6 offset-3">
                                Click on an observation expression to begin combining observation expressions
                            </div>
                        </div>
                        <div *ngIf="observationExpressionBuilder">
                            <div class="mt-3">
                                <div class="d-inline"
                                    *ngFor="let comparisonExpression of observationExpressionBuilder.getExpressionArray(); let i = index">
                                    <span class="me-2">[</span>
                                    <span class="badge bg-secondary me-2 stix_pattern">
                                        {{ comparisonExpression.getDisplayForExpression() }}
                                    </span>
                                    <span class="me-2">]</span>
                                    <div class="d-inline text-center me-2"
                                        *ngIf="(i+1) < observationExpressionBuilder.getExpressionArray().length">
                                        <select class="form-control form-select dropdown d-inline comparison-operator-dropdown"
                                            [(ngModel)]="comparisonExpression.comparisonOperator">
                                            <option *ngFor="let observationOperator of OBSERVATION_EXPRESSION_OPERATORS"
                                                [value]="observationOperator">
                                                {{ observationOperator }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="text-end">
                                <button class="btn btn-sm btn-secondary me-3"
                                    (click)="observationExpressionBuilder = null">
                                    <fa-icon [icon]="faUndo" class="pe-1"></fa-icon>
                                    Clear
                                </button>
                                <button class="btn btn-sm btn-primary" (click)="addPatternExpression()">
                                    <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- STIX Pattern Builder -->
        <div class="row" *ngIf="page === 4">
            <div class="col-12 text-center">
                <h3 class="stix-pattern-builder-steps">(FINAL) Step 4: Create STIX Pattern</h3>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <h4>Select a Pattern</h4>
                        <hr>
                        <div class="mt-3">
                            <ul id="questions">
                                <li *ngFor="let patternExpression of patternExpressions">
                                    {{ patternExpression.showEntireExpressionChain(true) }}
                                    <button type="button" class="btn btn-fa-xsm btn-primary ms-2"
                                        (click)="addObservationExpressionToStixPattern(patternExpression)">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                    <!-- <button type="button"
                                        class="btn btn-fa-xsm btn-danger trash-can" 
                                        *ngIf="!(question.readonly || this.stixService.revoked)"
                                        (click)="deleteExpression(null, observExpIndx)"
                                        style="margin-left:2.5px;">
                                        <fa-icon [icon]="faTrash" ></fa-icon>
                                    </button> -->
                                </li>
                            </ul>
                            <!-- <button *ngFor="let patternExpression of patternExpressions" type="button"
                                class="btn btn-sm btn-primary m-1"
                                (click)="addObservationExpressionToStixPattern(patternExpression)">
                                {{ patternExpression.showEntireExpressionChain(true) }}
                            </button> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <h4>Add Qualifier</h4>
                        <hr>
                        <div *ngIf="selectedStixPatternBuilder">
                            <div class="mt-3">
                                <div class="d-inline">
                                    <span class="badge bg-secondary me-2 stix_pattern">
                                        {{ selectedStixPatternBuilder.getDisplayForStixPatternBuilder() }}
                                    </span>
                                </div>
                                <select class="form-control form-select dropdown d-inline comparison-operator-dropdown"
                                    [(ngModel)]="selectedStixPatternBuilder.qualifierForDropdown">
                                    <option *ngFor="let qualifier of OBSERVATION_EXPRESSION_QUALIFIERS">
                                        {{ qualifier }}
                                    </option>
                                </select>
                                <div *ngIf="selectedStixPatternBuilder.qualifierForDropdown === 'WITHIN'"
                                    class="d-inline">
                                    <input type="number" class="d-inline form-control ms-2 number-input"
                                        [(ngModel)]="observationQualifierData1">
                                    <span *ngIf="selectedStixPatternBuilder.qualifierForDropdown === 'WITHIN'"
                                        class="ms-1 qualifier-trailing-label">
                                        SECONDS
                                    </span>
                                </div>
                                <div *ngIf="selectedStixPatternBuilder.qualifierForDropdown == 'REPEATS'"
                                    class="d-inline">
                                    <input type="number" class="d-inline form-control ms-2 number-input"
                                        [(ngModel)]="observationQualifierData1">
                                    <span *ngIf="selectedStixPatternBuilder.qualifierForDropdown === 'REPEATS'"
                                        class="ms-1 qualifier-trailing-label">
                                        TIMES
                                    </span>
                                </div>
                                <div *ngIf="selectedStixPatternBuilder.qualifierForDropdown === 'START'"
                                    class="d-inline">
                                    <span class="qualifier-trailing-label ms-1">START</span>
                                    <input class="form-control d-inline date-input ms-1 me-1"
                                        [(ngModel)]="observationQualifierData1" placeholder="START DATE"
                                        [owlDateTimeTrigger]="startDtPicker" [owlDateTime]="startDtPicker">
                                    <owl-date-time #startDtPicker></owl-date-time>
                                    <span class="qualifier-trailing-label ms-1 me-1">END</span>
                                    <input class="form-control d-inline date-input"
                                        [(ngModel)]="observationQualifierData2" placeholder="END DATE"
                                        [owlDateTimeTrigger]="endDtPicker" [owlDateTime]="endDtPicker">
                                    <owl-date-time #endDtPicker></owl-date-time>
                                </div>
                            </div>
                        </div>
                        <div class="comparison-expression-builder" style="padding-top: 55px;"
                            *ngIf="!selectedStixPatternBuilder">
                            <div class="col-6 offset-3">
                                Click on an observation expression to finish your STIX Pattern
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary me-2" aria-label="Close"
            (click)="cancelModal()">
            <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
            Cancel
        </button>
        <button class="btn btn-sm btn-secondary" type="button" (click)="page = page - 1;" *ngIf="page != 1">
            <fa-icon [icon]="faArrowLeft" class="pe-1"></fa-icon>
            Previous
        </button>
        <button class="btn btn-sm btn-primary" type="button" (click)="page = page + 1; clickContinue()"
            *ngIf="page != pageSize" [disabled]="disableContinue()">
            <fa-icon [icon]="faArrowRight" class="pe-1"></fa-icon>
            Next
        </button>
        <button class="btn btn-sm btn-success" [disabled]="disableSaveStixPattern()" type="button"
            *ngIf="page == pageSize" (click)="saveStixPattern()">
            <fa-icon [icon]="faSave" class="pe-1"></fa-icon>
            STIX Pattern
        </button>
    </div>
</div>