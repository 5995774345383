import { Component, OnInit, EventEmitter, Output, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { StixService } from "../stix-service.service";
import { LANGUAGES } from "../models/languages";
import {
  faTrash,
  faEdit,
  faBan,
  faPlus,
  faAngleDoubleUp,
  faInfoCircle,
  faAngleDoubleDown,
  faFileImport,
  faExclamationCircle,
  faStickyNote
} from "@fortawesome/free-solid-svg-icons";
import { DetectionRuleDialogComponent } from './detection-rule/detection-rule-dialog.component';
import { SnippetDialogComponent } from './snippet/snippet-dialog.component';
import { MatDialog } from "@angular/material/dialog";
import { v4 as uuid } from "uuid";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NoteDialogComponent } from '../add-component/note-dialog-component/note-dialog-component.component';
import { ExternalReferencesDialogComponent } from '../external-references/external-references-dialog/external-references-dialog.component';

@Component({
  selector: "malware-behaviour-object",
  templateUrl: "./malware-behaviour-object.component.html",
  styleUrls: ["./malware-behaviour-object.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class MalwareBehaviourObjectComponent implements OnInit {
  @Output() componentData = new EventEmitter<{}>();
  @ViewChild('nameRow') nameRow: ElementRef;
  @ViewChild('confidenceRow') confidenceRow: ElementRef;
  @ViewChild('languageRow') languageRow: ElementRef;
  @ViewChild('createdByRefRow') createdByRefRow: ElementRef;
  @ViewChild('microRow') microRow: ElementRef;
  @ViewChild('revokedRow') revokedRow: ElementRef;
  @ViewChild('objVersionRow') objVersionRow: ElementRef;
  @ViewChild('behaviorRefRow') behaviorRefRow: ElementRef;
  @ViewChild('objectDefinitionRow') objectDefinitionRow: ElementRef;
  @ViewChild('objectiveRefsRow') objectiveRefsRow: ElementRef;
  @ViewChild('relatedObjectRefsRow') relatedObjectRefsRow: ElementRef;
  @ViewChild('tagsRow') tagsRow: ElementRef;
  @ViewChild('snippetsRow') snippetsRow: ElementRef;
  @ViewChild('detectionRulesRow') detectionRulesRow: ElementRef;
  @ViewChild('contributorRefsRow') contributorRefsRow: ElementRef;

  currentMalwareObject = {};
  editedMalwareObject
  isAddingMalwareObject: boolean = false;

  toplevelEnabled = true;

  extension_type: string;
  tags: any = new Map();

  isEnabled: boolean = true;

  type: string = 'malware-behavior';

  tag_label: string = "";
  tag_value: string = "";
  newSelector: any = undefined;
  lang: any;
  errorMessage: string = "";
  errors = {};

  faEdit = faEdit;
  faTrash = faTrash;
  faBan = faBan;
  faPlus = faPlus;
  faAngleDoubleDown = faAngleDoubleDown;
  faAngleDoubleUp = faAngleDoubleUp;
  faInfoCircle = faInfoCircle;
  faFileImport = faFileImport;
  faExclamation = faExclamationCircle;
  faNote = faStickyNote;

  tlp_options = [];
  lang_options: any[] = LANGUAGES;
  selectors: any[];


  // Reference Question Support
  objective_refs = '';
  objectiveIds: any[] = this.stixService.bundle.objects.filter(o => o.id.includes('malware-objective--'));
  filteredObjectiveIds = this.objectiveIds;

  related_object_refs = '';
  attackIds: any[] = this.stixService.bundle.objects.filter(o => o.id.includes('attack-pattern--'));
  filteredAttackIds = this.attackIds;

  behavior_ref = '';
  behaviorIds: any[] = this.stixService.bundle.objects.filter(o => o.id.includes('malware-behavior--'));
  filteredBehaviorIds = this.behaviorIds;

  contributor_refs = '';
  identityIds: any[] = [{ id: environment.cisaIdentity.id, name: environment.cisaIdentity.name }];
  filteredIdentityIds = this.identityIds;

  initialForm;
  
  criticalityRegex = new RegExp("^(?:100|[1-9]?[0-9])$");

  
  // Left Tab Menu
  behaviorProperties = [
    {name: 'Name*', row: 'nameRow'},
    {name: 'Confidence', row: "confidenceRow"},
    {name: 'Language', row: 'languageRow'},
    {name: 'Created by Ref', row: 'createdByRefRow'},
    {name: 'Micro', row: 'microRow'},
    {name: "Revoked", row: 'revokedRow'},
    {name: "Object Version", row: 'objVersionRow'},
    {name: 'Object Definition*', row: 'objectDefinitionRow'},
    {name: 'Objective Refs', row: 'objectiveRefsRow'},
    {name: 'Related Object Refs', row: 'relatedObjectRefsRow'},
    {name: 'Tags', row: 'tagsRow'},
    {name: 'Snippets', row: 'snippetsRow'},
    {name: 'Detection Rules', row: 'detectionRulesRow'},
    {name: 'Contributor Refs', row: 'contributorRefsRow'},
  ];

  methodProperties = [
    {name: 'Name*', row: 'nameRow'},
    {name: 'Confidence', row: "confidenceRow"},
    {name: 'Language', row: 'languageRow'},
    {name: 'Created by Ref', row: 'createdByRefRow'},
    {name: 'Micro', row: 'MicroRow'},
    {name: "Revoked", row: 'revokedRow'},
    {name: "Behavior Ref*", row: 'behaviorRefRow'},
    {name: 'Object Definition*', row: 'objectDefinitionRow'},
    {name: 'Contributor Refs', row: 'contributorRefsRow'},
  ];

  objectiveProperties = [
    {name: 'Name*', row: 'nameRow'},
    {name: 'Confidence', row: "confidenceRow"},
    {name: 'Language', row: 'languageRow'},
    {name: 'Created by Ref', row: 'createdByRefRow'},
    {name: 'Micro', row: 'MicroRow'},
    {name: "Revoked", row: 'revokedRow'},
    {name: 'Object Definition*', row: 'objectDefinitionRow'},
  ];

  deletable_notes: Set<string> = new Set();
  notes;
  new_notes;

  constructor(public stixService: StixService, private router: Router, public matDialog: MatDialog, public matAutocomplete: MatAutocompleteModule, private modalService: NgbModal) {}

  ngOnInit() {
    this.stixService.objectMarkingReferences = [];
    this.stixService.stringArrays.set('labels', []);

    if (!["malware-behavior","malware-method","malware-objective"].includes(this.stixService.currentType)) {
      this.stixService.currentType = "malware-behavior";
    }
    this.type = this.stixService.currentType;
    this.currentMalwareObject['type'] = this.type;
    this.currentMalwareObject['id'] = `${this.type}--${uuid()}`
    this.currentMalwareObject['spec_version'] = "2.1"
    let date = (new Date()).toISOString();
    this.currentMalwareObject['created'] = date;
    this.currentMalwareObject['snippets'] = [];
    this.currentMalwareObject['detection_rules'] = [];
    
    this.stixService.notes = new Map();
    this.stixService.new_notes = new Map();

    const url = window.location.href;
    if (url.includes('revocation=true')) {
      this.stixService.revocation = true;
    }

    let obj = localStorage.getItem('item-to-edit') || '';
    this.stixService.externalReferences = [];
    this.stixService.granularMarkings = [];
    this.stixService.extensions = [];
    this.stixService.toplevel = [];
    this.stixService.objectMarkingReferences = [];
    this.stixService.contents = [];
    this.stixService.stringArrays = new Map<string, string[]>();
    this.stixService.modalObjectArray = [];
    localStorage.removeItem('item-to-edit');

    if (obj != '') {
      let currObject = JSON.parse(obj);
      // console.log(currObject);

      this.editedMalwareObject = currObject;
      this.currentMalwareObject = currObject;
      if (!this.currentMalwareObject['snippets']) {
        this.currentMalwareObject['snippets'] = [];
      }
      if (!this.currentMalwareObject['detection_rules']) {
        this.currentMalwareObject['detection_rules'] = [];
      }

      if (currObject.object_marking_refs) {
        this.stixService.objectMarkingReferences = currObject.object_marking_refs;
        delete this.currentMalwareObject["object_marking_refs"];
      }

      if (currObject.labels) {
        this.stixService.stringArrays.set('labels', currObject.labels);
        delete this.currentMalwareObject["labels"];
      }

      if (currObject.granular_markings) {
        this.stixService.granularMarkings = currObject.granular_markings;
        delete this.currentMalwareObject["granular_markings"];
      }

      if (currObject.external_references) {
        this.stixService.externalReferences = currObject.external_references;
        delete this.currentMalwareObject["external_references"];
      }

      if (this.currentMalwareObject["tags"]) {
        for (let key in this.currentMalwareObject["tags"]) {
          this.tags.set(key, this.currentMalwareObject["tags"][key]);
        }
      }
      delete this.currentMalwareObject["tags"];

      if (this.currentMalwareObject['extensions']){
        let arr = [];
        for (let ext in this.currentMalwareObject['extensions']) {
          // console.log(ext.substring(22));
          if (!ext.includes("d57b7c9c-7fa6-436b-b82c-8e6f69cdc3d0")) {
              let temp = {}
              temp[ext] = this.currentMalwareObject['extensions'][ext];
              this.stixService.extensions.push(temp);
            }
        }
        delete this.currentMalwareObject["extensions"]
      }

      for (let prop in this.currentMalwareObject) {
        if (this.isToplevel(prop)) {
          this.stixService.toplevel.push([prop,this.currentMalwareObject[prop]]);
          delete this.currentMalwareObject[prop];
        }
      }
    }
    else if (this.type == "malware-method" && this.behaviorIds.length == 1) {
      this.currentMalwareObject["behavior_ref"] = this.behaviorIds[0]["id"];
      this.filterRefs("behavior_ref")
    }

    this.stixService.bundle.objects.forEach(elem => {
      if (elem.type == "note" && elem.object_refs && elem.object_refs.includes(this.currentMalwareObject["id"]))
        this.stixService.notes.set(elem.id, JSON.parse(JSON.stringify(elem)));
    });

    this.notes = JSON.stringify(Array.from(this.stixService.notes));
    this.new_notes = JSON.stringify(Array.from(this.stixService.new_notes));
    
    this.currentMalwareObject['created'] = date;
    this.currentMalwareObject['modified'] = date;

    this.initialForm =  {
      iceObject: JSON.stringify(this.currentMalwareObject),
      objMarkRef: JSON.stringify(this.stixService.objectMarkingReferences),
      granularMarking: JSON.stringify(this.stixService.granularMarkings),
      externalRef: JSON.stringify(this.stixService.externalReferences),
      labels: JSON.stringify(this.stixService.stringArrays.get('labels')),
      extensions: JSON.stringify(this.stixService.extensions)
    }
  }

  addGranularMarking(): void {
    const component: any = this.currentMalwareObject;
    for (const x in component) {
      if (component[x].length == 0) {
        delete component[x];
      }
    }
    this.stixService.granularMarkings.push(component);
    this.isAddingMalwareObject = false;
    this.stixService.editedGranularMarking = undefined;
    this.newSelector = [];
    this.errorMessage = "";

    this.currentMalwareObject = {};

    // if (this.addButton().valid){
    //   let component: any = this.currentGranularMarking;
    //   for(var x in component){
    //     if (component[x].length == 0){
    //       delete component[x];
    //     }
    //   }
    //   this.stixService.granularMarkings.push(component);
    //   this.isAddingGranularMarkings = false;
    //   this.stixService.editedGranularMarking = undefined;
    //   this.newSelector = [];
    //   this.errorMessage = '';
    // }

    // else {
    //   this.errorMessage = this.addButton().errorMessage;
    // }
  }

  addOrCancel(): void {
    if (
      this.isAddingMalwareObject &&
      this.stixService.editedGranularMarking
    ) {
      this.stixService.granularMarkings.push(
        this.stixService.editedGranularMarking
      );
      this.stixService.editedGranularMarking = undefined;
    }
    this.isAddingMalwareObject = !this.isAddingMalwareObject;
    this.newSelector = [];
    this.errorMessage = "";
    this.currentMalwareObject = {};
  }

  addString(key): void {
    if (key == "contributor_refs") {
      const regex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
      if (!regex.test(this.contributor_refs) || !this.contributor_refs.startsWith("identity--")) {
        this.errors["contributor_refs"] = "Must be a valid ID and of type identity"
        return;
      }
      delete this.errors["contributor_refs"];
    }
    else if (key == "related_object_refs") {
      const regex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
      if (!regex.test(this.related_object_refs) || !this.related_object_refs.startsWith("attack-pattern--")) {
        this.errors["related_object_refs"] = "Must be a valid ID and of type attack patten"
        return;
      }
      delete this.errors["related_object_refs"];
    }
    else if (key == "objective_refs") {
      const regex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
      if (!regex.test(this.objective_refs) || !this.objective_refs.startsWith("malware-objective--")) {
        this.errors["objective_refs"] = "Must be a valid ID and of type malware-objective"
        return;
      }
      delete this.errors["objective_refs"];
    }

    let tempString = this[key];
    if (this.currentMalwareObject[key]) {
      let newStringArray = this.currentMalwareObject[key]!;
      if (newStringArray.indexOf(tempString) == -1) {
        newStringArray.push(tempString);
        this.currentMalwareObject[key] = newStringArray;
      }
    } else
      this.currentMalwareObject[key] = [tempString];

    this[key] = '';

    if (key == 'contributor_refs' || key == 'related_object_refs' || key == 'objective_refs') {
      this.filterRefs(key);
    }
  }

  deleteString(myobj: any, mykey: any) {
    let curr = this.currentMalwareObject[mykey] || [];
    curr = curr.filter(obj => obj !== myobj);
    if (curr.length > 0) {
      this.currentMalwareObject[mykey] = curr;
    }
    else {
      delete this.currentMalwareObject[mykey];
    }
  }

  addTag(): void {
    if (this.tag_label.length < 1) {
      this.errors["tags"] = "Key is required";
      return;
    }
    if (this.tag_value.length < 1) {
      this.errors["tags"] = "Count is required";
      return;
    }
    delete this.errors["tags"];

    this.tags.set(this.tag_label,this.tag_value);
    this.tag_label = "";
    this.tag_value = "";
    this.filterRefs('impacted_entity');
  }
  
  deleteTag(key: string): void {
    this.tags.delete(key);
  }

  openDialog(type: string) {
    let dialogRef;
    if (type == "detection_rules") {
      dialogRef = this.matDialog.open(DetectionRuleDialogComponent, {
        height: "600px",
        width: `${window.innerWidth / 3 * 2}px`,
      });
    }
    else if (type == "snippets") {
      dialogRef = this.matDialog.open(SnippetDialogComponent, {
        height: "600px",
        width: `${window.innerWidth / 3 * 2}px`,
      });
    }
    else if (type == "obj_defn") {
      dialogRef = this.matDialog.open(ExternalReferencesDialogComponent, {
        data: {"malware": true},
        height: "600px",
        width: `${window.innerWidth / 3 * 2}px`,
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (type === "detection_rules") {
          this.currentMalwareObject["detection_rules"].push(result);
        }
        else if (type === "snippets") {
          this.currentMalwareObject["snippets"].push(result);
        }
        else if (type === "obj_defn") {
          for (let prop in result) {
            if (prop == "hashes") {
              let empty = true;
              for (let p in result[prop]) {
                empty = false;
                break;
              }
              if (empty) {
                delete result[prop];
              }
            }
            else if (result[prop].length == 0) {
              delete result[prop];
            }
          }

          this.currentMalwareObject["obj_defn"] = (result);
        }
      }
    });
  }

  cancel()  {
    this.router.navigate(['/bundle']);
  }

  addMalwareBehaviourObject(data = null) {
    this.cleanObj();
    if (this.editedMalwareObject) {
      this.stixService.removeComponent(this.editedMalwareObject['id']);
    }
    if (this.stixService.editedGranularMarking) {
      this.stixService.granularMarkings.push(this.stixService.editedGranularMarking);
      this.stixService.editedGranularMarking = undefined;
    }
    if (this.stixService.editedExternalReference) {
      this.stixService.externalReferences.push(this.stixService.editedExternalReference);
      this.stixService.editedExternalReference = undefined;
    }
    if (this.stixService.editedExtension) {
      this.stixService.extensions.push(this.stixService.editedExtension);
      this.stixService.editedExtension = undefined;
    }

    if (this.stixService.objectMarkingReferences) {
      this.currentMalwareObject["object_marking_refs"] = this.stixService.objectMarkingReferences;
    }

    if (this.stixService.stringArrays.get("labels")) {
      this.currentMalwareObject["labels"] = this.stixService.stringArrays.get("labels");
    }

    if (this.stixService.granularMarkings.length > 0) {
      this.currentMalwareObject["granular_markings"] = this.stixService.granularMarkings;
    }
    if (this.stixService.externalReferences.length > 0) {
      this.currentMalwareObject["external_references"] = this.stixService.externalReferences;
    }
    //this.currentMalwareObject["extensions"] = JSON.parse(JSON.stringify(this.stixService.extensions).substring(1, JSON.stringify(this.stixService.extensions).length - 1));
    //if (this.type == 'behavior') {
      this.currentMalwareObject['extensions'] = {
        ['extension-definition-—d57b7c9c-7fa6-436b-b82c-8e6f69cdc3d0']: {
          extension_type: "new-sdo"
        }
      };
    /* }  else if (this.type == 'method') {
      this.currentMalwareObject['extensions'] = {
        ['extension-definition--d57b7c9c-7fa6-436b-b82c-8e6f69cdc3d0']: {
          extension_type: "new-sdo"
        }
      };
    } else if (this.type == 'objective') {
      this.currentMalwareObject['extensions'] = {
        ['extension-definition--d57b7c9c-7fa6-436b-b82c-8e6f69cdc3d0']: {
          extension_type: "new-sdo"
        }
      };
    } */

    if (this.stixService.extensions) {
      for (let ext of this.stixService.extensions) {
        let str = JSON.stringify(ext);
        this.currentMalwareObject["extensions"][str.substring(2,60)] = JSON.parse(str.substring(62,str.length - 1));
      }
      this.stixService.extensions = [];
    }

    for (let prop of this.stixService.toplevel) {
      this.currentMalwareObject![prop[0]] = prop[1];
    }

    this.stixService.toplevel = [];

    this.stixService.newObject = false;
    this.stixService.added = true;

    /* let formObj = this.form.getRawValue();
    }*/
    //this.componentData.emit(this.currentMalwareObject);

    this.cleanObj();

    this.stixService.addComponent(JSON.parse(JSON.stringify(this.currentMalwareObject)));

    if (this.stixService.new_notes.size > 0) {
      this.stixService.new_notes.forEach((value, key) => this.stixService.addComponent(Object.assign({}, value)))
    }

    this.router.navigate(['/bundle']);
    // this.stixService.bundle.objects.push(this.currentMalwareObject);
  }

  cleanObj(): void {
    if (this.tags.size > 0) {
      this.currentMalwareObject["tags"] = Object.fromEntries(this.tags);
    }

    for (let key in this.currentMalwareObject) {
      let prop = this.currentMalwareObject[key];
      if (prop == null || prop.length == 0) {
        delete this.currentMalwareObject[key];
      }
    }

    if (this.currentMalwareObject["object_marking_refs"] && this.currentMalwareObject["object_marking_refs"].length === 0) {
      delete this.currentMalwareObject["object_marking_refs"];
    }

    if (this.currentMalwareObject["labels"] && this.currentMalwareObject["object_marking_refs"].length === 0) {
      delete this.currentMalwareObject["labels"];
    }
  }

  setObjectMarkingReferences(newObjectMarkingReferences: string[]): void {
    this.stixService.objectMarkingReferences = newObjectMarkingReferences;
  }

  setLabels(newlabels: string[]): void {
    this.stixService.stringArrays.set('labels', newlabels);
  }

  isFormValid(): boolean {
    const formKeys = Object.keys(this.currentMalwareObject);
    formKeys.forEach(key => {
      if (!this.stixService.granularMarkingSelectors.includes(key)) {
        this.stixService.granularMarkingSelectors.push(key);
      }
    });

    if (
      JSON.stringify(this.currentMalwareObject) == this.initialForm.iceObject &&
      JSON.stringify(this.stixService.stringArrays.get('labels')) == this.initialForm.labels &&
      JSON.stringify(this.stixService.objectMarkingReferences) == this.initialForm.objMarkRef &&
      JSON.stringify(this.stixService.extensions) == this.initialForm.extensions &&
      JSON.stringify(this.stixService.externalReferences) == this.initialForm.externalRef &&
      JSON.stringify(this.stixService.granularMarkings) == this.initialForm.granularMarking &&
      JSON.stringify(Array.from(this.stixService.notes)) == this.notes &&
      JSON.stringify(Array.from(this.stixService.new_notes)) == this.new_notes
    )
      return false;

    if (this.currentMalwareObject['confidence'] && (this.currentMalwareObject['confidence'] < 0 || this.currentMalwareObject['confidence'] > 100)) {
      this.errors['confidence'] = 'Confidence value must be an integer in the range of 0-100';
      return false;
    }
    delete this.errors['confidence'];

    if (!this.currentMalwareObject["name"]  || !this.currentMalwareObject["obj_defn"]){//} || !this.currentMalwareObject["object_defn"]) {
      return false;
    }
    else if (this.currentMalwareObject["name"].trim().length == 0){//} || !this.currentMalwareObject["object_defn"]) {
      this.errors['name'] = 'Name can not be blank';
      return false;
    }
    delete this.errors['name'];

    if (this.type == 'malware-method' && !this.currentMalwareObject["behavior_ref"]) {
      return false;
    }

    return true;
  }

  filterRefs(type: string) {
    switch (type) {
      case 'contributor_refs':
        this.filteredIdentityIds = this.identityIds.filter(elem => {
          if (elem.id.includes(this.contributor_refs.trim()))
            return true;

          return false;
        });
        break;
      case 'behavior_ref':
        this.filteredBehaviorIds = this.behaviorIds.filter(elem => elem.id.includes(this.currentMalwareObject['behavior_ref'].trim()));
        break;
      case 'related_object_refs':
        this.filteredAttackIds = this.attackIds.filter(elem => elem.id.includes(this.related_object_refs.trim()));
        break;
      case 'objective_refs':
        this.filteredObjectiveIds = this.objectiveIds.filter(elem => elem.id.includes(this.objective_refs.trim()));
        break;
    }
  }

  scrollTo(rowName: string): void {
    const htmlElement = this[rowName] as ElementRef<HTMLDivElement>;
    const position = htmlElement.nativeElement.getBoundingClientRect().top;

    if (position > 84 && position < window.screen.height - 43)
      return;

    htmlElement.nativeElement.scrollIntoView();
    if (position < 84) {
      window.scrollBy({ top: position - 85 });
    }
  }

  openNoteDialogModal() {
    const modalRef = this.modalService.open(NoteDialogComponent, { size: 'xl', modalDialogClass: "modal-note" });
    modalRef.componentInstance.type = this.type;
    modalRef.componentInstance.notes = this.stixService.notes;
    modalRef.componentInstance.new_notes = this.stixService.new_notes;
    modalRef.componentInstance.deletable_notes = this.deletable_notes;
    modalRef.componentInstance.objectId = this.currentMalwareObject["id"];
  }

  isObject(prop: any): boolean {
    return typeof prop == "object";
  }

  isToplevel(key: string): boolean {
    if (["type","id","spec_version","created","modified","confidence","created_by_ref","lang","revoked","name","micro","obj_defn"].includes(key)) {
      return false;
    }

    if (this.type == "malware-objective") {
      return true;
    }

    if (key == "contributor_refs") {
      return false;
    }

    if (this.type == "malware-method") {
      if (key == "behavior_ref") {
        return false;
      }
      return true;
    }

    if (["obj_version","related_object_refs","objective_refs","tags","snippets","detection_rules","contributor_refs"].includes(key)) {
      return false;
    }

    return true;
  }
}
