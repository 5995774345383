import { OnInit, Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormModel } from '../dynamic-form-component/form-model';
import { QuestionBase } from '../dynamic-form-component/question-base';
import { ObjectsViewerComponent } from '../objects-viewer/objects-viewer.component';
import { STIX_OBJECTS } from '../models/stix-objects';
import { StixService } from '../stix-service.service';
import { GuidedService } from '../guided.service';
import { ExternalReference } from '../models/external-reference';
import { GranularMarking } from '../models/granular-marking';
import { Content } from '../models/content';
import { COMPONENT_MAP } from '../models/component-map';
import { Artifact } from '../models/artifact';
import { AttackPattern } from '../models/attack-pattern';
import { Autonomous } from '../models/autonomous';
import { Campaign } from '../models/campaign';
import { Coa } from '../models/coa';
import { Directory } from '../models/directory';
import { DomainName } from '../models/domain-name';
import { EmailAddress } from '../models/email-address';
import { EmailMessage } from '../models/email-message';
import { Grouping } from '../models/grouping';
import { Identity } from '../models/identity';
import { Incident } from '../models/incident';
import { Indicator } from '../models/indicator';
import { Infrastructure } from '../models/infrastructure';
import { IntrusionSet } from '../models/intrusion-set';
import { Ipv4Addr } from '../models/ipv4';
import { Ipv6Addr } from '../models/ipv6';
import { LanguageContent } from '../models/language-content';
import { ExtensionDefinition } from '../models/extension-definition';
import { MacAddress } from '../models/mac-address';
import { Malware } from '../models/malware';
import { MalwareAnalysis } from '../models/malware-analysis';
import { MarkingDefinition } from '../models/marking-definition';
import { Mutex } from '../models/mutex';
import { NetworkTraffic } from '../models/network-traffic';
import { Note } from '../models/note';
import { ObservedData } from '../models/observed-data';
import { Opinion } from '../models/opinion';
import { Process } from '../models/process';
import { Relationship } from '../models/relationship';
import { Report } from '../models/report';
import { Sighting } from '../models/sighting';
import { Software } from '../models/software';
import { ThreatActor } from '../models/threat-actor';
import { Tool } from '../models/tool';
import { Url } from '../models/url';
import { UserAccount } from '../models/user-account';
import { Vulnerability } from '../models/vulnerability';
import { WindowsRegistryKey } from '../models/windows-registry-key';
import { XCert } from '../models/x-cert';
import { File } from '../models/file';
import { Location } from '../models/location';
import { environment } from 'src/environments/environment';
import { IncidentCoreExtensionComponent } from '../incident-core-extension/incident-core-extension.component';
import { faEdit, faTrash, faFileImport, faTimes, faIdCard, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { NoteDialogComponent } from './note-dialog-component/note-dialog-component.component';

@Component({
  selector: 'app-add-component',
  templateUrl: './add-component.component.html',
  styleUrls: ['./add-component.component.css'],
})
export class AddComponentComponent implements OnInit {
  @Input() objectSelectionInput: any;
  @Input() objectPropertyTypeSelectionInput: any;
  @Input() queryParams: any;
  @Output() guidedUIEvent: EventEmitter<any>;

  faEdit = faEdit;
  faTrash = faTrash;
  faFileImport = faFileImport;
  faRevoke = faTimes;
  faIdCard = faIdCard;
  faNote = faStickyNote;

  questions: QuestionBase<any>[];
  componentId: string;
  errorList: string[] = [];
  componentMap = COMPONENT_MAP;
  currentComponent?: FormModel;
  currentComponentForDuplicates?: FormModel;
  duplicates: any = [];
  kill_chain_phases: {};
  storedIdentities: any;
  openNewWindow: boolean;
  cisaId = '';
  requiredMessage = '';

  deletable_notes: Set<string> = new Set();

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public stixService: StixService, public guidedService: GuidedService, private modalService: NgbModal) {
    this.guidedUIEvent = new EventEmitter();

    this.componentMap.set("artifact", new Artifact(stixService));
    this.componentMap.set("attack-pattern", new AttackPattern(stixService));
    this.componentMap.set("autonomous-system", new Autonomous(stixService));
    this.componentMap.set("campaign", new Campaign(stixService));
    this.componentMap.set("course-of-action", new Coa(stixService));
    this.componentMap.set("directory", new Directory(stixService));
    this.componentMap.set("domain-name", new DomainName(stixService));
    this.componentMap.set("email-addr", new EmailAddress(stixService));
    this.componentMap.set("email-message", new EmailMessage(stixService));
    this.componentMap.set("file", new File(stixService));
    this.componentMap.set("grouping", new Grouping(stixService));
    this.componentMap.set("identity", new Identity(stixService));
    this.componentMap.set("incident", new Incident(stixService));
    this.componentMap.set("indicator", new Indicator(stixService));
    this.componentMap.set("infrastructure", new Infrastructure(stixService));
    this.componentMap.set("intrusion-set", new IntrusionSet(stixService));
    this.componentMap.set("ipv4-addr", new Ipv4Addr(stixService));
    this.componentMap.set("ipv6-addr", new Ipv6Addr(stixService));
    this.componentMap.set("location", new Location(stixService));
    this.componentMap.set("mac-addr", new MacAddress(stixService));
    this.componentMap.set("malware-analysis", new MalwareAnalysis(stixService));
    this.componentMap.set("malware", new Malware(stixService));
    this.componentMap.set("malware-analysis", new MalwareAnalysis(stixService));
    this.componentMap.set("marking-definition", new MarkingDefinition(stixService));
    this.componentMap.set("mutex", new Mutex(stixService));
    this.componentMap.set("network-traffic", new NetworkTraffic(stixService));
    this.componentMap.set("note", new Note(stixService));
    this.componentMap.set("observed-data", new ObservedData(stixService));
    this.componentMap.set("opinion", new Opinion(stixService));
    this.componentMap.set("process", new Process(stixService));
    this.componentMap.set("relationship", new Relationship(stixService));
    this.componentMap.set("report", new Report(stixService));
    this.componentMap.set("sighting", new Sighting(stixService));
    this.componentMap.set("software", new Software(stixService));
    this.componentMap.set("threat-actor", new ThreatActor(stixService));
    this.componentMap.set("tool", new Tool(stixService));
    this.componentMap.set("url", new Url(stixService));
    this.componentMap.set("user-account", new UserAccount(stixService));
    this.componentMap.set("vulnerability", new Vulnerability(stixService));
    this.componentMap.set("windows-registry-key", new WindowsRegistryKey(stixService));
    this.componentMap.set("x509-certificate", new XCert(stixService));
    this.componentMap.set("report", new Report(stixService));
    this.componentMap.set("language-content", new LanguageContent(stixService));
    this.componentMap.set("extension-definition", new ExtensionDefinition(stixService));

    this.openNewWindow = false;

    if (!this.stixService.guidedUI) {
      this.componentId = this.activatedRoute.snapshot.paramMap.get("component-id") || '';
      if (!this.componentId)
        this.router.navigate(['/bundle']);

      this.currentComponent = this.componentMap.get(this.componentId!) as FormModel;
      if (!this.currentComponent) {
        this.router.navigate(['/bundle']);
      }

      this.activatedRoute.queryParams
        .subscribe(params => {
          this.updateComponent(params);
          console.log("ID", this.currentComponent["id"]);
        })
    }
  }

  ngOnInit(): void {
    if (this.stixService.guidedUI && this.objectSelectionInput) {
      this.stixService.getData().subscribe(data => {
        switch (data.type) {
          case 'create-relationship':
          case 'create-sighting':
            this.updateComponent(data, false);
            break;
        }
      })

      this.componentId = this.objectSelectionInput.routeName;
      this.currentComponent = this.componentMap.get(this.componentId!) as FormModel;
      this.initialSetup({})
    }

    this.loadLocalIdentities();
    this.cisaId = environment.cisaIdentity.id;
  }

  updateComponent(params, routeEvent = true) {
    let new_version = history.state.data && history.state.data.new_version
      ? history.state.data.new_version
      : params.new_version;

    let revocation = history.state.data && history.state.data.revocation
      ? history.state.data.revocation
      : params.revocation;

    this.stixService.revocation = revocation;

    if (history.state.data) {
      history.state.data.new_version = false;
      history.state.data.revocation = false;
    }

    console.info("CURRENT COMPONENT", this.currentComponent)
    this.questions = this.currentComponent!.getQuestions();

    for(let question of this.questions){
      if(question.key === 'type'){
        switch(question.value){
          case 'location':
            this.requiredMessage = 'Form Requires: Region, Country, Or Longitude and Latitude'
            break;
          case 'malware-analysis':
            this.requiredMessage = 'Form Requires: Product and Result or Analysis Sco Refs'
            break;
          case 'artifact':
            this.requiredMessage = 'Form Requires: Payload Bin or URL and Hashes'
            break;
          case 'file':
            this.requiredMessage = 'Form Requires: Name or Hashes'
            break;
          case 'network-traffic':
            this.requiredMessage = 'Form Requires: Protocols and Source Ref or Dest Ref'
            break;
        }

        break;
      }
    }
    
    if (history.state.source && this.componentId && routeEvent) {
      for (let q of this.questions) {
        if (q.key === 'target_ref') {
          q.value = history.state.target.id;
        }
        if (q.key === 'source_ref') {
          q.value = history.state.source.id;
        }
      }
    }

    if (this.componentId
        && !routeEvent
        && params.value.target
        && params.value.source) {
      for (let q of this.questions) {
        if (q.key === 'target_ref') {
          q.value = params.value.target.id;
          q.relString = params.value.target.id;
        }
        if (q.key === 'source_ref') {
          console.log()
          q.value = params.value.source.id;
          q.relString = params.value.source.id;
        }
      }
    }

    if (this.componentId
      && !routeEvent
      && params.value.sighting) {
      for (let q of this.questions) {
        if (q.key === 'sighting_of_ref'
            && params.value.sighting.length > 0) {
          q.value = params.value.sighting[0];
          q.relString = params.value.sighting[0];
        }
      }
    }

    if (localStorage.getItem('item-to-edit')) {
      this.stixService.isEditing = true;
      let sdoToEdit = JSON.parse(localStorage.getItem('item-to-edit') || '{}');
      if (sdoToEdit != '') {
        this.stixService.externalReferences = [];
        this.stixService.granularMarkings = [];
        this.stixService.extensions = [];
        this.stixService.toplevel = [];
        this.stixService.objectMarkingReferences = [];
        this.stixService.contents = [];
        this.stixService.stringArrays = new Map<string, string[]>();
        this.stixService.modalObjectArray = [];
        let pristineQuestions = this.currentComponent!.getQuestions();
        let revoked = sdoToEdit['revoked'] == true;
        let type = undefined;
        const modelKeys = Object.keys(sdoToEdit);
        this.stixService.revoked = revoked;
        modelKeys.forEach((key: string) => {
          let isKeyFound = false;
          for (let i = 0; i < pristineQuestions.length; i++) {
            if (revoked || revocation)
              pristineQuestions[i].readonly = true;                

            let control = pristineQuestions[i].controlType;
            let q_key = pristineQuestions[i].key;
            if (pristineQuestions[i].key === key) {
              if (control == 'hash-array' || control == 'string-dict') {
                let dict = sdoToEdit[q_key];
                for (let x in dict) {
                  let s = x + ": " + dict[x];
                  if (this.stixService.stringArrays.has(q_key)) {
                    let newStringArray = this.stixService.stringArrays.get(q_key)!;
                    if (newStringArray.indexOf(s) == -1) {
                      newStringArray.push(s);
                      this.stixService.stringArrays.set(q_key, newStringArray);
                    }
                  }
                  else
                    this.stixService.stringArrays.set(q_key, [s]);
                }
              }
              else if (Array.isArray(sdoToEdit[key])) {
                this.stixService.stringArrays.set(pristineQuestions[i].key, sdoToEdit[key])
              }
              else if (control == 'nonblankdropdown' || control == 'dropdown' || control == 'tooltip-dropdown' || control == 'tracked-dropdown') {
                console.log(pristineQuestions[i])
                pristineQuestions[i].value = []
                pristineQuestions[i].value.push(sdoToEdit[key].toString())
              }

              else if (q_key == 'definition') {
                pristineQuestions[i].value = sdoToEdit[key].tlp || sdoToEdit[key].statement;
              }

              else {
                if (q_key == 'type')
                  type = pristineQuestions[i].value;
                pristineQuestions[i].value = sdoToEdit[key];
              }

              isKeyFound = true;
              break;
            }

            if (q_key == 'phase_name') {
              for (var a in sdoToEdit['kill_chain_phases']) {
                let str = sdoToEdit['kill_chain_phases'][a]['phase_name'] + ': ' + sdoToEdit['kill_chain_phases'][a]['kill_chain_name'];
                if (this.stixService.stringArrays.has('killChain')) {
                  let newStringArray = this.stixService.stringArrays.get('killChain')!;
                  if (newStringArray.indexOf(str) == -1) {
                    newStringArray.push(str);
                    this.stixService.stringArrays.set('killChain', newStringArray);
                  }
                } else
                  this.stixService.stringArrays.set('killChain', [str]);
              }
            }

            if (q_key === 'incident_description') {
              pristineQuestions[i].value = sdoToEdit['description'];
            }

            if (q_key === 'url_value') {
              pristineQuestions[i].value = sdoToEdit['value'];
            }
          }

          if (!isKeyFound) {
            console.warn(`Key ${key} not found in model ${this.componentId}`);
            if (["object_marking_refs", "labels", "granular_markings", "external_references", "extensions", "value", "description"].indexOf(key) == -1
              && sdoToEdit[key] != undefined){
              this.stixService.toplevel.push([key, sdoToEdit[key]]);
            }
          }
        });

        if (sdoToEdit['granular_markings']) {
          for (let x in sdoToEdit['granular_markings']) {
            console.log(x);
            let mynewguy: GranularMarking = new GranularMarking(sdoToEdit['granular_markings'][x]['selectors'], sdoToEdit['granular_markings'][x]['lang'], sdoToEdit['granular_markings'][x]['marking_ref']);
            mynewguy = this.cleanObject(mynewguy);
            console.log(mynewguy);
            this.stixService.granularMarkings.push(mynewguy);
          }
        }
        this.stixService.granularMarkings = sdoToEdit['granular_markings'] || [];
        if (sdoToEdit['external_references']) {
          for (let x in sdoToEdit['external_references']) {
            let mynewguy: ExternalReference = new ExternalReference(sdoToEdit['external_references'][x]['source_name'], sdoToEdit['external_references'][x]['description'], sdoToEdit['external_references'][x]['url'], sdoToEdit['external_references'][x]['hashes'], sdoToEdit['external_references'][x]['external_id']);
            mynewguy = this.cleanObject(mynewguy);
            console.log(mynewguy);
            this.stixService.externalReferences.push(mynewguy);
          }
        }
        // Moves Incident Core Extension
        if (sdoToEdit['type'] == "incident" && sdoToEdit['extensions'] && sdoToEdit["extensions"]["extension-definition--ef765651-680c-498d-9894-99799f2fa126"]) {
          this.stixService.editedIncidentCore = sdoToEdit["extensions"]["extension-definition--ef765651-680c-498d-9894-99799f2fa126"];
          delete sdoToEdit["extensions"]["extension-definition--ef765651-680c-498d-9894-99799f2fa126"];
          let more = false;

          for (let i in sdoToEdit['extensions']) {
            more = true;
            break;
          }
          if (!more) {
            delete sdoToEdit['extensions'];
          }
        }
        // Moves Malware Corpus
        if (sdoToEdit['type'] == "malware" && sdoToEdit['extensions'] && sdoToEdit["extensions"]["extension-definition--8e9e338f-c9ee-4d4f-8cac-85b4dcfdf3c1"]) {
          this.stixService.editedMalwareCorpus = sdoToEdit["extensions"]["extension-definition--8e9e338f-c9ee-4d4f-8cac-85b4dcfdf3c1"];
          delete sdoToEdit["extensions"]["extension-definition--8e9e338f-c9ee-4d4f-8cac-85b4dcfdf3c1"];
          let more = false;

          for (let i in sdoToEdit['extensions']) {
            more = true;
            break;
          }
          if (!more) {
            delete sdoToEdit['extensions'];
          }
        }
        if (sdoToEdit['extensions']) {
          console.log(sdoToEdit['extensions'])
          let extensions_str = JSON.stringify(sdoToEdit['extensions']);
          extensions_str = extensions_str.substring(1, extensions_str.length - 1);  // Contents of extensions (removes the braces)
          //let regex = new RegExp(/^([^{^}]*{(([^{^}]*{[^{^}]*})||[^[^}])*},?)*$/);
          //let matches = extensions_str.match(regex);
          //console.log(matches);
          let extensions = extensions_str.replace(/},/g, '},,').split(',,');

          // For each extension object
          let len = extensions.length;
          for (let x = 0; x < len; x++) { // x in y loop will not work

            // Combines cases where a single extension was split incorrectly (because it has a }')
            while ((extensions[x].split('{').length - 1) != (extensions[x].split('}').length - 1)) {
              extensions[x] = extensions[x] + ',' + extensions[x + 1];
              let temp = extensions[x + 1];
              extensions = extensions.filter(obj => obj !== temp);
              len = len - 1;  // this is why it would not work
            }

            // Reformats the extension and pushes it to extensions on stixService
            let json = '{' + extensions[x] + '}';
            console.log(json);
            let component = JSON.parse(json);

            /* We no longer distinguish toplevel properties per extension
            // Adds toplevel properties back to the extension if applicable
            if (extensions[x].includes('\"extension_type\":\"toplevel-property-extension\"')) {
              let extension_id = extensions[x].substring(1, 59);  // Hard coding pulling the id
              let parentExtension = this.stixService.getObjectFromID(extension_id);
              let toplevel_props = parentExtension?.extension_properties;
              if (toplevel_props)
                for (let prop of toplevel_props) {
                  if (sdoToEdit[prop] != undefined)
                    component[extension_id][prop] = sdoToEdit[prop];
                }
            }
            */

            console.log(component);
            component.getGranularMarkingSelectors = function getGranularMarkingSelectors(): string[] {
              let selectors = [];
              selectors.push('extensions');
              this.extensions ? selectors.push('extensions') : null;
              return selectors;
            }
            if (extensions[x].includes('\"extension_type\":\"toplevel-property-extension\"')) {
              let extension_id = extensions[x].substring(1, 59);  // Hard coding pulling the id
              component[extension_id] = {};
              component[extension_id]["extension_type"] = "toplevel-property-extension";
            }

            this.stixService.extensions.push(component);
          }
        }
        if (sdoToEdit['x509_v3_extensions']) {
          console.log(sdoToEdit['x509_v3_extensions']);
          let component = JSON.parse('{"x509_v3_extensions": ' + JSON.stringify(sdoToEdit['x509_v3_extensions']) + '}');
          component.getGranularMarkingSelectors = function getGranularMarkingSelectors(): string[] {
            let selectors = [];
            selectors.push('x509_v3_extensions');
            //this.extensions ? selectors.push('extensions') : null;
            return selectors;
          }
          this.stixService.extensions.push(component);
        }

        if (sdoToEdit['contents']) {
          let contTmp = [];
          if (Array.isArray(sdoToEdit['contents'])) contTmp = sdoToEdit['contents'];
          else contTmp.push(this.stixService.convertLanguageContentsToRawData(sdoToEdit['contents']));
          let content: Content = new Content(contTmp[0]['lang'], contTmp[0]['fieldName'], contTmp[0]['fields']);
          content = this.cleanObject(content);
          this.stixService.contents.push(content);
          
          /* for (let x in sdoToEdit['contents']) {
            let mynewguy: Content = new Content(sdoToEdit['contents'][x]['lang'], sdoToEdit['contents'][x]['fieldName'], sdoToEdit['contents'][x]['fields']);
            mynewguy = this.cleanObject(mynewguy);
            console.log(mynewguy);
            this.stixService.contents.push(mynewguy);
          } */
        }

        if (sdoToEdit['values']) {
          this.stixService.modalObjectArray = sdoToEdit['values'];
        } else if (sdoToEdit['body_multipart']) {
          this.stixService.modalObjectArray = sdoToEdit['body_multipart'];
        } else if (sdoToEdit['contents']) {
          this.stixService.modalObjectArray = this.stixService.contents;
        }

        if (sdoToEdit['extension_properties']) {
          this.stixService.toplevelProperties = sdoToEdit['extension_properties'];
        }

        //this.stixService.externalReferences = sdoToEdit['external_references'] as ExternalReference[] || [];
        this.stixService.objectMarkingReferences = sdoToEdit['object_marking_refs'] || [];
        if (this.currentComponent!.hasGranularMarkings())
          this.currentComponent!.setGranularMarkings(this.stixService.granularMarkings);

        if (this.currentComponent!.hasExternalReferences())
          this.currentComponent!.setExternalReferences(this.stixService.externalReferences);

        if (this.currentComponent!.hasObjectMarkingReferences())
          this.currentComponent!.setObjectMarkingRefs(this.stixService.objectMarkingReferences);

        if (this.currentComponent!.hasExtensions())
          this.currentComponent!.setExtensions(this.stixService.extensions);

        if (this.currentComponent!.hasContents())
          this.currentComponent!.setContents(this.stixService.contents);

        console.log(this.currentComponent);
        this.questions = pristineQuestions;
        localStorage.removeItem('item-to-edit');

        this.stixService.notes = new Map();
        this.stixService.new_notes = new Map();

        if (revocation) {
          let revokedQ = this.questions.find(q => q.key === 'revoked');
          revokedQ.readonly = true;
          revokedQ.value = ['true'];
        }
        //});
        //this.stixService.removeComponent(sdoToEdit['id']);
      }
    }
    else {
      this.stixService.externalReferences = [];
      this.stixService.granularMarkings = [];
      this.stixService.extensions = [];
        this.stixService.toplevel = [];
      if (!this.stixService.guidedUI) {
        this.stixService.objectMarkingReferences = [];
      }
      this.stixService.contents = [];
      this.stixService.stringArrays = new Map<string, string[]>();
      if (this.componentId === 'report' && !this.doesBundleHaveReport()){
        this.stixService.stringArrays.set("object_refs", this.populateObjRefsforReport());
      } 
      this.stixService.modalObjectArray = [];
      this.stixService.revoked = false;
      this.stixService.customObjReadOnly = false;
      this.stixService.modifyingCustomObj = false;
      this.stixService.isEditing = false;
      this.stixService.notes = new Map();
      this.stixService.new_notes = new Map();
    }
      //}
  }

  doesBundleHaveReport(): boolean{
    for(let obj of this.stixService.bundle.objects){
      if(obj.type === 'report'){
        return true;
      }
    }

    return false;
  }

  addCISAIdentity(obj: any) {
    this.stixService.addComponent(obj);
  }

  guidedUIEventHandler(event) {
    switch (event.type) {
      case 'update-primary-properties':
        setTimeout(() => {
          this.setFavorites();
          this.setDisablePrimaryProperties();
        }, 300);
        break;
    }
  }

  setFavorites() {
    // Favorite object [{type: 'autonomous-system', properties: ['name', 'rir']}];
    let localFavorites: any = localStorage.getItem("favorite-attributes");
    if (localFavorites) {
      localFavorites = JSON.parse(localFavorites);

      let foundFavorite = null;

      this.questions.find(q => {
        foundFavorite = localFavorites.find(f => f.type === q.value);
        if (foundFavorite) {
          return true;
        }
        return false;
      })

      if (foundFavorite) {
        foundFavorite.properties.forEach(p => {
          let question = this.questions.find(q => q.key === p);
          if (question) {
            question.favorite = true;
          }
        })
      }
    }
  }

  setDisablePrimaryProperties() {
    if (!this.questions.some(q => (q.required || q.favorite) && !q.readonly)) {
      const questionsFavorited = {
        type: 'disable-primary-properties',
        value: this.questions,
      }
      this.stixService.sendData(questionsFavorited);
    } else {
      const questionsFavorited = {
        type: 'enable-primary-properties',
        value: this.questions,
      }
      this.stixService.sendData(questionsFavorited);
    }
  }

  initialSetup(params) {
    {
      let new_version = history.state.data && history.state.data.new_version
        ? history.state.data.new_version
        : params.new_version;

      let revocation = history.state.data && history.state.data.revocation
        ? history.state.data.revocation
        : params.revocation;

      this.stixService.revocation = revocation;

      if (history.state.data) {
        history.state.data.new_version = false;
        history.state.data.revocation = false;
      }

      //if (!this.stixService.modifyingCustomObj){
      this.questions = this.currentComponent!.getQuestions();

      if (history.state.source && this.componentId) {
        for (let q of this.questions) {
          if (q.key === 'target_ref') q.value = history.state.target.id;
          if (q.key === 'source_ref') q.value = history.state.source.id;
        }
      }

      if (localStorage.getItem('item-to-edit')) {
        this.stixService.isEditing = true;
        let sdoToEdit = JSON.parse(localStorage.getItem('item-to-edit') || '{}');
        if (sdoToEdit != '') {
          this.stixService.externalReferences = [];
          this.stixService.granularMarkings = [];
          this.stixService.extensions = [];
        this.stixService.toplevel = [];
          this.stixService.objectMarkingReferences =
            this.stixService.objectMarkingReferences.length > 0 && this.stixService.guidedUI ? this.stixService.objectMarkingReferences : [];
          this.stixService.contents = [];
          this.stixService.stringArrays = new Map<string, string[]>();
          this.stixService.modalObjectArray = [];
          let pristineQuestions = this.currentComponent!.getQuestions();
          let revoked = sdoToEdit['revoked'] == true;
          let type = undefined;
          const modelKeys = Object.keys(sdoToEdit);
          this.stixService.revoked = revoked;
          modelKeys.forEach((key: string) => {
            let isKeyFound = false;
            for (let i = 0; i < pristineQuestions.length; i++) {
              if (revoked || revocation)
                pristineQuestions[i].readonly = true;
              else if (new_version && type) {
                switch (type) {
                  case 'location':
                    if (pristineQuestions[i].key == 'created')
                      pristineQuestions[i].readonly = true;
                    break;
                  case 'attack-pattern':
                  case 'campaign':
                  case 'identity':
                  case 'incident':
                  case 'infrastructure':
                  case 'intrusion-set':
                  case 'course-of-action':
                  case 'threat-actor':
                  case 'tool':
                  case 'vulnerability':
                    if (pristineQuestions[i].key == 'created' || pristineQuestions[i].key == 'name')
                      pristineQuestions[i].readonly = true;
                    break;
                  case 'grouping':
                    if (pristineQuestions[i].key == 'created' || pristineQuestions[i].key == 'context' || pristineQuestions[i].key == 'object_refs')
                      pristineQuestions[i].readonly = true;
                    break;
                  case 'indicator':
                    if (pristineQuestions[i].key == 'created' || pristineQuestions[i].key == 'pattern' || pristineQuestions[i].key == 'pattern_type' || pristineQuestions[i].key == 'valid_from')
                      pristineQuestions[i].readonly = true;
                    break;
                  case 'language-content':
                    if (pristineQuestions[i].key == 'created' || pristineQuestions[i].key == 'object_ref' || pristineQuestions[i].key == 'contents')
                      pristineQuestions[i].readonly = true;
                    break;
                  case 'malware':
                    if (pristineQuestions[i].key == 'created' || pristineQuestions[i].key == 'is_family')
                      pristineQuestions[i].readonly = true;
                    break;
                  case 'malware-analysis':
                    if (pristineQuestions[i].key == 'created' || pristineQuestions[i].key == 'product')
                      pristineQuestions[i].readonly = true;
                    break;
                  case 'note':
                    if (pristineQuestions[i].key == 'created' || pristineQuestions[i].key == 'content' || pristineQuestions[i].key == 'object_refs')
                      pristineQuestions[i].readonly = true;
                    break;
                  case 'observed-data':
                    if (pristineQuestions[i].key == 'created' || pristineQuestions[i].key == 'first_observed' || pristineQuestions[i].key == 'last_observed' || pristineQuestions[i].key == 'number_observed')
                      pristineQuestions[i].readonly = true;
                    break;
                  case 'opinion':
                    if (pristineQuestions[i].key == 'created' || pristineQuestions[i].key == 'opinion' || pristineQuestions[i].key == 'object_refs')
                      pristineQuestions[i].readonly = true;
                    break;
                  case 'report':
                    if (pristineQuestions[i].key == 'created' || pristineQuestions[i].key == 'name' || pristineQuestions[i].key == 'published' || pristineQuestions[i].key == 'object_refs')
                      pristineQuestions[i].readonly = true;
                    break;
                  case 'relationship':
                    if (pristineQuestions[i].key == 'created' || pristineQuestions[i].key == 'relationship_type' || pristineQuestions[i].key == 'source_ref' || pristineQuestions[i].key == 'target_ref')
                      pristineQuestions[i].readonly = true;
                    break;
                  case 'sighting':
                    if (pristineQuestions[i].key == 'created' || pristineQuestions[i].key == 'sighting_of_ref')
                      pristineQuestions[i].readonly = true;
                    break;
                  case 'extension-definition':
                    if (pristineQuestions[i].key == 'created' || pristineQuestions[i].key == 'name' || pristineQuestions[i].key == 'schema' || pristineQuestions[i].key == 'version' || pristineQuestions[i].key == 'extension_types')
                      pristineQuestions[i].readonly = true;
                    break;
                  default:
                    break;
                }
              }

              let control = pristineQuestions[i].controlType;
              let q_key = pristineQuestions[i].key;
              if (pristineQuestions[i].key === key) {
                if (control == 'hash-array' || control == 'string-dict') {
                  let dict = sdoToEdit[q_key];
                  for (let x in dict) {
                    let s = x + ": " + dict[x];
                    if (this.stixService.stringArrays.has(q_key)) {
                      let newStringArray = this.stixService.stringArrays.get(q_key)!;
                      if (newStringArray.indexOf(s) == -1) {
                        newStringArray.push(s);
                        this.stixService.stringArrays.set(q_key, newStringArray);
                      }
                    }
                    else
                      this.stixService.stringArrays.set(q_key, [s]);
                  }
                }
                else if (Array.isArray(sdoToEdit[key])) {
                  this.stixService.stringArrays.set(pristineQuestions[i].key, sdoToEdit[key])
                }
                else if (control == 'dropdown' || control == 'tooltip-dropdown' || control == 'tracked-dropdown') {
                  console.log(pristineQuestions[i])
                  pristineQuestions[i].value = []
                  pristineQuestions[i].value.push(sdoToEdit[key].toString())
                }

                else if (q_key == 'definition') {
                  pristineQuestions[i].value = sdoToEdit[key].tlp || sdoToEdit[key].statement;
                }

                else {
                  if (q_key == 'type')
                    type = pristineQuestions[i].value;
                  pristineQuestions[i].value = sdoToEdit[key];
                }

                isKeyFound = true;
                break;
              }

              if (q_key == 'phase_name') {
                for (var a in sdoToEdit['kill_chain_phases']) {
                  let str = sdoToEdit['kill_chain_phases'][a]['phase_name'] + ': ' + sdoToEdit['kill_chain_phases'][a]['kill_chain_name'];
                  if (this.stixService.stringArrays.has('killChain')) {
                    let newStringArray = this.stixService.stringArrays.get('killChain')!;
                    if (newStringArray.indexOf(str) == -1) {
                      newStringArray.push(str);
                      this.stixService.stringArrays.set('killChain', newStringArray);
                    }
                  } else
                    this.stixService.stringArrays.set('killChain', [str]);
                }
              }
            }

            if (!isKeyFound)
              console.warn(`Key ${key} not found in model ${this.componentId}`);
          });

          if (sdoToEdit['granular_markings']) {
            for (let x in sdoToEdit['granular_markings']) {
              console.log(x);
              let mynewguy: GranularMarking = new GranularMarking(sdoToEdit['granular_markings'][x]['selectors'], sdoToEdit['granular_markings'][x]['lang'], sdoToEdit['granular_markings'][x]['marking_ref']);
              mynewguy = this.cleanObject(mynewguy);
              console.log(mynewguy);
              this.stixService.granularMarkings.push(mynewguy);
            }
          }
          this.stixService.granularMarkings = sdoToEdit['granular_markings'] || [];
          if (sdoToEdit['external_references']) {
            for (let x in sdoToEdit['external_references']) {
              let mynewguy: ExternalReference = new ExternalReference(sdoToEdit['external_references'][x]['source_name'], sdoToEdit['external_references'][x]['description'], sdoToEdit['external_references'][x]['url'], sdoToEdit['external_references'][x]['hashes'], sdoToEdit['external_references'][x]['external_id']);
              mynewguy = this.cleanObject(mynewguy);
              console.log(mynewguy);
              this.stixService.externalReferences.push(mynewguy);
            }
          }
          if (sdoToEdit['extensions']) {
            console.log(sdoToEdit['extensions'])
            let extensions_str = JSON.stringify(sdoToEdit['extensions']);
            extensions_str = extensions_str.substring(1, extensions_str.length - 1);  // Contents of extensions (removes the braces)
            //let regex = new RegExp(/^([^{^}]*{(([^{^}]*{[^{^}]*})||[^[^}])*},?)*$/);
            //let matches = extensions_str.match(regex);
            //console.log(matches);
            let extensions = extensions_str.replace(/},/g, '},,').split(',,');

            // For each extension object
            let len = extensions.length;
            for (let x = 0; x < len; x++) { // x in y loop will not work

              // Combines cases where a single extension was split incorrectly (because it has a }')
              while ((extensions[x].split('{').length - 1) != (extensions[x].split('}').length - 1)) {
                extensions[x] = extensions[x] + ',' + extensions[x + 1];
                let temp = extensions[x + 1];
                extensions = extensions.filter(obj => obj !== temp);
                len = len - 1;  // this is why it would not work
              }

              // Reformats the extension and pushes it to extensions on stixService
              let json = '{' + extensions[x] + '}';
              console.log(json);
              let component = JSON.parse(json);

              // Adds toplevel properties back to the extension if applicable
              if (extensions[x].includes('\"extension_type\":\"toplevel-property-extension\"')) {
                let extension_id = extensions[x].substring(1, 59);  // Hard coding pulling the id
                let parentExtension = this.stixService.getObjectFromID(extension_id);
                let toplevel_props = parentExtension.extension_properties;
                for (let prop of toplevel_props) {
                  if (sdoToEdit[prop] != undefined)
                    component[extension_id][prop] = sdoToEdit[prop];
                }
              }

              console.log(component);
              component.getGranularMarkingSelectors = function getGranularMarkingSelectors(): string[] {
                let selectors = [];
                selectors.push('extensions');
                this.extensions ? selectors.push('extensions') : null;
                return selectors;
              }
              this.stixService.extensions.push(component);
            }
          }
          if (sdoToEdit['x509_v3_extensions']) {
            console.log(sdoToEdit['x509_v3_extensions']);
            let component = JSON.parse('{"x509_v3_extensions": ' + JSON.stringify(sdoToEdit['x509_v3_extensions']) + '}');
            component.getGranularMarkingSelectors = function getGranularMarkingSelectors(): string[] {
              let selectors = [];
              selectors.push('x509_v3_extensions');
              //this.extensions ? selectors.push('extensions') : null;
              return selectors;
            }
            this.stixService.extensions.push(component);
          }

          if (sdoToEdit['contents']) {
            for (let x in sdoToEdit['contents']) {
              let mynewguy: Content = new Content(sdoToEdit['contents'][x]['lang'], sdoToEdit['contents'][x]['fieldName'], sdoToEdit['contents'][x]['fields']);
              mynewguy = this.cleanObject(mynewguy);
              console.log(mynewguy);
              this.stixService.contents.push(mynewguy);
            }
          }

          if (sdoToEdit['values']) {
            this.stixService.modalObjectArray = sdoToEdit['values'];
          } else if (sdoToEdit['body_multipart']) {
            this.stixService.modalObjectArray = sdoToEdit['body_multipart'];
          } else if (sdoToEdit['contents']) {
            this.stixService.modalObjectArray = this.stixService.contents;
          }

          if (sdoToEdit['extension_properties']) {
            this.stixService.toplevelProperties = sdoToEdit['extension_properties'];
          }

          //this.stixService.externalReferences = sdoToEdit['external_references'] as ExternalReference[] || [];
          this.stixService.objectMarkingReferences = sdoToEdit['object_marking_refs'] || [];
          if (this.currentComponent!.hasGranularMarkings())
            this.currentComponent!.setGranularMarkings(this.stixService.granularMarkings);

          if (this.currentComponent!.hasExternalReferences())
            this.currentComponent!.setExternalReferences(this.stixService.externalReferences);

          if (this.currentComponent!.hasObjectMarkingReferences())
            this.currentComponent!.setObjectMarkingRefs(this.stixService.objectMarkingReferences);

          if (this.currentComponent!.hasExtensions())
            this.currentComponent!.setExtensions(this.stixService.extensions);

          if (this.currentComponent!.hasContents())
            this.currentComponent!.setContents(this.stixService.contents);

          console.log(this.currentComponent);
          this.questions = pristineQuestions;
          localStorage.removeItem('item-to-edit');

          if (revocation) {
            let revokedQ = this.questions.find(q => q.key === 'revoked');
            revokedQ.readonly = true;
            revokedQ.value = ['true'];
          }
          //});
          //this.stixService.removeComponent(sdoToEdit['id']);
        }
      }
      else {
        this.stixService.externalReferences = [];
        this.stixService.granularMarkings = [];
        this.stixService.extensions = [];
        this.stixService.toplevel = [];
        this.stixService.objectMarkingReferences =
          this.stixService.objectMarkingReferences.length > 0 && this.stixService.guidedUI ? this.stixService.objectMarkingReferences : [];
        this.stixService.contents = [];
        this.stixService.stringArrays = new Map<string, string[]>();
        if (this.componentId === 'report' && !this.doesBundleHaveReport())
          this.stixService.stringArrays.set("object_refs", this.populateObjRefsforReport());
        this.stixService.modalObjectArray = [];
        this.stixService.revoked = false;
        this.stixService.customObjReadOnly = false;
        this.stixService.modifyingCustomObj = false;
        this.stixService.isEditing = false;
      }

      this.setFavorites();
      this.setDisablePrimaryProperties();
      //}
    }
  }

  loadLocalIdentities() {
    const idStorage = localStorage.getItem("identities");
    if (idStorage) {
      try {
        this.storedIdentities = JSON.parse(idStorage);
      } catch (e) {
        console.error("Error parsing saved identities with message: ", e);
      }
    }
  }

  // The reason for the copy is that if we edit component directly, we edit the bundle because of the linkages going on.
  getJsonDisplayForComponent(component): any {
    let copy = Object.assign({}, component); // Create a copy for displaying purposes

    if (copy.contents && typeof copy.contents === "object" && copy.contents.length > 0) {
      let contentsString = "";

      for (let i = 0; i < copy.contents.length; i++) {
        let newContent = new Content();
        newContent.lang = copy.contents[i].lang;
        newContent.fields = copy.contents[i].fields;
        newContent.fieldName = copy.contents[i].fieldName;
        contentsString += "{" + newContent.toString() + "},";
      }

      contentsString = contentsString.slice(0, -1); // Remove the last comma
      copy.contents = JSON.parse(`[ ${contentsString} ]`);
    }

    return copy;
  }

  public editObject(stixObject): void {
    console.log('stixObject to go to Editor for versioning: ', stixObject);
    // Set Modified with current time for new_version
    if (stixObject.modified) {
      stixObject.modified = new Date().toISOString();
    }

    this.stixService.objectFromClient = stixObject;
    localStorage.setItem("item-to-edit", JSON.stringify(stixObject));
    const new_version_objects = JSON.parse(localStorage.getItem("new_version_objects"));
    console.log(new_version_objects);
    const isNewVersion = (new_version_objects)
      ? new_version_objects.includes(stixObject.id)
      : false;

    console.log("IS NEW VERSION", isNewVersion);
    let url = this.router.serializeUrl(
      this.router.createUrlTree(['/add-component/' + stixObject.type])
    );

    if (this.openNewWindow) {
      url = url + '?new_version=true';
      window.open(url, '_blank');
    } else {
      // Route using same tab, used for debugging
      this.router.navigate([url], { queryParams: { new_version: true } });
    }
    window.scrollTo(0, 0);
  }

  removeObject(id: string) {
    this.stixService.removeFromIdentityLocalStorage(id);
    if (this.compareIdentity(id)) 
      localStorage.setItem('identity', environment.cisaIdentity.id);
    this.loadLocalIdentities();
  }

  public revokeObject(stixObject): void {
    console.log('stixObject to go to Editor for revocation: ', stixObject);

    // Set Modified with current time for revocation
    if (stixObject.modified) {
      stixObject.modified = new Date().toISOString();
    }

    this.stixService.objectFromClient = stixObject;
    localStorage.setItem("item-to-edit", JSON.stringify(stixObject));

    let url = this.router.serializeUrl(
      this.router.createUrlTree(['/add-component/' + stixObject.type])
    );

    if (this.openNewWindow) {
      url = url + '?revocation=true';
      window.open(url, '_blank');
    } else {
      // Route using same tab, used for debugging
      this.router.navigate([url], { queryParams: { revocation: true } });
    }
  }

  checkDuplicates(componentData: any) {
    if (this.stixService.taxiiServerType === 'custom')
        return;
      
    this.duplicates = [];

    // this.currentComponentForDuplicates = JSON.parse(JSON.stringify(this.currentComponent));
    this.currentComponentForDuplicates = this.currentComponent;
    this.currentComponentForDuplicates!.populateFromJSON(componentData, this.stixService);

    let checkDuplicates: Observable<any> = null;
    checkDuplicates = this.stixService.checkDuplicates(this.currentComponentForDuplicates);

    checkDuplicates.subscribe((d: any) => {
      if (d.body && d.body.founds && d.body.founds.length > 0) {
          let dupsFound = [];
          let rcPairs = [];
          d.body.founds.forEach(o => {
              dupsFound.push(o.object);
              rcPairs.push(o.fcPairs);
          })

          this.duplicates.push({
              original: this.currentComponentForDuplicates,
              duplicated: dupsFound,
              rcPairs: rcPairs,
          })
      }
    })
  }

  isDuplicated() {
    return this.duplicates.length > 0;
  }

  @HostListener("click", ["$event"])
  viewDuplicates(event, component) {
      if (event.target.localName.includes('img')) {
          event.stopPropagation();

          let duplicates = this.duplicates.find((d: any) => 
              d.original.id === component.id
          )

          if (duplicates) {
              let duplicated = [...duplicates.duplicated];
              let rcPairs = [...duplicates.rcPairs];

              if (duplicated.length > 0) {
                  this.openObjectViewerModal(duplicated, rcPairs, 'Duplicated Objects', 'Existing similar objects across all roots and collections on the selected server.')
              }
          }
      }
  }

  openObjectViewerModal(objects, rcPairs, title, description = null) {
    const modalRef = this.modalService.open(ObjectsViewerComponent, { size: 'xl' });
    modalRef.componentInstance.objects = objects;
    modalRef.componentInstance.rcPairs = rcPairs;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.description = description;
  }

  openNoteDialogModal() {
    const modalRef = this.modalService.open(NoteDialogComponent, { size: 'xl', modalDialogClass: "modal-note" });
    modalRef.componentInstance.type = this.getComponentDisplayName();
    modalRef.componentInstance.notes = this.stixService.notes;
    modalRef.componentInstance.new_notes = this.stixService.new_notes;
    modalRef.componentInstance.deletable_notes = this.deletable_notes;
    modalRef.componentInstance.objectId = this.currentComponent["id"];
  }


  addComponent(componentData: any): void {
    for (let prop in this.stixService.timezones) {
      if (this.stixService.timezones[prop].length > 0 && this.stixService.timezones[prop] != "Select Time Zone"
          && componentData[prop]) {
        componentData[prop] = this.stixService.convertToUTC(prop, componentData[prop]);
      }
    }
    this.stixService.timezones = {};

    this.stixService.selectorValidation();

    if (this.stixService.guidedUI && this.guidedService.isGuidedReport) {
      if (this.componentId === 'report' && !this.doesBundleHaveReport()) {
        this.stixService.stringArrays.set("object_refs", this.populateObjRefsforReport()); 
      }
    }

    this.currentComponent!.populateFromJSON(componentData, this.stixService);
    /*
    if(this.sdoToEdit != ''){
      this.currentComponent!.populateFromJSON(this.sdoToEdit, this.stixService);
      //this.componentMap.set(this.componentId, this.sdoToEdit);
      //this.currentComponent = this.componentMap.get(this.componentId!) as FormModel;
    }
    */
    if (this.currentComponent!.hasGranularMarkings())
      this.currentComponent!.setGranularMarkings(this.stixService.granularMarkings);

    if (this.currentComponent!.hasExtensions()) {
      let objs = this.stixService.extensions;
      let x509 = '';
      if (objs.length > 0) { // Derek - Need to fix hasExtensions()
        console.log(objs);
        let json = JSON.stringify(objs);
        //json = json.replace('[', '').replace('}}]', '}}');
        json = json.substring(1, json.length - 1);
        console.log(json);
        let split = json.split('},{');
        if (split.length == 1) {
          split = [];
          console.log(json);
          split.push(json); // Derek - make sure this isn't causing any problems (potential extra brace or splitting dicts)
        }
        console.log(split);
        for (let i = 0; i < split.length; i++) {
          let val = split[i];
          if (val.includes('x509_v3_extensions')) {
            x509 = val;

            if (split.length == 1) {
              // Do Nothing
            }
            else if (i == split.length - 1) {
              x509 = '{' + x509;
              split[i - 1] = split[i - 1] + '}';
            }
            else if (i == 0) {
              x509 = x509 + '}';
              split[i + 1] = '{' + split[i + 1];
            }
            split = split.filter(obj => obj != val);
            break;
          }
        }
        console.log(split);
        console.log(x509);

        if (x509.length > 0)
          this.currentComponent!['x509_v3_extensions'] = JSON.parse(x509)['x509_v3_extensions'];

        json = '';

        //const regex = new RegExp(/^(.*toplevel-property-extension",)(.*)(}})$/);
        const regex = new RegExp(/^(.*)(extension-definition.*)":{(.*)("extension_type":"toplevel-property-extension")(.*)(}})$/);
        for (let j in split) {
          let curr = split[j];
          //The split removes some braces
          if (curr[0] != '{')
            curr = ',' + curr;

          // If it is a Toplevel Extension, add the properties directly into the object
          const matches = curr.match(regex);
          if (matches) {
            let id = matches[2];
            let currObj = objs[j];
            console.log(matches);
            curr = matches[1] + matches[2] + '":{' + matches[4] + matches[6];
            console.log(curr);
            let top = (matches[3] + matches[5]).split(',');
            top = top.filter(obj => obj !== '');
            for (let i = 0; i < top.length; i++) {
              console.log(top[i]);
              let prop = top[i].replace(/"/g, '').split(':');
              console.log(currObj[id][prop[0]]);
              console.log(this.currentComponent);
              this.currentComponent![prop[0]] = currObj[id][prop[0]];
              console.log(this.currentComponent);
            }
          }

          json = json + curr;
        }
        //json = JSON.parse(json);
        //console.log(JSON.parse('{"extension-definition--462f0d33-a4ab-458a-a20e-50f00985865d":{"extension_type":"Property Extension","asd":"asd"},"extension-definition--462f0d33-a4ab-458a-a20e-50f00985865d":{"extension_type":"Toplevel Property Extension"}}'))
        if (json.length > 0)
          this.currentComponent!.setExtensions(JSON.parse(json));
      }
    }
    if (this.currentComponent!["type"] == "incident") {
      if (this.stixService.currentIncidentCoreExtension["isEnabled"]) {
        delete this.stixService.currentIncidentCoreExtension["isEnabled"];
        if (!this.currentComponent!["extensions"]) {
          this.currentComponent!["extensions"] = {};
        }
        this.currentComponent!["extensions"]["extension-definition--ef765651-680c-498d-9894-99799f2fa126"] = this.stixService.currentIncidentCoreExtension;
      }
    }
    if (this.currentComponent!["type"] == "malware") {
      if (this.stixService.currentMalwareCorpus["isEnabled"]) {
        delete this.stixService.currentMalwareCorpus["isEnabled"];
        if (!this.currentComponent!["extensions"]) {
          this.currentComponent!["extensions"] = {};
        }
        this.currentComponent!["extensions"]["extension-definition--8e9e338f-c9ee-4d4f-8cac-85b4dcfdf3c1"] = this.stixService.currentMalwareCorpus;
      }
    }

    if (this.currentComponent!.hasExternalReferences())
      this.currentComponent!.setExternalReferences(this.stixService.externalReferences);

    if (this.currentComponent!.hasContents())
      this.currentComponent!.setContents(this.stixService.contents);

    if (this.currentComponent!.hasObjectMarkingReferences()) {
      if (this.stixService.guidedUI) {
        if (this.stixService.objectMarkingReferencesTemp.length > 0) {
          this.currentComponent!.setObjectMarkingRefs(this.stixService.objectMarkingReferencesTemp);
          this.stixService.objectMarkingReferencesTemp = [];
        } else {
          this.currentComponent!.setObjectMarkingRefs(this.stixService.objectMarkingReferences);
        }
      } else {
        this.currentComponent!.setObjectMarkingRefs(this.stixService.objectMarkingReferences);
      }
    }

    for (let prop of this.stixService.toplevel) {
      this.currentComponent![prop[0]] = prop[1];
    }

    this.stixService.externalReferences = [];
    this.stixService.granularMarkings = [];
    this.stixService.extensions = [];
        this.stixService.toplevel = [];
    if (!this.stixService.guidedUI) {
      this.stixService.objectMarkingReferences = [];
    }
    this.stixService.contents = [];
    this.stixService.stringArrays = new Map<string, string[]>();
    //this.stixService.modalObjectArray = []; // Derek - check if needed
    this.stixService.revoked = false;
    this.stixService.customObjReadOnly = false;
    this.stixService.modifyingCustomObj = false;
    this.stixService.isEditing = false;

    // TODO : Calculate GUID (tmp -> angular2-uuid not using hashes/payload_bin)
    //var errors = this.stixService.addComponent(Object.assign({}, this.currentComponent as FormModel));

    ///**********************************************************
    //Validation Starts here
    //***********************************************************
    //run validation here instead of stix service 

    /*this.stixService.validate2(this.currentComponent as FormModel).then(data=>{
      if (data.length == 0){
        this.stixService.addComponent(Object.assign({}, this.currentComponent as FormModel));
        this.currentComponent = this.componentMap.get(this.componentId!) as FormModel;
        this.questions = this.currentComponent.getQuestions();
        this.router.navigate(['/bundle']);
      }
      else{
        for (var a in data){
          console.log(data[a]);
          const delReg = new RegExp(`\\b${this.componentId}\\b`, '');
          if (a=='0'){
            var num = '';
          }
          else{
            var num = a+')'
          }
          this.errorList.push(num+data[a].replace(delReg, '').replace(/--.*?:/, ''));
        }
      }
    });*/
    ///**********************************************************
    //Validation Ends here
    //***********************************************************
    //comment out these  lines when turning back on backend validaiotn 

    // if(this.stixService.externalReferences.length != 0){
    //   this.currentComponent['external_references'] = this.stixService.killChainExternalReferences;
    // }

    this.currentComponent = this.cleanObject(this.currentComponent);
    // Add to bundle and local cache in service
    this.stixService.addComponent(Object.assign({}, this.currentComponent as FormModel));
    this.stixService.killChainExternalReferences = [];
    this.currentComponent = this.componentMap.get(this.componentId!) as FormModel;
    this.questions = this.currentComponent.getQuestions();

    if (this.stixService.new_notes.size > 0) {
      this.stixService.new_notes.forEach((value, key) => this.stixService.addComponent(Object.assign({}, value)))
    }

    if (!this.stixService.guidedUI) {
      this.router.navigate(['/bundle']);
    }

    //If creating new version save it to a local cache to keep track of new versions
    if (this.activatedRoute.snapshot.queryParamMap.get("new_version") === "true") {
      const new_version_objects = JSON.parse(localStorage.getItem("new_version_objects"));
      if (!new_version_objects) localStorage.setItem("new_version_objects", JSON.stringify([componentData.id]));
      if (new_version_objects) {
        if (!new_version_objects.includes(componentData.id)) {
          new_version_objects.push(componentData.id);
        }
        if (componentData.type == 'report') {
          componentData.object_refs.forEach(ref => { if (!new_version_objects.includes(ref)) new_version_objects.push(ref) });
        }
        localStorage.setItem("new_version_objects", JSON.stringify(new_version_objects));
      }
    }
    /*if (errors.length == 0){
    // Clear Model
      this.currentComponent = this.componentMap.get(this.componentId!) as FormModel;
      this.questions = this.currentComponent.getQuestions();
      this.router.navigate(['/bundle']);
    }*/
  }

  cleanObject(myobject: any) {
    for (var x in myobject) {
      if (x == 'hashes') {
        if (Object.keys(myobject[x]).length == 0) {
          delete myobject[x];
        }
      }
      /*
      else if (x == 'data_type') {
        console.log(typeof myobject[x]);
        if (Object.keys(myobject[x]).length == 0){
          delete myobject[x];
        }
      }
      */

      else if (x == 'values' || x == 'body_multipart') {
        if (this.stixService.modalObjectArray.length == 0) {
          delete myobject[x];
        }
        else
          myobject[x] = this.stixService.modalObjectArray;
      }

      else if (x == 'contents') {
        if (this.stixService.modalObjectArray.length == 0) {
          delete myobject[x];
        } else {
          const contentObject = this.stixService.convertLanguageContentsToObject(this.stixService.modalObjectArray);
          myobject[x] = contentObject;
        }
      }

      else if (x == 'revoked') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if (x == 'defanged') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if (x == 'is_service_account') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if (x == 'is_privileged') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if (x == 'is_disabled') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if (x == 'can_escalate_privs') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if (x == 'is_family') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if (x == 'is_active') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if (x == 'is_hidden') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if (x == 'summary') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if (x == 'loaded') {
        delete myobject[x];
      }
      else if (x == 'is_multipart') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if (x == 'is_service_account') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if (x == 'is_privileged') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if (x == 'can_escalate_privs') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if (x == 'is_disableds') {
        if (myobject[x] === "") {
          delete myobject[x];
        }
      }
      else if ((x == 'same' || x == 'last_source_target') && myobject instanceof Relationship) {
        delete myobject[x];
      }
      else if (myobject[x] != undefined && typeof myobject[x] == 'boolean') {
        console.log(x);
      }
      // Not actually sure why this works
      // else if (Array.isArray(myobject[x]) && myobject[x].length === 0) {
      //   delete myobject[x];
      // }
      else if (myobject[x] instanceof Array && myobject[x].length == 0) {
        delete myobject[x];
      }
      else if (myobject[x] == '') {
        delete myobject[x];
      }
      // Does not work
      // else if (JSON.stringify(myobject[x]) == '{}') {
      //   delete myobject[x];
      // }
      else if (myobject[x] instanceof StixService) {
        delete myobject[x];
      }
      else if (myobject[x] == undefined) {
        delete myobject[x];
      }
      else if (myobject[x] == null) {
        delete myobject[x];
      }
      else if (myobject[x].size == 0) {
        delete myobject[x];
      }
      else if (typeof myobject[x] != 'string' && typeof myobject[x] != 'number' && typeof myobject[x] != 'boolean' && Object.prototype.toString.call(myobject[x]) !== '[object Date]') {
        console.info(x);
        let empty = true;
        for (let i in myobject[x]) {
          empty = false;
        }

        if (empty)
          delete myobject[x];
      }
    }
    return myobject;
  }

  getComponentDisplayName(): string {
    for (let i = 0; i < STIX_OBJECTS.length; i++) {
      for (let j = 0; j < STIX_OBJECTS[i].objects.length; j++) {
        if (STIX_OBJECTS[i].objects[j].routeName === this.componentId)
          return STIX_OBJECTS[i].objects[j].displayName;
      }
    }

    return this.componentId;
  }

  isIdentity() {
    return this.componentId && this.componentId.toLowerCase() === 'identity' ? true : false;
  }


  hasExternalReferences(): boolean {
    return this.currentComponent && this.currentComponent!.hasExternalReferences();
  }

  hasContents(): boolean {
    return this.currentComponent && this.currentComponent!.hasContents();
  }

  hasGranularMarkings(): boolean {
    return this.currentComponent && this.currentComponent!.hasGranularMarkings();
  }

  hasExtensions(): boolean {
    return this.currentComponent && this.currentComponent!.hasExtensions();
  }

  hasObjectMarkingReferences(): boolean {
    return this.currentComponent && this.currentComponent!.hasObjectMarkingReferences();
  }

  currentObject(): any {
    return this.currentComponent;
  }

  populateObjRefsforReport(): string[] {
    let objIds = [];
    this.stixService.bundle.objects.forEach(obj => objIds.push(obj.id));
    this.stixService.customObjects.forEach(obj => objIds.push(obj.id));
    return objIds;
  }

  setMainIdentity(id: string): void {
    return localStorage.setItem('identity', id);
  }

  compareIdentity(id: string): boolean {
    return localStorage.getItem('identity') === id;
  }
}