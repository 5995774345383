<nav *ngIf="!fullScreenMode" class="navbar navbar-expand-lg navbar-dark sticky-top container-fluid"
  [class]="{'dev-bg': environment.environment == 'ten-dev' || environment.environment == 'dev'}">
  <a class="navbar-brand" [routerLink]="['/home']">
    <img src="./assets/IMX_Icon_White_NoBackground_resized.png" alt="" width="58" height="37"
      class="d-inline-block align-top">
    <span style="margin-left: 20px;">{{ siteTitle }}</span>
  </a>

  <button class="navbar-toggler navbar-toggler-right btn-toggler" type="button" data-bs-toggle="collapse"
    data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarToggler">
    <ul class="navbar-nav ms-auto float-end" style="margin-right: 0px;">
      <li class="nav-item d-flex" *ngIf="isAuthenticated">
        <a class="d-flex btn my-2 my-sm-0 nav-link navbar-link"
          [routerLink]="['/', editorType]">
          <fa-icon [icon]="faEdit" class="faCog me-1"></fa-icon>
          <span class="me-2" [ngClass]="{currentPage: currentPage === 'Bundle Editor'}" >
            Editor
          </span>
        </a>
        <div *ngIf="environment.guidedUI" class="guided-toggle-wrapper"
          [class]="{'guided-toggle': editorType === 'guided'}">
          <ui-switch size="small" [(ngModel)]="isGuided" [disabled]="currentPage !== 'Bundle Editor'"
            (change)="changeEditor($event)" ></ui-switch>
          <div *ngIf="editorType === 'guided'" class="guided-text">Guided</div>
          <div *ngIf="editorType === 'bundle'" class="guided-text">Legacy</div>
        </div>
      </li>
      <li class="nav-item" *ngIf="isAuthenticated">
        <a class="d-flex btn my-2 my-sm-0 nav-link navbar-link"
          routerLink="/query-stix">
          <fa-icon [icon]="faSearch" class="faCog me-1"></fa-icon>
          <span class="" [ngClass]="{currentPage: currentPage === 'Search'}" >
            Search
          </span>
        </a>
      </li>
      <li class="nav-item" *ngIf="isAuthenticated">
        <a class="d-flex btn my-2 my-sm-0 nav-link navbar-link"
          routerLink="/my-profile">
          <fa-icon [icon]="faUser" class="faCog me-1"></fa-icon>
          <span class="" [ngClass]="{currentPage: currentPage === 'Profile'}" >
            My Profile
          </span>
        </a>
      </li>
      <li class="nav-item" *ngIf="isAuthenticated && isAdmin">
        <a class="d-flex btn my-2 my-sm-0 nav-link navbar-link"
          routerLink="/admin-main">
          <fa-icon [icon]="faUserTie" class="faCog me-1"></fa-icon>
          <span class="" [ngClass]="{currentPage: currentPage === 'Admin Main'}" >
            Admin
          </span>
        </a>
      </li>
      <li class="nav-item" *ngIf="isAuthenticated">
        <a class="d-flex btn my-2 my-sm-0 nav-link navbar-link"
          routerLink="/config">
          <fa-icon [icon]="faCog" class="faCog me-1"></fa-icon>
          <span class="" [ngClass]="{currentPage: currentPage === 'Config'}" >
            Server
          </span>
        </a>
      </li>
      <li class="d-flex nav-item ms-2 mt-2">
        <span class="badge rounded-circle bg-primary notification-badge me-1" style="margin-top: .15rem !important" *ngIf="!stixService.loadingAlerts && stixService.unreadAlerts !== 0">{{stixService.unreadAlerts}}</span>
        <fa-icon [icon]="faBell" class="faCog me-1 navbar-link hover-pointer" (click)="redirectToAlerts()" style="color: white;"></fa-icon>
      </li>
      <!-- <div class="btn-group cog-wrapper">
        <button class="btn my-2 my-sm-0 nav-link navbar-link" type="button" data-bs-toggle="dropdown"
          aria-expanded="false">
          <fa-icon [icon]="faCog" class="faCog"></fa-icon>
        </button>
        <div class="dropdown-menu  dropdown-menu-right">
          <a class="dropdown-item" routerLink="/config">Server Config</a>
          <a class="dropdown-item" routerLink="/my-data">My Profile</a>
          <a class="dropdown-item" (click)="logout()">Log Out</a>
        </div>
      </div> -->
    </ul>
  </div>
</nav>

<ngb-toast aria-live="polite" aria-atomic="true" *ngFor="let toast of announcementService.toasts" [class]="{
    'bg-danger': toast.type === 'error' || toast.type === 'upload-error',
    'bg-success': toast.type === 'success',    
    'bg-warning': toast.type === 'warn', 
    'text-white': toast.type !== 'warn',
    'text-dark': toast.type === 'warn',
    'bg-secondary': toast.type !== 'error' && toast.type !== 'success' && toast.type !== 'warn'
  }" class="annoucement-toast" [autohide]="false" [delay]="5000" (hidden)="announcementService.remove(toast)" id="toast-{{toast.type}}" (keydown)="selectAll($event)">
  <ng-template ngbToastHeader>
    <b class="me-auto">{{ toast.heading }}</b>
  </ng-template>
  <div *ngIf="toast.isBodyJson">
    <div class="hover-pointer text-center" (click)="toast.isJsonBodyExpanded = !toast.isJsonBodyExpanded">
      <span class="expand-for-details-title">Click to Show Details</span>
      <span class="expand-for-details-icon float-end">
        <fa-icon [icon]="faAngleDown" *ngIf="toast.isJsonBodyExpanded"></fa-icon>
        <fa-icon [icon]="faAngleLeft" *ngIf="!toast.isJsonBodyExpanded"></fa-icon>
      </span>
    </div>
    <hr class="bg-white" *ngIf="toast.isJsonBodyExpanded">
    <pre class="toast-code-previewer" *ngIf="toast.isJsonBodyExpanded">{{ toast.body | json }}</pre>
    <button class="btn btn-sm btn-outline-light ms-2 mt-4 mb-2 float-end" *ngIf="toast.isJsonBodyExpanded"
      (click)="announcementService.downloadJson(toast.body, toast.filename, toast.id)">
      <span *ngIf="!toast.isJsonDownloading">Download Output</span>
      <span *ngIf="toast.isJsonDownloading">
        <div class="spinner-grow text-light announcement-download-icon" role="status"></div>
      </span>
    </button>
  </div>
  <span contenteditable="true" id="toast-body"><span *ngIf="!toast.isBodyJson" #toastMessage contenteditable="false" style="display: inline-block; overflow: auto; max-height: 425px;">{{ toast.body }}</span></span>
  <button class="btn btn-sm btn-outline-light ms-2 mt-4 mb-2 float-end" *ngIf="toast.type === 'upload-error'"
    (click)="announcementService.downloadJson(toast.body, toast.filename, toast.id)">
    <span *ngIf="!toast.isJsonDownloading">Download Output</span>
    <span *ngIf="toast.isJsonDownloading">
      <div class="spinner-grow text-light announcement-download-icon" role="status"></div>
    </span>
  </button>
  <div *ngIf="toast.isGitLabIssue" class="pt-3">
    <!-- Using 'version-number' CSS class since the same styling applies here -->
    <a class="version-number" [href]="toast.gitlabIssueUrl" target="_blank">Your Support Request</a>
  </div>
</ngb-toast>

<div class="row" id="wrapper">
  <div class="wrapper col-sm-9 col-md-12" id="wrapper">
    <router-outlet></router-outlet>
  </div>
</div>

<nav *ngIf="!fullScreenMode" class="navbar navbar-expand-xl fixed-bottom w-100"
  [class]="{'dev-bg': environment.environment == 'ten-dev' || environment.environment == 'dev'}">
  <div class="row w-100">
    <div class="col-4">
      <span class="text-white">
        {{ environment.environment == 'ten-dev' || environment.environment == 'dev' ? 'Development' : 'Production'}}
      </span>
      <a class="version-number" (click)="openLargeSubModal(changelogModal)">
        v{{ environment.version }}
      </a>
    </div>
    <div class="col-4 text-center p-0">
      <a class="navbar-link" routerLink="/config">
        Current TAXII Server: {{ this.getServerDisplay() }}
      </a>
    </div>
    <div class="col-4 text-end p-0 text-white" *ngIf="environment.isSupportEnabled">
      <a class="request-support-label me-support" (click)="openSupportModal(supportModal, 'Request a New Feature')">
        Request Feature
      </a>
      <span class="separator-support">|</span>
      <a class="request-support-label ms-support" (click)="openSupportModal(supportModal, 'Submit an Issue')">
        Request Support
      </a>
    </div>
  </div>
</nav>

<ng-template #changelogModal let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-title">IMX Change Log</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"></span>
    </button>
  </div>

  <div class="modal-body" id="changelog">
    <ng-container *ngFor="let version of this.changelogkeys; let i=index">
      <ng-container *ngIf="version != 'Instructions' && version != 'template'">
        <div ngbDropdown>
          <button class="btn btn-outline-primary" id="sortMenu" ngbDropdownToggle
            (click)="isDropdownOpen = !isDropdownOpen">
            <span>Version: {{ current_version.version }}</span>
            <fa-icon class="ps-1" [icon]="faAngleLeft" *ngIf="isDropdownOpen"></fa-icon>
            <fa-icon class="ps-1" [icon]="faAngleDown" *ngIf="!isDropdownOpen"></fa-icon>
          </button>
          <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="sortMenu">
            <button *ngFor="let version of note_versions" class="dropdown-item" (click)="versionChanged(version)">
              Version: {{version}}
            </button>
          </div>
        </div>
        <div *ngIf="current_version.releaseDate" class="changelog-date pb-1 ps-1">Released on
          {{current_version.releaseDate}}
        </div>
        <div *ngIf="current_version.description" class="changelog-description pb-2 ps-1">
          {{current_version.description}}
        </div>
        <div *ngIf="current_version.newFeatures && current_version.newFeatures.length > 0" class="ps-4">
          <ng-container *ngFor="let feature of current_version.newFeatures">
            <li>
              <h6 style="display: inline;">{{feature.title}}</h6>
              <p>{{feature.description}}</p>
            </li>
          </ng-container>
        </div>
        <div *ngIf="current_version.video_url" class="changelog-description pb-2 ps-1">
          <h6>Video Link:</h6>
          <a href="{{current_version.video_url}}" target="_blank">{{current_version.video_url}}</a>
        </div>
        <div *ngIf="current_version.video" class="pb-2 ps-1">
          <video style="width:100%" controls>
            <source [src]="videoPath" type="video/mp4">
          </video>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-md btn-secondary" (click)="modal.dismiss()">
      <fa-icon [icon]="faBan"></fa-icon>
      Close
    </button>
  </div>
</ng-template>

<ng-template #supportModal let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-title">
    {{supportLabel}}
    </h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"></span>
    </button>
  </div>

  <div class="modal-body" id="changelog">
    <div class="row">
      <div class="col">
        <form>
          <div class="form-group">
            <label for="supportDescription">Description</label>
            <textarea class="form-control" name="description" id="supportDescription" rows="3"
              [(ngModel)]="supportRequest.description"></textarea>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-md btn-secondary" (click)="modal.dismiss()">
      <fa-icon [icon]="faBan"></fa-icon>
      Cancel
    </button>
    <button type="button" class="btn btn-md btn-success" [disabled]="isSupportSubmitDisabled()"
      (click)="submitSupportRequest()">
      <fa-icon [icon]="faPaperPlane"></fa-icon>
      Submit
      <div *ngIf="support.loading" class="spinner-grow text-light announcement-download-icon" role="status"></div>
    </button>

  </div>
</ng-template>

<app-config #config style="display: none"></app-config>