import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { StixService } from '../stix-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  screenWidth: any;
  userRoles = [];
  editorLink = null;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private authService: AuthService,
    private keycloak: KeycloakService,
    public stixService: StixService,
  ) { }

  ngOnInit(): void {
    this.stixService.getUnreadAlerts();
    if (this.stixService.getGuided()) {
      this.editorLink = '/guided';
    } else {
      this.editorLink = '/bundle';
    }

    this.screenWidth = window.innerWidth;

    if (environment.keycloak === true)
      this.userRoles = this.keycloak.getUserRoles();
  }

  get isAdmin(): boolean {
    if(environment.keycloak === false)
      return true;

    if (this.userRoles.length === 0)
      return false;

    if (this.userRoles.includes(environment.taxiiServer.adminRoleName))
      return true;

    return false;
  }
}
