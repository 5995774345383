<div class="container-fluid ps-3 pe-3">
  <div class="row ms-2 mt-3 me-3">
    <div class="col-xl-12">
      <div class="row  align-items-baseline">
        <div class="col-5 ps-3">
          <h3 class="col component-title ps-2 mt-2" style="margin-bottom:0;">Detection Rule</h3> 
        </div>
        <div class="mx-auto mt-3 col align-self-center">Form Requires: Rule or URL</div>
      </div>
      <div class="row pe-2" style="padding-top: 0;">
        <hr class="section-separator" style="margin-top: 8px; margin-bottom: 8px;">
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row"> 
            <div id="main-table" class="col-12 mt-3 mb-5">

              <div class="row">
                <!-- Left Column -->
                <div class="col-6 pe-5">

                  <div #ruleTypeRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Rule Type
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <select type="text" class="form-control"
                            [(ngModel)]="this.currRule.rule_type"
                          >
                            <option [ngValue]="undefined" disabled>Select Rule Type</option>
                            <option value=""></option>
                            <option *ngFor="let rule_type of detectionToolOV" [value]="rule_type">{{rule_type}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <!-- Right Column -->
                <div class="col-6">

                  <div #ruleNameRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Rule Name
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <input
                            class="form-control"
                            placeholder="Rule Name"
                            [(ngModel)]="this.currRule.rule_name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="row">
                <div class="col-12">

                  <div #ruleRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Rule
                          <span *ngIf="this.errors['rule']" class="warning-message ps-3">
                            {{ this.errors['rule'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex">
                            <textarea
                              type="text"
                              placeholder="Rule"
                              [(ngModel)]="this.currRule.rule"
                              class="form-control"
                              style="font-size: smaller;"
                            ></textarea>
                          </div>
                        </div>  
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="row">
                <!-- Left Column -->
                <div class="col-6 pe-5">

                  <div #urlRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          URL
                          <span *ngIf="this.errors['url']" class="warning-message ps-3">
                            {{ this.errors['url'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <input
                            class="form-control"
                            placeholder="URL"
                            [(ngModel)]="this.currRule.url"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <!-- Right Column -->
                <div class="col-6">

                  <div #detectRefRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Detect Ref
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select method reference"
                              class="form-control add-left no-border-right"
                              style="width: 98%;"
                              [matAutocomplete]="methodRefsAuto"
                              [(ngModel)]="this.currRule.detect_ref"
                              (ngModelChange)="filterRefs('detect_ref')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #methodRefsAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let id of this.filteredMethodIds" [value]="id.id">
                                {{id.id}}
                              </mat-option>
                            </mat-autocomplete>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="row">
                <div class="col-12">

                  <div #descriptionRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Description
                          <span *ngIf="this.errors['description']" class="warning-message ps-3">
                            {{ this.errors['description'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex">
                            <textarea
                              type="text"
                              placeholder="Description"
                              [(ngModel)]="this.currRule.description"
                              class="form-control"
                              style="font-size: smaller;"
                            ></textarea>
                          </div>
                        </div>  
                      </div>
                    </div>
                  </div>

                  <div #apiFncsRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Windows API Functions
                          <span *ngIf="this.errors['api_fncs']" class="warning-message ps-3">
                            {{ this.errors['api_fncs'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex">
                            <input
                              type="text"
                              placeholder="API Function"
                              [(ngModel)]="this.currFunction"
                              class="form-control"
                              style="width: 98%;"
                            />
                            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                              <button
                                style="max-width: 3%;"
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                (click)="this.currRule.api_fncs.push(this.currFunction); this.currFunction = '';"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>  
                      </div>
                      <div class="row" *ngIf="this.currRule.api_fncs.length > 0">
                        <div class="col mb-2 mt-2">
                          <div class="row mb-1" *ngFor="let fnc of this.currRule.api_fncs; let i = index">
                            <div class="col-12">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{fnc}}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="this.currRule.api_fncs.splice(i)"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Bootstrap column is somehow breaking re-rendering when it's out of view -->
    <div class="col-xl-12 text-end mb-5">
      <button
          type="button" class="btn btn-lg btn-secondary component-button text me-2" (click)="onX()" style="width: 175px !important">
          <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
          Cancel
      </button>   
      <button
          type="button" class="btn btn-lg btn-success component-button text" (click)="onConfirm()"
          [disabled]="!isDialogValid()" style="width: 175px !important; will-change: transform, opacity;">
          <fa-icon [icon]="faFileImport" class="pe-1"></fa-icon>
          Add
      </button>
    </div>
  </div>
</div>
