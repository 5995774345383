export const TASK_TYPE_OV = [
  "administrative",
  "attribution",
  "containment",
  "declared",
  "detected",
  "eradication",
  "escalated",
  "exercised-control",
  "external-intelligence",
  "external-outreach",
  "external-support",
  "implemented-control",
  "investigation",
  "negotiation",
  "playbook-execution",
  "playbook-step-execution",
  "ransom-payment",
  "recovery",
  "reported",
  "routine-updates",
  "victim-notification",
]