<div class="container-fluid ps-5 pe-5">
  <div class="row ms-2 mt-3 me-3">
    <div class="col-xl-12">
      <div class="row ps-3">
        <h3 class="col component-title ps-2 mt-2" style="margin-bottom:0;">{{ type }}</h3>
        <div class="col">
          <button type="button" class="btn add-btn float-end position-relative" style="overflow: visible;" (click)="openNoteDialogModal()">
            <fa-icon class="me-1" [icon]="faNote"></fa-icon>
            Add Note
            <span *ngIf="stixService.notes && stixService.new_notes && stixService.notes.size + stixService.new_notes.size > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
              {{stixService.notes.size + stixService.new_notes.size}}
            </span>
          </button>
        </div> 
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row ps-2 pe-2" *ngIf="errorMessage && this.errorMessage.length > 0">
            <div class="mx-auto mb-1 warning-message">{{ errorMessage }}</div>     
          </div>
          <div class="row pe-2 pb-2" style="padding-top: 0;">
            <hr class="section-separator" style="margin-top: 8px; margin-bottom: 8px;">
          </div>

          <div class="row pe-2" [ngClass]="{'ps-2 mb-2': stixService.guidedUI}" style="padding-top: 0;">
            <app-string-array class="extra-btn" [key]="'object_marking_refs'" (stringArrayOutput)="setObjectMarkingReferences($event)"
                [class]="'col-6'"></app-string-array>
            <app-string-array class="extra-btn" [key]="'labels'" (stringArrayOutput)="setLabels($event)"
                [class]="'col-6'"></app-string-array>
            <hr *ngIf="!stixService.guidedUI" class="section-separator mt-3 mb-2">
          </div>
      
          <div class="row">
            <!-- Side Bar -->
            <div *ngIf="this.type === 'event'" class="col-2 pt-3 pb-3 position-sticky side-bar" style="font-size: 1rem;">
              <div class="row p-1" *ngFor="let prop of this.eventProperties">
                <div class="col-12 text-start sidebar-item" (click)="scrollTo(prop.row)">
                  {{prop.name}}
                </div>
              </div>
            </div>

            <div *ngIf="this.type === 'impact'" class="col-2 pt-3 pb-3 position-sticky side-bar" style="font-size: 1rem;">
              <div class="row p-1" *ngFor="let prop of this.impactProperties">
                <div class="col-12 text-start sidebar-item" (click)="scrollTo(prop.row)">
                  {{prop.name}}
                </div>
              </div>
            </div>

            <div *ngIf="this.type === 'task'" class="col-2 pt-3 pb-3 position-sticky side-bar" style="font-size: 1rem;">
              <div class="row p-1" *ngFor="let prop of this.taskProperties">
                <div class="col-12 text-start sidebar-item" (click)="scrollTo(prop.row)">
                  {{prop.name}}
                </div>
              </div>
            </div>
            
            <div id="main-table" class="col-10 main-table mt-5 mb-5">
              <div class="row">
                <div class="col-12 ps-5 pe-5">
                  <div class="row pb-4">
                    <div class="col-3">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Type*
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              The value of this property MUST be {{ type }}
                            </div>
                          </span>
                        </div>

                        <!-- <span style="float: left">
                          <fa-icon class="ps-1" [icon]="faInfoCircle"> </fa-icon>
                          <div class="toolttext">
                            The value of this property MUST be {{ type }}
                          </div>
                        </span> -->
                      </div>
                      <div class="row">
                        <div class="col">
                          <input class="form-control" readonly [(ngModel)]="this.currentIncidentCoreObject['type']" />
                        </div>
                      </div>
                    </div>

                    <div class="col-7">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          ID
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input
                            class="form-control"
                            [(ngModel)]="this.currentIncidentCoreObject['id']"
                            readonly
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-2 pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Spec Ver.
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input
                            class="form-control"
                            [(ngModel)]="this.currentIncidentCoreObject['spec_version']"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 ps-5 pe-5">
                  <div class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Created
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input
                            class="form-control"
                            [(ngModel)]="this.currentIncidentCoreObject['created']"
                            readonly
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Modified
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input
                            class="form-control"
                            [(ngModel)]="this.currentIncidentCoreObject['modified']"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Event Header -->
              <div class="row" *ngIf="this.type == 'event'">
                <!-- Left Column -->
                <div class="col-6 ps-5 pe-5">
                  <div #nameRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Name
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <input
                            class="form-control"
                            placeholder="Name"
                            [(ngModel)]="this.currentIncidentCoreObject['name']"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #languageRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Language
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <select class="form-control dropdown"
                            [(ngModel)]="this.currentIncidentCoreObject['lang']"
                            [ngClass]="{'disabled-select': this.currentIncidentCoreObject['language'] === undefined}"
                          >
                            <option [ngValue]="undefined" disabled selected>Select language</option>
                            <option value=""></option>
                            <option *ngFor="let lang of lang_options" [value]="lang.key">{{lang.value}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #goalRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Goal
                          <span class="tooltipright">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext font-weight-normal" style="margin-left: 140px;">
                              The assumed goal, objective, desired outcome, or intended effect of this event. Not all events have goals.
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <input
                            class="form-control"
                            placeholder="Goal"
                            [(ngModel)]="this.currentIncidentCoreObject['goal']"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Right Column -->
                <div class="col-6 pe-5">
                  <div #confidenceRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Confidence
                          <span class="tooltipright">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext p-2" style="width: 250px; margin-left: 120px;">
                              <h4>Confidence Scales</h4>
                              <div class="font-weight-normal">The confidence property reflects the object creator's trust <br> in the accuracy of their data.
                                  The confidence value MUST <br> be a number in the range of 0-100.
                              </div>
                            <div class="tooltip-content">
                                  <table class="mt-2 font-weight-normal">
                                      <tr>
                                          <th>Low/Med/High</th>
                                          <th>Range of Values</th>
                                      </tr>
                                      <tr>
                                          <td style="padding:8px;">Low</td>
                                          <td style="padding:8px;">1-29</td>
                                      </tr>
                                      <tr>
                                          <td style="padding:8px;">Med</td>
                                          <td style="padding:8px;">30-69</td>
                                      </tr>
                                      <tr>
                                          <td style="padding:8px;">High</td>
                                          <td style="padding:8px;">70-100</td>
                                      </tr>
                                  </table>
                            </div>
                            </div>
                          </span>
                          <span *ngIf="this.errors['confidence']" class="warning-message ps-3">
                            {{ this.errors['confidence'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                            <input
                            class="form-control"
                            type="number"
                            min="0"
                            max="100"
                            placeholder="Confidence"
                            [(ngModel)]="this.currentIncidentCoreObject['confidence']"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #createdByRefRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Created By Ref
                          <span class="tooltipright">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext font-weight-normal" style="margin-left: 140px;">
                              Defines the id property of the identity object, describing the entity responsible<br>for creating this object. Relevant objects within the bundle will be listed in a dropdown menu.
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <select type="text" class="form-control"
                            [(ngModel)]="this.currentIncidentCoreObject['created_by_ref']"
                            [ngClass]="{'disabled-select': this.currentIncidentCoreObject['confidence'] === undefined}"
                          >
                            <option [ngValue]="undefined" disabled>Select reference</option>
                            <option value=""></option>
                            <option *ngFor="let id of ids" [value]="id.id">{{id.name}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #statusRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Status*
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <input
                          *ngIf="this.stixService.revoked || this.stixService.revocation"
                          class="form-control"
                          [(ngModel)]="this.currentIncidentCoreObject['status']"
                          readonly
                          />
                          <select
                            *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                            class="form-control dropdown"
                            [ngClass]="{'disabled-select': this.currentIncidentCoreObject['status'] === undefined}"
                            [(ngModel)]="this.currentIncidentCoreObject['status']"
                          >
                            <option [ngValue]="undefined" disabled selected>Select status</option>
                            <option
                              *ngFor="let opt of this.eventStatusEnum"
                              [value]="opt"
                            >
                              {{ this.stixService.toTitle(opt) }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="this.type == 'event'">
                <div class="col-12 ps-5">
                  <div #eventTypesRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Event Types
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 pe-5">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select event type"
                              class="form-control add-left no-border-right"
                              [matAutocomplete]="eventEntityTypeAuto"
                              [(ngModel)]="eventTypes"
                              (ngModelChange)="filterRefs('event_types')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #eventEntityTypeAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let opt of this.filteredIncidentEventType" [value]="opt">
                                {{ this.stixService.toTitle(opt) }}                              
                              </mat-option>
                            </mat-autocomplete>
                            <ng-container
                              *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                            >
                              <button
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                style="max-width: 3%;"
                                [disabled]="!eventTypes"
                                (click)="addString('event_types')"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="this.currentIncidentCoreObject['event_types'] && this.currentIncidentCoreObject['event_types'].length > 0">
                        <div class="col mb-2 mt-2 pe-5">
                          <div class="row mb-1" *ngFor="let currentString of this.currentIncidentCoreObject['event_types']">
                            <div class="col-12">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{currentString}}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="deleteString(currentString, 'event_types')"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #sightingRefsRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Sighting Refs
                          <span *ngIf="this.errors['sighting_refs']" class="warning-message ps-3">
                            {{ this.errors['sighting_refs'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 pe-5">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select sighting reference"
                              class="form-control add-left no-border-right"
                              style="width: 98%;"
                              [matAutocomplete]="sightingRefsAuto"
                              [(ngModel)]="sightingRefs"
                              (ngModelChange)="filterRefs('sighting')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #sightingRefsAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let opt of this.filteredSightingObjects" [value]="opt.id">
                                {{opt.id}}
                              </mat-option>
                            </mat-autocomplete>
                            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                              <button
                                style="max-width: 3%;"
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                (click)="addString('sighting_refs')"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>  
                      </div>
                      <div class="row" *ngIf="this.currentIncidentCoreObject['sighting_refs'] && this.currentIncidentCoreObject['sighting_refs'].length > 0">
                        <div class="col mb-2 mt-2 pe-5">
                          <div class="row mb-1" *ngFor="let currentString of this.currentIncidentCoreObject['sighting_refs']">
                            <div class="col-12">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{currentString}}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="deleteString(currentString, 'sighting_refs')"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #changedObjectsRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Changed Objects
                          <span class="tooltipright">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext font-weight-normal" style="margin-left: 140px;">
                              Select the State Change Type and input applicable refs then use the add button to create a Changed Object.<br>State Change Type: How this activity influenced the change in state between the initial_ref and result_ref.
                            </div>
                          </span>
                          <span *ngIf="this.errors['initialRefs']" class="warning-message ps-3">
                            {{ this.errors['initialRefs'] }}
                          </span>
                          <span *ngIf="this.errors['resultRefs']" class="warning-message ps-3">
                            {{ this.errors['resultRefs'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 pe-5">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select initial reference"
                              class="form-control"
                              [matAutocomplete]="eventInitialAuto"
                              [(ngModel)]="initialRefs"
                              (ngModelChange)="filterRefs('initialRefs')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #eventInitialAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let opt of this.filteredInitialRefIds" [value]="opt">
                                {{opt}}
                              </mat-option>
                            </mat-autocomplete>
                          </div>
                        </div>
                        <div class="col-6 pe-5">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select result reference"
                              class="form-control"
                              [matAutocomplete]="eventResultAuto"
                              [(ngModel)]="resultRefs"
                              (ngModelChange)="filterRefs('resultRefs')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #eventResultAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let opt of this.filteredResultRefIds" [value]="opt">
                                {{opt}}
                              </mat-option>
                            </mat-autocomplete>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mt-2 pe-5">
                          <div class="d-flex">
                            <select
                              *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                              id="state_change_type"
                              class="form-control dropdown add-left no-border-right"
                              style="width: 98%;"
                              [ngClass]="{'disabled-select': this.state_change_type === ''}"
                              [(ngModel)]="state_change_type"
                            >
                              <option value="" disabled selected>Select State Change Type</option>
                              <option *ngFor="let opt of this.stateChangeTypeOV" [value]="opt">
                                {{ this.stixService.toTitle(opt) }}
                              </option>
                            </select>
                            <input *ngIf="this.stixService.revoked || this.stixService.revocation" class="form-control add-left no-border-right" style="width:98%;" id="state_change_type" type="text" [(ngModel)]="state_change_type" readonly>
                            <ng-container
                              *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                            >
                              <button
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                style="max-width: 3%;"
                                [disabled]="!isChangedObjectValid()"
                                (click)="addObject('changed_objects')"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="this.currentIncidentCoreObject['changed_objects'] && this.currentIncidentCoreObject['changed_objects'].length > 0">
                        <div class="col mb-2 mt-2 pe-5">
                          <div class="row mb-1" *ngFor="let currentObj of this.currentIncidentCoreObject['changed_objects']">
                            <div class="col-12">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{currentObj.initial_ref + '   ' + currentObj.state_change_type + '   ' + currentObj.result_ref }}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="deleteString(currentObj, 'changed_objects')"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #nextEventRefsRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Next Event Refs
                          <span *ngIf="this.errors['nextEventRefs']" class="warning-message ps-3">
                            {{ this.errors['nextEventRefs'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 pe-5">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select event reference"
                              class="form-control add-left no-border-right"
                              style="width: 98%;"
                              [matAutocomplete]="nextEventRefAuto"
                              [(ngModel)]="nextEventRefs"
                              (ngModelChange)="filterRefs('nextEventRefs')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #nextEventRefAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let opt of this.filteredEventRefIds" [value]="opt.id">
                                {{opt.id}}
                              </mat-option>
                            </mat-autocomplete>
                            <ng-container
                              *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                            >
                              <button
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                style="max-width: 3%;"
                                [disabled]="!isRefsValid('event')"
                                (click)="addString('next_event_refs')"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="this.currentIncidentCoreObject['next_event_refs'] && this.currentIncidentCoreObject['next_event_refs'].length > 0">
                        <div class="col mb-2 mt-2 pe-5">
                          <div class="row mb-1" *ngFor="let currentString of this.currentIncidentCoreObject['next_event_refs']">
                            <div class="col-12">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{currentString}}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="deleteString(currentString, 'next_event_refs')"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Impact Header -->
              <div class="row" *ngIf="this.type == 'impact'">
                <!-- Left Column -->
                <div class="col-6 ps-5 pe-5">
                  <div #confidenceRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Confidence
                          <span class="tooltipright">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext p-2" style="width: 250px; margin-left: 120px;">
                              <h4>Confidence Scales</h4>
                              <div class="font-weight-normal">The confidence property reflects the object creator's trust <br> in the accuracy of their data.
                                  The confidence value MUST <br> be a number in the range of 0-100.
                              </div>
                            <div class="tooltip-content">
                                  <table class="mt-2 font-weight-normal">
                                      <tr>
                                          <th>Low/Med/High</th>
                                          <th>Range of Values</th>
                                      </tr>
                                      <tr>
                                          <td style="padding:8px;">Low</td>
                                          <td style="padding:8px;">1-29</td>
                                      </tr>
                                      <tr>
                                          <td style="padding:8px;">Med</td>
                                          <td style="padding:8px;">30-69</td>
                                      </tr>
                                      <tr>
                                          <td style="padding:8px;">High</td>
                                          <td style="padding:8px;">70-100</td>
                                      </tr>
                                  </table>
                            </div>
                            </div>
                          </span>

                          <span *ngIf="this.errors['confidence']" class="warning-message ps-3">
                            {{ this.errors['confidence'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                            <input
                            class="form-control"
                            type="number"
                            min="0"
                            max="100"
                            placeholder="Confidence"
                            [(ngModel)]="this.currentIncidentCoreObject['confidence']"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #createdByRefRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Created By Ref
                          <span class="tooltipright">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext font-weight-normal" style="margin-left: 140px;">
                              Defines the id property of the identity object, describing the entity responsible<br>for creating this object. Relevant objects within the bundle will be listed in a dropdown menu.
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <select type="text" class="form-control"
                            [(ngModel)]="this.currentIncidentCoreObject['created_by_ref']"
                            [ngClass]="{'disabled-select': this.currentIncidentCoreObject['confidence'] === undefined}"
                          >
                            <option [ngValue]="undefined" disabled>Select reference</option>
                            <option value=""></option>
                            <option *ngFor="let id of ids" [value]="id.id">{{id.name}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #impactCategoryRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                         Impact Category*
                         <span *ngIf="this.errors['impact_category']" class="warning-message ps-3">
                           {{ this.errors['impact_category'] }}
                         </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <input
                            *ngIf="this.stixService.revoked || this.stixService.revocation"
                            class="form-control"
                            [(ngModel)]="this.currentIncidentCoreObject['impact_category']"
                            readonly
                          />
                          <select
                            *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                            class="form-control form-select dropdown"
                            [ngClass]="{'disabled-select': this.currentIncidentCoreObject['impact_category'] === undefined}"
                            [(ngModel)]="this.currentIncidentCoreObject['impact_category']"
                            (ngModelChange)="openExtension()"
                          >
                            <option [ngValue]="undefined" *ngIf="!this.currentIncidentCoreObject['impact_category']" disabled selected>Select outcome type</option>
                            <option [value]="this.currentIncidentCoreObject['impact_category']" *ngIf="this.currentIncidentCoreObject['impact_category'] && this.currentIncidentCoreObject['impact_category'].endsWith('-ext')" selected>{{this.stixService.toTitle(this.currentIncidentCoreObject['impact_category'])}}</option>
                            <option
                              *ngFor="let opt of this.impactCategoriesVocab"
                              [value]="opt"
                            >
                              {{ this.stixService.toTitle(opt) }}
                            </option>
                          </select>



                          <!-- <input
                            type="text"
                            matInput
                            placeholder="Select impact category"
                            class="form-control"
                            [matAutocomplete]="impactCategoryAuto"
                            [(ngModel)]="this.currentIncidentCoreObject['impact_category']"
                            (ngModelChange)="filterRefs('impact_category')"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          />
                          <mat-autocomplete #impactCategoryAuto="matAutocomplete" class="autocomplete">
                            <mat-option *ngFor="let opt of this.filteredImpactCategoriesVocab" [value]="opt">
                              {{opt}}
                            </mat-option>
                          </mat-autocomplete> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Right Column -->
                <div class="col-6 pe-5">
                  <div #languageRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Language
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <select class="form-control dropdown"
                            [(ngModel)]="this.currentIncidentCoreObject['lang']"
                            [ngClass]="{'disabled-select': this.currentIncidentCoreObject['language'] === undefined}"
                          >
                            <option [ngValue]="undefined" disabled selected>Select language</option>
                            <option value=""></option>
                            <option *ngFor="let lang of lang_options" [value]="lang.key">{{lang.value}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #recoverabilityRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Recoverability
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <input
                            *ngIf="this.stixService.revoked || this.stixService.revocation"
                            class="form-control"
                            [(ngModel)]="this.currentIncidentCoreObject['recoverability']"
                            readonly
                          />
                          <select
                            *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                            class="form-control dropdown"
                            [ngClass]="{'disabled-select': this.currentIncidentCoreObject['recoverability'] === undefined}"
                            [(ngModel)]="this.currentIncidentCoreObject['recoverability']"
                          >
                            <option [ngValue]="undefined" selected disabled>Select recoverability</option>
                            <option value=""></option>
                            <option
                              *ngFor="let opt of this.recoverabilityEnum"
                              [value]="opt"
                            >
                              {{ this.stixService.toTitle(opt) }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #criticalityRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Criticality
                          <span class="tooltipright">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext p-2" style="width: 400px; margin-left: 110px;">
                              <h4>Incident Criticality Mapping</h4>
                              <div>
                                This appendix defines mappings for criticality scales to be used by the criticality property.<br>
                                A value of "Not Specified" in the table below means that the criticality property is not present.
                              </div>
                              <div class="tooltip-content">
                                  <table class="mt-2">
                                      <tr>
                                          <th>5 Qualitative</th>
                                          <th>STIX Criticality Value</th>
                                          <th>Range of Values</th>
                                      </tr>
                                      <tr>
                                          <td style="padding:8px;">Not specified</td>
                                          <td style="padding:8px;">Not Specified</td>
                                          <td style="padding:8px;">N/A</td>
                                      </tr>
                                      <tr>
                                        <td style="padding:8px;">False Positive</td>
                                        <td style="padding:8px;">0</td>
                                        <td style="padding:8px;">0</td>
                                      </tr>
                                      <tr>
                                        <td style="padding:8px;">Low</td>
                                        <td style="padding:8px;">15</td>
                                        <td style="padding:8px;">1-29</td>
                                      </tr>
                                      <tr>
                                        <td style="padding:8px;">Moderate</td>
                                        <td style="padding:8px;">40</td>
                                        <td style="padding:8px;">30-49</td>
                                      </tr>
                                      <tr>
                                        <td style="padding:8px;">High</td>
                                        <td style="padding:8px;">70</td>
                                        <td style="padding:8px;">50-89</td>
                                      </tr>
                                      <tr>
                                        <td style="padding:8px;">Extreme</td>
                                        <td style="padding:8px;">95</td>
                                        <td style="padding:8px;">90-100</td>
                                      </tr>
                                  </table>
                              </div>
                            </div>
                          </span>

                          <span *ngIf="this.errors['criticality']" class="warning-message ps-3">
                            {{ this.errors['criticality'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input
                            class="form-control"
                            placeholder="Criticality"
                            type="number"
                            [(ngModel)]="this.currentIncidentCoreObject['criticality']"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="this.type == 'impact'">
                <div class="col-12 ps-5 pe-5">
                  <div #impactedEntityCountsRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Impacted Entity Counts
                          <span *ngIf="this.errors['impacted_entity_counts']" class="warning-message ps-3">
                            {{ this.errors['impacted_entity_counts'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-9">
                          <input
                            type="text"
                            matInput
                            placeholder="Select impacted entity type"
                            class="form-control"
                            [matAutocomplete]="impactedEntityCountAuto"
                            [(ngModel)]="entity_count_string"
                            (ngModelChange)="filterRefs('impacted_entity')"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          />
                          <mat-autocomplete #impactedEntityCountAuto="matAutocomplete" class="autocomplete">
                            <mat-option *ngFor="let opt of this.filteredIncidentEntityType" [value]="opt">
                              {{ this.stixService.toTitle(opt) }}                              
                            </mat-option>
                          </mat-autocomplete>
                        </div>
                        <div class="col-3">
                          <div class="d-flex">
                            <input
                              class="form-control add-left no-border-right"
                              placeholder="Count"
                              type="number"
                              style="width: 90%"
                              [(ngModel)]="entity_count_count"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                              <button
                                style="max-width: 10%;"
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                (click)="addEntityCount()"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="this.impacted_entity_counts && this.impacted_entity_counts.size > 0" class="row">
                        <div class="col mb-2 mt-2">
                          <div class="row mb-1" *ngFor="let x of this.impacted_entity_counts.keys()">
                            <div class="col-12">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{x+': '+this.impacted_entity_counts.get(x)}}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="deleteEntityCount(x)"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #impactedRefsRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Impacted Refs
                          <span *ngIf="this.errors['impacted_refs']" class="warning-message ps-3">
                            {{ this.errors['impacted_refs'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select impacted ref"
                              class="form-control add-left no-border-right"
                              style="width:98%;"
                              [matAutocomplete]="impactedRefsAuto"
                              [(ngModel)]="impactedRefs"
                              (ngModelChange)="filterRefs('impacted_refs')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #impactedRefsAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let opt of this.filteredImpactedRefIds" [value]="opt.id">
                                {{ opt.id }}                              
                              </mat-option>
                            </mat-autocomplete>
                            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                              <button
                                style="max-width: 3%;"
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                (click)="addString('impacted_refs')"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="this.currentIncidentCoreObject['impacted_refs'] && this.currentIncidentCoreObject['impacted_refs'].length > 0" class="row">
                        <div class="col mb-2 mt-2">
                          <div class="row mb-1" *ngFor="let currentString of this.currentIncidentCoreObject['impacted_refs']">
                            <div class="col-12">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{currentString}}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="deleteString(currentString, 'impacted_refs')"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Task Header -->
              <div class="row" *ngIf="this.type == 'task'">
                <!-- Left Column -->
                <div class="col-6 ps-5 pe-5">
                  <div #nameRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Name
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <input
                            class="form-control"
                            placeholder="Name"
                            [(ngModel)]="this.currentIncidentCoreObject['name']"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #languageRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Language
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <select class="form-control dropdown"
                            [(ngModel)]="this.currentIncidentCoreObject['lang']"
                            [ngClass]="{'disabled-select': this.currentIncidentCoreObject['language'] === undefined}"
                          >
                            <option [ngValue]="undefined" disabled selected>Select language</option>
                            <option value=""></option>
                            <option *ngFor="let lang of lang_options" [value]="lang.key">{{lang.value}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #outcomeRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Outcome*
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input
                            *ngIf="this.stixService.revoked || this.stixService.revocation"
                            class="form-control"
                            [(ngModel)]="this.currentIncidentCoreObject['outcome']"
                            readonly
                          />
                          <select
                            *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                            class="form-control dropdown"
                            [ngClass]="{'disabled-select': this.currentIncidentCoreObject['outcome'] === undefined}"
                            [(ngModel)]="this.currentIncidentCoreObject['outcome']"
                          >
                            <option [ngValue]="undefined" disabled selected>Select outcome type</option>
                            <option
                              *ngFor="let opt of this.taskOutcomeEnum"
                              [value]="opt"
                            >
                              {{ this.stixService.toTitle(opt) }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Right Column -->
                <div class="col-6 pe-5">
                  <div #confidenceRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Confidence
                          <span class="tooltipright">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext p-2" style="width: 250px; margin-left: 120px;">
                              <h4>Confidence Scales</h4>
                              <div class="font-weight-normal">The confidence property reflects the object creator's trust <br> in the accuracy of their data.
                                  The confidence value MUST <br> be a number in the range of 0-100.
                              </div>
                            <div class="tooltip-content">
                                  <table class="mt-2 font-weight-normal">
                                      <tr>
                                          <th>Low/Med/High</th>
                                          <th>Range of Values</th>
                                      </tr>
                                      <tr>
                                          <td style="padding:8px;">Low</td>
                                          <td style="padding:8px;">1-29</td>
                                      </tr>
                                      <tr>
                                          <td style="padding:8px;">Med</td>
                                          <td style="padding:8px;">30-69</td>
                                      </tr>
                                      <tr>
                                          <td style="padding:8px;">High</td>
                                          <td style="padding:8px;">70-100</td>
                                      </tr>
                                  </table>
                            </div>
                            </div>
                          </span>

                          <span *ngIf="this.errors['confidence']" class="warning-message ps-3">
                            {{ this.errors['confidence'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                            <input
                            class="form-control"
                            type="number"
                            min="0"
                            max="100"
                            placeholder="Confidence"
                            [(ngModel)]="this.currentIncidentCoreObject['confidence']"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #createdByRefRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Created By Ref
                          <span class="tooltipright">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext font-weight-normal" style="margin-left: 140px;">
                              Defines the id property of the identity object, describing the entity responsible<br>for creating this object. Relevant objects within the bundle will be listed in a dropdown menu.
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <select type="text" class="form-control"
                            [(ngModel)]="this.currentIncidentCoreObject['created_by_ref']"
                            [ngClass]="{'disabled-select': this.currentIncidentCoreObject['created_by_ref'] === undefined}"
                          >
                            <option [ngValue]="undefined" disabled>Select reference</option>
                            <option value=""></option>
                            <option *ngFor="let id of ids" [value]="id.id">{{id.name}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #priorityRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Priority
                          <span class="tooltipright">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext p-2" style="width: 250px; margin-left: 90px;">
                              Please reference Appendix B of the Incident Core Extension Docs to
                              find the table/scoring guidelines most appropriate for the situation
                            </div>
                          </span>
                          <span *ngIf="this.errors['priority']" class="ms-2 warning-msg">
                            {{ this.errors['priority'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input
                            class="form-control"
                            type="number"
                            placeholder="Priority"
                            min="0"
                            max="100"
                            [(ngModel)]="this.currentIncidentCoreObject['priority']"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="this.type == 'task'">
                <div class="col-12 ps-5 pe-5">
                  <div #taskTypesRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Task Types
                        </div>
                      </div>
                      <div class="row">
                        <div class="col pe-5">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select task type"
                              class="form-control add-left no-border-right"
                              [matAutocomplete]="taskTypeAuto"
                              [(ngModel)]="taskTypes"
                              (ngModelChange)="filterRefs('task_types')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #taskTypeAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let opt of this.filteredIncidentTaskType" [value]="opt">
                                {{ this.stixService.toTitle(opt) }}                              
                              </mat-option>
                            </mat-autocomplete>
                            <ng-container
                              *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                            >
                              <button
                                class="btn btn-fa-sm add-btn add-right"
                                style="float:right; max-width:3%;"
                                type="button"
                                [disabled]="!taskTypes"
                                (click)="addString('task_types')"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="this.currentIncidentCoreObject['task_types'] && this.currentIncidentCoreObject['task_types'].length > 0">
                        <div class="col mb-2 mt-2">
                          <div class="row mb-1" *ngFor="let currentString of this.currentIncidentCoreObject['task_types']">
                            <div class="col-12 pe-5">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{currentString}}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="deleteString(currentString, 'task_types')"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #errorRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Error
                        </div>
                      </div>
                      <div class="row">
                        <div class="col pe-5">
                          <textarea
                            class="form-control"
                            style="font-size: 14px; padding: 4px 8px;"
                            placeholder="Error"
                            [(ngModel)]="this.currentIncidentCoreObject['error']"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div #changedObjectsRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Changed Objects
                          <span *ngIf="this.errors['initialRefs']" class="warning-message ps-3">
                            {{ this.errors['initialRefs'] }}
                          </span>
                          <span *ngIf="this.errors['resultRefs']" class="warning-message ps-3">
                            {{ this.errors['resultRefs'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 pe-5">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select initial reference"
                              class="form-control"
                              [matAutocomplete]="eventInitialAuto"
                              [(ngModel)]="initialRefs"
                              (ngModelChange)="filterRefs('initialRefs')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #eventInitialAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let opt of this.filteredInitialRefIds" [value]="opt">
                                {{opt}}
                              </mat-option>
                            </mat-autocomplete>
                          </div>
                        </div>
                        <div class="col-6 pe-5">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select result reference"
                              class="form-control"
                              [matAutocomplete]="eventResultAuto"
                              [(ngModel)]="resultRefs"
                              (ngModelChange)="filterRefs('resultRefs')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #eventResultAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let opt of this.filteredResultRefIds" [value]="opt">
                                {{opt}}
                              </mat-option>
                            </mat-autocomplete>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mt-2 pe-5">
                          <div class="d-flex">
                            <select
                              *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                              id="state_change_type"
                              class="form-control dropdown add-left no-border-right"
                              style="width: 98%;"
                              [ngClass]="{'disabled-select': this.state_change_type === ''}"
                              [(ngModel)]="state_change_type"
                            >
                              <option value="" disabled selected>Select type</option>
                              <option *ngFor="let opt of this.stateChangeTypeOV" [value]="opt">
                                {{ this.stixService.toTitle(opt) }}
                              </option>
                            </select>
                            <input *ngIf="this.stixService.revoked || this.stixService.revocation" class="form-control add-left no-border-right" style="width:98%;" id="state_change_type" type="text" [(ngModel)]="state_change_type" readonly>
                            <ng-container
                              *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                            >
                              <button
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                style="max-width: 3%;"
                                [disabled]="!isChangedObjectValid()"
                                (click)="addObject('changed_objects')"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="this.currentIncidentCoreObject['changed_objects'] && this.currentIncidentCoreObject['changed_objects'].length > 0">
                        <div class="col mb-2 mt-2 pe-5">
                          <div class="row mb-1" *ngFor="let currentObj of this.currentIncidentCoreObject['changed_objects']">
                            <div class="col-12">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{currentObj.initial_ref + '   ' + currentObj.state_change_type + '   ' + currentObj.result_ref }}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="deleteString(currentObj, 'changed_objects')"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #impactedEntityCountsRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Impacted Entity Counts
                          <span *ngIf="this.errors['impacted_entity_counts']" class="warning-message ps-3">
                            {{ this.errors['impacted_entity_counts'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-9">
                          <input
                            type="text"
                            matInput
                            placeholder="Select impacted entity type"
                            class="form-control"
                            [matAutocomplete]="impactedEntityCountAuto"
                            [(ngModel)]="entity_count_string"
                            (ngModelChange)="filterRefs('impacted_entity')"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          />
                          <mat-autocomplete #impactedEntityCountAuto="matAutocomplete" class="autocomplete">
                            <mat-option *ngFor="let opt of this.filteredIncidentEntityType" [value]="opt">
                              {{ this.stixService.toTitle(opt) }}                              
                            </mat-option>
                          </mat-autocomplete>
                        </div>
                        <div class="col-3">
                          <div class="d-flex">
                            <input
                              class="form-control add-left no-border-right"
                              placeholder="Count"
                              type="number"
                              style="width: 90%"
                              [(ngModel)]="entity_count_count"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                              <button
                                style="max-width: 10%;"
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                (click)="addEntityCount()"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="this.impacted_entity_counts && this.impacted_entity_counts.size > 0" class="row">
                        <div class="col mb-2 mt-2">
                          <div class="row mb-1" *ngFor="let x of this.impacted_entity_counts.keys()">
                            <div class="col-12">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{x+': '+this.impacted_entity_counts.get(x)}}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="deleteEntityCount(x)"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #nextTaskRefsRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Next Task Refs
                          <span *ngIf="this.errors['nextTaskRefs']" class="warning-message ps-3">
                            {{ this.errors['nextTaskRefs'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select task reference"
                              class="form-control add-left no-border-right"
                              style="width: 98%;"
                              [matAutocomplete]="nextTaskRefAuto"
                              [(ngModel)]="nextTaskRefs"
                              (ngModelChange)="filterRefs('next_task_refs')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #nextTaskRefAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let opt of this.filteredTaskRefIds" [value]="opt.id">
                                {{opt.id}}
                              </mat-option>
                            </mat-autocomplete>
                            <ng-container
                              *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                            >
                              <button
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                style="max-width: 3%;"
                                [disabled]="!isRefsValid('task')"
                                (click)="addString('next_task_refs')"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="this.currentIncidentCoreObject['next_task_refs'] && this.currentIncidentCoreObject['next_task_refs'].length > 0">
                        <div class="col mb-2 mt-2">
                          <div class="row mb-1" *ngFor="let currentString of this.currentIncidentCoreObject['next_task_refs']">
                            <div class="col-12">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{currentString}}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="deleteString(currentString, 'next_task_refs')"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Common Properties -->
              <div class="row">
                <div class="col-12 ps-5 pe-5">
                  <div #descriptionRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Description
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <textarea
                            class="form-control"
                            style="font-size: 14px; padding: 4px 8px;"
                            placeholder="Description"
                            [(ngModel)]="this.currentIncidentCoreObject['description']"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div #startTimeRow class="row pb-4">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-6 pe-5">
                          <div class="row">
                            <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                              Start Time
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <input
                              class="form-control"
                              placeholder="Start Time"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                              [owlDateTimeTrigger]="dtStartTime"
                              [owlDateTime]="dtStartTime"
                              [(ngModel)]="this.currentIncidentCoreObject['start_time']"
                              />
                              <owl-date-time #dtStartTime></owl-date-time>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 pe-5">
                          <div class="row">
                            <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                              Start Time Fidelity
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <input
                                *ngIf="this.stixService.revoked || this.stixService.revocation"
                                class="form-control" [(ngModel)]="this.currentIncidentCoreObject['start_time_fidelity']"
                                readonly
                              />
                              <select
                                *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                                [ngClass]="{'disabled-select': this.currentIncidentCoreObject['start_time_fidelity'] === undefined}"
                                class="form-control dropdown" [(ngModel)]="this.currentIncidentCoreObject['start_time_fidelity']"
                              >
                                <option [ngValue]="undefined" disabled selected>Select Time Fidelity</option>
                                <option value=""></option>
                                <option *ngFor="let opt of this.timestampFidelityEnum" [value]="opt">
                                  {{ this.stixService.toTitle(opt) }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #endTimeRow class="row pb-4">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-6 pe-5">
                          <div class="row">
                            <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                              End Time
                              <span *ngIf="this.errors['end_time']" class="warning-message ps-2">
                                {{ this.errors['end_time'] }}
                              </span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <input
                                class="form-control"
                                placeholder="End Time"
                                [readonly]="this.stixService.revoked || this.stixService.revocation"
                                [owlDateTimeTrigger]="dtEndTime"
                                [owlDateTime]="dtEndTime"
                                [(ngModel)]="this.currentIncidentCoreObject['end_time']"
                              />
                              <owl-date-time #dtEndTime></owl-date-time>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 pe-5">
                          <div class="row">
                            <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                              End Time Fidelity
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <input
                              *ngIf="this.stixService.revoked || this.stixService.revocation"
                              class="form-control" [(ngModel)]="this.currentIncidentCoreObject['end_time_fidelity']"
                              readonly
                              />
                              <select
                                *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                                [ngClass]="{'disabled-select': this.currentIncidentCoreObject['end_time_fidelity'] === undefined}"
                                class="form-control dropdown" [(ngModel)]="this.currentIncidentCoreObject['end_time_fidelity']"
                              >
                                <option [ngValue]="undefined" disabled selected>Select Time Fidelity</option>
                                <option value=""></option>
                                <option *ngFor="let opt of this.timestampFidelityEnum" [value]="opt">
                                  {{ this.stixService.toTitle(opt) }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="this.type == 'impact'">
                <div class="col-12 ps-5 pe-5">
                  <div #supersededRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Superseded By Ref
                          <span *ngIf="this.errors['superseded_by_ref']" class="warning-message ps-3">
                            {{ this.errors['superseded_by_ref'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input
                          type="text"
                          matInput
                          placeholder="Select superseded ref"
                          class="form-control"
                          [matAutocomplete]="supersededAuto"
                          [(ngModel)]="this.currentIncidentCoreObject['superseded_by_ref']"
                          (ngModelChange)="filterRefs('superseded_by_ref')"
                          [readonly]="this.stixService.revoked || this.stixService.revocation"
                        />
                        <mat-autocomplete #supersededAuto="matAutocomplete" class="autocomplete">
                          <mat-option *ngFor="let opt of this.filteredImpactObjects" [value]="opt.id">
                            {{ opt.id }}                              
                          </mat-option>
                        </mat-autocomplete>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pe-2">
            <hr class="section-separator" style="margin-top: 4px;">
            <div class="col-12 pb-3" style="float:left">
                <app-granular-markings class="w-100 extra-btn"></app-granular-markings>
            </div>
            <div class="col-12 pb-3" style="float:left">
                <app-external-references class="w-100 extra-btn"></app-external-references>
            </div>

            <div class="col-12 pb-1">
                <app-extensions class="w-100 extra-btn"></app-extensions>
            </div>

            <!-- <hr class="section-separator" style="margin-top: 8px;"> -->
          </div>
          <div *ngIf="!stixService.guidedUI && stixService.toplevel.length > 0" class="row pt-4">
            <div class="input-group">
                <p class="stix-title pt-2 pb-2"> Top Level Properties</p>
                <div class="p-0 pt-1 float-end ms-3">
                    <button *ngIf="!this.toplevelEnabled" type="button"
                        class="btn btn-fa-sm btn-collapse btn-outline-primary" (click)="this.toplevelEnabled = !this.toplevelEnabled">
                        <fa-icon [icon]="faAngleDoubleDown"></fa-icon>
                    </button>
                    <button *ngIf="this.toplevelEnabled" type="button"
                        class="btn btn-fa-sm btn-collapse btn-outline-secondary" (click)="this.toplevelEnabled = !this.toplevelEnabled">
                        <fa-icon [icon]="faAngleDoubleUp"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="row mb-1" *ngFor="let prop of stixService.toplevel">
                <div *ngIf="toplevelEnabled" class="col-12">
                    <div class="d-flex">
                        <ng-template [ngIf]="!isObject(prop[1])" [ngIfElse]="objProp">
                            <ng-template [ngIf]="('' + prop[1]).length < 200" [ngIfElse]="largeProp">
                                <span
                                    class="form-control dropdown add-left no-border-right"
                                    id="{{prop[0]}}"
                                    style="height: 31px;background-color: #21252908 !important; font-size: smaller;"
                                    readonly
                                ><b>{{prop[0]}}:</b> {{prop[1]}}</span>
                                <button
                                    class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                    type="button"
                                    (click)="stixService.deleteProp(prop[0])"
                                >
                                X
                                </button>
                            </ng-template>
                            <ng-template #largeProp>
                                <span
                                    class="form-control dropdown add-left no-border-right"
                                    style="height: 125px; background-color: #21252908 !important; font-size: smaller; overflow-wrap: break-word; overflow-y: scroll;"
                                    readonly
                                ><b>{{prop[0]}}:</b><br>&#13;&#10;{{prop[1]}}</span>
                                <button
                                    class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                    type="button"
                                    (click)="stixService.deleteProp(prop[0])"
                                    style="height: 125px"
                                >
                                X
                                </button>
                            </ng-template>
                        </ng-template>
                        <ng-template #objProp>
                            <span
                                class="form-control dropdown add-left no-border-right"
                                style="height: 125px; background-color: #21252908 !important; font-size: smaller; overflow-wrap: break-word; overflow-y: scroll; white-space: pre-wrap;"
                                readonly
                            ><b>{{prop[0]}}:</b><br>&#13;&#10;{{prop[1] | json}}</span>
                            <button
                                class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                type="button"
                                (click)="stixService.deleteProp(prop[0])"
                                style="height: 125px"
                            >
                            X
                            </button>
                        </ng-template>
                    </div>
                </div>
            </div>
          </div>
          <hr *ngIf="!stixService.guidedUI" class="section-separator" style="margin-bottom: 8px; margin-top: 1rem;">
        </div>
      </div>
    </div>
    <!-- Bootstrap column is somehow breaking re-rendering when it's out of view -->
    <div class="col-xl-12 text-end mb-5">
      <button
          type="button" class="btn btn-lg btn-secondary component-button text me-2" (click)="cancel()" style="width: 175px !important">
          <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
          Cancel
      </button>   
      <button
          type="button" class="btn btn-lg btn-success component-button text" (click)="addIncidentCoreObject()"
          [disabled]="!isFormValid()" style="width: 175px !important; will-change: transform, opacity;">
          <fa-icon [icon]="faFileImport" class="pe-1"></fa-icon>
          Add to Bundle
      </button>
    </div>
    <!-- <div class="ms-auto text-right mb-5 me-5 pe-3">
      <button
          type="button" class="btn btn-lg btn-secondary component-button text me-2" (click)="cancel()" style="width: 175px !important">
          <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
          Cancel
      </button>   
      <button
          type="button" class="btn btn-lg btn-success component-button text" (click)="addIncidentCoreObject()"
          [disabled]="!isFormValid()" style="width: 175px !important">
          <fa-icon [icon]="faFileImport" class="pe-1"></fa-icon>
          Add to Bundle
      </button>
    </div> -->
  </div>
</div>