<div>
    <div class="d-flex justify-content-left mb-5">
        <h4 class="modal-title ps-3 pt-3" *ngIf="pageNum === 1 || (pageNum === 2 && (notes.size > 0 || new_notes.size > 0))">
            Add or Edit Note Referencing {{type}} Object
        </h4>
        <h4 class="modal-title ps-3 pt-3" *ngIf="pageNum === 2 && notes?.size === 0 && new_notes?.size === 0">
            Add Note Referencing {{type}} Object
        </h4>
    </div>
    <!-- <hr> -->
    <div class="container ps-5 pe-5 note-container">
        <div *ngIf="pageNum === 1" class="row">
            <div class="col">
                <div class="row form-control note-items p-3" (click)="createNewNote()">
                    <div class="col">
                        Create a new Note Object
                    </div>
                </div>
                <div *ngFor="let n of notes.keys()" [ngClass]="{'selected-note': selectedNote == n}" (click)="selectNote(n)" class="row form-control note-items p-3 mt-3">
                    <div class="col">
                        {{shortenContent(notes.get(n)["content"])}}
                    </div>
                </div>
                <div *ngFor="let m of new_notes.keys()" (click)="selectNote(m)" [ngClass]="{'selected-note': selectedNote == m}" class="row form-control note-items p-3 mt-3">
                    <div class="col d-flex justify-content-between">
                        <div>
                            {{shortenContent(new_notes.get(m)["content"])}}
                        </div>
                        <button *ngIf="deletable_notes.has(m)" (click)="deleteNote(m)" class="btn btn-close float-end">
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="pageNum === 2" class="row">
            <div class="col">
                <div class="row mb-4">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                                    Confidence
                                    <span *ngIf="this.errors['confidence']" class="warning-message ps-3">
                                      {{ this.errors['confidence'] }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <input 
                                    class="form-control"
                                    type="number"
                                    min="0"
                                    max="100"
                                    placeholder="Confidence"
                                    [(ngModel)]="note['confidence']"
                                    (ngModelChange)="isValid()"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                                    Language
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <select
                                    class="form-control form-select dropdown"
                                    [(ngModel)]="note['lang']"
                                    (ngModelChange)="isValid()"
                                >
                                    <option [ngValue]="undefined" disabled selected>Select language</option>
                                    <option value=""></option>
                                    <option *ngFor="let lang of lang_options" [value]="lang.key">{{lang.value}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                                    Created By Ref
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <select
                                    class="form-control form-select dropdown"
                                    [(ngModel)]="note['created_by_ref']"
                                    (ngModelChange)="isValid()"
                                >
                                    <option [ngValue]="undefined" disabled>Select reference</option>
                                    <option value=""></option>
                                    <option *ngFor="let id of ids" [value]="id.id">{{id.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                                    Abstract
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <input class="form-control" [(ngModel)]="note['abstract']" (ngModelChange)="isValid()" placeholder="Abstract" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                                    Authors
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-flex">
                                <input class="form-control add-left no-border-right" [(ngModel)]="authors" (ngModelChange)="isValid()" placeholder="Authors" style="width:97%;" />
                                <button
                                    class="btn btn-fa-sm add-btn add-right float-end"
                                    style="max-width:3%;"
                                    type="button"
                                    [disabled]="!authors"
                                    (click)="addString('authors')"
                                >
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="note['authors'] && note['authors'].length > 0" class="row">
                            <div class="col mb-2 mt-2">
                                <div class="row mb-1" *ngFor="let currentString of note['authors']">
                                    <div class="col d-flex">
                                        <input 
                                            class="form-control dropdown add-left no-border-right"
                                            value="{{currentString}}"
                                            readonly
                                        />
                                        <button
                                            class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                            type="button"
                                            (click)="deleteString('authors', currentString)"
                                        >
                                            X
                                      </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                                    Object Refs*
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-flex">
                                <select
                                    class="form-control form-select dropdown add-left no-border-right"
                                    style="width: 97%;"
                                    [(ngModel)]="object_refs"
                                >
                                    <option [ngValue]="undefined" disabled>Select reference</option>
                                    <option value=""></option>
                                    <option *ngFor="let id of object_ref_list" [value]="id">{{id}}</option>
                                </select>
                                <button
                                    class="btn btn-fa-sm add-btn add-right float-end"
                                    style="max-width:3%;"
                                    type="button"
                                    [disabled]="!object_refs"
                                    (click)="addString('object_refs')"
                                >
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="note['object_refs'] && note['object_refs'].length > 0" class="row">
                            <div class="col mb-2 mt-2">
                                <div class="row mb-1" *ngFor="let currentString of note['object_refs']">
                                    <div *ngIf="currentString !== objectId" class="col d-flex">
                                        <input 
                                            class="form-control dropdown add-left no-border-right"
                                            value="{{currentString}}"
                                            readonly
                                        />
                                        <button
                                            class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                            type="button"
                                            (click)="deleteString('object_refs', currentString)"
                                        >
                                            X
                                      </button>
                                    </div>
                                    <div *ngIf="currentString === objectId" class="col">
                                        <input 
                                            class="form-control"
                                            value="{{currentString}}"
                                            readonly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                                    Content*
                                    <span *ngIf="this.errors['content']" class="warning-message ps-3">
                                      {{ this.errors['content'] }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <textarea class="form-control" style="font-size: 14px; padding: 4px 8px;" [(ngModel)]="note['content']" (ngModelChange)="isValid()" placeholder="Content"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <hr> -->
    <div class="d-flex justify-content-end mt-5 mb-2 me-3">
        <button
            type="button" class="btn btn-sm btn-secondary component-button text me-2"
            (click)="close()" *ngIf="pageNum === 1"
        >
            <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
            Close
        </button>  
        <button
            type="button" class="btn btn-sm btn-secondary component-button text me-2"
            (click)="close()" *ngIf="pageNum === 2"
        >
            <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
            Cancel
        </button>  
        <button
            *ngIf="pageNum === 1" type="button" class="btn btn-sm btn-success component-button text" [disabled]="!selectedNote"
            (click)="goNext()"
        >
            <fa-icon [icon]="faArrowRight" class="pe-1"></fa-icon>
            Next
        </button>
        <button
            *ngIf="pageNum === 2 && (notes.size > 0 || new_notes.size > 0)" type="button" class="btn btn-sm btn-secondary component-button text me-2 ms-2"
            (click)="goPrevious()"
        >
            <fa-icon [icon]="faArrowLeft" class="pe-1"></fa-icon>
            Previous
        </button>   
        <button
            *ngIf="pageNum === 2" type="button" class="btn btn-sm btn-success component-button ms-2 text"
            [disabled]="!valid"
            (click)="addNote()"
        >
            <fa-icon [icon]="faAddToBundle" class="pe-1"></fa-icon>
            Add Note
        </button>
    </div>
</div>
