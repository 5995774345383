<div class="container-fluid ps-5 pe-5">
  <div class="row ms-2 mt-3 me-3">
    <div class="col-xl-12">
      <div class="row ps-3">
        <h3 class="col component-title ps-2 mt-2" style="margin-bottom:0;">{{ type.replace("-", " ") }}</h3>
        <div class="col">
          <button type="button" class="btn add-btn float-end position-relative" style="overflow: visible;" (click)="openNoteDialogModal()">
            <fa-icon class="me-1" [icon]="faNote"></fa-icon>
            Add Note
            <span *ngIf="stixService.notes && stixService.new_notes && stixService.notes.size + stixService.new_notes.size > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
              {{stixService.notes.size + stixService.new_notes.size}}
            </span>
          </button>
        </div> 
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row ps-2 pe-2" *ngIf="errorMessage && this.errorMessage.length > 0">
            <div class="mx-auto mb-1 warning-message">{{ errorMessage }}</div>     
          </div>
          <div class="row pe-2 pb-2" style="padding-top: 0;">
            <hr class="section-separator" style="margin-top: 8px; margin-bottom: 8px;">
          </div>

          <div class="row pe-2" [ngClass]="{'ps-2 mb-2': stixService.guidedUI}" style="padding-top: 0;">
            <app-string-array class="extra-btn" [key]="'object_marking_refs'" (stringArrayOutput)="setObjectMarkingReferences($event)"
                [class]="'col-6'"></app-string-array>
            <app-string-array class="extra-btn" [key]="'labels'" (stringArrayOutput)="setLabels($event)"
                [class]="'col-6'"></app-string-array>
            <hr *ngIf="!stixService.guidedUI" class="section-separator mt-3 mb-2">
          </div>
      
          <div class="row">
            <!-- Side Bar -->
            <div *ngIf="this.type === 'malware-behavior'" class="col-2 pt-3 pb-3 position-sticky side-bar" style="font-size: 1rem;">
              <div class="row p-1" *ngFor="let prop of this.behaviorProperties">
                <div class="col-12 text-start sidebar-item" (click)="scrollTo(prop.row)">
                  {{prop.name}}
                </div>
              </div>
            </div>

            <div *ngIf="this.type === 'malware-method'" class="col-2 pt-3 pb-3 position-sticky side-bar" style="font-size: 1rem;">
              <div class="row p-1" *ngFor="let prop of this.methodProperties">
                <div class="col-12 text-start sidebar-item" (click)="scrollTo(prop.row)">
                  {{prop.name}}
                </div>
              </div>
            </div>

            <div *ngIf="this.type === 'malware-objective'" class="col-2 pt-3 pb-3 position-sticky side-bar" style="font-size: 1rem;">
              <div class="row p-1" *ngFor="let prop of this.objectiveProperties">
                <div class="col-12 text-start sidebar-item" (click)="scrollTo(prop.row)">
                  {{prop.name}}
                </div>
              </div>
            </div>
            
            <div id="main-table" class="col-10 main-table mt-5 mb-5">
              <div class="row">
                <div class="col-12 ps-5 pe-5">
                  <div class="row pb-4">
                    <div class="col-3">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Type*
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              The value of this property MUST be {{ type }}
                            </div>
                          </span>
                        </div>

                        <!-- <span style="float: left">
                          <fa-icon class="ps-1" [icon]="faInfoCircle"> </fa-icon>
                          <div class="toolttext">
                            The value of this property MUST be {{ type }}
                          </div>
                        </span> -->
                      </div>
                      <div class="row">
                        <div class="col">
                          <input class="form-control" readonly [(ngModel)]="this.currentMalwareObject['type']" />
                        </div>
                      </div>
                    </div>

                    <div class="col-7">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          ID
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              The id property uniquely identifies this object.
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input
                            class="form-control"
                            [(ngModel)]="this.currentMalwareObject['id']"
                            readonly
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-2 pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Spec Ver.
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              The version of the STIX specification used to represent this object.
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input
                            class="form-control"
                            [(ngModel)]="this.currentMalwareObject['spec_version']"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 ps-5 pe-5">
                  <div class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Created
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              The time at which the object was originally created.
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input
                            class="form-control"
                            [(ngModel)]="this.currentMalwareObject['created']"
                            readonly
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Modified
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              The modified property is only used by STIX Objects that support versioning and represents the time that this particular version of the object was last modified.
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input
                            class="form-control"
                            [(ngModel)]="this.currentMalwareObject['modified']"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <!-- Left Column -->
                <div class="col-6 ps-5 pe-5">
                  <div #confidenceRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Confidence
                          <span class="tooltipright">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext p-2" style="width: 250px; margin-left: 120px;">
                              <h4>Confidence Scales</h4>
                              <div class="font-weight-normal">The confidence property reflects the object creator's trust <br> in the accuracy of their data.
                                  The confidence value MUST <br> be a number in the range of 0-100.
                              </div>
                            <div class="tooltip-content">
                                  <table class="mt-2 font-weight-normal">
                                      <tr>
                                          <th>Low/Med/High</th>
                                          <th>Range of Values</th>
                                      </tr>
                                      <tr>
                                          <td style="padding:8px;">Low</td>
                                          <td style="padding:8px;">1-29</td>
                                      </tr>
                                      <tr>
                                          <td style="padding:8px;">Med</td>
                                          <td style="padding:8px;">30-69</td>
                                      </tr>
                                      <tr>
                                          <td style="padding:8px;">High</td>
                                          <td style="padding:8px;">70-100</td>
                                      </tr>
                                  </table>
                            </div>
                            </div>
                          </span>
                          <span *ngIf="this.errors['confidence']" class="warning-message ps-3">
                            {{ this.errors['confidence'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                            <input
                            class="form-control"
                            type="number"
                            min="0"
                            max="100"
                            placeholder="Confidence"
                            [(ngModel)]="this.currentMalwareObject['confidence']"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #languageRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Language
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              The language of the text content in this object. If the property is not present, then the language of the content is en (English).
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <select class="form-control dropdown"
                            [(ngModel)]="this.currentMalwareObject['lang']"
                            [ngClass]="{'disabled-select': this.currentMalwareObject['language'] === undefined}"
                          >
                            <option [ngValue]="undefined" disabled selected>Select language</option>
                            <option value=""></option>
                            <option *ngFor="let lang of lang_options" [value]="lang.key">{{lang.value}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #nameRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Name*
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              The name of the {{this.type}}.
                            </div>
                          </span>
                          <span *ngIf="this.errors['name']" class="warning-message ps-3">
                            {{ this.errors['name'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <input
                            class="form-control"
                            placeholder="Name"
                            [(ngModel)]="this.currentMalwareObject['name']"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <ng-container *ngIf="this.type == 'malware-behavior'">
                    <div #objVersionRow class="row pb-4">
                      <div class="col">
                        <div class="row">
                          <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                            Object Version
                            <span class="tooltipright ps-2">
                              <fa-icon [icon]="faInfoCircle"> </fa-icon>
                              <div class="toolttext" style="margin-left:80px;">
                                Specifies the version of the behavior object.
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <input
                              class="form-control"
                              placeholder="Object Version"
                              [(ngModel)]="this.currentMalwareObject['obj_version']"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="this.type == 'malware-method'">
                    <div #behaviorRefRow class="row pb-4">
                      <div class="col">
                        <div class="row">
                          <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                            Behavior Ref*
                            <span class="tooltipright ps-2">
                              <fa-icon [icon]="faInfoCircle"> </fa-icon>
                              <div class="toolttext" style="margin-left:80px;">
                                The STIX identifier of the parent behavior object associated with the method.
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="d-flex">
                              <input
                                type="text"
                                matInput
                                placeholder="Select malware behavior reference"
                                class="form-control add-left no-border-right"
                                style="width: 98%;"
                                [matAutocomplete]="behaviorRefsAuto"
                                [(ngModel)]="this.currentMalwareObject['behavior_ref']"
                                (ngModelChange)="filterRefs('behavior_ref')"
                                [readonly]="this.stixService.revoked || this.stixService.revocation"
                              />
                              <mat-autocomplete #behaviorRefsAuto="matAutocomplete" class="autocomplete">
                                <mat-option *ngFor="let id of this.filteredBehaviorIds" [value]="id.id">
                                  {{id.id}}
                                </mat-option>
                              </mat-autocomplete>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <!-- Right Column -->
                <div class="col-6 pe-5">
                  <div #createdByRefRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Created By Ref
                          <span class="tooltipright">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext font-weight-normal" style="margin-left: 140px;">
                              Defines the id property of the identity object, describing the entity responsible<br>for creating this object. Relevant objects within the bundle will be listed in a dropdown menu.
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <select type="text" class="form-control"
                            [(ngModel)]="this.currentMalwareObject['created_by_ref']"
                            [ngClass]="{'disabled-select': this.currentMalwareObject['confidence'] === undefined}"
                          >
                            <option [ngValue]="undefined" disabled>Select reference</option>
                            <option value=""></option>
                            <option *ngFor="let id of identityIds" [value]="id.id">{{id.name}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #revokedRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Revoked
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              Indicates whether the object has been revoked.
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <select class="form-control dropdown"
                            [(ngModel)]="this.currentMalwareObject['revoked']"
                            [ngClass]="{'disabled-select': this.currentMalwareObject['revoked'] === undefined}"
                          >
                            <option [ngValue]="undefined" disabled selected>Revoked</option>
                            <option value=""></option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div #microRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Micro
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              Indicates whether the method belongs to a micro-behavior. The default value is false.
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <select class="form-control dropdown"
                            [(ngModel)]="this.currentMalwareObject['micro']"
                            [ngClass]="{'disabled-select': this.currentMalwareObject['micro'] === undefined}"
                          >
                            <option [ngValue]="undefined" disabled selected>Micro</option>
                            <option value=""></option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 ps-5">
                  <div #objectDefinitionRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Object Definition*
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              The definition of the {{this.type}}.
                            </div>
                          </span>
                          <span *ngIf="this.errors['obj_defn']" class="warning-message ps-3">
                            {{ this.errors['obj_defn'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 pe-5">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="--> Use button on right to create an Object Definition -->"
                              class="form-control add-left no-border-right text-center"
                              style="width: 98%;"
                              readonly
                            />
                            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                              <button
                                style="max-width: 3%;"
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                (click)="openDialog('obj_defn')"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>  
                      </div>
                      <div class="row" *ngIf="this.currentMalwareObject['obj_defn']">
                        <div class="col mb-2 mt-2 pe-5">
                          <div class="row mb-1">
                            <div class="col-12">
                              <div class="d-flex">
                                <textarea 
                                  class="form-control dropdown add-left no-border-right"
                                  style="font-size: small;"
                                  readonly
                                >{{this.currentMalwareObject['obj_defn'] | json}}</textarea>
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  style="height: 200px;"
                                  (click)="this.currentMalwareObject['obj_defn'] = undefined"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="this.type == 'malware-behavior'">
                <div class="col-12 ps-5">
                  <div #relatedObjectRefsRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Related Object Refs
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              Related objects from other knowledge bases.<br>This property SHOULD be the identifier of an ATT&CK technique.
                            </div>
                          </span>
                          <span *ngIf="this.errors['related_object_refs']" class="warning-message ps-3">
                            {{ this.errors['related_object_refs'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 pe-5">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select ATT&CK reference"
                              class="form-control add-left no-border-right"
                              style="width: 98%;"
                              [matAutocomplete]="relatedObjectRefsAuto"
                              [(ngModel)]="related_object_refs"
                              (ngModelChange)="filterRefs('related_object_refs')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #relatedObjectRefsAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let id of this.filteredAttackIds" [value]="id.id">
                                {{id.id}}
                              </mat-option>
                            </mat-autocomplete>
                            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                              <button
                                style="max-width: 3%;"
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                (click)="addString('related_object_refs')"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>  
                      </div>
                      <div class="row" *ngIf="this.currentMalwareObject['related_object_refs'] && this.currentMalwareObject['related_object_refs'].length > 0">
                        <div class="col mb-2 mt-2 pe-5">
                          <div class="row mb-1" *ngFor="let id of this.currentMalwareObject['related_object_refs']">
                            <div class="col-12">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{id}}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="deleteString(id, 'related_object_refs')"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div #objectiveRefsRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Objective Refs
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              The malware objective objects associated with the behavior.
                            </div>
                          </span>
                          <span *ngIf="this.errors['objective_refs']" class="warning-message ps-3">
                            {{ this.errors['objective_refs'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 pe-5">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select malware objective reference"
                              class="form-control add-left no-border-right"
                              style="width: 98%;"
                              [matAutocomplete]="objectiveRefsAuto"
                              [(ngModel)]="objective_refs"
                              (ngModelChange)="filterRefs('objective_refs')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #objectiveRefsAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let id of this.filteredObjectiveIds" [value]="id.id">
                                {{id.id}}
                              </mat-option>
                            </mat-autocomplete>
                            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                              <button
                                style="max-width: 3%;"
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                (click)="addString('objective_refs')"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>  
                      </div>
                      <div class="row" *ngIf="this.currentMalwareObject['objective_refs'] && this.currentMalwareObject['objective_refs'].length > 0">
                        <div class="col mb-2 mt-2 pe-5">
                          <div class="row mb-1" *ngFor="let id of this.currentMalwareObject['objective_refs']">
                            <div class="col-12">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{id}}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="deleteString(id, 'objective_refs')"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #tagsRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Tags
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              The dictionary keys MUST be the tag names where the values are the value of the tag.
                            </div>
                          </span>
                          <span *ngIf="this.errors['tags']" class="warning-message ps-3">
                            {{ this.errors['tags'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <input
                            type="text"
                            matInput
                            placeholder="Tag label"
                            class="form-control"
                            [(ngModel)]="tag_label"
                            [readonly]="this.stixService.revoked || this.stixService.revocation"
                          />
                        </div>
                        <div class="col-8" style="padding-right: 6px;">
                          <div class="d-flex">
                            <input
                              class="form-control add-left no-border-right"
                              placeholder="Tag value"
                              type="text"
                              style="width: 90%"
                              [(ngModel)]="tag_value"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                              <button
                                style="max-width: 10%;"
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                (click)="addTag()"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="this.tags && this.tags.size > 0" class="row">
                        <div class="col mb-2 mt-2 pe-5">
                          <div class="row mb-1" *ngFor="let x of this.tags.keys()">
                            <div class="col-12">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{x+': '+this.tags.get(x)}}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="deleteTag(x)"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #snippetsRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Snippets
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              The code snippets associated with the behavior.
                            </div>
                          </span>
                          <span *ngIf="this.errors['snippets']" class="warning-message ps-3">
                            {{ this.errors['snippets'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 pe-5">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="--> Use button on right to create a Snippet -->"
                              class="form-control add-left no-border-right text-center"
                              style="width: 98%;"
                              readonly
                            />
                            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                              <button
                                style="max-width: 3%;"
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                (click)="openDialog('snippets')"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>  
                      </div>
                      <div class="row" *ngIf="this.currentMalwareObject['snippets'].length > 0">
                        <div class="col mb-2 mt-2 pe-5">
                          <div class="row mb-1" *ngFor="let snippet of this.currentMalwareObject['snippets']; let i = index">
                            <div class="col-12">
                              <div class="d-flex">
                                <textarea 
                                  class="form-control dropdown add-left no-border-right"
                                  style="font-size: small;"
                                  readonly
                                >{{ snippet | json}}</textarea>
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  style="height: 100px;"
                                  (click)="this.currentMalwareObject['snippets'].splice(i)"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #detectionRulesRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Detection Rules
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              The ways to detect the behavior.
                            </div>
                          </span>
                          <span *ngIf="this.errors['detection_rules']" class="warning-message ps-3">
                            {{ this.errors['detection_rules'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 pe-5">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="--> Use button on right to create a Detection Rule -->"
                              class="form-control add-left no-border-right text-center"
                              style="width: 98%;"
                              readonly
                            />
                            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                              <button
                                style="max-width: 3%;"
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                (click)="openDialog('detection_rules')"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>  
                      </div>
                      <div class="row" *ngIf="this.currentMalwareObject['detection_rules'].length > 0">
                        <div class="col mb-2 mt-2 pe-5">
                          <div class="row mb-1" *ngFor="let rule of this.currentMalwareObject['detection_rules']; let i = index">
                            <div class="col-12">
                              <div class="d-flex">
                                <textarea 
                                  class="form-control dropdown add-left no-border-right"
                                  style="font-size: small;"
                                  readonly
                                >{{ rule | json}}</textarea>
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  style="height: 100px;"
                                  (click)="this.currentMalwareObject['detection_rules'].splice(i)"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="this.type != 'malware-objective'">
                <div class="col-12 ps-5">
                  <div #contributorRefsRow class="row pb-4">
                    <div class="col pe-5">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Contributor Refs
                          <span class="tooltipright ps-2">
                            <fa-icon [icon]="faInfoCircle"> </fa-icon>
                            <div class="toolttext" style="margin-left:80px;">
                              Contributor(s) to the behavior information.
                            </div>
                          </span>
                          <span *ngIf="this.errors['contributor_refs']" class="warning-message ps-3">
                            {{ this.errors['contributor_refs'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 pe-5">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select contributor reference"
                              class="form-control add-left no-border-right"
                              style="width: 98%;"
                              [matAutocomplete]="contributorRefsAuto"
                              [(ngModel)]="contributor_refs"
                              (ngModelChange)="filterRefs('contributor_refs')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #contributorRefsAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let id of this.filteredIdentityIds" [value]="id.id">
                                {{id.name}}
                              </mat-option>
                            </mat-autocomplete>
                            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                              <button
                                style="max-width: 3%;"
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                (click)="addString('contributor_refs')"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>  
                      </div>
                      <div class="row" *ngIf="this.currentMalwareObject['contributor_refs'] && this.currentMalwareObject['contributor_refs'].length > 0">
                        <div class="col mb-2 mt-2 pe-5">
                          <div class="row mb-1" *ngFor="let id of this.currentMalwareObject['contributor_refs']">
                            <div class="col-12">
                              <div class="d-flex">
                                <input 
                                  class="form-control dropdown add-left no-border-right"
                                  value="{{id}}"
                                  readonly
                                />
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="deleteString(id, 'contributor_refs')"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pe-2">
            <hr class="section-separator" style="margin-top: 4px;">
            <div class="col-12 pb-3" style="float:left">
                <app-granular-markings class="w-100 extra-btn"></app-granular-markings>
            </div>
            <div class="col-12 pb-3" style="float:left">
                <app-external-references class="w-100 extra-btn"></app-external-references>
            </div>

            <div class="col-12 pb-1">
                <app-extensions class="w-100 extra-btn"></app-extensions>
            </div>

            <!-- <hr class="section-separator" style="margin-top: 8px;"> -->
          </div>
          <div *ngIf="!stixService.guidedUI && stixService.toplevel.length > 0" class="row pt-4">
            <div class="input-group">
                <p class="stix-title pt-2 pb-2"> Top Level Properties</p>
                <div class="p-0 pt-1 float-end ms-3">
                    <button *ngIf="!this.toplevelEnabled" type="button"
                        class="btn btn-fa-sm btn-collapse btn-outline-primary" (click)="this.toplevelEnabled = !this.toplevelEnabled">
                        <fa-icon [icon]="faAngleDoubleDown"></fa-icon>
                    </button>
                    <button *ngIf="this.toplevelEnabled" type="button"
                        class="btn btn-fa-sm btn-collapse btn-outline-secondary" (click)="this.toplevelEnabled = !this.toplevelEnabled">
                        <fa-icon [icon]="faAngleDoubleUp"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="row mb-1" *ngFor="let prop of stixService.toplevel">
                <div *ngIf="toplevelEnabled" class="col-12">
                    <div class="d-flex">
                        <ng-template [ngIf]="!isObject(prop[1])" [ngIfElse]="objProp">
                            <ng-template [ngIf]="('' + prop[1]).length < 200" [ngIfElse]="largeProp">
                                <span
                                    class="form-control dropdown add-left no-border-right"
                                    id="{{prop[0]}}"
                                    style="height: 31px;background-color: #21252908 !important; font-size: smaller;"
                                    readonly
                                ><b>{{prop[0]}}:</b> {{prop[1]}}</span>
                                <button
                                    class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                    type="button"
                                    (click)="stixService.deleteProp(prop[0])"
                                >
                                X
                                </button>
                            </ng-template>
                            <ng-template #largeProp>
                                <span
                                    class="form-control dropdown add-left no-border-right"
                                    style="height: 125px; background-color: #21252908 !important; font-size: smaller; overflow-wrap: break-word; overflow-y: scroll;"
                                    readonly
                                ><b>{{prop[0]}}:</b><br>&#13;&#10;{{prop[1]}}</span>
                                <button
                                    class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                    type="button"
                                    (click)="stixService.deleteProp(prop[0])"
                                    style="height: 125px"
                                >
                                X
                                </button>
                            </ng-template>
                        </ng-template>
                        <ng-template #objProp>
                            <span
                                class="form-control dropdown add-left no-border-right"
                                style="height: 125px; background-color: #21252908 !important; font-size: smaller; overflow-wrap: break-word; overflow-y: scroll; white-space: pre-wrap;"
                                readonly
                            ><b>{{prop[0]}}:</b><br>&#13;&#10;{{prop[1] | json}}</span>
                            <button
                                class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                type="button"
                                (click)="stixService.deleteProp(prop[0])"
                                style="height: 125px"
                            >
                            X
                            </button>
                        </ng-template>
                    </div>
                </div>
            </div>
          </div>
          <hr *ngIf="!stixService.guidedUI" class="section-separator" style="margin-bottom: 8px; margin-top: 1rem;">
        </div>
      </div>
    </div>
    <!-- Bootstrap column is somehow breaking re-rendering when it's out of view -->
    <div class="col-xl-12 text-end mb-5">
      <button
          type="button" class="btn btn-lg btn-secondary component-button text me-2" (click)="cancel()" style="width: 175px !important">
          <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
          Cancel
      </button>   
      <button
          type="button" class="btn btn-lg btn-success component-button text" (click)="addMalwareBehaviourObject()"
          [disabled]="!isFormValid()" style="width: 175px !important; will-change: transform, opacity;">
          <fa-icon [icon]="faFileImport" class="pe-1"></fa-icon>
          Add to Bundle
      </button>
    </div>
  </div>
</div>