export class SnippetObject {

    snippet: string;
    exemplify_ref: string;
    language: string;
    description: string;
    references: any[];
    hash: string;
    author_ref: string;

    constructor(snippet: string, exemplify_ref?: string, language?: string, description?: string, references?: any[], hash ?: string, author_ref?: string){
        this.snippet = snippet || '';
        this.exemplify_ref = exemplify_ref || '';
        this.language = language || '';
        this.description = description || '';
        this.references = references || [];
        this.hash  = hash  || '';
        this.author_ref = author_ref || '';
    }

    /* getGranularMarkingSelectors(): string[] {
        let selectors = [];
        this.contains_refs ? selectors.push('contains_refs') : null;
        this.comment ? selectors.push('comment') : null;
        return selectors;
    } */
}