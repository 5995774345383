
<div class="row sticky-top pb-3" style="top: 69px; background-color: white;">
  <div class="col-12 mt-1">
    <div class="row ps-3">
      <h5 class="pt-1">Malware Corpus</h5>
      <div class="pe-0 ps-5">
        <button type="button" *ngIf="!this.currentMalwareCorpus['isEnabled']"
            class="btn btn-fa-sm btn-outline-primary" style="padding-left:12px !important;"
            (click)="this.currentMalwareCorpus['isEnabled'] = !this.currentMalwareCorpus['isEnabled'];this.stixService.sendMBEStatus(false);">
            <fa-icon [icon]="faAngleDoubleDown"></fa-icon>
        </button>
        <button type="button" *ngIf="this.currentMalwareCorpus['isEnabled']"
            class="btn btn-fa-sm btn-outline-secondary"
            style="padding-left:12px !important;"
            (click)="this.currentMalwareCorpus['isEnabled'] = !this.currentMalwareCorpus['isEnabled'];this.stixService.sendMBEStatus(true);">
            <fa-icon [icon]="faAngleDoubleUp"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- <div class="col-6">
    <div class="float-end d-flex justify-content-end pe-0">
      <button type="button" *ngIf="!isEnabled"
          class="btn btn-fa-sm btn-outline-primary" style="padding-left:12px !important;"
          (click)="isEnabled = !isEnabled">
          <fa-icon [icon]="faAngleDoubleDown"></fa-icon>
      </button>
      <button type="button" *ngIf="isEnabled"
          class="btn btn-fa-sm btn-outline-secondary"
          style="padding-left:12px !important;"
          (click)="isEnabled = !isEnabled">
          <fa-icon [icon]="faAngleDoubleUp"></fa-icon>
      </button>
    </div>
  </div> -->
</div>
<div *ngIf="this.errorMessage && this.errorMessage.length > 0" class="row">
  <div class="mx-auto warning-message">{{ this.errorMessage }}</div>
</div>

<div class="form-group p-2" *ngIf="this.currentMalwareCorpus['isEnabled']">
  <div class="row">
    <div class="col-2 pt-3 pb-3 table-content position-sticky" style="color: #a7a7a7; height:40%; contain:layout; font-size: .9rem; top:110px;">
      <div class="row p-1">
        <div class="col-12 text-start sidebar-item" (click)="scrollTo(yearRow)">
          Year
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12 text-start sidebar-item" (click)="scrollTo(platformsRow)">
          Platforms
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12 text-start sidebar-item" (click)="scrollTo(objectDefinitionRow)">
          Object Definition*
        </div>
      </div>
    </div>
    <div class="col-10 pt-3 pb-3" style="border-left: 1px solid #ced4da;">
      <div class="row">
        <div id="left-col" class="col-6 pe-3 pb-0 ps-5">
          <div #yearRow id="year-row" class="row p-2 ps-0 pb-3 row-item">
            <div class="col">
              <div class="row">
                <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                  Year
                  <span class="tooltipright ps-2">
                    <fa-icon [icon]="faInfoCircle"> </fa-icon>
                    <div class="toolttext" style="margin-left:80px;">
                      This property denotes the year the malware instance or family was first seen.
                    </div>
                  </span>
                  <span *ngIf="this.otherErrors['year'] && this.otherErrors['year'] != ''" class=" warning-message ps-3">
                      {{ this.otherErrors['year'] }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-11">
                  <input
                  class="form-control"
                  type="text"
                  placeholder="Year"
                  [(ngModel)]="this.currentMalwareCorpus['year']"
                  [readonly]="this.stixService.revoked || this.stixService.revocation"
                  (change)="isValid()"
                />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="right-col" class="col-6 pe-3 pb-0 ps-0">



        </div>
      </div>
      <div class="row">
        <div class="col-12 pe-3 ps-5">
          <div #platformsRow id="platforms-row" class="row p-2 ps-0 pb-3 row-item">
            <div class="col">
              <div class="row">
                <div class="col stix-title ms-0 mb-2 input-header" style="float: left">
                  Platforms<span class="tooltipright ps-2">
                    <fa-icon [icon]="faInfoCircle"> </fa-icon>
                    <div class="toolttext" style="margin-left:80px;">
                      This property denotes the year the malware instance or family was first seen.
                    </div>
                  </span>
                  <span *ngIf="this.otherErrors['platforms'] && this.otherErrors['platforms'] != ''" class=" warning-message ps-3">
                      {{ this.otherErrors['platforms'] }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-11 pe-0">
                  <input
                    type="text"
                    matInput
                    placeholder="Select impact reference"
                    class="form-control add-left no-border-right"
                    [matAutocomplete]="platformsAuto"
                    [(ngModel)]="platforms"
                    (ngModelChange)="filterOSOV()"
                    [readonly]="this.stixService.revoked || this.stixService.revocation"
                  />
                  <mat-autocomplete #platformsAuto="matAutocomplete" class="autocomplete">
                    <mat-option *ngFor="let os of this.filteredOSOV" [value]="os">
                      {{ os }}
                    </mat-option>
                  </mat-autocomplete>
                </div>
                <div class="col-1 ps-0">
                  <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                    <button
                      class="btn btn-fa-sm btn-primary no-border-left add-right"
                      type="button"
                      (click)="addString('platforms')"
                    >
                      <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                  </ng-container>
                </div> 
              </div>
              <div class="row">
                <div class="col-12 mb-2 mt-2">
                  <div class="row mb-1 ref-item"  *ngFor="let currentString of this.currentMalwareCorpus['platforms']" tabindex="0">
                      <div class="col-11 pe-0">
                        <input
                          class="form-control dropdown add-left no-border-right"
                          value="{{getRef('OSOV', currentString)}}"
                          readonly
                        >
                      </div>
                      <div class="col ps-0">
                        <button
                          class="btn btn-fa-sm btn-primary add-button add-right font-weight-bold"
                          type="button"
                          (click)="deleteString(currentString, 'platforms')"
                        >
                          X
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div #objectDefinitionRow class="row p-2 ps-0 pb-3 row-item">
            <div class="col">
              <div class="row">
                <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                  Object Definition*
                  <span class="tooltipright ps-2">
                    <fa-icon [icon]="faInfoCircle"> </fa-icon>
                    <div class="toolttext" style="margin-left:80px;">
                      Reference to the malware.
                    </div>
                  </span>
                  <span *ngIf="this.otherErrors['obj_defn']" class="warning-message ps-3">
                    {{ this.otherErrors['obj_defn'] }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 pe-5">
                  <div class="d-flex pe-3" style="margin-right: 1px;">
                    <input
                      type="text"
                      matInput
                      placeholder="--> Use button on right to create an Object Definition -->"
                      class="form-control add-left no-border-right text-center"
                      style="width: 98%;"
                      readonly
                    />
                    <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                      <button
                        style="max-width: 3%;"
                        class="btn btn-fa-sm btn-primary no-border-left add-right"
                        type="button"
                        (click)="openDialog('obj_defn')"
                      >
                        <fa-icon [icon]="faPlus"></fa-icon>
                      </button>
                    </ng-container>
                  </div>
                </div>  
              </div>
              <div class="row" *ngIf="this.currentMalwareCorpus['obj_defn']">
                <div class="col-12 mb-2 mt-2">
                  <div class="row mb-1 ref-item">
                    <div class="col-11 pe-0">
                      <textarea 
                          class="form-control dropdown add-left no-border-right"
                          style="font-size: small;height: 200px;"
                          readonly
                        >{{this.currentMalwareCorpus['obj_defn'] | json}}</textarea>
                    </div>
                    <div class="col ps-0">
                      <button
                          class="btn btn-fa-sm btn-primary add-button add-right font-weight-bold"
                          type="button"
                          style="height: 200px;"
                          (click)="this.currentMalwareCorpus['obj_defn'] = undefined; isValid();"
                        >
                        X
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {{this.fixThis()}}
</div>

