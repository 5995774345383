export const acs_enums = {
    classification: ["TS" , "S" , "C" , "U"] as const,
    formal_determination: ["Publicly Releasable", "Federal and non-Federal Entities", "Federal Entities"] as const,
    deny_permit: ["Deny", "Permit"] as const,
    privilege_action: [ "ALL", "CISAUSES", "DSPLY" , "IDSRC" ,  "TENOT" , "NETDEF" , "LEGAL" , "INTEL" , "TEARLINE" , "OPACTION" , "REQUEST" , "ANONYMOUSACCESS" ,] as const,
    entity: ["ALL", "MIL" , "GOV" , "CTR" , "SVR" , "SVC" , "DEV" , "NET" ] as const,
    permitted_nationalities: [
        "ALL",
        'US',
        'A1',
        'A2',
        'A3',
        'AD',
        'AE',
        'AF',
        'AG',
        'AI',
        'AL',
        'AM',
        'AO',
        'AQ',
        'AR',
        'AS',
        'AT',
        'AU',
        'AW',
        'AZ',
        'BA',
        'BB',
        'BD',
        'BE',
        'BF',
        'BG',
        'BH',
        'BI',
        'BJ',
        'BL',
        'BM',
        'BN',
        'BO',
        'BQ',
        'BR',
        'BS',
        'BT',
        'BV',
        'BW',
        'BY',
        'BZ',
        'CA',
        'CC',
        'CD',
        'CF',
        'CG',
        'CH',
        'CI',
        'CK',
        'CL',
        'CM',
        'CN',
        'CO',
        'CP',
        'CR',
        'CU',
        'CV',
        'CW',
        'CX',
        'CY',
        'CZ',
        'DE',
        'DG',
        'DJ',
        'DK',
        'DM',
        'DO',
        'DZ',
        'EC',
        'EE',
        'EG',
        'EH',
        'ER',
        'ES',
        'ET',
        'FI',
        'FJ',
        'FK',
        'FM',
        'FO',
        'FR',
        'GA',
        'GB',
        'GD',
        'GE',
        'GF',
        'GG',
        'GH',
        'GI',
        'GL',
        'GM',
        'GN',
        'GP',
        'GQ',
        'GR',
        'GS',
        'GT',
        'GU',
        'GW',
        'GY',
        'HK',
        'HM',
        'HN',
        'HR',
        'HT',
        'HU',
        'ID',
        'IE',
        'IL',
        'IM',
        'IN',
        'IO',
        'IQ',
        'IR',
        'IS',
        'IT',
        'JE',
        'JM',
        'JO',
        'JP',
        'KE',
        'KG',
        'KH',
        'KI',
        'KM',
        'KN',
        'KP',
        'KR',
        'KW',
        'KY',
        'KZ',
        'LA',
        'LB',
        'LC',
        'LI',
        'LK',
        'LR',
        'LS',
        'LT',
        'LU',
        'LV',
        'LY',
        'MA',
        'MC',
        'MD',
        'ME',
        'MF',
        'MG',
        'MH',
        'MK',
        'ML',
        'MM',
        'MN',
        'MO',
        'MP',
        'MQ',
        'MR',
        'MS',
        'MT',
        'MU',
        'MV',
        'MW',
        'MX',
        'MY',
        'MZ',
        'NA',
        'NC',
        'NE',
        'NF',
        'NG',
        'NI',
        'NL',
        'NO',
        'NP',
        'NR',
        'NU',
        'NZ',
        'OM',
        'PA',
        'PE',
        'PF',
        'PG',
        'PH',
        'PK',
        'PL',
        'PM',
        'PN',
        'PR',
        'PT',
        'PW',
        'PY',
        'QA',
        'QM',
        'QS',
        'QU',
        'QW',
        'QX',
        'QZ',
        'RE',
        'RO',
        'RS',
        'RU',
        'RW',
        'SA',
        'SB',
        'SC',
        'SD',
        'SE',
        'SG',
        'SH',
        'SI',
        'SK',
        'SL',
        'SM',
        'SN',
        'SO',
        'SR',
        'SS',
        'ST',
        'SV',
        'SX',
        'SY',
        'SZ',
        'TC',
        'TD',
        'TF',
        'TG',
        'TH',
        'TJ',
        'TK',
        'TL',
        'TM',
        'TN',
        'TO',
        'TR',
        'TT',
        'TV',
        'TW',
        'TZ',
        'UA',
        'UG',
        'UY',
        'UZ',
        'VA',
        'VC',
        'VE',
        'VG',
        'VI',
        'VN',
        'VU',
        'WF',
        'WS',
        'XA',
        'XB',
        'XC',
        'XD',
        'XE',
        'XG',
        'XH',
        'XJ',
        'XK',
        'XL',
        'XM',
        'XP',
        'XQ',
        'XR',
        'XS',
        'XT',
        'XU',
        'XV',
        'XW',
        'YE',
        'YT',
        'ZA',
        'ZM',
        "ZW"] as const,
    permitted_organizations: ["ALL", "USA.CIA", "USA.CTIIC", "USA.DIA", "USA.DHS", "USA.DHS.CBP", "USA.DHS.ICE", "USA.DHS.NCCIC", "USA.DHS.NCSC", "USA.DHS.TSA", "USA.DHS.USCG", "USA.DHS.US-CERT", "USA.DHS.USSS", "USA.DISA", "USA.DNI", "USA.DNI.IC-SCC", "USA.DOC", "USA.DOC.NIST", "USA.DOD", "USA.DOD.AFCYBER", "USA.DOD.ARCYBER", "USA.DOD.C10F","USA.DOD.DC3", "USA.DOD.MARFORCYBER", "USA.DOD.USA", "USA.DOD.USAF", "USA.DOD.USCYBERCOM", "USA.DOD.USCYBERCOM-JOC", "USA.DOD.USMC", "USA.DOD.USN", "USA.DOD.USSTRATCOM", "USA.DOE", "USA.DOJ", "USA.DOJ.DEA", "USA.DOJ.FBI", "USA.DOS", "USA.DOT", "USA.DOT.FAA", "USA.ED", "USA.EOP", "USA.GSA", "USA.HHS", "USA.HUD", "USA.NASA", "USA.NCIJTF", "USA.NGA", "USA.NRO", "USA.NSA", "USA.NSA.NTOC", "USA.SSA", "USA.TREAS", "USA.USDA"] as const,
    shareability: ["ALL", "NCC" , "EM" , "LE" , "IC"  ] as const,
    sharing_scope: ["USA.CIA", "USA.USG", "USA.CTIIC", "USA.DIA", "USA.DHS", "USA.DHS.CBP", "USA.DHS.ICE", "USA.DHS.NCCIC", "USA.DHS.NCSC", "USA.DHS.TSA", "USA.DHS.USCG", "USA.DHS.US-CERT", "USA.DHS.USSS", "USA.DISA", "USA.DNI", "USA.DNI.IC-SCC", "USA.DOC", "USA.DOC.NIST", "USA.DOD", "USA.DOD.AFCYBER", "USA.DOD.ARCYBER", "USA.DOD.C10F","USA.DOD.DC3", "USA.DOD.MARFORCYBER", "USA.DOD.USA", "USA.DOD.USAF", "USA.DOD.USCYBERCOM", "USA.DOD.USCYBERCOM-JOC", "USA.DOD.USMC", "USA.DOD.USN", "USA.DOD.USSTRATCOM", "USA.DOE", "USA.DOJ", "USA.DOJ.DEA", "USA.DOJ.FBI", "USA.DOS", "USA.DOT", "USA.DOT.FAA", "USA.ED", "USA.EOP", "USA.GSA", "USA.HHS", "USA.HUD", "USA.NASA", "USA.NCIJTF", "USA.NGA", "USA.NRO", "USA.NSA", "USA.NSA.NTOC", "USA.SSA", "USA.TREAS", "USA.USDA" , "FOREIGNGOV" , "SECTOR"] as const
}

export interface AcsConfig {
    classification?: Classification,
    formal_determination?:  FormalDetermination,
    // formal_determination_list?: FormalDetermination[],
    usage_permissions?: DenyPermit,
    privilege_action?: PrivilegeAction,
    usage_rule_effect?: DenyPermit,
    entity?:  Entity,
    entity_list?: Entity[],
    permitted_nationalities?: PermittedNationalities,
    permitted_nationalities_list?: PermittedNationalities[],
    permitted_organizations?:  PermittedOrganizations,
    permitted_organizations_list?: PermittedOrganizations[],
    shareability?:  Shareability,
    shareability_list?: Shareability[],
    further_sharing?: DenyPermit,
    sharing_scope?: SharingScope,
    sharing_scope_list?: SharingScope[],
    further_rule_effect?: DenyPermit,
    access_privilege_list?: AccessPrivilege[],
    further_sharing_list?: FurtherSharing[]
}

export type Classification = typeof acs_enums.classification[number] | "";
export type FormalDetermination = typeof acs_enums.formal_determination[number] | "";
export type PrivilegeAction = typeof acs_enums.privilege_action[number] | "";
export type DenyPermit = typeof acs_enums.deny_permit[number] | "";
export type Entity = typeof acs_enums.entity[number] | "";
export type PermittedNationalities = typeof acs_enums.permitted_nationalities[number] | "";
export type PermittedOrganizations = typeof acs_enums.permitted_organizations[number] | "";
export type Shareability = typeof acs_enums.shareability[number] | "";
export type SharingScope = typeof acs_enums.sharing_scope[number] | "";
export type ListProperties = 'formal_determination' | 'entity' | 'permitted_nationalities' | 'permitted_organizations' | 'shareability' | 'sharing_scope'

export interface AccessPrivilege {
    privilege_action: PrivilegeAction,
    rule_effect: 'deny' | 'permit',
    privilege_scope?: PrivilegeScope
}

export interface PrivilegeScope {
    entity?: Entity[],
    permitted_nationalities?: PermittedNationalities[],
    permitted_organizations?: PermittedOrganizations[],
    shareability?: Shareability[]
}

export interface FurtherSharing {
    sharing_scope: SharingScope[],
    rule_effect: 'deny' | 'permit'
}