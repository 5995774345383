<div class="dialog">
  <div class="modal-header">
    <h4 class="modal-title" id="publish-modal-title">
      <span>{{ this.objectType }} Entry</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="onX()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!--div class="modal-body">
    <div class="row">
      <div class="mx-auto warning-message">{{ this.errorMessage }}</div>
    </div>
    <div class="form-group mt-2 pt-2 ps-2 pe-2">
      <div class="row">
        <div class="p-0 ps-2 pe-2 col-8">
          <p class="stix-title" style="float: left">{{ this.objectType }} Ref*</p>
          <div class="input-group">
            <select
              *ngIf="!this.stixService.revoked && !this.stixService.revocation"
              id="object_ref"
              class="form-control dropdown"
              [(ngModel)]="object_ref"
            >
              <option value="" selected></option>
              <option
                *ngFor="let opt of this.refObjects"
                [value]="opt['id']"
              >
                {{ opt['id'] }}
              </option>
            </select>
            <input class="form-control" id="object_ref" type="text" [(ngModel)]="object_ref"
                [readonly]="this.stixService.revoked || this.stixService.revocation">
          </div>

          <div class="row" *ngIf="this.errors['object_ref'] && this.errors['object_ref'] != ''">
            <span class="mb-2 warning-message ps-3">
                {{ this.errors['object_ref'] }}
            </span>
          </div>
        </div>
        <div class="p-0 ps-2 pe-2 col-4">
          <p class="stix-title" style="float: left">Sequence Start</p>
          <select
              *ngIf="!this.stixService.revoked && !this.stixService.revocation"
              id="sequence_start"
              class="form-control dropdown"
              [(ngModel)]="sequence_start"
            >
              <option value="" selected></option>
              <option value="true" selected>True</option>
              <option value="false" selected>False</option>
            </select>
            <input class="form-control" id="sequence_start" type="text" [(ngModel)]="sequence_start"
              *ngIf="this.stixService.revoked || this.stixService.revocation" readonly>
        </div>
      </div>


      <div class="pt-5">
        <div class="row p-0 ps-2">
          <h5 style="float: left">Next Steps*</h5><br>
          <div *ngIf="!this.stixService.revoked && !this.stixService.revocation" class="input-group pe-2">
            <div class="input-group">
              <div class="input-group col-11 p-0">
                <div class="col-8 p-0">
                  <p class="stix-title pe-2" style="float: left">{{ this.objectType }} Ref</p>
                  <div class="input-group">
                    <select
                      id="seq_object_ref"
                      class="form-control dropdown"
                      [(ngModel)]="seq_object_ref"
                    >
                      <option value="" selected></option>
                      <option
                        *ngFor="let opt of this.refObjects"
                        [value]="opt['id']"
                      >
                        {{ opt['id'] }}
                      </option>
                    </select>
                    <input class="form-control" id="seq_object_ref" type="text"
                        [(ngModel)]="seq_object_ref">
                  </div>
                </div>
                <div class="col-2 p-0">
                  <p class="stix-title pe-2" style="float: left">Condition Type</p>
                  <select class="form-control dropdown" [(ngModel)]="seq_condition_type">
                    <option value="" selected></option>
                    <option *ngFor="let opt of this.activityConditionEnum" [value]="opt">
                      {{ this.stixService.toTitle(opt) }}
                    </option>
                  </select>
                </div>
                <div class="col-2 p-0">
                  <p class="stix-title pe-2 ps-2" style="float: left">Transition Type</p>
                  <select class="form-control dropdown" [(ngModel)]="seq_transition_type">
                    <option value="" selected></option>
                    <option *ngFor="let opt of this.activityTransitionEnum" [value]="opt">
                      {{ this.stixService.toTitle(opt) }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="pt-4 ps-1">
                <button class="btn btn-fa-sm btn-primary add-button" type="button"
                  (click)="this.addNextStep()">
                  <fa-icon [icon]="faPlus"></fa-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="this.errors['next_steps'] && this.errors['next_steps'] != ''">
            <span class="mb-2 warning-message ps-3">
                {{ this.errors['next_steps'] }}
            </span>
          </div>

          <div
            class="form-group mt-2 pe-0 col-9 ps-4"
            *ngIf="this.next_steps.length > 0"
          >
            <accordion>
              <accordion-group
                *ngFor="let step of this.next_steps; let i = index"
                [heading]="step['event_ref'] || step['task_ref']"
                [panelClass]="'accordion-panel-class'"
              >
                <ng-container
                  *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                >
                  <div>
                    <button
                      type="button"
                      class="btn btn-fa btn-danger"
                      style="margin-left: 10px"
                      (click)="this.next_steps.splice(i, 1)"
                    >
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                </ng-container>
                <pre>{{ step | json }}</pre>
              </accordion-group>
            </accordion>
          </div>
        </div>
      </div>
    </div>
  </div-->
  <div class="modal-body">
    <div class="row">
      <div class="mx-auto warning-message">{{ this.errorMessage }}</div>
    </div>
    <div class="form-group ps-2 pe-2">
      <div class="pt-2">
        <div class="row p-0 ps-2">
          <h5 style="float: left">Next {{ objectType }} Refs*</h5><br>
          <div *ngIf="!this.stixService.revoked && !this.stixService.revocation" class="input-group pe-2">
            <div class="input-group">
              <div class="input-group col-11 p-0">
                <div class="col-12 p-0">
                  <p class="stix-title pe-2" style="float: left">{{ objectType }} Ref</p>
                  <div class="input-group">
                    <select
                      id="seq_object_ref"
                      class="form-control dropdown"
                      [(ngModel)]="seq_object_ref"
                    >
                      <option value="" selected></option>
                      <option
                        *ngFor="let opt of this.refObjects"
                        [value]="opt['id']"
                      >
                        <span *ngIf="objectType == 'Event'">
                          {{ opt['name'] ? opt['name'] : opt['id']}}
                        </span>
                        <span *ngIf="objectType == 'Impact'">
                          {{ opt['impact_category'] ? opt['impact_category'] + " (" + opt['id'] + ')'  : opt['id']}}
                        </span>
                        <span *ngIf="objectType == 'Task'">
                          {{ opt['name'] ? opt['name'] : opt['id']}}
                        </span>
                      </option>
                    </select>
                    <input class="form-control" id="seq_object_ref" type="text"
                        [(ngModel)]="seq_object_ref">
                  </div>
                </div>
              </div>

              <div class="pt-4 ps-1">
                <button class="btn btn-fa-sm btn-primary add-button" type="button"
                  (click)="this.addNextStep()" [disabled]="!seq_object_ref || next_steps.includes(seq_object_ref)">
                  <fa-icon [icon]="faPlus"></fa-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="this.errors['next_steps'] && this.errors['next_steps'] != ''">
            <span class="mb-2 warning-message ps-3">
                {{ this.errors['next_steps'] }}
            </span>
          </div>

          <div
            class="form-group mt-2 pe-0 col-9 ps-4"
            *ngIf="this.next_steps.length > 0"
          >
            <accordion>
              <accordion-group
                *ngFor="let step of this.next_steps; let i = index"
                [heading]="step"
                [panelClass]="'accordion-panel-class'"
              >
                <ng-container
                  *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                >
                  <div>
                    <button
                      type="button"
                      class="btn btn-fa btn-danger"
                      style="margin-left: 10px"
                      (click)="this.next_steps.splice(i, 1)"
                    >
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                </ng-container>
                <pre>{{ step | json }}</pre>
              </accordion-group>
            </accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary" (click)="onX()">
      Cancel
    </button>
    <button type="button" class="btn btn-sm btn-success" (click)="onConfirm()" [disabled]="!isDialogValid()">
      Add
    </button>
  </div>
</div>
