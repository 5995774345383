<div class="container-fluid ps-3 pe-3">
  <div class="row ms-2 mt-3 me-3">
    <div class="col-xl-12">
      <div class="row ps-3">
        <h3 class="col component-title ps-2 mt-2" style="margin-bottom:0;">Snippet</h3> 
      </div>
      <div class="row pe-2" style="padding-top: 0;">
        <hr class="section-separator" style="margin-top: 8px; margin-bottom: 8px;">
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row"> 
            <div id="main-table" class="col-12 mt-3 mb-5">

              <div class="row">
                <div class="col-12">

                  <div #snippetRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Snippet*
                          <span *ngIf="this.errors['snippet']" class="warning-message ps-3">
                            {{ this.errors['snippet'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex">
                            <textarea
                              type="text"
                              matInput
                              placeholder="Snippet"
                              [(ngModel)]="this.currSnippet.snippet"
                              class="form-control"
                              style="font-size: smaller;"
                            ></textarea>
                          </div>
                        </div>  
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <!-- Left Column -->
                <div class="col-6 pe-5">

                  <div #exemplifyRefRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Exemplify Ref
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select malware-method reference"
                              class="form-control add-left no-border-right"
                              style="width: 98%;"
                              [matAutocomplete]="methodRefsAuto"
                              [(ngModel)]="this.currSnippet.exemplify_ref"
                              (ngModelChange)="filterRefs('exemplify_ref')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #methodRefsAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let id of this.filteredMethodIds" [value]="id.id">
                                {{id.id}}
                              </mat-option>
                            </mat-autocomplete>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #languageRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Language
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select language"
                              class="form-control add-left no-border-right"
                              style="width: 98%;"
                              [matAutocomplete]="languageAuto"
                              [(ngModel)]="this.currSnippet.language"
                              (ngModelChange)="filterRefs('language')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #languageAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let lang of filteredLang" [value]="lang">
                                {{lang}}
                              </mat-option>
                            </mat-autocomplete>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <!-- Right Column -->
                <div class="col-6">

                  <div #authorRefRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Author Ref
                          <span *ngIf="this.errors['author_ref']" class="warning-message ps-3">
                            {{ this.errors['author_ref'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="Select author reference"
                              class="form-control add-left no-border-right"
                              style="width: 98%;"
                              [matAutocomplete]="authorRefsAuto"
                              [(ngModel)]="this.currSnippet.author_ref"
                              (ngModelChange)="filterRefs('author_ref')"
                              [readonly]="this.stixService.revoked || this.stixService.revocation"
                            />
                            <mat-autocomplete #authorRefsAuto="matAutocomplete" class="autocomplete">
                              <mat-option *ngFor="let id of this.filteredIdentityIds" [value]="id.id">
                                {{id.id}}
                              </mat-option>
                            </mat-autocomplete>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div #hashRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Hash
                          <span *ngIf="this.errors['hash']" class="warning-message ps-3">
                            {{ this.errors['hash'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <input
                          class="form-control"
                          placeholder="Hash"
                          [(ngModel)]="this.currSnippet.hash"
                        />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="row">
                <div class="col-12">

                  <div #descriptionRow class="row pb-4">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          Description
                          <span *ngIf="this.errors['description']" class="warning-message ps-3">
                            {{ this.errors['description'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex">
                            <textarea
                              type="text"
                              matInput
                              placeholder="Description"
                              [(ngModel)]="this.currSnippet.description"
                              class="form-control"
                              style="font-size: smaller;"
                            ></textarea>
                          </div>
                        </div>  
                      </div>
                    </div>
                  </div>

                  <div #referencesRow class="row">
                    <div class="col">
                      <div class="row">
                        <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                          References
                          <span *ngIf="this.errors['description']" class="warning-message ps-3">
                            {{ this.errors['description'] }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex">
                            <input
                              type="text"
                              matInput
                              placeholder="--> Use button on right to create a Reference -->"
                              class="form-control add-left no-border-right text-center"
                              style="width: 98%;"
                              readonly
                            />
                            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                              <button
                                style="max-width: 3%;"
                                class="btn btn-fa-sm add-btn add-right"
                                type="button"
                                (click)="createRef()"
                              >
                                <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                            </ng-container>
                          </div>
                        </div>  
                      </div>
                      <div class="row" *ngIf="this.currSnippet.references.length > 0">
                        <div class="col mb-2 mt-2 pe-5">
                          <div class="row mb-1" *ngFor="let ref of this.currSnippet.references; let i = index">
                            <div class="col-12">
                              <div class="d-flex">
                                <textarea 
                                  class="form-control dropdown add-left no-border-right"
                                  style="font-size: small;"
                                  readonly
                                >{{ ref | json}}</textarea>
                                <button
                                  class="btn btn-fa-sm add-btn add-right font-weight-bold"
                                  type="button"
                                  (click)="this.currSnippet.references.splice(i)"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Bootstrap column is somehow breaking re-rendering when it's out of view -->
    <div class="col-xl-12 text-end mb-5">
      <button
          type="button" class="btn btn-lg btn-secondary component-button text me-2" (click)="onX()" style="width: 175px !important">
          <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
          Cancel
      </button>   
      <button
          type="button" class="btn btn-lg btn-success component-button text" (click)="onConfirm()"
          [disabled]="!isDialogValid()" style="width: 175px !important; will-change: transform, opacity;">
          <fa-icon [icon]="faFileImport" class="pe-1"></fa-icon>
          Add
      </button>
    </div>
  </div>
</div>