<div class="dialog">
    <div class="modal-header ">
        <h4 class="modal-title" id="publish-modal-title">
            <span>Granular Markings</span>
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="onX()">
            <span aria-hidden="true"></span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="mx-auto warning-message">{{this.errorMessage}}</div>    
        </div>
        <!-- <div class="form-group mt-2" *ngIf="!isAddingGranularMarkings && this.stixService.granularMarkings.length > 0">
            <accordion>
                <accordion-group *ngFor="let granularMarking of this.stixService.granularMarkings"
                    [heading]="granularMarking.selectors[0]" [panelClass]="'accordion-panel-class'">
                    <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                        <div>
                            <button type="button"
                                class="btn btn-fa btn-primary" 
                                (click)="editGranularMarkings(granularMarking)">
                                <fa-icon [icon]="faEdit" ></fa-icon>
                            </button>
                            <button type="button"
                                class="btn btn-fa btn-danger" style="margin-left: 10px;"
                                (click)="deleteGranularMarkings(granularMarking)">
                                <fa-icon [icon]="faTrash" ></fa-icon>
                            </button>
                            <button class="btn btn-sm btn-primary add-button" type="button"
                                (click)=editGranularMarkings(granularMarking) style="height:40px;width:auto">
                                Edit
                            </button>
                            <button class="btn btn-sm btn-primary add-button" type="button"
                                (click)=deleteGranularMarkings(granularMarking)
                                style="height:40px;width:auto;background-color:rgb(210, 0, 0)">
                                Delete
                            </button>
                        </div>
                    </ng-container>
                    <pre>{{ granularMarking | json }}</pre>
                </accordion-group>
            </accordion>
        </div> -->
        <div class="form-group mt-2" *ngIf="isAddingGranularMarkings">
            <div class="input-group mb-2">
                <span class="input-group-text">
                    <div class="tooltipright"><b>Selectors</b>
                        <span class="toolttext">ctrl+click to choose multiple selectors.<br> The text field displays current
                            selection(s).</span>
                    </div>
                </span>
                <input class="form-control" [(ngModel)]="this.newSelector" type="text" [disabled]="true">
                <select type="text" size=3 class="selectpicker form-control form-select" [(ngModel)]="this.newSelector" name="newSelector"
                    id="newSelector" multiple style="height:90px">
                    <option *ngFor="let item of stixService.granularMarkingSelectors" [value]=item>
                        {{item}}
                    </option>
                </select>
            </div>
            <div class="input-group mb-2">
                <span class="input-group-text">
                    <b>Language</b>
                </span>
                
                <select type="text" class="form-control form-select" 
                    [(ngModel)]="this.currentGranularMarking.lang" [disabled]="this.currentGranularMarking.marking_ref != ''">
                    <option value=""></option>
                    <option *ngFor="let lang of this.lang_options" [value]="lang.key">{{lang.value}}</option>
                </select>
            </div>
            <div class="input-group">
                <span class="input-group-text">
                    <div class="tooltipright"><b>Marking Reference</b>
                        <span class="toolttext">Common references in dropdown -<br>use the box on the right for custom
                            entries.</span>
                    </div>
                </span>
                <select type="text" class="form-control form-select" (change)="getTlpOptions($event)"
                    [disabled]="!isTLPOne || this.currentGranularMarking.lang != ''" [(ngModel)]="tlp_version">
                    <option value=''></option>
                    <option value="v1">TLP v1.0</option>
                    <option value="v2">TLP v2.0</option>            
                </select>
                <select type="text" class="form-control form-select" [(ngModel)]="tlp_name" (change)="setMarkingRef()"
                    [disabled]="this.currentGranularMarking.lang != ''">
                    <option value='' selected></option>
                    <option *ngFor="let tlp of tlp_options" [value]="tlp.key">{{tlp.key}}</option>
                    <!--ng-container *ngFor="let obj of stixService.bundle.objects">
                        <option *ngIf="obj.id.includes('marking-definition--')" [value]="obj.id">
                            {{obj.id}}
                        </option>
                    </ng-container-->
                </select>
                <input class="form-control" style="width: 35%;" [(ngModel)]="this.currentGranularMarking.marking_ref" type="text"
                    [disabled]="this.currentGranularMarking.lang != ''">
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex">
        <button type="button" class="btn btn-sm btn-secondary" (click)="onX()">
            <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
            Cancel
        </button>
        <button type="button" class="btn btn-sm btn-success ms-2" (click)="onConfirm()" [disabled]="this.currentGranularMarking.lang == '' && this.currentGranularMarking.marking_ref == '' || this.newSelector.length == 0 || this.newSelector[0] == ''">
            <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
            Add
        </button>
    </div>
</div>





