export const environment = {
  guidedUI: true,
  keycloak: false,
  production: false,
  fluentd: true,
  environment: "dev",
  version: "3.3.0",
  siteTitle: "DEVELOPMENT",
  isSupportEnabled: false,
  queryResultLimit: 64, // Maximum number of objects to be returned on TAXII Query per page
  // Objects per page may be lower if there is a server imposed limit
  tlpConfig: {
    enabledTlpVersions: [
      // {value: 'v1', label: 'TLP v1.0'},
      { value: 'v2', label: 'TLP v2.0' }
    ],
    disabledTlpColors: [
      { name: 'TLP:RED', version: 'v2' }
    ],
    enableTlpOne: false
  },
  cognitoUserPoolId: "us-east-1_I34MpgAn0",
  cognitoAppClientId: "6sng7cjd48mnqafe71nam7av0e",
  quicksight: {
    dashboardId: '5a884ffb-cacf-4229-ab84-25585bfa533a',
    userArn: 'arn:aws:quicksight:us-east-1:737701924959:user/default/qemajl.lumi@peraton.com',
    apiGatewayUrl: 'https://epyuv3fjx8.execute-api.us-east-1.amazonaws.com/prod/getDashboardEmbedURL?',
    resetDisabled: false,
    undoRedoDisabled: false
  },
  taxiiServer: {
    url: 'https://3.235.58.91/',
    username: 'imx-admin',
    password: '2zrn$I456tel',
    a1ApiRoot: 'Send2A1',
    a1ChildRoot: 'https://taxii4j2.dfta.ninja/taxii/v2.1-os/Send2A1/',
    ctiCollectionId: '94459486-1905-4252-9988-8af6cccd8fdf',
    a1CollectionId: '94859b8c-6795-40e5-9f8c-70433e40ad19',
    a1RawCollectionId: '7d476dc7-5c2a-418b-b150-8b07cad20b43',
    apiVersion: 'v2.1-os',
    adminRoleName: 'offline_access',
    required: []
  },
  adminServer: {
    url: 'https://3.235.58.91/',
    username: 'imx-admin',
    password: '2zrn$I456tel',
    apiVersion: 'v1.0',
    baselineGroup: '',  // no content or null indicate no baseline group is added to user
  },
  cisaIdentity: {
    "type": "identity",
    "id": "identity--b1160532-b8f3-4cfa-9b7d-423e253fbc59",
    "spec_version": "2.1",
    "name": "CISA Dev",
    "description": "Cybersecurity and Infrastructure Security Agency Dev Identity.",
    "identity_class": "organization",
    "confidence": 100,
    "lang": "en",
    "object_marking_refs": [
      "marking-definition--94868c89-83c2-464b-929b-a1a8aa3c8487"
    ],
    "created": "2022-09-06T22:54:13.599Z",
    "modified": "2022-09-06T22:54:13.599Z"
  },
  extensionSchema: {
    url: 'https://3.235.58.91/ext/v1.0/schema/',
    username: 'imx-admin',
    password: '2zrn$I456tel'
  },
  aisTaxiiProxy: {
    url: 'https://3.211.155.16:9443/taxii/v2.1-os/',
    username: 'imx-admin',
    password: '2zrn$I456tel',
    required: ['ACS Markings']
  },
  keycloakConfig: {
    // url: 'http://localhost:8080', //local 
    // realm: 'DFTA', //local
    url: 'https://keycloak.dfta.ninja', //dev
    realm: 'DFTA', //dev
    clientId: 'imx'
  },
  fluentdConfig: {
    protocol: 'https',
    // url: '127.0.0.1:9880'
    url: 'logging.dfta.ninja',
    // url: '35.175.111.116:9880',
    // url: 'localhost:9880'
  },
  supportServerConfig: {
    bugLabel: 'Support::New',
    featureLabel: 'Feature::New',
    path: '/gitlab-issue/',
    projectId: '1267', //1
  },
  bearerTokenURLs: [
    // 'https://35.175.209.41/',
    'https://localhost:9443',
    'http://localhost',
  ],
  notKeycloakTaxiiServers: [
    './assets',
    'https://rbac.dfta.ninja/*',
    // 'https://35.175.209.41/*',
    'https://44.203.235.105/*',
    'https://3.211.155.16/*',
    'https://taxii.dfta.awpc.peratonpartners.com/*',
  ],
  imxServer: {
    // url: 'http://localhost:3000',
    // url: 'https://35.175.111.116:443',
    url: 'https://imx-server.dfta.ninja',
    user: 'imx-admin',
    pass: 'imx'
  },
  deciderURL: 'https://decider.dfta.ninja/',
  // deciderURL: 'http://decider.th.ten:8001/',
  analyst1URL: 'https://ec2-34-206-54-94.compute-1.amazonaws.com/',
  kibanaDashboardURL: "http://35.175.111.116:5601/app/discover#/",
  tpg: {
    url: 'http://localhost:8080/ctraib/tpg.php',
  },
  helpVideo: {
    "who-individual": "who-individual.mp4",
    "how-event-detection": "how-event-detection.mp4",
    "how-observable": "how-observable.mp4",
    "how-pattern-builder": "how-pattern-builder.mp4",
    "how-ttp": "how-ttp.mp4",
    "when-sighting": "when-sighting.mp4",
    "how-event": "how-event.mp4",
    "how-cwe": "how-cwe.mp4",
    "why-common-behaviors": "why-common-behaviors.mp4",
    "why-attacker": "why-attacker.mp4",
    "when-event": "when-event.mp4",
    "where-location": "where-location.mp4",
    "what-impact": "what-impact.mp4",
    "report-assessment": "report-assessment.mp4"
  },
  helpScript: {
    "who-individual": "who-individual.pdf",
    "how-event-detection": "how-event-detection.pdf",
    "how-observable": "how-observable.mp4",
    "how-pattern-builder": "how-pattern-builder.pdf",
    "how-ttp": "how-ttp.pdf",
    "when-sighting": "when-sighting.pdf",
    "how-event": "how-event.pdf",
    "how-cwe": "how-cwe.pdf",
    "why-common-behaviors": "why-common-behaviors.pdf",
    "why-attacker": "why-attacker.pdf",
    "when-event": "when-event.pdf",
    "where-location": "where-location.pdf",
    "what-impact": "what-impact.pdf",
    "report-assessment": "report-assessment.pdf"
  },
  customConfiguration: {
    analyst1: {
      enabled: true,
      apiRoot: 'Send2A1',
      collectionId: '94859b8c-6795-40e5-9f8c-70433e40ad19',
    },
    cisa: {
      enabled: true,
      apiRoot: 'Test',
      collectionId: '8cc07b46-ce47-4394-acdf-0808b9b8e86d',
    }
  },
};
