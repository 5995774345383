import { v4 as uuid } from "uuid";
import { FormModel } from "../dynamic-form-component/form-model";
import { QuestionBase } from "../dynamic-form-component/question-base";
import { DatetimeQuestion } from "../dynamic-form-component/question-types/question-datepicker";
import { DropdownQuestion } from "../dynamic-form-component/question-types/question-dropdown";
import { TextboxQuestion } from "../dynamic-form-component/question-types/question-textbox";
import { IdentityQuestion } from "../dynamic-form-component/question-types/question-identity";
import { LanguageContentsQuestion }from "../dynamic-form-component/question-types/question-language-contents";
import { ExternalReference } from "./external-reference";
import { GranularMarking } from "./granular-marking";
import { Extension } from "./extension";
import { StringArrayQuestion } from "../dynamic-form-component/question-types/question-string-array";
import { BundleObjectsQuestion } from "../dynamic-form-component/question-types/question-bundle-obj";
import { OpenVocabArrayQuestion } from "../dynamic-form-component/question-types/question-ov-array";
import { StixService } from "../stix-service.service";
import { Content } from "./content";
import { Window } from "./window";

export class LanguageContent extends FormModel {
    type?: string;
    id?: string;
    spec_version?: string;
    created?: string;
    modified?: string;
    created_by_ref?: string;
    revoked?: boolean;
    labels?: string[];
    confidence?: number;
    external_references?: ExternalReference[];
    object_marking_refs?: string[];
    granular_markings?: GranularMarking[];
    extensions?: Extension[];
    object_modified?: string;
    object_ref?: string;
    contents?: Content[];
    loaded?: boolean = false;

    constructor(
        public stixService: StixService,
        type?: string | '',
        id?: string | '',
        spec_version?: string | '',
        created?: string | '',
        modified?: string | '',
        created_by_ref?: string | '',
        revoked?: boolean,
        labels?: string[] | [],
        confidence?: number,
        external_references?: ExternalReference[] | [],
        object_marking_refs?: string[] | [],
        granular_markings?: GranularMarking[] | [],
        extensions?: Extension[] | [],
        object_modified?: string | '',
        contents?: Content[] | [],
        object_ref?: string | '',
    ) {
        super();
        this.type = type;
        this.id = id;
        this.spec_version = spec_version;
        this.created = created;
        this.modified = modified;
        this.created_by_ref = created_by_ref;
        this.revoked = revoked;
        this.labels = labels;
        this.confidence = confidence;
        this.external_references = external_references;
        this.object_marking_refs = object_marking_refs;
        this.granular_markings = granular_markings;
        this.extensions = extensions;
        this.object_modified = object_modified;
        this.contents = contents;
        this.object_ref = object_ref;
    }

    private getIdentities(): any {
        return this.getObjectRefs().filter(id => id.key.includes('identity--'));
    }

    private getObjectRefs(): any[] {
        const ids = [];

        if (this.stixService && this.stixService.bundle && this.stixService.bundle.objects) {
            for (var i = 0; i < this.stixService.bundle.objects.length; i++)
                if (ids.indexOf(this.stixService.bundle.objects[i].id) == -1)
                    ids.push({
                        key: this.stixService.bundle.objects[i].id,
                        value: this.stixService.bundle.objects[i].id
                    });
        }

        return ids;
    }

    getExternalReferences(): ExternalReference[] {
        return [];
    }

    getWindows(): Window[] {
        return [];
    }

    getGranularMarkings(): GranularMarking[] {
        return this.granular_markings || [];
    }
    getExtensions(): Extension[] {
        return this.extensions || [];
    }

    getContents(): Content[] {
        return this.contents || [];
    }

    getQuestions(): QuestionBase<any>[] {
        let questions: QuestionBase<any>[] = [
            new TextboxQuestion({
                key: 'type',
                label: 'Type',
                value: 'language-content',
                required: true,
                order: 1,
                type: 'text',
                readonly: true,
                columnWidth: 'col-2 type'
            }),
            new TextboxQuestion({
                key: 'id',
                label: 'ID',
                value: `language-content--${uuid()}`,
                validatorFn: (componentData: any) => {
                    const id = componentData.id;
                    const idRegex = new RegExp('^language-content--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$')
                    if (!idRegex.test(id))
                        return {
                            valid: false,
                            errorMessage: "Must begin with 'language-content--' and followed by a UUID (i.e. opinion--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                        };
                    return {
                        valid: true,
                    };
                },
                required: true,
                order: 2,
                type: 'text',
                readonly: true,
                columnWidth: 'col-5 id'
            }),
            new TextboxQuestion({
                key: 'spec_version',
                label: 'Spec Ver.',
                value: '2.1',
                readonly: true,
                columnWidth: 'col-1 spec-version',
                required: true,
                order: 3
            }),
            new DatetimeQuestion({
                key: 'created',
                label: 'Created*',
                columnWidth: 'col-2 created',
                required: true,
                order: 4
            }),
            new DatetimeQuestion({
                key: 'modified',
                label: 'Modified*',
                validatorFn: (componentData: any) => {
                    // Check whether Modified Date is equal or after to Created Date                    
                    var createdDateTime = Date.parse(componentData['created']);
                    var modifiedDateTime = Date.parse(componentData['modified']);

                    if (modifiedDateTime && modifiedDateTime < createdDateTime
                        && !this.stixService.newObject) {
                        return {
                            valid: false,
                            errorMessage: "Modified date must be after created date."
                        };
                    }

                    return {
                        valid: true
                    };

                },
                columnWidth: 'col-2 modified',
                required: true,
                order: 5
            }),
            new TextboxQuestion({
                key: 'confidence',
                label: 'Confidence',
                validatorFn: (componentData: any) => {
                    const confidence = componentData.confidence;
                    const confidenceRegex = new RegExp('^(?:100|[1-9]?[0-9])$')
                    if (confidence) {
                        if (!confidenceRegex.test(confidence))
                            return {
                                valid: false,
                                errorMessage: "Confidence value must be an integer in the range of 0-100"
                            };
                    };
                    return {
                        valid: true,
                    };
                },
                columnWidth: 'col-2 confidence-2',
                order: 7,
                required: false,
                type: 'number',
            }),
            new DropdownQuestion({
                key: 'revoked',
                label: 'Revoked',
                options: [
                    { key: 'true', value: 'True' },
                    { key: 'false', value: 'False' },
                ],
                columnWidth: 'col-6',
                order: 88
            }),
            new LanguageContentsQuestion({
                key: 'contents',
                label: 'Contents',
                order: 9,
                columnWidth: 'col-12',
                placeholder: '--> Use button on the right to create Contents -->',
            }),
            /*new DatetimeQuestion({
                key: 'object_modified',
                label: 'Object Modified',
                columnWidth: 'col-6',
                //required: false,
                required: true,
                order: 7
            }),*/
            new TextboxQuestion({
                key: 'object_modified',
                label: 'Object Modified',
                columnWidth: 'col-4 language-2',
                required: false,
                readonly: true,
                order: 6
            }),
            new IdentityQuestion({
                key: 'created_by_ref',
                label: 'Created By Ref',
                validatorFn: (componentData: any) => {
                    const created_by_ref = componentData.created_by_ref;
                    const created_by_refRegex = new RegExp('identity--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}')
                    if (created_by_ref != '') {
                        if (!created_by_refRegex.test(created_by_ref))
                            return {
                                valid: false,
                                errorMessage: "Must begin with 'identity--' and followed by a UUID (i.e. identity--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                            };
                    }
                    return {
                        valid: true,
                    };
                },
                relString: this.created_by_ref,
                order: 7,
                required: false,
                columnWidth: 'col-6 created-by-ref-2',
            }),
            // new StringArrayQuestion({
            //     key: 'labels',
            //     label: 'Labels',
            //     value: new Array(),
            //     columnWidth: 'col-12',
            //     order: 10
            // }),
            new BundleObjectsQuestion({
                key: 'object_ref',
                label: 'Object Reference*',
                validatorFn: (componentData: any) => {
                    questions.find((i) => i.key == "object_ref").relString = componentData.object_ref;
                    const objectReferences = componentData.object_ref;
                    const objectReferencesRegex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
                    if (objectReferences && objectReferences != '' && !objectReferencesRegex.test(objectReferences))
                        return {
                            valid: false,
                            errorMessage: "Required and must match id format [object-type]--[UUID]"
                        };
                    return {
                        valid: true
                    };
                },
                required: true,
                columnWidth: 'col-12',
                order: 8,
                type: 'text',
            }),
        ];

        return (questions.sort((a, b) => a.order - b.order));
    }

    hasX509V3Extensions(): boolean {
        return false;
    }

    hasWindows(): boolean {
        return false;
    }
    hasExternalReferences(): boolean {
        return true;
    }

    hasGranularMarkings(): boolean {
        return true;
    }

    hasExtensions(): boolean {
        return true;
    }

    hasObjectMarkingReferences(): boolean {
        return true;
    }

    hasContents(): boolean {
        return true;
    }

    populateFromJSON(componentData: any, stixService: StixService): void {
        this.type = componentData.type;
        this.id = componentData.id;
        this.created_by_ref = componentData.created_by_ref;
        this.spec_version = componentData.spec_version;
        this.confidence = parseInt(componentData.confidence) || undefined;
        this.created = componentData.created;
        this.modified = componentData.modified;
        this.revoked = JSON.parse(componentData.revoked[0] || '""');
        this.external_references = componentData.external_references;
        this.labels = stixService.stringArrays.get("labels") || [];
        this.object_marking_refs = componentData.object_marking_refs;
        this.granular_markings = componentData.granular_markings;
        this.extensions = componentData.extensions;
        this.object_ref = componentData.object_ref || '""';
        this.object_modified = componentData.object_modified;
        // 'contents' are set in add-component cleanObject with stixService modalObjectArray
    }

    setExternalReferences(newExternalReferences: ExternalReference[]): void {
        this.external_references = newExternalReferences;
    }

    setGranularMarkings(newGranularMarkings: GranularMarking[]): void {
        this.granular_markings = newGranularMarkings;
    }

    setExtensions(newExtensions: Extension[]): void {
        this.extensions = newExtensions;
    }

    setObjectMarkingRefs(newObjectMarkingRefs: string[]): void {
        this.object_marking_refs = newObjectMarkingRefs;
    }

    setWindows(newWindows: Window[]): void {
        // N/a
    }

    setContents(newContents: Content[]): void {
        this.contents = newContents;
    }
}
