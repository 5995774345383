import { Component, OnInit, EventEmitter, Output, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { IncidentCoreObject } from "./types/incident-core-object";
import { StixService } from "../stix-service.service";
import { LANGUAGES } from "../models/languages";
import { ENTITY_TYPE_OV } from './Entity_Type_OV';
import { EVENT_TYPE_OV } from "./Event_Type_OV";
import { TASK_TYPE_OV } from "./Task_Type_OV";
import {
  faTrash,
  faEdit,
  faBan,
  faPlus,
  faAngleDoubleUp,
  faInfoCircle,
  faAngleDoubleDown,
  faFileImport,
  faExclamationCircle,
  faStickyNote
} from "@fortawesome/free-solid-svg-icons";
import { AttackerActivityDialogComponent } from "./attacker-activity/attacker-activity-dialog.component";
import { ObjectEntryDialogComponent } from "./object-entry/object-entry-dialog.component";
import { ChangedObjectDialogComponent } from './changed-object/changed-object-dialog.component';
import { MatDialog } from "@angular/material/dialog";
import { v4 as uuid } from "uuid";
import { Router } from '@angular/router';
import { ObjectEntry } from './object-entry/object-entry';
import { AddComponentComponent } from '../add-component/add-component.component';
import { environment } from 'src/environments/environment';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import all_schemas from '../bundle/schemas.json';
import { ImpactExtensionsDialogComponent } from './impact-extensions/impact-extensions-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NoteDialogComponent } from '../add-component/note-dialog-component/note-dialog-component.component';

@Component({
  selector: "incident-core-object",
  templateUrl: "./incident-core-object.component.html",
  styleUrls: ["./incident-core-object.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class IncidentCoreObjectComponent implements OnInit {
  @Output() componentData = new EventEmitter<{}>();
  @ViewChild('nameRow') nameRow: ElementRef;
  @ViewChild('confidenceRow') confidenceRow: ElementRef;
  @ViewChild('languageRow') languageRow: ElementRef;
  @ViewChild('createdByRefRow') createdByRefRow: ElementRef;
  @ViewChild('goalRow') goalRow: ElementRef;
  @ViewChild('statusRow') statusRow: ElementRef;
  @ViewChild('descriptionRow') descriptionRow: ElementRef;
  @ViewChild('eventTypesRow') eventTypesRow: ElementRef;
  @ViewChild('sightingRefsRow') sightingRefsRow: ElementRef;
  @ViewChild('changedObjectsRow') changedObjectsRow: ElementRef;
  @ViewChild('nextEventRefsRow') nextEventRefsRow: ElementRef;
  @ViewChild('startTimeRow') startTimeRow: ElementRef;
  @ViewChild('endTimeRow') endTimeRow: ElementRef;
  @ViewChild('impactCategoryRow') impactCategoryRow: ElementRef;
  @ViewChild('recoverabilityRow') recoverabilityRow: ElementRef;
  @ViewChild('criticalityRow') criticalityRow: ElementRef;
  @ViewChild('impactedEntityCountsRow') impactedEntityCountsRow: ElementRef;
  @ViewChild('impactedRefsRow') impactedRefsRow: ElementRef;
  @ViewChild('supersededRow') supersededRow: ElementRef;
  @ViewChild('outcomeRow') outcomeRow: ElementRef;
  @ViewChild('priorityRow') priorityRow: ElementRef;
  @ViewChild('taskTypesRow') taskTypesRow: ElementRef;
  @ViewChild('errorRow') errorRow: ElementRef;
  @ViewChild('nextTaskRefsRow') nextTaskRefsRow: ElementRef;

  currentIncidentCoreObject = {};
  editedIncidentCoreObject
  isAddingIncidentCoreObject: boolean = false;
  newIncidentCoreObject: IncidentCoreObject[] = [];

  determination: string;
  extension_type: string = "zzz";
  investigation_status: string;
  criticality: number;
  recoverability: string;
  traceability_impact: string;
  attacker_activites: any[];
  availability_impacts: any[];
  confidentiality_impacts: any[];
  defender_activites: any[];
  detection_methods: any[];
  external_impacts: any[];
  impacted_entity_counts: any = new Map();
  incident_types: any[];
  integrity_impacts: any[];
  monetary_impacts: any[];
  physical_impacts: any[];
  scores: any[] = [];
  events: ObjectEntry[] = [];
  tasks: ObjectEntry[] = [];

  isEnabled: boolean = true;
  toplevelEnabled: boolean = true;

  type: string = 'event';

  previousImpactCat: string = ''; 
  incidentTypes: string;
  eventTypes: string;
  taskTypes: string;
  impactedRefs: string;
  sightingRefs: string;
  entity_count_string: string = "";
  entity_count_count: string = "";
  stateChangeType: string = "";
  initialRef: string = "";
  resultRef: string = "";
  scores_name: string = "";
  scores_value: string = "";
  scores_description: string = "";
  detectionMethods: string;
  newSelector: any = undefined;
  lang: any;
  marking_ref: any;
  errorMessage: string = "";
  errors = {};

  isFormEditing = true;
  faEdit = faEdit;
  faTrash = faTrash;
  faBan = faBan;
  faPlus = faPlus;
  faAngleDoubleDown = faAngleDoubleDown;
  faAngleDoubleUp = faAngleDoubleUp;
  faInfoCircle = faInfoCircle;
  faFileImport = faFileImport;
  faExclamation = faExclamationCircle;
  faNote = faStickyNote;

  tlp_options = [];
  lang_options: any[] = LANGUAGES;
  selectors: any[];
  impactObjects: any[] = this.stixService.bundle.objects.filter(o => o.id.includes('impact'));
  filteredImpactObjects = this.impactObjects;
  sightingObjects: any[] = this.stixService.bundle.objects.filter(o => o.id.includes('sighting'));
  filteredSightingObjects = this.sightingObjects;

  changedObjectsRefIds: any[] = this.stixService.bundle.objects.filter(o => all_schemas["SDOs_SROs"].includes(o.type)).map(o => o.id);
  state_change_type = "";
  initialRefs = '';
  resultRefs = '';
  filteredInitialRefIds = this.changedObjectsRefIds;
  filteredResultRefIds = this.changedObjectsRefIds;

  nextEventRefs: string = '';
  eventRefIds = this.stixService.bundle.objects.filter(o => o.id.includes('event--'));
  filteredEventRefIds = this.eventRefIds;

  nextTaskRefs: string = '';
  taskRefIds = this.stixService.bundle.objects.filter(o => o.id.includes('task--'));
  filteredTaskRefIds = this.taskRefIds;

  impactedRefIds = this.stixService.bundle.objects;
  filteredImpactedRefIds = this.impactedRefIds;

  ids: any[] = [{ id: environment.cisaIdentity.id, name: environment.cisaIdentity.name }];

  initialForm;

  incidentDeterminationEnum = [
    "blocked",
    "confirmed",
    "failed-attempt",
    "false-positive",
    "suspected",
  ];
  eventStatusEnum = [
    "ongoing",
    "occurred",
    "not-occurred",
    "pending",
    "undetermined",
  ]
  taskOutcomeEnum = [
    "cancelled",
    "failed",
    "ongoing",
    "pending",
    "successful",
    "unknown",
  ]
  timestampFidelityEnum = [
    "day",
    "hour",
    "minute",
    "month",
    "second",
    "year",
  ]
  stateChangeTypeOV = [
    "caused",
    "contributed-to",
    "input",
    "mitigated",
    "output",
    "resolved",
  ]
  incidentInvestigationOV = ["closed", "new", "open"];
  recoverabilityEnum = [
    "extended",
    "not-applicable",
    "not-recoverable",
    "regular",
    "supplemented",
  ];
  incidentDetectionMethodsEnum = [
    "automated-tool",
    "commercial-solution",
    "external-notification",
    "human-review",
    "message-from-attacker",
    "propriety-solution",
    "system-outage",
    "user-reporting",
  ]
  incidentEventTypeOV = EVENT_TYPE_OV;
  filteredIncidentEventType = this.incidentEventTypeOV;
  incidentEntityTypeOV = ENTITY_TYPE_OV;
  filteredIncidentEntityType = this.incidentEntityTypeOV;
  incidentTaskTypeOV = TASK_TYPE_OV;
  filteredIncidentTaskType = this.incidentTaskTypeOV;
  traceabilityEnum = [
    "accountability-lost",
    "partial-accountability",
    "provable-accountability",
  ];
  impactCategoriesVocab = [
    'availability',
    // 'availability-ext',
    'confidentiality',
    // 'confidentiality-ext',
    'external',
    // 'external-ext',
    'integrity',
    // 'integrity-ext',
    'monetary',
    // 'monetary-ext',
    'physical',
    // 'physical-ext',
    'traceability',
    // 'traceability-ext'
  ]
  filteredImpactCategoriesVocab = this.impactCategoriesVocab;
  criticalityRegex = new RegExp("^(?:100|[1-9]?[0-9])$");

  eventProperties = [
    {name: 'Name', row: 'nameRow'},
    {name: 'Confidence', row: "confidenceRow"},
    {name: 'Language', row: 'languageRow'},
    {name: 'Created by ref', row: 'createdByRefRow'},
    {name: 'Goal', row: 'goalRow'},
    {name: "Status*", row: 'statusRow'},
    {name: 'Event types', row: 'eventTypesRow'},
    {name: 'Sighting refs', row: 'sightingRefsRow'},
    {name: 'Changed objects', row: 'changedObjectsRow'},
    {name: 'Next event refs', row: 'nextEventRefsRow'},
    {name: 'Description', row: 'descriptionRow'},
    {name: 'Start time', row: 'startTimeRow'},
    {name: 'Start time fidelity', row: 'startTimeRow'},
    {name: 'End time', row: "endTimeRow"},
    {name: 'End time fidelity', row: 'endTimeRow'}
  ];

  impactProperties = [
    {name: 'Confidence', row: "confidenceRow"},
    {name: 'Language', row: 'languageRow'},
    {name: 'Created by ref', row: 'createdByRefRow'},
    {name: 'Recoverability', row: 'recoverabilityRow'},
    {name: 'Impact Category*', row: 'impactCategoryRow'},
    {name: 'Criticality', row: 'criticalityRow'},
    {name: 'Impacted Entity Counts', row: 'impactedEntityCountsRow'},
    {name: 'Impacted Refs', row: 'impactedRefsRow'},
    {name: 'Description', row: 'descriptionRow'},
    {name: 'Start time', row: 'startTimeRow'},
    {name: 'Start time fidelity', row: 'startTimeRow'},
    {name: 'End time', row: "endTimeRow"},
    {name: 'End time fidelity', row: 'endTimeRow'},
    {name: 'Superseded By Ref', row: 'supersededRow'},
  ];

  taskProperties = [
    {name: 'Name', row: "nameRow"},
    {name: 'Confidence', row: "confidenceRow"},
    {name: 'Language', row: "languageRow"},
    {name: 'Created By Ref', row: "createdByRefRow"},
    {name: 'Outcome*', row: "outcomeRow"},
    {name: 'Priority', row: "priorityRow"},
    {name: 'Task Types', row: "taskTypesRow"},
    {name: 'Error', row: "errorRow"},
    {name: 'Changed objects', row: 'changedObjectsRow'},
    {name: 'Impacted Entity Counts', row: "impactedEntityCountsRow"},
    {name: 'Next Task Refs', row: "nextTaskRefsRow"},
    {name: 'Description', row: "descriptionRow"},
    {name: 'Start time', row: 'startTimeRow'},
    {name: 'Start time fidelity', row: 'startTimeRow'},
    {name: 'End time', row: "endTimeRow"},
    {name: 'End time fidelity', row: 'endTimeRow'}
  ];

  objProps = {
    event: ["name","goal","status","event_types","sighting_refs","changed_objects","next_event_refs"],
    impact: ["recoverability","impact_category","criticality","impacted_refs","superseded_by_ref"],
    task: ["name","outcome","priority","task_types","error","changed_objects","impacted_entity_counts","next_task_Refs"],
  }

  deletable_notes: Set<string> = new Set();
  notes;
  new_notes;

  constructor(public stixService: StixService, private router: Router, public matDialog: MatDialog, public matAutocomplete: MatAutocompleteModule, private modalService: NgbModal) {}

  ngOnInit() {
    
    this.stixService.objectMarkingReferences = [];
    this.stixService.stringArrays.set('labels', []);

    if (!["event","impact","task"].includes(this.stixService.currentType)) {
      this.stixService.currentType = "event";
    }
    this.type = this.stixService.currentType;
    this.currentIncidentCoreObject['type'] = this.type;
    this.currentIncidentCoreObject['id'] = `${this.type}--${uuid()}`
    this.currentIncidentCoreObject['spec_version'] = "2.1"
    let date = (new Date()).toISOString();
    this.currentIncidentCoreObject['created'] = date;
    
    this.stixService.notes = new Map();
    this.stixService.new_notes = new Map();

    const url = window.location.href;
    if (url.includes('revocation=true')) {
      this.stixService.revocation = true;
    }

    let obj = localStorage.getItem('item-to-edit') || '';
      this.stixService.externalReferences = [];
      this.stixService.granularMarkings = [];
      this.stixService.extensions = [];
      this.stixService.toplevel = [];
      this.stixService.objectMarkingReferences = [];
      this.stixService.contents = [];
      this.stixService.stringArrays = new Map<string, string[]>();
      this.stixService.modalObjectArray = [];
    localStorage.removeItem('item-to-edit');
    if (obj != '') {
      let currObject = JSON.parse(obj);
      console.log(currObject);

      this.editedIncidentCoreObject = currObject;
      this.currentIncidentCoreObject = currObject;

      if (currObject.object_marking_refs) {
        this.stixService.objectMarkingReferences = currObject.object_marking_refs;
        delete this.currentIncidentCoreObject["object_marking_refs"];
      }

      if (currObject.labels) {
        this.stixService.stringArrays.set('labels', currObject.labels);
        delete this.currentIncidentCoreObject["labels"];
      }

      if (currObject.external_references) {
        this.stixService.externalReferences = currObject.external_references;
        delete this.currentIncidentCoreObject["external_references"];
      }

      if (this.currentIncidentCoreObject["impacted_entity_counts"]) {
        //this.impacted_entity_counts = new Map(this.currentIncidentCoreObject["impacted_entity_counts"]); - not sure why this breaks
        for (let key in this.currentIncidentCoreObject["impacted_entity_counts"]) {
          this.impacted_entity_counts.set(key, parseInt(this.currentIncidentCoreObject["impacted_entity_counts"][key]));
        }
      }
      delete this.currentIncidentCoreObject["impacted_entity_counts"];

      if (this.currentIncidentCoreObject["start_time"]) {
        this.currentIncidentCoreObject["start_time"] = this.currentIncidentCoreObject["start_time"];
      }

      if (this.currentIncidentCoreObject["end_time"]) {
        this.currentIncidentCoreObject["end_time"] = this.currentIncidentCoreObject["end_time"];
      }

      if (this.currentIncidentCoreObject['extensions']){
        let arr = [];
        for (let ext in this.currentIncidentCoreObject['extensions']) {
          console.log(ext.substring(22));
          if (!ext.includes("4ca6de00-5b0d-45ef-a1dc-ea7279ea910e") && 
            ext != "extension-definition--7cc33dd6-f6a1-489b-98ea-522d351d71b9" &&
            ext.substring(22) != "2074a052-8be4-4932-849e-f5e7798e0030") {
              let temp = {}
              temp[ext] = this.currentIncidentCoreObject['extensions'][ext];
              this.stixService.extensions.push(temp);
            }
        }
        delete this.currentIncidentCoreObject["extensions"];
      }

      for (let prop in this.currentIncidentCoreObject) {
        if (this.isToplevel(prop)) {
          this.stixService.toplevel.push([prop,this.currentIncidentCoreObject[prop]]);
          delete this.currentIncidentCoreObject[prop];
        }
      }
        // Check that the extension definition is correct - actually unnecessary/we can just enforce it
        // It WILL allow for multiple new-sdo properties though
      /* else {
        console.log('ERROR: Custom Objects MUST have an extension type');
        this.router.navigate(['/bundle']);
        return;
      } */
    }

    this.stixService.bundle.objects.forEach(elem => {
      if (elem.type == "note" && elem.object_refs && elem.object_refs.includes(this.currentIncidentCoreObject["id"]))
        this.stixService.notes.set(elem.id, JSON.parse(JSON.stringify(elem)));
    });

    this.notes = JSON.stringify(Array.from(this.stixService.notes));
    this.new_notes = JSON.stringify(Array.from(this.stixService.new_notes));
    
    this.currentIncidentCoreObject['created'] = date;
    this.currentIncidentCoreObject['modified'] = date;

    this.initialForm =  {
      iceObject: JSON.stringify(this.currentIncidentCoreObject),
      objMarkRef: JSON.stringify(this.stixService.objectMarkingReferences),
      granularMarking: JSON.stringify(this.stixService.granularMarkings),
      externalRef: JSON.stringify(this.stixService.externalReferences),
      labels: JSON.stringify(this.stixService.stringArrays.get('labels')),
      extensions: JSON.stringify(this.stixService.extensions)
    }
  }

  addGranularMarking(): void {
    const component: any = this.currentIncidentCoreObject;
    for (const x in component) {
      if (component[x].length == 0) {
        delete component[x];
      }
    }
    this.stixService.granularMarkings.push(component);
    this.isAddingIncidentCoreObject = false;
    this.stixService.editedGranularMarking = undefined;
    this.newSelector = [];
    this.errorMessage = "";

    this.currentIncidentCoreObject = {};

    // if (this.addButton().valid){
    //   let component: any = this.currentGranularMarking;
    //   for(var x in component){
    //     if (component[x].length == 0){
    //       delete component[x];
    //     }
    //   }
    //   this.stixService.granularMarkings.push(component);
    //   this.isAddingGranularMarkings = false;
    //   this.stixService.editedGranularMarking = undefined;
    //   this.newSelector = [];
    //   this.errorMessage = '';
    // }

    // else {
    //   this.errorMessage = this.addButton().errorMessage;
    // }
  }

  addOrCancel(): void {
    if (
      this.isAddingIncidentCoreObject &&
      this.stixService.editedGranularMarking
    ) {
      this.stixService.granularMarkings.push(
        this.stixService.editedGranularMarking
      );
      this.stixService.editedGranularMarking = undefined;
    }
    this.isAddingIncidentCoreObject = !this.isAddingIncidentCoreObject;
    this.newSelector = [];
    this.errorMessage = "";
    this.currentIncidentCoreObject = {};
  }

  editIncidentCoreObject(myobj: any) {
    this.stixService.editedGranularMarking = myobj;
    this.currentIncidentCoreObject = myobj;
    this.stixService.granularMarkings =
      this.stixService.granularMarkings.filter((obj) => obj !== myobj);
  }

  deleteIncidentCoreObject(myobj: any) {
    this.stixService.granularMarkings =
      this.stixService.granularMarkings.filter((obj) => obj !== myobj);
  }

  addButton(): any {
    const md_Regex = new RegExp(
      "marking-definition--[0-9a-f]{8}-[0-9a-f]{4}-[45][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}"
    );

    this.currentIncidentCoreObject = {};

    return {
      valid: true,
    };
  }

  addString(key): void {
    if (key == "sighting_refs") {
      const regex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
      if (!regex.test(this.sightingRefs) || !this.sightingRefs.startsWith("sighting--")) {
        this.errors["sighting_refs"] = "Must be a valid ID and of type sighting"
        return;
      }
      delete this.errors["sighting_refs"];
      const regex2 = new RegExp(/^(attack-pattern|indicator|malware)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
      if (!regex2.test(this.sightingRefs)) {
        this.errors["sighting_refs"] = "Sighting *SHOULD* come be of type Attack Pattern/Indicator/Malware";
      }
    }
    else if (key == "impacted_refs") {
      const regex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
      if (!regex.test(this.impactedRefs)) {
        this.errors["impacted_refs"] = "Must be a valid ID"
        return;
      }
      delete this.errors["impacted_refs"];
    }
    let tempKey = key.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
      return (txt.charAt(0).toUpperCase() + txt.substr(1));
    }).replace(/ /g, "");
    tempKey = tempKey.charAt(0).toLowerCase() + tempKey.substr(1);
    let tempString = this[tempKey];
    if (this.currentIncidentCoreObject[key]) {
      let newStringArray = this.currentIncidentCoreObject[key]!;
      if (newStringArray.indexOf(tempString) == -1) {
        newStringArray.push(tempString);
        this.currentIncidentCoreObject[key] = newStringArray;
      }
    } else
      this.currentIncidentCoreObject[key] = [tempString];

    this[tempKey] = '';

    if (key === 'sighting_refs') {
      this.filterRefs('sighting');
    } else if (key === 'next_event_refs') {
      this.filterRefs("nextEventRefs")
    } else if (key === 'impacted_refs') {
      this.filterRefs('impacted_refs')
    } else if (key === 'next_task_refs') {
      this.filterRefs("next_task_refs");
    } else if (key === 'event_types') {
      this.filterRefs(key);
    } else if (key === 'task_types') {
      this.filterRefs(key);
    }
  }

  addObject(key) {
    let obj;

    switch (key) {
      case 'changed_objects':
        obj = {
          state_change_type: this.state_change_type,
          initial_ref: this.initialRefs,
          result_ref: this.resultRefs
        };

        this.initialRefs = '';
        this.resultRefs = '';
        this.state_change_type = '';
        this.filterRefs("initialRefs");
        this.filterRefs("resultRefs");
        this.isChangedObjectValid();
        break;
    }

    if (this.currentIncidentCoreObject[key]) {
      this.currentIncidentCoreObject[key].push(obj);
    } else
      this.currentIncidentCoreObject[key] = [obj];

  }

  deleteString(myobj: any, mykey: any) {
    let curr = this.currentIncidentCoreObject[mykey] || [];
    curr = curr.filter(obj => obj !== myobj);
    if (curr.length > 0) {
      this.currentIncidentCoreObject[mykey] = curr;
    }
    else {
      delete this.currentIncidentCoreObject[mykey];
    }
  }

  addEntityCount(): void {
    if (this.entity_count_string.length < 1) {
      this.errors["impacted_entity_counts"] = "Key is required";
      return;
    }
    if (this.entity_count_count.length < 1) {
      this.errors["impacted_entity_counts"] = "Count is required";
      return;
    }
    if(parseInt(this.entity_count_count) < 0) {
      this.errors["impacted_entity_counts"] = "Count must not be negative";
      return;
    }
    delete this.errors["impacted_entity_counts"];

    this.impacted_entity_counts.set(this.entity_count_string,parseInt(this.entity_count_count));
    this.entity_count_string = "";
    this.entity_count_count = "";
    let temp = Object.fromEntries(this.impacted_entity_counts); // Derek - this is how to convert later
    this.filterRefs('impacted_entity');
  }
  
  deleteEntityCount(key: string): void {
    this.impacted_entity_counts.delete(key);
  }

  addChangedObject(): void {
    let temp = {};
    temp["state_change_type"] = this.stateChangeType;
    this.stateChangeType = "";
    temp["initial_ref"] = this.initialRef;
    this.initialRef = "";
    temp["result_ref"] = this.resultRef;
    this.resultRef = "";
    if (this.currentIncidentCoreObject["changed_objects"]) {
      this.currentIncidentCoreObject["changed_objects"].push(temp);
    }
    else {
      this.currentIncidentCoreObject["changed_objects"] = [temp];
    }
  }

  openObjectEntryDialog(type: string) {
    let dialogRef;
    if (type == "Event" || type == "Task") {
      let refs = [];
      if (type == "Event" && this.currentIncidentCoreObject["next_event_refs"]) {
        refs = this.currentIncidentCoreObject["next_event_refs"].slice();
      } else if (type == "Task" && this.currentIncidentCoreObject["next_task_refs"]) {
        refs = this.currentIncidentCoreObject["next_task_refs"].slice();
      }

      dialogRef = this.matDialog.open(ObjectEntryDialogComponent, {
        data: {"type": type, "refs": refs},
        height: "600px",
        width: `${window.innerWidth / 3 * 2}px`,
      });
    }
    else if (type == "Changed_Object") {
      dialogRef = this.matDialog.open(ChangedObjectDialogComponent, {
        data: {"type": "Event"},
        height: "600px",
        width: `${window.innerWidth / 3 * 2}px`,
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        console.log(type);
        if (type === "Event") {
          //if (this.currentIncidentCoreObject["subevents"]) {
          //  this.currentIncidentCoreObject["subevents"].push(result);
          //}
          //else {
            this.currentIncidentCoreObject["next_event_refs"] = result;
          //}
        }
        else if (type === "Task") {
          //if (this.currentIncidentCoreObject["next_task_refs"]) {
          //  this.currentIncidentCoreObject["next_task_refs"].push(result);
          //}
          //else {
            this.currentIncidentCoreObject["next_task_refs"] = result;
          //}
        }
        else if (type == "Changed_Object") {
          console.log(result);
          if (this.currentIncidentCoreObject["changed_objects"]) {
            this.currentIncidentCoreObject["changed_objects"].push(result);
          }
          else {
            this.currentIncidentCoreObject["changed_objects"] = [result];
          }
        }
      }
    });
  }

  cancel()  {
    this.router.navigate(['/bundle']);
  }

  addIncidentCoreObject(data = null) {
    if (this.editedIncidentCoreObject) {
      this.stixService.removeComponent(this.editedIncidentCoreObject['id']);
    }
    if (this.stixService.editedGranularMarking) {
      this.stixService.granularMarkings.push(this.stixService.editedGranularMarking);
      this.stixService.editedGranularMarking = undefined;
    }
    if (this.stixService.editedExternalReference) {
      this.stixService.externalReferences.push(this.stixService.editedExternalReference);
      this.stixService.editedExternalReference = undefined;
    }
    if (this.stixService.editedExtension) {
      this.stixService.extensions.push(this.stixService.editedExtension);
      this.stixService.editedExtension = undefined;
    }

    if (this.stixService.objectMarkingReferences) {
      this.currentIncidentCoreObject["object_marking_refs"] = this.stixService.objectMarkingReferences;
    }

    if (this.stixService.stringArrays.get("labels")) {
      this.currentIncidentCoreObject["labels"] = this.stixService.stringArrays.get("labels");
    }

    if (this.stixService.granularMarkings.length > 0) {
      this.currentIncidentCoreObject["granular_markings"] = this.stixService.granularMarkings;
    }
    if (this.stixService.externalReferences.length > 0) {
      this.currentIncidentCoreObject["external_references"] = this.stixService.externalReferences;
    }
    //this.currentIncidentCoreObject["extensions"] = JSON.parse(JSON.stringify(this.stixService.extensions).substring(1, JSON.stringify(this.stixService.extensions).length - 1));
    if (this.type == 'event') {
      this.currentIncidentCoreObject['extensions'] = {
        ['extension-definition-—​4ca6de00-5b0d-45ef-a1dc-ea7279ea910e']: {
          extension_type: "new-sdo"
        }
      };
    } else if (this.type == 'impact') {
      let ext_type = this.currentIncidentCoreObject["impact_category"]+'-ext';
      let tempObj = this.stixService.extensions.find(obj => Object.keys(obj)[0] === ext_type)[ext_type];
      this.currentIncidentCoreObject['extensions'] = {
        [ext_type]: tempObj,
        ['extension-definition--7cc33dd6-f6a1-489b-98ea-522d351d71b9']: {
          extension_type: "new-sdo"
        }
      };
    } else if (this.type == 'task') {
      this.currentIncidentCoreObject['extensions'] = {
        ['extension-definition--2074a052-8be4-4932-849e-f5e7798e0030']: {
          extension_type: "new-sdo"
        }
      };
    }

    if (this.stixService.extensions) {
      for (let ext of this.stixService.extensions) {
        for (let title in ext) {
          this.currentIncidentCoreObject["extensions"][title] = ext[title];
        }
        // let str = JSON.stringify(ext);
        // this.currentIncidentCoreObject["extensions"][str.substring(2,60)] = JSON.parse(str.substring(62,str.length - 1));
      }
      this.stixService.extensions = [];
    }

    for (let prop of this.stixService.toplevel) {
      this.currentIncidentCoreObject![prop[0]] = prop[1];
    }

    this.stixService.toplevel = [];

    this.stixService.newObject = false;
    this.stixService.added = true;

    /* let formObj = this.form.getRawValue();
    }*/
    //this.componentData.emit(this.currentIncidentCoreObject);

    this.cleanObj();

    this.stixService.addComponent(JSON.parse(JSON.stringify(this.currentIncidentCoreObject)));

    if (this.stixService.new_notes.size > 0) {
      this.stixService.new_notes.forEach((value, key) => this.stixService.addComponent(Object.assign({}, value)))
    }

    this.router.navigate(['/bundle']);
    // this.stixService.bundle.objects.push(this.currentIncidentCoreObject);
  }

  cleanObj(): void {
    if (this.impacted_entity_counts.size > 0) {
      this.currentIncidentCoreObject["impacted_entity_counts"] = Object.fromEntries(this.impacted_entity_counts);
    }

    if (this.currentIncidentCoreObject["start_time"]) {
      if (typeof this.currentIncidentCoreObject["start_time"] != "string") {
        this.currentIncidentCoreObject["start_time"] = this.currentIncidentCoreObject["start_time"].toISOString();
      }
    }

    if (this.currentIncidentCoreObject["end_time"]) {
      if (typeof this.currentIncidentCoreObject["end_time"] != "string") {
        this.currentIncidentCoreObject["end_time"] = this.currentIncidentCoreObject["end_time"].toISOString();
      }
    }

    if (this.currentIncidentCoreObject["next_event_refs"] && this.currentIncidentCoreObject["next_event_refs"] < 1) {
      delete this.currentIncidentCoreObject["next_event_refs"];
    }

    if (this.currentIncidentCoreObject["next_task_refs"] && this.currentIncidentCoreObject["next_task_refs"] < 1) {
      delete this.currentIncidentCoreObject["next_task_refs"];
    }

    for (let key in this.currentIncidentCoreObject) {
      let prop = this.currentIncidentCoreObject[key];
      if (prop == null || (typeof prop == "string" && prop.length < 1)) {
        delete this.currentIncidentCoreObject[key];
      }
    }

    if (this.currentIncidentCoreObject["object_marking_refs"] && this.currentIncidentCoreObject["object_marking_refs"].length === 0) {
      delete this.currentIncidentCoreObject["object_marking_refs"];
    }

    if (this.currentIncidentCoreObject["labels"] && this.currentIncidentCoreObject["object_marking_refs"].length === 0) {
      delete this.currentIncidentCoreObject["labels"];
    }
  }

  

  /*getGranularMarkLabel(key: string): string {
    for (let i = 0; i < this.stixService.granularMarkingSelectors.length; i++) {
      if (this.stixService.granularMarkingSelectors[i].key == key) {
        return this.stixService.granularMarkingSelectors[i].label;
      }
    }
    return '';
  }*/

  setObjectMarkingReferences(newObjectMarkingReferences: string[]): void {
    this.stixService.objectMarkingReferences = newObjectMarkingReferences;
  }

  setLabels(newlabels: string[]): void {
    this.stixService.stringArrays.set('labels', newlabels);
  }

  isChangedObjectValid(): boolean {
    if (this.initialRefs) {
      const regex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
      let matches = this.initialRefs.match(regex);
      if (!matches || !all_schemas['SDOs_SROs'].includes(this.initialRefs.split('--')[0])) {
        this.errors["initialRefs"] = "Must be a valid ID and an SDO";
        return false;
      }
      else if (this.resultRefs) {
        delete this.errors["initialRefs"];
        let matches2 = this.resultRefs.match(regex);
        if (matches2 && matches[1] != matches2[1]) {
          this.errors["initialRefs"] = "Must be of the same object type as Result Ref";
          return false;
        }
      }
    } else {
      delete this.errors["initialRefs"];
    }
    if (this.resultRefs) {
      const regex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
      if (!regex.test(this.resultRefs) || !all_schemas['SDOs_SROs'].includes(this.resultRefs.split('--')[0])) {
        this.errors["resultRefs"] = "Must be a valid ID and an SDO";
        return false;
      }
      else {
        delete this.errors["resultRefs"];
      }
    }

    delete this.errors["initialRefs"];
    delete this.errors["resultRefs"];

    if ((!this.initialRefs && !this.resultRefs) || !this.state_change_type)
      return false;

    return true;
  }

  isRefsValid(type): boolean {
    let key = type;
    if (type === 'event') {
      key = `nextEvent`;
    } else if (type === 'task') {
      key = 'nextTask';
    }

    if (this[`${key}Refs`].length === 0)
      return false;

    const regex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
    if (!regex.test(this[`${key}Refs`]) || !this[`${key}Refs`].startsWith(`${type}--`)) {
      this.errors[`${key}Refs`] = "Must be a valid ID and type of " + type;
      return false;
    }
    
    delete this.errors[`${key}Refs`];
    return true;
  }

  isFormValid(): boolean {
    const formKeys = Object.keys(this.currentIncidentCoreObject);
    formKeys.forEach(key => {
      if (!this.stixService.granularMarkingSelectors.includes(key)) {
        this.stixService.granularMarkingSelectors.push(key);
      }
    });

    if (
      JSON.stringify(this.currentIncidentCoreObject) == this.initialForm.iceObject &&
      JSON.stringify(this.stixService.stringArrays.get('labels')) == this.initialForm.labels &&
      JSON.stringify(this.stixService.objectMarkingReferences) == this.initialForm.objMarkRef &&
      JSON.stringify(this.stixService.extensions) == this.initialForm.extensions &&
      JSON.stringify(this.stixService.externalReferences) == this.initialForm.externalRef &&
      JSON.stringify(this.stixService.granularMarkings) == this.initialForm.granularMarking &&
      JSON.stringify(Array.from(this.stixService.notes)) == this.notes &&
      JSON.stringify(Array.from(this.stixService.new_notes)) == this.new_notes
    )
      return false;

    if (this.currentIncidentCoreObject['start_time'] && this.currentIncidentCoreObject['end_time']
    && Date.parse(this.currentIncidentCoreObject['end_time']) < Date.parse(this.currentIncidentCoreObject['start_time'])) {
      this.errors["end_time"] = "End time must be after start time";
      return false;
    }
    delete this.errors["end_time"];

    if (this.currentIncidentCoreObject['confidence'] && (this.currentIncidentCoreObject['confidence'] < 0 || this.currentIncidentCoreObject['confidence'] > 100)) {
      this.errors['confidence'] = 'Confidence value must be an integer in the range of 0-100';
      return false;
    }
    delete this.errors['confidence'];

    switch (this.type) {
      case 'event':
        if (!this.currentIncidentCoreObject["status"]) {
          return false;
        }
        break;
      case 'impact':
        let ret = true;
        if (this.currentIncidentCoreObject["criticality"] && 
          (this.currentIncidentCoreObject["criticality"] > 100 || this.currentIncidentCoreObject["criticality"] < 0)) {
          this.errors["criticality"] = "Criticality must be between 0 and 100";
          ret = false;
        } else delete this.errors["criticality"];

        if (this.currentIncidentCoreObject['superseded_by_ref']) {
          const regex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
          if (!regex.test(this.currentIncidentCoreObject['superseded_by_ref']) || !this.currentIncidentCoreObject['superseded_by_ref'].startsWith('impact--')) {
            this.errors["superseded_by_ref"] = "Must be a valid ID and type Impact"
            ret = false;
          } else if (!this.currentIncidentCoreObject['end_time']) {
            this.errors["superseded_by_ref"] = "End time must be populated when this field is present"
            ret = false;
          } else if (!this.currentIncidentCoreObject['impact_category']) {
            this.errors["superseded_by_ref"] = "Impact category must be populated, and of the same category"
            ret = false;
          } else {
            const supersededRef = this.stixService.bundle.objects.find(elem => elem.id === this.currentIncidentCoreObject['superseded_by_ref']);
            if (supersededRef && supersededRef.impact_category && supersededRef.impact_category !== this.currentIncidentCoreObject['impact_category']) {
              this.errors["superseded_by_ref"] = "Impact category of the reference object must be the same category";
              ret = false;
            } else {
              delete this.errors["superseded_by_ref"];
            }
          }
        } else {
          delete this.errors["superseded_by_ref"];
        }
        
        if (!this.currentIncidentCoreObject["impact_category"]) {
          ret = false;
        } else {
          let type = this.currentIncidentCoreObject["impact_category"];
          this.errors["impact_category"] = "Extension of the selected type must be present";
          for (let ext of this.stixService.extensions) {
            if (ext[type+"-ext"] || (type.endsWith('-ext') && ext[type])) {
              delete this.errors["impact_category"];
            }
          }
          if (this.errors["impact_category"]) {
            ret = false;
          }
        }
        return ret;
      case 'task':
        let returnVal = true;
        if (!this.currentIncidentCoreObject["outcome"]) {
          returnVal = false;
        }

        if (this.currentIncidentCoreObject['priority'] && (this.currentIncidentCoreObject['priority'] < 0 || this.currentIncidentCoreObject['priority'] > 100 )) {
          this.errors['priority'] = "Value of priority should be between 0-100";
          returnVal = false;
        } else {
          delete this.errors['priority'];
        }

        return returnVal;
    }

    return true;
  }

  filterRefs(type: string) {
    switch (type) {
      case 'sighting':
        this.filteredSightingObjects = this.sightingObjects.filter(elem => {
          if (elem.id.includes(this.sightingRefs.trim()))
            return true;

          return false;
        });
        break;
      case 'initialRefs':
        this.filteredInitialRefIds = this.changedObjectsRefIds.filter(elem => elem.includes(this.initialRefs.trim()));
        this.isChangedObjectValid();
        break;
      case 'resultRefs':
        this.filteredResultRefIds = this.changedObjectsRefIds.filter(elem => elem.includes(this.resultRefs.trim()));
        this.isChangedObjectValid();
        break;
      case 'nextEventRefs':
        this.filteredEventRefIds = this.eventRefIds.filter(elem => elem.id.includes(this.nextEventRefs.trim()));
        break;
      case 'impact_category':
        this.filteredImpactCategoriesVocab = this.impactCategoriesVocab.filter(elem => elem.includes(this.currentIncidentCoreObject['impact_category'].trim()));
        break;
      case 'impacted_entity':
        this.filteredIncidentEntityType = this.incidentEntityTypeOV.filter(elem => elem.includes(this.entity_count_string.trim()));
        break;
      case 'task_types':
        this.filteredIncidentTaskType = this.incidentTaskTypeOV.filter(elem => elem.includes(this.taskTypes.trim()));
        break;
      case 'event_types':
        this.filteredIncidentEventType = this.incidentEventTypeOV.filter(elem => elem.includes(this.eventTypes.trim()));
        break;
      case 'impacted_refs':
        this.filteredImpactedRefIds = this.impactedRefIds.filter(elem => elem.id.includes(this.impactedRefs.trim()));
        break;
      case 'superseded_by_ref':
        this.filteredImpactObjects = this.impactObjects.filter(elem => elem.id.includes(this.currentIncidentCoreObject['superseded_by_ref'].trim()));
        break;
      case 'next_task_refs':
        this.filteredTaskRefIds = this.taskRefIds.filter(elem => elem.id.includes(this.nextTaskRefs.trim()));
        break;
    }
  }

  scrollTo(rowName: string): void {
    const htmlElement = this[rowName] as ElementRef<HTMLDivElement>;
    const position = htmlElement.nativeElement.getBoundingClientRect().top;

    if (position > 84 && position < window.screen.height - 43)
      return;

    htmlElement.nativeElement.scrollIntoView();
    if (position < 84) {
      window.scrollBy({ top: position - 85 });
    }
  }

  openExtension() {
    if (!this.currentIncidentCoreObject['impact_category'])
      return;

    const dialogRef2 = this.matDialog.open(ImpactExtensionsDialogComponent, {
      data: {"type": this.currentIncidentCoreObject['impact_category'] + '-ext'},
      height: "600px",
      width: `${window.innerWidth / 3 * 2}px`,
    });

    dialogRef2.afterClosed().subscribe((result) => {
      if (!result) {
        this.currentIncidentCoreObject['impact_category'] = this.previousImpactCat;
      } else {
        this.previousImpactCat = this.currentIncidentCoreObject['impact_category'];
        
        this.stixService.extensions = this.stixService.extensions.filter(elem => {
          if (!Object.keys(elem)[0].endsWith('-ext'))
            return true;

          const type = Object.keys(elem)[0].split('-ext')[0];
          console.log(type, this.currentIncidentCoreObject['impact_category'].split('-ext')[0])
          return (type === this.currentIncidentCoreObject['impact_category'].split('-ext')[0]);
        });
      }
    });
  }

  openNoteDialogModal() {
    const modalRef = this.modalService.open(NoteDialogComponent, { size: 'xl', modalDialogClass: "modal-note" });
    modalRef.componentInstance.type = this.type;
    modalRef.componentInstance.notes = this.stixService.notes;
    modalRef.componentInstance.new_notes = this.stixService.new_notes;
    modalRef.componentInstance.deletable_notes = this.deletable_notes;
    modalRef.componentInstance.objectId = this.currentIncidentCoreObject["id"];
  }

  isObject(prop: any): boolean {
    return typeof prop == "object";
  }

  isToplevel(key: string): boolean {
    if (["type","id","spec_version","created","modified","confidence","created_by_ref","lang","description","start_time","start_time_fidelity","end_time","end_time_fidelity"].includes(key)) {
      return false;
    }

    if (this.objProps[this.type].includes(key)) {
      return false;
    }

    return true;
  }
}
