import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StixService } from "../stix-service.service";
import { FormGroup } from '@angular/forms';

import {
  faTrash,
  faEdit,
  faBan,
  faPlus,
  faAngleDoubleUp,
  faInfoCircle,
  faAngleDoubleDown,
} from "@fortawesome/free-solid-svg-icons";
import { ExternalReferencesDialogComponent } from '../external-references/external-references-dialog/external-references-dialog.component';
import { MatDialog } from "@angular/material/dialog";
import { MatAutocompleteModule, MatAutocomplete } from "@angular/material/autocomplete"

@Component({
  selector: "malware-corpus",
  templateUrl: "./malware-corpus.component.html",
  styleUrls: ["./malware-corpus.component.css"],
})
export class MalwareCorpusComponent implements OnInit {
  @Output() isMBEValid = new EventEmitter<boolean>();
  iceForm!: FormGroup;
  currentMalwareCorpus = {};
  editedMalwareCorpus = undefined;
  criticalityError = "";
  otherErrors = {};
  isAddingMalwareCorpus: boolean = false;

  extension_type: string = "property-extension";
  errorMessage: string = "";

  platforms;
  obj_defn;

  faEdit = faEdit;
  faTrash = faTrash;
  faBan = faBan;
  faPlus = faPlus;
  faAngleDoubleDown = faAngleDoubleDown;
  faAngleDoubleUp = faAngleDoubleUp;
  faInfoCircle = faInfoCircle;

  selectors: any[]; 
  componentData: any;

  OSOV = [
    "android",
    "ios",
    "linux",
    "macos",
    "windows",
  ];
  filteredOSOV = this.OSOV;

  constructor(public stixService: StixService, public matDialog: MatDialog, public matAutocomplete: MatAutocompleteModule) {}

  ngOnInit() {
    this.currentMalwareCorpus["isEnabled"] = false;
    if (this.stixService.editedMalwareCorpus) {
      this.currentMalwareCorpus = this.editedMalwareCorpus = this.stixService.editedMalwareCorpus;
      this.stixService.editedMalwareCorpus = undefined;
      this.currentMalwareCorpus["isEnabled"] = true;
    }
    this.currentMalwareCorpus["extension_type"] = "property-extension";

    this.stixService.sendMBEStatus(true);
  }

  isValid() {
    if (this.currentMalwareCorpus["year"] &&
      (this.currentMalwareCorpus["year"] < 0)) {
      this.otherErrors['year'] = "Year must be a positive number";
      this.stixService.sendMBEStatus(false);
    } else {
      this.stixService.sendMBEStatus(this.currentMalwareCorpus['obj_defn'] ? true : false);
      this.otherErrors['year'] = "";
    }
  }

  addGranularMarking(): void {
    const component: any = this.currentMalwareCorpus;
    for (const x in component) {
      if (component[x].length == 0) {
        delete component[x];
      }
    }
    this.stixService.granularMarkings.push(component);
    this.isAddingMalwareCorpus = false;
    this.stixService.editedGranularMarking = undefined;
    this.errorMessage = "";

    this.currentMalwareCorpus = {};

    // if (this.addButton().valid){
    //   let component: any = this.currentGranularMarking;
    //   for(var x in component){
    //     if (component[x].length == 0){
    //       delete component[x];
    //     }
    //   }
    //   this.stixService.granularMarkings.push(component);
    //   this.isAddingGranularMarkings = false;
    //   this.stixService.editedGranularMarking = undefined;
    //   this.newSelector = [];
    //   this.errorMessage = '';
    // }

    // else {
    //   this.errorMessage = this.addButton().errorMessage;
    // }
  }

  addOrCancel(): void {
    if (
      this.isAddingMalwareCorpus &&
      this.stixService.editedGranularMarking
    ) {
      this.stixService.granularMarkings.push(
        this.stixService.editedGranularMarking
      );
      this.stixService.editedGranularMarking = undefined;
    }
    this.isAddingMalwareCorpus = !this.isAddingMalwareCorpus;
    this.errorMessage = "";
    this.currentMalwareCorpus = {};
  }

  addString(key): void {
    let tempString = this[key];

    if (!tempString)
      return;
    
    if (this.currentMalwareCorpus[key]) {
      let newStringArray = this.currentMalwareCorpus[key]!;
      if (newStringArray.indexOf(tempString) == -1) {
        newStringArray.push(tempString);
        this.currentMalwareCorpus[key] = newStringArray;
      }
    } else
      this.currentMalwareCorpus[key] = [tempString];

    this[key] = '';

    if (key === "platforms") {
      this.filterOSOV();
    } 
  }

  deleteString(myobj: any, mykey: any) {
    let curr = this.currentMalwareCorpus[mykey] || [];
    curr = curr.filter(obj => obj !== myobj);
    if (curr.length > 0) {
      this.currentMalwareCorpus[mykey] = curr;
    }
    else {
      delete this.currentMalwareCorpus[mykey];
    }
  }

  openDialog(type: string) {
    let dialogRef;
    if (type == "obj_defn") {
      dialogRef = this.matDialog.open(ExternalReferencesDialogComponent, {
        data: {"malware": true},
        height: "600px",
        width: `${window.innerWidth / 3 * 2}px`,
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (type === "obj_defn") {
          for (let prop in result) {
            if (prop == "hashes") {
              let empty = true;
              for (let p in result[prop]) {
                empty = false;
                break;
              }
              if (empty) {
                delete result[prop];
              }
            }
            else if (result[prop].length == 0) {
              delete result[prop];
            }
          }

          this.currentMalwareCorpus["obj_defn"] = (result);
          this.isValid();
        }
      }
    });
  }

  fixThis(): void {
    this.stixService.currentMalwareCorpus = this.currentMalwareCorpus;
  }

  scrollTo(htmlElem: HTMLElement): void {
    htmlElem.scrollIntoView();
    window.scrollBy({ top: -120 });
  }
  /*getGranularMarkLabel(key: string): string {
    for (let i = 0; i < this.stixService.granularMarkingSelectors.length; i++) {
      if (this.stixService.granularMarkingSelectors[i].key == key) {
        return this.stixService.granularMarkingSelectors[i].label;
      }
    }
    return '';
  }*/

  // ngDoCheck() {
  //   this.isIceValid.emit(this.isValid());
  // }

  // ngAfterViewChecked() {
  //   this.isIceValid.emit(this.isValid());
  // }
  

  getRef(type, id): string {
    let refObject = this[type].find(elem => elem.id === id);
    if (!refObject)  
      return id;

    return "";
  }
  
  filterOSOV() {
    this.filteredOSOV = this.OSOV.filter(elem => elem.includes(this.platforms.trim()));
  }
}
