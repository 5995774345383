<div class="d-flex body guided">
  <div *ngIf="fullScreenMode">
    <app-stix-viewer class="full-width nav-link" [bundle]="getStixPreview()"
      [class]="{'full-screen-guided': fullScreenMode}">
    </app-stix-viewer>
  </div>

  <app-guided-menu *ngIf="!fullScreenMode" #menu class="guided-menu" (menuSelection)="selectMenu($event)">
  </app-guided-menu>

  <app-bundle #bundle style="display: none"></app-bundle>
  <app-string-array #objectMarking style="display: none" [key]="'object_marking_refs'"></app-string-array>
  <app-root #appRoot style="display: none"></app-root>
  <app-add-component *ngIf="addComponentRefreshed" #appAddComponent style="display: none"
    [objectSelectionInput]="objectSelection"></app-add-component>


  <div [class]="{'move-out-of-view': fullScreenMode}" class="main-wrapper">

    <div *ngIf="menuSelection === 'who-individual'" class="w-100">
      <app-who-individual #whoIndividual (updateContinue)="disableContinue($event)" (showAssistance)="showAssistance()"
        (changePage)="redirectCart($event)">
      </app-who-individual>
    </div>
    <div *ngIf="menuSelection === 'how-event-detection'" class="w-100">
      <app-how-event-detection #howEventDetection (updateContinue)="disableContinue($event)"
        (showAssistance)="showAssistance()" (changePage)="redirectCart($event)">
      </app-how-event-detection>
    </div>
    <div *ngIf="menuSelection === 'how-pattern-builder'" class="w-100">
      <app-how-pattern-builder #howPatternBuilder [patternBuilderEvent]="patternBuilderEmitter"
        (showAssistance)="showAssistance()" (changePage)="redirectCart($event)"></app-how-pattern-builder>
    </div>
    <div *ngIf="menuSelection === 'how-observable'" class="w-100">
      <app-how-observable #howObservable (showAssistance)="showAssistance()"
        (changePage)="redirectCart($event)"></app-how-observable>
    </div>
    <div *ngIf="menuSelection === 'how-ttp'" class="w-100">
      <app-how-ttp #howTtp [deciderToolEvent]="deciderToolEmitter" (changePage)="redirectCart($event)"
        (updateContinue)="disableContinue($event)" (disableDecider)="disableDecider($event)"
        (showAssistance)="showAssistance()"></app-how-ttp>
    </div>

    <!-- Not used - for reference only -->
    <!-- <div *ngIf="menuSelection === 'when-sighting'" class="w-100">
      <app-when-sighting #whenSighting
        (showAssistance)="showAssistance()">
      </app-when-sighting>
    </div> -->

    <div *ngIf="menuSelection === 'when-event'" class="w-100">
      <app-when-event #whenEvent (showAssistance)="showAssistance()" (changePage)="redirectCart($event)">
      </app-when-event>
    </div>

    <div *ngIf="menuSelection === 'how-event'" class="w-100">
      <app-how-event #howEvent (showAssistance)="showAssistance()" (changePage)="redirectCart($event)">
      </app-how-event>
    </div>

    <div *ngIf="menuSelection === 'report-assessment'" class="w-100">
      <app-report-assessment #reportAssessment (showAssistance)="showAssistance()" (changePage)="redirectCart($event)">
      </app-report-assessment>
    </div>

    <div *ngIf="menuSelection === 'how-cwe'" class="w-100">
      <app-how-cwe #howCwe (updateContinue)="disableContinue($event)" (showAssistance)="showAssistance()"
        (changePage)="redirectCart($event)">
      </app-how-cwe>
    </div>

    <div *ngIf="menuSelection === 'why-common-behaviors'" class="w-100">
      <app-why-common-behaviors #whyCommonBehaviors (updateContinue)="disableContinue($event)"
        (showAssistance)="showAssistance()" (changePage)="redirectCart($event)">
      </app-why-common-behaviors>
    </div>

    <div *ngIf="menuSelection === 'why-attacker'" class="w-100">
      <app-why-attacker #whyAttacker (updateContinue)="disableContinue($event)" (showAssistance)="showAssistance()"
        (changePage)="redirectCart($event)">
      </app-why-attacker>
    </div>

    <div *ngIf="menuSelection === 'where-location'" class="w-100">
      <app-where-location #whereLocation (updateContinue)="disableContinue($event)" (showAssistance)="showAssistance()"
        (changePage)="redirectCart($event)">
      </app-where-location>
    </div>

    <div *ngIf="menuSelection === 'what-impact'" class="w-100">
      <app-what-impact #whatImpact (updateContinue)="disableContinue($event)" (showAssistance)="showAssistance()"
        (changePage)="redirectCart($event)">
      </app-what-impact>
    </div>

    <!-- TLP Type -->
    <div *ngIf="false && menuSelection === 'tlp-type'"
      class="d-flex justify-content-center align-items-center content-wrapper">
      <div class="d-flex flex-column justify-content-start align-items-center content-wrapper">
        <div class="tlp-build">Let's Build & Publish a STIX Envelope</div>
        <div class="tlp-select">Select a Default TLP</div>
        <div class="mt-2" style="width: 100px">
          <select *ngIf="this.env.tlpConfig.enabledTlpVersions.length > 1" type="text" [(ngModel)]="tlpVersion"
            (change)="getTlpOptions($event)">
            <option *ngFor="let ver of this.env.tlpConfig.enabledTlpVersions" value="{{ver.value}}">{{ver.label}}
            </option>
          </select>
          <!-- <select *ngIf="this.env.tlpConfig.enableTlpOne" type="text" [(ngModel)]="tlpVersion" (change)="getTlpOptions($event)">
            <option value="v1" selected>TLP v1.0</option>
            <option value="v2">TLP v2.0</option>
          </select> -->
        </div>
        <input class="form-control" *ngIf="this.env.tlpConfig.enabledTlpVersions.length == 1" disabled
          value="{{this.env.tlpConfig.enabledTlpVersions[0].label}}" style="width: 49vw">
        <!-- <input class="form-control" *ngIf="!this.env.tlpConfig.enableTlpOne" disabled value="TLP v2.0" style="width: 73vw"> -->
        <div class="d-flex mt-3">
          <div *ngFor="let option of this.objectMarking.tlp_options"
            class="d-flex justify-content-center align-items-center card card-tlp {{option.css}}"
            [ngClass]="{'item-selected': checkSelectedTLP(option.key)}" (click)="selectTLP(option.key)">
            <div>{{option.name}}</div>
          </div>
        </div>
        <!-- <div *ngIf="tlpVersion === 'v1'" class="d-flex mt-3">
          <div class="d-flex justify-content-center align-items-center card card-white"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:WHITE')}" (click)="selectTLP('TLP:WHITE')">
            <div>White TLP</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-green"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:GREEN')}" (click)="selectTLP('TLP:GREEN')">
            <div>Green TLP</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-amber"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:AMBER')}" (click)="selectTLP('TLP:AMBER')">
            <div>Amber TLP</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-red" *ngIf=""
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:RED')}" (click)="selectTLP('TLP:RED')">
            <div>Red TLP</div>
          </div>
        </div>

        <div *ngIf="tlpVersion === 'v2'" class="d-flex mt-3">
          <div class="d-flex justify-content-center align-items-center card card-white card-tlp-v2"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:CLEAR')}" (click)="selectTLP('TLP:CLEAR')">
            <div>Clear TLP</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-green card-tlp-v2"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:GREEN')}" (click)="selectTLP('TLP:GREEN')">
            <div>Green TLP</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-amber card-tlp-v2"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:AMBER')}" (click)="selectTLP('TLP:AMBER')">
            <div>Amber TLP</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-amber card-tlp-v2"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:AMBER+STRICT')}" (click)="selectTLP('TLP:AMBER+STRICT')">
            <div>Amber+Strict TLP</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-red card-tlp-v2"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:RED')}" (click)="selectTLP('TLP:RED')">
            <div>Red TLP</div>
          </div>
        </div> -->
      </div>

    </div>

    <!-- Indicator Type - 1 -->
    <div *ngIf="menuSelection === 'indicator-type-1'"
      class="d-flex justify-content-center align-items-start content-wrapper mt-5">
      <div class="d-flex flex-column justify-content-center align-items-center" style="width: 60%">
        <div class="tlp-build text-center">Threat Intelligence Portal</div>
        <!-- <div class="tlp-select">Please select an option</div> -->
        <div class="d-flex mt-3 justify-content-between" style="min-width: 100%">
          <!-- <div class="d-flex justify-content-center align-items-center card card-blue"
            [ngClass]="{'item-selected': indicatorSelection === 'cyber-event'}"
            (click)="selectIndicatorType('cyber-event')">
            <div>Cyber Event</div>
          </div> -->

          <div class="d-flex flex-column justify-content-center align-items-center object-type-wrapper me-5 card"
            [ngClass]="{'item-selected': indicatorSelection === 'ioc'}"
            (click)="selectIndicatorType('ioc'); goForward()">
            <div class="mb-4" style="font-size: 3rem">
              <fa-icon [icon]="faEdit" alt="" width="30px" height="20" class="d-inline-block align-top"></fa-icon>
            </div>
            <div class="mt-5">
              Create CTI Object<br>&nbsp;
            </div>
          </div>

          <div class="d-flex flex-column justify-content-center align-items-center object-type-wrapper me-5 card"
            [ngClass]="{'item-selected': indicatorSelection === 'gr'}" (click)="selectIndicatorType('gr'); goForward()">
            <div class="mb-4" style="font-size: 3rem">
              <fa-icon [icon]="faChartBar" alt="" width="30px" height="20" class="d-inline-block align-top"></fa-icon>
            </div>
            <div class="mt-5">
              Guided Report<br>&nbsp;
            </div>
          </div>

          <div *ngIf="env.customConfiguration.analyst1.enabled" class="d-flex flex-column justify-content-center align-items-center object-type-wrapper ms-5 card"
            [ngClass]="{'item-selected': indicatorSelection === 'add-evidence'}"
            (click)="selectIndicatorType('add-evidence'); goForward()">
            <div class="mb-5" style="font-size: 3rem">
              <img src="./assets/analyst1-full-logo.png" width="200" height="35" role="button" tabindex="0"
                class="ms-2 center" title="analyst1" alt="analyst1">
            </div>
            <div class="mb-3">
              Upload Evidence <br>OR<br>Add Indicators
            </div>
          </div>

          <!-- <div class="d-flex justify-content-center align-items-center card card-blue"
            [ngClass]="{'item-selected': indicatorSelection === 'ioc'}" (click)="selectIndicatorType('ioc')">
            <div>Create CTI Object</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-blue"
            [ngClass]="{'item-selected': indicatorSelection === 'add-evidence'}"
            (click)="selectIndicatorType('add-evidence')">
            <div>Analyst1</div>
          </div> -->

          <!-- <div class="d-flex justify-content-center align-items-center card card-blue"
            [ngClass]="{'item-selected': indicatorSelection === 'add-ioc'}" (click)="selectIndicatorType('add-ioc')">
            <div>Add Analyst 1 IOC</div>
          </div> -->
        </div>
      </div>
    </div>

    <!-- Indicator Type - 2 -->
    <div *ngIf="menuSelection === 'indicator-type-2'"
      class="d-flex justify-content-center align-items-start content-wrapper" [class]="{'content-wrapper-long': indicatorSelection === 'add-evidence'
                && activeTabAnalyst1EvidenceType === 'Search'}">
      <div *ngIf="indicatorSelection !== 'add-evidence'"
        class="d-flex flex-column justify-content-center align-items-center">
        <!-- <div *ngIf="indicatorSelection === 'cyber-event'" class="tlp-build">Let's Create a Cyber Event</div> -->
        <div *ngIf="indicatorSelection === 'ioc'" class="tlp-build">Let's Create a CTI Object</div>
        <div class="tlp-select">Select or Search for a CTI Type</div>

        <div class="mt-4 input-group d-flex justify-content-end search-bar align-self-end">
          <input class="form-control search-input" (keydown.shift.enter)="search('shift+enter')"
            (keydown.enter)="search('enter')" (keydown.escape)="clearSearch()"
            placeholder="Search for an Indicator Type" [(ngModel)]="searchText" (ngModelChange)="clearSearchResults()">
          <div class="search-control">
            <p class="search-results" *ngIf="searchText !== ''">{{searchResults}}</p>
          </div>
          <button class="search-control clear-button" [disabled]="searchText === ''" (click)="clearSearch()">
            <p class="clear-button-text" *ngIf="searchText !== ''">X</p>
          </button>
        </div>

        <div class="d-flex flex-column mt-2">
          <ul class="nav nav-tabs">
            <li *ngFor="let tab of tabs" class="nav-item" style="cursor: pointer" (click)="activeTab = tab">
              <a class="nav-link" [ngClass]="{'active': activeTab?.title === tab?.title}">
                {{ tab.title }}
              </a>
            </li>
            <li class="nav-item" (click)="activeTab = 'favorite-object'" style="cursor: pointer">
              <a class="nav-link" [ngClass]="{'active': activeTab === 'favorite-object'}">
                <strong>Favorites</strong>
              </a>
            </li>
          </ul>

          <div *ngIf="activeTab !== 'favorite-object'" class="row mt-5 tab-objects-wrapper">
            <div *ngFor="let object of activeTab?.objects" class="col col-md-3 col-lg-3" style="margin-right: 50px">
              <button type="button" class="btn btn-clear btn-100 mt-5"
                style="color: black; background: rgb(233, 236, 239)" (click)="selectObject(object); goForward()"
                [ngClass]="{'btn-clear-blue': objectSelection && object.displayName === objectSelection.displayName,
                            'btn-match': object?.match,
                            'btn-match-selected': object?.type === searchMatches[searchIndex]?.tab?.objects[searchMatches[searchIndex]?.objIndex]?.type}">
                {{ object?.displayName }}
              </button>

              <button *ngIf="stixService.guidedUI && showFavIconType(object) === 'plus'"
                style="position: absolute; top: 51px; left: 222px" type="button"
                class="btn btn-fa-sm btn-outline-danger d-flex justify-content-center ms-0 me-2"
                title="Click to add favorite" (click)="updateFavObj(object)">
                <fa-icon [icon]="faHeart" alt="" width="30px" height="20" class="d-inline-block align-top"></fa-icon>
              </button>
              <button *ngIf="stixService.guidedUI && showFavIconType(object) === 'minus'"
                style="position: absolute; top: 51px; left: 222px" type="button"
                class="btn btn-fa-sm btn-danger d-flex justify-content-center ms-0 me-2"
                title="Click to remove favorite" (click)="updateFavObj(object)">
                <img src="./assets/heart-circle-minus-solid.svg" (click)="updateFavObj(object)" alt="" width="30px"
                  height="20" class="d-inline-block align-top fav-color ms-1">
              </button>
            </div>
          </div>

          <div *ngIf="activeTab === 'favorite-object'" class="row mt-5 tab-objects-wrapper">
            <div *ngFor="let object of getFavoriteObjects()" class="col col-md-3 col-lg-3" style="margin-right: 50px">
              <button type="button" class="btn btn-clear btn-100 mt-5"
                style="color: black; background: rgb(233, 236, 239)" (click)="selectObject(object); goForward()"
                [ngClass]="{'btn-clear-blue': objectSelection && object.displayName === objectSelection.displayName,
                            'btn-match': object?.match,
                            'btn-match-selected': object?.type === searchMatches[searchIndex]?.tab?.objects[searchMatches[searchIndex]?.objIndex]?.type}">
                {{ object?.displayName }}
              </button>

              <button *ngIf="stixService.guidedUI && showFavIconType(object) === 'plus'"
                style="position: absolute; top: 51px; left: 222px" type="button"
                class="btn btn-fa-sm btn-outline-danger d-flex justify-content-center ms-0 me-2"
                title="Click to add favorite" (click)="updateFavObj(object)">
                <fa-icon [icon]="faHeart" alt="" width="30px" height="20" class="d-inline-block align-top"></fa-icon>
              </button>
              <button *ngIf="stixService.guidedUI && showFavIconType(object) === 'minus'"
                style="position: absolute; top: 51px; left: 222px" type="button"
                class="btn btn-fa-sm btn-danger d-flex justify-content-center ms-0 me-2"
                title="Click to remove favorite" (click)="updateFavObj(object)">
                <img src="./assets/heart-circle-minus-solid.svg" (click)="updateFavObj(object)" alt="" width="30px"
                  height="20" class="d-inline-block align-top fav-color ms-1">
              </button>
            </div>
          </div>
        </div>

      </div>

      <div *ngIf="indicatorSelection === 'add-evidence'"
        class="d-flex flex-column justify-content-center align-items-center" style="width: 40vw">
        <div class="tlp-build">Let's Add Indicators from Analyst1</div>
        <!-- <div class="tlp-select">Please upload file OR retrieve with Evidence ID</div> -->
        <div class="tlp-select">{{ analyst1Indicatorheader() }}</div>

        <div class="d-flex flex-column justify-content-left mt-2" style="min-width: 60vw">

          <div class="d-flex flex-column mt-2">
            <ul class="nav nav-tabs">
              <li *ngFor="let tab of evidenceTypeTabs" class="nav-item" style="cursor: pointer"
                (click)="activeTabAnalyst1EvidenceType = tab; setAnalyst1Tab(); analyst1Objects = []">
                <a class="nav-link" [ngClass]="{'active': activeTabAnalyst1EvidenceType === tab}">
                  {{ tab }}
                </a>
              </li>
            </ul>
          </div>

          <div *ngIf="activeTabAnalyst1EvidenceType === 'File'">
            <h6 class="mt-5 d-flex justify-content-center" *ngIf="activeTabAnalyst1EvidenceType === 'File'">
              To extract indicators from a report, please select the file below. <br>This file will be sent to Analyst1
              for indicator extraction and then IMX will <br>automatically ingest those indicators for the
              creation/updating of your STIX bundle. </h6>
          </div>
          <div *ngIf="activeTabAnalyst1EvidenceType === 'File'"
            class="d-flex mt-5 justify-content-around align-items-center profile-upload mt-5">
            <!-- <input #fileInput class="profile-upload-input" type="file" (click)="fileInput.value = null" value=""
              (change)="handleFileInput($event.target)" name="file" id="myfile" /> -->
            <div class="d-flex justify-content-start align-items-start">
              <div class="d-flex flex-column">
                <button type="button" class="btn btn-100 btn-outline-primary pt-1 pb-1 ms-5"
                  (click)="fileInput.click()">
                  <fa-icon [icon]="faSelect" class="pe-1"></fa-icon>
                  Select File
                </button>
                <!-- <span *ngIf="!evidenceUploadClicked">{{ evidenceSubmissions[0]?.file?.name }}</span> -->
              </div>
              <input #fileInput style="display: none" class="profile-upload-input" type="file"
                (click)="fileInput.value = null" value="" (change)="handleFileInput($event.target)" name="file"
                id="myfile" />
            </div>

            <!-- <span *ngIf="!evidenceUploadClicked">{{ file }}</span> -->
            <!-- <span *ngIf="!evidenceUploadClicked">{{ evidenceSubmissions[0]?.file?.name }}</span> -->
            <span (click)="uploadEvidence()" class="me-5">
              <button type="button" class="btn btn-100 btn-outline-primary pt-1 pb-1"
                [disabled]="evidenceSubmissions.length === 0 || evidenceUploadClicked">
                <fa-icon [icon]="faFileUpload" class="pe-1"></fa-icon>
                Upload
              </button>
            </span>
          </div>

          <h6 class="mt-5 d-flex justify-content-center mb-3" *ngIf="activeTabAnalyst1EvidenceType === 'Evidence ID'">
            Entering an evidence ID allows you to automatically ingest all indicators from a report submitted to
            Analyst1. <br>Click on the "Where's My Evidence ID" link in the bottom left to learn more.
          </h6>
          <div *ngIf="activeTabAnalyst1EvidenceType === 'Evidence ID'"
            class="d-flex mt-2 justify-content-center align-items-center">
            <div class="d-flex guided-div-border">
              <p class="pt-2 pe-1 pb-2 mb-0 me-1">Evidence ID</p>
              <div>
                <input type="text" class="form-control guided-input-border" [(ngModel)]="evidenceIdUserInput"
                  style="margin-bottom: 3px" />
              </div>
            </div>
          </div>

          <div
            *ngIf="evidenceSubmissions.length > 0 && evidenceUploadClicked && activeTabAnalyst1EvidenceType === 'File'"
            class="d-flex justify-content-between mt-5 pt-3 pb-3">
            <div>File</div>
            <div>Evidence ID</div>
            <div>Status</div>
          </div>

          <div
            *ngIf="evidenceSubmissions.length > 0 && evidenceUploadClicked && activeTabAnalyst1EvidenceType === 'File'"
            class="d-flex justify-content-between mt-1 pt-3 pb-3"
            style="border-top: 1px solid #dee2e6; border-bottom: 1px solid #dee2e6;">
            <div>{{ showEvidenceFileName() }}</div>
            <div [ngStyle]="{'left': getEvidenceIdPosition()}" style="position: relative">{{ evidenceIdFromFile }}</div>
            <div>{{ getUploadStatus() }}</div>
          </div>

          <div *ngIf="activeTabAnalyst1EvidenceType === 'File' && !evidenceUploadClicked"
            class="d-flex justify-content-center mt-5 pt-3 pb-3">
            <span>{{ evidenceSubmissions[0]?.file?.name }}</span>
          </div>

          <div *ngIf="activeTabAnalyst1EvidenceType === 'Search'"
            class="d-flex flex-column justify-content-start align-items-center">
            <div style="height: 35vh; width: 60vw">
              <!-- <div class="d-flex justify-content-center tlp-build">Select Indicator for Object Details</div> -->

              <div class="modal-body" style="padding-left: 0px; padding-right: 0px; height: 40vh;">
                <div *ngIf="showingAnalyst1" class="w-100">
                  <div class="d-flex justify-content-center flex-column">
                    <div class="row text-center pt-2 pb-4 offset-1">
                      <div class="col-8 input-group search-bar">
                        <input class="form-control search-input" [(ngModel)]="searchTextAnalyst1"
                          placeholder="Input criteria to search indicators on Analyst1."
                          (keydown.enter)="getAnalyst1Objects()">
                        <button class="clear-button" [disabled]="!searchTextAnalyst1" (click)="clearSearchAnalyst1()">
                          <p class="clear-button-text" *ngIf="searchTextAnalyst1">X</p>
                        </button>
                      </div>
                      <div class="col-3" style="padding-left: 4.5%">
                        <button *ngIf="!loadingStix" [disabled]="!searchTextAnalyst1"
                          class="btn btn-primary pe-4 ps-4 pt-2 pb-2 query-button" (click)="getAnalyst1Objects()">
                          <fa-icon [icon]="faSearch" class="pe-1"></fa-icon>
                          Search
                        </button>
                        <!-- <button *ngIf="loadingStix" disabled class="btn btn-primary pe-4 ps-4 pt-2 pb-2 query-button">
                          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                          <span class="sr-only">Loading...</span>
                        </button> -->
                        <button *ngIf="loadingStix" class="btn btn-secondary pe-4 ps-4 pt-2 pb-2 query-button"
                          (click)="cancelAnalyst1Search()">
                          <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div class="pb-1 mb-1" style="border-bottom: 1px solid #dee2e6;"></div>
                    <div *ngIf="analyst1Objects?.length > 0" style="width: 98%; align-self: center;"
                      class="row text-center pb-2">
                      <div class="col-md-12 mt-2 d-flex justify-content-between">
                        <div class="col-md-9 d-flex">
                          <h1 class="h3">Objects</h1>
                        </div>
                        <div class="d-flex justify-content-end align-items-center col-md-3 pe-0">
                          <button *ngIf="showNextAnalyst1Page() && !loadingStixNext" type="button"
                            class="btn btn-sm btn-outline-primary next-button" (click)="getNextAnalyst1Page()"
                            title="More objects are available for this query. Click Next to see them.">
                            <fa-icon [icon]="faArrowRight" class="pe-1"></fa-icon>
                            Next
                          </button>
                          <button *ngIf="loadingStixNext" type="button" disabled
                            class="btn btn-sm btn-outline-primary next-button">
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            <span class="sr-only">Loading...</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div [class]="analyst1Objects?.length === 0 ? 'mb-6' : 'mb-3'"
                  style="display: flex; justify-content: center; align-items: center; width: 100%; height: 70%; overflow: auto;"
                  class="mt-1 analyst1-objects-wrapper">
                  <div *ngIf="analyst1Objects?.length === 0 && !addingStixToPending"
                    class="d-flex justify-content-center align-items-center">
                    <div class="container" style="text-align: center;">
                      <p style="font-weight: bold;">No results</p>
                      <!-- <p>Type search criteria above and click Search to view results</p> -->
                    </div>
                  </div>

                  <div *ngIf="addingStixToPending" class="d-flex justify-content-center align-items-center"
                    style="margin-top: 20vh">
                    Loading&nbsp;<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  </div>

                  <div *ngIf="analyst1Objects?.length > 0" style="width: 100%; height: 100%">
                    <div class="d-flex justify-content-start mt-3">
                      <div style="margin-left: 5%" class="d-flex flex-column">
                        <span style="font-weight: bold;">Check All</span>
                        <input type="checkbox" (change)="checkAllAnalyst1Objects($event)" class="align-self-start" />
                      </div>
                      <div style="margin-left: 7%; width: 60%">
                        <p style="font-weight: bold;">Value</p>
                      </div>
                      <div style="margin-left: 5%">
                        <p style="font-weight: bold;">Type</p>
                      </div>
                    </div>
                    <div *ngFor="let object of analyst1Objects">
                      <div class="d-flex justify-content-start mt-3">
                        <div style="margin-left: 5%">
                          <input type="checkbox" [(ngModel)]="object.checked" />
                        </div>
                        <div style="margin-left: 13%; width: 60%; overflow: auto">
                          <div *ngIf="useGeneralApi || useEvidenceApi">{{ object?.value?.name }}</div>
                          <div *ngIf="useBatchCheckApi">{{ object?.matchedValue }}</div>
                        </div>
                        <div style="margin-left: 5%">
                          <span *ngIf="useGeneralApi || useEvidenceApi">{{ object?.type }}</span>
                          <span *ngIf="useBatchCheckApi">{{ object?.type?.key }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>
    </div>

    <!-- Indicator type - 3 -->
    <div *ngIf="menuSelection === 'indicator-type-3'"
      class="d-flex justify-content-center align-items-start content-wrapper">

      <div class="d-flex flex-column justify-content-center align-items-center">
        <div style="width: 60vw">
          <div class="d-flex justify-content-center tlp-build">Select Indicator for Object Details</div>

          <div class="modal-body analyst1-indicator-body">
            <div *ngIf="showingAnalyst1" class="w-100">
              <div class="d-flex justify-content-center flex-column">
                <div *ngIf="indicatorSelection === 'add-ioc'" class="row text-center pt-2 pb-4 offset-1">
                  <div class="col-8 input-group search-bar">
                    <input class="form-control search-input" [(ngModel)]="searchTextAnalyst1"
                      placeholder="Input criteria to search indicators on Analyst1."
                      (keydown.enter)="getAnalyst1Objects()">
                    <button class="clear-button" [disabled]="!searchTextAnalyst1" (click)="clearSearchAnalyst1()">
                      <p class="clear-button-text" *ngIf="searchTextAnalyst1">X</p>
                    </button>
                  </div>
                  <div class="col-3" style="padding-left: 4.5%">
                    <button *ngIf="!loadingStix" [disabled]="!searchTextAnalyst1"
                      class="btn btn-primary pe-4 ps-4 pt-2 pb-2 query-button" (click)="getAnalyst1Objects()">
                      <fa-icon [icon]="faSearch" class="pe-1"></fa-icon>
                      Search
                    </button>
                    <button *ngIf="loadingStix" disabled class="btn btn-primary pe-4 ps-4 pt-2 pb-2 query-button">
                      <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      <span class="sr-only">Loading...</span>
                    </button>
                  </div>
                </div>
                <div class="pb-1 mb-1" style="border-bottom: 1px solid #dee2e6;"></div>
                <div *ngIf="analyst1Objects?.length > 0" style="width: 98%; align-self: center;"
                  class="row text-center pb-2">
                  <div class="col-md-12 mt-2 d-flex justify-content-between">
                    <div class="col-md-9 d-flex">
                      <h1 class="h3">Objects</h1>
                    </div>
                    <div class="d-flex justify-content-end align-items-center col-md-3 pe-0">
                      <button *ngIf="showNextAnalyst1Page() && !loadingStixNext" type="button"
                        class="btn btn-sm btn-outline-primary next-button" (click)="getNextAnalyst1Page()"
                        title="More objects are available for this query. Click Next to see them.">
                        <fa-icon [icon]="faArrowRight" class="pe-1"></fa-icon>
                        Next
                      </button>
                      <button *ngIf="loadingStixNext" type="button" disabled
                        class="btn btn-sm btn-outline-primary next-button">
                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span class="sr-only">Loading...</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div [class]="analyst1Objects?.length === 0 ? 'mb-6' : 'mb-3'"
              style="display: flex; justify-content: center; align-items: center; width: 100%; height: 70%; overflow: auto;"
              class="mt-1 analyst1-objects-wrapper">
              <div *ngIf="analyst1Objects?.length === 0 && !addingStixToPending"
                class="d-flex justify-content-center align-items-center">
                <div class="container" style="text-align: center;">
                  <p style="font-weight: bold;">No results</p>
                </div>
              </div>

              <div *ngIf="addingStixToPending" class="d-flex justify-content-center align-items-center"
                style="margin-top: 20vh">
                Loading&nbsp;<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              </div>

              <div *ngIf="analyst1Objects?.length > 0" style="width: 100%; height: 100%">
                <div class="d-flex justify-content-start mt-3">
                  <div style="margin-left: 5%" class="d-flex flex-column">
                    <span style="font-weight: bold;">Check All</span>
                    <input type="checkbox" (change)="checkAllAnalyst1Objects($event)" class="align-self-start" />
                  </div>
                  <div style="margin-left: 7%; width: 60%">
                    <p style="font-weight: bold;">Value</p>
                  </div>
                  <div style="margin-left: 5%">
                    <p style="font-weight: bold;">Type</p>
                  </div>
                </div>
                <div *ngFor="let object of analyst1Objects">
                  <div class="d-flex justify-content-start mt-3">
                    <div style="margin-left: 5%">
                      <input type="checkbox" [(ngModel)]="object.checked" />
                    </div>
                    <div style="margin-left: 13%; width: 60%; overflow: auto">
                      <div *ngIf="useGeneralApi || useEvidenceApi">{{ object?.value?.name }}</div>
                      <div *ngIf="useBatchCheckApi">{{ object?.matchedValue }}</div>
                    </div>
                    <div style="margin-left: 5%">
                      <span *ngIf="useGeneralApi || useEvidenceApi">{{ object?.type }}</span>
                      <span *ngIf="useBatchCheckApi">{{ object?.type?.key }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>

    <!-- Additional Info - 1 -->
    <div *ngIf="menuSelection === 'additional-info-1'"
      class="d-flex justify-content-center align-items-start content-wrapper">
      <div *ngIf="indicatorSelection !== 'add-evidence' && indicatorSelection !== 'add-ioc'"
        class="d-flex flex-column justify-content-center align-items-center">
        <div *ngIf="!customObject" class="tlp-build">{{ displayObjectTitle() }}</div>

        <div class="d-flex object-info-wrapper mt-5 w-100">
          <div class="d-flex flex-column">
            <button type="button" (click)="selectObjectPropertyType('required')"
              [class]="{'btn-primary': objectPropertyTypeSelection === 'required' && !isPrimaryPropertiesDisabled, 'btn-disabled': isPrimaryPropertiesDisabled === true, 'btn-clear': !isPrimaryPropertiesDisabled }"
              class="btn-clear btn-100" [disabled]="isPrimaryPropertiesDisabled">
              Primary Properties
            </button>
            <button type="button" (click)="selectObjectPropertyType('common')"
              [class]="{ 'btn-primary': objectPropertyTypeSelection === 'common' }" class="btn-clear btn-100 mt-5">
              Common Properties
            </button>
            <button type="button" (click)="selectObjectPropertyType('read-only')"
              [class]="{ 'btn-primary': objectPropertyTypeSelection === 'read-only' }" class="btn-clear btn-100 mt-5">
              Read-only Properties
            </button>
          </div>

          <app-add-component *ngIf="!customObject" class="guided-add-component"
            [objectPropertyTypeSelectionInput]="objectPropertyTypeSelection" [objectSelectionInput]="objectSelection"
            [queryParams]="queryParams">
          </app-add-component>

          <app-custom-object *ngIf="customObject" class="guided-add-component"
            [objectPropertyTypeSelectionInput]="objectPropertyTypeSelection" [queryParams]="queryParams"
            style="position: relative; top: -80px; left: 50px;">
          </app-custom-object>

        </div>

        <!-- either custom or add-component in bundle's editObject -->
        <!-- <app-custom-object></app-custom-object> -->

      </div>

      <!-- <div *ngIf="indicatorSelection === 'add-evidence' || indicatorSelection === 'add-ioc'"
        class="d-flex flex-column justify-content-center align-items-center">
        <div style="height: 35vh; width: 60vw">
          <div class="d-flex justify-content-center tlp-build">Select Indicator for Object Details</div>

          <div class="modal-body" style="padding-left: 0px; padding-right: 0px; height: 54vh;">
            <div *ngIf="showingAnalyst1" class="w-100">
              <div class="d-flex justify-content-center flex-column">
                <div *ngIf="indicatorSelection === 'add-ioc'" class="row text-center pt-2 pb-4 offset-1">
                  <div class="col-8 input-group search-bar">
                    <input class="form-control search-input" [(ngModel)]="searchTextAnalyst1"
                      placeholder="Input criteria to search indicators on Analyst 1."
                      (keydown.enter)="getAnalyst1Objects()">
                    <button class="clear-button" [disabled]="!searchTextAnalyst1" (click)="clearSearchAnalyst1()">
                      <p class="clear-button-text" *ngIf="searchTextAnalyst1">X</p>
                    </button>
                  </div>
                  <div class="col-3" style="padding-left: 4.5%">
                    <button *ngIf="!loadingStix" [disabled]="!searchTextAnalyst1"
                      class="btn btn-primary pe-4 ps-4 pt-2 pb-2 query-button" (click)="getAnalyst1Objects()">
                      Search
                    </button>
                    <button *ngIf="loadingStix" disabled class="btn btn-primary pe-4 ps-4 pt-2 pb-2 query-button">
                      <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      <span class="sr-only">Loading...</span>
                    </button>
                  </div>
                </div>
                <div class="pb-1 mb-1" style="border-bottom: 1px solid #dee2e6;"></div>
                <div *ngIf="analyst1Objects?.length > 0" style="width: 98%; align-self: center;"
                  class="row text-center pb-2">
                  <div class="col-md-12 mt-2 d-flex justify-content-between">
                    <div class="col-md-9 d-flex">
                      <h1 class="h3">Objects</h1>
                    </div>
                    <div class="d-flex justify-content-end align-items-center col-md-3 pe-0">
                      <button *ngIf="showNextAnalyst1Page() && !loadingStixNext" type="button"
                        class="btn btn-sm btn-outline-primary next-button" (click)="getNextAnalyst1Page()"
                        title="More objects are available for this query. Click Next to see them.">
                        Next Page
                      </button>
                      <button *ngIf="loadingStixNext" type="button" disabled
                        class="btn btn-sm btn-outline-primary next-button">
                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span class="sr-only">Loading...</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div [class]="analyst1Objects?.length === 0 ? 'mb-6' : 'mb-3'"
              style="display: flex; justify-content: center; align-items: center; width: 100%; height: 80%; overflow: auto;"
              class="mt-1 analyst1-objects-wrapper">
              <div *ngIf="analyst1Objects?.length === 0 && !addingStixToPending"
                class="d-flex justify-content-center align-items-center">
                <div class="container" style="text-align: center;">
                  <p style="font-weight: bold;">No results</p>
                </div>
              </div>

              <div *ngIf="addingStixToPending" class="d-flex justify-content-center align-items-center">
                Loading&nbsp;<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              </div>

              <div *ngIf="analyst1Objects?.length > 0" style="width: 100%; height: 100%">
                <div class="d-flex justify-content-start mt-3">
                  <div style="margin-left: 5%" class="d-flex flex-column">
                    <span style="font-weight: bold;">Check All</span>
                    <input type="checkbox" (change)="checkAllAnalyst1Objects($event)" class="align-self-start" />
                  </div>
                  <div style="margin-left: 7%; width: 60%">
                    <p style="font-weight: bold;">Value</p>
                  </div>
                  <div style="margin-left: 5%">
                    <p style="font-weight: bold;">Type</p>
                  </div>
                </div>
                <div *ngFor="let object of analyst1Objects">
                  <div class="d-flex justify-content-start mt-3">
                    <div style="margin-left: 5%">
                      <input type="checkbox" [(ngModel)]="object.checked" />
                    </div>
                    <div style="margin-left: 13%; width: 60%; overflow: auto">
                      <div *ngIf="useGeneralApi || useEvidenceApi">{{ object?.value?.name }}</div>
                      <div *ngIf="useBatchCheckApi">{{ object?.matchedValue }}</div>
                    </div>
                    <div style="margin-left: 5%">
                      <span *ngIf="useGeneralApi || useEvidenceApi">{{ object?.type }}</span>
                      <span *ngIf="useBatchCheckApi">{{ object?.type?.key }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div> -->
    </div>

    <div *ngIf="menuSelection === 'add-indicator-to-bundle'"
      class="d-flex justify-content-center align-items-start content-wrapper w-100"
      [class]="{'content-wrapper-long': indicatorSelection === 'add-evidence'}">
      <app-add-indicator-to-bundle #addIndicatorToBundle [evidenceId]="evidenceId"></app-add-indicator-to-bundle>
    </div>

    <!-- Additional Info - 2 -->
    <div *ngIf="menuSelection === 'additional-info-2'"
      class="d-flex justify-content-center align-items-start content-wrapper w-100"
      [class]="{'content-wrapper-long': indicatorSelection === 'add-evidence'}">
      <!-- <div *ngIf="indicatorSelection !== 'add-evidence' && indicatorSelection !== 'add-ioc'"
        class="d-flex flex-column justify-content-center align-items-center">
        <div class="success">Successfully Added Object!</div>

        <div class="d-flex flex-column justify-content-center align-items-center">
          <fa-icon class="check-circle" [icon]="faCheckCircle">
          </fa-icon>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <strong>Would you like to create a relationship with this object?</strong>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <button type="button" class="btn-clear btn-100" style="color: black" (click)="createRelationship = true;"
            [ngClass]="{'btn-clear-blue': createRelationship === true}">
            Yes
          </button>

          <button type="button" class="btn-clear btn-100 ms-4" style="color: black"
            (click)="createRelationship = false;" [ngClass]="{'btn-clear-blue': createRelationship === false}">
            No
          </button>
        </div>
      </div> -->

      <div *ngIf="indicatorSelection === 'add-evidence' || indicatorSelection === 'add-ioc'"
        class="d-flex flex-column justify-content-center align-items-center">
        <div style="height: 35vh; width: 60vw">
          <div class="d-flex justify-content-center tlp-build">Add Indicator to Bundle</div>

          <div class="modal-body mb-3" style="padding: 2rem; height: 60vh; overflow: hidden;">
            <div style="height: 63vh">

              <div *ngIf="addingStixToPending" class="d-flex justify-content-center align-items-center"
                style="margin-top: 20vh">
                Loading&nbsp;<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              </div>

              <div *ngIf="!addingStixToPending">
                <ul class="nav nav-tabs">
                  <li class="nav-item" (click)="activeTabAnalyst1ObjType = 'indicator'">
                    <a class="nav-link active"
                      [ngClass]="{'active': activeTabAnalyst1ObjType === 'indicator'}">Indicator</a>
                  </li>
                  <li class="nav-item" (click)="activeTabAnalyst1ObjType = 'stix'">
                    <a class="nav-link" [ngClass]="{'active': activeTabAnalyst1ObjType === 'stix'}">Parsed</a>
                  </li>
                </ul>
                <div class="tooltipright inline-block">
                  <fa-icon class="ps-1" [icon]="faInfoCircle"></fa-icon>
                  <div class="toolttext" style="width: 350px">Parsed indicators are IOC's that IMX can and does
                    automatically convert to cyber-observable STIX objects. Indicators are IOC's pulled from A1, but
                    that the analyst would have to manually add as STIX objects. User has option to add one or both to
                    the bundle, but Indicators will not be classified into STIX objects.</div>
                </div>

                <div *ngIf="activeTabAnalyst1ObjType === 'indicator'">
                  <div class="row">
                    <div class="col text-center text-dark stix-preview-message">
                      <div class="bundle-wrapper a1indicator">
                        <div *ngFor="let stixObject of stixObjects; let i = index;">
                          <div *ngFor="let object of stixObject.objects; let j = index;">
                            <div *ngIf="(pendingObjects | bundlefilter: object.type).length > 0">
                              <div class="row text-start">
                                <div class="col" id="{{getStixObjectID(object.type)}}">
                                  <table class="table bundle-wrapper a1indicator">
                                    <thead>
                                      <th colspan="2">
                                        {{object.displayName}}
                                        <span class="badge rounded-circle bg-primary sdo-badge">
                                          {{ (pendingObjects | bundlefilter: object.type
                                          ).length
                                          }}
                                        </span>
                                      </th>
                                    </thead>
                                    <tbody class="overflow">
                                      <div *ngIf="true">
                                        <ng-container
                                          *ngFor="let component of pendingObjects | bundlefilter: object.type; let k = index; let c = count;">
                                          <tr>
                                            <td class="btn-link overflow" style="padding-top:14px; width: 65vw"
                                              id="{{getComponentId(component, i, j, k)}}" (click)="changeShow(j, k)">
                                              <div class="pull-left float-start overflow">
                                                {{getComponentDisplay(component)}}</div>
                                            </td>
                                          </tr>
                                          <tr class="expanded-panel" *ngIf="objnumber == j && rownumber == k">
                                            <td colspan="2">
                                              <div class="row">
                                                <div class="col-md-2">
                                                  <button type="button" class="btn btn-fa btn-warning"
                                                    (click)="removeObject(component.id);">
                                                    <fa-icon [icon]="faTrash"></fa-icon>
                                                  </button>
                                                </div>
                                              </div>
                                              <div class="row" style="width: 80%">
                                                <pre>{{ getJsonDisplayForComponent(component) | json }}</pre>
                                              </div>
                                            </td>
                                          </tr>
                                        </ng-container>
                                      </div>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="activeTabAnalyst1ObjType === 'stix'">
                  <div class="row">
                    <div class="col text-center text-dark stix-preview-message">
                      <div class="bundle-wrapper">
                        <div *ngFor="let stixObject of stixObjects; let i = index;">
                          <div *ngFor="let object of stixObject.objects; let j = index;">
                            <div *ngIf="(pendingStixObjects | bundlefilter: object.type).length > 0">
                              <div class="row text-start">
                                <div class="col" id="{{getStixObjectID(object.type)}}">
                                  <table class="table">
                                    <thead>
                                      <th colspan="2">
                                        {{object.displayName}}
                                        <span class="badge rounded-circle bg-primary sdo-badge">
                                          {{ (pendingStixObjects | bundlefilter: object.type
                                          ).length
                                          }}
                                        </span>
                                      </th>
                                    </thead>
                                    <tbody style="overflow: hidden">
                                      <div *ngIf="true">
                                        <ng-container
                                          *ngFor="let component of pendingStixObjects | bundlefilter: object.type; let k = index; let c = count;">
                                          <tr>
                                            <td class="btn-link overflow" style="padding-top:14px; width: 65vw"
                                              id="{{getComponentId(component, i, j, k)}}" (click)="changeShow(j, k)">
                                              <div class="pull-left float-start overflow">
                                                {{getComponentDisplay(component)}}</div>
                                            </td>
                                          </tr>
                                          <tr class="expanded-panel" *ngIf="objnumber == j && rownumber == k">
                                            <td colspan="2">
                                              <div class="row">
                                                <div class="col-md-2">
                                                  <button type="button" class="btn btn-fa btn-warning"
                                                    (click)="removeObject(component.id);">
                                                    <fa-icon [icon]="faTrash"></fa-icon>
                                                  </button>
                                                </div>
                                              </div>
                                              <div class="row" style="width: 80%">
                                                <pre>{{ getJsonDisplayForComponent(component) | json }}</pre>
                                              </div>
                                            </td>
                                          </tr>
                                        </ng-container>
                                      </div>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="pendingStixObjects?.length === 0" style="margin-top: 20%">
                          The JSON information pulled from A1 does not have a clear a:b pattern that allows IMX to
                          convert it to a STIX object.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Additional Info - 3 -->
    <div *ngIf="menuSelection === 'additional-info-3'"
      class="d-flex justify-content-center align-items-start content-wrapper">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="success">Successfully Added Object!</div>

        <div class="d-flex flex-column justify-content-center align-items-center">
          <fa-icon class="check-circle" [icon]="faCheckCircle">
          </fa-icon>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <strong>Would you like to create a relationship with this object?</strong>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <button type="button" class="btn-clear btn-100" style="color: black"
            (click)="createRelationship = true; goForward()"
            [ngClass]="{'btn-clear-blue': createRelationship === true}">
            <fa-icon [icon]="faCheck" class="pe-1"></fa-icon>
            Yes
          </button>

          <button type="button" class="btn-clear btn-100 ms-4" style="color: black"
            (click)="createRelationship = false; goForward()"
            [ngClass]="{'btn-clear-blue': createRelationship === false}">
            <fa-icon [icon]="faTimes" class="pe-1"></fa-icon>
            No
          </button>
        </div>
      </div>
    </div>

    <!-- Relationship - 1 -->
    <div *ngIf="menuSelection === 'relationship-1'"
      class="d-flex justify-content-center align-items-start content-wrapper" style="">
      <div class="d-flex flex-column justify-content-center align-items-center w-75">
        <div class="tlp-build">Let's Create a Relationship Object!</div>
        <div class="tlp-select">Select Object(s) and Type of Relationship</div>

        <app-bundle #bundleForReview [showObjectSection]="false" [showVisualizerSection]="false"
          [showBundleSection]="true" [menuSelection]="menuSelection" style="width: 100%">
        </app-bundle>

      </div>
    </div>

    <!-- Relationship - 2 -->
    <div *ngIf="menuSelection === 'relationship-2'"
      class="d-flex justify-content-center align-items-start content-wrapper">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="tlp-build">{{ displayObjectTitle() }}</div>

        <div class="d-flex object-info-wrapper mt-3">
          <div class="d-flex flex-column">
            <button type="button" (click)="selectObjectPropertyType('required')"
              [class]="{ 'btn-primary': objectPropertyTypeSelection === 'required' }" class="btn-clear btn-100">
              Primary Properties
            </button>
            <button type="button" (click)="selectObjectPropertyType('common')"
              [class]="{ 'btn-primary': objectPropertyTypeSelection === 'common' }" class="btn-clear btn-100 mt-5">
              Common Properties
            </button>
            <button type="button" (click)="selectObjectPropertyType('read-only')"
              [class]="{ 'btn-primary': objectPropertyTypeSelection === 'read-only' }" class="btn-clear btn-100 mt-5">
              Read-only Properties
            </button>
          </div>

          <app-add-component class="guided-add-component"
            [objectPropertyTypeSelectionInput]="objectPropertyTypeSelection" [objectSelectionInput]="objectSelection">
          </app-add-component>

        </div>
      </div>
    </div>

    <!-- RELATIONSHIP SECTION BASED ON MOCKUP -->
    <!-- <div *ngIf="menuSelection === 'relationship-1'"
      class="d-flex justify-content-center align-items-start content-wrapper">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="tlp-build">Let's Create a Relationship Object</div>
        <div class="tlp-select">Select an entity to relate your STIX Object</div>
        <div class="d-flex mt-3">
          <div class="d-flex justify-content-center align-items-center card card-blue"
            [ngClass]="{'item-selected': relationshipTypeSelection === 'stix-object'}"
            (click)="selectRelationshipType('stix-object')">
            <div>STIX Object</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-blue"
            [ngClass]="{'item-selected': relationshipTypeSelection === 'analyst1-object'}"
            (click)="selectRelationshipType('analyst1-object')">
            <div>Analyst1 Object</div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div *ngIf="menuSelection === 'relationship-1-1'"
      class="d-flex justify-content-center align-items-start content-wrapper">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="tlp-build">Let's Create a Relationship Object</div>
        <div class="tlp-select">Select type of Relationship Object </div>
        <div class="d-flex mt-3">
          <div class="d-flex justify-content-center align-items-center card card-blue"
            [ngClass]="{'item-selected': relationshipSelection === 'relationship'}"
            (click)="selectRelationship('relationship')">
            <div>Relationship</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-blue"
            [ngClass]="{'item-selected': relationshipSelection === 'sighting'}"
            (click)="selectRelationship('sighting')">
            <div>Sighting</div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Relationship - 2 -->
    <!-- <div *ngIf="menuSelection === 'relationship-2'"
      class="d-flex justify-content-center align-items-start content-wrapper">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="tlp-build">{{ displayObjectTitle() }}</div>

        <div class="d-flex object-info-wrapper mt-5">
          <div class="d-flex flex-column">
            <button type="button" (click)="selectObjectPropertyType('required')"
              [class]="{ 'btn-primary': objectPropertyTypeSelection === 'required' }" class="btn-clear btn-100">
              Primary Properties
            </button>
            <button type="button" (click)="selectObjectPropertyType('common')"
              [class]="{ 'btn-primary': objectPropertyTypeSelection === 'common' }" class="btn-clear btn-100 mt-5">
              Common Properties
            </button>
            <button type="button" (click)="selectObjectPropertyType('read-only')"
              [class]="{ 'btn-primary': objectPropertyTypeSelection === 'read-only' }" class="btn-clear btn-100 mt-5">
              Read-only Properties
            </button>
          </div>

          <app-add-component class="guided-add-component"
            [objectPropertyTypeSelectionInput]="objectPropertyTypeSelection" [objectSelectionInput]="objectSelection">
          </app-add-component>

        </div>
      </div>
    </div> -->

    <!-- Object Addition -->
    <div *ngIf="menuSelection === 'object-addition'"
      class="d-flex justify-content-center align-items-start content-wrapper">

      <app-object-addition #objectAddition (addObjectEvent)="goForward()"></app-object-addition>

      <!-- <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="d-flex justify-content-center align-items-center mt-4">
            <strong class="tlp-build">Would you like to add additional CTI?</strong>
          </div>
          <fa-icon class="check-circle-another-object" [icon]="faQuestionCircle">
          </fa-icon>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <button type="button" class="btn-clear btn-100" style="color: black" (click)="createAnotherObject = true; goForward()"
            [ngClass]="{'btn-clear-blue': createAnotherObject === true}">
            <fa-icon [icon]="faCheck" class="pe-1"></fa-icon>
            Yes
          </button>

          <button type="button" class="btn-clear btn-100 ms-4" style="color: black"
            (click)="createAnotherObject = false; goForward()" [ngClass]="{'btn-clear-blue': createAnotherObject === false}">
            <fa-icon [icon]="faTimes" class="pe-1"></fa-icon>
            No
          </button>
        </div>
      </div> -->
    </div>

    <!-- Review -->
    <div *ngIf="menuSelection === 'build-review'"
      class="d-flex flex-column justify-content-start align-items-center content-wrapper">

      <div class="d-flex justify-content-between align-items-center w-75 mb-3">
        <div class="tlp-build">Bundle Overview</div>
        <div class="d-flex justify-content-center align-items-center">
          <span class="ps-4" style="width: 200px">Other Viewing Options:</span>
          <select style="width: 150px" class="form-control form-select" name="otherViewingOptions"
            (change)="updateViewOption($event)">
            <option *ngFor="let opt of reviewOptions">{{opt}}</option>
          </select>
        </div>
      </div>

      <div *ngIf="reviewOption === 'JSON'"
        class="d-flex flex-column justify-content-center align-items-center w-50 h-75">
        <pre class="preview-bundle mt-4 mb-5" style="width: 65vw; height: 40vh">{{ getStixPreview() | json }}</pre>
      </div>

      <div *ngIf="reviewOption !== 'JSON'"
        class="d-flex flex-column justify-content-center align-items-center w-75 mb-3">
        <app-bundle *ngIf="reviewOption === 'Bundle' && !bundleRefresh" #bundleForReview [showObjectSection]="false"
          [showVisualizerSection]="false" [showBundleObjectsSectionOnly]="true" [menuSelection]="menuSelection"
          [isGroupByTLP]="isGroupByTLP" class="build-review-bundle-wrapper">
        </app-bundle>

        <div *ngIf="reviewOption === 'Visual'" style="width: 100%; border: 1px solid black">
          <app-stix-viewer class="full-width nav-link" [bundle]="getStixPreview()">
          </app-stix-viewer>
        </div>
      </div>
    </div>

    <!-- TLP Type -->
    <div *ngIf="menuSelection === 'tlp-type'" class="d-flex justify-content-center align-items-center content-wrapper">
      <div class="d-flex flex-column justify-content-start align-items-center content-wrapper">
        <div class="tlp-build">Assign Default TLP</div>
        <!-- <div class="tlp-select">Select a TLP</div> -->
        <div class="mt-2" style="width: 100px">
          <select *ngIf="this.env.tlpConfig.enabledTlpVersions.length > 1" type="text" [(ngModel)]="tlpVersion"
            (change)="getTlpOptions($event)">
            <option *ngFor="let ver of this.env.tlpConfig.enabledTlpVersions" value="{{ver.value}}">{{ver.label}}
            </option>
          </select>
          <!-- <select *ngIf="this.env.tlpConfig.enableTlpOne" type="text" [(ngModel)]="tlpVersion" (change)="getTlpOptions($event)">
            <option value="v1" selected>TLP v1.0</option>
            <option value="v2">TLP v2.0</option>
          </select> -->
        </div>
        <input class="form-control" *ngIf="this.env.tlpConfig.enabledTlpVersions.length == 1" disabled
          value="{{this.env.tlpConfig.enabledTlpVersions[0].label}}" style="width: 49vw">
        <!-- <input class="form-control" *ngIf="!this.env.tlpConfig.enableTlpOne" disabled value="TLP v2.0" style="width: 73vw"> -->
        <div class="mt-2" style="width: 100%; text-align: center">
          TLP is optional but <b>recommended</b>. This TLP color will only be applied to objects that do not have a TLP
          color assigned to them.<br>This pre-selected TLP is the highest TLP in bundle and can be deselected.
        </div>
        <div class="d-flex" style="margin-top: -20px">
          <div *ngFor="let option of this.objectMarking.tlp_options"
            class="d-flex justify-content-center align-items-center card card-tlp {{option.css}}"
            [ngClass]="{'item-selected': checkSelectedTLP(option.key)}" (click)="selectTLP(option.key)">
            <div style="width: -webkit-fill-available">{{option.key}}</div>
          </div>
        </div>
        <div *ngIf="onPageMsgs?.length > 0" class="row mt-5">
          <div class="col">
            <div class="d-flex justify-content-center flex-column">
              <div *ngFor="let msg of onPageMsgs;">
                {{ msg }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex flex-column">
          <div *ngFor="let tlpCount of tlpCounts">
            <div class="me-2 badge" [class]="getTlpStyle(tlpCount.tlp.key)" style="width: 135px">{{tlpCount.tlp.key}}</div><span class="ms-3 badge rounded-circle bg-primary sdo-badge">{{tlpCount.count}}</span>
          </div>
        </div> -->
        <!-- <div *ngIf="tlpVersion === 'v1'" class="d-flex mt-3">
          <div class="d-flex justify-content-center align-items-center card card-white"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:WHITE')}" (click)="selectTLP('TLP:WHITE')">
            <div>White TLP</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-green"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:GREEN')}" (click)="selectTLP('TLP:GREEN')">
            <div>Green TLP</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-amber"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:AMBER')}" (click)="selectTLP('TLP:AMBER')">
            <div>Amber TLP</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-red" *ngIf=""
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:RED')}" (click)="selectTLP('TLP:RED')">
            <div>Red TLP</div>
          </div>
        </div>

        <div *ngIf="tlpVersion === 'v2'" class="d-flex mt-3">
          <div class="d-flex justify-content-center align-items-center card card-white card-tlp-v2"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:CLEAR')}" (click)="selectTLP('TLP:CLEAR')">
            <div>Clear TLP</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-green card-tlp-v2"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:GREEN')}" (click)="selectTLP('TLP:GREEN')">
            <div>Green TLP</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-amber card-tlp-v2"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:AMBER')}" (click)="selectTLP('TLP:AMBER')">
            <div>Amber TLP</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-amber card-tlp-v2"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:AMBER+STRICT')}" (click)="selectTLP('TLP:AMBER+STRICT')">
            <div>Amber+Strict TLP</div>
          </div>
          <div class="d-flex justify-content-center align-items-center card card-red card-tlp-v2"
            [ngClass]="{'item-selected': checkSelectedTLP('TLP:RED')}" (click)="selectTLP('TLP:RED')">
            <div>Red TLP</div>
          </div>
        </div> -->
      </div>
    </div>


    <!-- Publish Type - 1 -->
    <div *ngIf="menuSelection === 'publish-type-1'"
      class="d-flex justify-content-center align-items-start content-wrapper">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="tlp-build">Where would you like to Publish?</div>
        <div class="tlp-select">Select an automatic or manual TAXII Server</div>

        <div class="d-flex flex-column mt-5" style="width: 50vw">
          <ul class="nav nav-tabs">
            <li class="nav-item" (click)="activePublishTab = 'Automatic'">
              <a class="nav-link" [ngClass]="{'active': activePublishTab === 'Automatic'}">
                Automatic
              </a>
            </li>
            <li class="nav-item" (click)="activePublishTab = 'Manual'">
              <a class="nav-link" [ngClass]="{'active': activePublishTab === 'Manual'}">
                Manual
              </a>
            </li>
          </ul>

          <div *ngIf="activePublishTab === 'Manual'" class="d-flex mt-2 justify-content-center">
            <div class="d-flex justify-content-center align-items-center card card-blue"
              [ngClass]="{'item-selected': guidedBundle?.taxiiServerType === 'default'}"
              (click)="selectPublishType('default')">
              <div>Default<br>Server</div>
            </div>
            <div class="d-flex justify-content-center align-items-center card card-blue"
              [ngClass]="{'item-selected': guidedBundle?.taxiiServerType === 'custom'}"
              (click)="selectPublishType('custom')">
              <div>Custom<br>Server</div>
            </div>
            <div class="d-flex justify-content-center align-items-center card card-blue"
              [ngClass]="{'item-selected': guidedBundle?.taxiiServerType === 'ais'}" (click)="selectPublishType('ais')">
              <div>AIS<br>Server</div>
            </div>
          </div>

          <div *ngIf="activePublishTab === 'Automatic'"
            class="d-flex flex-column justify-content-center align-items-center mt-3">
            <div class="d-flex justify-content-center" style="height: 10%; width: 80%" *ngIf="publishToCisa">
              <h6 class="mt-5 d-flex justify-content-center">
                This will publish your information directly to CISA
              </h6>
            </div>
            <div class="d-flex justify-content-center" style="height: 10%; width: 80%"
              [ngSwitch]="guidedBundle?.taxiiServerType" *ngIf="!publishToCisa">
              <h6 class="mt-5 d-flex justify-content-center" *ngSwitchCase="'raw'">
                The "Collaboration" collection belongs to "Internal-CISA" API Root and both are housed on the Default
                TAXII server. This API root and collection are in place for analysts to publish new CTI and collaborate
                on existing CTI as new information becomes available.
              </h6>
              <h6 class="mt-5 d-flex justify-content-center" *ngSwitchCase="'a1'">
                The "Analyst1" collection belongs to {{env.taxiiServer.a1ApiRoot}} API Root and both are housed on the Default TAXII
                server. This API root and collection are in place for analysts to publish CTI to Analyst1.
              </h6>
              <h6 class="mt-5 d-flex justify-content-center" *ngSwitchCase="'sandbox'">
                The "Testing" API Root and "Testing-Collection" are housed on the Default TAXII server. This API root
                and collection are in place for analysts to practice STIX bundle creation.
              </h6>
              <h6 class="mt-5 d-flex justify-content-center" *ngSwitchDefault>
                <br><br>
              </h6>
            </div>

            <div class="d-flex justify-content-center" style="margin-top: -20px">
              <!-- <div *ngIf="initialIngestWritable" class="d-flex justify-content-center align-items-center card card-blue"
                [ngClass]="{'item-selected': guidedBundle?.taxiiServerType === 'raw'}" (click)="selectPublishType('raw')">
                <div>Publish<br>to<br>Collaboration</div>
              </div>
              <div class="d-flex justify-content-center align-items-center card card-blue"
                [ngClass]="{'item-selected': guidedBundle?.taxiiServerType === 'raw'}" (click)="selectPublishType('raw')">
                <div>Publish<br>to<br>CISA</div>
              </div> -->
              <div *ngIf="env.customConfiguration.cisa.enabled" class="d-flex justify-content-center align-items-center card card-blue"
                [ngClass]="{'item-selected': guidedBundle?.taxiiServerType === 'cisa'}" (click)="selectPublishType('cisa')">
                <div class="pb-2">Publish to CISA</div>
                <img class="cisa-publication-logo" src="../../assets/CISA_Logo.png" alt="CISA Logo">
              </div>

              <div class="d-flex justify-content-center align-items-center card card-blue"
                [ngClass]="{'item-selected': guidedBundle?.taxiiServerType === 'sandbox'}"
                (click)="selectPublishType('sandbox')">
                <div>Publish<br>to<br>Testing</div>
              </div>

              <div *ngIf="a1Writable && env.customConfiguration.analyst1.enabled" class="d-flex justify-content-center align-items-center card card-blue"
                [ngClass]="{'item-selected': guidedBundle?.taxiiServerType === 'a1'}" (click)="selectPublishType('a1')">
                <div>Publish<br>to<br>Analyst1</div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Publish type - 2 -->
    <div *ngIf="menuSelection === 'publish-type-2'"
      class="d-flex justify-content-center align-items-start content-wrapper">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="tlp-build">Please provide more detail</div>
        <div class="tlp-select">Add an API Root & Collection</div>

        <div *ngIf="guidedBundle?.taxiiServerType === 'default' || guidedBundle?.taxiiServerType === 'ais'"
          class="d-flex object-info-wrapper mt-5">
          <div class="d-flex flex-column">
            <div class="d-flex justify-content-center align-items-center card card-blue">
              <div *ngIf="guidedBundle?.taxiiServerType === 'default'">Default<br>Server</div>
              <div *ngIf="guidedBundle?.taxiiServerType === 'ais'">AIS<br>Server</div>
            </div>
          </div>
          <div class="d-flex flex-column relationship-properties-wrapper mt-5">
            <div class="d-flex align-items-center">
              <div class="d-flex justify-content-end relationship-property-name-wrapper">
                <div class="">
                  <div class="relationship-property-name">
                    API Root
                  </div>
                </div>
              </div>
              <div class="relationship-property-input-wrapper">
                <div ngbDropdown>
                  <button class="btn btn-outline-primary server-detail-dropdown" style="height:31px;" id="sortMenu"
                    ngbDropdownToggle
                    [disabled]="stixService.isLoadingRoots > 0 || guidedBundle?.stixService?.apiRoots?.length === 0">
                    <span *ngIf="stixService.isLoadingRoots">
                      Loading API Roots...
                    </span>
                    <span *ngIf="stixService.isLoadingRoots <= 0 && !guidedBundle?.taxiiServer?.apiRoot">
                      <fa-icon [icon]="faSelect" class="pe-1"></fa-icon>
                      Select
                    </span>
                    <span *ngIf="stixService.isLoadingRoots <= 0 && guidedBundle?.taxiiServer?.apiRoot">
                      {{ guidedBundle?.taxiiServer?.apiRoot }}
                    </span>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="sortMenu">
                    <button class="dropdown-item" *ngFor="let apiRoot of this.guidedBundle.apiRootsPublishTo()"
                      (click)="selectApiRoot(apiRoot); checkRootAndCollectionSelected()"
                      title="{{ apiRoot?.description }}">
                      {{ apiRoot?.title }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center mt-3">
              <div class="d-flex justify-content-end relationship-property-name-wrapper">
                <div class="">
                  <div class="relationship-property-name">
                    Collections
                  </div>
                </div>
              </div>
              <div class="relationship-property-input-wrapper">
                <div ngbDropdown>
                  <button class="btn btn-outline-primary server-detail-dropdown" style="height:31px;" id="sortMenu"
                    ngbDropdownToggle
                    [disabled]="!guidedBundle.taxiiServer?.apiRoot || !guidedBundle.collectionsCanWriteTo()">
                    <span *ngIf="!guidedBundle?.selectedCollection">
                      <fa-icon [icon]="faSelect" class="pe-1"></fa-icon>
                      Select
                    </span>
                    <span *ngIf="guidedBundle?.selectedCollection">
                      {{ guidedBundle?.selectedCollection?.title }}
                    </span>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="sortMenu">
                    <ng-container *ngFor="let collection of guidedBundle?.collections">
                      <button class="dropdown-item"
                        (click)="guidedBundle.onCollectionChanged(collection); checkRootAndCollectionSelected()"
                        title="{{ collection?.description }}">
                        {{ collection.title }}
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="d-flex align-items-center mt-3 col-5" style="background-color:aqua">
              <ng-container *ngIf="this.stixService.apiRootProcessingError != ''">
                <p>
                  <span style="font-weight: bold;">Failed to connect to {{this.stixService.taxiiServer.url ==
                    "https://3.211.155.16/taxii/v2.1-os/" ? this.stixService.prod ? "https://ais2.cisa.dhs.gov/taxii2/" :
                    "https://ais2-pfte.taxii.us-cert.gov/" : this.stixService.taxiiServer.url}}</span>
                  <br>{{this.stixService.apiRootProcessingError}}<br>URL: {{this.stixService.apiRootProcessingErrorURL}}
                </p>
              </ng-container>
            </div> -->

          </div>
        </div>

        <div *ngIf="guidedBundle?.taxiiServerType === 'custom'" class="d-flex object-info-wrapper mt-5">
          <div class="d-flex flex-column">
            <div class="d-flex justify-content-center align-items-center card card-blue">
              <div>Custom TAXII Server</div>
            </div>
          </div>

          <div class="d-flex flex-column relationship-properties-wrapper mt-5">

            <div class="d-flex align-items-center">
              <div class="d-flex justify-content-end relationship-property-name-wrapper">
                <div class="">
                  <div class="relationship-property-name">
                    TAXII Server URL
                  </div>
                </div>
              </div>
              <div class="relationship-property-input-wrapper">
                <input type="url" name="taxii-server-url" minlength="8" [(ngModel)]="guidedBundle.taxiiServer.url"
                  placeholder="" required class="form-control t1t-left server-detail-dropdown"
                  aria-label="TAXII Server URL" aria-describedby="taxii-server-url">
              </div>
            </div>

            <!-- <div class="d-flex align-items-center mt-3">
              <div class="d-flex justify-content-end relationship-property-name-wrapper">
                <div class="">
                  <div class="relationship-property-name">
                    Auth Method
                  </div>
                </div>
              </div>
              <div class="relationship-property-input-wrapper">
                <div class="btn-group server-detail-dropdown" role="group">
                  <button type="button" class="btn btn-outline-secondary" style="height:38px;"
                    [class]="{'active': guidedBundle?.taxiiServerAuthType === 'cert'}"
                    (click)="resetRootCollectionSelection(); guidedBundle.taxiiServerAuthType = 'cert'; guidedBundle.taxiiServer.username = ''; guidedBundle.taxiiServer.password  = ''; ">
                    Certificate Auth
                  </button>
                  <button type="button" class="btn btn-outline-secondary" style="height:38px;"
                    [class]="{'active': guidedBundle?.taxiiServerAuthType === 'basic'}"
                    (click)="resetRootCollectionSelection(); guidedBundle.taxiiServerAuthType = 'basic'; guidedBundle.taxiiServer.certificate = '';">
                    Basic Auth
                  </button>
                </div>
              </div>
            </div> -->

            <div *ngIf="guidedBundle?.taxiiServerAuthType === 'cert'" class="d-flex align-items-center mt-3">
              <div class="d-flex justify-content-end relationship-property-name-wrapper">
                <div class="">
                  <div class="relationship-property-name">
                    TAXII Server Certificate
                  </div>
                </div>
              </div>
              <div class="relationship-property-input-wrapper">
                <div class="server-detail-dropdown input-group">
                  <input type="file" class="custom-file-input" id="inputGroupFile02" name="taxii-server-certificate"
                    minlength="8" [(ngModel)]="guidedBundle.taxiiServer.certificate" placeholder="Please select"
                    required aria-label="TAXII Server Certificate" aria-describedby="taxii-server-certificate">
                  <label class="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{{
                    guidedBundle?.taxiiServer?.certificate ?
                    guidedBundle?.taxiiServer?.certificate : '' }}</label>
                </div>
              </div>
            </div>

            <div *ngIf="guidedBundle?.taxiiServerAuthType === 'basic'" class="d-flex align-items-center mt-3">
              <div class="d-flex justify-content-end relationship-property-name-wrapper">
                <div class="">
                  <div class="relationship-property-name">
                    TAXII Server Username
                  </div>
                </div>
              </div>
              <div class="relationship-property-input-wrapper">
                <div class="server-detail-dropdown input-group">
                  <input type="text" ngModel name="taxii-server-username" #passwordInput="ngModel" minlength="8"
                    [(ngModel)]="guidedBundle.taxiiServer.username" placeholder="" required
                    class="form-control t1t-left" aria-label="TAXII Server Username"
                    aria-describedby="taxii-server-username">
                </div>
              </div>
            </div>

            <div *ngIf="guidedBundle?.taxiiServerAuthType === 'basic'" class="d-flex align-items-center mt-3">
              <div class="d-flex justify-content-end relationship-property-name-wrapper">
                <div class="">
                  <div class="relationship-property-name">
                    TAXII Server Password
                  </div>
                </div>
              </div>
              <div class="relationship-property-input-wrapper">
                <div class="server-detail-dropdown input-group">
                  <input type="password" ngModel name="taxii-server-password" #passwordInput="ngModel" minlength="8"
                    [(ngModel)]="guidedBundle.taxiiServer.password" placeholder="" required
                    class="form-control t1t-left" aria-label="TAXII Server Password"
                    aria-describedby="taxii-server-password">
                </div>
              </div>
            </div>

            <div *ngIf="guidedBundle?.taxiiServerType === 'custom'" class="d-flex align-items-center mt-3">
              <div class="d-flex justify-content-end relationship-property-name-wrapper">
                <div class="">
                  <div class="relationship-property-name">

                  </div>
                </div>
              </div>
              <div class="relationship-property-input-wrapper">
                <button type="button" class="btn btn-sm btn-primary" style="margin-bottom: 10px;"
                  (click)="this.getAPIRoots();"
                  [disabled]="guidedBundle.isConnectDisabled(true) || guidedBundle.isPublishing">
                  <fa-icon [icon]="faConnect" class="pe-1"></fa-icon>
                  Connect
                </button>
              </div>
            </div>

            <div class="d-flex align-items-center mt-3">
              <div class="d-flex justify-content-end relationship-property-name-wrapper">
                <div class="">
                  <div class="relationship-property-name">
                    API Root
                  </div>
                </div>
              </div>
              <div class="relationship-property-input-wrapper">
                <div ngbDropdown>
                  <button class="btn btn-outline-primary server-detail-dropdown" style="height:38px;" id="sortMenu"
                    ngbDropdownToggle [disabled]="stixService.isLoadingRoots > 0">
                    <span *ngIf="stixService.isLoadingRoots > 0">
                      Loading API Roots...
                    </span>
                    <span *ngIf="stixService.isLoadingRoots <= 0 && !guidedBundle?.taxiiServer?.apiRoot">
                      <fa-icon [icon]="faSelect" class="pe-1"></fa-icon>
                      Select
                    </span>
                    <span *ngIf="stixService.isLoadingRoots <= 0 && guidedBundle?.taxiiServer?.apiRoot">
                      {{ guidedBundle?.taxiiServer?.apiRoot }}
                    </span>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="sortMenu">
                    <button class="dropdown-item" *ngFor="let apiRoot of this.apiRootsPublishTo()"
                      (click)="selectApiRoot(apiRoot)" title="{{ apiRoot?.description }}">
                      {{ apiRoot?.apiPath }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center mt-3">
              <div class="d-flex justify-content-end relationship-property-name-wrapper">
                <div class="">
                  <div class="relationship-property-name">
                    Collections
                  </div>
                </div>
              </div>
              <div class="relationship-property-input-wrapper">
                <div ngbDropdown>
                  <button class="btn btn-outline-primary server-detail-dropdown" style="height:38px;" id="sortMenu"
                    ngbDropdownToggle
                    [disabled]="!guidedBundle.taxiiServer?.apiRoot || !guidedBundle.collectionsCanWriteTo()">
                    <span *ngIf="coll == null">
                      <fa-icon [icon]="faSelect" class="pe-1"></fa-icon>
                      Select
                    </span>
                    <span *ngIf="coll != null">
                      {{ coll.title }}
                    </span>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="sortMenu">
                    <ng-container *ngFor="let collection of guidedBundle?.taxiiServer?.availableCollections">
                      <button *ngIf="collection.can_write" class="dropdown-item"
                        (click)="guidedBundle.selectedCollection = collection; sanityCheck(collection);"
                        title="{{ collection?.description }}">
                        {{ collection.title }}
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="d-flex align-items-center mt-3 col-5" style="background-color:aqua">
              <ng-container *ngIf="this.stixService.apiRootProcessingError != ''">
                <p>
                  <span style="font-weight: bold;">Failed to connect to {{this.stixService.taxiiServer.url ==
                    "https://3.211.155.16/taxii/v2.1-os/" ? this.stixService.prod ? "https://ais2.cisa.dhs.gov/taxii2/" :
                    "https://ais2-pfte.taxii.us-cert.gov/" : this.stixService.taxiiServer.url}}</span>
                  <br>{{this.stixService.apiRootProcessingError}}<br>URL: {{this.stixService.apiRootProcessingErrorURL}}
                </p>
              </ng-container>
            </div> -->

          </div>
        </div>

      </div>
    </div>


    <!-- Publish Polling -->
    <div *ngIf="menuSelection === 'publish-polling'"
      class="d-flex justify-content-center align-items-start content-wrapper">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="tlp-build">Polling for Results</div>
        <!-- <div class="tlp-select">See latency status for an estimated wait time</div> -->
        <div class="d-flex flex-column mt-5 pt-5">
          <div class="spinner mx-auto"></div>
          <div class="text-center">Waiting for Results...</div>
          <!-- Derek: Make sure to add latency status -->
        </div>

      </div>
    </div>


    <!-- Publish Review -->
    <div *ngIf="menuSelection === 'publish-review'"
      class="d-flex justify-content-center align-items-start content-wrapper">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="tlp-build">Let's Review</div>
        <div class="tlp-select">Current TAXII Server</div>
        <div>
          <div class="d-flex object-info-wrapper mt-5">

            <div class="d-flex flex-column relationship-properties-wrapper mt-5">
              <div class="d-flex align-items-center">
                <div class="d-flex justify-content-end relationship-property-name-wrapper">
                  <div class="">
                    <div class="relationship-property-name">
                      TAXII Server
                    </div>
                  </div>
                </div>
                <div class="publish-review-data-wrapper">
                  <div class="publish-review-data">{{ serverTypeReview() }}</div>
                </div>
              </div>
              <div class="d-flex align-items-center mt-3">
                <div class="d-flex justify-content-end relationship-property-name-wrapper">
                  <div class="">
                    <div class="relationship-property-name">
                      API Root
                    </div>
                  </div>
                </div>
                <div class="publish-review-data-wrapper">
                  <div class="publish-review-data">{{ guidedBundle?.taxiiServer?.apiRoot }}</div>
                </div>
              </div>

              <div class="d-flex align-items-center mt-3">
                <div class="d-flex justify-content-end relationship-property-name-wrapper">
                  <div class="">
                    <div class="relationship-property-name">
                      Collection
                    </div>
                  </div>
                </div>
                <div class="publish-review-data-wrapper">
                  <ng-container [ngSwitch]="this.guidedBundle.taxiiServerType">
                    <div *ngSwitchCase="'a1'" class="publish-review-data">
                      <!-- Analyst1 -->
                      {{ getCollectionFromID(env.taxiiServer.a1CollectionId)?.title }}
                    </div>
                    <div *ngSwitchCase="'raw'" class="publish-review-data">
                      <!-- Initial Ingest -->
                      {{ getCollectionFromID(env.taxiiServer.a1RawCollectionId)?.title }}
                    </div>
                    <div *ngSwitchCase="'sandbox'" class="publish-review-data">
                      <!-- Testing -->
                      {{ getCollectionFromID(stixService.sandbox.collection)?.title }}
                    </div>
                    <div *ngSwitchCase="'cisa'" class="publish-review-data">
                      <!-- CISA -->
                      {{ getCollectionFromID(env.customConfiguration.cisa.collectionId)?.title }}
                    </div>
                    <div *ngSwitchDefault class="publish-review-data">{{ guidedBundle?.selectedCollection?.title }}
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Publish Success -->
    <div *ngIf="menuSelection === 'publish-success'"
      class="d-flex col-12 flex-column justify-content-center align-items-center" [class]="contentWrapperClass()">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="tlp-build">Publication Status</div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <fa-icon *ngIf="this.publishStatus == 'success'" class="check-circle" [icon]="faCheckCircle"></fa-icon>
          <fa-icon *ngIf="this.publishStatus == 'warn'" class="warn-circle" [icon]="faInfoCircle"></fa-icon>
          <fa-icon *ngIf="this.publishStatus == 'error'" class="error-circle" [icon]="faExclamationTriangle"></fa-icon>
        </div>
        <div>
          <div *ngIf="this.publishStatus == 'success'" class="success">Successfully Published Bundle!</div>
          <div *ngIf="this.publishStatus == 'warn'" class="warn">Partially Published Bundle</div>
          <div *ngIf="this.publishStatus == 'error'" class="error">Error Publishing Bundle</div>
          <div *ngIf="this.additionalStatusMessage.length > 0" class="tlp-select">{{ this.additionalStatusMessage }}
          </div>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="d-flex justify-content-center align-items-center mt-4">
            <strong>Would you like to create another bundle?</strong>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <button type="button" class="btn-clear btn-100" style="color: black"
            (click)="createAnotherBundle('yes'); goForward()"
            [ngClass]="{'btn-clear-blue': createAnotherObject === true}">
            Yes
          </button>

          <button type="button" class="btn-clear btn-100 ms-4" style="color: black" (click)="createAnotherBundle('no')"
            [ngClass]="{'btn-clear-blue': createAnotherObject === false}">
            No
          </button>
        </div>
      </div>
      <!-- <div class="d-flex col-12">
        <div class="col-6 d-flex flex-column justify-content-center align-items-center pt-5">
          <div *ngIf="this.publishStatus == 'success'" class="success">Successfully Published Bundle!</div>
          <div *ngIf="this.publishStatus == 'warn'" class="warn">Publish status information</div>
          <div *ngIf="this.publishStatus == 'error'" class="error">Error Publishing Bundle</div>
          <div *ngIf="this.additionalStatusMessage.length > 0" class="tlp-select">{{ this.additionalStatusMessage }}</div>

          <div class="d-flex flex-column justify-content-center align-items-center">
            <fa-icon *ngIf="this.publishStatus == 'success'" class="check-circle" [icon]="faCheckCircle"></fa-icon>
            <fa-icon *ngIf="this.publishStatus == 'warn'" class="warn-circle" [icon]="faInfoCircle"></fa-icon>
            <fa-icon *ngIf="this.publishStatus == 'error'" class="error-circle" [icon]="faTimesCircle"></fa-icon>
            <div *ngIf="this.publishStatus == 'success' || this.publishStatus == 'warn'"
            class="d-flex justify-content-center align-items-center mt-4">
              <strong>Would you like to create another bundle?</strong>
            </div>
          </div>
          <div *ngIf="this.publishStatus == 'success' || this.publishStatus == 'warn'" class="d-flex justify-content-center mt-3">
            <button type="button" class="btn-clear btn-100" style="color: black" (click)="createAnotherBundle('yes'); goForward()"
              [ngClass]="{'btn-clear-blue': createAnotherObject === true}">
              Yes
            </button>

            <button type="button" class="btn-clear btn-100 ms-4" style="color: black"
              (click)="createAnotherBundle('no')" [ngClass]="{'btn-clear-blue': createAnotherObject === false}">
              No
            </button>
          </div>
        </div>
        <div class="col-6 d-flex flex-column justify-content-start align-items-start pt-5">
          <div *ngIf="publishFeedback?.successes?.length > 0" class="col-10 publish-status-wrapper">
            <div class="text-success mb-2 pointer" (click)="expandSuccess = !expandSuccess; expandFailure = false;"><strong style="font-size: 1.5rem"><u>Successes</u></strong>
              <span class="badge rounded-circle bg-primary sdo-badge ms-1 badge-wrapper">{{publishFeedback?.successes?.length}}</span>
            </div>
            <div *ngIf="expandSuccess" class="publish-items-wrapper">
              <ul *ngFor="let successObj of publishFeedback?.successes" class="list-group publish-item">
                <li class="list-group-item">{{ getObjectNameFromPublish(successObj) }}</li>
              </ul>
            </div>
          </div>

          <div *ngIf="publishFeedback?.failures?.length > 0" class="col-10 publish-status-wrapper">
            <div class="text-danger mb-2 pointer" (click)="expandFailure = !expandFailure; expandSuccess = false;"><strong style="font-size: 1.5rem"><u>Failures</u></strong>
              <span class="badge rounded-circle bg-danger sdo-badge ms-1 badge-wrapper">{{publishFeedback?.failures?.length}}</span>
            </div>
            <div *ngIf="expandFailure" class="publish-items-wrapper">
              <ul *ngFor="let failureObj of publishFeedback?.failures" class="list-group publish-item">
                <li class="list-group-item">
                  <div>{{ getObjectNameFromPublish(failureObj) }}</div>
                  <div class="btn-danger">{{ failureObj.message }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex col-8 mt-3 pointer advanced-detail" (click)="expandAdvancedDetails = !expandAdvancedDetails"><span class="btn-link"><strong>Publish Status Details</strong></span>
        <pre *ngIf="expandAdvancedDetails" class="col-11 status-message">{{ this.publishFeedback | json }}</pre>
      </div> -->
    </div>

    <!-- Guided Report 1 -->
    <div *ngIf="menuSelection === 'guided-report-1'"
      class="d-flex justify-content-center align-items-start content-wrapper">
      <div class="d-flex flex-column justify-content-center align-items-center">

        <app-report-type></app-report-type>

      </div>
    </div>

    <!-- Threat Actor 2 -->
    <div *ngIf="menuSelection === 'threat-actor-2'"
      class="d-flex justify-content-center align-items-start content-wrapper">
      <div class="d-flex flex-column justify-content-center align-items-center">

        <app-threat-actor #threatActor></app-threat-actor>

      </div>
    </div>


    <div class="d-flex flex-column justify-content-center align-items-center w-100 mt-3">
      <div class="bottom-wrapper">
        <div class="assist-wrapper d-flex">
          <div class="d-flex align-items-center justify-content-between" style="width: 65%; margin-left: 3%">
            <button *ngIf="showContinueAnalyst1()" [disabled]="!enableAddToCart()"
              class="btn btn-100 btn-outline-primary pe-4 ps-4 pt-2 pb-2 ms-4 query-button" (click)="addToCart()">
              <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
              Add
            </button>
            <span *ngIf="analyst1ObjectsInCart?.length > 0 && showContinueAnalyst1()"
              style="position: absolute; left: 680px">
              Indicators Added: </span>
            <span *ngIf="analyst1ObjectsInCart?.length > 0 && showContinueAnalyst1()"
              style="color: white; position: absolute; left: 810px" class="badge rounded-circle bg-primary sdo-badge">
              {{ analyst1ObjectsInCart?.length }}
            </span>
            <div
              *ngIf="menuSelection === 'additional-info-2' && (indicatorSelection === 'add-evidence' || indicatorSelection === 'add-ioc') && !addingStixToPending"
              class="dropup">
              <button class="btn btn-100 btn-success dropdown-toggle query-button" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <fa-icon [icon]="faSelect" class="pe-1"></fa-icon>
                <!-- Add To Bundle -->
                {{ showAddAnalyst1Type() }}
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="selectAddToBundleType('indicator')">Add Indicator Only</a>
                <a class="dropdown-item" (click)="selectAddToBundleType('parsed')">Add Parsed Only</a>
                <a class="dropdown-item" (click)="selectAddToBundleType('both')">Add Indicator + Parsed</a>
              </div>
            </div>

            <span *ngIf="menuSelection === 'build-review' && evidenceId && showViewEvidenceOnAnalyst1()"
              style="margin-left: 60%">
              <a class="btn-link pointer view-evidence-button" (click)="openAnalyst1('view-evidence')">View Evidence on
                Analyst1</a>
            </span>
            <span
              *ngIf="menuSelection === 'indicator-type-2' && indicatorSelection === 'add-evidence' && activeTabAnalyst1EvidenceType === 'Evidence ID'"
              class="ms-4">
              <a class="btn-link pointer" (click)="findEvidenceIdModal()">Where's My Evidence ID?</a>
            </span>

            <div *ngIf="menuSelection === 'tlp-type'" class="d-flex flex-column mb-3">
              <div *ngIf="reportObject && reportTLP" class="ms-2">
                <strong style="display: inline-block; width: 150px">Report Object:</strong>
                <span class="me-2 badge" [class]="stixService.getTlpStyle(reportTLP?.key)"
                  style="width: 135px">{{reportTLP?.key}}</span>
              </div>
              <div *ngIf="reportObject && !reportTLP" class="ms-2"><strong
                  style="display: inline-block; width: 150px">Report Object:</strong> Unassigned</div>
              <div>
                <span class="ms-2" style="display: inline-block; width: 164px"><strong>Unassigned:</strong></span>
                <span class="badge rounded-circle bg-primary sdo-badge ms-2"
                  style="min-width: 1rem">{{unassignedObjMarkingRefObjs?.length}}</span>
              </div>
              <div *ngFor="let tlpCount of tlpCounts">
                <div class="me-2 badge" [class]="stixService.getTlpStyle(tlpCount.tlp.key)">{{tlpCount.tlp.key}}</div>
                <span class="badge rounded-circle bg-primary sdo-badge" style="min-width: 1rem" [class]="{'tlp-margin-left': tlpMargin(tlpCount.tlp) === 'other',
                  'tlp-margin-left-amber': tlpMargin(tlpCount.tlp) === 'amber',
                  'tlp-margin-left-strict': tlpMargin(tlpCount.tlp) === 'strict',
                  'tlp-margin-left-red': tlpMargin(tlpCount.tlp) === 'red'}">
                  {{tlpCount.count}}
                </span>
              </div>
            </div>

            <div *ngIf="menuSelection === 'publish-success'" class="d-flex justify-content-center align-items-center"
              style="margin-left: 0%">
              <div *ngIf="publishFeedback?.successes?.length > 0" class="col-10 publish-status-wrapper">
                <div class="d-flex justify-content-center align-items-center text-success mb-2 pointer"
                  (click)="expandSuccess = !expandSuccess; expandFailure = false; expandAdvancedDetails = false;">
                  <strong style="font-size: 1.5rem"><u>Published</u></strong>
                  <span
                    class="badge rounded-circle bg-primary sdo-badge ms-1">{{publishFeedback?.successes?.length}}</span>
                </div>
                <div *ngIf="expandSuccess" class="publish-items-wrapper">
                  <ul *ngFor="let successObj of publishFeedback?.successes" class="list-group publish-item">
                    <li class="list-group-item">{{ getObjectNameFromPublish(successObj) }}</li>
                  </ul>
                </div>
              </div>
              <div *ngIf="publishFeedback?.failures?.length > 0" style="margin-left: -20%"
                class="col-10 publish-status-wrapper">
                <div class="d-flex justify-content-center align-items-center text-danger mb-2 pointer"
                  (click)="expandFailure = !expandFailure; expandSuccess = false; expandAdvancedDetails= false;"><strong
                    style="font-size: 1.5rem"><u>Not Published</u></strong>
                  <span class="badge rounded-circle bg-danger sdo-badge ms-1">{{publishFeedback?.failures?.length}}</span>
                </div>
                <div *ngIf="expandFailure" class="publish-items-wrapper">
                  <ul *ngFor="let failureObj of publishFeedback?.failures" class="list-group publish-item">
                    <li class="list-group-item">
                      <div>{{ getObjectNameFromPublish(failureObj) }}</div>
                      <div class="btn-danger">{{ failureObj.message }}</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex col-8 pointer" style="position: absolute; left: 49%; width: auto"
                (click)="expandAdvancedDetails = !expandAdvancedDetails; expandSuccess = false; expandFailure = false;">
                <span class="btn-link"><strong style="font-size: 1.5rem"><u>Publication Details</u></strong></span>
                <div *ngIf="expandAdvancedDetails" class="col-11 publish-items-wrapper list-group-item"
                  [style.max-width]="advancedDetailWidth()" [style.width]="advancedDetailWidth()">
                  <pre>{{ this.publishFeedback | json }}</pre>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="showNeedAssistance()" class="d-flex justify-content-end align-items-center"
            style="width: 50%; margin-right: 3%">
            <!-- <fa-icon *ngIf="!(menuSelection === 'publish-success' && publishFeedback?.failures?.length > 0)"
              class="question-circle" [icon]="faQuestionCircle" (click)="showAssistance()">
            </fa-icon> -->
            <fa-icon *ngIf="menuSelection === 'publish-success' && publishFeedback?.failures?.length > 0"
              [class]="detectedErrorClass()" class="question-circle" [icon]="faQuestionCircle"
              (click)="appRoot.openSupportModal(supportModal, 'Issue')">
            </fa-icon>
            <div class="d-flex justify-content-center align-items-center assist-text">
              <!-- <div *ngIf="!(menuSelection === 'publish-success' && publishFeedback?.failures?.length > 0)">Need assistance?<br>Let us help.</div> -->
              <div *ngIf="menuSelection === 'publish-success' && publishFeedback?.failures?.length > 0"
                style="font-weight: 700" [class]="detectedErrorClass()">Report Error.<br>Let us help.</div>
            </div>
          </div>
        </div>
        <div class="bottom-nav-wrapper mt-3">
          <div class="d-flex justify-content-between align-items-center">
            <button class="btn btn-100 btn-secondary ms-3"
              *ngIf="menuSelection != 'publish-polling' && menuSelection != 'build-review' && this.guidedService.currentCartIndex === null"
              [ngClass]="{ 'placeholder-hidden' : menuSelection === 'indicator-type-1' }" (click)="goBack()"
              style="font-weight: 700">
              <fa-icon [icon]="faArrowLeft" class="pe-1"></fa-icon>
              Back
            </button>
            <button class="btn btn-100 btn-secondary ms-3"
              *ngIf="this.guidedService.currentCartIndex !== null && menuSelection !== 'build-review' && menuSelection !== 'publish-review' && menuSelection !== 'publish-success'"
              [ngClass]="{ 'placeholder-hidden' : menuSelection === 'indicator-type-1' }"
              (click)="cancelEditFromBundle()" style="font-weight: 700">
              <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
              Cancel
            </button>
            <button *ngIf="menuSelection === 'build-review'" type="button" class="btn btn-md btn-secondary"
              (click)="goBack()">
              <fa-icon [icon]="faArrowLeft"></fa-icon>
            </button>

            <div *ngIf="menuSelection === 'build-review'" class="dropup">
              <button class="btn btn-100 btn-success me-3 dropdown-toggle query-button" type="button"
                style="font-weight: 700;" data-bs-toggle="dropdown" aria-expanded="false">
                <fa-icon [icon]="faPen" class="pe-1"></fa-icon>
                Bundle Management
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="guidedBundle.saveBundleModal(saveModal, true)">Save Bundle</a>
                <a class="dropdown-item" (click)="guidedBundle.loadBundle(loadModal)">Load Saved Bundle</a>
                <a class="dropdown-item" (click)="guidedBundle.downloadBundle(true)">Download Bundle</a>
                <a class="dropdown-item" (click)="guidedBundle.uploadFile(uploadFile)">Upload Bundle</a>
              </div>
            </div>

            <button *ngIf="menuSelection === 'build-review'" type="button" class="btn btn-warning me-3 btn-100"
              style="height: 30px" (click)="guidedBundle.discardBundleHandler()"
              [disabled]="guidedBundle.bundle.objects.length === 0">
              <fa-icon [icon]="faBackspace" class="pe-1"></fa-icon>
              Discard Bundle
            </button>

            <button *ngIf="showAddObject()" class="btn btn-100 btn-success me-3" (click)="addObject()"
              style="font-weight: 700">
              <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
              Add More CTI
            </button>

            <button *ngIf="menuSelection === 'publish-success'" class="btn btn-100 btn-success me-3"
              (click)="createAnotherBundle('no'); menuSelection = 'build-review'" style="font-weight: 700">
              <fa-icon [icon]="faFile" class="pe-1"></fa-icon>
              Current Bundle
            </button>

            <button *ngIf="menuSelection === 'how-ttp'" class="btn btn-100 btn-primary me-3" style="font-weight: 700"
              (click)="openDeciderTool()">
              <fa-icon [icon]="faTools" class="pe-1"></fa-icon>
              Decider Tool
            </button>

            <!-- <button *ngIf="menuSelection === 'how-pattern-builder'" class="btn btn-100 btn-primary me-3" style="font-weight: 700" (click)="openPatternBuilder()">
            <fa-icon [icon]="faTools" class="pe-1"></fa-icon>
            Pattern Builder
          </button> -->

            <button
              *ngIf="showContinue() && menuSelection !== 'build-review' && menuSelection !== 'publish-review' && menuSelection !== 'publish-success' && this.guidedService.currentCartIndex === null"
              class="btn btn-100 btn-primary me-3" (click)="goForward()" style="font-weight: 700"
              [disabled]="disableContinue()">
              Continue
              <fa-icon [icon]="faArrowRight" class="ps-1"></fa-icon>
            </button>

            <button
              *ngIf="showContinue() && this.guidedService.currentCartIndex !== null && menuSelection !== 'build-review' && menuSelection !== 'publish-review' && menuSelection !== 'publish-success'"
              class="btn btn-100 btn-primary me-3" (click)="editFromBundle()" style="font-weight: 700"
              [disabled]="disableContinue()">
              <fa-icon [icon]="faEdit" class="pe-1"></fa-icon>
              Update
            </button>


            <button *ngIf="menuSelection === 'additional-info-3'" class="btn btn-100 btn-primary me-3"
              (click)="goForward()" style="font-weight: 700">
              Skip To Publication
              <fa-icon [icon]="faArrowRight" class="ps-1"></fa-icon>
            </button>

            <button *ngIf="menuSelection === 'build-review'" type="button" class="btn btn-md btn-secondary"
              (click)="goForward()">
              <fa-icon [icon]="faArrowRight"></fa-icon>
            </button>

            <button *ngIf="showContinue() && menuSelection === 'publish-review'" class="btn btn-100 btn-success me-3"
              (click)="goForward()" style="font-weight: 700" [disabled]="disableContinue()">
              <fa-icon [icon]="faUpload" class="pe-1"></fa-icon>
              Publish
            </button>

            <button *ngIf="showContinueAnalyst1()" [disabled]="!enableReview()" class="btn btn-100 btn-primary me-3"
              (click)="viewDetail(); goForward();" style="font-weight: 700">
              <fa-icon [icon]="faArrowRight" class="pe-1"></fa-icon>
              Continue
              <!-- <span *ngIf="analyst1ObjectsInCart?.length > 0" class="badge rounded-circle bg-primary sdo-badge">{{ analyst1ObjectsInCart?.length }}</span> -->
            </button>

            <button *ngIf="showContinue() && menuSelection === 'publish-success'" class="btn btn-100 btn-primary me-3"
              (click)="createAnotherBundle('yes'); goForward()" style="font-weight: 700">
              <fa-icon [icon]="faUndo" class="pe-1"></fa-icon>
              New Bundle
            </button>
            <!-- <button *ngIf="addingStixToPending && showContinueAnalyst1()" type="button" disabled
                class="btn btn-lg btn-primary me-3">
                <span class="spinner-grow spinner-grow-sm" role="status"
                    aria-hidden="true"></span>
                <span class="sr-only">Importing...</span>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="errorMessages?.length > 0" class="row error-message">
    <div class="col">
      <div class="d-flex justify-content-center flex-column">
        <div *ngFor="let err of errorMessages;" class="alert alert-danger" role="alert">
          {{ err }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="popupMsgs?.length > 0" class="row error-message">
    <div class="col">
      <div class="d-flex justify-content-center flex-column">
        <div *ngFor="let msg of popupMsgs;" class="alert alert-primary" role="alert">
          {{ msg }}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #supportModal let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-title">
      {{appRoot.supportLabel}}
    </h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"></span>
    </button>
  </div>

  <div class="modal-body" id="changelog">
    <div class="row">
      <div class="col">
        <form>
          <div class="form-group">
            <label for="supportDescription">Description</label>
            <textarea class="form-control" name="description" id="supportDescription" rows="3"
              [(ngModel)]="appRoot.supportRequest.description"></textarea>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-md btn-secondary" (click)="modal.dismiss()">
      <fa-icon [icon]="faBan"></fa-icon>
      Cancel
    </button>
    <button type="button" class="btn btn-md btn-success" [disabled]="appRoot.isSupportSubmitDisabled()"
      (click)="appRoot.submitSupportRequest()">
      <fa-icon [icon]="faPaperPlane"></fa-icon>
      Submit
      <!-- <div *ngIf="support.loading" class="spinner-grow text-light announcement-download-icon" role="status"></div> -->
    </button>

  </div>
</ng-template>

<ng-template #saveModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="save-modal-title">
      <span *ngIf="guidedBundle.importingObjects && !guidedBundle.savingBundle">Import STIX Objects</span>
      <span *ngIf="!guidedBundle.importingObjects && !guidedBundle.isReset">Save STIX Bundle</span>
      <span *ngIf="!guidedBundle.importingObjects && !guidedBundle.savingBundle && guidedBundle.isReset">Reset STIX
        Objects</span>
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"></span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!guidedBundle.savingBundle && !guidedBundle.isReset && !guidedBundle.importingObjects" class="pt-2">
      <app-saved-bundles-table class="w-100" [mode]="'saving'"
        (bundleStatus)="guidedBundle.updateSaveModal($event)"></app-saved-bundles-table>
    </div>
    <div *ngIf="guidedBundle.savingBundle || guidedBundle.isReset || guidedBundle.importingObjects" class="pt-2">
      <ng-container *ngIf="guidedBundle.importingObjects && !guidedBundle.savingBundle">
        Your current bundle is displayed below. Choose how you would like to handle the existing
        objects:<br>
        (1) Discard the bundle entirely, (2) Merge the objects you are importing into the current
        bundle, or (3) Save the current bundle for later use.
        <br>
      </ng-container>
      <ng-container *ngIf="guidedBundle.savingBundle">
        <div class="row ps-3 pb-1">
          If you would like to save your bundle, please enter a name below. If you would instead like to
          discard/reset the bundle please confirm so.
        </div>
        <div class="row ms-0 input-border">
          <input placeholder="Enter a name for the bundle" class="form-control"
            style="font-size: x-large; font-weight: 400;" type="text" [disabled]="true"
            [(ngModel)]="guidedBundle.savingModalData.name">
        </div>
      </ng-container>
      <ng-container *ngIf="!guidedBundle.importingObjects && !guidedBundle.savingBundle">
        Your current bundle is displayed below. Confirm you would like to discard the bundle to reset.<br>
      </ng-container>
      <br>
      <ul class="nav nav-tabs">
        <li class="nav-item" (click)="guidedBundle.activeTab = 'jsonViewer'">
          <a class="nav-link active" [ngClass]="{'active': guidedBundle.activeTab === 'jsonViewer'}">JSON</a>
        </li>
        <!-- <li class="nav-item" (click)="guidedBundle.activeTab = 'visualViewer'">
                <a class="nav-link" [ngClass]="{'active': guidedBundle.activeTab === 'visualViewer'}">Visual</a>
            </li> -->
      </ul>
      <pre *ngIf="guidedBundle.activeTab === 'jsonViewer'"
        class="preview-bundle">{{ guidedBundle.getStixPreview() | json }}</pre>
      <app-stix-viewer *ngIf="guidedBundle.activeTab === 'visualViewer'" class="nav-link"
        [bundle]="guidedBundle.getStixPreview()">
      </app-stix-viewer>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close(false)">
      <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
      Cancel
    </button>
    <button *ngIf="guidedBundle.savingBundle" type="button" class="btn btn-sm btn-secondary"
      (click)="guidedBundle.savingBundle = false">
      <fa-icon [icon]="faArrowLeft" class="pe-1"></fa-icon>
      Back
    </button>
    <!-- <button type="button" class="btn btn-sm btn-secondary" data-bs-toggle="tooltip" data-placement="top"
          title="Open this visualization in a new tab so that you can use your browser's full width to view your bundle"
          (click)="guidedBundle.routeToNewTab('stix-viewer')">
          Visualize Bundle
      </button> -->
    <ng-container *ngIf="guidedBundle.importingObjects && !guidedBundle.savingBundle">
      <button type="button" class="btn btn-sm btn-warning" (click)="guidedBundle.discardBundle();">
        <fa-icon [icon]="faBackspace" class="pe-1"></fa-icon>
        Discard Bundle
      </button>
      <button type="button" class="btn btn-sm btn-outline-secondary"
        (click)="guidedBundle.mergeBundle(stixService.objectsToLoad)">
        <fa-icon [icon]="faMerge" class="pe-1"></fa-icon>
        Merge into Bundle
      </button>
    </ng-container>
    <ng-container *ngIf="guidedBundle.savingBundle">
      <button *ngIf="guidedBundle.savingModalData.destination == 'local' && guidedBundle.savingModalData.isNew == false"
        type="button" class="btn btn-sm btn-success" (click)="guidedBundle.overwriteBundle('local')">
        <fa-icon [icon]="faSave" class="pe-1"></fa-icon>
        Locally
      </button>
      <button *ngIf="guidedBundle.savingModalData.destination == 'local' && guidedBundle.savingModalData.isNew == true"
        type="button" class="btn btn-sm btn-success" (click)="guidedBundle.saveBundle('local')">
        <fa-icon [icon]="faSave" class="pe-1"></fa-icon>
        Locally
      </button>
      <button
        *ngIf="guidedBundle.savingModalData.destination == 'profile' && guidedBundle.savingModalData.isNew == true"
        type="button" class="btn btn-sm btn-success" (click)="guidedBundle.saveBundle('profile')">
        <fa-icon [icon]="faSave" class="pe-1"></fa-icon>
        Profile
      </button>
      <button
        *ngIf="guidedBundle.savingModalData.destination == 'profile' && guidedBundle.savingModalData.isNew == false"
        type="button" class="btn btn-sm btn-success" (click)="guidedBundle.overwriteBundle('profile')">
        <fa-icon [icon]="faSave" class="pe-1"></fa-icon>
        Profile
      </button>
    </ng-container>
    <ng-container *ngIf="!guidedBundle.importingObjects && !guidedBundle.savingBundle && guidedBundle.isReset">
      <button type="button" class="btn btn-sm btn-warning" (click)="guidedBundle.discardBundle();">
        <fa-icon [icon]="faBackspace" class="pe-1"></fa-icon>
        Discard Bundle
      </button>
    </ng-container>
  </div>
</ng-template>


<ng-template #loadModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="load-modal-title">
      Load STIX Bundle
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"></span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!guidedBundle.loadingBundle" class="pt-2">
      <app-saved-bundles-table class="w-100" [mode]="'loading'"
        (bundleStatus)="guidedBundle.updateLoadModal($event)"></app-saved-bundles-table>
    </div>
    <div *ngIf="guidedBundle.loadingBundle" class="pt-2">
      Selected Bundle:
      <select class="col-4 form-control form-select" style="font-size: large; font-weight: 500;" type="text"
        [(ngModel)]="guidedBundle.selectedBundle" [disabled]="true">
        <option *ngFor="let bundle of this.stixService.savedBundles" [value]="bundle.name">{{bundle.name}}
        </option>
      </select>
      <br>
      <ul class="nav nav-tabs">
        <li class="nav-item" (click)="guidedBundle.activeTab = 'jsonViewer'">
          <a class="nav-link active" [ngClass]="{'active': guidedBundle.activeTab === 'jsonViewer'}">JSON</a>
        </li>
        <!-- <li class="nav-item" (click)="activeTab = 'visualViewer'">
                <a class="nav-link" [ngClass]="{'active': activeTab === 'visualViewer'}">Visual</a>
            </li> -->
      </ul>
      <pre *ngIf="guidedBundle.activeTab === 'jsonViewer'"
        class="preview-bundle ">{{ guidedBundle.getStixPreview() | json }}</pre>
      <!-- <app-stix-viewer *ngIf="activeTab === 'visualViewer'" class="nav-link" [bundle]="guidedBundle.getStixPreview()">
        </app-stix-viewer> -->
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close(false)">
      <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
      Cancel
    </button>
    <button *ngIf="false" type="button" class="btn btn-sm btn-secondary" data-bs-toggle="tooltip" data-placement="top"
      title="Open this visualization in a new tab so that you can use your browser's full width to view your bundle"
      (click)="guidedBundle.routeToNewTab('stix-viewer')">
      <fa-icon [icon]="faVisualize" class="pe-1"></fa-icon>
      Visualize Bundle
    </button>
    <button type="button" class="btn btn-sm btn-success" (click)="guidedBundle.sendBundle();"
      [disabled]="guidedBundle.selectedBundle == ''">
      <fa-icon [icon]="faFileDownload" class="pe-1"></fa-icon>
      Load Bundle
    </button>
  </div>
</ng-template>


<ng-template #uploadFile let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="load-modal-title">
      Upload File
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"></span>
    </button>
  </div>
  <div class="modal-body">
    <h6>Select File(s) to Upload Below</h6>
    <div class="row justify-content-start">
      <div class="col-2">
        <input type="file" style="display: block" [(ngModel)]="guidedBundle.selectedFile" class="bottom-bump"
          accept=".json" id="file1" name="upload" (change)="guidedBundle.onFileChanged($event)" multiple>
      </div>
      <!-- <ng-container *ngIf="this.stixService.savedBundles.length != 0">
          <div class="col-2" style="padding-left: 4.5%">OR</div>
          <button class="btn btn-secondary pe-4 ps-4 pt-1 pb-1 query-button" (click)="guidedBundle.loadBundle(loadModal)">
              Load Saved Bundle
          </button>
      </ng-container> -->
    </div>
    <div class="row justify-content-start">
      <div class="col-2">
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="guidedBundle.uploadBundle();"
          [disabled]="guidedBundle.selectedFile == undefined">
          <fa-icon [icon]="faFileUpload" class="pe-1"></fa-icon>
          Upload
        </button>
      </div>
    </div>


  </div>
</ng-template>