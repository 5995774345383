import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, ChangeDetectorRef, Inject, HostListener, TemplateRef } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Bundle } from '../models/bundle';
import { StixService } from '../stix-service.service';
import { STIX_OBJECTS } from "../models/stix-objects";
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnnouncementService } from '../announcement-service/announcement-service.service';
import { Content } from '../models/content';
import { result } from 'validate.js';
import all_schemas from './schemas.json';
import { STIX_OBJECTS_LIST } from "../models/stix-objects";
import { ACS_OBJECTS } from '../models/acs-objects';
import { MatDialog } from '@angular/material/dialog';
import { RelationshipDialogComponent } from '../query-stix/relationship-dialog/relationship-dialog/relationship-dialog.component';
import { faEdit, faTrash, faSave, faDownload, faUndo, faUpload, faPlus, faMinus, faPlusSquare, faLink, faFileDownload, faFileUpload, faAngleDoubleUp, faInfoCircle, faAngleDoubleDown, faFileImport, faBan, faBackspace, faRandom, faArrowLeft, faArrowRight, faEye, faSearch, faProjectDiagram, faCheck, faPaste } from '@fortawesome/free-solid-svg-icons';
// import { QueryStixComponent } from '../query-stix/query-stix.component';
import { Analyst1DialogComponent } from '../dialogs/analyst1-dialog/analyst1-dialog.component';
import { MessageDialogComponent } from '../dialogs/message-dialog/message-dialog.component';
import { CopyPasteDialogComponent } from '../dialogs/copy-paste-dialog/copy-paste-dialog.component';
import { GuidedService } from '../guided.service';

import { v4 as uuid } from "uuid";
import { FluentdService } from '../fluentd/fluentd.service';
import { TLP20_OPTIONS, TLP_OPTIONS } from '../tlpMarkingDef';
import { SavedBundlesService } from '../saved-bundles/saved-bundles-service/saved-bundles.service';
import { SubmissionsService } from '../submissions-service/submissions.service';
import { ObjectsViewerComponent } from '../objects-viewer/objects-viewer.component';
import { AccessPrivilege, AcsConfig, FurtherSharing, ListProperties, PrivilegeScope, acs_enums } from '../dialogs/analyst1-dialog/acs-types';

declare var require: any;
const Validator = require('jsonschema').Validator;
const Minify = require('node-json-minify');

@Component({
    selector: 'app-bundle',
    templateUrl: './bundle.component.html',
    styleUrls: ['./bundle.component.css']
})
export class BundleComponent implements OnInit {
    @Input() showObjectSection: any = true;
    @Input() showVisualizerSection: any = true;
    @Input() showBundleObjectsSectionOnly: any = false;
    @Input() showBundleSection: any = true;
    @Input() menuSelection: any = null;
    @Input() isGroupByTLP: any = false;
    @ViewChild('stixModal') stixModal!: TemplateRef<any>;

    // Original A1 data import approach: Code for reusing query stix component
    // @ViewChild("queryStix", { static: true }) queryStix: QueryStixComponent;

    faEdit = faEdit;
    faInfoCircle = faInfoCircle;
    faTrash = faTrash;
    faAngleDoubleDown = faAngleDoubleDown;
    faAngleDoubleUp = faAngleDoubleUp;
    faAddToBundle = faFileImport;
    faSave = faSave;
    faDownload = faDownload;
    faUndo = faUndo;
    faUpload = faUpload;
    faPlus = faPlus;
    faMinus = faMinus;
    faPlusSquare = faPlusSquare;
    faLink = faLink;
    faFileDownload = faFileDownload;
    faFileUpload = faFileUpload;
    faBan = faBan;
    faBackspace = faBackspace;
    faMerge = faRandom;
    faArrowLeft = faArrowLeft;
    faArrowRight = faArrowRight;
    faEye = faEye;
    faSearch = faSearch;
    faProjectDiagram = faProjectDiagram;
    faCheck = faCheck;
    faPaste = faPaste;

    bundleCopy;
    bundle: Bundle;
    categorizedBundle: any;
    isDiscarding: boolean = false;
    isMerging: boolean = false;
    isPublishing: boolean = false;
    isUploading: boolean = false;
    isResetNeeded: boolean = false;
    loadingApiRoots: boolean = false;
    loadingStix: boolean = false;
    creatingObject: boolean = true;
    showingBundle: boolean = true;
    showingVisualizer: boolean = false;
    showingAnalyst1: boolean = false;
    prevShowingVisualizer: boolean = false;
    modalCurrentStep = 1; // Current step the modal is on prior to publishing
    modalStepCount = 3;    // Max steps before publishing - corresponds to how many sections there are in the publish modal
    stixObjects = STIX_OBJECTS;
    taxiiServer = {
        url: this.stixService.taxiiServer.url,
        username: this.stixService.taxiiServer.username,
        password: this.stixService.taxiiServer.password,
        certificate: null,
        apiRoot: '',
        childRoot: '',
        availableCollections: [],
        collection: null
    };
    taxiiServerAuthType = this.stixService.taxiiServerAuthType;
    taxiiServerType = this.stixService.taxiiServerType;
    required = [];

    searchText: string = '';
    searchTextAnalyst1: string = '';
    prevSearch: string = '';
    searchResults: string = '';
    searchIndex: number = 0;
    searchMatches: Array<string> = [];
    rownumber: number = -1;
    objnumber: number = -1;

    apiRootProcessingError = '';
    apiRootProcessingErrorURL = '';
    aisUrl = environment.aisTaxiiProxy.url;
    selectedAPIRoot = null;
    APIRoots = [];
    noResults = true;
    selectedCollection;
    apiRoots = [];
    collections = [];
    taxiiServerBaseUrl = '';
    // activeTab = 'visualViewer';
    activeTab = 'jsonViewer';
    customObjects: any[] = [];  //Derek - I think this is deprecated
    objectRegex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);

    upload: any = [];
    bad_uploads: any[] = [];
    selectedFile;
    importingObjects: boolean = false;
    savingBundle: boolean = false;
    loadingBundle: boolean = false;
    deletedCISAIdentity: boolean = false;
    bundleName: string = '';
    invalidName: string = '';
    selectedBundle: string = '';
    selectedBundle2: string = '';
    savedBundleDict: any = {};
    isSavingBundle = false;

    selectedObjects: any[] = [];
    objectsQueryResults = {}

    guidedUI: boolean;

    analyst1Objects: any = [];
    analyst1TotalPages: number = 0;
    analyst1currentPage: number = 0;
    loadingStixNext: boolean = false;
    addingStixToBundle: boolean = false;
    publishA1: boolean = false;
    taxiiServerUrl: string = '';    // This is global purely for the guided ui - can definitely be fixed

    page: Map<string, number>;
    customPage: number = 1;

    acsMarkingOptions = ACS_OBJECTS;

    modalRef: any;

    unassignedObjMarkingRefObjs = null;
    tlpOptions = [];
    tlpCounts = [];
    
    elem: any;
    fullScreenMode: boolean = false;

    viewingSavedBundles: boolean = false;
    savedBundleResults: String = '';
    bundleRowNumber: number = -1;

    savingModalData: any = {};
    loadingModalData: any = {};
    isReset: boolean = false;

    events: any = [];
    impacts: any = [];
    tasks: any = [];

    newVersionObjectsLoad = [];
    loadedBundleName: string = '';

    duplicates: any = [];
    references: any = [];
    environment = environment;
    showDuplicatedObjectToast = false;

    expandedObjectTypes = [];

    objectsExpanded = false;

    //ACS Marking
    acs_enums = acs_enums;
    acsIsValid = false;
    acsErrors = {
        classification: "Classification must be filled",
        formal_determination: "Formal Determination must be selected",
        usage_permission: 'Usage Permission must be selected',
        further_sharing: 'Further Sharing must be selected'
    };

    access_privilege_collapse = [];
    further_sharing_collapse = [];

    enableObj = {
        access_privilege: false,
        further_sharing: false
    }
    enableAddValue = {
        entity_list: false,
        permitted_nationalities_list: false,
        permitted_organizations_list: false,
        shareability_list: false,
        sharing_scope_list: false
    }
    acsMarkingConfig: AcsConfig = {
    classification: "",
    formal_determination: "",
    usage_permissions: "",
    privilege_action: "",
    usage_rule_effect: "",
    entity: "",
    entity_list: [],
    permitted_nationalities: "",
    permitted_nationalities_list: [],
    permitted_organizations: "",
    permitted_organizations_list: [],
    shareability: "",
    shareability_list: [],
    further_sharing: "",
    sharing_scope: "",
    sharing_scope_list: [],
    further_rule_effect: "",
    access_privilege_list: [],
    further_sharing_list: []
    }
    configModalRef: NgbModalRef;
    defaultStixConfig = {
        aisTlp: "",
        orgCountry: "US",
        orgAdmin: "US-DC",
        acsMarkings: {}
      };
    tempStixConfig = JSON.parse(JSON.stringify(this.defaultStixConfig));
    stixConfig = JSON.parse(JSON.stringify(this.defaultStixConfig));
    saveConfig = false;
    stixConfigSaved = false;

    @HostListener('document:fullscreenchange', ['$event'])
    @HostListener('document:webkitfullscreenchange', ['$event'])
    @HostListener('document:mozfullscreenchange', ['$event'])
    @HostListener('document:MSFullscreenChange', ['$event'])
    fullscreen(event: KeyboardEvent){
        setTimeout(() => {
            this.fullScreenMode = !this.fullScreenMode;

            if (this.fullScreenMode) {
                this.stixService.sendData({type: 'full-screen', value: true});
            } else {
                this.stixService.sendData({type: 'full-screen', value: false});
            }
        }, 200)
    }

    private httpHeaders = new HttpHeaders()
        .set('Accept', 'application/taxii+json;version=2.1')
        .set('Authorization', `Basic ${btoa(environment.taxiiServer.username + ":" + environment.taxiiServer.password)}`);

    private httpHeadersJSON = new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${btoa(environment.taxiiServer.username + ":" + environment.taxiiServer.password)}`);

    constructor(
        private announcementService: AnnouncementService,
        private modalService: NgbModal,
        public stixService: StixService,
        private router: Router,
        private httpClient: HttpClient,
        public relationshipDialog: MatDialog,
        private fluentd: FluentdService,
        private changeDetector: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: any,
        private savedBundlesService: SavedBundlesService,
        private submissions: SubmissionsService, 
        public copyPasteDialog: MatDialog,
        private guidedService: GuidedService
    ) {
        //this.stixService.convertLanguageContentsToObject();
        //this.stixService.setLanguageContentsArray();
        this.bundle = this.stixService.bundle;
        this.duplicates = [];
        this.customObjects = this.stixService.getCustomObjects();
        if (this.stixService.added)
            this.parseDeletionQueue();
        this.stixService.added = false;
        this.stixService.deletionQueue = [];
        if (environment.taxiiServer.username === 'keycloak') {
            this.taxiiServerAuthType = 'keycloak';
            this.stixService.taxiiServerAuthType = 'keycloak';
        }

        this.guidedUI = this.stixService.guidedUI;

        if(stixService.acsObject){
            stixService.acsSelection = stixService.acsObject.type;
        } else {
            stixService.acsSelection = '';
        }

        console.log(history.state);
        if(history.state.bundle && history.state.bundle === 'clear'){
            this.clearBundle();
            this.addObjectsAsync(history.state.objects);
        }

        this.getSavedBundles();
    }


    ngOnInit(): void {
        if(environment.tlpConfig.enabledTlpVersions.length === 1){
            const tlpVersion = environment.tlpConfig.enabledTlpVersions[0].value;
            this.tlpOptions = this.stixService.getTlpOptions(tlpVersion);
            this.tlpOptions.unshift({key: 'UNASSIGNED', name: 'Unassigned', css: 'TLP-UNASSIGNED', short: 'UNASSIGNED'});
        }
        if (this.showBundleObjectsSectionOnly) {
            this.showBundleSection = false;
        }
        // Add CISA identity
        // if (environment.production) {  // For demo purpose added cisa identity to dev
        let ids = JSON.parse(JSON.stringify({ "arr": [] }));
        const existingIdentity = localStorage.getItem("identity");
        if (!existingIdentity)
            localStorage.setItem("identity", environment.cisaIdentity.id);
        
        let localStorageIdentities = localStorage.getItem("identities");
        if (localStorageIdentities) {
            try {
                ids = JSON.parse(localStorageIdentities);
            } catch (e) {
                console.error("Error parsing saved identities with message: ", e);
            }
        }
        const existingCISAIdentity = ids.arr.find(i => i.id === environment.cisaIdentity.id);
        if (!existingCISAIdentity) {
            ids.arr.push(environment.cisaIdentity);
            localStorage.setItem("identities", JSON.stringify(ids));
        }

        this.stixService.db.imxCache.toArray().then(result => {
            if (result.length < 1)
                this.stixService.addComponent(existingCISAIdentity);
        })
            //this.creatingObject = true;

        // this.stixService.imxServerCalls();

        if (this.stixService.taxiiServer.url.length === 0) {
            console.log("oops");
            this.serverSettingsChanged(this.stixService.taxiiServerType);
        }

        if (this.taxiiServerType === 'custom') {
            this.selectedAPIRoot = null;
        }

        //this.stixService.savedBundles = [];
        //localStorage.removeItem("saved-bundles");

        this.stixService.getData().subscribe(data => {
            if (data && data.type && data.type === 'loading-objects-complete') {
                this.loadingStix = false;
            }
        })

        this.getUnassignedObjects();
        this.countTLP();

        // test only
        // setTimeout(x => {
        //     this.editObject(this.customObjects[0], true)
        // }, 2000)

        this.page = new Map();
        
        for (let stixObject of this.stixObjects) {
            for (let object of stixObject.objects) {
                this.page.set(object.type, 1);
            }
        }

        this.elem = document.documentElement;
        this.pullCoreExtensionObjects();

        // add sorting observed-data by first_observed under request during customer meeting
        const byFirstObserved = (a, b) => {
            if (a['type'] !== 'observed-data' || b['type'] !== 'observed-data') {
                return 0;
            } else {
                a = new Date(a['first_observed']).getTime();
                b = new Date(b['first_observed']).getTime();
            }
            return a - b;
        }
        this.bundle.objects.sort(byFirstObserved);

        this.loadedBundleName = JSON.parse(localStorage.getItem("bundle-name"))?.bundleName;

        if (this.taxiiServerType !== 'custom')
            setTimeout(() => {
                this.checkDuplicates();
                this.checkReferences();
            }, 500);

        // test
        // setTimeout(() => {
        //     this.viewDuplicates(this.bundle.objects[5]);
        // }, 1500)
    }

    expandCollapse() {
        this.objectsExpanded = !this.objectsExpanded;
        
        if (this.objectsExpanded) {
            this.expandedObjectTypes = [];
        }

        this.bundle.objects.forEach(o => {
            this.toggleObjectTypeToShow(o.type)
        })

        this.stixService.customObjects.forEach(co => {
            this.toggleObjectTypeToShow('custom')
        })
    }

    toggleObjectTypeToShow(objectType, direct = false) {
        let foundObjectTypeIndex = this.expandedObjectTypes.findIndex(o => o === objectType);
        if (foundObjectTypeIndex >= 0 && (direct || !this.objectsExpanded)) {
            this.expandedObjectTypes.splice(foundObjectTypeIndex, 1);
        } else if (foundObjectTypeIndex < 0 && (direct || this.objectsExpanded)) {
            this.expandedObjectTypes.push(objectType);
        }
    }

    showObjectType(objectType) {
        return this.expandedObjectTypes.some(o => o === objectType);
    }

    checkDuplicates() {
        if (this.taxiiServerType === 'custom')
            return;

        this.duplicates = [];
        let originals = [];

        let allCheckDuplicates: Observable<any>[] = [];
        this.bundle.objects.forEach((o: any) => {
            let publishedObjects: any = localStorage.getItem('published-objects');
            let alreadyPublishedFound = false;
            
            if (publishedObjects) {
                publishedObjects = JSON.parse(publishedObjects);
                alreadyPublishedFound = publishedObjects.some(po => po.id === o.id);
            }

            if (!alreadyPublishedFound) {
                originals.push(o);
                let duplicated = this.stixService.checkDuplicates(o);

                if (duplicated) {
                    allCheckDuplicates.push(duplicated)
                }
            }
        })

        forkJoin(allCheckDuplicates).subscribe((data: any) => {
            data.forEach((d: any, i: number) => {
                if (d.body && d.body.founds && d.body.founds.length > 0) {
                    let dupsFound = [];
                    let rcPairs = [];
                    d.body.founds.forEach(o => {
                        dupsFound.push(o.object);
                        rcPairs.push(o.fcPairs);
                    })

                    this.duplicates.push({
                        // original: this.bundle.objects[i],
                        original: originals[i],
                        duplicated: dupsFound,
                        rcPairs: rcPairs,
                    })
                }
            })

            if (this.duplicates.length > 0) {
                this.showDuplicatedObjectToast = true;
                setTimeout(() => {
                    this.showDuplicatedObjectToast = false;
                }, 10000);
            }
        })
    }

    checkReferences() {
        this.references = [];

        let allCheckReferences: Observable<any>[] = [];
        let allReferencedInRelationship: Observable<any>[] = [];
        let allReferencedInRelationshipIndex: number[] = [];

        this.bundle.objects.forEach((o: any) => {
            let referenced = this.stixService.checkReferences(o);

            if (referenced) {
                allCheckReferences.push(referenced)
            }
        })

        forkJoin(allCheckReferences).subscribe((data: any) => {
            data.forEach((d: any, i: number) => {
                if (d.body && d.body.founds && d.body.founds.length > 0) {
                    let refsFound = [];
                    let rcPairs = [];
                    d.body.founds.forEach(o => {
                        refsFound.push(o.object);
                        rcPairs.push(o.fcPairs);

                        if (o.object.type === 'relationship') {
                            let objectId = null;

                            if (this.bundle.objects[i].id === o.object.source_ref) {
                                objectId = o.object.target_ref;
                            }

                            if (this.bundle.objects[i].id === o.object.target_ref) {
                                objectId = o.object.source_ref;
                            }

                            if (objectId) {
                                let referenced = this.stixService.checkId(objectId);

                                if (referenced) {
                                    allReferencedInRelationship.push(referenced);
                                    allReferencedInRelationshipIndex.push(i);
                                }
                            }
                        }
                    })

                    if (allReferencedInRelationship.length > 0) {
                        forkJoin(allReferencedInRelationship).subscribe((data: any) => {
                            data.forEach((d: any, i: number) => {
                                if (d.body && d.body.founds && d.body.founds.length > 0) {
                                    d.body.founds.forEach(o => {
                                        refsFound.push(o.object);
                                        rcPairs.push(o.fcPairs);
                                    })
                
                                    this.references.push({
                                        original: this.bundle.objects[allReferencedInRelationshipIndex[i]],
                                        referenced: refsFound,
                                        rcPairs: rcPairs,
                                    })
                                }
                            })
                        })
                    } else {
                        this.references.push({
                            original: this.bundle.objects[i],
                            referenced: refsFound,
                            rcPairs: rcPairs,
                        })
                    }
                }
            })
        })
    }

    isDuplicated(id) {
        return this.duplicates.some((d: any) => {
            return d.original.id === id
        });
    }

    isReferenced(id) {
        return this.references.some((d: any) => {
            return d.original.id === id
        });
    }

    ref(id) {
        let ref = this.references.find(r => r.original.id === id)
        console.log(JSON.stringify(ref))
        return ref;

    }

    @HostListener("click", ["$event"])
    viewDuplicates(event, component) {
        if (event.target.localName.includes('img')) {
            event.stopPropagation();

            let duplicates = this.duplicates.find((d: any) => 
                d.original.id === component.id
            )

            if (duplicates) {
                let duplicated = [...duplicates.duplicated];
                let rcPairs = [...duplicates.rcPairs];

                if (duplicated.length > 0) {
                    this.openObjectViewerModal(duplicated, rcPairs, 'Duplicated Objects', 'Existing similar objects across all roots and collections on the selected server.')
                }
            }
        }
    }

    viewReferences(event, component) {
        if (event.target.localName.includes('img')) {
            event.stopPropagation();

            let references = this.references.find((r: any) => 
                r.original.id === component.id
            )

            if (references) {
                let referenced = [...references.referenced];
                let rcPairs = [...references.rcPairs];

                // Commented out removal of current object in duplicate list to show other root/collection that has this object
                // let originalIndexInDuplicated = referenced.findIndex(r => r.id === component.id);
                // referenced.splice(originalIndexInDuplicated, 1);

                if (referenced.length > 0) {
                    this.openObjectViewerModal(referenced, rcPairs, 'Referencing Objects', 'Objects referencing this object across all roots and collections.')
                }
            }
        }
    }

    openObjectViewerModal(objects, rcPairs, title, description = null) {
        const modalRef = this.modalService.open(ObjectsViewerComponent, { size: 'xl' });
        modalRef.componentInstance.objects = objects;
        modalRef.componentInstance.rcPairs = rcPairs;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.description = description;

        modalRef.result.then((resp) => {
            switch (resp.type) {
                case 'add':
                    setTimeout(() => {
                        this.checkDuplicates();
                        this.checkReferences();
                    }, 500)
                    break;
                default:
                    break;
            }
        })
    }
    
    pullCoreExtensionObjects(): void {
        this.events = this.stixService.bundle.objects.filter(obj => obj["type"] == 'event');// && obj["extensions"]["extension-definition—-​4ca6de00-5b0d-45ef-a1dc-ea7279ea910e"]);
        this.impacts = this.stixService.bundle.objects.filter(obj => obj["type"] == 'impact');
        this.tasks = this.stixService.bundle.objects.filter(obj => obj["type"] == 'task');
        this.events = this.stixService.bundle.objects.filter(obj => obj["type"] == 'malware-behavior');
        this.impacts = this.stixService.bundle.objects.filter(obj => obj["type"] == 'malware-method');
        this.tasks = this.stixService.bundle.objects.filter(obj => obj["type"] == 'malware-objective');
    }


    private async getSavedBundles(){
        let res = await this.stixService.getSavedBundles(1, '');
        console.log(res);
        if(res === false){
            // this.announcementService.show('Failed to retrieve Saved Bundles', 'There was an error in communicating to the IMX Server', 'error', false);
        }
    }

    public changeShow(objIndex, rowIndex): void {
        if (objIndex == this.objnumber && rowIndex == this.rownumber) {
            this.rownumber = -1;
        } else {
            this.objnumber = objIndex;
            this.rownumber = rowIndex;
        }
    }

    importObjects(modal: any) {
        this.loadingBundle = false;
        this.stixService.hasObjectsToLoad = false;
        if (this.bundle.objects.length > 0) {
            this.importingObjects = true;
            this.modalService.dismissAll();
            this.activeTab = 'jsonViewer';
            this.modalService.open(modal, { ariaLabelledBy: 'save-modal-title', size: 'xl', windowClass: 'publish-modal' }).result.finally(() => {
                // Reset Values
                this.importingObjects = false;
                this.savingBundle = false;
                this.stixService.objectsToLoad = [];
                this.bundleName = '';
                this.invalidName = '';
                this.selectedBundle2 = '';
                this.isUploading = false;
                this.isDiscarding = false;   
                this.isMerging = false;
            });
        }
        else {
            this.addObjectsAsync(this.stixService.objectsToLoad);
        }

        this.changeDetector.detectChanges();
    }

    clearBundle(): void {
        this.stixService.clearBundle();
        this.bundle = this.stixService.bundle;
        this.bundle.id = `bundle--${uuid()}`;
        this.customObjects = [];
        this.isResetNeeded = false;
        this.stixService.addComponent(environment.cisaIdentity);
        this.deletedCISAIdentity = false;
        this.rownumber = -1;
        this.objnumber = -1;
        this.updateGroupByTLP();
        localStorage.setItem("acsIgnore", JSON.stringify([]));
        localStorage.setItem("reportsToNotTrack", JSON.stringify([]));

        if(this.guidedService){
            this.guidedService.cachedCart = [];
            localStorage.setItem('cached-cart', JSON.stringify(this.guidedService.cachedCart));
            this.guidedService.cart = {};
            localStorage.setItem('guided-cart', JSON.stringify(this.guidedService.cart));
            this.guidedService.editCart = {};
            localStorage.setItem('edit-cart', JSON.stringify(this.guidedService.editCart));
            this.guidedService.currentCartIndex = null;
            localStorage.setItem('cart-index', JSON.stringify(this.guidedService.currentCartIndex));

            this.guidedService.cartLengths = {"total": 0, "who-individual": 0, "how-event-detection": 0, "how-observable": 0,
                "how-pattern-builder": 0, "how-ttp": 0, "how-cwe": 0, "what-impact": 0, "where-location": 0};
        }
    }

    resetBundle(modal: any): void {
        localStorage.removeItem('published-objects');
        localStorage.setItem("acsIgnore", JSON.stringify([]));
        localStorage.setItem("reportsToNotTrack", JSON.stringify([]));
        this.isReset = true;
        this.savingBundle = false;
        this.loadingBundle = false;
        this.showingVisualizer = false;
        this.newVersionObjectsLoad = [];
        this.stixService.newVersionObjects = [];
        this.modalService.dismissAll();
        this.activeTab = 'jsonViewer';
        this.modalService.open(modal, { ariaLabelledBy: 'save-modal-title', size: 'xl', windowClass: 'publish-modal' });
    }

    saveBundleModal(modal: any, clearCallback: boolean): void {
        this.isReset = false;
        if(clearCallback) this.stixService.tempStatusCallback = undefined;
        console.log('modal');
        this.showingVisualizer = false;
        this.modalService.dismissAll();
        this.activeTab = 'jsonViewer';
        this.savingBundle = false;
        this.loadingBundle = false;
        this.modalService.open(modal, { ariaLabelledBy: 'save-modal-title', size: 'xl', windowClass: 'publish-modal' });
    }

    categorizeBundle(): void{
        if (!this.categorizedBundle) {
            for (let stixObject of this.stixObjects) {
                for (let object of stixObject.objects) {
                    this.categorizedBundle[object.type] = { page: 1, objects: [] }
                }
            }
        }

        for (let component of this.bundle.objects) {

        }
    }

    dismissModal(): void {
        this.modalService.dismissAll();
    }

    removeBundleName(): void {
        localStorage.removeItem("bundle-name");
        this.loadedBundleName = '';
    }

    discardBundle(): void {
        this.isDiscarding = true;
        setTimeout(async () => {
            try {
                this.clearBundle();
                await this.addObjectsAsync(this.stixService.objectsToLoad);
                // await this.addCustomObjAsync();

                if (this.stixService.uploadErrors) {
                    this.announcementService.show('Errors encountered while uploading', this.stixService.uploadErrors, 'upload-error', false);
                }
                this.updateGroupByTLP();
                this.loadVersioning();

                setTimeout(() => {
                    this.checkDuplicates();
                    this.checkReferences();
                }, 500)
            } catch (error) {
                this.announcementService.show('Unexpected error while uploading', `${error}\n~~~~~\n\n${this.stixService.uploadErrors}`, 'upload-error', false);
            } finally {
                this.modalService.dismissAll();
                this.isDiscarding = false;
                this.loadingBundle = false;
                this.stixService.objectsToLoad = [];
                this.stixService.newVersionObjects = [];
                this.stixService.uploadErrors = '';
                this.newVersionObjectsLoad = [];
                // if (this.selectedBundle2 != '') {
                //     await this.stixService.db.savedBundles.where('name').equals(this.selectedBundle2).delete();
                // }
                setTimeout(() => {
                    this.checkDuplicates();
                    this.checkReferences();
                }, 500)
            }

            // if (this.importingObjects && !this.savingBundle && this.upload_errors) {
            //     this.announcementService.show('Errors encountered while uploading', this.upload_errors, 'upload-error', false);
            // }
        })
    }

    mergeBundle(objectsToLoad: any): void {
        this.isMerging = true;

        setTimeout(async () => {
            try {
                await this.addObjectsAsync(objectsToLoad);
                // await this.addCustomObjAsync();

                this.loadVersioning();
                if (this.stixService.uploadErrors) {
                    this.announcementService.show('Errors encountered while uploading', this.stixService.uploadErrors, 'upload-error', false);
                }

                setTimeout(() => {
                    this.checkDuplicates();
                    this.checkReferences();
                }, 500)
            } catch (error) {
                this.announcementService.show('Unexpected error while uploading', `${error}\n~~~~~\n\n${this.stixService.uploadErrors}`, 'upload-error', false);
            }
            finally {
                this.modalService.dismissAll();
                // if (this.selectedBundle2 != '') {
                //     await this.stixService.db.savedBundles.where('name').equals(this.selectedBundle2).delete();
                // }
                this.stixService.uploadErrors = '';
                this.isMerging = false;
                this.loadingBundle = false;
                this.stixService.newVersionObjects = [];
                this.updateGroupByTLP();

                setTimeout(() => {
                    this.checkDuplicates();
                    this.checkReferences();
                }, 500)
            }
        })
    }

    async addObjectsAsync(objects: any): Promise<void> {
        const customObjects = this.upload.filter((obj: any) => !STIX_OBJECTS_LIST.includes(obj.type));
        objects = customObjects.concat(objects);

        const objectsToAdd = objects.map(object => {
            return {id: object.id, index: this.stixService.imxCacheIndex++, object: object}
        });

        await this.stixService.db.imxCache.bulkPut(objectsToAdd);
        const addedBundle = await this.stixService.db.imxCache.orderBy("index").toArray();
        const newBundle = addedBundle.map((obj) => obj.object);
        this.stixService.bundle.objects = newBundle.filter((obj) => STIX_OBJECTS_LIST.includes(obj.type));
        this.stixService.customObjects = newBundle.filter((obj) => !STIX_OBJECTS_LIST.includes(obj.type));

        this.stixService.imxCacheIndex = addedBundle.length > 0 ?
            addedBundle[addedBundle.length - 1].index + 1 :
            1;

        for (let obj of objects) {
            this.stixService.addIdentity(obj);
        }

        this.upload = [];
    }

    async loadBundle(modal: any) {
        this.savingBundle = false;
        
        if(this.stixService.serverSavedBundles.status && this.stixService.serverSavedBundles.status === 500){
            // this.announcementService.show('Failed to retrieve Saved Bundles', 'There was an error in communicating to the IMX Server', 'error', false);
        }

        this.savedBundleDict = {}
        for (let obj of this.stixService.savedBundles) {
            let bundle = obj;
            this.savedBundleDict[bundle.name] = bundle.bundle;
        }
        this.activeTab = 'jsonViewer';
        this.loadingBundle = false;
        this.modalService.open(modal, { ariaLabelledBy: 'load-modal-title', size: 'xl', windowClass: 'publish-modal' }).result.finally(() => {
            // Reset Values
            this.loadingBundle = false;
            this.selectedBundle = '';
        });
        
    }

    updateSaveModal(event){
        if(event.status === 'save'){
            // this.savingBundle = true;
            this.savingModalData = event;

            if(event.isNew === true){
                this.saveBundle(event.destination);
            } else {
                this.overwriteBundle(event.destination);
            }
        } else if(event.status === 'load'){
            this.loadingBundle = true;
            this.loadingModalData = event;
            const newVersionObjects = (event.destination === 'local') 
                ? event?.bundle?.new_version_objects 
                : this.loadingModalData?.bundle?._source?.new_version_objects;
            this.newVersionObjectsLoad = newVersionObjects 
                ? newVersionObjects 
                : [];
            this.selectedBundle = this.loadingModalData.name;
        }
    }

    updateLoadModal(event){
        if(event.status === 'load'){
            this.loadingBundle = true;
            this.loadingModalData = event;
            this.selectedBundle = this.loadingModalData.name;
            console.log(this.loadingModalData);
        }
    }

    uploadFile(modal: any) {
        this.modalService.open(modal, { ariaLabelledBy: 'load-modal-title', size: 'xl', windowClass: 'publish-modal' }).result.finally(() => {
            // Reset Values
            this.loadingBundle = false;
            this.selectedBundle = '';
        });
    }

    sendBundle() {
        this.stixService.objectsToLoad = this.getStixPreview().objects
        this.selectedBundle2 = this.selectedBundle
        this.modalService.dismissAll();
        this.stixService.hasObjectsToLoad = true;
    }

    async overwriteBundle(destination): Promise<void> {
        const newVersionBundle = []
        const newObjects: Array<any> = JSON.parse(localStorage.getItem('new_version_objects'));
        
        if (newObjects && newObjects.length > 0) {
            this.bundle.objects.forEach(obj => {
                if (newObjects.includes(obj.id))
                    newVersionBundle.push(obj.id);
            })
        }

        let timestamp = (new Date()).toString();
        let timeArr = timestamp.split('(');
        let timezone = timeArr[1];
        let zoneArr = timezone.split(' ');
        let zoneString = '';
        for(let word of zoneArr){
            zoneString += word.substring(0, 1);
        }
        let timeString = `${timestamp.substring(0, 24)} ${zoneString}`;
            
        const bundle = (this.savingModalData.destination === 'profile')
            ? this.savingModalData.bundle._source.bundle
            : this.savingModalData.bundle.bundle;

        let tempBundle = {
            reportNo: this.savingModalData.destination === 'profile' ? this.savingModalData.bundle._source.reportNo : this.savingModalData.bundle.reportNo,
            name: this.savingModalData.name,
            bundle: this.savingModalData.isChangingName ? bundle : this.bundle.objects,
            created: this.savingModalData.destination === 'profile' ? this.savingModalData.bundle._source.created : this.savingModalData.bundle.created,
            modified: timeString,
            tlp: this.findTlpColor(this.bundle.objects),
            submissionID: this.savingModalData.destination === 'profile' ? this.savingModalData.bundle._source.submissionID : this.savingModalData.bundle.submissionID,
            publicationServer: this.savingModalData.destination === 'profile' ? this.savingModalData.bundle._source.publicationServer : this.savingModalData.bundle.publicationServer,
            publicationRoot: this.savingModalData.destination === 'profile' ? this.savingModalData.bundle._source.publicationRoot : this.savingModalData.bundle.publicationRoot,
            publicationStatus: this.savingModalData.destination === 'profile' ? this.savingModalData.bundle._source.publicationStatus : this.savingModalData.bundle.publicationStatus,
            internal_status: this.savingModalData.destination === 'profile' ? this.savingModalData.bundle._source.internal_status : this.savingModalData.bundle.internal_status,
            new_version_objects: newVersionBundle
        }

        if(this.stixService.tempStatusCallback){
            tempBundle.submissionID = this.stixService.tempStatusCallback.data._id;
            let submission: any  = await this.submissions.getSubmissionByID(tempBundle.submissionID);

            tempBundle.publicationServer = `Taxii: ${submission.type}`;
            tempBundle.publicationRoot = `Root/Col: ${submission.root}/${submission.collection.title}`;
            tempBundle.publicationStatus = `Status: ${submission.res.status}`;
            tempBundle.new_version_objects = this.stixService.newVersionObjects;
        }

        switch(this.savingModalData.destination){
            case 'profile':
                let tempDoc = this.savingModalData.bundle;
                tempDoc._source = tempBundle;

                let res: any = await this.savedBundlesService.updateSavedBundle(tempDoc);
                console.log(res);
                if( !res.data && !res.data.result && res.data.result !== 'updated' ){
                    this.announcementService.show('Error in Saving Bundle', 'Error saving Bundle to IMX Server, please either try again or saved locally', 'error', false);
                } else {
                    this.announcementService.show('Bundle Saved', 'The Bundle was successfully overwritten to My Profile', 'success', false);
                    this.addObjectsAsync(this.stixService.objectsToLoad);
                    if (this.selectedBundle2 != '') {
                        this.stixService.serverSavedBundles = this.stixService.serverSavedBundles.filter(bundle => bundle.name !== this.selectedBundle2);
                    }
                }
                break;
            case 'local':
                await this.stixService.db.savedBundles.where("name").equals(this.savingModalData.bundle.name).modify(tempBundle);
                this.announcementService.show('Bundle Saved', 'The Bundle was successfully overwritten locally', 'success', false);
                break;
        }

        this.modalService.dismissAll();
        await this.stixService.getSavedBundles(1, '');
    }

    async saveBundle(destination): Promise<void> {
        const newVersionBundle = []
        const newObjects: Array<any> = JSON.parse(localStorage.getItem('new_version_objects'));
        
        if (newObjects && newObjects.length > 0) {
            this.bundle.objects.forEach(obj => {
                if (newObjects.includes(obj.id))
                    newVersionBundle.push(obj.id);
            })
        }

        this.isSavingBundle = true;

        this.stixService.reportNo++;

        let timestamp = (new Date()).toString();
        let timeArr = timestamp.split('(');
        let timezone = timeArr[1];
        let zoneArr = timezone.split(' ');
        let zoneString = '';
        for(let word of zoneArr){
            zoneString += word.substring(0, 1);
        }
        let timeString = `${timestamp.substring(0, 24)} ${zoneString}`;

        let tempBundle = {
            reportNo: this.stixService.reportNo,
            name: this.savingModalData.name,
            bundle: this.bundle.objects,
            created: timeString,
            modified: '',
            tlp: this.findTlpColor(this.bundle.objects),
            submissionID: '',
            publicationServer: '',
            publicationRoot: '',
            publicationStatus: '',
            internal_status: this.savingModalData.bundle.internal_status !== 'TBD' ? this.savingModalData.bundle.internal_status : 'TBD',
            new_version_objects: newVersionBundle
        }

        if(this.stixService.tempStatusCallback){
            tempBundle.submissionID = this.stixService.tempStatusCallback.data._id;
            let submission: any  = await this.submissions.getSubmissionByID(tempBundle.submissionID);
            tempBundle.publicationServer = `Taxii: ${submission.type}`;
            tempBundle.publicationRoot = `Root/Col: ${submission.root}/${submission.collection.title}`;
            tempBundle.publicationStatus = `Status: ${submission.res.status}`;
            tempBundle.new_version_objects = this.stixService.newVersionObjects;
        }

        if(destination === 'local'){
            this.stixService.db.savedBundles.put(tempBundle);
            this.announcementService.show('Bundle Saved', 'The Bundle was Saved Locally', 'success', false);
        } else {
            let res: any = await this.savedBundlesService.postSavedBundle(tempBundle);
            if( res.status !== 201 ){
                this.announcementService.show('Error in Saving Bundle', 'Error saving Bundle to IMX Server, please either try again or saved locally', 'error', false);
            } else {
                this.announcementService.show('Bundle Saved', 'The Bundle was Saved to Server', 'success', false);
                this.addObjectsAsync(this.stixService.objectsToLoad);
                console.log("SELECT", this.selectedBundle2)
                if (this.selectedBundle2 != '') {
                    this.stixService.serverSavedBundles = this.stixService.serverSavedBundles.filter(bundle => bundle.name !== this.selectedBundle2);
                }
            }
        }
        
        this.isSavingBundle = false;
        // this.clearBundle();
        this.bundleName = '';
        this.modalService.dismissAll();
        await this.stixService.getSavedBundles(1, '');
    }

    invalidBundleName(): boolean {
        for (let bundle of this.stixService.savedBundles) {
            this.savedBundleDict[bundle.name] = bundle.bundle;
        }

        if (this.bundleName != this.selectedBundle2 && this.savedBundleDict[this.bundleName]) {
            this.invalidName = "Name already in use";
            return true;
        }
        this.invalidName = '';
        return false;
    }

    constructTaxiiServerURL(a1?: string): string {
        if (this.taxiiServerType === 'custom') {
            this.taxiiServer.username = this.stixService.taxiiServer.username;
            this.taxiiServer.password = this.stixService.taxiiServer.password;
            this.taxiiServer.url = this.stixService.taxiiServer.url;
        }
        if (a1 || this.publishA1){
            this.taxiiServer.url = environment.taxiiServer.url;
            this.taxiiServer.username = environment.taxiiServer.username;
            this.taxiiServer.password = environment.taxiiServer.password;
        }
        if (((a1 && a1 == 'a1') || this.publishA1)) {
            return `${environment.taxiiServer.a1ChildRoot}collections/${environment.taxiiServer.a1CollectionId}/objects/`;
        }
        else if (a1 && a1 == 'raw'){
            return `${environment.taxiiServer.a1ChildRoot}collections/${environment.taxiiServer.a1RawCollectionId}/objects/`;
        }
        else if (a1){
            return "";
        }
        else if (this.taxiiServerType === "default" && this.taxiiServer.url && this.taxiiServer.childRoot && this.selectedCollection && this.selectedCollection.id)
            return `${this.taxiiServer.childRoot}collections/${this.selectedCollection.id}/objects/`;

        else if (this.taxiiServerType === "custom" && this.taxiiServer.url && this.taxiiServer.username && this.taxiiServer.password) {
            let apiRoot = ""
            if (typeof this.selectedAPIRoot == "string"){
                apiRoot = this.selectedAPIRoot;
            }
            else if (this.selectedAPIRoot.apiPath) {
                apiRoot = this.selectedAPIRoot.apiPath;
            }
            else {
                apiRoot = this.selectedAPIRoot["api-root"];
            }
            if (this.taxiiServerBaseUrl.includes('/taxii/v2.1-os')) {
                return `${this.taxiiServerBaseUrl}${apiRoot}/collections/${this.selectedCollection.id}/objects/`;
            }
            else {
                return `${this.taxiiServerBaseUrl}taxii/v2.1-os/${apiRoot}/collections/${this.selectedCollection.id}/objects/`;
            }
        }
        else if (this.taxiiServerType === 'ais') {
            return `${this.taxiiServer.childRoot}collections/3a5910b5-b4f5-4f1e-8852-a8eec1664847/objects/`;
        }

        else if (this.taxiiServerType === 'sandbox') {
            return `${environment.taxiiServer.url}taxii/${environment.taxiiServer.apiVersion}/${this.stixService.sandbox['api-root']}/collections/${this.stixService.sandbox.collection}/objects/`;
        }
        else if (this.taxiiServerType === 'cisa') {
            return `${environment.taxiiServer.url}taxii/${environment.taxiiServer.apiVersion}/${environment.customConfiguration.cisa.apiRoot}/collections/${environment.customConfiguration.cisa.collectionId}/objects/`;
        }

        return "";
    }

    createObject(): void {
        this.creatingObject = !this.creatingObject;
    }

    createRelationship(): void {
        let options: any[] = [];
        let objs = this.bundle.objects.concat(this.stixService.customObjects);

        for (let selected of this.selectedObjects) {
            for (let obj of objs) {
                if (obj.id === selected) {
                    console.log(obj);
                    options.push(obj);
                }
            }
        }

        let source = options[0];
        let target = options[1];

        const dialogRef = this.relationshipDialog.open(RelationshipDialogComponent, {
            data: { options: options, source: source, target: target, from: "bundle" },
            height: '400px',
            width: `${window.innerWidth / 2}px`
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && !this.guidedUI) this.router.navigateByUrl('/add-component/relationship', { state: result });
            if (result && this.guidedUI) {
                const relationshipEvent = {
                    type: 'create-relationship-go-forward',
                }
                this.stixService.sendData(relationshipEvent);

                setTimeout(() => {
                    const relationshipEvent = {
                        type: 'create-relationship',
                        value: result,
                    }
                    this.stixService.sendData(relationshipEvent);
                }, 300)
            }
        });
    }

    createSighting() {
        const relationshipEvent = {
            type: 'create-sighting-go-forward',
        }
        this.stixService.sendData(relationshipEvent);

        setTimeout(() => {
            const relationshipEvent = {
                type: 'create-sighting',
                value: {
                    sighting: this.selectedObjects,
                }
            }
            this.stixService.sendData(relationshipEvent);
        }, 500)
    }

    getStixObjectID(type): string {
        type = type.toLowerCase();
        type.replace(' ', '-');

        return type;
    }

    searchCompare(object: any, target: string): boolean {
        for (let field in object) {
            let value = object[field].toString();
            value = value.toLowerCase();
            if (field.includes(target) || value.includes(target)) {
                return true;
            }
        }

        return false;
    }

    search(type): void {
        if (this.searchText === '') return;

        if (this.prevSearch === this.searchText) {
            if (type === 'enter')
                this.searchIndex++;
            else
                this.searchIndex--;
        } else {
            this.prevSearch = this.searchText;
            this.searchIndex = 0;
            this.searchResults = '';
            this.searchMatches = [];
            let tempSearch = this.searchText.toLowerCase();

            for (let i = 0; i < this.stixObjects.length; i++) {
                for (let j = 0; j < this.stixObjects[i].objects.length; j++) {
                    let stix_type = this.stixObjects[i].objects[j].type;
                    let count = 0;

                    for (let k = 0; k < this.bundle.objects.length; k++) {
                        let bundle_type = this.bundle.objects[k].type;

                        if (stix_type === bundle_type && this.searchCompare(this.bundle.objects[k], tempSearch)) {
                            this.searchMatches.push(this.getComponentId(this.bundle.objects[k], i, j, count));
                            count++;
                        }
                        else if (stix_type === bundle_type) {
                            count++;
                        }
                    }
                }
            }
        }

        if (this.searchIndex < 0) this.searchIndex = this.searchMatches.length - 1;
        else if (this.searchIndex >= this.searchMatches.length) this.searchIndex = 0;

        this.searchResults = `${this.searchIndex + 1} of ${this.searchMatches.length}`;

        let el = document.getElementById(this.searchMatches[this.searchIndex]);
        if (el) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    getFirstBundleObject(): string {
        for (let i = 0; i < this.stixObjects.length; i++) {
            for (let j = 0; j < this.stixObjects[i].objects.length; j++) {
                let stix_type = this.stixObjects[i].objects[j].type;

                for (let k = 0; k < this.bundle.objects.length; k++) {
                    let bundle_type = this.bundle.objects[k].type;

                    if (stix_type === bundle_type) {
                        return this.bundle.objects[k].type;
                    }
                }
            }
        }
        return '';
    }

    clearSearch(): void {
        var target = this.getFirstBundleObject();

        this.searchResults = '';
        this.searchText = '';

        let el = document.getElementById(target);

        if (el) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    clearSearchResults() {
        this.searchResults = '';
    }

    clearSearchAnalyst1() {
        this.searchTextAnalyst1 = '';
    }

    highlightSearch(component: any): boolean {
        if (this.searchText === '') return false;
        let tempSearch = this.searchText.toLowerCase();

        if (this.searchCompare(component, tempSearch)) return true;

        return false;
    }

    getComponentId(component: any, i: number, j: number, k: number): string {
        let result = `${this.getComponentDisplay(component)}${i}${j}${k}`;

        return result;
    }

    getComponentDisplay(component: any): string {
        if (component.type) {
            let componentDisplay = '';
            switch (component.type) {
                case 'malware-analysis': {
                    componentDisplay = component.id;
                    break;
                }
                case 'artifact': {
                    if (component.payload_bin) {
                        // componentDisplay = component.payload_bin;
                        if (component.hashes) {
                          const keys = Object.keys(component.hashes);
                          componentDisplay = component.hashes[keys[0]];
                        } else 
                          componentDisplay = component.id;
                      } else
                          componentDisplay = component.url;
                      break;
                }
                case 'autonomous-system': {
                    componentDisplay = `${component.number} ${component.name ? '(' + component.name + ')' : ''}`;
                    break;
                }
                case 'directory': {
                    componentDisplay = component.path;
                    break;
                }
                case 'email-message': {
                    componentDisplay = `${component.subject ? component.subject : `No Subject Included: (${component.id})`}`
                    break;
                }
                case 'file': {
                    if (component.name)
                        componentDisplay = component.name;
                    else if (component.hashes)
                        componentDisplay = `${Object.keys(component.hashes)[0]}: ${component.hashes[Object.keys(component.hashes)[0]]}`;
                    else
                        componentDisplay = `<NO NAME> (${component.id})`;
                    break;
                }
                case 'domain-name':
                case 'email-addr':
                case 'url':
                case 'ipv4-addr':
                case 'ipv6-addr':
                case 'mac-addr': {
                    componentDisplay = component.value;
                    break;
                }
                case 'impact':
                    componentDisplay = component.impact_category ? component.impact_category.charAt(0).toUpperCase() + component.impact_category.slice(1) : `(${component.id})`;
                    break;
                case 'mutex':
                case 'software': {
                    componentDisplay = component.name;
                    break;
                }
                case 'process': {
                    if (component.pid)
                        componentDisplay = component.pid;
                    else if (component.cwd)
                        componentDisplay = component.cwd;
                    else
                        componentDisplay = `<NO NAME> (${component.id})`;
                    break;
                }
                case 'relationship': {
                    if (component.source_ref && component.target_ref) {
                        let sourceRefObject = this.stixService.bundle.objects.filter(obj => obj.id === component.source_ref);
                        let targetRefObject = this.stixService.bundle.objects.filter(obj => obj.id === component.target_ref);
                        let sourceDisplay = sourceRefObject.length > 0 ? this.getComponentDisplay(sourceRefObject[0]) : component.source_ref;
                        let targetDisplay = targetRefObject.length > 0 ? this.getComponentDisplay(targetRefObject[0]) : component.target_ref;
                        let relationship_type = component.relationship_type;
                        componentDisplay = `${sourceDisplay} <i>${relationship_type}</i> ${targetDisplay}`;
                    } else
                        componentDisplay = `<NO NAME> (${component.id})`;
                    break;
                }
                case 'user-account': {
                    if (component.user_id)
                        componentDisplay = component.user_id;
                    else if (component.account_login)
                        componentDisplay = component.account_login;
                    else if (component.display_name)
                        componentDisplay = component.display_name;
                    else
                        componentDisplay = `<NO NAME> (${component.id})`;
                    break;
                }
                case 'windows-registry-key': {
                    if (component.key)
                        componentDisplay = component.key;
                    else
                        componentDisplay = `<NO NAME> (${component.id})`;
                    break;
                }
                case 'x509-certificate': {
                    if (component.subject)
                        componentDisplay = component.subject;
                    else if (component.serial_number)
                        componentDisplay = component.serial_number;
                    else if (component.hashes)
                        componentDisplay = `${Object.keys(component.hashes)[0]}: ${component.hashes[Object.keys(component.hashes)[0]]}`;
                    else if (component.issuer)
                        componentDisplay = component.issuer;
                    else
                        componentDisplay = `<NO NAME> (${component.id})`;
                    break;
                }
                default:
                    return component.name ? component.name : `<NO NAME> (${component.id})`;
            }

            return componentDisplay;
        } else
            return component.name ? component.name : '<NO NAME>';
    }

    getTlp(component: any): Array<{name: string, type: string}> {
        let tlp = [];
        if (component.object_marking_refs) {
            component.object_marking_refs.forEach(objMark => {
                let markingName: string;

                markingName = TLP_OPTIONS.find(obj => obj.id === objMark)?.name;
                if (markingName !== undefined) 
                    return tlp.push({name: markingName, type: "tlpv1"});

                markingName = TLP20_OPTIONS.find(obj => obj.id === objMark)?.name;
                if (markingName !== undefined)
                    return tlp.push({name: markingName, type: "tlpv2"});

                // markingName = ACS_OBJECTS.find(acs => acs.object.id === objMark)?.type;
                const acs_type = localStorage.getItem('acs-type');
                if (!acs_type)
                    markingName = undefined;
                else
                    markingName = JSON.parse(acs_type)[objMark];
                if (markingName !== undefined)
                    return tlp.push({name: markingName, type: "acs"});

                return null;
            })
        }
        tlp.sort((a,b) => {
            const order = ["acs", "tlpv2", "tlpv1"];
            return order.indexOf(a.type) - order.indexOf(b.type);
        })
        return tlp;
    }

    getTlpStyle(tlp: {name: string, type: string}): string {
        let tlpStyle = '';
        const { name, type } = tlp;

        if (type === 'acs') {
            return 'acs-marking-bundle'
        } else {
            switch (name) {
                case "TLP:CLEAR": {
                    tlpStyle = 'tlp-clear-bundle'
                    break;
                }
                case "TLP:GREEN": {
                    tlpStyle = 'tlp-green-bundle'
                    break;
                }
                case "TLP:AMBER": {
                    tlpStyle = 'tlp-amber-bundle'
                    break;
                }
                case "TLP:AMBER+STRICT": {
                    tlpStyle = 'tlp-amber-bundle'
                    break;
                }
                case "TLP:RED": {
                    tlpStyle = 'tlp-red-bundle'
                    break;
                }
                case "TLP:WHITE": {
                    tlpStyle = 'tlp-white-bundle'
                    break;
                }
            }
        }
        if (tlp) {
            
        }
        return tlpStyle;
    }

    // The reason for the copy is that if we edit component directly, we edit the bundle because of the linkages going on.
    getJsonDisplayForComponent(component): any {
        let copy = Object.assign({}, component); // Create a copy for displaying purposes

        if (copy.contents && typeof copy.contents === "object" && copy.contents.length > 0) {
            let contentsString = "";

            for (let i = 0; i < copy.contents.length; i++) {
                let newContent = new Content();
                newContent.lang = copy.contents[i].lang;
                newContent.fields = copy.contents[i].fields;
                newContent.fieldName = copy.contents[i].fieldName;
                contentsString += "{" + newContent.toString() + "},";
            }

            contentsString = contentsString.slice(0, -1); // Remove the last comma
            copy.contents = JSON.parse(`[ ${contentsString} ]`);
        }

        return copy;
    }

    isConnectDisabled(bypassStep = false): boolean {
        // Checking TAXII Server Information
        if (this.modalCurrentStep === 2 || bypassStep) {
            // Custom TAXII Server
            // Check for url/username/password
            if (this.taxiiServerType === 'custom' && this.taxiiServerAuthType !== 'keycloak' &&
                (!this.taxiiServer.url || !this.taxiiServer.username || !this.taxiiServer.password)
            ) {
                // console.debug("Custom taxii server without url/username/password");
                return true;
            } else if (this.taxiiServerType === 'custom' && this.taxiiServerAuthType === 'keycloak' && !this.taxiiServer.url) {
                // console.debug("Custom keycloak taxii server without url");
                return true;
            }
        }
        return false;
    }

    isNextDisabled(): boolean {
        // Checking TAXII Server Information
        if (this.modalCurrentStep === 2) {
            // Custom TAXII Server
            // Check for url/username/password/APIRoot/Collection

            console.log(this.stixService.taxiiServer);
            if (this.stixService.taxiiServerType === 'custom') {
                if (this.taxiiServerAuthType === 'keycloak') {
                    if (!this.stixService.taxiiServer.url) {
                        console.debug('Custom Keycloak Taxii Server missing URL');
                        return true;
                    }
                }
                else if (!this.stixService.taxiiServer.url || !this.stixService.taxiiServer.username || !this.stixService.taxiiServer.password) {
                    console.debug("Custom taxii server without url/username/password");
                    return true;
                    }
                else if (!this.selectedAPIRoot || !this.selectedCollection) {
                    console.debug("API Root or Collection missing");
                    return true;
                }
            }

            if (this.stixService.taxiiServerType === 'default' &&
                // Derek - feature branch had (!this.taxiiServer.apiRoot || !this.taxiiServer.collection)) {
                (!this.taxiiServer.apiRoot || !this.selectedCollection)) {
                console.debug("Default taxii server without api root or collection id");
                return true;
            }

            if (this.taxiiServerType === 'default' &&
                (this.taxiiServer.apiRoot && !this.selectedCollection.id)) {
                console.debug("API Root selected but collection not defined");
                return true;
            }
        } else if (this.modalCurrentStep === 0){
            console.log(JSON.parse(JSON.stringify(this.stixService.taxiiServer.required)));
            let result = !this.requiredCheckAll(this.stixService.taxiiServer.required, '');
            console.log(result);
            return result;
        }
        return false;
    }

    getStixPreview(): Bundle {
        let tempBundle = JSON.parse(JSON.stringify(this.stixService.bundle));
        tempBundle.objects = [...tempBundle.objects, ...this.stixService.customObjects];
        if (this.loadingBundle) {
            let bundleLocation: any[];
            switch(this.loadingModalData.destination){
                case 'profile':
                    bundleLocation = this.loadingModalData.bundle._source.bundle;
                    break;
                case 'local':
                    bundleLocation = this.loadingModalData.bundle.bundle;
                    break;
            }
            tempBundle = new Bundle(bundleLocation);
        }
        // if (tempBundle.objects && tempBundle.objects.length > 0) {
        //     tempBundle.objects.forEach(o => {
        //         if (o.description) {
        //             delete o.description;
        //         }
        //     });
        // }
        return tempBundle;
    }

    previewObjectsToLoad(): Bundle {
        this.showingVisualizer = false;
        let tempBundle = {
            type: this.bundle.type,
            id: `bundle`,
            objects: []
        }
        tempBundle.objects = [...this.stixService.objectsToLoad];
        return tempBundle;
    }

    routeToNewTab(route) {
        window.open('/' + route, '_blank');
    }

    retrieveAPIRoot(apiRootURL): string {
        if (apiRootURL.startsWith('http://')) {
            apiRootURL = apiRootURL.replace('http://', 'https://');
        }

        function findNthOccur(str, ch, N) {
            var occur = 0;
            // Loop to find the 3rd occurrence of '/'
            for (var i = 0; i < str.length; i++) {
                if (str[i] == ch) {
                    occur += 1;
                }
                if (occur == N)
                    return i;
            }
            return -1;
        }

        if (apiRootURL.startsWith('https://')) {
            const splitRootURL = apiRootURL.split('/');
            if (apiRootURL.endsWith("/")) {
                return splitRootURL[splitRootURL.length - 2];
            } else {
                return splitRootURL[splitRootURL.length - 1];
            }
        } else if (apiRootURL.startsWith("/")) {
            if (apiRootURL.endsWith("/"))
                return apiRootURL.substring(1, apiRootURL.length - 1);
            else
                return apiRootURL.substring(1, apiRootURL.length);
        } else {
            this.apiRootProcessingError = 'Could not retrieve API Root information from the TAXII server.';
            return null;
        }
    }

    getAPIRootInformation(api_root?: string): Observable<unknown> {
        console.log(this.taxiiServerBaseUrl);
        console.log(api_root);
        console.log(this.taxiiServerBaseUrl + api_root + "/");
        return this.httpClient.get(this.taxiiServerBaseUrl + api_root + "/", { headers: this.httpHeaders });
    }

    onAPIRootChanged(apiRoot: any) {
        this.loadingApiRoots = true;
        this.selectedCollection = '';
        this.collections = [];
        this.taxiiServer.availableCollections = [];
        this.selectedAPIRoot = apiRoot;
        if (this.stixService.taxiiServerType == 'custom') {
            let urlArray = this.stixService.taxiiServer.url.split('/');
            this.taxiiServerBaseUrl = `${urlArray[0]}//${urlArray[2]}/taxii/v2.1-os/`;
        }
        this.getCollectionsIds();
    }

    onCollectionChanged(collection) {
        this.selectedCollection = collection;
        this.taxiiServer.collection = collection;
    }

    private getCollectionsIds(root = undefined) {
        const tempRoot = !root ? this.selectedAPIRoot : root;   // Not actually sure what this is for
        let rootIndex;
        if (this.selectedAPIRoot == null){
            console.log("No API Root Selected");
            return null;
        }

        // Derek - see if these first two checks can be removed
        if (typeof this.selectedAPIRoot == 'string') {
            console.log("a");
            rootIndex = this.stixService.apiRoots2.findIndex(r => r['apiPath'] === this.selectedAPIRoot);
        }
        else {
            if (this.selectedAPIRoot["api-root"]) {
                console.log("b");
                rootIndex = this.stixService.apiRoots2.findIndex(r => r['apiPath'] === this.selectedAPIRoot["api-root"]);
            }
            else {
                console.log("c");
                rootIndex = this.stixService.apiRoots2.findIndex(r => r['apiPath'] === this.selectedAPIRoot["apiPath"]);
            }
        }
        if (rootIndex > -1){
            this.collections = this.stixService.apiRoots2[rootIndex]['collections'];
            this.collections = this.collections.filter(c => c.can_write);
            this.loadingApiRoots = false;
        }
        this.taxiiServer.availableCollections = this.collections;
        if (this.taxiiServer.availableCollections.length === 1) {
            this.taxiiServer.collection = this.taxiiServer.availableCollections[0];
        } else {
            this.taxiiServer.collection = null;
        }
    }

    requiredCheckAll(required, when): boolean {
        if(when === 'init'){
            this.required = [];
        }
        for(let field of required){
            let result = this.requiredCheck(field);
            if(result === false){
                if(when === 'init'){
                    this.required.push({label: field, showInPopup: true});
                } else {
                    return false;
                }
                
            }
        }
        
        if(when === 'init' && this.required.length !== 0){
            return false;
        }
        return true;
    }

    requiredCheck(field): boolean {
        switch(field){
            case 'ACS Markings':
                for (let object of this.bundle.objects) {
                    if (object.type === 'marking-definition' && object.extensions)
                        if (object.extensions['extension-definition--3a65884d-005a-4290-8335-cb2d778a83ce'])
                            return true;
                }

                return false;
        }

        return true;
    }

    openPublishModal(modal): void {
        let passesRequiredCheck = this.stixService.taxiiServer.required.length === 0;
        if(!passesRequiredCheck){
            passesRequiredCheck = this.requiredCheckAll(this.stixService.taxiiServer.required, 'init');
            if(!passesRequiredCheck){
                this.modalCurrentStep = 0;
            }
        }

        this.prevShowingVisualizer = this.showingVisualizer;
        this.showingVisualizer = false;
        // this.serverSettingsChanged(this.stixService.taxiiServerType);

        this.modalService.open(modal, { ariaLabelledBy: 'publish-modal-title', size: 'xl', windowClass: 'publish-modal' }).result.finally(() => {
            // Reset Values
            this.modalCurrentStep = 1;
            this.modalStepCount = 3;
            this.taxiiServer = {
                url: this.stixService.taxiiServer.url,
                username: this.stixService.taxiiServer.username,
                password: this.stixService.taxiiServer.password,
                certificate: null,
                apiRoot: '',
                childRoot: '',
                availableCollections: [],
                collection: null
            };
            this.isPublishing = false;
            this.publishA1 = false;
            this.taxiiServerType = 'default';
            this.showingVisualizer = this.prevShowingVisualizer;
            this.selectedAPIRoot = null;
            this.selectedCollection = null;
        });

        if (this.stixService.taxiiServerType !== 'a1') {
            this.stixService.refreshRootCollections('can_write');
        }
    }

    openModal(modal) {
        this.deletedCISAIdentity = false;
        this.modalService.open(modal, { size: "xl" });
    }

    refreshApiRootsCollections() {
        this.stixService.refreshTaxiiUrls().then((_) => {
            console.debug("API Roots loaded successfully!");
        }).catch((err) => {
            console.error(err);
        });
    }

    publishBundle(showAnnouncement: boolean = true, a1?: string): void {
        if (this.stixService.taxiiServerType === 'custom') {
            this.taxiiServer.username = this.stixService.taxiiServer.username;
        }
        let object_ids = []
        for (let object of this.stixService.bundle.objects) {
            object_ids.push(object.id);
        }

        let logApiRoot = "";
        let logCollection = "";
        if((a1 && a1 == 'a1') || this.publishA1){
            logApiRoot = environment.taxiiServer.a1ApiRoot;
            logCollection = environment.taxiiServer.a1CollectionId
        }
        else if (a1 && a1 == 'raw') {
            logApiRoot = environment.taxiiServer.a1ApiRoot;
            logCollection = environment.taxiiServer.a1RawCollectionId
        }
        else {
            logApiRoot = this.selectedAPIRoot ? this.selectedAPIRoot["api-root"] : this.taxiiServer.apiRoot;
            logCollection = this.selectedCollection ? this.selectedCollection.id : this.taxiiServer.collection.id;
        }

        let logInfo = {
            action: "publish_req",
            description: "User has attempted to publish to Taxii Server",
            server_type: this.stixService.taxiiServerType,
            url: this.constructTaxiiServerURL(a1),
            api_root: logApiRoot,
            collection: logCollection,
            bundle: this.stixService.bundle.objects
        }
        console.log(logInfo);
        this.isPublishing = true;

        var ids = JSON.parse(JSON.stringify({ "arr": [] }));
        if (localStorage.getItem("identities")) {
            try {
                var ids = JSON.parse(localStorage.getItem("identities")!) || JSON.parse(JSON.stringify({ "arr": [] }));
            } catch (e) {
                console.error("Error parsing saved identities with message: ", e);
                localStorage.removeItem("identities");
            }
        }

        const bundleObjs = this.stixService.bundle.objects;
        for (var i = 0; i < bundleObjs.length; i++) {
            if (bundleObjs[i].type === 'identity') {
                const idStorageIndex = ids.arr.findIndex(idStorage => idStorage.id === bundleObjs[i].id);
                if (idStorageIndex !== -1) {
                    ids.arr.splice(idStorageIndex, 1, bundleObjs[i]);
                } else {
                    ids.arr.push(bundleObjs[i]);
                }
            }
        }
        localStorage.setItem("identities", JSON.stringify(ids));
        console.log(this.selectedCollection);
        this.stixService.publishBundle(
            this.constructTaxiiServerURL(a1),
            this.taxiiServer.username,
            this.taxiiServer.password,
            this.taxiiServerAuthType,
            this.selectedCollection
        ).then((result) => {
            console.debug("Successful return from STIX service for publication");
            console.log(result);
            this.fluentd.logEvent(logInfo, result);
            this.isResetNeeded = true;
            this.taxiiServerUrl = "";
            if (result['id']) {
                if ((a1) || this.publishA1) {
                    this.taxiiServerUrl = `${environment.taxiiServer.a1ChildRoot}status/` + result['id'] + '/';
                } else if ((this.taxiiServerType === "default") && this.taxiiServer.url && this.taxiiServer.childRoot) {
                    this.taxiiServerUrl = `${this.taxiiServer.childRoot}status/` + result['id'] + '/';
                } else if (this.taxiiServerType === "sandbox") {
                    this.taxiiServerUrl = `${environment.taxiiServer.url}taxii/${environment.taxiiServer.apiVersion}/${this.stixService.sandbox['api-root']}/status/` + result['id'] + '/';
                } else if (this.taxiiServerType === "cisa") {
                    this.taxiiServerUrl = `${environment.taxiiServer.url}taxii/${environment.taxiiServer.apiVersion}/${environment.customConfiguration.cisa.apiRoot}}/status/` + result['id'] + '/';
                } else if (this.taxiiServerType === "custom" && this.taxiiServer.url && this.taxiiServer.username && this.taxiiServer.password) {
                    let apiRoot = ""
                    if (typeof this.selectedAPIRoot == "string"){
                        apiRoot = this.selectedAPIRoot;
                    }
                    else if (this.selectedAPIRoot.apiPath) {
                        apiRoot = this.selectedAPIRoot.apiPath;
                    }
                    else {
                        apiRoot = this.selectedAPIRoot["api-root"];
                    }
                    if (this.taxiiServerBaseUrl.includes('/taxii/v2.1-os')) {
                        this.taxiiServerUrl = `${this.taxiiServerBaseUrl}${apiRoot}/status/` + result['id'] + '/';
                    }
                    else {
                        this.taxiiServerUrl = `${this.taxiiServerBaseUrl}taxii/v2.1-os/${apiRoot}/status/` + result['id'] + '/';
                    }
                } else if (this.taxiiServerType === "ais" && this.taxiiServer.url && this.taxiiServer.username && this.taxiiServer.password)
                    this.taxiiServerUrl = `${this.taxiiServer.childRoot}status/` + result['id'] + '/';
                this.announcementService.getStatus(this.taxiiServerUrl, this.taxiiServer.collection, showAnnouncement);
            }
            this.modalCurrentStep = 4;
            this.stixService.sendData({ type: "publish-success" });
            
            this.getBundleManifest().subscribe((resp: any) => {
                if (resp && resp.body && resp.body.objects) {
                    let manifestObjs = resp.body.objects;
                    let publishedObjects;

                    publishedObjects = localStorage.getItem('published-objects');
                    if (publishedObjects) {
                        publishedObjects = JSON.parse(publishedObjects);
                    } else {
                        publishedObjects = [];
                    }

                    manifestObjs.forEach(mo => {
                        publishedObjects.push({id: mo.id, date_added: mo.date_added});
                    })
                    
                    localStorage.setItem('published-objects', JSON.stringify(publishedObjects));
                }

                setTimeout(() => {
                    this.checkDuplicates();
                    this.checkReferences();
                }, 500)
            });
        }).catch((err) => {
            // Send to Announcement Service
            console.error("Error received from STIX Service Publish Bundle");
            console.error(err);
            if (err && err.error && err.error.title) {
                this.announcementService.show('Publication Unsuccessful', `There was an error publishing your STIX document. Error: ${err.error.title}`, 'error', false);
            } else {
                this.announcementService.show('Publication Unsuccessful', `${JSON.stringify(err)}`, 'error', true);
            }
            this.modalService.dismissAll();

            this.stixService.sendData({ type: "publish-error", err });
        }).finally(() => { this.isPublishing = false; this.publishA1 = false; });
    }

    getBundleManifest() {
        let url = `${this.taxiiServer.childRoot}collections/${this.taxiiServer.collection.id}/manifest/?match[id]=`;
        let ids = '';

        this.stixService.bundle.objects.forEach((o, i) => {
            ids += o.id
            if (i < this.stixService.bundle.objects.length) {
                ids += ',';
            }
        })
        url += ids;

        // return this.httpClient.get<any>(url, { headers: this.httpHeaders, observe: 'response' });
        return this.stixService.taxiiHttpReq('get', url, { headers: this.httpHeaders, observe: 'response'});
    }

    // Allows for Guided UI to close most recent announcement
    removeAnnouncement() {
        this.announcementService.remove();
    }

    removeObject(id: string) {
        if (id == environment.cisaIdentity.id) {
            this.deletedCISAIdentity = true;
        }

        this.stixService.removedCustomObj = [];

        this.stixService.removeComponent(id).then(() => {
            // Updates BundleComponent for children Custom Objects that were removed
            for (let customObj of this.stixService.removedCustomObj) {
                this.stixService.removeComponent(customObj['id']);
            }
            this.stixService.removedCustomObj = [];
            this.rownumber = -1;
            this.objnumber = -1;
    
            this.updateGroupByTLP();
    
            if (this.guidedUI) {
                for (let workflow of this.guidedService.cachedCart) {
                    for (let page in workflow) {
                        for (let type in workflow[page]) {
                            const typeInCachedCart = workflow[page][type];
                            workflow[page][type] = typeInCachedCart.filter((obj) => obj.id !== id);
                        }
                    }
                }

                localStorage.setItem('cached-cart', JSON.stringify(this.guidedService.cachedCart));
            }
            

            const new_version_objects = JSON.parse(localStorage.getItem("new_version_objects"));
            if (!new_version_objects) return;
            if (!new_version_objects.includes(id)) return;
    
            localStorage.setItem("new_version_objects", JSON.stringify(new_version_objects.filter(elem => elem !== id)));
        })
    }

    downloadBundle(guided = false): void {
        if (this.bundle.type === undefined
            || this.bundle.id === ''
            || this.bundle.id === undefined) {
                this.bundle = this.stixService.refreshBundle();
        }

        if (guided) {
            this.bundle = this.stixService.refreshBundle(false, this.bundle.id);
        }
        let tempBundle = {
            type: this.bundle.type,
            id: this.bundle.id,
            objects: []
        }

        for(let object of this.bundle.objects){
            tempBundle.objects.push(object);
        }

        for(let object of this.stixService.customObjects){
            tempBundle.objects.push(object);
        }
        
        const a = document.createElement("a");
        const file = new Blob([JSON.stringify(tempBundle)], { type: "application/taxii+json;version=2.1" });
        a.href = URL.createObjectURL(file);
        a.download = `bundle_name.json`;
        setTimeout(() => {
            a.click();
        }, 400);
    }

    onFileChanged(event) {
        this.upload = [];
        this.bad_uploads = [];
        for (let file of event.target.files) {
            const fileReader = new FileReader();

            fileReader.readAsText(file, "UTF-8");
            fileReader.onload = () => {
                let string = <string>fileReader.result;

                const objRegex = "{\"type\":\"\w[-[a-z0-9]+]*\",\"id\":\"\w[-[a-z0-9]+]*--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}\",\"spec_version\":\"[0-9]+\.[0-9]+\",.+}";

                let regex = new RegExp(`^{\"objects\":\[${objRegex}\]}$`);//[,${objRegex}]*\]}$`);

                regex = new RegExp(`^{\"objects\":\[{\"type\":\"\w[-[a-z0-9]+]*\",\"id\":\"\w[-[a-z0-9]+]*--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}\",\"spec_version\":\"[0-9]+\.[0-9]+\",.+}\]}$`);

                // Derek - Doesn't work using back ticks (figure out why)
                regex = new RegExp(/^{\s*\"objects\":\s*\[\s*({\s*\"type\":\s*\"\w[-[a-z0-9]+]*\",\s*\"id\":\s*\"\w[-[a-z0-9]+]*--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}\",\s*\"spec_version\":\s*\"[0-9]+\.[0-9]+\",(.*\s*)+}\s*)(\s*,\s*{\"type\":\s*\"\w[-[a-z0-9]+]*\",\s*\"id\":\s*\"\w[-[a-z0-9]+]*--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}\",\s*\"spec_version\":\s*\"[0-9]+\.[0-9]+\",(.*\s*)+})*\s*]\s*}$/);


                //Removes spaces
                let spaceRegex = new RegExp(/({[^"]*)("[^"]*(":\s*")[^"]*("\s*,\s*))*"[^"]*(":\s*).*(\s*})/);


                string = Minify(string).replace(/\n/g, '').replace(/}/g, '},').replace(/,,/g, ',').replace(/,}/g, '}').replace(/,]/g, ']');
                while (string[string.length - 1] == ',')
                    string = string.substring(0, string.length - 1);

                let cleanString = string;


                try {
                    let bad = false;
                    let objects;

                    try {
                        objects = JSON.parse(string);
                    }
                    catch (error2) {
                        try {
                            string = '{"objects":[' + string + ']}';
                            objects = JSON.parse(string);
                        }
                        catch (error3) {
                            bad = true;
                            console.log(error3);
                        }
                    }

                    if (!bad) {
                        if (objects.objects) {
                            objects = objects.objects;
                        }
                        if (objects["type"] && objects["id"] && objects["spec_version"]) {
                            objects = [objects];
                        }
                        if (!objects.length) {
                            bad = true;
                        }
                    }
                    // Attempts to salvage valid objects
                    else if ((cleanString.match(/{/g) || []).length == (cleanString.match(/}/g) || []).length) {
                        if (cleanString.indexOf('"objects":[')) {
                            cleanString = cleanString.substring(cleanString.indexOf('"objects":[') + 11,cleanString.length - 1);
                        }

                        string = '{"objects":['

                        let arr = cleanString.split('}');

                        for (let i = 0; i < arr.length; i++) {
                            let comma = false;  // could definitely be cleaner but this is simpler
                            let temp = arr[i] + '}';
                            if (temp.charAt(0) == ',') {
                                comma = true;
                                temp = temp.substring(1,arr[i].length) + '}';
                            }

                            while ((temp.match(/{/g) || []).length > (temp.match(/}/g) || []).length && arr[i+1]) {
                                temp = temp + arr[i+1] + '}';
                                i++;
                            }

                            try {
                                JSON.parse(temp);
                                if (comma) {
                                    string = string + ',';
                                }
                                string = string + temp;
                            }
                            catch (e) {
                            }
                        }
                        string = string + ']}';
                        try {
                            objects = JSON.parse(string).objects;
                            bad = false;
                        }
                        catch (e) {
                            console.log(e);
                        }
                    }


                    /**Derek - Look through the following if we ever want to perform file validation */
                    /*
                    string = JSON.stringify(JSON.parse(string));
                    
                    const dumbRegex = new RegExp(/^{\s*\"objects\":/);
                    if (!dumbRegex.test(string)) {
                        if (string[0] == '[')
                            objects = JSON.parse(string);//string = '{"objects": ' + string + '}';

                        else {
                            let bundle = JSON.parse(string);
                            //let bundleRegex = new RegExp(/^{\s*\"type\":\s*\"bundle\",\s*\"id\":\s*\"bundle--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}\",\s*("objects":(.*\s*)+)$/);
                            //let matches = string.match(bundleRegex);
                            if (matches) {
                                string = '{' + matches[1];
                            }
                            else
                                bad = true;
                        }
                    }
                    */

                    if (!bad) {
                        for (let obj of objects) {
                            // Removes empty fields
                            let cleanObj = Object.fromEntries(Object.entries(obj).filter(([key, value]) => (value !== undefined && value !== null && (typeof value !== "string" || value.length > 0))));
                            this.upload = this.upload.concat(cleanObj);
                        }
                    }
                    else {
                        this.bad_uploads = this.bad_uploads.concat(file.name);
                    }
                }
                catch (error) {
                    this.bad_uploads = this.bad_uploads.concat(file.name);
                    console.error(error);
                }
            }
            fileReader.onerror = (error) => {
                console.log(error);
                this.announcementService.show('Upload Unsuccessful', `There was an error publishing your STIX document. Error: ${error}`, 'error', false);
            }
        }
    }

    uploadBundle() {
        this.savingBundle = false;
        this.isUploading = true;
        this.stixService.uploadErrors = '';
        let published_objects = JSON.parse(localStorage.getItem("new_version_objects")) || [];
        let previous_published_objects = [...published_objects];
        
        setTimeout(() => {
            if (this.upload.length < 1) {
                let title = 'Upload Unsuccessful';
                let error = `The following file(s) are of an incorrect format.\nError: ${this.bad_uploads.toString()}`;
                this.isUploading = false;
                this.announcementService.show(title, error, 'error', false);
                return;
            }

            let title = 'Errors encountered while uploading';
            let errors: string[] = [];
            let error = '';
            if (this.bad_uploads.length > 0) {
                error = `The following file(s) are of an incorrect format or contain an unrecognized object.\nError: ${this.bad_uploads.toString()}~~~~~\n\n`;
            }

            var v = new Validator();

            let ids: String[] = [];
            for (let obj of this.upload) {
                let report = { "errors": [] };
                
                for (let prop in obj) {
                    if (Array.isArray(obj[prop]) && obj[prop].length === 0) {
                        delete obj[prop];
                    }
                }

                // Validation to be added here
                if (all_schemas[obj.type]) {
                    let schema = all_schemas[obj.type]; // Locates schema of appropriate type
                    if (schema["allOf"].length < 2) {     // Adds in the common props if they have not already been combined
                        if (all_schemas["SDOs_SROs"].includes(obj.type))
                            schema["allOf"] = ((all_schemas["common_props"] as unknown) as typeof schema["allOf"]).concat(schema["allOf"]);
                        else if (all_schemas["SCOs"].includes(obj.type))   //Really just a sanity check
                            schema["allOf"] = ((all_schemas["sco_common_props"] as unknown) as typeof schema["allOf"]).concat(schema["allOf"]);
                    }

                    report = v.validate(obj, schema);
                    console.log("Report: ", report);
                }
                console.log(report.errors);
                if (!(obj.id == "identity--b1160532-b8f3-4cfa-9b7d-423e253fbc59" && ids.includes(obj.id))) {
                    if (report.errors.length == 0) {
                        ids.push(obj["id"]);
                        this.stixService.objectsToLoad.push(obj);
                        // if (!STIX_OBJECTS_LIST.includes(obj.type)) {
                        //     let customObjects = JSON.parse(localStorage.getItem("customObjects")!) || JSON.parse(JSON.stringify({ "arr": [] }));
                        //     this.removeCustomObject(obj.id);
                        //     customObjects.arr.push(obj);
                        //     localStorage.setItem("customObjects", JSON.stringify(customObjects)); // Save in cache
                        //     this.customObjects.push(obj);
                        // }
                        // this.stixService.addComponent(obj);
                        
                        if (!published_objects.includes(obj.id)) {
                            published_objects.push(obj.id);
                        } else {
                            previous_published_objects = previous_published_objects.filter(element => element !== obj.id);
                        }   
                    } else {
                        let path: any = report.errors[0]["path"]
                        if (path.length == 0) {
                            path = report.errors[0]["instance"]["type"];
                        }
                        let message: string = report.errors[0]["message"];
                        // if (message.length > 250)
                        //     message = message.substring(0, 250) + '...';
                        let err = `${obj.id}: ${path} ${message}\n`;

                        for (let i = 1; i < report.errors.length; i++) {
                            path = report.errors[i]["path"]
                            if (path.length == 0) {
                                path = report.errors[i]["instance"]["type"];
                            }
                            message = report.errors[i]["message"];
                            // if (message.length > 250)
                            //     message = message.substring(0, 250) + '...';

                            err = `${err}${path} ${message}\n`;
                            

                        }
                        err = err + '~~~~~\n\n';

                        errors.push(err);
                        console.log("validation failed");

                        this.stixService.objectsToLoad.push(obj);
                        // if (!STIX_OBJECTS_LIST.includes(obj.type)) {
                        //     let customObjects = JSON.parse(localStorage.getItem("customObjects")!) || JSON.parse(JSON.stringify({ "arr": [] }));
                        //     this.removeCustomObject(obj.id);
                        //     customObjects.arr.push(obj);
                        //     localStorage.setItem("customObjects", JSON.stringify(customObjects)); // Save in cache
                        //     this.customObjects.push(obj);
                        // }
                        // this.stixService.addComponent(obj);
                        
                        if (!published_objects.includes(obj.id)) {
                            published_objects.push(obj.id);
                        } else {
                            previous_published_objects = previous_published_objects.filter(element => element !== obj.id);
                        }   
                    }
                }
                //this.stixService.objectsToLoad.push(obj);            
            }

            if (error.length > 0 || errors.length > 0) {
                for (let err of errors) {
                    error = error + err;
                }
                console.log(error);
                this.stixService.uploadErrors = error;
            }
            
            this.selectedFile = undefined;
            this.stixService.hasObjectsToLoad = true;
            localStorage.setItem("new_version_objects", JSON.stringify(published_objects));
            localStorage.setItem("previous_new_version_objects", JSON.stringify(previous_published_objects));
        })
    }

    serverSettingsChanged(type: string): void {
        this.taxiiServerType = type;

        if (type === "default") {
            this.taxiiServer.url = environment.taxiiServer.url;
            this.taxiiServer.username = environment.taxiiServer.username;
            this.taxiiServer.password = environment.taxiiServer.password;
            this.taxiiServer.apiRoot = null;
            this.taxiiServer.childRoot = null;
            this.taxiiServer.collection = null;
        } else if (type === "a1" || type === "raw") {
            console.log("oops");
            let collection = null;
            this.stixService.apiRoots.find(r => {
                collection = r.collections.find(c =>
                    c.id ===
                    (type === "a1" ? environment.taxiiServer.a1CollectionId
                        : type === "raw" ? environment.taxiiServer.a1RawCollectionId
                            : null)
                )
                return !!collection;
            });

            this.taxiiServer.url = environment.taxiiServer.url;
            this.taxiiServer.username = environment.taxiiServer.username;
            this.taxiiServer.password = environment.taxiiServer.password;
            this.taxiiServer.apiRoot = environment.taxiiServer.a1ApiRoot;
            this.taxiiServer.childRoot = environment.taxiiServer.a1ChildRoot;
            this.taxiiServer.collection = {
                "id": environment.taxiiServer.a1CollectionId,
                "title": collection ? collection.title : '',
            };

            switch(type){
                case 'a1':
                    this.taxiiServer.collection.title = 'Vetted';
                    break;
                case 'raw':
                    this.taxiiServer.collection.title = 'Raw';
            }
        } else if (type === "custom" || type === "ais") {
            this.taxiiServer.url = this.stixService.taxiiServer.url;
            this.taxiiServer.username = this.stixService.taxiiServer.username;
            this.taxiiServer.password = this.stixService.taxiiServer.password;
            this.taxiiServer.apiRoot = null;
            this.taxiiServer.childRoot = null;
            this.taxiiServer.collection = null;
        } else if (type === "sandbox") {
            if (this.stixService.sandbox) {
                this.taxiiServer.url = environment.taxiiServer.url;
                this.taxiiServer.username = environment.taxiiServer.username;
                this.taxiiServer.password = environment.taxiiServer.password;
                this.taxiiServer.apiRoot = this.stixService.sandbox['api-root'];
                this.taxiiServer.childRoot = environment.taxiiServer.url + 'taxii/' + environment.taxiiServer.apiVersion + '/' + this.stixService.sandbox['api-root'] + '/';

                let sandboxCollection = null;
                this.stixService.apiRoots2.find(r => {
                    const collection = r.collections.find(c => c.id === this.stixService.sandbox.collection);

                    if (collection) {
                        sandboxCollection = collection;
                        return true;
                    }
                    return false;
                })

                this.taxiiServer.collection = {
                    "id": this.stixService.sandbox.collection,
                    "title": sandboxCollection ? sandboxCollection.title : this.stixService.sandbox.collection,
                };
            }
        }
        else if (type === "cisa") {
            this.taxiiServer.url = environment.taxiiServer.url;
            this.taxiiServer.username = environment.taxiiServer.username;
            this.taxiiServer.password = environment.taxiiServer.password;
            this.taxiiServer.apiRoot = environment.customConfiguration.cisa.apiRoot;
            this.taxiiServer.childRoot = environment.taxiiServer.url + 'taxii/' + environment.taxiiServer.apiVersion + '/' + environment.customConfiguration.cisa.apiRoot + '/';

            let cisaCollection = null;
            this.stixService.apiRoots2.find(r => {
                const collection = r.collections.find(c => c.id === environment.customConfiguration.cisa.collectionId);

                if (collection) {
                    cisaCollection = collection;
                    return true;
                }
                return false;
            })

            this.taxiiServer.collection = {
                "id": environment.customConfiguration.cisa.collectionId,
                "title": cisaCollection ? cisaCollection.title : environment.customConfiguration.cisa.collectionId,
            };
        }
        else {
            this.APIRoots = [];
            this.selectedAPIRoot = null;
            this.apiRoots = [];
            this.selectedCollection = null;
            this.taxiiServer.apiRoot = null;
            this.taxiiServer.childRoot = null;
            this.taxiiServer.collection = null;
        }
    }

    showBundle(): void {
        this.showingBundle = !this.showingBundle;
    }

    showVisualizer(): void {
        this.showingVisualizer = !this.showingVisualizer;
    }

    updateSelectedObjects(id: string) {
        console.log(id);
        let len = this.selectedObjects.length;  // Starting length
        this.selectedObjects = this.selectedObjects.filter(x => x !== id);

        if (len === this.selectedObjects.length)    // If the object was not found (already checked)
            this.selectedObjects.push(id);
    }

    editObject(obj: any, custom: boolean): void {
        // if(obj.external_references && obj.kill_chain_phases){
        //     this.stixService.killChainExternalReferences = obj.external_references;
        // }
        // Navigate to page and pre-fill input fields
        if (obj.type === 'language-content') this.stixService.convertLanguageContentsToArray(obj);
        const new_version_objects = JSON.parse(localStorage.getItem("new_version_objects"));
        const isNewVersion = (new_version_objects)
            ? new_version_objects.includes(obj.id)
            : false;

        if (isNewVersion) {
            obj = JSON.parse(JSON.stringify(obj));
            if (obj.modified) {
                obj.modified = new Date().toISOString();
            }
        }

        localStorage.setItem("item-to-edit", JSON.stringify(obj));

        if (!this.stixService.guidedUI) {
            if (!custom) {
                if (['event', 'impact', 'task'].includes(obj.type)) {
                    this.stixService.currentType = obj.type;
                    this.router.navigate(['/incident-obj']);
                }
                else if (['malware-behavior', 'malware-method', 'malware-objective'].includes(obj.type)) {
                    this.stixService.currentType = obj.type;
                    this.router.navigate(['/malware-obj']);
                }
                else {
                    (isNewVersion)
                        ? this.router.navigate(['/add-component/' + obj.type], { queryParams: { new_version: true, fromBundle: true } })
                        : this.router.navigate(['/add-component/' + obj.type]);
                }
            }
            else {
                this.router.navigate(['/custom-object']);
            }
        }

        if (this.stixService.guidedUI) {
            const stixObj = this.stixObjects.find(so => {
                return so.objects.find(o =>
                    o.type === obj.type
                )
            })

            let menuSelection = 'additional-info-1';

            let cartObjData = this.findObjInCart(obj);

            if(cartObjData.found === true){
                switch(cartObjData.page){
                    case 'Incident':
                        this.guidedService.cart = this.guidedService.cachedCart[cartObjData.cartIndex];
                        localStorage.setItem('guided-cart', JSON.stringify(this.guidedService.cart));
                        localStorage.setItem('edit-cart', JSON.stringify(this.guidedService.editCart));

                        this.guidedService.currentCartIndex = cartObjData.cartIndex;
                        localStorage.setItem('cart-index', this.guidedService.currentCartIndex.toString());

                        this.guidedService.editCartObject = cartObjData.guidedObject;
                        this.guidedService.editCartObject.component = 'how-event-detection';

                        menuSelection = 'how-event-detection';

                        this.guidedService.cartLengths["total"] = 0;

                        for(let page in this.guidedService.cart){
                            if(page === 'relationships') continue;

                            let count = 0;
                            for(let type in this.guidedService.cart[page]){
                                count += this.guidedService.cart[page][type].length;
                            }
                            this.guidedService.cartLengths[page] = count;
                            this.guidedService.cartLengths["total"] += this.guidedService.cartLengths[page];
                        }
                        if (this.guidedService.cart["Incident"]) {
                            this.guidedService.cartLengths["how-event-detection"]++;
                        }
                        break;
                    default:
                        if (cartObjData.page === 'relationships') {
                            const cartIndex: any = cartObjData.guidedObject;
                            const relationshipObj = this.guidedService.cachedCart[cartObjData.cartIndex]?.relationships?.relationships[Number(cartIndex.index)];
                            if (relationshipObj) {
                                const sourceObj = relationshipObj.target_ref.startsWith("attack-pattern--") ? this.stixService.bundle.objects.find((obj) => obj.id === relationshipObj.target_ref) : this.stixService.bundle.objects.find((obj) => obj.id === relationshipObj.source_ref);
                                cartObjData = this.findObjInCart(sourceObj);
                            }
                        }

                        this.guidedService.cart = this.guidedService.cachedCart[cartObjData.cartIndex];
                        localStorage.setItem('guided-cart', JSON.stringify(this.guidedService.cart));
                        localStorage.setItem('edit-cart', JSON.stringify(this.guidedService.editCart));
                        this.guidedService.currentCartIndex = cartObjData.cartIndex;
                        localStorage.setItem('cart-index', this.guidedService.currentCartIndex.toString());

                        this.guidedService.editCartObject = cartObjData.guidedObject;
                        this.guidedService.editCartObject.component = cartObjData.page;
                        menuSelection = cartObjData.page;
                        this.guidedService.cartLengths["total"] = 0;

                        for(let page in this.guidedService.cart){
                            if(page === 'relationships') continue;

                            let count = 0;
                            for(let type in this.guidedService.cart[page]){
                                count += this.guidedService.cart[page][type].length;
                            }
                            this.guidedService.cartLengths[page] = count;
                            this.guidedService.cartLengths["total"] += this.guidedService.cartLengths[page];
                        }
                        if (this.guidedService.cart["Incident"]) {
                            this.guidedService.cartLengths["how-event-detection"]++;
                        }
                }
                
            }

            let data: any = {
                type: !custom || this.guidedService.currentCartIndex !== null ? 'edit-object' : 'edit-custom-object',
                value: obj,
                stixObj: stixObj,
                queryParams: isNewVersion ? { new_version: true, fromBundle: true } : undefined,
                menuSelection: menuSelection,
            }

            this.stixService.sendData(data);
        }
    }

    findObjInCart(obj){
        let result = {
            guidedObject: {},
            cartIndex: null,
            page: '',
            found: false
        }

        if(!this.guidedService.cachedCart || this.guidedService.cachedCart.length === 0){
            return result;
        }

        this.guidedService.editCart = {};
        for(let cartIndex in this.guidedService.cachedCart){
            for(let page in this.guidedService.cachedCart[cartIndex]){
                for(let type in this.guidedService.cachedCart[cartIndex][page]){
                    const pageArray =  this.guidedService.cachedCart[cartIndex][page][type];
                    // Not sure why = was being used in an if clause.
                    // if(pageArray.length > 0 && (obj.type = this.guidedService.cachedCart[cartIndex][page][type][0].type)){
                    if (pageArray.length > 0 && (obj.type == this.guidedService.cachedCart[cartIndex][page][type][0].type)) {
                        for(let i in this.guidedService.cachedCart[cartIndex][page][type]){
                            let cartObj = this.guidedService.cachedCart[cartIndex][page][type][i]
                            if(cartObj.id === obj.id){
                                result.found = true;
                                result.guidedObject = {
                                    component: page === 'Incident' ? 'how-event-detection' : page,
                                    key: type,
                                    index: i,
                                    specialFlag: page === 'Incident' ? true : false,
                                };
                                result.cartIndex = cartIndex;
                                result.page = page;

                                if(page === 'Incident'){
                                    let eventPage = 'how-event-detection';
                                    let eventType = 'Event';

                                    if(this.guidedService.cachedCart[cartIndex][eventPage] && this.guidedService.cachedCart[cartIndex][eventPage][eventType].length !== 0){
                                        this.guidedService.editCart[eventPage] = {
                                            'Event': []
                                        }

                                        for(let event of this.guidedService.cachedCart[cartIndex][eventPage][eventType]){
                                            this.guidedService.editCart[eventPage]['Event'].push(event);
                                        }
                                    }
                                }

                                for(let typeOnTargetPage in this.guidedService.cachedCart[cartIndex][page]){
                                    for(let item of this.guidedService.cachedCart[cartIndex][page][typeOnTargetPage]){
                                        if(this.guidedService.editCart[page] && this.guidedService.editCart[page][typeOnTargetPage]){
                                            this.guidedService.editCart[page][typeOnTargetPage].push(item);
                                        } else if (this.guidedService.editCart[page]) {
                                            this.guidedService.editCart[page][typeOnTargetPage] = [ item ];
                                        } else {
                                            this.guidedService.editCart[page] = {}
                                            this.guidedService.editCart[page][typeOnTargetPage] = [ item ];
                                        }

                                        if(!this.guidedService.cachedCart[cartIndex]['relationship']) continue;

                                        for(let relationship of this.guidedService.cachedCart[cartIndex]['relationship']['relationship']){
                                            if(relationship.source_ref === item.id){
                                                let found = false;

                                                for(let relInEditCart of this.guidedService.editCart['relationship']['relationship']){
                                                    if(relInEditCart.id === relationship.id){
                                                        found = true;
                                                        break;
                                                    }
                                                }

                                                if(found === false){
                                                    this.guidedService.editCart['relationship']['relationship'].push(relationship);
                                                }
                                            }
                                        }
                                    }
                                }
                                

                                return result;
                            }
                        }
                    }
                }
            }
        }

        return result;
    }

    disableEditObject(obj: any) {
        if (obj.type === 'marking-definition') {
            const new_version_objects = JSON.parse(localStorage.getItem("new_version_objects"));
            if (new_version_objects?.length > 0 
            && new_version_objects.includes(obj.id)) {
                return true;
            }
        }

        // if (this.guidedUI  && this.guidedService.cachedCart) {
        //     return this.guidedService.cachedCart.some(session => {
        //         if (!session.relationships || !session.relationships.relationships)
        //             return false;

        //         return session.relationships.relationships.some(rel => rel.id === obj.id)
        //     })
        // }

        return (obj.created_by_ref && (obj.created_by_ref !== environment.cisaIdentity.id && obj.created_by_ref !== localStorage.getItem('identity'))) || (obj.revoked === true);
    }

    getCustomObjectHeading(component: any): string {
        return component['type'] + ' (' + component['id'] + ')';
    }

    parseDeletionQueue(): void {
        for (let request of this.stixService.deletionQueue) {
            this.stixService.removeExtensionDefChildren(request[0], request[1], undefined, false, request[2]);
        }

        // Updates BundleComponent for children Custom Objects that were removed
        for (let customObj of this.stixService.removedCustomObj) {
            this.removeObject(customObj['id']);
        }
        this.stixService.removedCustomObj = [];
    }

    collectionsCanWriteTo() {
        // console.log(this.taxiiServer);
        // The below check is needed for Guided UI since it currently uses the deprecated local calls/variables
        console.log("oops");
        if (this.selectedAPIRoot){
            this.getCollectionsIds();
        }

        return this.taxiiServer.availableCollections.some(c => c.can_write === true);
    }

    showAnalyst1(): void {
        this.showingAnalyst1 = !this.showingAnalyst1;
    }

    getAnalyst1Objects() {
        this.loadingStix = true;

        // Original approach: Code for reusing query stix component
        // if (this.queryStix && false) {
        //     this.queryStix.isObjects = true;
        //     this.queryStix.showMainSearch = false;
        //     // this.queryStix.queryString = `?match[type]=indicator&match[value]=${this.searchTextAnalyst1}`;
        //     this.searchTextAnalyst1 = 'domain'; // test
        //     this.queryStix.queryString = `?match[type]=domain`; // test
        //     this.queryStix.selectedAPIRoot = 'Root2';
        //     this.queryStix.selectedCollection = "7d476dc7-5c2a-418b-b150-8b07cad20b43";
        //     this.queryStix.getObjects();
        // }

        // Current approach: API call to proxy and get selected objects when add to bundle
        let url = this.taxiiServer.url + 'A1/indicator/?searchTerm=' + this.searchTextAnalyst1 + '*&pageSize=20';

        this.httpClient.get<any>(url, { headers: this.httpHeadersJSON }).subscribe(
            (resp: any) => {
                console.log("Server response: ", resp);
                this.analyst1Objects = resp.results;
                this.analyst1Objects.forEach(o => {
                    o['checked'] = false;
                })
                this.analyst1currentPage = resp.page;
                this.analyst1TotalPages = resp.totalPages;
                this.loadingStix = false;
            }
        );
    }

    checkAllAnalyst1Objects(event) {
        this.analyst1Objects.forEach(o => {
            o.checked = event.target.checked;
        })
    }

    addAnalyst1ToBundle() {
        let objectReqs = [];
        this.addingStixToBundle = true;
        const checkedAnalyst1Objects = this.analyst1Objects.filter(o => o.checked).forEach(o => {
            let url = this.taxiiServer.url + 'A1/indicator/' + o.id + '/stix/';
            objectReqs.push(
                this.httpClient.get<any>(url, { headers: this.httpHeadersJSON })
            )
        })

        forkJoin(objectReqs).subscribe((resp: any) => {
            let respNum = 0;
            if (resp.length > 0) {
                resp.forEach(r => {
                    respNum++
                    if (respNum === resp.length) {
                        this.addingStixToBundle = false;
                    }
                    r.objects.forEach(o => {
                        this.stixService.addComponent(o);
                    })
                })
            }
        },
            (err: any) => {
                console.error(err, "Error requesting individual Analyst1 object.");
            });
    }

    updateAnalyst1Checked(object) {
        object.checked = !object.checked;
    }

    showNextAnalyst1Page() {
        return this.analyst1currentPage < this.analyst1TotalPages;
    }

    getNextAnalyst1Page() {
        this.loadingStixNext = true;
        // API call to proxy and get selected objects when add to bundle
        let url = this.taxiiServer.url + 'A1/indicator/?searchTerm=' + this.searchTextAnalyst1 + '*&pageSize=20&page=' + (this.analyst1currentPage + 1);

        this.httpClient.get<any>(url, { headers: this.httpHeadersJSON }).subscribe(
            (resp: any) => {
                console.log("Server response: ", resp);
                this.analyst1Objects = resp.results;
                this.analyst1Objects.forEach(o => {
                    o['checked'] = false;
                })
                this.analyst1currentPage = resp.page;
                this.analyst1TotalPages = resp.totalPages;
                this.loadingStixNext = false;
            }
        );
    }

    enableAddToBundle() {
        return this.analyst1Objects.some(o => o.checked);
    }

    openAnalyst1Modal() {
        this.modalService.open(Analyst1DialogComponent, { size: 'analyst1' });
    }

    increaseCurrentStep() {
        if (this.stixService.publishToA1) {
            this.modalCurrentStep = this.modalCurrentStep + 2;
            return;
        }

        if (this.modalCurrentStep == 1) {
            this.apiRootsPublishTo();
        }
        this.modalCurrentStep = this.modalCurrentStep + 1
    }

    decreaseCurrentStep() {
        if (this.stixService.publishToA1) {
            this.modalCurrentStep = this.modalCurrentStep - 2;
            return;
        }

        this.modalCurrentStep = this.modalCurrentStep - 1
        if (this.modalCurrentStep <= 2) {
            this.publishA1 = false;
        }
    }

    selectApiRoot(apiRoot) {
        this.selectedAPIRoot = apiRoot;
        this.taxiiServer.apiRoot = apiRoot.title;
        this.taxiiServer.childRoot = apiRoot.childRoot;
        this.getCollectionsIds();
    }

    apiRootsPublishTo() {
        console.log("hit");
        let that = this;
        this.stixService.apiRoots2 = this.stixService.apiRoots2.filter(function (item, pos) {
            return that.stixService.apiRoots2.indexOf(item) == pos;
        })

        this.apiRoots = this.stixService.apiRoots2.filter(r =>
            r.collections.some(c => c.can_write)
        );
        console.log(this.apiRoots);
        console.log(this.stixService.apiRoots2);
        // NOT ACTING FRIENDLY. RESULTS IN ERROR IN CONSOLE
        // if (roots.length == 1) {
        //     this.selectApiRoot(roots[0]);
        // }

        return this.apiRoots;
    }

    selectAcsMarking(event) {
        this.stixService.acsSelection = event.target.value;
    }

    async applyACS() {
        this.stixService.acsObject = ACS_OBJECTS.find(o => o.type === this.stixService.acsSelection);

        if (this.stixService.acsObject) {
            if (this.stixService.taxiiServerType === 'ais'
                && this.stixService.acsObject.object
                && this.stixService.acsObject.object.extensions
                && this.stixService.acsObject.object.extensions['extension-definition--3a65884d-005a-4290-8335-cb2d778a83ce']
                && this.stixService.acsObject.object.extensions['extension-definition--3a65884d-005a-4290-8335-cb2d778a83ce']['responsible_entity_custodian']) {
                this.stixService.acsObject.object.extensions['extension-definition--3a65884d-005a-4290-8335-cb2d778a83ce']['responsible_entity_custodian'] = 'USA.DHS.NCCIC';
            }

            const acs_objs = new Set();
            let selected_acs_id = this.stixService.acsObject.object.id;
            for (let acs_obj of this.acsMarkingOptions) {
                acs_objs.add(acs_obj.object.id);
                await this.stixService.removeComponent(acs_obj.object.id);
            }

            this.stixService.addComponent(this.stixService.acsObject.object);
            // this.stixService.bundle.objects.forEach(o => {
            //     if (o.type !== 'marking-definition') {
            //         if (o && o.object_marking_refs) {
            //             o.object_marking_refs = o.object_marking_refs.filter((marking_ref) => !acs_objs.has(marking_ref));
            //             o.object_marking_refs.push(selected_acs_id)
            //         } else {
            //             o['object_marking_refs'] = [selected_acs_id];
            //         }
            //     }
            // })

            // const objectsToAdd = this.stixService.bundle.objects.map(object => {
            //     return {id: object.id, index: , object: object}
            // });
            let newBundle = await this.stixService.db.imxCache.orderBy("index").toArray();
            let acsIgnore = JSON.parse(localStorage.getItem('acsIgnore'));
            if (!acsIgnore)
                acsIgnore = [];
            acsIgnore = new Set([...acsIgnore]);

            newBundle.forEach(obj => {
                let o = obj.object;
                if (o.type !== 'marking-definition' && !acsIgnore.has(o.id)) {
                    if (o && o.object_marking_refs) {
                        o.object_marking_refs = o.object_marking_refs.filter((marking_ref) => !acs_objs.has(marking_ref));
                        o.object_marking_refs.push(selected_acs_id)
                    } else {
                        o['object_marking_refs'] = [selected_acs_id];
                    }
                }
            });

            await this.stixService.db.imxCache.bulkPut(newBundle);
        }

        this.isResetNeeded = false;
    }

    discardBundleHandler(message = null) {
        this.modalRef = this.modalService.open(MessageDialogComponent);

        message = message ? message : "Please confirm you would like to discard current bundle.";

        let messageData = {
            'title': 'Discard Bundle',
            'message': message,
        }

        this.modalRef.componentInstance.messageObj = messageData;

        this.modalRef.result.then((resp) => {
            switch (resp) {
                case 'confirm': {
                    this.clearBundle();
                    this.stixService.objectsToLoad = [];
                    const discardEvent = {
                        type: 'discard-bundle',
                    }
                    this.stixService.sendData(discardEvent);
                    setTimeout(() => {
                        this.discardBundle();
                    }, 500);
                    break;
                }
                default:
                    break;
            }
        });
    }

    getUnassignedObjects() {
        this.unassignedObjMarkingRefObjs = this.stixService.getUnassignedObjects();
    }

    bundleObjectType(type) {
        switch (type.key) {
            case 'UNASSIGNED':
                return this.bundle.objects.filter(o => {
                    const tlpObj = this.getTlp(o);
                    if (tlpObj.length === 0
                        || (tlpObj.some(o => o.type.toLowerCase() === 'acs') && tlpObj.length === 1)) {
                        return true;
                    }
                    return false;
                })
            case 'TLP:CLEAR':
            case 'TLP:GREEN':
            case 'TLP:AMBER':
            case 'TLP:AMBER+STRICT':
            case 'TLP:WHITE':
            case 'TLP:RED':
                const tlps = this.bundle.objects.filter(o => {
                    const tlpObj: any = this.getTlp(o);
                    if (tlpObj.length > 0 && tlpObj[tlpObj.length - 1].name === type.key) {
                        return true;
                    }
                    return false;
                })
                return tlps;
            default:
                return this.bundle.objects;
        }
    }

    countTLP() {
        this.tlpCounts = this.stixService.countTLP();
        this.tlpCounts.forEach(tlp => {
            if (tlp.count === 0)
                return;
            if (!this.tlpOptions.find(opt => opt.key === tlp.tlp.key)) {
                this.tlpOptions.push(tlp.tlp);
            }
        })
    }

    tlpShown(tlp) {
        return this.tlpCounts.find(obj => obj.tlp.key === tlp.key);
    }

    tlpCount(tlp) {
        let sum = 0
        sum += this.tlpCounts.reduce((currentVal, currentTlp) => {
            if (currentTlp.tlp.key === tlp.key) currentVal += currentTlp.count;
            return currentVal;
        }, 0);

        return (!sum) ? 0 : sum;
    }

    tlpMargin(tlp) {
       if(tlp.key === "TLP:AMBER+STRICT") {
        return 'strict';
       }
       if(tlp.key === "TLP:AMBER") {
        return 'amber';
       }
       return 'other';
    }

    tlpAccordianDataTargetId(tlp) {
        return 'collapse' + tlp.short;
    }

    selectGroupByTLP(event) {
        if (event && event.target && event.target.value) {
            this.isGroupByTLP = event.target.value === 'true';
            this.updateGroupByTLP();
        }
    }
        
    updateGroupByTLP() {
        this.getUnassignedObjects();
        this.countTLP();
    }

    openFullscreen() {
        if (this.elem.requestFullscreen) {
            this.elem.requestFullscreen();
        } else if (this.elem.mozRequestFullScreen) {
            // Firefox
            this.elem.mozRequestFullScreen();
        } else if (this.elem.webkitRequestFullscreen) {
            // Chrome, Safari and Opera
            this.elem.webkitRequestFullscreen();
        } else if (this.elem.msRequestFullscreen) {
            // IE/Edge
            this.elem.msRequestFullscreen();
        }
    }
    
    closeFullscreen() {
        if (this.document.exitFullscreen) {
            this.document.exitFullscreen();
        } else if (this.document.mozCancelFullScreen) {
            // Firefox
            this.document.mozCancelFullScreen();
        } else if (this.document.webkitExitFullscreen) {
            // Chrome, Safari and Opera
            this.document.webkitExitFullscreen();
        } else if (this.document.msExitFullscreen) {
            // IE/Edge
            this.document.msExitFullscreen();
        }
    }

    viewSavedBundles(){
        if(this.viewingSavedBundles === true){
            this.viewingSavedBundles = false;
        } else {
            this.viewingSavedBundles = true;
        }
    }

    

    findTlpColor(bundle){
        let result: String = '';

        for(let object of bundle){
            if(object.object_marking_refs){
                for(let ref of object.object_marking_refs){
                    for(let tlp of TLP_OPTIONS){
                        if(ref === tlp.id){
                            let nameArr = tlp.name.split(':');
                            let color = nameArr[1];
                            if(!result.includes(color)){
                                if(result !== '') result += ', ';
                                result += color;
                            }
                        }
                    }

                    for(let tlp of TLP20_OPTIONS){
                        if(ref === tlp.id){
                            let nameArr = tlp.name.split(':');
                            let color = nameArr[1];
                            if(!result.includes(color)){
                                if(result !== '') result += ', ';
                                result += color;
                            }
                        }
                    }
                }
            }
        }

        return result;
    }

    copyPaste(modal){
        this.savingBundle = false;
        this.isReset = false;
        const copyPasteRef = this.copyPasteDialog.open(CopyPasteDialogComponent, {
            data: {},
            minHeight: '150px',
            maxHeight: '1800px',
            width: `${window.innerWidth / 1.5}px`,
            panelClass: 'copy-paste-dialog-container',
            position: {
                top: '150px'
            }
        });

        copyPasteRef.afterClosed().subscribe(result => {
            if(result?.selectedObjects?.length !== 0){
                this.stixService.objectsToLoad = result.selectedObjects.objects;
                this.importObjects(modal);
            }
        });
    }

    loadVersioning(): void {
        const new_version = JSON.parse(localStorage.getItem('new_version_objects'));
        const submissionId = (this.loadingModalData?.destination === 'local')
            ? this.loadingModalData?.bundle?.submissionID
            : this.loadingModalData?.bundle?._source?.submissionID;
        const discarding = this.isDiscarding;

        if (!this.newVersionObjectsLoad || this.newVersionObjectsLoad.length === 0) {
            if (!submissionId)
                return console.log("Submission ID missing");
                
            this.submissions.getSubmissionByID(submissionId).then((res: any) => {
                const result = res.res;
                if (!result.successes)
                    result.successes = [];

                if (result.failures && result.failures.length > 0) {
                    const duplicates = result.failures.filter(f => f.message === 'duplicate collection/object/version\n');
                    if (duplicates && duplicates.length > 0) {
                        result['successes'] = [...result.successes, ...duplicates];
                    }
                }
                result.successes = result.successes.map(elem => elem.id);
                if (new_version && new_version.length > 0 && !discarding) {
                    result.successes.forEach(elem => {
                        if (!new_version.includes(elem))
                            new_version.push(elem);
                    })
                    localStorage.setItem('new_version_objects', JSON.stringify(new_version));
                } else
                    localStorage.setItem('new_version_objects', JSON.stringify(result.successes));
            });
            return;
        }
        
        if (discarding || !new_version || new_version.length === 0)
           return localStorage.setItem('new_version_objects', JSON.stringify(this.newVersionObjectsLoad));

        this.newVersionObjectsLoad.forEach(element => {
            if (!new_version.includes(element)) 
                new_version.push(element);
        });

        return localStorage.setItem('new_version_objects', JSON.stringify(new_version));
    }

    openStixConfig() {
        if (this.saveConfig)
        this.tempStixConfig = JSON.parse(JSON.stringify(this.stixConfig));

        this.saveConfig = false;
        this.configModalRef = this.modalService.open(this.stixModal, { ariaLabelledBy: "stix-config-modal", size: 'xl'});
        this.configModalRef.result.finally(() => {
        this.acsMarkingConfig = {
            classification: "",
            formal_determination: "",
            usage_permissions: "",
            privilege_action: "",
            usage_rule_effect: "",
            entity: "",
            entity_list: [],
            permitted_nationalities: "",
            permitted_nationalities_list: [],
            permitted_organizations: "",
            permitted_organizations_list: [],
            shareability: "",
            shareability_list: [],
            further_sharing: "",
            sharing_scope: "",
            sharing_scope_list: [],
            further_rule_effect: "",
            access_privilege_list: [],
            further_sharing_list: []
        };

        this.acsIsValid = false;
        this.acsErrors = {
            classification: "Classification must be filled",
            formal_determination: "Formal Determination must be selected",
            usage_permission: 'Usage Permission must be selected',
            further_sharing: 'Further Sharing must be selected'
        };

        this.enableAddValue = {
            entity_list: false,
            permitted_nationalities_list: false,
            permitted_organizations_list: false,
            shareability_list: false,
            sharing_scope_list: false
        }
        this.enableObj = {
            access_privilege: false,
            further_sharing: false
        }
        });
    }

    closeStixConfig() {
    if(this.configModalRef)
        this.configModalRef.dismiss();
    }

    generateAcs() {
        const acs = {
        type: "marking-definition",
        spec_version: "2.1",
        id: `marking-definition--${uuid()}`,
        created: new Date().toISOString(),
        extensions: {
            'extension-definition--3a65884d-005a-4290-8335-cb2d778a83ce': {
            extension_type: "property-extension",
            identifier: "isa:guide.19001.DFTA-a9bdb603-7f4f-44c6-acb0-d47f614e64b2",
            create_date_time: new Date().toISOString(),
            responsible_entity_custodian: "USA.DHS.CISA.CSD.TH",
            responsible_entity_originator: "USA.DHS.CISA.CSD.TH",
            policy_reference: `urn:isa:policy:acs:ns:v3.0?privdefault=${this.acsMarkingConfig.usage_permissions.toLowerCase()}&sharedefault=${this.acsMarkingConfig.further_sharing.toLowerCase()}`,
            control_set: {
                classification: this.acsMarkingConfig.classification
            },
            authority_reference: [
                "urn:isa:authority:ais"
            ]
            }
        }
        }
    
        const acs_type: string = localStorage.getItem("acs-type");
        let acs_types: Record<string, string>;

        if (!acs_type)
            acs_types = {};
        else
            acs_types = JSON.parse(acs_type);

        if (this.acsMarkingConfig.formal_determination === 'Federal Entities') {
            acs.extensions['extension-definition--3a65884d-005a-4290-8335-cb2d778a83ce'].control_set['formal_determination'] = ["INFORMATION-DIRECTLY-RELATED-TO-CYBERSECURITY-THREAT"];
            acs_types[acs.id] = 'Federal Entities';
        } else if (this.acsMarkingConfig.formal_determination === 'Federal and non-Federal Entities') {
            acs.extensions['extension-definition--3a65884d-005a-4290-8335-cb2d778a83ce'].control_set['formal_determination'] = ["INFORMATION-DIRECTLY-RELATED-TO-CYBERSECURITY-THREAT","FOUO","AIS"];
            acs_types[acs.id] = 'Federal and non-Federal Entities';
        } else if (this.acsMarkingConfig.formal_determination === 'Publicly Releasable') {
            acs.extensions['extension-definition--3a65884d-005a-4290-8335-cb2d778a83ce'].control_set['formal_determination'] = ["INFORMATION-DIRECTLY-RELATED-TO-CYBERSECURITY-THREAT","PUBREL"];
            acs_types[acs.id] = 'Publicly Releasable';
        }

        localStorage.setItem("acs-type", JSON.stringify(acs_types));

        if (this.acsMarkingConfig.access_privilege_list.length > 0) {
        acs.extensions['extension-definition--3a65884d-005a-4290-8335-cb2d778a83ce']['access_privilege'] = this.acsMarkingConfig.access_privilege_list
        }
        if (this.acsMarkingConfig.further_sharing_list.length > 0) {
        acs.extensions['extension-definition--3a65884d-005a-4290-8335-cb2d778a83ce']['further_sharing'] = this.acsMarkingConfig.further_sharing_list
        }
    
        this.tempStixConfig.acsMarkings = acs;
        this.applyCustomAcs();
    }
    
    validateAcsMarking() {
        let valid = true;
        if (!this.acsMarkingConfig.classification) {
        this.acsErrors.classification = "Classification must be filled";
        valid = false;
        } else {
        this.acsErrors.classification = '';
        }
    
        if (!this.acsMarkingConfig.formal_determination) {
            this.acsErrors.formal_determination = "Formal Determination must be selected";
            valid = false;
        } else {
            this.acsErrors.formal_determination = "";
        }
    
        if (!this.acsMarkingConfig.usage_permissions) {
        this.acsErrors.usage_permission = 'Usage Permissions must be selected';
        valid = false
        } else {
        this.acsErrors.usage_permission = '';
        }
    
        if (!this.acsMarkingConfig.further_sharing) {
        this.acsErrors.further_sharing = 'Further Sharing must be selected';
        valid = false
        } else {
        this.acsErrors.further_sharing = '';
        }
    
        this.acsIsValid = valid;
    }
    
    validateAddString(prop) {
        const propList = `${prop}_list`;
    
        if (!this.acsMarkingConfig[prop]) {
        this.enableAddValue[propList] = false;
        return;
        }
    
        if (this.acsMarkingConfig[propList].length > 0 && this.acsMarkingConfig[prop] === "ALL") {
        this.enableAddValue[propList] = false;
        return;
        }
    
        if (this.acsMarkingConfig[propList].includes("ALL")) {
        this.enableAddValue[propList] = false;
        return;
        }
    
        if (this.acsMarkingConfig[propList].includes(this.acsMarkingConfig[prop])) {
        this.enableAddValue[propList] = false;
        } else {
        this.enableAddValue[propList] = true;
        }
    }
    
    addString(prop: ListProperties) {
        const propList = `${prop}_list`;
        this.acsMarkingConfig[propList].push(this.acsMarkingConfig[prop]);
        this.acsMarkingConfig[prop] = '';
        this.enableAddValue[propList] = false;
        this.validateAddObj();
    
        if (prop === "formal_determination")
        this.validateAcsMarking();
    }
    
    removeString(prop: keyof AcsConfig, value) {
        const propList = `${prop}_list`;
        this.acsMarkingConfig[propList] = this.acsMarkingConfig[propList].filter(elem => elem !== value);
        this.validateAddString(prop);
        this.validateAddObj();
    
        if (prop === "formal_determination")
            this.validateAcsMarking();
    }
    
    validateAddObj() {
        if (!this.acsMarkingConfig.privilege_action || !this.acsMarkingConfig.usage_rule_effect || (this.acsMarkingConfig.entity_list.length === 0 && this.acsMarkingConfig.permitted_nationalities_list.length === 0 && this.acsMarkingConfig.permitted_organizations_list.length === 0 && this.acsMarkingConfig.shareability_list.length === 0)) {
        this.enableObj.access_privilege = false;
        } else {
        this.enableObj.access_privilege = true;
        }
    
        if (this.acsMarkingConfig.sharing_scope_list.length === 0 || !this.acsMarkingConfig.further_rule_effect) {
        this.enableObj.further_sharing = false;
        } else {
        this.enableObj.further_sharing = true;
        }
    }
    
    //Need to lowercase
    addObj(prop: "access_privilege_list" | "further_sharing_list") {
        if (prop === 'access_privilege_list') {
        const privilege_scope: PrivilegeScope = {};
        if (this.acsMarkingConfig.entity_list.length > 0)
            privilege_scope.entity = this.acsMarkingConfig.entity_list;
        if (this.acsMarkingConfig.permitted_nationalities_list.length > 0)
            privilege_scope.permitted_nationalities = this.acsMarkingConfig.permitted_nationalities_list;
        if (this.acsMarkingConfig.permitted_organizations_list.length > 0)
            privilege_scope.permitted_organizations = this.acsMarkingConfig.permitted_organizations_list;
        if (this.acsMarkingConfig.shareability_list.length > 0)
            privilege_scope.shareability = this.acsMarkingConfig.shareability_list;
    
        
        const access_privilege: AccessPrivilege = {
            privilege_action: this.acsMarkingConfig.privilege_action,
            rule_effect: this.acsMarkingConfig.usage_rule_effect.toLowerCase() as 'deny' | 'permit'
        };
    
        if (JSON.stringify(privilege_scope) !== '{}')
            access_privilege.privilege_scope = privilege_scope;
    
        this.acsMarkingConfig.access_privilege_list.push(JSON.parse(JSON.stringify(access_privilege)));
        this.access_privilege_collapse.push(false);
    
        this.acsMarkingConfig.privilege_action = '';
        this.acsMarkingConfig.usage_rule_effect = '';
        this.acsMarkingConfig.entity = '';
        this.acsMarkingConfig.permitted_nationalities = '';
        this.acsMarkingConfig.permitted_organizations = '';
        this.acsMarkingConfig.shareability = '';
        this.acsMarkingConfig.entity_list = [];
        this.acsMarkingConfig.permitted_nationalities_list = [];
        this.acsMarkingConfig.permitted_organizations_list = [];
        this.acsMarkingConfig.shareability_list = [];
        this.enableAddValue.entity_list = false;
        this.enableAddValue.permitted_nationalities_list = false;
        this.enableAddValue.permitted_organizations_list = false;
        this.enableAddValue.shareability_list = false;
        this.enableObj.access_privilege = false;
        } else {
        const further_sharing: FurtherSharing = {
            sharing_scope: this.acsMarkingConfig.sharing_scope_list,
            rule_effect: this.acsMarkingConfig.further_rule_effect.toLowerCase() as 'deny' | 'permit'
        };
    
        this.acsMarkingConfig.further_sharing_list.push(JSON.parse(JSON.stringify(further_sharing)));
        this.further_sharing_collapse.push(false);
    
        this.acsMarkingConfig.sharing_scope = '';
        this.acsMarkingConfig.further_rule_effect = '';
        this.acsMarkingConfig.sharing_scope_list = [];
        this.enableAddValue.sharing_scope_list = false;
        this.enableObj.further_sharing = false;
        }
        this.validateAcsMarking();
    }
    
    removeObj(type: 'access_privilege' | 'further_sharing', index) {
        this.acsMarkingConfig[`${type}_list`].splice(index, 1);
        this[`${type}_collapse`].splice(index, 1);
        this.validateAcsMarking();
    }
    
    collapseJson(type: "access_privilege_collapse" | "further_sharing_collapse", index: number) {
        this[type][index] = !this[type][index];
    }

    async applyCustomAcs() {
        this.stixService.acsObject = {
            object: this.tempStixConfig.acsMarkings
        };

        if (this.stixService.acsObject) {
            if (this.stixService.taxiiServerType === 'ais'
                && this.stixService.acsObject.object
                && this.stixService.acsObject.object.extensions
                && this.stixService.acsObject.object.extensions['extension-definition--3a65884d-005a-4290-8335-cb2d778a83ce']
                && this.stixService.acsObject.object.extensions['extension-definition--3a65884d-005a-4290-8335-cb2d778a83ce']['responsible_entity_custodian']) {
                this.stixService.acsObject.object.extensions['extension-definition--3a65884d-005a-4290-8335-cb2d778a83ce']['responsible_entity_custodian'] = 'USA.DHS.NCCIC';
            }

            const acs_objs = new Set();
            let selected_acs_id = this.stixService.acsObject.object.id;

            for (let o of this.stixService.bundle.objects) {
                if (o.type === 'marking-definition' && o.extensions) {
                    if (o.extensions['extension-definition--3a65884d-005a-4290-8335-cb2d778a83ce']) {
                        acs_objs.add(o.id);
                        await this.stixService.removeComponent(o.id);
                    }
                }
            }

            await this.stixService.addComponent(this.stixService.acsObject.object);

            let newBundle = await this.stixService.db.imxCache.orderBy("index").toArray();
            let acsIgnore = JSON.parse(localStorage.getItem('acsIgnore'));
            if (!acsIgnore)
                acsIgnore = [];
            acsIgnore = new Set([...acsIgnore]);

            //Determining what acs marking def ids are publicly releasable, fed and non fed, or fed only
            const acs_type = localStorage.getItem("acs-type");
            let acs_types: Record<string, string>;
            if (acs_type)
                acs_types = JSON.parse(acs_type);

            newBundle.forEach(obj => {
                let o = obj.object;
                if (o.type !== 'marking-definition' && !acsIgnore.has(o.id)) {
                    if (o && o.object_marking_refs) {
                        o.object_marking_refs = o.object_marking_refs.filter((marking_ref) => !acs_objs.has(marking_ref));
                        o.object_marking_refs.push(selected_acs_id)
                    } else {
                        o['object_marking_refs'] = [selected_acs_id];
                    }
                }
            });

            await this.stixService.db.imxCache.bulkPut(newBundle);
        }

        this.isResetNeeded = false;
        this.closeStixConfig();
    }
}
