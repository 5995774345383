<div
    class="d-flex justify-content-center align-items-start content-wrapper">
    <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="d-flex justify-content-center align-items-center mt-4">
            <strong class="title-size2">Would you like to add additional CTI?</strong>
            </div>
            <fa-icon class="check-circle-another-object" [icon]="faQuestionCircle">
            </fa-icon>
        </div>
        <div class="d-flex justify-content-center mt-3">
            <button type="button" class="btn-clear btn-100" style="color: black"
                (click)="createAnotherObject = true; onAddObject()"
                [ngClass]="{'btn-clear-blue': createAnotherObject === true}">
                Yes
            </button>

            <button type="button" class="btn-clear btn-100 ms-4" style="color: black"
                (click)="createAnotherObject = false; onAddObject()"
                [ngClass]="{'btn-clear-blue': createAnotherObject === false}">
                No
            </button>
        </div>
    </div>

    <app-add-component #addComponent style="display: none"
        [objectSelectionInput]="objectSelection"
    >
    </app-add-component>
</div>