<div [formGroup]="form" class="pb-2"
    [ngClass]="{'pe-2':!stixService.guidedUI}">
    <div [ngSwitch]="question.controlType" [ngClass]="{'w-100': stixService.guidedUI}" id="questions">
        <!-- Textbox question -->
        <div *ngSwitchCase="'textbox'" [ngClass]="{'display-flex guided-div-border': stixService.guidedUI}">
            <!-- <span class="tooltipright input-group-text"> -->
            <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                <!-- <b title="{{description}}">{{ questionLabel(question) }}</b> -->
                <!-- style="float:left"  -->
                <p class="float-start nowrap" title="{{description}}">{{ questionLabel(question) }}</p>
                <!-- <span *ngIf="showInfoIcon"> -->
                <span class="float-start icon-height" *ngIf="tooltip">
                    <!-- <fa-icon (click)="openTooltipModal(confidenceTooltip)" class="ps-1" [icon]="faInfoCircle">
                    </fa-icon> -->
                    <fa-icon class="ps-1" [icon]="faInfoCircle">
                    </fa-icon>
                    <div [ngSwitch]="question.key">
                        <div *ngSwitchCase="'confidence'" class="toolttext" style="width:250px">
                            <div class="tooltip-wrapper p-1">
                                <h4>Confidence Scales</h4>
                                <!-- <div>The confidence property reflects the object creator's trust <br> in the accuracy of their data.
                                    The confidence value MUST <br> be a number in the range of 0-100.</div> -->
                                <div [innerHTML]="tooltip"></div>
                                <div class="tooltip-content">
                                    <table>
                                        <tr>
                                            <th>Low/Med/High</th>
                                            <th>Range of Values</th>
                                        </tr>
                                        <tr>
                                            <td>Low</td>
                                            <td>1-29</td>
                                        </tr>
                                        <tr>
                                            <td>Med</td>
                                            <td>30-69</td>
                                        </tr>
                                        <tr>
                                            <td>High</td>
                                            <td>70-100</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchDefault class="toolttext" [innerHTML]="tooltip">
                        </div>
                    </div>
                </span>
            </span>
            <ng-container [ngSwitch]="question.key">
                <input *ngSwitchCase="'type'" class="form-control" [ngClass]="{'m-0 display-flex guided-input-border':stixService.guidedUI}" [formControlName]="question.key" [id]="question.key"
                    [type]="question.type" [readonly]="isTypeReadOnly || this.stixService.revoked">
                <input *ngSwitchCase="'spec_version'" class="form-control" [ngClass]="{'m-0 display-flex guided-input-border':stixService.guidedUI}" [formControlName]="question.key"
                    [id]="question.key" [type]="question.type"
                    [readonly]="isSpecVersionReadOnly || this.stixService.revoked">
                <ng-container *ngIf="question.key=='id' && !question.value.includes('marking-definition--')">
                    <input *ngSwitchCase="'id'" class="form-control" [ngClass]="{'guided-input-border':stixService.guidedUI}" [formControlName]="question.key"
                        [id]="question.key" [type]="question.type"
                        [readonly]="isIDReadOnly || this.stixService.revoked">
                </ng-container>
                <textarea *ngSwitchCase="'content'" class="form-control" [formControlName]="question.key"
                    [id]="question.key" rows="5" wrap="soft" [ngClass]="{'m-0 guided-input-border':stixService.guidedUI}"
                    [readonly]="(question.readonly || this.stixService.revoked)" (ngModelChange)="inputUpdate($event)"></textarea>
                <textarea *ngSwitchCase="'explanation'" class="form-control" [formControlName]="question.key"
                    [id]="question.key" rows="5" wrap="soft" [ngClass]="{'m-0 guided-input-border':stixService.guidedUI}"
                    [readonly]="(question.readonly || this.stixService.revoked)"></textarea>
                <textarea *ngSwitchCase="'body'" class="form-control" [formControlName]="question.key"
                    [id]="question.key" rows="5" wrap="soft" [ngClass]="{'m-0 guided-input-border':stixService.guidedUI}"
                    [readonly]="(question.readonly || this.stixService.revoked)"></textarea>
                <textarea *ngSwitchCase="'command_line'" class="form-control" [formControlName]="question.key"
                    [id]="question.key" rows="5" wrap="soft" [ngClass]="{'m-0 guided-input-border':stixService.guidedUI}"
                    [readonly]="(question.readonly || this.stixService.revoked)" (ngModelChange)="inputUpdate($event)"></textarea>
                <textarea *ngSwitchCase="'url_value'" class="form-control" [formControlName]="question.key"
                    [id]="question.key" rows="5" wrap="soft" [ngClass]="{'m-0 guided-input-border':stixService.guidedUI}"
                    [readonly]="(question.readonly || this.stixService.revoked)"></textarea>
                <textarea *ngSwitchCase="'description'" class="form-control" [formControlName]="question.key"
                    [id]="question.key" rows="5" wrap="soft" [ngClass]="{'m-0 guided-input-border':stixService.guidedUI}"
                    [readonly]="(question.readonly || this.stixService.revoked)"></textarea>
                <textarea *ngSwitchCase="'incident_description'" class="form-control" [formControlName]="question.key"
                    [id]="question.key" rows="8" wrap="soft" [ngClass]="{'m-0 guided-input-border':stixService.guidedUI}"
                    [readonly]="(question.readonly || this.stixService.revoked)"></textarea>
                <textarea *ngSwitchCase="'payload_bin'" class="form-control" [formControlName]="question.key"
                    [id]="question.key" rows="5" wrap="soft" [ngClass]="{'m-0 guided-input-border':stixService.guidedUI}"
                    [readonly]="(question.readonly || this.stixService.revoked)" (ngModelChange)="inputUpdate($event)"></textarea>
                <input *ngSwitchDefault class="form-control" [ngClass]="{'m-0 guided-input-border':stixService.guidedUI}" [formControlName]="question.key" [id]="question.key"
                    [type]="question.type" [readonly]="(question.readonly || this.stixService.revoked)" (ngModelChange)="inputUpdate($event)">
            </ng-container>
        </div>

        <!-- Indicator Pattern (No Readonly)-->
        <div *ngSwitchCase="'pattern'" [ngClass]="{'display-flex': stixService.guidedUI}" >
            <span class="tooltipright" [ngClass]="{'display-flex guided-div-border guided-button mt-1 pe-`': stixService.guidedUI}">
                <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                <span class="float-start" *ngIf="tooltip" [ngClass]="{'pe-1':stixService.guidedUI}">
                    <fa-icon class="ps-1" [icon]="faInfoCircle">
                    </fa-icon>
                    <div [ngSwitch]="question.key">
                        <div *ngSwitchDefault class="toolttext" [innerHTML]="tooltip">
                        </div>
                    </div>
                </span>
            </span>
            <!-- <textarea *ngIf="stixService.guidedUI" class="form-control guided-input-border" [formControlName]="question.key"
                placeholder="Use pattern builder to create pattern" [value]="question.value" [id]="question.key"
                rows="5" wrap="soft" [readonly]="(question.readonly || this.stixService.revoked)"></textarea> -->
            <div class="input-group">
                <!-- *ngIf="!stixService.guidedUI" -->
                <textarea  class="form-control" [ngClass]="{'guided-input-border':stixService.guidedUI}" [formControlName]="question.key"
                    placeholder="Use pattern builder to create pattern -->" [value]="question.value" [id]="question.key"
                    rows="5" wrap="soft" [readonly]="(question.readonly || this.stixService.revoked)"></textarea>
                <button class="btn btn-fa-sm btn-primary add-indicator-pattern-button" type="button"
                    style="padding-top:6px;" (click)="openModal('stixPatternBuilder')"
                    [disabled]="(question.readonly || this.stixService.revoked)">
                    <fa-icon [icon]="faPlus"></fa-icon>
                    <!-- Create Pattern -->
                </button>
            </div>  
        </div>

        <!-- Body Multipart (No Readonly) -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'body-multipart'" [ngClass]="{'display-flex': stixService.guidedUI}">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0" [ngClass]="{'display-inline-flex':stixService.guidedUI}">
                            <div [ngClass]="{'float-start display-flex guided-div-border guided-button':stixService.guidedUI}">
                                <span class="nowrap" [ngClass]="{' mt-1 pe-1': stixService.guidedUI}">
                                    <p>{{ questionLabel(question) }}</p>
                                </span>
                                <input *ngIf="stixService.guidedUI" type="text" class="form-control guided-input-border" [formControlName]="question.key"
                                    [id]="question.key" [placeholder]="question.placeholder" [value]="question.value"
                                    readonly=true style="text-align: center;">
                            </div>
                            <div class="input-group" [ngClass]="{'float-start':stixService.guidedUI}">
                                <input *ngIf="!stixService.guidedUI" type="text" class="form-control" [formControlName]="question.key"
                                    [id]="question.key" [placeholder]="question.placeholder" [value]="question.value"
                                    readonly=true style="text-align: center;">
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                    <button class="btn btn-fa-sm btn-primary" type="button"
                                        (click)="openModal('body_multipart')"
                                        [disabled]="(question.readonly || this.stixService.revoked || this.form.controls['is_multipart'].value.toString() === 'false')">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="this.stixService.modalObjectArray && this.stixService.modalObjectArray.length > 0"
                    class="col-12 p-0">
                    <div class="form-group mt-2 bump">
                        <accordion>
                            <accordion-group *ngFor="let x of this.stixService.modalObjectArray; let i = index"
                                [heading]="getBody_MultipartHeading(x)" [panelClass]="'accordion-panel-class'">
                                <div *ngIf="!this.stixService.customObjReadOnly" class="row">
                                    <div class="col-md-2">
                                        <button type="button" class="btn btn-fa btn-primary me-2"
                                            (click)="editModalObject(x, 'body_multipart', i)">
                                            <fa-icon [icon]="faEdit"></fa-icon>
                                        </button>
                                        <button type="button" class="btn btn-fa btn-warning"
                                            style="background-color:rgb(210, 0, 0)" (click)="deleteModalObject(x)">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                                <pre>{{ x | json }}</pre>
                            </accordion-group>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>

        <!-- Windows Registry Value Type (No Readonly) -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'windows-value'" [ngClass]="{'display-flex': stixService.guidedUI}">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0" [ngClass]="{'display-inline-flex':stixService.guidedUI}">
                            <div [ngClass]="{'float-start display-flex guided-div-border guided-button':stixService.guidedUI}">
                                <span class="" [ngClass]="{'mt-1 pe-1': stixService.guidedUI}">
                                    <p class="nowrap">{{ questionLabel(question) }}</p>
                                </span>
                                <input *ngIf="stixService.guidedUI" type="text" class="form-control guided-input-border" [formControlName]="question.key"
                                    [id]="question.key" [placeholder]="question.placeholder" [value]="question.value"
                                    readonly=true style="text-align: center;">
                            </div>
                            <div class="input-group" [ngClass]="{'float-start':stixService.guidedUI}">
                                <input *ngIf="!stixService.guidedUI" type="text" class="form-control" [formControlName]="question.key"
                                    [id]="question.key" [placeholder]="question.placeholder" [value]="question.value"
                                    readonly=true style="text-align: center;">
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                    <button class="btn btn-fa-sm btn-primary  " type="button"
                                        (click)="openModal('windows_value')"
                                        [disabled]="(question.readonly || this.stixService.revoked)">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <span *ngIf="this.stixService.modalObjectArray && this.stixService.modalObjectArray.length > 0"
                    class="tab"></span>
                <!--Does not work-->

                <div *ngIf="this.stixService.modalObjectArray && this.stixService.modalObjectArray.length > 0"
                    class="col-12 p-0">
                    <div class="form-group mt-2 bump">
                        <accordion>
                            <accordion-group *ngFor="let x of this.stixService.modalObjectArray; let i = index;"
                                [heading]="x.name || 'No Name'" [panelClass]="'accordion-panel-class'">
                                <div *ngIf="!this.stixService.customObjReadOnly" class="row">
                                    <div class="col-md-2">
                                        <button type="button" class="btn btn-fa btn-primary me-2"
                                            (click)="editModalObject(x, 'windows_value', i)">
                                            <fa-icon [icon]="faEdit"></fa-icon>
                                        </button>
                                        <button type="button" class="btn btn-fa btn-warning"
                                            style="background-color:rgb(210, 0, 0)" (click)="deleteModalObject(x);">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                                <pre>{{ x | json }}</pre>
                            </accordion-group>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>

        <!-- Language Contents Type -->
        <div class="input-group w-100 ms-15 p-0" *ngSwitchCase="'language-contents'">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0">
                            <span class="tooltipright">
                                <p class="float-start" >{{ questionLabel(question) }}</p>
                                <span class="float-start" *ngIf="tooltip">
                                    <fa-icon class="ps-1" [icon]="faInfoCircle">
                                    </fa-icon>
                                    <div class="toolttext" [innerHTML]="tooltip">
                                    </div>
                                </span>
                            </span>
                            <div class="input-group">
                                <input type="text" class="form-control" [formControlName]="question.key"
                                    [id]="question.key" [placeholder]="question.placeholder" [value]="question.value"
                                    readonly=true style="text-align: center;">
                                <ng-container *ngIf="!question.readonly">
                                    <button class="btn btn-fa-sm btn-primary" type="button" (click)="openLanguageModal()"
                                        [disabled]="question.readonly || (this.stixService.modalObjectArray && this.stixService.modalObjectArray.length > 0)">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                        <!-- Create Contents -->
                                    </button>
                                </ng-container>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div *ngIf="this.stixService.modalObjectArray && this.stixService.modalObjectArray.length > 0"
                    class="col-12 p-0">
                    <div class="form-group mt-2 bump">
                        <accordion>
                            <accordion-group *ngFor="let x of this.stixService.modalObjectArray; let i = index;"
                                [heading]="x.lang" [panelClass]="'accordion-panel-class'">
                                <div *ngIf="!this.stixService.customObjReadOnly" class="row">
                                    <div class="col-md-2">
                                        <button type="button" class="btn btn-fa btn-primary me-2"
                                            (click)="editModalObject(x, 'language_contents', i)">
                                            <fa-icon [icon]="faEdit"></fa-icon>
                                        </button>
                                        <button type="button" class="btn btn-fa btn-warning"
                                            style="background-color:rgb(210, 0, 0)" (click)="deleteModalObject(x)">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                                <pre>{{ getContentsDisplay(x) | json }}</pre>
                            </accordion-group>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>

        <!-- String Array -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'string-array'" [ngClass]="{'display-flex':stixService.guidedUI}">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0" [ngClass]="{'display-inline-flex':stixService.guidedUI}">
                            <div [ngClass]="{'float-start display-flex guided-div-border guided-button':stixService.guidedUI}">
                                <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1':stixService.guidedUI}">
                                    <p class="float-start nowrap" title="{{description}}">{{ questionLabel(question) }}</p>
                                    <span class="float-start icon-height" *ngIf="tooltip">
                                        <fa-icon class="ps-1" [icon]="faInfoCircle">
                                        </fa-icon>
                                        <div class="toolttext" [innerHTML]="tooltip">
                                        </div>
                                    </span>
                                </span>
                                <input *ngIf="stixService.guidedUI" class="form-control guided-input-border"  style="width:max-content" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)" >
                            </div>
                            <div class="input-group" [ngClass]="{'float-start':stixService.guidedUI}">
                                <input *ngIf="!stixService.guidedUI" class="form-control" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)">
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                    <button class="btn btn-fa-sm btn-primary" type="button" (click)="addString()"
                                        [disabled]="newString == '' || !isValid">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2 added-items-font"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)" tabindex="0">
                                {{ currentString }}
                                <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    *ngIf="!(question.readonly || this.stixService.revoked)"
                                    (click)="deleteString(currentString, question.key)"
                                    (keydown.enter)="deleteString(currentString, question.key)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Open Vocab Array -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'ov-array'" [ngClass]="{'display-flex':stixService.guidedUI}">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0" [ngClass]="{'display-inline-flex':stixService.guidedUI}">
                            <div [ngClass]="{'float-start display-flex guided-div-border guided-button':stixService.guidedUI}">
                                <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1':stixService.guidedUI}">
                                    <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                                    <span class="float-start icon-height" *ngIf="tooltip">
                                        <fa-icon class="ps-1" [icon]="faInfoCircle">
                                        </fa-icon>
                                        <div class="toolttext" [innerHTML]="tooltip">
                                        </div>
                                    </span>
                                </span>
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked) && stixService.guidedUI">
                                    <select [id]="question.key" *ngSwitchCase="'ov-array'" [(ngModel)]="newString"
                                        [formControlName]="question.key" class="form-control form-select ov-array guided-input-border">
                                        <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}
                                        </option>
                                    </select>
                                </ng-container>
                                <input *ngIf="(question.readonly || this.stixService.revoked) && stixService.guidedUI" class="form-control guided-input-border"
                                    [formControlName]="question.key" [id]="question.key" [type]="question.type"
                                    readonly>
                            </div>
                            <div class="input-group" [ngClass]="{'float-start':stixService.guidedUI}">
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked)  && !stixService.guidedUI">
                                    <select [id]="question.key" *ngSwitchCase="'ov-array'" [(ngModel)]="newString"
                                        [formControlName]="question.key" class="form-control form-select ov-array">
                                        <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}
                                        </option>
                                    </select>
                                </ng-container>
                                <input *ngIf="(question.readonly || this.stixService.revoked) && !stixService.guidedUI" class="form-control"
                                    [formControlName]="question.key" [id]="question.key" [type]="question.type"
                                    readonly>
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                    <button class="btn btn-fa-sm btn-primary" type="button" (click)="addString()"
                                        [disabled]="newString == ''|| !isValid">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2 added-items-font"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)" tabindex="0">
                                {{ currentString }}
                                <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    *ngIf="!(question.readonly || this.stixService.revoked)"
                                    (click)="deleteString(currentString, question.key)"
                                    (keydown.enter)="deleteString(currentString, question.key)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Datetime Picker -->
        <div class="" *ngSwitchCase="'datetime'" [ngClass]="{'display-flex guided-div-border' : stixService.guidedUI}">
            <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1' : stixService.guidedUI}">
                <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                <span class="float-start icon-height" *ngIf="tooltip">
                    <fa-icon class="ps-1" [icon]="faInfoCircle">
                    </fa-icon>
                    <div class="toolttext" [innerHTML]="tooltip">
                    </div>
                </span>
            </span>
            <div class="input-group">
                <ng-template [ngIf]="!(question.readonly || this.stixService.revoked)" [ngIfElse]="elseBlock">
                    <div class="col-8 p-0 pe-2">
                        <input class="form-control" [formControlName]="question.key" [ngClass]="{'guided-input-border' : stixService.guidedUI}"
                            [id]="question.key" [placeholder]="question.placeholder" [owlDateTimeTrigger]="dtPicker2" [owlDateTime]="dtPicker2">
                        <owl-date-time (afterPickerClosed)="this.isAddEnabled.emit()" #dtPicker2></owl-date-time>
                    </div>
                    <div class="col-4 p-0">
                        <select class="form-control form-select"
                            (change)="this.updateTimezone(question.key, timezone.value)" #timezone>
                            <option hidden selected style="color: rgb(177, 177, 177)" value="Select Time Zone">Select Time Zone</option>
                            <option *ngFor="let tz of this.stixService.timeZoneOptions" value="{{tz}}">{{this.stixService.formattedTimeZoneOptions[tz]}}</option>
                        </select>
                    </div>
                </ng-template>
                <ng-template #elseBlock>
                    <input class="form-control" [ngClass]="{'guided-input-border' : stixService.guidedUI}" [formControlName]="question.key" [id]="question.key"
                        [placeholder]="question.placeholder" readonly>
                </ng-template>
            </div>
        </div>

        <!-- Analysis SCO Ref Array (Does Not Exist?)-->
        <div class="input-group w-100 p-0" *ngSwitchCase="'analysis-array'" [ngClass]="{'display-flex': stixService.guidedUI}">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0" [ngClass]="{'display-flex': stixService.guidedUI}">
                            <span class="input-group-text">
                                <p>{{ questionLabel(question) }}</p>
                            </span>
                            <div class="input-group">
                                <input class="form-control" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString2" type="text">
                                <button class="btn btn-fa-sm btn-primary  " type="button" (click)="addString3()"
                                    [disabled]="newString3 == ''|| !isValid">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                    <!-- Add {{ questionLabel(question) }} -->
                                </button>
                            </div>
                        </div>
                        <!-- <div class="col text-end p-0">
                            
                        </div> -->
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)">
                                {{ currentString }}
                                <button class="btn btn-sm btn-danger" type="button"
                                    (click)="deleteString(currentString, question.key)">Del</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Dropdown -->
        <div class="" *ngSwitchCase="'dropdown'" [ngClass]="{'display-flex guided-div-border': stixService.guidedUI}">
            <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                <p class="float-start nowrap" *ngIf="revocation && question.key === 'revoked'"><mark>{{ questionLabel(question) }}</mark></p>
                <p class="float-start nowrap" *ngIf="!(revocation && question.key === 'revoked')">{{ questionLabel(question) }}</p>
                <span class="float-start icon-height" *ngIf="tooltip">
                    <fa-icon class="ps-1" [icon]="faInfoCircle">
                    </fa-icon>
                    <div class="toolttext" [innerHTML]="tooltip">
                    </div>
                </span>
            </span>
            <input *ngIf="(question.readonly || this.stixService.revoked)" class="form-control"
                [formControlName]="question.key" [id]="question.key" [type]="question.type" readonly>
            <select *ngIf="!(question.readonly || this.stixService.revoked)" [id]="question.key"
                [formControlName]="question.key" class="form-control form-select dropdown" [multiple]="question.multiple"
                [disabled]="(question.readonly || this.stixService.revoked)" [ngClass]="{'guided-input-border': stixService.guidedUI}"
                (ngModelChange)="inputUpdate($event)">
                <option value="" selected></option>
                <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option>
            </select>
        </div>

        <!-- Non Blank Dropdown -->
        <div class="" *ngSwitchCase="'nonblankdropdown'" [ngClass]="{'display-flex guided-div-border': stixService.guidedUI}">
            <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                <p class="float-start nowrap" *ngIf="revocation && question.key === 'revoked'"><mark>{{ questionLabel(question) }}</mark></p>
                <p class="float-start nowrap" *ngIf="!(revocation && question.key === 'revoked')">{{ questionLabel(question) }}</p>
                <span class="float-start icon-height" *ngIf="tooltip">
                    <fa-icon class="ps-1" [icon]="faInfoCircle">
                    </fa-icon>
                    <div class="toolttext" [innerHTML]="tooltip">
                    </div>
                </span>
            </span>
            <input *ngIf="(question.readonly || this.stixService.revoked)" class="form-control"
                [formControlName]="question.key" [id]="question.key" [type]="question.type" readonly>
            <select *ngIf="!(question.readonly || this.stixService.revoked)" [id]="question.key"
                [formControlName]="question.key" class="form-control form-select dropdown" [multiple]="question.multiple"
                [disabled]="(question.readonly || this.stixService.revoked)" (change)="isMultipart()"
                [ngClass]="{'guided-input-border':stixService.guidedUI}">
                <option hidden selected></option>
                <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option>
            </select>
        </div>

        <!-- Dropdown Summary (Not Used)-->
        <div class="input-group" *ngSwitchCase="'dropdownsum'">
            <span class="input-group-text">
                <b>{{ questionLabel(question) }}</b>
            </span>
            <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                <select [id]="question.key" [(ngModel)]='nrSelect' *ngSwitchCase="'dropdownsum'"
                    [formControlName]="question.key" class="form-control form-select dropdownsum">
                    <option value="false">False</option>
                    <option value="true">True</option>
                </select>
            </ng-container>
            <input *ngIf="(question.readonly || this.stixService.revoked)" class="form-control"
                [formControlName]="question.key" [id]="question.key" [type]="question.type" readonly>
        </div>

        <!-- Reference Array -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'reference-array'" [ngClass]="{'display-flex': stixService.guidedUI}">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0" [ngClass]="{'display-inline-flex':stixService.guidedUI}">
                            <div [ngClass]="{'float-start display-flex guided-div-border guided-button':stixService.guidedUI}">
                                <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                                    <p class="float-start nowrap" title="{{description}}">{{ questionLabel(question) }}</p>
                                    <span class="float-start icon-height" *ngIf="tooltip">
                                        <fa-icon class="ps-1" [icon]="faInfoCircle">
                                        </fa-icon>
                                        <div class="toolttext" [innerHTML]="tooltip">
                                        </div>
                                    </span>
                                </span>
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked) && stixService.guidedUI">
                                    <select [id]="question.key" *ngSwitchCase="'reference-array'"
                                        [formControlName]="question.key" class="form-control form-select dropdown guided-input-border"
                                        [(ngModel)]="newString">
                                        <option value="" selected></option>
                                        <option *ngFor="let opt of getBundleObjects()" [value]="opt['id']">
                                            {{ getObjDisplayName(opt) }}
                                        </option>
                                    </select>
                                </ng-container>
                                <input *ngIf="stixService.guidedUI" class="form-control guided-input-border" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)">
                            </div>
                            <div class="input-group" [ngClass]="{'float-start':stixService.guidedUI}">
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked) && !stixService.guidedUI">
                                    <select [id]="question.key" *ngSwitchCase="'reference-array'"
                                        [formControlName]="question.key" class="form-control form-select dropdown"
                                        [(ngModel)]="newString">
                                        <option value="" selected></option>
                                        <option *ngIf="dynamicForm.question_type === 'report'" [value]="'Add all objects'">Add all objects in the bundle</option>
                                        <option *ngFor="let opt of getBundleObjects()" [value]="opt['id']">
                                            {{ getObjDisplayName(opt) }}
                                        </option>
                                    </select>
                                </ng-container>
                                <input *ngIf="!stixService.guidedUI && dynamicForm.question_type !== 'report'" class="form-control" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)">
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                    <button class="btn btn-fa-sm btn-primary  " type="button" (click)="addString()"
                                        [disabled]="newString == ''|| !isValid">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2 added-items-font"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)" tabindex="0">
                                {{ currentString }}
                                <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    *ngIf="!(question.readonly || this.stixService.revoked)"
                                    (click)="deleteString(currentString, question.key)"
                                    (keydown.enter)="deleteString(currentString, question.key)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Relationship -->
        <div class="" *ngSwitchCase="'rel-type'" [ngClass]="{'w-100 display-inline-flex guided-div-border':stixService.guidedUI}">
            <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                <fa-icon class="ps-1 float-start icon-height" [icon]="faInfoCircle">
                </fa-icon>
                <span class="toolttext">Dropdown option will become available once
                    valid<br>Source Reference ID and Target Reference ID<br>are provided (per STIX 2.1 <a
                        href="https://docs.oasis-open.org/cti/stix/v2.1/cs02/stix-v2.1-cs02.html#_6n2czpjuie3v">Appendix
                        B</a>).<br>Use the text box on the right to manually<br>enter a custom relationship
                    type.</span>
            </span>
            <div class="input-group">
                <select *ngIf="!(question.readonly || this.stixService.revoked)" [id]="question.key"
                    [formControlName]="question.key" class="form-control form-select dropdown" [(ngModel)]="question.value"
                    [ngClass]="{'guided-input-border':stixService.guidedUI}">
                    <option value="" selected></option>
                    <option *ngFor="let opt of question.arrOptions" [value]="opt">{{opt}}</option>
                </select>
                <input class="form-control" [formControlName]="question.key" [id]="question.key" type="text"
                    [(ngModel)]="question.value" [readonly]="(question.readonly || this.stixService.revoked)"
                    [ngClass]="{'guided-input-border':stixService.guidedUI}">
            </div>
            
        </div>

        <!-- Bundle Objects -->
        <div class="" *ngSwitchCase="'bundle-obj'" [ngClass]="{'display-flex guided-div-border': stixService.guidedUI}">
            <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                <fa-icon class="ps-1 float-start" [icon]="faInfoCircle">
                </fa-icon>
                <span *ngIf="!tooltip" class="toolttext">
                    Dropdown option displays objects currently in the
                    bundle.<br>Use the text box on the right to manually<br>enter a custom relationship type.
                </span>
                <span *ngIf="tooltip">
                    <div class="toolttext" [innerHTML]="tooltip">
                    </div>
                </span>
            </span>
            <div class="input-group">
                <select *ngIf="!(question.readonly || this.stixService.revoked)" [id]="question.key"
                    [formControlName]="question.key" class="form-control form-select dropdown" [(ngModel)]="question.value"
                    [ngClass]="{'guided-input-border':stixService.guidedUI}" (ngModelChange)="inputUpdate($event)">
                    <option value="" selected></option>
                    <option *ngFor="let opt of getBundleObjects()" [value]="opt['id']">
                        {{ getObjDisplayName(opt) }}
                    </option>
                </select>
                <input [ngClass]="{'guided-input-border': stixService.guidedUI}" class="form-control" [formControlName]="question.key" [id]="question.key" type="text"
                    [(ngModel)]="question.value" [readonly]="(question.readonly || this.stixService.revoked)"
                    (ngModelChange)="inputUpdate($event)">
            </div>
            
        </div>

        <!-- Identity (Fix Readonly)-->
        <div class="" *ngSwitchCase="'identity'" [ngClass]="{'display-flex guided-div-border':stixService.guidedUI}">
            <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1':stixService.guidedUI}">
                <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                <span class="float-start" *ngIf="!tooltip" class="toolttext text-center">
                    Dropdown option displays identities currently in
                    the bundle.<br>Use the text box on the right to manually<br>enter a custom identity.
                </span>
                <span *ngIf="tooltip" class="">
                    <fa-icon class="ps-1" [icon]="faInfoCircle">
                    </fa-icon>
                    <div class="toolttext" [innerHTML]="tooltip">
                    </div>
                </span>
            </span>
            <select id="identities" [formControlName]="question.key" class="form-control form-select dropdown" [ngClass]="{'mt--2': !stixService.guidedUI, 'guided-input-border': stixService.guidedUI}"
                [(ngModel)]="question.relString" [disabled]="(question.readonly || this.stixService.revoked)">
                <option value=""></option>
                <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option>
            </select>
        </div>

        <!-- Sighting (Fix Readonly)-->
        <div class="" *ngSwitchCase="'sighting'" [ngClass]="{'display-flex guided-div-border':stixService.guidedUI}">
            <span class="tooltipright" [ngClass]="{'mt-1 pe-1':stixService.guidedUI}">
                <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                <span class="toolttext text-center">
                    Dropdown option displays SDOs currently in the
                    bundle.<br>Use the text box on the right to manually<br>enter a custom sighting ref.
                </span>
            </span>
            <div class="input-group">
                <select id="sightings" [formControlName]="question.key" class="form-control form-select dropdown"
                    [(ngModel)]="question.relString" [disabled]="(question.readonly || this.stixService.revoked)"
                    [ngClass]="{'guided-input-border': stixService.guidedUI}" (ngModelChange)="inputUpdate($event)">
                    <option *ngIf="!stixService.guidedUI" value=""></option>
                    <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option>
                </select>
                <input class="form-control" [formControlName]="question.key" [id]="question.key" type="text"
                    [(ngModel)]="question.relString" [readonly]="(question.readonly || this.stixService.revoked)"
                    [ngClass]="{'guided-input-border': stixService.guidedUI}" (ngModelChange)="inputUpdate($event)">
            </div>
            
        </div>

        <!-- SCO Refs -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'sco-refs'" [ngClass]="{'display-flex': stixService.guidedUI}">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0" [ngClass]="{'display-inline-flex':stixService.guidedUI}">
                            <div [ngClass]="{'float-start display-flex guided-div-border guided-button':stixService.guidedUI}">
                                <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                                    <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                                    <span class="float-start icon-height" >
                                        <fa-icon class="ps-1" [icon]="faInfoCircle">
                                        </fa-icon>
                                        <div class="toolttext" *ngIf="tooltip" [innerHTML]="tooltip">
                                        </div>
                                        <div *ngIf="!tooltip" class="toolttext">
                                            Dropdown option displays SCOs currently in the
                                            bundle.<br>Use the text box on the right to manually<br>enter a custom SCO
                                            ref.
                                        </div>
                                    </span>
                                </span>
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked) && stixService.guidedUI">
                                    <select [id]="question.key" *ngSwitchCase="'sco-refs'"
                                        [formControlName]="question.key" class="form-control form-select dropdown guided-input-border"
                                        [(ngModel)]="newString">
                                        <option value="" selected></option>
                                        <ng-container *ngFor="let opt of getBundleObjects()">
                                            <ng-container *ngIf="opt.id.match(objectRegex)">
                                                <option *ngIf="SCO_LIST.indexOf(opt.id.match(objectRegex)[1]) != -1"
                                                    [value]="opt['id']">
                                                    {{ getObjDisplayName(opt) }}
                                                </option>
                                            </ng-container>
                                        </ng-container>
                                    </select>
                                </ng-container>
                                <input *ngIf="stixService.guidedUI" class="form-control guided-input-border" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)">
                            </div>
                            <div class="input-group" [ngClass]="{'float-start':stixService.guidedUI}">
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked) && !stixService.guidedUI">
                                    <select [id]="question.key" *ngSwitchCase="'sco-refs'"
                                        [formControlName]="question.key" class="form-control form-select dropdown"
                                        [(ngModel)]="newString">
                                        <option value="" selected></option>
                                        <ng-container *ngFor="let opt of getBundleObjects()">
                                            <ng-container *ngIf="opt.id.match(objectRegex)">
                                                <option *ngIf="SCO_LIST.indexOf(opt.id.match(objectRegex)[1]) != -1"
                                                    [value]="opt['id']">
                                                    {{ getObjDisplayName(opt) }}
                                                </option>
                                            </ng-container>
                                        </ng-container>
                                    </select>
                                </ng-container>
                                <input *ngIf="!stixService.guidedUI" class="form-control" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)">
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                    <button class="btn btn-fa-sm btn-primary   " type="button" (click)="addString()"
                                        [disabled]="newString == ''|| !isValid">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-12 p-0" [ngClass]="{'display-flex': stixService.guidedUI}">
                            
                            <div class="input-group">
                                
                                

                                
                            </div>
                        </div>
                        <!-- <div class="col text-end p-0">
                            <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                <button class="btn btn-sm btn-primary   " type="button" (click)="addString()"
                                    [disabled]="newString == ''|| !isValid">
                                    Add
                                    Add Analysis SCO Refs
                                </button>
                            </ng-container>
                        </div> -->
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2 added-items-font"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)" tabindex="0">
                                {{ currentString }}
                                <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    *ngIf="!(question.readonly || this.stixService.revoked)"
                                    (click)="deleteString(currentString, question.key)"
                                    (keydown.enter)="deleteString(currentString, question.key)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Tooltip Textbox -->
        <div class="" *ngSwitchCase="'tooltip-textbox'" [ngClass]="{'display-flex guided-div-border': stixService.guidedUI}">
            <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                <span class="float-start icon-height" *ngIf="tooltip">
                    <fa-icon class="ps-1" [icon]="faInfoCircle">
                    </fa-icon>
                    <div class="toolttext" [innerHTML]="tooltip">
                    </div>
                </span>
            </span>
            <input class="form-control" [ngClass]="{'guided-input-border': stixService.guidedUI}" [formControlName]="question.key" [id]="question.key" [type]="question.type"
                [readonly]="(question.readonly || this.stixService.revoked)">
        </div>

        <!-- Tooltip Dropdown -->
        <div class="" *ngSwitchCase="'tooltip-dropdown'" [ngClass]="{'display-flex guided-div-border': stixService.guidedUI}">
            <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                    <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                    <span class="float-start icon-height" >
                        <fa-icon class="ps-1" [icon]="faInfoCircle">
                        </fa-icon>
                        <div class="toolttext" [innerHTML]="tooltip">
                        </div>
                    </span>
            </span>
            <div class="input-group">
                <input *ngIf="(question.readonly || this.stixService.revoked)" class="form-control" [ngClass]="{'guided-input-border':stixService.guidedUI}"
                    [formControlName]="question.key" [id]="question.key" [type]="question.type" readonly>
                <select *ngIf="!(question.readonly || this.stixService.revoked)" [id]="question.key"
                    [formControlName]="question.key" class="form-control form-select dropdown" [multiple]="question.multiple"
                    [ngClass]="{'guided-input-border':stixService.guidedUI}">
                    <option value="" selected></option>
                    <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option>
                </select>
            </div>
            
        </div>

        <!-- Tracked Dropdown -->
        <div class="" *ngSwitchCase="'tracked-dropdown'">
            <span class="tooltipright">
                <p class="float-start">{{ questionLabel(question) }}</p>
                <span class="float-start icon-height" *ngIf="tooltip">
                    <fa-icon class="ps-1" [icon]="faInfoCircle">
                    </fa-icon>
                    <div class="toolttext" [innerHTML]="tooltip">
                    </div>
                </span>
            </span>
            <div class="input-group">
                <input *ngIf="(question.readonly || this.stixService.revoked)" class="form-control"
                    [formControlName]="question.key" [id]="question.key" [type]="question.type" readonly>
                <select *ngIf="!(question.readonly || this.stixService.revoked)" [id]="question.key"
                    [formControlName]="question.key" class="form-control form-select dropdown" [(ngModel)]="question.relString"
                    [multiple]="question.multiple">
                    <option value="" selected></option>
                    <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option>
                </select>
            </div>
            
        </div>

        <!-- SCO Reference Array -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'sco-reference-array'" [ngClass]="{'display-flex': stixService.guidedUI}">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0" [ngClass]="{'display-inline-flex':stixService.guidedUI}">
                            <div [ngClass]="{'float-start display-flex guided-div-border guided-button':stixService.guidedUI}">
                                <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}" [title]="tooltip">
                                    <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                                    <span class="float-start icon-height" >
                                        <fa-icon class="ps-1" [icon]="faInfoCircle">
                                        </fa-icon>
                                        <div class="toolttext" *ngIf="tooltip" [innerHTML]="tooltip">
                                        </div>
                                        <div *ngIf="!tooltip" class="toolttext">
                                            Dropdown option displays SCOs currently in the
                                            bundle.<br>Use the text box on the right to manually<br>enter a custom SCO
                                            ref.
                                        </div>
                                    </span>
                                </span>
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked) && stixService.guidedUI">
                                    <select [id]="question.key" *ngSwitchCase="'sco-reference-array'"
                                        [formControlName]="question.key" class="form-control form-select dropdown guided-input-border"
                                        [(ngModel)]="newString">
                                        <option value="" selected></option>
                                        <ng-container *ngFor="let opt of getBundleObjects()">
                                            <ng-container *ngIf="opt.id.match(objectRegex)">
                                                <option *ngIf="SCO_LIST.indexOf(opt.id.match(objectRegex)[1]) != -1"
                                                    [value]="opt['id']">
                                                    {{ getObjDisplayName(opt) }}
                                                </option>
                                            </ng-container>
                                        </ng-container>
                                    </select>
                                </ng-container>
                                <input *ngIf="stixService.guidedUI" class="form-control guided-input-border" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)">
                            </div>
                            <div class="input-group" [ngClass]="{'float-start':stixService.guidedUI}">
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked) && !stixService.guidedUI">
                                    <select [id]="question.key" *ngSwitchCase="'sco-reference-array'"
                                        [formControlName]="question.key" class="form-control form-select dropdown"
                                        [(ngModel)]="newString">
                                        <option value="" selected></option>
                                        <ng-container *ngFor="let opt of getBundleObjects()">
                                            <ng-container *ngIf="opt.id.match(objectRegex)">
                                                <option *ngIf="SCO_LIST.indexOf(opt.id.match(objectRegex)[1]) != -1"
                                                    [value]="opt['id']">
                                                    {{ getObjDisplayName(opt) }}
                                                </option>
                                            </ng-container>
                                        </ng-container>
                                    </select>
                                </ng-container>
                                <input *ngIf="!stixService.guidedUI" class="form-control" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)">
                                    <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                        <button class="btn btn-fa-sm btn-primary  " type="button" (click)="addString()"
                                            [disabled]="newString == ''|| !isValid">
                                            <fa-icon [icon]="faPlus"></fa-icon>
                                        </button>
                                    </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2 added-items-font"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)" tabindex="0">
                                {{ currentString }}
                                <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    *ngIf="!(question.readonly || this.stixService.revoked)"
                                    (click)="deleteString(currentString, question.key)"
                                    (keydown.enter)="deleteString(currentString, question.key)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Sighting Ref Array (Not Used) -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'sighting-array'">
            <div class="row w-100 p-0">
                <div class="col-12">
                    {{ question.label}}
                </div>
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-9">
                            <div class="input-group">
                                <span class="input-group-text">
                                    New {{ questionLabel(question) }}
                                </span>
                                <input class="form-control" placeholder="" [formControlName]="question.key"
                                    [id]="question.key" [(ngModel)]="newString" type="text">
                                <button class="btn btn-fa-sm btn-primary  " type="button" (click)="addString()"
                                    [disabled]="newString2 == ''|| !isValid">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                    <!-- Add {{ questionLabel(question) }} -->
                                </button>
                            </div>
                        </div>
                        <!-- <div class="col text-end p-0">
                            <button class="btn btn-fa-sm btn-primary  " type="button" (click)="addString()"
                                [disabled]="newString2 == ''|| !isValid">
                                <fa-icon [icon]="faPlus"></fa-icon>
                                Add {{ questionLabel(question) }}
                            </button>
                        </div> -->
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)">
                                {{ currentString }}
                                <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    *ngIf="!(question.readonly || this.stixService.revoked)"
                                    (click)="deleteString(currentString, question.key)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Definition -->
        <div class="input-group" *ngSwitchCase="'definition'">
            <span class="input-group-text">
                <div class="tooltipright"><b>{{ questionLabel(question) }}</b>
                    <span class="toolttext text-center">Dropdown option displays tlp options.<br>Use the text box on the
                        right to manually<br>enter a statement.</span>
                </div>
            </span>
            <select id="question.key" [formControlName]="question.key" class="form-control form-select dropdown"
                [(ngModel)]="question.relString">
                <option value=""></option>
                <option *ngFor="let tlp of this.TLP_OPTIONS" [value]="tlp.id">{{tlp.definition.tlp}}</option>
            </select>
            <input class="form-control" [formControlName]="question.key" [id]="question.key" type="text"
                [(ngModel)]="question.relString">
        </div>

        <!-- Mime Question-->
        <div *ngSwitchCase="'mime'" [ngClass]="{'display-flex guided-div-border': stixService.guidedUI}">
            <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                <p class="float-start nowrap" *ngIf="revocation && question.key === 'revoked'"><mark>{{ questionLabel(question) }}</mark></p>
                <p class="float-start nowrap" *ngIf="!(revocation && question.key === 'revoked')">{{ questionLabel(question) }}</p>
                <span class="float-start icon-height" *ngIf="tooltip">
                    <fa-icon class="ps-1" [icon]="faInfoCircle">
                    </fa-icon>
                    <div class="toolttext" [innerHTML]="tooltip">
                    </div>
                </span>
            </span>
            <div class="input-group">
                <select [id]="question.key" [formControlName]="question.key"
                class="form-control form-select dropdown" [(ngModel)]="question.relString">
                    <option value='' selected></option>
                    <option *ngFor="let opt of question.options"
                    [value]="opt.key">{{opt.value}}</option>
                </select>
                <input class="form-control" [formControlName]="question.key" [id]="question.key" [(ngModel)]="question.relString"
                type="text">
            </div>  
        </div>

        <!-- Protocol Array (No Readonly) -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'protocol-array'" [ngClass]="{'display-flex': stixService.guidedUI}">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0" [ngClass]="{'display-inline-flex':stixService.guidedUI}">
                            <div [ngClass]="{'float-start display-flex guided-div-border guided-button':stixService.guidedUI}">
                                <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                                    <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                                    <span class="float-start icon-height" *ngIf="tooltip">
                                        <fa-icon class="ps-1" [icon]="faInfoCircle">
                                        </fa-icon>
                                        <div class="toolttext" [innerHTML]="tooltip">
                                        </div>
                                    </span>
                                </span>
                                <select *ngIf="stixService.guidedUI" [id]="question.key"
                                    [formControlName]="question.key" class="form-control form-select dropdown guided-input-border"
                                    [(ngModel)]="newString">
                                    <option value="" selected></option>
                                    <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}
                                    </option>
                                </select>
                                <input *ngIf="stixService.guidedUI" class="form-control guided-input-border" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text">
                            </div>
                            <div class="input-group" [ngClass]="{'float-start':stixService.guidedUI}">
                                <select *ngIf="!stixService.guidedUI" [id]="question.key"
                                    [formControlName]="question.key" class="form-control form-select dropdown"
                                    [(ngModel)]="newString">
                                    <option value="" selected></option>
                                    <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}
                                    </option>
                                </select>
                                <input *ngIf="!stixService.guidedUI" class="form-control" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text">
                                <button class="btn btn-fa-sm btn-primary  " type="button" (click)="addString()"
                                    [disabled]="newString == ''|| !isValid">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2 added-items-font"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)" tabindex="0">
                                {{ currentString }}
                                <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    *ngIf="!(question.readonly || this.stixService.revoked)"
                                    (click)="deleteString(currentString, question.key)"
                                    (keydown.enter)="deleteString(currentString, question.key)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Hybrid (No Readonly)-->
        <div class="" *ngSwitchCase="'hybrid'" [ngClass]="{'display-flex guided-div-border': stixService.guidedUI}">
            <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                <fa-icon class="float-start ps-1 icon-height" [icon]="faInfoCircle">
                </fa-icon>
                <span *ngIf="!tooltip" class="toolttext text-center">Dropdown option displays relevant objects
                    currently in the
                    bundle.<br>Use the text box on the right to manually<br>enter an id.</span>
                <span *ngIf="tooltip">
                    <div class="toolttext" [innerHTML]="tooltip">
                    </div>
                </span>
            </span>
            <div class="input-group">
                <select [id]="question.key" [formControlName]="question.key" class="form-control form-select dropdown"
                    [(ngModel)]="question.relString" [ngClass]="{'guided-input-border':stixService.guidedUI}">
                    <option value="" selected></option>
                    <ng-container *ngFor="let opt of getBundleObjects()">
                        <option *ngIf="opt.id.includes(question.target + '--')" [value]="opt['id']">
                            {{ getObjDisplayName(opt) }}
                        </option>
                    </ng-container>
                </select>
                <input class="form-control" [formControlName]="question.key" [id]="question.key" type="text"
                    [(ngModel)]="question.relString" [ngClass]="{'guided-input-border':stixService.guidedUI}">
            </div>
        </div>

        <!-- Hybrid Array (No Readonly)-->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'hybrid-array'" [ngClass]="{'display-flex': stixService.guidedUI}">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0" [ngClass]="{'display-inline-flex': stixService.guidedUI}">
                            <div [ngClass]="{'guided-div-border guided-button display-flex': stixService.guidedUI}">
                                <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                                    <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                                    <fa-icon class="float-start ps-1 icon-height" [icon]="faInfoCircle">
                                    </fa-icon>
                                    <span *ngIf="!tooltip" class="toolttext">SROs and SCOs in the bundle will be in
                                        dropdown -<br>use
                                        the box on the right for custom entires.</span>
                                    <span *ngIf="tooltip">
                                        <div class="toolttext" [innerHTML]="tooltip">
                                        </div>
                                    </span>
                                </span>
                                <select *ngIf="stixService.guidedUI" [id]="question.key"
                                    [formControlName]="question.key" class="form-control form-select dropdown guided-input-border"
                                    [(ngModel)]="newString">
                                    <option value='' selected></option>
                                    <ng-container *ngFor="let opt of getBundleObjects()">
                                        <option *ngIf="opt.id.includes(question.target + '--')" [value]="opt['id']">
                                            {{ getObjDisplayName(opt) }}
                                        </option>
                                    </ng-container>
                                </select>
                                <input *ngIf="stixService.guidedUI" class="form-control guided-input-border" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text">
                            </div>
                            <div class="input-group">
                                <select [id]="question.key" *ngIf="!stixService.guidedUI"
                                    [formControlName]="question.key" class="form-control form-select dropdown"
                                    [(ngModel)]="newString">
                                    <option value='' selected></option>
                                    <ng-container *ngFor="let opt of getBundleObjects()">
                                        <option *ngIf="opt.id.includes(question.target + '--')" [value]="opt['id']">
                                            {{ getObjDisplayName(opt) }}
                                        </option>
                                    </ng-container>
                                </select>
                                <input *ngIf="!stixService.guidedUI" class="form-control" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text">
                                <button class="btn btn-fa-sm btn-primary  " type="button" (click)="addString()"
                                    [disabled]="newString == ''|| !isValid">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                    <!-- Add {{ questionLabel(question) }} -->
                                </button>
                            </div>
                        </div>
                        <!-- <div class="col text-end p-0">
                            <button class="btn btn-fa-sm btn-primary  " type="button" (click)="addString()"
                                [disabled]="newString == ''|| !isValid">
                                <fa-icon [icon]="faPlus"></fa-icon>
                                Add {{ questionLabel(question) }}
                            </button>
                        </div> -->
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2 added-items-font"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)" tabindex="0">
                                {{ currentString }}
                                <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    *ngIf="!(question.readonly || this.stixService.revoked)"
                                    (click)="deleteString(currentString, question.key)"
                                    (keydown.enter)="deleteString(currentString, question.key)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Hash Array (No Readonly)-->
        <div class="w-100 p-0" *ngSwitchCase="'hash-array'" [ngClass]="{'display-flex': stixService.guidedUI}">
            <div class="row w-100 p-0 m-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0">
                            <div class="p-0" [ngClass]="{'col-12 display-flex guided-div-border': stixService.guidedUI, 'col-2 float-start':!stixService.guidedUI}">
                                <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                                    <p class="float-start nowrap">Hash Type</p>
                                    <span class="float-start">
                                        <fa-icon class="ps-1" [icon]="faInfoCircle">
                                        </fa-icon>
                                        <div class="toolttext">
                                            Cryptographic hash key/algorithm used to encrypt the URL.<br>Use [ MD5,
                                            SHA-256, or SHA-512 ] whenever possible.
                                        </div>
                                    </span>
                                </span>
                                <select [id]="question.key + 'Key'" [formControlName]="question.key"
                                    class="form-control form-select dropdown" [ngClass]="{'guided-input-border':stixService.guidedUI}"
                                    (ngModel)="question.relString" (change)="setHashType()">
                                    <option value="" selected></option>
                                    <option value="MD5">MD5</option>
                                    <option value="SHA-1">SHA-1</option>
                                    <option value="SHA-256" selected>SHA-256</option>
                                    <option value="SHA-512">SHA-512</option>
                                    <option value="SHA3-256">SHA3-256</option>
                                    <option value="SHA3-512">SHA3-512</option>
                                    <option value="SSDEEP">SSDEEP</option>
                                    <option value="TLSH">TLSH</option>
                                </select>
                            </div>
                            <div [ngClass]="{'col-12 p-0 display-inline-flex': stixService.guidedUI, 'col-10 ps-1 pe-0 float-start':!stixService.guidedUI}">
                                <div [ngClass]="{'p-0 mt-2 display-inline-flex guided-div-border guided-button': stixService.guidedUI}">
                                    <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                                        <p class="float-start nowrap">Hash Value</p>
                                        <span class="float-start">
                                            <fa-icon class="ps-1" [icon]="faInfoCircle">
                                            </fa-icon>
                                            <div class="toolttext">
                                                The hash URL.
                                            </div>
                                        </span>
                                    </span>
                                    <input *ngIf="stixService.guidedUI" class="form-control guided-input-border" [formControlName]="question.key"
                                        [id]="question.key + 'Value'" [(ngModel)]="newString" type="text">
                                </div>
                                <div class="input-group">
                                    <input *ngIf="!stixService.guidedUI" class="form-control" [formControlName]="question.key"
                                        [id]="question.key + 'Value'" [(ngModel)]="newString" type="text">
                                    <button class="btn btn-fa-sm btn-primary" [ngClass]="{'mt-2': stixService.guidedUI}" type="button" (click)="addDict()"
                                        [disabled]="!isHashEnabled()">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                        <!-- Add Hash -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--
                <div class="col-1">
                    <button class="btn btn-sm btn-primary add-button" type="button"
                        (click)="addHash(this.hashType, this.hashValue)"
                        [disabled]="this.hashValue == undefined || this.hashValue == ''">
                        Add Hash
                    </button>
                </div>
            -->
                <div class="col-12 p-0">
                    <div class="form-group mt-2 added-items-font"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)" tabindex="0">
                                {{ currentString }}
                                <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    *ngIf="!(question.readonly || this.stixService.revoked)"
                                    (click)="deleteString(currentString, question.key)"
                                    (keydown.enter)="deleteString(currentString, question.key)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row warning-message ms-2">
                {{ hashError }}
            </div>
        </div>

        <!-- Reference -->
        <div class="" *ngSwitchCase="'ref'" [ngClass]="{'display-inline-flex guided-div-border w-100': stixService.guidedUI}">
            <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                <span class="float-start icon-height" >
                    <fa-icon class="ps-1" [icon]="faInfoCircle">
                    </fa-icon>
                    <div class="toolttext" *ngIf="tooltip" [innerHTML]="tooltip">
                    </div>
                    <div *ngIf="!tooltip" class="toolttext">
                        Valid reference types in the bundle will be in dropdown
                        -<br>use
                        the box on the right for custom entires.
                    </div>
                </span>
            </span>
            <div class="input-group">
                <select *ngIf="!(question.readonly || this.stixService.revoked)" [id]="question.key"
                    [formControlName]="question.key" class="form-control form-select dropdown" [(ngModel)]="question.relString"
                    [ngClass]="{'guided-input-border': stixService.guidedUI}" (ngModelChange)="inputUpdate($event)">
                    <option value="" selected></option>
                    <ng-container *ngFor="let opt of getBundleObjects()">
                        <ng-container *ngIf="opt.id.match(objectRegex)">
                            <option *ngIf="question.allowedRefs.indexOf(opt.id.match(objectRegex)[1]) != -1"
                                [value]="opt['id']">
                                {{ getObjDisplayName(opt) }}
                            </option>
                        </ng-container>
                    </ng-container>
                </select>
                <input class="form-control" [formControlName]="question.key" [id]="question.key" type="text"
                    [(ngModel)]="question.relString" [readonly]="(question.readonly || this.stixService.revoked)"
                    [ngClass]="{'guided-input-border': stixService.guidedUI}"
                    (ngModelChange)="inputUpdate($event)">
            </div>
            
        </div>

        <!-- Ref Array -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'ref-array'" [ngClass]="{'display-flex': stixService.guidedUI}">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0" [ngClass]="{'display-inline-flex':stixService.guidedUI}">
                            <div [ngClass]="{'float-start display-flex guided-div-border guided-button':stixService.guidedUI}">
                                <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                                    <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                                    <span class="float-start icon-height" >
                                        <fa-icon class="ps-1" [icon]="faInfoCircle">
                                        </fa-icon>
                                        <div class="toolttext" *ngIf="tooltip" [innerHTML]="tooltip">
                                        </div>
                                        <div *ngIf="!tooltip" class="toolttext">
                                            Relevant objects in the bundle will be in dropdown -<br>use
                                            the box on the right for custom entires.
                                        </div>
                                    </span>
                                </span>
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked) && stixService.guidedUI">
                                    <select [id]="question.key" *ngSwitchCase="'ref-array'"
                                        [formControlName]="question.key" class="form-control form-select dropdown guided-input-border"
                                        [(ngModel)]="newString">
                                        <option value="" selected></option>
                                        <ng-container *ngFor="let opt of getBundleObjects()">
                                            <ng-container *ngIf="opt && opt.id && opt.id.match(objectRegex)">
                                                <option
                                                    *ngIf="question.allowedRefs.indexOf(opt.id.match(objectRegex)[1]) != -1"
                                                    [value]="opt['id']">
                                                    {{ getObjDisplayName(opt) }}
                                                </option>
                                            </ng-container>
                                        </ng-container>
                                    </select>
                                </ng-container>
                                <input *ngIf="stixService.guidedUI" class="form-control guided-input-border" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)">
                            </div>
                            <div class="input-group" [ngClass]="{'float-start':stixService.guidedUI}">
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked) && !stixService.guidedUI">
                                    <select [id]="question.key" *ngSwitchCase="'ref-array'"
                                        [formControlName]="question.key" class="form-control form-select dropdown"
                                        [(ngModel)]="newString">
                                        <option value="" selected></option>
                                        <ng-container *ngFor="let opt of getBundleObjects()">
                                            <ng-container *ngIf="opt && opt.id && opt.id.match(objectRegex)">
                                                <option
                                                    *ngIf="question.allowedRefs.indexOf(opt.id.match(objectRegex)[1]) != -1"
                                                    [value]="opt['id']">
                                                    {{ getObjDisplayName(opt) }}
                                                </option>
                                            </ng-container>
                                        </ng-container>
                                    </select>
                                </ng-container>
                                <input *ngIf="!stixService.guidedUI" class="form-control" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)">
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                    <button class="btn btn-fa-sm btn-primary  " type="button" (click)="addString()"
                                        [disabled]="newString == ''|| !isValid">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-12 p-0" [ngClass]="{'display-flex': stixService.guidedUI}">
                            
                            <div class="input-group">
                                
                                
                            </div>
                        </div>
                        <!-- <div class="col text-end p-0">
                            <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                <button class="btn btn-sm btn-primary  " type="button" (click)="addString()"
                                    [disabled]="newString == ''|| !isValid">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                    Add {{ questionLabel(question) }}
                                </button>
                            </ng-container>
                        </div> -->
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2 added-items-font"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)" tabindex="0">
                                {{ currentString }}
                                <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    *ngIf="!(question.readonly || this.stixService.revoked)"
                                    (click)="deleteString(currentString, question.key)"
                                    (keydown.enter)="deleteString(currentString, question.key)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Tooltip String Array -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'tooltip-string-array'" [ngClass]="{'display-flex': stixService.guidedUI}">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0" [ngClass]="{'display-flex': stixService.guidedUI}">
                            <div class="col-12 p-0" [ngClass]="{'display-inline-flex':stixService.guidedUI}">
                                <div [ngClass]="{'float-start display-flex guided-div-border guided-button':stixService.guidedUI}">
                                    <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                                        <p class="float-start nowrap">{{ questionLabel(question) }}</p>
                                        <span class="float-start toolttext text-center">{{ question.tooltip }}</span>
                                    </span>
                                    <input *ngIf="stixService.guidedUI" class="form-control guided-input-border" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)">
                                </div>
                                <div class="input-group" [ngClass]="{'float-start':stixService.guidedUI}">
                                    <input *ngIf="!stixService.guidedUI" class="form-control" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)">
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                    <button class="btn btn-fa-sm btn-primary  " type="button" (click)="addString()"
                                        [disabled]="newString == ''|| !isValid">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2 added-items-font"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)" tabindex="0">
                                {{ currentString }}
                                <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    *ngIf="!(question.readonly || this.stixService.revoked)"
                                    (click)="deleteString(currentString, question.key)"
                                    (keydown.enter)="deleteString(currentString, question.key)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- String Dictionary (No Readonly)-->
        <div class="input-group w-100 pe-0 ms-0" *ngSwitchCase="'string-dict'" [ngClass]="{'display-flex me--15': stixService.guidedUI}">
            <div class="row w-100 m-0 pe-0">  
                <div class="col-12 p-0">
                    <div class="ps-0" [ngClass]="{'display-flex guided-div-border col-12 pe-0': stixService.guidedUI, 'col-6 pe-1 float-start': !stixService.guidedUI}">
                        <span class="tooltipright" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                            <p class="float-start nowrap">{{ questionLabel(question) }} Key</p>
                            <span class="float-start icon-height" >
                                <fa-icon class="ps-1" [icon]="faInfoCircle">
                                </fa-icon>
                                <div class="toolttext" *ngIf="tooltip" [innerHTML]="tooltip">
                                </div>
                            </span>
                        </span>
                        <input class="form-control" [ngClass]="{'guided-input-border':stixService.guidedUI}" [formControlName]="question.key" [id]="question.key + 'Key'"
                            [(ngModel)]="question.relString" type="text" style="border-radius:0%">
                    </div>
                    <div class="pe-0" [ngClass]="{'display-inline-flex ps-0 col-12 mt-2': stixService.guidedUI, 'col-6 ps-1 float-start': !stixService.guidedUI}">
                        <div [ngClass]="{'display-flex guided-div-border guided-button': stixService.guidedUI}">
                            <span class="nowrap" [ngClass]="{'mt-1 pe-1': stixService.guidedUI}">
                                <p>{{ questionLabel(question) }} Value</p>
                            </span>
                            <input *ngIf="stixService.guidedUI" class="form-control guided-input-border" [formControlName]="question.key"
                                [id]="question.key + 'Value'" [(ngModel)]="newString" type="text">
                        </div>
                        <div class="input-group">
                            <input *ngIf="!stixService.guidedUI" class="form-control" [formControlName]="question.key"
                                [id]="question.key + 'Value'" [(ngModel)]="newString" type="text">
                            <button class="btn btn-fa-sm btn-primary" type="button" (click)="addDict()"
                                [disabled]="newString == '' || question.relString == '' || !isDictEnabled()">
                                <fa-icon [icon]="faPlus"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>    
                <div class="col-12 p-0">
                    <div class="form-group mt-2 added-items-font"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)" tabindex="0">
                                {{ currentString }}
                                <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    *ngIf="!(question.readonly || this.stixService.revoked)"
                                    (click)="deleteString(currentString, question.key)"
                                    (keydown.enter)="deleteString(currentString, question.key)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row w-100 p-0">
                {{ dictError }}
            </div>
        </div>

        <!-- Kill Chain--
        <div class="input-group w-100 p-0" *ngSwitchCase="'kill-chain'">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col">
                            <div class="input-group">
                                <span class="input-group-text"><b>Kill Chain Name</b></span>
                                <select [id]="question.key + 'KillChain'" *ngSwitchCase="'kill-chain'"
                                    [formControlName]="question.key" class="form-control dropdown"
                                    [(ngModel)]="newString">
                                    <option value="" selected></option>
                                    <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}
                                    </option>
                                </select>
                                <input class="form-control" [formControlName]="question.key" [id]="question.key + 'KillChain'"
                                    [(ngModel)]="newString" type="text">
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-group">
                                <span class="input-group-text"><b>Kill Chain Name</b></span>
                                <input class="form-control" [formControlName]="question.key" [id]="question.key + 'Key'"
                                    [(ngModel)]="question.relString" type="text">
                            </div>
                            <div class="input-group">
                                <span class="input-group-text">
                                    <b>{{ questionLabel(question) }} Value</b>
                                </span>
                                <input class="form-control" [formControlName]="question.key"
                                    [id]="question.key + 'Value'" type="text">
                            </div>
                        </div>
                        <div class="col text-end p-0">
                            <button class="btn btn-sm btn-primary add-button" type="button" (click)="addString()"
                                [disabled]="newString == ''|| !isValid">
                                Add Kill Chain Phase
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div style="padding-left: 30px">
                        </div>
                        <div class="col-6 p-0">
                            <div class="input-group">
                                <span class="input-group-text"><b>Phase Name</b></span>
                                <input class="form-control" [formControlName]="question.key" [id]="question.key + 'Phase'"
                                    [(ngModel)]="newString2" type="text">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)">
                                {{ currentString }}
                                <button class="btn btn-sm btn-success add-button" type="button"
                                    (click)="deleteString(currentString, question.key)">Del</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>-->

        <!-- Kill Chain--
        <div class="input-group w-100 p-0" *ngSwitchCase="'kill-chain'">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col">
                            <div class="input-group">
                                <span class="input-group-text"><b>{{ questionLabel(question) }}</b></span>
                                <select [id]="question.key + 'KillChain'" *ngSwitchCase="'kill-chain'"
                                    [formControlName]="question.key" class="form-control dropdown"
                                    [(ngModel)]="newString">
                                    <option value="" selected></option>
                                    <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}
                                    </option>
                                </select>
                                <input class="form-control" [formControlName]="question.key" [id]="question.key + 'KillChain'"
                                    [(ngModel)]="newString" type="text">
                            </div>
                        </div>
                        <div class="col text-end p-0">
                            <button class="btn btn-sm btn-primary add-button" type="button" (click)="addKillChain()"
                                [disabled]="newString == ''|| !isValid">
                                Add Kill Chain Phase
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)">
                                {{ currentString }}
                                <button class="btn btn-sm btn-success add-button" type="button"
                                    (click)="deleteString(currentString, question.key)">Del</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>-->

        <!-- Kill Chain --
        <div class="input-group w-100 p-0" *ngSwitchCase="'kill-chain'">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col">
                            <div class="input-group">
                                <span class="input-group-text">
                                    <div class="tooltipright"><b>Phase Name</b>
                                        <span class="toolttext text-center">{{ question.tooltip }}</span>
                                    </div>
                                </span>
                                <select [id]="question.key + 'Phase'" [formControlName]="question.key"
                                    class="form-control dropdown" [(ngModel)]="newString" (change)="syncKillChain()">
                                    <option value="" selected></option>
                                    <option *ngFor="let opt of question.arrOptions" [value]="opt">{{opt}}
                                    </option>
                                </select>
                                <input class="form-control" [formControlName]="question.key"
                                    [id]="question.key + 'Phase'" [(ngModel)]="newString" type="text"
                                    (change)="syncKillChain()">
                            </div>
                            <div class="input-group">
                                <span class="input-group-text">
                                    <b>Kill Chain Name</b>
                                </span>
                                <input class="form-control" [formControlName]="question.key"
                                    [id]="question.key + 'KillChain'" [(ngModel)]="newString2" type="text">
                            </div>
                        </div>
                        <div class="col text-end p-0">
                            <button class="btn btn-sm btn-primary add-button" type="button" (click)="addKillChain()"
                                [disabled]="newString == '' || newString2 == ''">
                                Add Kill Chain Phase
                            </button>
                        </div>
                    </div>
                </div>--



                <!--
                <div class="col-1">
                    <button class="btn btn-sm btn-primary add-button" type="button"
                        (click)="addHash(this.hashType, this.hashValue)"
                        [disabled]="this.hashValue == undefined || this.hashValue == ''">
                        Add Hash
                    </button>
                </div>
            --
                <div class="col-12 p-0">
                    <div class="form-group mt-2"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)">
                                {{ currentString }}
                                <button class="btn btn-sm btn-success add-button" type="button"
                                    (click)="deleteString(currentString, question.key)">Del</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row w-100 p-0">
                {{ hashError }}
            </div>
        </div>-->

        <!-- Phase Name --
        <div class="input-group w-100 p-0" *ngSwitchCase="'phase-name'">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col">
                            <div class="input-group">
                                <span class="input-group-text"><b>{{ questionLabel(question) }}</b></span>
                                <select *ngIf="!(question.readonly || this.stixService.revoked)" [id]="question.key"
                                    [formControlName]="question.key" class="form-control dropdown"
                                    [(ngModel)]="question.relString">
                                    <option value="" selected></option>
                                    <option *ngFor="let opt of question.arrOptions" [value]="opt">{{opt}}
                                    </option>
                                </select>
                                <input class="form-control" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="question.relString" type="text" [readonly]="(question.readonly || this.stixService.revoked)">
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

        <!-- Kill Chain--
        <div class="input-group w-100 p-0" *ngSwitchCase="'kill-chain'">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col">
                            <div class="input-group">
                                <span class="input-group-text"><b>{{ questionLabel(question) }}</b></span>
                                <input class="form-control" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="question.value" type="text" [readonly]="(question.readonly || this.stixService.revoked)">
                            </div>
                        </div>
                        <div class="col text-end p-0">
                            <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                <button class="btn btn-sm btn-primary add-button" type="button" (click)="addKillChain()"
                                    [disabled]="!question.value || question.value == ''|| question.relString == ''">
                                    Add Kill Chain Phase
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2"
                        *ngIf="stixService.stringArrays.get('killChain') && stixService.stringArrays.get('killChain')!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get('killChain')">
                                {{ currentString }}
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                    <button class="btn btn-sm btn-success add-button" type="button"
                                        (click)="deleteString(currentString, 'killChain')">Del</button>
                                </ng-container>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>-->

        <!-- Kill Chain -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'kill-chain'" [ngClass]="{'display-flex': stixService.guidedUI}">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0" [ngClass]="{'display-flex guided-div-border': stixService.guidedUI}">
                            <span [ngClass]="{'nowrap mt-1 pe-1': stixService.guidedUI}">
                                <p class="pb-no-span">{{ questionLabel(question) }}</p>
                            </span>
                            <div class="input-group">
                                <select *ngIf="!(question.readonly || this.stixService.revoked)" [id]="question.key"
                                    [formControlName]="question.key" class="form-control form-select dropdown" [ngClass]="{'guided-input-border': stixService.guidedUI}"
                                    [(ngModel)]="question.value">
                                    <option value="" selected></option>
                                    <option value="lockheed-martin-cyber-kill-chain">lockheed-martin-cyber-kill-chain
                                    </option>
                                </select>
                                <input class="form-control" [ngClass]="{'guided-input-border': stixService.guidedUI}" [formControlName]="question.key" [id]="question.key"
                                    style="border-radius:0;" [(ngModel)]="question.value" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Phase Name -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'phase-name'" [ngClass]="{'display-flex': stixService.guidedUI}">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0" [ngClass]="{'display-inline-flex':stixService.guidedUI}">
                            <div [ngClass]="{'float-start display-flex guided-div-border guided-button':stixService.guidedUI}">
                                <span class="tooltipright" [ngClass]="{'display-flex nowrap mt-1 pe-1': stixService.guidedUI}">
                                    <p class="float-start" title="{{description}}">{{ questionLabel(question) }}</p>
                                    <span class="float-start" *ngIf="tooltip">
                                        <fa-icon class="ps-1" [icon]="faInfoCircle">
                                        </fa-icon>
                                        <div class="toolttext" [innerHTML]="tooltip">
                                        </div>
                                    </span>
                                </span>
                                <select *ngIf="(!(question.readonly || this.stixService.revoked)) && stixService.guidedUI" [id]="question.key"
                                    [formControlName]="question.key" class="form-control form-select dropdown guided-input-border"
                                    [(ngModel)]="newString">
                                    <option value="" selected></option>
                                    <option *ngFor="let opt of question.arrOptions" [value]="opt">{{opt}}
                                    </option>
                                </select>
                                <input *ngIf="stixService.guidedUI && question.relString" class="form-control guided-input-border" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)">
                                <input *ngIf="stixService.guidedUI && !question.relString" class="form-control" value="" type="text"
                                    [readonly]="true">
                            </div>
                            <div class="input-group" [ngClass]="{'float-start':stixService.guidedUI}">
                                <select *ngIf="(!(question.readonly || this.stixService.revoked)) && !stixService.guidedUI" [id]="question.key"
                                    [formControlName]="question.key" class="form-control form-select dropdown"
                                    [(ngModel)]="newString">
                                    <option value="" selected></option>
                                    <option *ngFor="let opt of question.arrOptions" [value]="opt">{{opt}}
                                    </option>
                                </select>
                                <input *ngIf="!stixService.guidedUI && question.relString" class="form-control" [formControlName]="question.key" [id]="question.key"
                                    [(ngModel)]="newString" type="text"
                                    [readonly]="(question.readonly || this.stixService.revoked)">
                                <input *ngIf="!stixService.guidedUI && !question.relString" class="form-control" value="" type="text"
                                    [readonly]="true">
                                    <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                        <button class="btn btn-fa-sm btn-primary" type="button" (click)="addKillChain()"
                                            [disabled]="question.relString == ''|| newString == ''">
                                            <fa-icon [icon]="faPlus"></fa-icon>
                                        </button>
                                        <button class="btn btn-fa-sm btn-primary" type="button" (click)="openDeciderTool()">
                                            <fa-icon [icon]="faDecider"></fa-icon>
                                        </button>
                                    </ng-container>
                            </div>
                        </div>
                        <!-- <div class="col-12 p-0" [ngClass]="{'display-flex': stixService.guidedUI}">
                            
                            <div class="input-group">
                                
                                
                            </div>
                        </div> -->
                        <!-- <div class="col text-end p-0">
                            
                        </div> -->
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2 added-items-font"
                        *ngIf="stixService.stringArrays.get('killChain') && stixService.stringArrays.get('killChain')!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get('killChain')" tabindex="0">
                                {{ currentString }}
                                <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    *ngIf="!(question.readonly || this.stixService.revoked)"
                                    (click)="deleteString(currentString, 'killChain')"
                                    (keydown.enter)="deleteString(currentString, 'killChain')">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Extension Type -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'extension-type'">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0">
                            <span class="">
                                <p class="pb-no-span">Extension Type*</p>
                            </span>
                            <div class="input-group">
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                    <select [id]="question.key" *ngSwitchCase="'extension-type'" [(ngModel)]="newString"
                                        [formControlName]="question.key" class="form-control form-select extension-type">
                                        <option value=""></option>
                                        <option *ngFor="let opt of getExtTypeOpt(question.options)" [value]="opt.key">
                                            {{opt.value}}
                                        </option>
                                    </select>
                                </ng-container>
                                <input *ngIf="(question.readonly || this.stixService.revoked)" class="form-control"
                                    [formControlName]="question.key" [id]="question.key" [type]="question.type"
                                    readonly>
                                <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                    <button class="btn btn-fa-sm btn-primary  " type="button"
                                        (click)="addExtensionType(custom_obj)" [disabled]="newString == ''|| !isValid">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                        <!-- Add Extension Type -->
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                        <!-- <div class="col text-end p-0">
                            <ng-container *ngIf="!(question.readonly || this.stixService.revoked)">
                                <button class="btn btn-fa-sm btn-primary  " type="button"
                                    (click)="addExtensionType(custom_obj)" [disabled]="newString == ''|| !isValid">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                    Add Extension Type
                                </button>
                            </ng-container>
                        </div> -->
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group mt-2 added-items-font"
                        *ngIf="stixService.stringArrays.get(question.key) && stixService.stringArrays.get(question.key)!.length > 0">
                        <ul>
                            <li *ngFor="let currentString of stixService.stringArrays.get(question.key)" tabindex="0">
                                {{ currentString }}
                                <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    *ngIf="!(question.readonly || this.stixService.revoked)"
                                    (click)="deleteString(currentString, question.key)"
                                    (keydown.enter)="deleteString(currentString, question.key)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div *ngIf="this.stixService.modalObjectArray && this.stixService.modalObjectArray.length > 0"
                    class="col-12 p-0">
                    <div class="form-group mt-2 bump">
                        <accordion>
                            <accordion-group *ngFor="let x of this.stixService.modalObjectArray" [heading]="x['id']"
                                [panelClass]="'accordion-panel-class'">
                                <div *ngIf="!this.stixService.customObjReadOnly" class="row">
                                    <div class="col-md-6">
                                        <button type="button" class="btn btn-fa btn-primary me-2"
                                            (click)="this.editModalObject(x, custom_obj);">
                                            <fa-icon [icon]="faEdit"></fa-icon>
                                        </button>
                                        <button type="button" class="btn btn-fa btn-warning"
                                            style="background-color:rgb(210, 0, 0)" (click)="deleteModalObject(x);">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                                <pre>{{ x | json }}</pre>
                            </accordion-group>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>

        <!-- File Upload -->
        <div class="input-group w-100 p-0 ms-15" *ngSwitchCase="'file-upload'">
            <div class="row w-100 p-0">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="col-12 p-0">
                            <div id="file-upload">
                                <span class="">
                                    <p>{{ questionLabel(question) }}</p>
                                </span>
                                <div class="input-group">
                                    <!-- <div class="custom-file"> -->
                                        <input type="file" class="custom-file-input form-control" id="choosefile"
                                            accept=".json" (change)="onChooseFileHandler($event)">
                                        <!-- <label id="file-upload-label" class="custom-file-label col-form-label-sm form-label pt-1"
                                            for="choosefile">{{ (loadedFile && loadedFile.name)?
                                            loadedFile.name : '' }}</label> -->
                                    <!-- </div> -->
                                    <div>
                                        <button type="button" class="ms-1 btn btn-sm btn-primary file-upload-btn-width"
                                            (click)="onLoadFile()" [disabled]="!loadedFile">Upload</button>
                                    </div>
                                </div>
                                
                            </div>
                            <div *ngIf="message" class="warning-message">{{ message }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Line Separator -->
        <hr *ngSwitchCase="'separator'" class="mt-2 mb-2">
    </div>

    <div class="errorMessage warning-message" *ngIf="!isValid">
        {{ validationMessage }}
    </div>
</div>

<ng-template #confidenceTooltip let-modal>
    <div class="tooltip-wrapper">
        <h2>Confidence Scales</h2>
        <div>The confidence property reflects the object creator's trust in the accuracy of their data.
            The confidence value MUST be a number in the range of 0-100.</div>
        <div class="tooltip-content">
            <table>
                <tr>
                    <th>Low/Med/High</th>
                    <th>Range of Values</th>
                </tr>
                <tr>
                    <td>Low</td>
                    <td>1-29</td>
                </tr>
                <tr>
                    <td>Med</td>
                    <td>30-69</td>
                </tr>
                <tr>
                    <td>High</td>
                    <td>70-100</td>
                </tr>
            </table>
        </div>
    </div>
</ng-template>

<ng-template #stixPatternBuilder let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="pattern-builder-modal-title">STIX Pattern Builder</h2>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click'); resetStixPatternBuilder()">
            <span aria-hidden="true"></span>
        </button>
    </div>
    <div class="modal-body stix-pattern-builder-modal-body">
        <!-- Comparison Expression Builder -->
        <div class="row" *ngIf="page === 1">
            <div class="col-12 text-center mb-3">
                <h3 class="stix-pattern-builder-steps">Step 1: Create Comparison Expressions</h3>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="dropdown">
                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Cyber Observable Object Types
                    </button>
                    <div *ngIf="scoBundleObjects?.length > 0" class="dropdown-menu">
                        <a class="dropdown-item" *ngFor="let obj of scoBundleObjects"
                            (click)="mapBundleObjToSCOObj(obj)">{{ obj.displayName }}</a>
                    </div>
                </div>
                <hr>

                <accordion *ngIf="currObjectPath" class="mb-3">
                    <accordion-group
                        *ngFor="let component of bundle.objects | bundlefilter: currObjectPath?.type; let i = index; let c = index;"
                        [heading]="stixService.getComponentDisplay(component)"
                        (isOpenChange)="updateExpressionConstant($event, component)">
                        <div class="row ms-3">
                            <pre>{{ stixService.getJsonDisplayForComponent(component) | json }}</pre>
                        </div>
                    </accordion-group>
                </accordion>

                <div *ngIf="!scoBundleObjectSelected && !currObjectPath">
                    Please select a Cyber Observable Object type.
                </div>

                <div *ngIf="currObjectPath && !typeInBundle()">
                    Please create expression with type <strong>{{ currObjectPath?.displayName }}</strong>. This object
                    type doesn't exist in current bundle.
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <h4>Create Comparison Expression</h4>
                <hr>
                <div class="comparison-expression-builder" *ngIf="!currObjectPath">
                    <div class="col-6 offset-3">
                        Select a Cyber Observable Object to begin creating a comparison expression
                    </div>
                </div>
                <div *ngIf="currObjectPath">
                    <div class="row">
                        <div *ngIf="selectedComparisonOperator !== 'EXISTS'" class="col-lg-4 col-md-4 col-12">
                            <div>Object Path</div>
                            <input type="text" class="form-control" disabled
                                value="{{currObjectPath.type}}{{currPropertyName ? ':' + currPropertyName : ''}}">
                            <div>Property Name</div>
                            <select class="form-control form-select dropdown" [(ngModel)]="currPropertyName"
                                (change)="updateExpressionConstant($event)" *ngIf="typeInBundle()">
                                <option *ngFor="let propertyName of propertyNames" [value]="propertyName">
                                    {{ propertyName }}
                                </option>
                            </select>
                            <select class="form-control form-select dropdown" [(ngModel)]="currPropertyName"
                                (change)="updateExpressionConstant($event)" *ngIf="!typeInBundle()">
                                <option hidden value></option>
                                <option *ngFor="let propertyName of propertyNames" [value]="propertyName">
                                    {{ propertyName }}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="selectedComparisonOperator === 'EXISTS'" class="col-lg-4 col-md-4 col-12">
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                            <div>Comparison Operator</div>
                            <select class="form-control form-select dropdown" [(ngModel)]="selectedComparisonOperator">
                                <option *ngFor="let comparisonOperator of COMPARISON_OPERATORS"
                                    [value]="comparisonOperator">
                                    {{ comparisonOperator }}
                                </option>
                                <option *ngIf="showSetOperator()" [value]="'ISSUBSET'">ISSUBSET</option>
                                <option *ngIf="showSetOperator()" [value]="'ISSUPERSET'">ISSUPERSET</option>
                                <option [value]="'EXISTS'">EXISTS</option>
                            </select>
                        </div>
                        <div *ngIf="selectedComparisonOperator === 'EXISTS'" class="col-lg-4 col-md-4 col-12">
                            <div>Object Path</div>
                            <input type="text" class="form-control" disabled
                                value="{{currObjectPath.type}}{{currPropertyName ? ':' + currPropertyName : ''}}">
                            <div>Property Name</div>
                            <select class="form-control form-select dropdown" [(ngModel)]="currPropertyName" *ngIf="typeInBundle()">
                                <option *ngFor="let propertyName of propertyNames" [value]="propertyName">
                                    {{ propertyName }}
                                </option>
                            </select>
                            <select class="form-control form-select dropdown" [(ngModel)]="currPropertyName" *ngIf="!typeInBundle()">
                                <option hidden value></option>
                                <option *ngFor="let propertyName of propertyNames" [value]="propertyName">
                                    {{ propertyName }}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="selectedComparisonOperator !== 'EXISTS'" class="col-lg-4 col-md-4 col-12">
                            <div>Constant</div>
                            <input type="text" class="form-control" [(ngModel)]="comparisonConstant"
                                placeholder="Enter your comparison value">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col text-end">
                            <button type="button" class="btn btn-sm btn-primary" [disabled]="selectedComparisonOperator !== 'EXISTS' && comparisonConstant === null || currPropertyName === ''"
                                (click)="addComparisonExpression()">
                                <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-start mt-3" *ngIf="comparisonExpressions.length > 0">
                <h4>Your Comparison Expressions</h4>
                <hr>
                <div>
                    <ul id="questions">
                        <li *ngFor="let comparisonExpression of comparisonExpressions; let compExpIndx = index;">
                            {{ comparisonExpression.getDisplayForExpression() }}
                            <button type="button" class="btn btn-fa-xsm btn-danger ms-2"
                                *ngIf="!(question.readonly || this.stixService.revoked)"
                                (click)="deleteExpression(compExpIndx)">
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>
                        </li>
                    </ul>

                    <!-- <button  *ngFor="let comparisonExpression of comparisonExpressions; let compExpIndx = index;" type="button"
                        class="btn btn-sm btn-primary m-1"
                        disabled>
                        {{ comparisonExpression.getDisplayForExpression() }}
                        <button type="button" class="close expression" data-dismiss="modal" aria-label="Close" (click)="deleteExpression(compExpIndx)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </button> -->
                </div>
            </div>
        </div>

        <!-- Single Observation Expression Builder -->
        <div class="row" *ngIf="page === 2">
            <div class="col-12 text-center mb-3">
                <h3 class="stix-pattern-builder-steps">Step 2: Create Observation Expressions</h3>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <h4>
                            Select Comparison Expression(s)
                        </h4>
                        <hr>
                        <div>
                            <ul id="questions">
                                <li
                                    *ngFor="let comparisonExpression of comparisonExpressions; let compExpIndx = index;">
                                    {{ comparisonExpression.getDisplayForExpression() }}
                                    <button type="button" class="btn btn-fa-xsm btn-primary ms-2"
                                        *ngIf="!(question.readonly || this.stixService.revoked)"
                                        (click)="addComparisonExpressionToObservationExpression(comparisonExpression)">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                    <button type="button" class="btn btn-fa-xsm btn-danger ms-1"
                                        *ngIf="!(question.readonly || this.stixService.revoked)"
                                        (click)="deleteExpression(compExpIndx)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </ul>
                            <!-- <button *ngFor="let comparisonExpression of comparisonExpressions; let compExpIndx = index" type="button"
                                class="btn btn-sm btn-primary m-1"
                                (click)="addComparisonExpressionToObservationExpression(comparisonExpression)">
                                {{ comparisonExpression.getDisplayForExpression() }}
                                <button type="button" class="close expression" data-dismiss="modal" aria-label="Close" (click)="deleteExpression(compExpIndx)">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </button> -->
                        </div>
                        <h4 *ngIf="observationExpressions.length !== 0" class="mt-4">
                            Observation Expressions
                        </h4>
                        <hr>
                        <div>
                            <ul id="questions">
                                <li
                                    *ngFor="let observationExpression of observationExpressions; let observExpIndx = index">
                                    {{ observationExpression.showEntireExpressionChain(true) }}
                                    <button type="button" class="btn btn-fa-xsm btn-danger ms-2"
                                        *ngIf="!(question.readonly || this.stixService.revoked)"
                                        (click)="deleteExpression(null, observExpIndx)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </ul>
                            <!-- <button *ngFor="let observationExpression of observationExpressions; let observExpIndx = index" type="button"
                                class="btn btn-sm btn-primary m-1"
                                (click)="addComparisonExpressionToObservationExpression(observationExpression)"
                                disabled>
                                {{ observationExpression.showEntireExpressionChain(true) }}
                                <button type="button" class="close expression" data-dismiss="modal" aria-label="Close" (click)="deleteExpression(null, observExpIndx)">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </button> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <h4>Create Observation Expression</h4>
                        <hr>
                        <div class="comparison-expression-builder" style="padding-top: 55px;"
                            *ngIf="!observationExpressionBuilder">
                            <div class="col-6 offset-3">
                                Click on a comparison expression to begin creating an observation expression
                            </div>
                        </div>
                        <div *ngIf="observationExpressionBuilder">
                            <div class="mt-3">
                                <span class="me-2">[</span>
                                <div class="d-inline"
                                    *ngFor="let comparisonExpression of observationExpressionBuilder.getExpressionArray(); let i = index">
                                    <span class="badge bg-secondary me-2 stix_pattern">
                                        {{ comparisonExpression.getDisplayForExpression() }}
                                    </span>
                                    <div class="d-inline text-center me-2"
                                        *ngIf="(i+1) < observationExpressionBuilder.getExpressionArray().length">
                                        <select class="form-control form-select dropdown d-inline comparison-operator-dropdown"
                                            [(ngModel)]="comparisonExpression.comparisonOperator">
                                            <option *ngFor="let observationOperator of COMPARISON_EXPRESSION_OPERATORS"
                                                [value]="observationOperator">
                                                {{ observationOperator }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <span>]</span>
                            </div>
                            <hr>
                            <div class="text-end">
                                <button class="btn btn-sm btn-secondary me-3"
                                    (click)="observationExpressionBuilder = null">
                                    <fa-icon [icon]="faUndo" class="pe-1"></fa-icon>
                                    Clear
                                </button>
                                <button class="btn btn-sm btn-primary" (click)="addObservationExpression()">
                                    <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Multiple Observation Expression Builder -->
        <div class="row" *ngIf="page === 3">
            <div class="col-12 text-center mb-3">
                <h3 class="stix-pattern-builder-steps">Step 3: Combine Observation Expressions</h3>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <h4 *ngIf="observationExpressions.length !== 0" class="mt-4">
                            Select Observation Expressions
                        </h4>
                        <hr>
                        <div>
                            <ul id="questions">
                                <li
                                    *ngFor="let observationExpression of observationExpressions; let observExpIndx = index">
                                    {{ observationExpression.showEntireExpressionChain(true) }}
                                    <button type="button" class="btn btn-fa-xsm btn-primary ms-2"
                                        *ngIf="!(question.readonly || this.stixService.revoked)"
                                        (click)="addComparisonExpressionToObservationExpression(observationExpression)">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                    <button type="button" class="btn btn-fa-xsm btn-danger ms-1"
                                        *ngIf="!(question.readonly || this.stixService.revoked)"
                                        (click)="deleteExpression(null, observExpIndx)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </ul>
                            <!-- <button *ngFor="let observationExpression of observationExpressions; let observExpIndx = index" type="button"
                                class="btn btn-sm btn-primary m-1"
                                (click)="addComparisonExpressionToObservationExpression(observationExpression)"
                                >
                                {{ observationExpression.showEntireExpressionChain(true) }}
                                <button type="button" class="close expression" data-dismiss="modal" aria-label="Close" (click)="deleteExpression(null, observExpIndx)">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </button> -->
                        </div>

                        <h4 *ngIf="patternExpressions?.length > 0" class="mt-4">
                            Pattern
                        </h4>
                        <hr>
                        <div>
                            <ul id="questions">
                                <li *ngFor="let patternExpression of patternExpressions; let patternExpIndx = index">
                                    {{ patternExpression.showEntireExpressionChain(true) }}
                                    <button type="button" class="btn btn-fa-xsm btn-danger ms-2"
                                        *ngIf="!(question.readonly || this.stixService.revoked)"
                                        (click)="deleteExpression(null, null, patternExpIndx)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </ul>
                            <!-- <button *ngFor="let patternExpression of patternExpressions; let patternExpIndx = index" type="button"
                                class="btn btn-sm btn-primary m-1"
                                (click)="addComparisonExpressionToObservationExpression(patternExpression)"
                                >
                                {{ patternExpression.showEntireExpressionChain(true) }}
                                <button type="button" class="close expression" data-dismiss="modal" aria-label="Close" (click)="deleteExpression(null, null, patternExpIndx)">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </button> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <h4>Combine Observation Expressions</h4>
                        <hr>
                        <div class="comparison-expression-builder" style="padding-top: 55px;"
                            *ngIf="!observationExpressionBuilder">
                            <div class="col-6 offset-3">
                                Click on an observation expression to begin combining observation expressions
                            </div>
                        </div>
                        <div *ngIf="observationExpressionBuilder">
                            <div class="mt-3">
                                <div class="d-inline"
                                    *ngFor="let comparisonExpression of observationExpressionBuilder.getExpressionArray(); let i = index">
                                    <span class="me-2">[</span>
                                    <span class="badge bg-secondary me-2 stix_pattern">
                                        {{ comparisonExpression.getDisplayForExpression() }}
                                    </span>
                                    <span class="me-2">]</span>
                                    <div class="d-inline text-center me-2"
                                        *ngIf="(i+1) < observationExpressionBuilder.getExpressionArray().length">
                                        <select class="form-control form-select dropdown d-inline comparison-operator-dropdown"
                                            [(ngModel)]="comparisonExpression.comparisonOperator">
                                            <option *ngFor="let observationOperator of OBSERVATION_EXPRESSION_OPERATORS"
                                                [value]="observationOperator">
                                                {{ observationOperator }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="text-end">
                                <button class="btn btn-sm btn-secondary me-3"
                                    (click)="observationExpressionBuilder = null">
                                    <fa-icon [icon]="faUndo" class="pe-1"></fa-icon>
                                    Clear
                                </button>
                                <button class="btn btn-sm btn-primary" (click)="addPatternExpression()">
                                    <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- STIX Pattern Builder -->
        <div class="row" *ngIf="page === 4">
            <div class="col-12 text-center">
                <h3 class="stix-pattern-builder-steps">(FINAL) Step 4: Create STIX Pattern</h3>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <h4>Select a Pattern</h4>
                        <hr>
                        <div class="mt-3">
                            <ul id="questions">
                                <li *ngFor="let patternExpression of patternExpressions">
                                    {{ patternExpression.showEntireExpressionChain(true) }}
                                    <button type="button" class="btn btn-fa-xsm btn-primary ms-2"
                                        *ngIf="!(question.readonly || this.stixService.revoked)"
                                        (click)="addObservationExpressionToStixPattern(patternExpression)">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                    <!-- <button type="button"
                                        class="btn btn-fa-xsm btn-danger trash-can" 
                                        *ngIf="!(question.readonly || this.stixService.revoked)"
                                        (click)="deleteExpression(null, observExpIndx)"
                                        style="margin-left:2.5px;">
                                        <fa-icon [icon]="faTrash" ></fa-icon>
                                    </button> -->
                                </li>
                            </ul>
                            <!-- <button *ngFor="let patternExpression of patternExpressions" type="button"
                                class="btn btn-sm btn-primary m-1"
                                (click)="addObservationExpressionToStixPattern(patternExpression)">
                                {{ patternExpression.showEntireExpressionChain(true) }}
                            </button> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <h4>Add Qualifier</h4>
                        <hr>
                        <div *ngIf="selectedStixPatternBuilder">
                            <div class="mt-3">
                                <div class="d-inline">
                                    <span class="badge bg-secondary me-2 stix_pattern">
                                        {{ selectedStixPatternBuilder.getDisplayForStixPatternBuilder() }}
                                    </span>
                                </div>
                                <select class="form-control form-select dropdown d-inline comparison-operator-dropdown"
                                    [(ngModel)]="selectedStixPatternBuilder.qualifierForDropdown">
                                    <option *ngFor="let qualifier of OBSERVATION_EXPRESSION_QUALIFIERS">
                                        {{ qualifier }}
                                    </option>
                                </select>
                                <div *ngIf="selectedStixPatternBuilder.qualifierForDropdown === 'WITHIN'"
                                    class="d-inline">
                                    <input type="number" class="d-inline form-control ms-2 number-input"
                                        [(ngModel)]="observationQualifierData1">
                                    <span *ngIf="selectedStixPatternBuilder.qualifierForDropdown === 'WITHIN'"
                                        class="ms-1 qualifier-trailing-label">
                                        SECONDS
                                    </span>
                                </div>
                                <div *ngIf="selectedStixPatternBuilder.qualifierForDropdown == 'REPEATS'"
                                    class="d-inline">
                                    <input type="number" class="d-inline form-control ms-2 number-input"
                                        [(ngModel)]="observationQualifierData1">
                                    <span *ngIf="selectedStixPatternBuilder.qualifierForDropdown === 'REPEATS'"
                                        class="ms-1 qualifier-trailing-label">
                                        TIMES
                                    </span>
                                </div>
                                <div *ngIf="selectedStixPatternBuilder.qualifierForDropdown === 'START'"
                                    class="d-inline">
                                    <span class="qualifier-trailing-label ms-1">START</span>
                                    <input class="form-control d-inline date-input ms-1 me-1"
                                        [(ngModel)]="observationQualifierData1" placeholder="START DATE"
                                        [owlDateTimeTrigger]="startDtPicker" [owlDateTime]="startDtPicker">
                                    <owl-date-time #startDtPicker></owl-date-time>
                                    <span class="qualifier-trailing-label ms-1 me-1">END</span>
                                    <input class="form-control d-inline date-input"
                                        [(ngModel)]="observationQualifierData2" placeholder="END DATE"
                                        [owlDateTimeTrigger]="endDtPicker" [owlDateTime]="endDtPicker">
                                    <owl-date-time #endDtPicker></owl-date-time>
                                </div>
                            </div>
                        </div>
                        <div class="comparison-expression-builder" style="padding-top: 55px;"
                            *ngIf="!selectedStixPatternBuilder">
                            <div class="col-6 offset-3">
                                Click on an observation expression to finish your STIX Pattern
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" aria-label="Close"
            (click)="modal.dismiss('Cross click'); resetStixPatternBuilder()">
            <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
            Cancel
        </button>
        <button class="btn btn-sm btn-secondary" type="button" (click)="page = page - 1;" *ngIf="page != 1">
            <fa-icon [icon]="faArrowLeft" class="pe-1"></fa-icon>
            Previous
        </button>
        <button class="btn btn-sm btn-primary" type="button" (click)="page = page + 1; clickContinue()"
            *ngIf="page != pageSize" [disabled]="disableContinue()">
            <fa-icon [icon]="faArrowRight" class="pe-1"></fa-icon>
            Next
        </button>
        <button class="btn btn-sm btn-success" [disabled]="disableSaveStixPattern()" type="button"
            *ngIf="page == pageSize" (click)="saveStixPattern()">
            <fa-icon [icon]="faSave" class="pe-1"></fa-icon>
            STIX Pattern
        </button>
    </div>
</ng-template>

<ng-template #body_multipart let-modal style="margin-left: 100px;">
    <div class="modal-header">
        <h2 class="modal-title" id="modal-title">Configure Body Multipart</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"></span>
        </button>
    </div>

    <div class="modal-body extensions-modal-body">
        <div class="col-md-12">
            <div class="warning-message text-center mb-2">{{this.modalError.get('object')}}</div>
            <div class="row bump">
                <div class="p-0 col-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Body</b>
                        </span>
                        <input class="form-control" (input)="isModalAddEnabled()" [(ngModel)]="this.currentModalObject['body']" type="text">
                    </div>
                </div>
                <div class="p-0 col-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Body Raw Ref</b>
                        </span>
                        <select class="form-control form-select dropdown" (change)="isModalAddEnabled()" [(ngModel)]="this.currentModalObject['body_raw_ref']">
                            <option value="" selected></option>
                            <ng-container *ngFor="let opt of this.stixService.bundle.objects">
                                <ng-container *ngIf="opt.id.match(objectRegex)">
                                    <option *ngIf="['artifact','file'].indexOf(opt.id.match(objectRegex)[1]) != -1"
                                        [value]="opt['id']">
                                        {{ getObjDisplayName(opt) }}
                                    </option>
                                </ng-container>
                            </ng-container>
                        </select>
                        <input class="form-control" [(ngModel)]="this.currentModalObject['body_raw_ref']" type="text">
                    </div>
                    <div class="mb-2"
                        *ngIf="this.currentModalObject['body_raw_ref'] != '' && this.modalError.get('body_raw_ref')">
                        <span class="warning-message">
                            {{this.modalError.get('body_raw_ref')}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row bump">
                <div class="p-0 col-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Content Type</b>
                        </span>
                        <input class="form-control" (input)="isModalAddEnabled()" [(ngModel)]="this.currentModalObject['content_type']" type="text">
                    </div>
                </div>
                <div class="p-0 col-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Content Disposition</b>
                        </span>
                        <input class="form-control" (input)="isModalAddEnabled()" [(ngModel)]="this.currentModalObject['content_disposition']"
                            type="text">
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="modal-footer">
        
        <button type="button" class="btn btn-sm btn-secondary" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
            Cancel
        </button>
        <button class="btn btn-sm btn-success" [disabled]="!modalEnabled" type="button"
            (click)="addModalObject('body_multipart'); this.isAddEnabled.emit()">
            <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
            Add
        </button>
    </div>
</ng-template>

<ng-template #windows_value let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-title">Configure Windows Registry Value Type</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"></span>
        </button>
    </div>

    <div class="modal-body extensions-modal-body">
        <div class="col-md-12">
            <span class="tab"></span>

            <div class="row bump">
                <div class="p-0 col-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Name</b>
                        </span>
                        <input class="form-control" (input)="isModalAddEnabled()" [(ngModel)]="this.currentModalObject['name']" type="text">
                    </div>
                </div>
                <div class="p-0 col-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Data</b>
                        </span>
                        <input class="form-control" (input)="isModalAddEnabled()" [(ngModel)]="this.currentModalObject['data']" type="text">
                    </div>
                </div>
            </div>

            <div class="row bump">
                <div class="p-0 col-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Data Type</b>
                        </span>
                        <select type="text" class="form-control form-select" (change)="isModalAddEnabled()" [(ngModel)]="this.currentModalObject['data_type']">
                            <option value=""></option>
                            <option *ngFor="let opt of dataOptions" [value]="opt">{{opt}}</option>
                        </select>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
            Cancel
        </button>
        <button class="btn btn-sm btn-success btn-windows-key" [disabled]="!modalEnabled" type="button"
            (click)="addModalObject('windows_value'); this.isAddEnabled.emit();">
            <fa-icon [icon]="faPlus" class="pe-1"></fa-icon>
            Add
        </button>
    </div>
</ng-template>

<ng-template #custom_obj let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-title">Configure Custom {{customObjType}}</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"></span>
        </button>
    </div>

    <div class="modal-body extensions-modal-body" id="custom-obj">
        <div class="col-12 p-0">
            <h4>Common Properties</h4>
            <div class="row bump p-0">
                <div class="col-4">
                    <div class="mb-2">
                        <span class="">
                            <p>Type*</p>
                        </span>
                        <input class="form-control" [(ngModel)]="this.currentModalObject['type']" type="text"
                            [readonly]="this.stixService.customObjReadOnly">
                    </div>
                </div>
                <div class="col-5">
                    <div class="mb-2">
                        <span class="">
                            <p>ID</p>
                        </span>
                        <input class="form-control" [(ngModel)]="this.currentModalObject['id']" type="text" readonly>
                    </div>
                </div>
                <div class="col-3">
                    <div class="mb-2">
                        <span class="">
                            <p>Spec Version</p>
                        </span>
                        <input class="form-control" [(ngModel)]="this.currentModalObject['spec_version']" type="text"
                            readonly>
                    </div>
                </div>
                <div class="mb-2" *ngIf="this.currentModalObject['type'] != '' && this.modalError.get('type')">
                    <span class="warning-message">
                        {{this.modalError.get('type')}}
                    </span>
                </div>
            </div>

            <div *ngIf="customObjType != 'SCO'">
                <div class="row bump p-0">
                    <div class="col-6">
                        <div class="mb-2">
                            <span >
                                <p>Created*</p>
                            </span>
                            <input class="form-control" [(ngModel)]="this.currentModalObject['created']" type="text"
                                readonly>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-2">
                            <span>
                                <p>Modified*</p>
                            </span>
                            <input class="form-control" [(ngModel)]="this.currentModalObject['modified']" type="text"
                                readonly>
                        </div>
                    </div>
                </div>

                <div class="row bump p-0">
                    <div class="col-6">
                        <div class="mb-2">
                            <span class="tooltipright">
                                <p class="float-start">Confidence</p>
                                <!-- <fa-icon (click)="openTooltipModal(confidenceTooltip)" class="ps-1"
                                    [icon]="faInfoCircle">
                                </fa-icon> -->
                                <fa-icon class="float-start ps-1" [icon]="faInfoCircle">
                                </fa-icon>
                                <div class="toolttext">
                                    <div class="tooltip-wrapper">
                                        <h2>Confidence Scales</h2>
                                        <div>The confidence property reflects the object creator's trust <br>
                                            in the accuracy of their data.
                                            The confidence value MUST <br> be a number in the range of 0-100.</div>
                                        <div class="tooltip-content">
                                            <table>
                                                <tr>
                                                    <th>Low/Med/High</th>
                                                    <th>Range of Values</th>
                                                </tr>
                                                <tr>
                                                    <td>Low</td>
                                                    <td>1-29</td>
                                                </tr>
                                                <tr>
                                                    <td>Med</td>
                                                    <td>30-69</td>
                                                </tr>
                                                <tr>
                                                    <td>High</td>
                                                    <td>70-100</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <input class="form-control" [(ngModel)]="this.currentModalObject['confidence']"
                                type="number" [readonly]="this.stixService.customObjReadOnly">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-2">
                            <span>
                                <p>Language</p>
                            </span>
                            <input *ngIf="this.stixService.customObjReadOnly" class="form-control"
                                [(ngModel)]="this.currentModalObject['lang']" type="text" readonly>
                            <select *ngIf="!this.stixService.customObjReadOnly" class="form-control form-select dropdown"
                                [(ngModel)]="this.currentModalObject['lang']">
                                <option value="" selected></option>
                                <option *ngFor="let lang of languages" [value]="lang.key">{{lang.value}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row bump p-0">
                    <div class="col-6">
                        <div class="mb-2">
                            <span class="">
                                <p>Created By Ref</p>
                            </span>
                            <input *ngIf="this.stixService.customObjReadOnly" class="form-control" type="text"
                                [(ngModel)]="this.currentModalObject['created_by_ref']" readonly>
                            <select *ngIf="!this.stixService.customObjReadOnly" type="text" class="form-control form-select"
                                [(ngModel)]="this.currentModalObject['created_by_ref']">
                                <option value=""></option>
                                <option *ngFor="let id of ids" [value]="id.id">{{id.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-2">
                            <span class="">
                                <p>Revoked</p>
                            </span>
                            <input *ngIf="this.stixService.customObjReadOnly" class="form-control" type="text"
                                [(ngModel)]="this.currentModalObject['revoked']" readonly>
                            <select *ngIf="!this.stixService.customObjReadOnly" class="form-control form-select dropdown"
                                [(ngModel)]="this.currentModalObject['revoked']">
                                <option value="" selected></option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- Labels String Array -->
                <div class="row bump p-0">
                    <div class="col-12">
                        <span class="">
                            <p>Labels</p>
                        </span>
                        <div class="input-group mb-2">
                            <input class="form-control" [(ngModel)]="this.modalProps['modal_labels']" type="text"
                                [readonly]="this.stixService.customObjReadOnly">
                            <div>
                                <button *ngIf="!this.stixService.customObjReadOnly"
                                    class="btn btn-fa-sm btn-primary ms-1" type="button"
                                    (click)="addModalString(this.modalProps['modal_labels'], 'modal_labels')"
                                    
                                    [disabled]="this.modalProps['modal_labels'] == undefined || this.modalProps['modal_labels'] == '' || this.stixService.customObjReadOnly">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <span class="tab">
                        <div class="form-group mt-2">
                            <li *ngFor="let x of this.currentModalObject.get('modal_labels')" tabindex="0">
                                {{x}}
                                <button *ngIf="!this.stixService.customObjReadOnly"
                                    class="btn btn-fa-xsm btn-danger trash-can ms-1" type="button"
                                    (click)="deleteModalString(x, 'modal_labels')"
                                    style="height:40px;width:auto;background-color:rgb(210, 0, 0)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </div>
                    </span>
                </div>
            </div>

            <div *ngIf="customObjType == 'SCO'">
                <div class="row bump p-0">
                    <div class="col-12">
                        <div class="my-2">
                            <span class="">
                                <p>Defanged</p>
                            </span>
                            <input *ngIf="this.stixService.customObjReadOnly" class="form-control" type="text"
                                [(ngModel)]="this.currentModalObject['defanged']" readonly>
                            <select *ngIf="!this.stixService.customObjReadOnly" class="form-control form-select dropdown"
                                [(ngModel)]="this.currentModalObject['defanged']">
                                <option value="" selected></option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="!this.stixService.customObjReadOnly">
                <span class="tab"></span>
                <h4>Create Custom Property</h4>
                <div class="row">
                    <div class="col-6">
                        <div class="">
                            <span class="">
                                <p>Property Name</p>
                            </span>
                            <input class="form-control" [(ngModel)]="customProp['name']" type="text">
                        </div>
                        <div class="mb-2"
                            *ngIf="this.currentModalObject['name'] != '' && this.modalError.get('customName')">
                            <span class="warning-message">
                                {{this.modalError.get('customName')}}
                            </span>
                        </div>
                    </div>
                    <div class="col-6">
                        <span class="">
                            <p>Property Type</p>
                        </span>
                        <div class="input-group mb-2">
                            <select type="text" class="form-control form-select" [(ngModel)]="customProp['type']">
                                <option value=""></option>
                                <option value="text">Text</option>
                                <option value="boolean">Boolean</option>
                                <option value="integer">Integer</option>
                                <option value="datetime">Datetime</option>
                                <option value="array">Array</option>
                                <option value="dict">Dictionary</option>
                            </select>
                            <div>
                                <button class="btn btn-fa-sm btn-primary" type="button"
                                    (click)="createProperty(collection)"
                                    [disabled]="customProp['type'] == '' || customProp['name'] == '' || ((customProp['type'] == 'text' && customProp['text'] == '') || (customProp['type'] == 'integer' && customProp['integer'] == '') || (customProp['type'] == 'datetime' && customProp['datetime'] == ''))">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container [ngSwitch]="this.customProp['type']">
                    <div *ngSwitchCase="'text'" class="row">
                        <div class="mb-2 col-12">
                            <span class="">
                                <p>Property Value</p>
                            </span>
                            <textarea class="form-control" [(ngModel)]="customProp['text']" type="text"></textarea>
                        </div>
                    </div>
                    <div *ngSwitchCase="'boolean'" class="row">
                        <div class="mb-2 col-6">
                            <span class="">
                                <p>Property Value</p>
                            </span>
                            <select class="form-control form-select dropdown" [(ngModel)]="customProp['boolean']">
                                <option value="" selected></option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </div>
                    </div>
                    <div *ngSwitchCase="'integer'" class="row">
                        <div class="mb-2 col-6">
                            <span class="">
                                <p>Property Value</p>
                            </span>
                            <input class="form-control" [(ngModel)]="customProp['integer']" type="number">
                        </div>
                    </div>
                    <div *ngSwitchCase="'datetime'" class="row">
                        <div class="mb-2 col-6">
                            <span class="">
                                <p>Property Value</p>
                            </span>
                            <input class="form-control" [(ngModel)]="customProp['datetime']"
                                [owlDateTimeTrigger]="dtPickerCustom" [owlDateTime]="dtPickerCustom">
                            <owl-date-time #dtPickerCustom></owl-date-time>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <!-- Messed up logic first time - this is only present when there is a custom property -->
            <ng-container
                *ngIf="!((!this.customStringPropKeys || this.customStringPropKeys.length == 0) && (!this.customArrPropKeys || this.customArrPropKeys.length == 0))">
                <span class="tab"></span>
                <h4>Your Custom Properties</h4>
                <hr>
                <div class="row">
                    <span class="tab">
                        <div class="form-group mt-2">
                            <li *ngFor="let x of this.customStringPropKeys" tabindex="0">
                                {{x}}: {{this.currentModalObject[x]}}
                                <button *ngIf="!this.stixService.customObjReadOnly"
                                    class="btn btn-fa-xsm btn-danger trash-can ms-1" type="button"
                                    (click)="deleteProperty(x)"
                                    style="height:40px;width:auto;background-color:rgb(210, 0, 0)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </div>
                    </span>
                </div>
                <div *ngIf="this.customArrPropKeys && this.customArrPropKeys.length > 0" class="col-12 p-0">
                    <div class="form-group mt-2 bump">
                        <accordion>
                            <accordion-group *ngFor="let x of this.customArrPropKeys" [heading]="x"
                                [panelClass]="'accordion-panel-class'">
                                <div *ngIf="!this.stixService.customObjReadOnly" class="row">
                                    <div class="col-md-2">
                                        <button type="button" class="btn btn-fa btn-primary me-2"
                                            (click)="this.editCollection(x, collection);">
                                            <fa-icon [icon]="faEdit"></fa-icon>
                                        </button>
                                        <button type="button" class="btn btn-fa btn-warning"
                                            style="background-color:rgb(210, 0, 0)" (click)="deleteProperty(x);">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                                <pre>{{ this.currentModalObject[x] | json }}</pre>
                            </accordion-group>
                        </accordion>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- <div class="col-5 pe-5">
                <div class="row ps-4">
                    <app-granular-markings class="w-100"></app-granular-markings>
                </div>
                <div class="row ps-4" *ngIf="customObjType != 'SCO'">
                    <hr class="section-separator">
                    <app-external-references class="w-100"></app-external-references>
                </div>
                <div class="row ps-4">
                    <hr class="section-separator">
                    <app-string-array [key]="'object_marking_refs'" class="w-100"></app-string-array>
                </div>
                <div class="row ps-4">
                    <hr class="section-separator">
                    <app-extensions class="w-100"></app-extensions>
                </div>
            </div> -->
    </div>

    <div class="modal-footer mb-2">
        <button type="button" class="btn btn-sm btn-secondary" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
            Cancel
        </button>
        <button class="btn btn-sm btn-success me-3" [disabled]="!isCustomObjAddEnabled()" type="button"
            (click)="addModalObject(this.currentModalObject['id']); this.isAddEnabled.emit()">
            <fa-icon [icon]="faPlusCircle" class="pe-1"></fa-icon>
            Create
            <!-- Create Object -->
        </button>
    </div>
</ng-template>

<ng-template #collection let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-title">Configure {{this.customProp['name']}}</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"></span>
        </button>
    </div>

    <div class="modal-body extensions-modal-body" id="collection">
        <div class="col-md-12">
            <span class="tab"></span>

            <!-- String Array -->
            <ng-container *ngIf="this.customProp['type'] == 'array'">
                <div class="row bump">
                    <div class="p-0 col-12">
                        <div class="input-group mb-2">
                            <span class="input-group-text">
                                <b>Value</b>
                            </span>
                            <input class="form-control" [(ngModel)]="this.customProp['value']" type="text">
                            <div>
                                <button class="btn btn-fa-sm btn-primary  " type="button"
                                    (click)="addSubModalString(this.customProp['value'], 'customArray')"
                                    [disabled]="this.customProp['value'] == ''">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <span class="tab">
                        <div class="form-group mt-2">
                            <li *ngFor="let x of this.customProp.get('customArray')" tabindex="0">
                                {{x}}
                                <button class="btn btn-fa-xsm btn-danger trash-can ms-1" type="button"
                                    (click)="deleteSubModalString(x, 'customArray')">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </div>
                    </span>
                </div>
            </ng-container>

            <!-- String Dict -->
            <ng-container *ngIf="this.customProp['type'] == 'dict'" [ngClass]="{'display-flex': stixService.guidedUI}">
                <div class="row">
                    <div class="p-0 col-12" [ngClass]="{'display-flex': stixService.guidedUI}">
                        <div class="input-group mb-2 col-12" [ngClass]="{'display-flex mt-1 pe-1': stixService.guidedUI}">
                            <span class="input-group-text">
                                <b>Key</b>
                            </span>
                            <input class="form-control" [(ngModel)]="this.customProp['key']" type="text">
                        </div>
                        <div class="input-group mb-2 col-12">
                            <span class="input-group-text">
                                <b>Value</b>
                            </span>
                            <input class="form-control" [(ngModel)]="this.customProp['value']" type="text">
                            <div>
                                <button class="btn btn-fa-sm btn-primary" type="button"
                                    (click)="addSubModalDictString(this.customProp['key'], this.customProp['value'], 'customDict')"
                                    [disabled]="this.customProp['key'] == '' || this.customProp['value'] == ''">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <span class="tab">
                        <div class="form-group mt-2">
                            <li *ngFor="let x of this.customProp.get('customDict').keys()" tabindex="0">
                                {{x}}: {{this.customProp.get('customDict').get(x)}}
                                <button class="btn btn-fa-xsm btn-danger trash-can ms-1" type="button"
                                    (click)="deleteSubModalDictString(x, 'customDict')">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </div>
                    </span>
                </div>
            </ng-container>

        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
            Cancel
        </button>
        <button class="btn btn-sm btn-primary me-3" type="button" (click)="createSubProperty()"
            [disabled]="this.customProp.get('customArray').length == 0 && this.customProp.get('customDict').size == 0">
            <fa-icon [icon]="faPlusCircle" class="pe-1"></fa-icon>
            Create
            <!-- Create Property -->
        </button>
    </div>
</ng-template>