
<div class="row sticky-top pb-3" style="top: 69px; background-color: white;">
  <div class="col-12 mt-1">
    <div class="row ps-3">
      <h5 class="pt-1">Incident Core Extension</h5>
      <div class="pe-0 ps-5">
        <button type="button" *ngIf="!this.currentIncidentCoreExtension['isEnabled']"
            class="btn btn-fa-sm btn-outline-primary" style="padding-left:12px !important;"
            (click)="this.currentIncidentCoreExtension['isEnabled'] = !this.currentIncidentCoreExtension['isEnabled'];this.stixService.sendIceStatus(false);">
            <fa-icon [icon]="faAngleDoubleDown"></fa-icon>
        </button>
        <button type="button" *ngIf="this.currentIncidentCoreExtension['isEnabled']"
            class="btn btn-fa-sm btn-outline-secondary"
            style="padding-left:12px !important;"
            (click)="this.currentIncidentCoreExtension['isEnabled'] = !this.currentIncidentCoreExtension['isEnabled'];this.stixService.sendIceStatus(true);">
            <fa-icon [icon]="faAngleDoubleUp"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- <div class="col-6">
    <div class="float-end d-flex justify-content-end pe-0">
      <button type="button" *ngIf="!isEnabled"
          class="btn btn-fa-sm btn-outline-primary" style="padding-left:12px !important;"
          (click)="isEnabled = !isEnabled">
          <fa-icon [icon]="faAngleDoubleDown"></fa-icon>
      </button>
      <button type="button" *ngIf="isEnabled"
          class="btn btn-fa-sm btn-outline-secondary"
          style="padding-left:12px !important;"
          (click)="isEnabled = !isEnabled">
          <fa-icon [icon]="faAngleDoubleUp"></fa-icon>
      </button>
    </div>
  </div> -->
</div>
<div *ngIf="this.errorMessage && this.errorMessage.length > 0" class="row">
  <div class="mx-auto warning-message">{{ this.errorMessage }}</div>
</div>

<div class="form-group p-2" *ngIf="this.currentIncidentCoreExtension['isEnabled']">
  <div class="row">
    <div class="col-2 pt-3 pb-3 table-content position-sticky" style="color: #a7a7a7; height:40%; contain:layout; font-size: .9rem; top:110px;">
      <div class="row p-1">
        <div class="col-12 text-start sidebar-item" (click)="scrollTo(determinationRow)">
          Determination*
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12 text-start sidebar-item" (click)="scrollTo(investigationRow)">
          Investigation Status*
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12 text-start sidebar-item" (click)="scrollTo(criticalityRow)">
          Criticality
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12 text-start sidebar-item" (click)="scrollTo(recoverabilityRow)">
          Recoverability
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12 text-start sidebar-item" (click)="scrollTo(detectionRow)">
          Detection Methods
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12 text-start sidebar-item" (click)="scrollTo(incidentRow)">
          Incident Types
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12 text-start sidebar-item" (click)="scrollTo(impactedEntRow)">
          Impacted Entity Counts
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12 text-start sidebar-item" (click)="scrollTo(impactRefsRow)">
          Impact Refs
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12 text-start sidebar-item" (click)="scrollTo(taskRefsRow)">
          Task Refs
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12 text-start sidebar-item" (click)="scrollTo(eventRefsRow)">
          Event Refs
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12 text-start sidebar-item" (click)="scrollTo(scoreRow)">
          Scores
        </div>
      </div>
    </div>
    <div class="col-10 pt-3 pb-3" style="border-left: 1px solid #ced4da;">
      <div class="row">
        <div id="left-col" class="col-6 pe-3 pb-0 ps-5">
          <div #determinationRow id="determination-row" class="row p-2 ps-0 pb-3 row-item">
            <div class="col">
              <div class="row">
                <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                  Determination*
                </div>
              </div>
              <!-- <div class="row description-box">
                <div class="col-10 mb-3 ms-0">
                  A high-level determination on the status of this incident. The value of this property SHOULD be suspected until enough information is available to provide a well researched result. Some automated tools may flag results as blocked or low-value automatically depending on the tool type or activity. For example, a tool that blocks a series of phishing emails may create an incident with a blocked determination automatically. The values of this property MUST come from the incident-determination-enum enumeration.
                </div>
              </div> -->
              <div class="row">
                <div class="col-11">
                  <input
                  *ngIf="this.stixService.revoked || this.stixService.revocation"
                  class="form-control"
                  [(ngModel)]="this.currentIncidentCoreExtension['determination']"
                  readonly
                  />
                  <select
                    *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                    class="form-control dropdown"
                    [ngClass]="{'disabled-select': this.currentIncidentCoreExtension['determination'] === undefined}"
                    [(ngModel)]="this.currentIncidentCoreExtension['determination']"
                    (change)="isValid();"
                  >
                    <option [ngValue]="undefined" disabled selected>Select determination</option>
                    <option
                      *ngFor="let opt of this.incidentDeterminationEnum"
                      [value]="opt"
                    >
                      {{ this.stixService.toTitle(opt) }}
                    </option>
                </select>
                </div>
              </div>
            </div>
          </div>
          <div #criticalityRow id="criticality-row" class="row p-2 ps-0 pb-3 row-item">
            <div class="col">
              <div class="row">
                <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                  Criticality
                  <span class="tooltipright ms-2">
                    <fa-icon [icon]="faInfoCircle"> </fa-icon>
                    <div class="toolttext font-weight-normal">
                      Please reference Appendix B of the Incident Core Extension Docs to
                      find the table/scoring guidelines most appropriate for the situation
                    </div>
                  </span>
                  <span *ngIf="this.otherErrors['criticality'] && this.otherErrors['criticality'] != ''" class=" warning-message ps-3">
                      {{ this.otherErrors['criticality'] }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-11">
                  <input
                  class="form-control"
                  type="number"
                  placeholder="Criticality"
                  [(ngModel)]="this.currentIncidentCoreExtension['criticality']"
                  [readonly]="this.stixService.revoked || this.stixService.revocation"
                  (change)="isValid()"
                />
                </div>
              </div>
            </div>
          </div>
          <div #detectionRow id="detection-row" class="row p-2 ps-0 pb-3 row-item">
            <div class="col">
              <div class="row">
                <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                  Detection Methods
                </div>
              </div>
              <div class="row">
                <div class="col-10 me-0 pe-0">
                  <input
                  *ngIf="this.stixService.revoked || this.stixService.revocation"
                  class="form-control"
                  [(ngModel)]="detectionMethods"
                  readonly
                  />
                  <select
                    *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                    [ngClass]="{'disabled-select': detectionMethods === undefined}"
                    class="form-control dropdown add-left no-border-right"
                    [(ngModel)]="detectionMethods"
                  >
                    <option [ngValue]="undefined" disabled selected>Select detection method</option>
                    <option
                      *ngFor="let opt of this.incidentDetectionMethodsEnum"
                      [value]="opt"
                    >
                      {{ this.stixService.toTitle(opt) }}
                    </option>
                  </select>
                </div>
                <div class="col-1 ms-0 ps-0">
                  <ng-container
                    *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                  >
                    <button
                      class="btn btn-fa-sm btn-primary add-button add-right"
                      type="button"
                      (click)="addString('detection_methods')"
                    >
                      <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                  </ng-container>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mb-2 mt-2">
                  <div class="row mb-1 ref-item"  *ngFor="let currentString of this.currentIncidentCoreExtension['detection_methods']" tabindex="0">
                      <div class="col-10 pe-0">
                        <input
                          class="form-control dropdown add-left no-border-right"
                          value="{{currentString}}"
                          readonly
                        >
                      </div>
                      <div class="col ps-0">
                        <button
                          class="btn btn-fa-sm btn-primary add-button add-right font-weight-bold"
                          type="button"
                          (click)="deleteString(currentString, 'detection_methods')"
                        >
                          X
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="right-col" class="col-6 pe-3 pb-0 ps-0">
          <div #investigationRow id="investigation-row" class="row p-2 ps-0 pb-3 row-item">
            <div class="col">
              <div class="row">
                <div class="col stix-title ms-0 mb-2 input-header" style="float: left;">
                  Investigation Status*
                </div>
              </div>
              <!-- <div class="row description-box">
                <div class="col-10 mb-3 ms-0">
                  The current status of the incident investigation. The values of this property SHOULD come from the incident-investigation-ov open vocabulary.
                </div>
              </div> -->
              <div class="row">
                <div class="col-11">
                  <input
                    *ngIf="this.stixService.revoked || this.stixService.revocation"
                    class="form-control"
                    [(ngModel)]="this.currentIncidentCoreExtension['investigation_status']"
                    readonly
                  />
                  <select
                    *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                    class="form-control dropdown"
                    [ngClass]="{'disabled-select': this.currentIncidentCoreExtension['investigation_status'] === undefined}"
                    [(ngModel)]="this.currentIncidentCoreExtension['investigation_status']"
                    (change)="isValid()"
                  >
                    <option [ngValue]="undefined" disabled selected>Select investigation status</option>
                    <option *ngFor="let opt of this.incidentInvestigationOV" [value]="opt">
                      {{ this.stixService.toTitle(opt) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div #recoverabilityRow id="recoverability-row" class="row p-2 ps-0 pb-3 row-item">
            <div class="col">
              <div class="row">
                <div class="col stix-title ms-0 mb-2 input-header" style="float: left">
                  Recoverability
                </div>
              </div>
              <div class="row">
                <div class="col-11">
                  <input
                    *ngIf="this.stixService.revoked || this.stixService.revocation"
                    class="form-control"
                    [(ngModel)]="this.currentIncidentCoreExtension['recoverability']"
                    readonly
                  />
                  <select
                    *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                    [ngClass]="{'disabled-select': this.currentIncidentCoreExtension['recoverability'] === undefined}"
                    class="form-control dropdown"
                    [(ngModel)]="this.currentIncidentCoreExtension['recoverability']"
                  >
                    <option [ngValue]="undefined" disabled selected>Select recoverability</option>
                    <option value=""></option>
                    <option *ngFor="let opt of this.recoverabilityEnum" [value]="opt">
                      {{ this.stixService.toTitle(opt) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div #incidentRow id="incident-types-row" class="row pt-2 pe-0 ps-2 pb-3 row-item">
            <div class="col">
              <div class="row">
                <div class="col stix-title ms-0 mb-2 input-header" style="float: left">
                  Incident Types
                </div>
              </div>
              <div class="row">
                <div class="col-10 me-0 pe-0">
                  <input
                  *ngIf="this.stixService.revoked || this.stixService.revocation"
                  class="form-control"
                  [(ngModel)]="incidentTypes"
                  readonly
                  />
                  <select
                    *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                    [ngClass]="{'disabled-select': incidentTypes === undefined}"
                    class="form-control dropdown add-left no-border-right"
                    [(ngModel)]="incidentTypes"
                  >
                    <option [ngValue]="undefined" disabled selected>Select incident type</option>
                    <option
                      *ngFor="let opt of this.incidentEventTypeOV"
                      [value]="opt"
                    >
                      {{ this.stixService.toTitle(opt) }}
                    </option>
                  </select>
                </div>
                <div class="col-1 ms-0 ps-0">
                  <ng-container
                    *ngIf="!this.stixService.revoked && !this.stixService.revocation"
                  >
                    <button
                      class="btn btn-fa-sm btn-primary add-button add-right"
                      type="button"
                      (click)="addString('incident_types')"
                    >
                      <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                  </ng-container>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mb-2 mt-2">
                  <div class="row mb-1 ref-item"  *ngFor="let currentString of this.currentIncidentCoreExtension['incident_types']" tabindex="0">
                      <div class="col-10 pe-0">
                        <input
                          class="form-control dropdown add-left no-border-right"
                          value="{{currentString}}"
                          readonly
                        >
                      </div>
                      <div class="col ps-0">
                        <button
                          class="btn btn-fa-sm btn-primary add-button add-right font-weight-bold"
                          type="button"
                          (click)="deleteString(currentString, 'incident_types')"
                        >
                          X
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pe-3 ps-5">
          <div #impactedEntRow id="impacted-row" class="row p-2 ps-0 pb-3 row-item">
            <div class="col">
              <div class="row">
                <div class="col stix-title ms-0 mb-2 input-header" style="float: left">
                  Impacted Entity Counts
                  <span *ngIf="this.otherErrors['impacted_entity_counts'] && this.otherErrors['impacted_entity_counts'] != ''" class="mb-2 warning-message ps-3">
                    {{ this.otherErrors['impacted_entity_counts'] }}
                </span>
                </div>
              </div>
              <div class="row">
                <div class="col-10 pe-0">
                  <input
                    type="text"
                    matInput
                    placeholder="Select impacted entity"
                    class="form-control "
                    [matAutocomplete]="impactedEntityCounts"
                    [(ngModel)]="entity_count_string"
                    (ngModelChange)="filterEntityCounts()"
                    [readonly]="this.stixService.revoked || this.stixService.revocation"
                  />
                  <mat-autocomplete #impactedEntityCounts="matAutocomplete" class="autocomplete">
                    <mat-option *ngFor="let opt of this.entityCountsList" [value]="opt">
                      {{opt}}
                    </mat-option>
                  </mat-autocomplete>
                </div>
                <div class="col-1 pe-0">
                  <input
                    class="form-control add-both no-border-right"
                    type="number"
                    placeholder="Count"
                    [(ngModel)]="entity_count_count"
                    [readonly]="this.stixService.revoked || this.stixService.revocation"
                  />
                </div>
                <div class="col-1 ps-0">
                  <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                    <button
                      class="btn btn-fa-sm btn-primary no-border-left add-right"
                      type="button"
                      (click)="addEntityCount()"
                    >
                      <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                  </ng-container>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mb-2 mt-2">
                  <div class="row mb-1 ref-item"  *ngFor="let x of this.impacted_entity_counts.keys()" tabindex="0">
                      <div class="col-11 pe-0">
                        <input
                          class="form-control dropdown add-left no-border-right"
                          value="{{x}}: {{this.impacted_entity_counts.get(x)}}"
                          readonly
                        >
                      </div>
                      <div class="col ps-0">
                        <button
                          class="btn btn-fa-sm btn-primary add-button add-right font-weight-bold"
                          type="button"
                          (click)="this.impacted_entity_counts.delete(x)"
                        >
                          X
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div #impactRefsRow id="impact-refs-row" class="row p-2 ps-0 pb-3 row-item">
            <div class="col">
              <div class="row">
                <div class="col stix-title ms-0 mb-2 input-header" style="float: left">
                  Impact Refs
                  <span *ngIf="this.otherErrors['impact_refs'] && this.otherErrors['impact_refs'] != ''" class=" warning-message ps-3">
                      {{ this.otherErrors['impact_refs'] }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-11 pe-0">
                  <input
                    type="text"
                    matInput
                    placeholder="Select impact reference"
                    class="form-control add-left no-border-right"
                    [matAutocomplete]="impactRefAuto"
                    [(ngModel)]="impactRefs"
                    (ngModelChange)="filterImpactRefs()"
                    [readonly]="this.stixService.revoked || this.stixService.revocation"
                  />
                  <mat-autocomplete #impactRefAuto="matAutocomplete" class="autocomplete">
                    <mat-option *ngFor="let opt of this.filteredImpactRefsList" [value]="opt.id">
                      {{ opt['impact_category'] ? opt['impact_category'] + " (" + opt['id'] + ')'  : opt['id']}}
                    </mat-option>
                  </mat-autocomplete>
                </div>
                <div class="col-1 ps-0">
                  <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                    <button
                      class="btn btn-fa-sm btn-primary no-border-left add-right"
                      type="button"
                      (click)="addString('impact_refs')"
                    >
                      <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                  </ng-container>
                </div> 
              </div>
              <div class="row">
                <div class="col-12 mb-2 mt-2">
                  <div class="row mb-1 ref-item"  *ngFor="let currentString of this.currentIncidentCoreExtension['impact_refs']" tabindex="0">
                      <div class="col-11 pe-0">
                        <input
                          class="form-control dropdown add-left no-border-right"
                          value="{{getRef('impactRefsList', currentString)}}"
                          readonly
                        >
                      </div>
                      <div class="col ps-0">
                        <button
                          class="btn btn-fa-sm btn-primary add-button add-right font-weight-bold"
                          type="button"
                          (click)="deleteString(currentString, 'impact_refs')"
                        >
                          X
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div #eventRefsRow id="event-refs-row" class="row p-2 ps-0 pb-3 row-item">
            <div class="col">
              <div class="row">
                <div class="col stix-title ms-0 mb-2 input-header" style="float: left">
                  Event Refs
                  <span *ngIf="this.otherErrors['event_refs'] && this.otherErrors['event_refs'] != ''" class=" warning-message ps-3">
                      {{ this.otherErrors['event_refs'] }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-11 pe-0">
                  <input
                    type="text"
                    matInput
                    placeholder="Select event reference"
                    class="form-control add-left no-border-right"
                    [matAutocomplete]="eventRefAuto"
                    [(ngModel)]="eventRefs"
                    (ngModelChange)="filterEventRefs()"
                    [readonly]="this.stixService.revoked || this.stixService.revocation"
                  />
                  <mat-autocomplete #eventRefAuto="matAutocomplete" class="autocomplete">
                    <mat-option *ngFor="let opt of this.filteredEventRefsList" [value]="opt.id">
                      {{ opt['name'] ? opt['name'] + " (" + opt['id'] + ')' : opt['id']}}
                    </mat-option>
                  </mat-autocomplete>
                </div>  
                <div class="col-1 ps-0">
                  <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                    <button
                      class="btn btn-fa-sm btn-primary no-border-left add-right"
                      type="button"
                      (click)="addString('event_refs')"
                    >
                      <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                  </ng-container>
                </div> 
              </div>
              <div class="row">
                <div class="col-12 mb-2 mt-2">
                  <div class="row mb-1 ref-item"  *ngFor="let currentString of this.currentIncidentCoreExtension['event_refs']" tabindex="0">
                      <div class="col-11 pe-0">
                        <input
                          class="form-control dropdown add-left no-border-right"
                          value="{{getRef('eventRefsList', currentString)}}"
                          readonly
                        >
                      </div>
                      <div class="col ps-0">
                        <button
                          class="btn btn-fa-sm btn-primary add-button add-right font-weight-bold"
                          type="button"
                          (click)="deleteString(currentString, 'event_refs')"
                        >
                          X
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div #taskRefsRow id="task-refs-row" class="row p-2 ps-0 pb-3 row-item">
            <div class="col">
              <div class="row">
                <div class="col stix-title ms-0 mb-2 input-header" style="float: left">
                  Task Refs
                  <span *ngIf="this.otherErrors['task_refs'] && this.otherErrors['task_refs'] != ''" class=" warning-message ps-3">
                      {{ this.otherErrors['task_refs'] }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-11 pe-0">
                  <input
                    type="text"
                    placeholder="Select task reference"
                    matInput
                    class="form-control add-left no-border-right"
                    [matAutocomplete]="taskRefAuto"
                    [(ngModel)]="taskRefs"
                    (ngModelChange)="filterTaskRefs()"
                    [readonly]="this.stixService.revoked || this.stixService.revocation"
                  />
                  <mat-autocomplete #taskRefAuto="matAutocomplete" class="autocomplete">
                    <mat-option *ngFor="let opt of this.filteredTaskRefsList" [value]="opt.id">
                      {{ opt['name'] ? opt['name'] + " (" + opt['id'] + ')' : opt['id']}}
                    </mat-option>
                  </mat-autocomplete>
                </div>  
                <div class="col-1 ps-0">
                  <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                    <button
                      class="btn btn-fa-sm btn-primary no-border-left add-right"
                      type="button"
                      (click)="addString('task_refs')"
                    >
                      <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                  </ng-container>
                </div> 
              </div>
              <div class="row">
                <div class="col-12 mb-2 mt-2">
                  <div class="row mb-1 ref-item"  *ngFor="let currentString of this.currentIncidentCoreExtension['task_refs']" tabindex="0">
                      <div class="col-11 pe-0">
                        <input
                          class="form-control dropdown add-left no-border-right"
                          value="{{getRef('taskRefsList', currentString)}}"
                          readonly
                        >
                      </div>
                      <div class="col ps-0">
                        <button
                          class="btn btn-fa-sm btn-primary add-button add-right font-weight-bold"
                          type="button"
                          (click)="deleteString(currentString, 'task_refs')"
                        >
                          X
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div #scoreRow id="score-row" class="row p-2 ps-0 pb-3 row-item">
            <div class="col">
              <div class="row">
                <div class="col stix-title ms-0 mb-2 input-header" style="float: left">
                  Scores
                  <span *ngIf="this.otherErrors['scores'] && this.otherErrors['scores'] != ''" class=" warning-message ps-3">
                      {{ this.otherErrors['scores'] }}
                  </span>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-11">
                  <div class="row">
                    <div class="col-6">
                      <input class="form-control" id="scores_name" type="text" placeholder="Name" [(ngModel)]="scores_name"
                        [readonly]="this.stixService.revoked || this.stixService.revocation">
                    </div>
                    <div class="col-6 pe-0">
                      <input class="form-control" id="scores_name" type="number" [(ngModel)]="scores_value" placeholder="Value"
                      [readonly]="this.stixService.revoked || this.stixService.revocation"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-11 pe-0">
                  <input class="form-control add-left no-border-right" id="scores_name" type="text" placeholder="Description" [(ngModel)]="scores_description"
                  [readonly]="this.stixService.revoked || this.stixService.revocation">
                </div>
                <div class="col-1 ps-0">
                  <button class="btn btn-fa-sm btn-primary add-right" type="button"
                  (click)="createScore()">
                  <fa-icon [icon]="faPlus"></fa-icon>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mb-2 mt-2">
                  <div class="row mb-1 ref-item" *ngFor="let currentString of this.currentIncidentCoreExtension['scores']; index as i" tabindex="0">
                    <div class="col">
                      <div class="row">
                        <div class="col-11 pe-0">
                          <input
                            class="form-control score-item dropdown add-left no-border-right"
                            value="{{currentString['name']}}"
                            [ngClass]="{'no-border-bottom': collapseScore[i]}"
                            (click)="changeCollapse(i)"
                            readonly
                          >
                        </div>
                        <div class="col ps-0">
                          <button
                            class="btn btn-fa-sm btn-primary add-button add-right font-weight-bold"
                            type="button"
                            (click)="deleteScore(i)"
                          >
                            X
                          </button>
                        </div>
                      </div>
                      <div class="row" *ngIf="collapseScore[i]">
                        <div class="col-11 pe-0">
                          <textarea class="form-control p-2" value="{{currentString | json}}" style="border-top: none; border-radius: 0 0 .25rem .25rem; background-color: white; min-height: 150px; max-height: 300px;" disabled readonly></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {{this.fixThis()}}
</div>

