import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { StixService } from "src/app/stix-service.service";
import {
  faPlus,
  faTrash,
  faInfoCircle,
  faBan,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import { environment } from 'src/environments/environment';
import { SnippetObject } from "./snippet";
import { MatDialog } from "@angular/material/dialog";
import { ExternalReferencesDialogComponent } from "../../external-references/external-references-dialog/external-references-dialog.component";


@Component({
  selector: "snippet-dialog",
  templateUrl: "./snippet-dialog.component.html",
  styleUrls: ["./snippet-dialog.component.css"],
})
export class SnippetDialogComponent implements OnInit {
  faPlus = faPlus;
  faTrash = faTrash;
  faInfoCircle = faInfoCircle;
  faBan = faBan;
  faFileImport = faFileImport;

  errors = {};

  currSnippet = new SnippetObject("");
  currReference = '';

  methodIds: any[] = this.stixService.bundle.objects.filter(o => o.id.includes('method--'));
  filteredMethodIds = this.methodIds;

  identityIds: any[] = [{ id: environment.cisaIdentity.id, name: environment.cisaIdentity.name }];
  filteredIdentityIds = this.identityIds;

  implementationLangOV = [
    "asm",
    "cmd",
    "delphi",
    "masm",
  ]
  filteredLang = this.implementationLangOV;

  constructor(
    public stixService: StixService,
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<SnippetDialogComponent>
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onX(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {

    for (let prop in this.currSnippet) {
      if (this.currSnippet[prop].length == 0) {
        delete this.currSnippet[prop];
      }
    }

    this.dialogRef.close(this.currSnippet);
  }

  ngOnInit(): void {}

  createRef(): void {
    let dialogRef
    dialogRef = this.matDialog.open(ExternalReferencesDialogComponent, {
      height: "600px",
      width: `${window.innerWidth / 3 * 2}px`,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        for (let prop in result) {
          if (prop == "hashes") {
            let empty = true;
            for (let p in result[prop]) {
              empty = false;
              break;
            }
            if (empty) {
              delete result[prop];
            }
          }
          else if (result[prop].length == 0) {
            delete result[prop];
          }
        }
        
        this.currSnippet.references.push(result);
      }
    });
  }

  isDialogValid(): boolean {
    let regex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
      if (!regex.test(this.currSnippet.exemplify_ref) || !this.currSnippet.exemplify_ref.startsWith("malware-method--")) {
        this.errors["detect_ref"] = "Must be a valid ID of type malware-method";
      }
      else {
        delete this.errors["detect_ref"];
      }


    return this.currSnippet.snippet.length > 0;
  }

  filterRefs(type: string) {
    switch (type) {
      case 'author_ref':
        this.filteredIdentityIds = this.identityIds.filter(elem => {
          if (elem.id.includes(this.currSnippet.author_ref.trim()))
            return true;

          return false;
        });
        break;
      case 'detect_ref':
        this.filteredMethodIds = this.methodIds.filter(elem => elem.id.includes(this.currSnippet.exemplify_ref.trim()));
        break;
      case 'language':
        this.filteredLang = this.implementationLangOV.filter(elem => elem.includes(this.currSnippet.language.trim()));
        break;
    }
  }
}
