<div class="row">
    <!-- <div class="col-6"></div> -->
    <div class="col-12 mt-1 pe-0 ps-0">
        <div class="input-group" [ngClass]="{'ps-3' : !stixService.guidedUI, 'display-flex w-100 mt-1' : stixService.guidedUI}" *ngIf="key === 'labels'">
            <div class="col-12 p-0" [ngClass]="{'display-flex':stixService.guidedUI}">
                <div [ngClass]="{'display-flex guided-div-border guided-button':stixService.guidedUI, 'float-start':!stixService.guidedUI}">
                    <p class="me-2 stix-title" style="margin-top: 4px;" [title]="tooltip">{{ currentStringValidator.displayName }}</p>
                    <input *ngIf="stixService.guidedUI" class="form-control" [ngClass]="{'guided-input-border' : stixService.guidedUI}" id="labels" style="height:31px" [(ngModel)]="newString" type="text">
                </div>
                <div class="d-flex input-group-append">
                    <input *ngIf="!stixService.guidedUI" class="form-control me-1" id="labels" style="height:31px" [(ngModel)]="newString" type="text">
                    <ng-container>
                        <button class="btn btn-fa-sm btn-primary" type="button" (click)="addString()" [disabled]="newString === ''">
                            <fa-icon [icon]="faPlus"></fa-icon>
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
        <div *ngIf="key !== 'labels'" class="d-flex align-items-center">
            <p class="stix-title" style="float:left" [title]="tooltip">{{ currentStringValidator.displayName }}</p>
            <span *ngIf="stixService.guidedUI">&nbsp;(TLP Color)</span>
            <!-- <input class="form-control ms-1" style="float:left" *ngIf="key == 'labels'"
                [(ngModel)]="newString" type="text"> -->
            <div class="ms-3">
                <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                    <button class="btn btn-fa-sm btn-primary" type="button" *ngIf="!isAddingElement"
                        (click)="openDialog()">
                        <fa-icon [icon]="faPlus"></fa-icon>
                        <!-- Add -->
                    </button>
                </ng-container>
                <button class="btn btn-fa-sm btn-outline-secondary fa-ban-sm" type="button" *ngIf="isAddingElement"
                    (click)="addOrCancel()">
                    <fa-icon [icon]="faBan"></fa-icon>
                    <!-- Cancel -->
                </button>
            </div>
        </div>   
    </div>
</div>
<!--Labels-->
<div class="form-group mt-2" *ngIf="stixService.stringArrays.get(key) && stixService.stringArrays.get(key)!.length > 0" id="questions">
    <ul>
        <li *ngFor="let currentString of stixService.stringArrays.get(key)" tabindex="0">
            {{ currentString }}
            <!-- *ngIf="!this.stixService.revoked && !this.stixService.revocation" -->
            <ng-container >
                <button type="button"
                    class="btn btn-fa-xsm btn-danger trash-can" 
                    (click)="deleteString(currentString, key)">
                    <fa-icon [icon]="faTrash" ></fa-icon>
                </button>
            </ng-container>
        </li>
    </ul>
</div>
<!-- Object Marking References -->
<div class="form-group mt-2" *ngIf="!isAddingElement && (stixService.objectMarkingReferences.length > 0 || stixService.objectMarkingReferencesTemp.length > 0) && key == 'object_marking_refs'" id="questions">
    <ul *ngIf="isObjectMarkingRefUpdated" class="bulletless">
        <li *ngFor="let currentString of stixService.objectMarkingReferencesTemp" tabindex="0">
            <span class="me-2 badge" [class] = "getDisplayStyle(getDisplayName(currentString))">{{ getDisplayName(currentString) }}</span>
            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                <button type="button"
                    class="btn btn-fa-xsm btn-danger trash-can" 
                    (click)="deleteOmr(currentString)">
                    <fa-icon [icon]="faTrash"></fa-icon>
                </button>
            </ng-container>
        </li>
    </ul>
    <ul *ngIf="!isObjectMarkingRefUpdated" class="bulletless">
        <li *ngFor="let currentString of stixService.objectMarkingReferences" tabindex="0">
            <span class="me-2 badge" [class] = "getDisplayStyle(getDisplayName(currentString))">{{ getDisplayName(currentString) }}</span>
            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                <button type="button"
                    class="btn btn-fa-xsm btn-danger trash-can" 
                    (click)="deleteOmr(currentString)">
                    <fa-icon [icon]="faTrash"></fa-icon>
                </button>
            </ng-container>
        </li>
    </ul>
</div>
<div class="form-group mt-2" *ngIf="isAddingElement && key === 'object_marking_refs'">
    <div class="input-group mb-2">
        <span class="input-group-text">
            <div class="tooltipright"><b>{{ currentStringValidator.displayName }}</b>
                <span class="toolttext">Common references in dropdown -<br>use the box on the right for custom
                    entries.</span>
            </div>
        </span>
        <select type="text" class="form-control form-select col-2" (change)="getTlpOptions($event)">
            <option value='' selected></option>
            <option value="v1">TLP v1.0</option>
            <option value="v2">TLP v2.0</option>            
        </select>
        <select type="text" class="form-control form-select col-2" [(ngModel)]="this.currentString" (change)="addStringElement()">
            <option value='' selected></option>
            <option *ngFor="let tlp of tlp_options" [value]="tlp.value">{{tlp.key}}</option>
            <!--ng-container *ngFor="let obj of stixService.bundle.objects">
                <option *ngIf="obj.id.includes('marking-definition--')" [value]="obj.id">
                    {{obj.name}}
                </option>
            </ng-container-->
        </select>
        <input class="form-control" placeholder="{{ currentStringValidator.placeholder }}"
            [(ngModel)]="this.currentString" [pattern]="currentStringValidator.regexValidation" type="text">
        <!--button class="btn btn-fa-sm btn-primary" type="button" (click)="addStringElement()"
            [disabled]="currentString == ''">
            <fa-icon [icon]="faPlus"></fa-icon>
        </button-->
    </div>
</div>
<!--<div class="form-group mt-2" *ngIf="isAddingElement">
    <div class="input-group mb-2">
        <span class="input-group-text">
            Quick Add - Current Bundle 
        </span>
        <select type="text" class="form-control" id="currentStringInput2" [formControl]="currentStringInput2">
            <option value=null  selected >None</option>
            <!--<option *ngFor="let obj of stixService.bundle.objects" [value]="obj.id">{{obj.id}}</option>--
            <ng-container *ngFor="let obj of stixService.bundle.objects">
                <option *ngIf="obj.id.includes('marking-definition--')" [value]="obj.id" >
                    {{obj.id}}
                </option>
            </ng-container>

        </select>
        <button class="btn btn-sm btn-success add-button" type="button" (click)="addStringElement2()" [disabled]="currentStringInput2.invalid || currentStringInput.value!=null && currentStringInput.value!=''">
            Add
        </button>
    </div>
</div>
<div class="form-group mt-2" *ngIf="isAddingElement">
    <div class="input-group mb-2">
        <span class="input-group-text">
            Quick Add - TLP
        </span>
        <select type="text" class="form-control" id="currentStringInput3" [formControl]="currentStringInput3">
            <option value='' selected></option>
            <option *ngFor="let tlp of tlp_options" [value]="tlp.value">{{tlp.key}}</option>
        </select>
        <button class="btn btn-sm btn-success add-button" type="button" (click)="addStringElement3()" [disabled]="currentStringInput2!=null && currentStringInput.value!=null && currentStringInput.value!=''">
            Add
        </button>
    </div>
</div>-->