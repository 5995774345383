<div class="body user-data">
    <ul class="nav nav-tabs">
      <li class="nav-item" (click)="setTab('submissions')">
        <a class="nav-link" [ngClass]="{'active': activeTab === 'submissions'}">Submissions</a>
      </li>
      <li class="nav-item" (click)="setTab('bundles')">
        <a class="nav-link active" [ngClass]="{'active': activeTab === 'bundles'}">Saved Bundles</a>
      </li>
      <li class="nav-item d-flex" (click)="setTab('alerts')">
        <a class="nav-link active d-flex" [ngClass]="{'active': activeTab === 'alerts'}">
            <span class="badge rounded-circle bg-primary notification-badge me-1" *ngIf="!stixService.loadingAlerts && stixService.unreadAlerts !== 0">{{stixService.unreadAlerts}}</span>
            Alerts
        </a>
      </li>
    </ul>

    <ng-container *ngIf="isLoading">
        <div class="row-name ps-3 pt-3">
            Loading...
            <span class="spinner-grow spinner-grow-sm" role="status"
                aria-hidden="true"></span>
        </div>
    </ng-container>

    <ng-container *ngIf="activeTab == 'submissions' && stixService.imxServerConnectionError != '' && !isLoading">
        <div class="row-name ps-3 pt-3 text-center">
            <span class="color-red">**{{stixService.imxServerConnectionError}}**</span>
        </div>
    </ng-container>

    <div *ngIf="activeTab == 'submissions' && stixService.imxServerConnectionError == ''" class="row w-100" style="margin-top: 20px; overflow: auto;">
        <div class="status-table w-100" style="margin-top: 10px; overflow: auto;">
            <div class="container-fluid ">
                <div>
                    <table *ngIf="statusTable.length != 0 && !isLoading" class="table mb-0 status-bottom-border">
                        <thead>
                            <tr style="height: 50px;">
                                <th scope="col">
                                    <span  style="cursor:pointer;" (click)="sort('user', 'status')">
                                        User ID
                                        <fa-icon *ngIf="sortKey === 'status-des'" [icon]="faAngleDown">
                                        </fa-icon>
                                        <fa-icon *ngIf="sortKey === 'status-asc'" [icon]="faAngleUp">
                                        </fa-icon>
                                    </span>
                                </th>
                                <th scope="col">
                                    <span  style="cursor:pointer;" (click)="sort('document', 'status')">
                                        STIX Doc Name
                                        <fa-icon *ngIf="sortKey === 'status-des'" [icon]="faAngleDown">
                                        </fa-icon>
                                        <fa-icon *ngIf="sortKey === 'status-asc'" [icon]="faAngleUp">
                                        </fa-icon>
                                    </span>
                                </th>
                                <th scope="col">
                                    <span  style="cursor:pointer;" (click)="sort('request_timestamp', 'status')">
                                        Submission
                                        <fa-icon *ngIf="sortKey === 'status-des'" [icon]="faAngleDown">
                                        </fa-icon>
                                        <fa-icon *ngIf="sortKey === 'status-asc'" [icon]="faAngleUp">
                                        </fa-icon>
                                    </span>
                                </th>
                                <th scope="col">
                                    <span  style="cursor:pointer;" (click)="sort('status', 'status')">
                                        Status
                                        <fa-icon *ngIf="sortKey === 'status-des'" [icon]="faAngleDown">
                                        </fa-icon>
                                        <fa-icon *ngIf="sortKey === 'status-asc'" [icon]="faAngleUp">
                                        </fa-icon>
                                    </span>
                                </th>
                                <th scope="col">
                                    <span  style="cursor:pointer;" (click)="sort('taxii', 'status')">
                                        TAXII Server
                                        <fa-icon *ngIf="sortKey === 'taxii-des'" [icon]="faAngleDown">
                                        </fa-icon>
                                        <fa-icon *ngIf="sortKey === 'taxii-asc'" [icon]="faAngleUp">
                                        </fa-icon>
                                    </span>
                                </th>
                                <th scope="col">
                                    <span style="cursor:pointer;" (click)="sort('root_collection', 'status')">
                                        Root/Collection
                                        <fa-icon *ngIf="sortKey === 'root_collection-des'" [icon]="faAngleDown">
                                        </fa-icon>
                                        <fa-icon *ngIf="sortKey === 'root_collection-asc'" [icon]="faAngleUp">
                                        </fa-icon>
                                    </span>
                                </th>
                                <th scope="col">
                                    <span style="cursor:pointer;" (click)="sort('update_timestamp', 'status')">
                                        Last Status Update
                                        <fa-icon *ngIf="sortKey === 'request_timestamp-des'" [icon]="faAngleDown">
                                        </fa-icon>
                                        <fa-icon *ngIf="sortKey === 'request_timestamp-asc'" [icon]="faAngleUp">
                                        </fa-icon>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let obj of statusTable; let i = index;">
                                <tr style="height: 90px;" [ngClass]="{ 'gray-bg': i % 2 === 0 }">
                                    <td style="cursor:pointer; color: #1d1e1e; font-weight: normal; width: 15%;"
                                        class="row-name" (click)="changeStatusShow(i)">
                                        {{obj.userID}}
                                    </td>
                                    <td style="cursor:pointer; color: #1d1e1e; font-weight: normal; width: 25%; word-break: break-word;"
                                        class="row-name" (click)="changeStatusShow(i)">
                                        {{obj.docName}}
                                    </td>
                                    <td style="cursor:pointer; color: #1d1e1e; font-weight: normal; width: 15%;"
                                        class="row-name" (click)="changeStatusShow(i)">
                                        {{ getDate(obj.res.request_timestamp) }}
                                    </td>
                                    <td style="cursor:pointer; color: #1d1e1e; font-weight: normal; width: 10%;"
                                        class="row-name" (click)="changeStatusShow(i)">
                                        <fa-icon *ngIf="obj.res.status == 'complete'" [icon]="faCheck" class="color-green"></fa-icon>
                                        <fa-icon *ngIf="obj.res.status != 'complete' && obj.res.status != 'pending'"[icon]="faStop" class="color-red"></fa-icon>
                                        <fa-icon *ngIf="obj.res.status == 'pending'"[icon]="faClock" class="color-yellow"></fa-icon>
                                        {{obj.res.status}}
                                    </td>
                                    <td style="cursor:pointer; color: #1d1e1e; font-weight: normal; width: 10%;"
                                        class="row-name" (click)="changeStatusShow(i)">
                                        <fa-icon *ngIf="getTaxiiServer(obj.taxiiServer) != 'Default' && getTaxiiServer(obj.taxiiServer) != 'AIS'"[icon]="faStop" class="color-red"></fa-icon>
                                        {{ getTaxiiServer(obj.taxiiServer) }}
                                    </td>
                                    <td style="cursor:pointer; color: #1d1e1e; font-weight: normal; width: 10%;"
                                        class="row-name" (click)="changeStatusShow(i)">
                                        {{ obj.root_collection }}
                                    </td>
                                    <td style="cursor:pointer; color: #1d1e1e; font-weight: normal; width: 15%;"
                                        class="row-name" (click)="changeStatusShow(i)">
                                        {{ getDate(obj['@updated_timestamp'], obj) }}
                                    </td>
                                </tr>
                                <tr class="expanded-panel" [ngClass]="{ 'gray-bg': i % 2 === 0 }" *ngIf="statusRowNumber == i">
                                    <td colspan="12" class="ps-4 pe-4 pb-3 preview" >
                                        <pre class="preview-json-window p-3"  >{{ statusQueryResults | json }}</pre>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <div class="footer" *ngIf="totalStatusPages !== 0 && !isLoading" [ngClass]="{'footer-no-bottom': totalStatusPages == 1}">
                        <div *ngIf="totalStatusPages !== 1 && !isLoading"  class="span-footer float-start">
                            <span >Page: {{currentStatusPage}}/{{totalStatusPages}}</span>
                        </div>
                        <div class="buttons-footer">
                            <button *ngIf="currentStatusPage > 1 && !isLoading" class="btn btn-fa-sm btn-outline-primary ms-1 btn-status" (click)="nextPage('left')">
                                <fa-icon [icon]="faArrowLeft"></fa-icon>
                            </button>
                            <button *ngIf="currentStatusPage < totalStatusPages && !isLoading" class="btn btn-fa-sm btn-outline-primary ms-1 btn-status" (click)="nextPage('right')">
                                <fa-icon [icon]="faArrowRight"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isLoading && statusTable.length == 0">
                    <span style="font-weight: bold; text-align: left;">No Status IDs saved for user</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="activeTab == 'bundles'" class="row w-100" style="margin-top: 20px; overflow: auto;">
        <app-saved-bundles-table *ngIf="!isLoading" class="w-100" [mode]="'basic'"></app-saved-bundles-table>
    </div>

    <div *ngIf="activeTab == 'alerts'" class="row w-100" style="margin-top: 20px; overflow: auto;">
        <app-alert-tab *ngIf="!isLoading" class="w-100"></app-alert-tab>
    </div>
</div>