<div class="viewer-wrapper mb-2">
  <!-- <div id="graph-element"
    data-stix-allow-dragdrop="false"
    data-show-idrefs="true"
    data-show-markings="true"
    data-show-sidebar="true"
    data-graph-layout="cise"
    data-caption="STIX 2.1 Viewer"
    data-disable-mouse-zoom="false"
    data-show-footer="true"
    data-graph-width="98%"
    data-graph-height="80vh">
  </div> -->
    <div id="canvas-container">
      <div id="canvas-wrapper">
        <div id="canvas"></div>
      </div>
      <div id="canvas-info d-flex flex-column" style="width: 400px; max-width: 400px; min-width: 400px; font-size: 14px; font-family: system-ui; word-wrap: break-word">

        <div style="position: relative">
          <ul class="nav nav-tabs">
            <li class="nav-item" (click)="setTab('selected-node')">
              <a class="nav-link" [ngClass]="{'active': activeTab === 'selected-node'}">Selected Node</a>
            </li>
            <li class="nav-item" (click)="setTab('linked-nodes')">
              <a class="nav-link active" [ngClass]="{'active': activeTab === 'linked-nodes'}">Linked Nodes</a>
            </li>
            <li class="nav-item" (click)="setTab('legend')">
              <a class="nav-link active" [ngClass]="{'active': activeTab === 'legend'}">Legend</a>
            </li>
          </ul>
<!-- 
          <button type="button"
              (click)="showMenuBar = !showMenuBar"
              class="btn btn-fa-sm btn-outline-primary fa-double visualizer-menu-button"
              [class]="{'rotate': !showMenuBar, 'rotate-back': showMenuBar}">
              <fa-icon [icon]="faAngleDoubleUp"></fa-icon>
          </button> -->
        </div>

        <div [class]="showMenuBar ? 'visualizer-menu-content' : 'not-show'">
          <div id="selected" class="sidebar" [class]="{'not-show': activeTab === 'linked-nodes' || activeTab === 'legend'}">
            <div id="selection"></div>
          </div>
          <div id="connections" class="sidebar" [class]="{'not-show': activeTab === 'selected-node' || activeTab === 'legend'}">
            <h5 *ngIf="selectedNode">Incoming Edges:</h5>
            <div id="connections-incoming"></div>
            <h5 *ngIf="selectedNode">Outgoing Edges:</h5>
            <div id="connections-outgoing"></div>
          </div>
          <div id="legend" class="sidebar" [class]="{'not-show': activeTab === 'linked-nodes' || activeTab === 'selected-node'}">
            <table id="legend-content">
            </table>
          </div>
        </div>
      </div>
  </div>
</div>