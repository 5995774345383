import { Component, OnInit, EventEmitter, ViewChild, Output } from '@angular/core';
import { faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidV4 } from "uuid";
import { GuidedService } from 'src/app/guided.service';
import { StixService } from 'src/app/stix-service.service';
import { GuidedCartComponent } from '../guided-cart/guided-cart.component';

@Component({
  selector: 'app-how-event-detection',
  templateUrl: './how-event-detection.component.html',
  styleUrls: ['./how-event-detection.component.css']
})
export class HowEventDetectionComponent implements OnInit {
  @ViewChild("guidedCart", { static: false }) guidedCart: GuidedCartComponent;

  //With Cart
  @Output() disableCartEmitter = new EventEmitter<boolean>();
  @Output() newItemEmitter = new EventEmitter<any>();
  @Output() editModeEmitter = new EventEmitter<boolean>();
  @Output() syncCartEmitter = new EventEmitter<any>();
  @Output() getEditItemEmitter = new EventEmitter<any>();

  component: string = 'how-event-detection';

  //With Parent
  @Output() updateContinue = new EventEmitter<boolean>();
  @Output() showAssistance = new EventEmitter<any>();
  @Output() changePage = new EventEmitter<any>();

  faQuestionCircle = faQuestionCircle;
  faInfoCircle = faInfoCircle;
  incidentName = '';
  determination = '';
  investigationStatus = '';
  detectionMethods = [];
  data = {};

  description = '';
  descriptionName = '';

  cartIds: number[] = [];

  IncidentId = '';
  
  determinationOptions: any[] = [
    { key: 'blocked', value: 'Blocked' },
    { key: 'confirmed', value: 'Confirmed' },
    { key: 'failed-attempt', value: 'Failed Attempt' },
    { key: 'false-positive', value: 'False Positive' },
    { key: 'suspected', value: 'Suspected' }
  ]

  investigationStatusOptions: any[] = [
    { key: 'closed', value: 'Closed' },
    { key: 'new', value: 'New' },
    { key: 'open', value: 'Open' }
  ]
  
  detectionOptions: any[] = [
    { key: 'automated-tool', value: 'Automated Tool' },
    { key: 'commercial-solution', value: 'Commercial Solution' },
    { key: 'external-notification', value: 'External Notification' },
    { key: 'human-review', value: 'Human Review' },
    { key: 'message-from-attacker', value: 'Message From Attacker' },
    { key: 'propriety-solution', value: 'Propriety Solution' },
    { key: 'system-outage', value: 'System Outage' },
    { key: 'user-reporting', value: 'User Reporting' }
  ]

  constructor(
    public guidedService: GuidedService,
    public stixService: StixService,
  ) {

  }
  // detectionOptions: string[] = ['Automated Tool', 'Commercial Solution', 'External Notification', 'Human Review', 'Message From Attacker', 'Proprietary Solution', 'System Outage', 'User Reporting'];

  ngOnInit(): void {
    this.setIncident();

    const editCartTimeout = setTimeout( () => {
      if(this.guidedService.editCartObject.component && this.guidedService.editCartObject.component === this.component){
        if(this.guidedService.editCartObject.specialFlag === false){
          this.getEditItemEmitter.emit(this.guidedService.editCartObject);
        }
      }
    }, 100);
  }

  setIncident(){
    if(this.guidedService.cart['Incident']){
      this.incidentName = this.guidedService.cart['Incident']['Incident'][0].name;
      this.determination = this.guidedService.cart['Incident']['Incident'][0]["extensions"][this.guidedService.incidentCoreExtentionIncidentId]["determination"];
      this.investigationStatus = this.guidedService.cart['Incident']['Incident'][0]["extensions"][this.guidedService.incidentCoreExtentionIncidentId]["investigation_status"];
      this.detectionMethods = this.guidedService.cart['Incident']['Incident'][0]["extensions"][this.guidedService.incidentCoreExtentionIncidentId]["detection_methods"] ? this.guidedService.cart['Incident'][0]["extensions"][this.guidedService.incidentCoreExtentionIncidentId]["detection_methods"] : [];

      this.IncidentId = this.guidedService.cart['Incident']['Incident'][0].id;
    }
  }

  isFormValid() {
    if (this.determination === '' || this.investigationStatus === '') {
      this.updateContinue.emit(true);
    } else {
      this.updateContinue.emit(false);
    }
  }
  
  async addComponents(){
    if(this.determination === '' || this.investigationStatus === ''){
      //@ts-ignore
      return;
    }

    let today = new Date()
    let tempIncident = {
      type: 'incident',
      name: this.incidentName ? this.incidentName : `cyber-threat-detection-generated-${today.toISOString()}`,
      "extensions": {
        [this.guidedService.incidentCoreExtentionIncidentId]: {
            "extension_type": "property-extension",
            "determination": this.determination,
            "investigation_status": this.investigationStatus,
        }
      },
      cartId: -1
    }

    tempIncident['display_name'] = tempIncident.name;

    if (this.detectionMethods && this.detectionMethods.length > 0) {
      tempIncident["extensions"][this.guidedService.incidentCoreExtentionIncidentId]["detection_methods"] = this.detectionMethods;
    }

    tempIncident['id'] = this.IncidentId !== '' ? this.IncidentId : this.getStixId(tempIncident, 'Incident')

    let tempCartItem = {
      'Incident': [ tempIncident ]
    }

    await this.guidedService.syncCart('Incident', tempCartItem);
    return new Promise((resolve, reject) => {
      resolve(true);
    })

    // let types = [];
    // let data = [];



    // if (!!this.determination || !!this.investigationStatus) {
    //   // Create Custom object type of Incident
    //   types.push('incident');

    //   let dataTmp = {
    //     name: this.incidentName,
    //     // description: this.description, // description covered by event details
    //     "extensions": {
    //       [this.guidedService.incidentCoreExtentionIncidentId]: {
    //           "extension_type": "property-extension",
    //           "determination": this.determination,
    //           "investigation_status": this.investigationStatus,
    //       }
    //     }
    //   };

    //   if (this.detectionMethods && this.detectionMethods.length > 0) {
    //     dataTmp["extensions"][this.guidedService.incidentCoreExtentionIncidentId]["detection_methods"] = this.detectionMethods;
    //   }

    //   data.push(dataTmp);

    //   this.guidedService.addComponents(types, data);

      // if (this.guidedService.autoGenRelationships) {
      //   // Identity --- Incident
      //   setTimeout(() => {
      //     this.guidedService.findPreviouslyAddedObjects('identity', 'identity-event-detection');
      //     this.guidedService.addedObjects.subscribe(addedObjects => {
      //       if (addedObjects.eventType === 'identity-event-detection'
      //         && addedObjects.objects
      //         && addedObjects.objects.length > 0) {

      //           if (addedObjects.objects.length === 1 && addedObjects.objects[0].name === 'CISA Dev') {
      //             return;
      //           }

      //           this.guidedService.findPreviouslyAddedObjects('incident', 'incident-event-detection');
      //           this.guidedService.addedObjects.subscribe(addedObjects => {
      //             if (addedObjects.eventType === 'incident-event-detection'
      //               && addedObjects.objects
      //               && addedObjects.objects.length > 0) {
      //               this.guidedService.createRelationships(
      //                 'targets',
      //                 addedObjects.objects[0].id,
      //                 'identity',
      //               )

      //               this.guidedService.createRelationships(
      //                 'contact-for',
      //                 'identity',
      //                 addedObjects.objects[0].id,
      //               )
      //             }
      //           })
      //       }
      //     })
      //   }, 300)
      // }
      
    // }
  }

  getStixId(object, typeName){
    if(this.guidedService.editCart && this.guidedService.editCart['Incident'] && this.guidedService.editCart['Incident'][typeName]){
      for(let oldObject of this.guidedService.editCart['Incident'][typeName]){
        if(oldObject.cartId === object.cartId){
          return oldObject.id;
        }
      }
    }

    return `${object.type}--${this.stixService.getUUIDFrIdContributingProperties(object)}`;
  }

  addCartItem(cancel){
    if(cancel){
      this.reset();
      this.checkCart();
      return;
    }

    let today = new Date();

    let eventObject = {
      type: 'event',
      name: this.descriptionName !== '' ? this.descriptionName : `event-generated-${today.toISOString()}`,
      description: this.description
    }

    eventObject['cartId'] = this.cartIds[0] ? this.cartIds[0] : null;

    let emitObj = {
      mode: this.component,
      push: [
        eventObject
      ]
    }

    this.newItemEmitter.emit(emitObj);
    this.disableCartEmitter.emit(true);
    this.reset();
  }

  editCartItem(event){
    this.description = event.description;
    this.descriptionName = event.name;
    this.cartIds = [ event.cartId ];
  }

  checkCart(){
    if(this.description === ''){
      this.disableCartEmitter.emit(true);
      return;
    }

    this.disableCartEmitter.emit(false);
  }

  reset(){
    this.description = '';
    this.descriptionName = '';

    this.cartIds = [];
  }

  redirectCart(event){
    if(event.component !== this.component){
      this.changePage.emit(event);
    } else {
      this.getEditItemEmitter.emit(event);
    }
  }

  syncCart(event){
    if(event === this.component){
      this.syncCartEmitter.emit();
    }
  }

  showAssistanceTrigger(){
    this.showAssistance.emit();
  }
}