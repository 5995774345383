export class DetectionRuleObject {
    //<!--rule_type/rule_name/rule/url/dtect_ref/description/api_fncs-->

    rule_type: string;
    rule_name: string;
    rule: string;
    url: string;
    detect_ref: string;
    description: string;
    api_fncs: string[];

    constructor(rule_type: string, rule_name?: string, rule?: string, url?: string, detect_ref?: string, description?: string, api_fncs?: string[]){
        this.rule_type = rule_type || '';
        this.rule_name = rule_name || '';
        this.rule = rule || '';
        this.url = url || '';
        this.detect_ref = detect_ref || '';
        this.description = description || '';
        this.api_fncs = api_fncs || [];
    }

    /* getGranularMarkingSelectors(): string[] {
        let selectors = [];
        this.contains_refs ? selectors.push('contains_refs') : null;
        this.comment ? selectors.push('comment') : null;
        return selectors;
    } */
}