export const EVENT_TYPE_OV = [
  "aggregation-information-phishing-schemes",
  "benign",
  "blocked",
  "brute-force-attempt",
  "c&c-server-hosting",
  "compromised-system",
  "confirmed",
  "connection-malware-port",
  "connection-malware-system",
  "content-forbidden-by-law",
  "control-system-bypass",
  "copyrighted-content",
  "data-exfiltration",
  "deferred",
  "deletion-information",
  "denial-of-service",
  "destruction",
  "dictionary-attack-attempt",
  "discarded",
  "disruption-data-transmission",
  "dissemination-malware-email",
  "dissemination-phishing-emails",
  "dns-cache-poisoning",
  "dns-local-resolver-hijacking",
  "dns-spoofing-registered",
  "dns-rebinding",
  "dns-server-compromise",
  "dns-spoofing-unregistered",
  "dns-stub-resolver-hijacking",
  "dns-zone-transfer",
  "domain-name-compromise",
  "duplicate",
  "email-flooding",
  "equipment-loss",
  "equipment-theft",
  "exploit",
  "exploit-attempt",
  "exploit-framework-exhausting-resources",
  "exploit-tool-exhausting-resources",
  "failed",
  "file-inclusion",
  "file-inclusion-attempt",
  "hosting-malware-webpage",
  "hosting-phishing-sites",
  "illegitimate-use-name",
  "illegitimate-use-resources",
  "infected-by-known-malware",
  "insufficient-data",
  "known-malware",
  "lame-delegations",
  "major",
  "modification-information",
  "misconfiguration",
  "natural",
  "negotiation",
  "network-scanning",
  "no-apt",
  "packet-flood",
  "password-cracking-attempt",
  "policy-violation",
  "ransomware",
  "ransomware-payment",
  "refuted",
  "scan-probe",
  "silently-discarded",
  "supply-chain-customer",
  "supply-chain-vendor",
  "spam",
  "sql-injection",
  "sql-injection-attempt",
  "successful",
  "system-probe",
  "theft-access-credentials",
  "unattributed",
  "unauthorized-access-information",
  "unauthorized-access-system",
  "unauthorized-equipment",
  "unauthorized-release",
  "unauthorized-use",
  "undetermined",
  "unintentional",
  "unknown-apt",
  "unspecified",
  "vandalism",
  "wiretapping",
  "worm-spreading",
  "xss",
  "xss-attempt",
]