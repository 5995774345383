<div class="dialog">
    <div class="modal-header justify-content-between">
        <h4 class="modal-title" id="publish-modal-title">
            <span *ngIf="!isParsed">Raw Data Import</span>
            <span *ngIf="isParsed">IOC's Identified</span>
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="onX()">
            <span aria-hidden="true"></span>
        </button>
    </div>
    <div class="modal-body" style="max-height: 55vh;">
        <ul *ngIf="!isParsed" class="nav nav-tabs">
            <li class="nav-item" (click)="setTab('copy-paste')">
                <a class="nav-link" [ngClass]="{'active': tab === 'copy-paste'}">Copy/Paste Text</a>
            </li>
            <li class="nav-item" (click)="setTab('upload-file')">
                <a class="nav-link active" [ngClass]="{'active': tab === 'upload-file'}">Upload File</a>
            </li>
        </ul>
        <div *ngIf="!isParsed && tab === 'copy-paste'" class="form-group ms-2 mt-2">
            <label class="dialogLabel">Text to be Parsed:</label>
            <textarea class="form-control" rows="15" wrap="soft" [(ngModel)]="textBody" (input)="textBodyChange()"></textarea>
        </div>
        <div *ngIf="!isParsed && tab === 'upload-file'" class="form-group ms-2 mt-2">
            <div class="row ms-0">
                <label class="dialogLabel">File to be Parsed:</label>
            </div>
            <div class="mt-2">
                <h6>Select File to Upload Below</h6>
                <div class="row">
                    <div class="col-12">
                        <!-- style="overflow:hidden; max-width: 300px;" -->
                        <input type="file" [(ngModel)]="selectedFile" class="bottom-bump w-100" accept="{{acceptFileString}}" id="file1"
                            name="parseUpload" (change)="onFileChanged($event)">
                    </div>
                </div>
            </div>
            
        </div>
        <div *ngIf="isParsed && !isConfirm">
            <div class="">
                <span>Select All:</span>
                <br>
                <input type="checkbox" [ngModel]="selectAll" (change)="updateSelectAll()">
            </div>
            <div *ngFor="let map of objects2DArray">
                <div *ngIf="parsedObjects[map[0]]">
                    <table>
                        <thead>
                            <th colspan="2">
                                {{map[1]}}
                            </th>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let object of parsedObjects[map[0]]" class="w-100">
                                <tr>
                                    <td style="font-weight: normal; width: 2%; margin-top: 5px !important;">
                                        <input type="checkbox"
                                            class="select-item checkbox mt-1"
                                            [ngModel]="getSelected(object.id)"
                                            (change)="updateSelectedObjects(object.id)">
                                    </td>
                                    <td class="btn-link overflow ps-1" style="width: 98%; cursor: pointer" (click)="changeShow(object.id)">
                                        {{getComponentDisplay(object)}}
                                    </td>
                                </tr>
                                <tr class="expanded-panel" *ngIf="showId == object.id">
                                    <td colspan="2">
                                        <div class="row ms-1">
                                            <pre>{{ object | json }}</pre>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="isConfirm" class="pt-2">
            Temp Bundle:
            <br>
            <ul class="nav nav-tabs">
                <li class="nav-item" (click)="activeTab = 'jsonViewer'">
                    <a class="nav-link active" [ngClass]="{'active': activeTab === 'jsonViewer'}">JSON</a>
                </li>
                <li class="nav-item" (click)="activeTab = 'visualViewer'">
                    <a class="nav-link" [ngClass]="{'active': activeTab === 'visualViewer'}">Visual</a>
                </li>
            </ul>
            <pre *ngIf="activeTab === 'jsonViewer'" class="preview-bundle">{{ getStixPreview() | json }}</pre>
            <app-stix-viewer *ngIf="activeTab === 'visualViewer'" class="nav-link" [bundle]="getStixPreview()">
            </app-stix-viewer>
        </div>
    </div>
    <div class="modal-footer">
<!--         <h6>Select File(s) to Upload Below</h6>
        <div class="col-12">
            <input type="file" [(ngModel)]="selectedFile" class="bottom-bump w-100" id="file"
                    name="upload" (change)="fileChanged($event)" multiple>
        </div>
        <button *ngIf="!isUploading" type="button" class="btn query-button btn-outline-primary"
            (click)="uploadDocument();" [disabled]="this.selectedFile == undefined">
            <fa-icon [icon]="faFileUpload" class="pe-1"></fa-icon>
            Upload
        </button>
        <button *ngIf="isUploading" type="button" class="btn query-button btn-outline-primary"
            [disabled]="true">
            <span class="spinner-grow spinner-grow-sm me-1" role="status" aria-hidden="true"></span>Uploading...
        </button> -->

        <button type="button" class="btn btn-sm btn-secondary" (click)="onX()">
            <fa-icon [icon]="faBan" class="pe-1"></fa-icon>
            Cancel
        </button>
        <button *ngIf="isParsed" type="button" class="btn btn-sm btn-secondary ms-2" (click)="onBack()">
            <fa-icon [icon]="faArrowLeft" class="pe-1"></fa-icon>
            Back
        </button>
        <button type="button" *ngIf="!isParsed" class="btn btn-sm btn-success ms-2" [disabled]="disableParse === true" (click)="onParse()">
            <fa-icon [icon]="faPlay" class="pe-1"></fa-icon>
            Parse
            <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        </button>
        <button *ngIf="isParsed && !isConfirm" type="button" class="btn btn-sm btn-success ms-2" (click)="onConfirm()">
            <fa-icon [icon]="faCheck" class="pe-1"></fa-icon>
            Confirm
        </button>
        <button *ngIf="isConfirm" type="button" class="btn btn-sm btn-success ms-2" (click)="onAddToBundle()">
            <fa-icon [icon]="faFileImport" class="pe-1"></fa-icon>
            Add to Bundle
        </button>
    </div>
</div>
