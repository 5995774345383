<div class="row mb-2">

    <!-- TYPE -->
    <div class="col-3">
        <select
            [(ngModel)]="type"
            (ngModelChange)="updateOptions($event)"
            class="form-control input-sm py-0" 
            id="version" 
            name="type" 
            title="Match Type"
        >
            <option *ngFor="let type of types" 
                [value]="type">{{type}}</option>
        </select>
    </div>

    <!-- ATTRIBUTES -->
    <div class="col-3" *ngIf="type !== 'relationships' && type !== 'created by'">
        <select
            class="form-control form-select input-sm py-0 form-select"
            id="version"
            name="attribute"
            [disabled]="!type"
            title="Match Attribute"
            [(ngModel)]="attribute"
            (change)="updateOptions($event)"
        >
            <option value=""></option>
            <option *ngFor="let attribute of attributes" 
                [value]="attribute">{{attribute}}</option>
        </select>
    </div>

    <!-- SIGN -->
    <div class="col-1"
        *ngIf="type === 'calculations'">
        <select 
            class="form-control  form-select input-sm py-0"
            id="version"
            name="sign"
            [disabled]="!type"
            title="Match Sign"
            [(ngModel)]="sign"
            *ngIf="attribute !== 'valid_until'
                && attribute !== 'valid_from'"
        >
            <option *ngFor="let sign of signs" 
                [value]="sign">{{sign}}</option>
        </select>

        <select 
            class="form-control form-select input-sm py-0"
            id="version"
            name="sign"
            [disabled]="!type"
            title="Match Sign"
            [(ngModel)]="sign"
            *ngIf="attribute === 'valid_until'
                || attribute === 'valid_from'"
        >
            <option [value]="sign">{{sign}}</option>
        </select>
    </div>
    <div class="col-1" 
        *ngIf="type === 'created by'
            || type === 'relationships'
            || type === 'top-level property'
            || type === 'top-level property of type list'
            || type === 'property within nested structures'
            || type === ''"
    >
        <select 
            class="form-control form-select input-sm py-0"
            id="version"
            name="sign"
            [disabled]="!type"
            title="Match Sign"
            [(ngModel)]="sign"
        >
            <option [value]="sign">{{sign}}</option>
        </select>
    </div>

    <!-- VALUE -->
    <div class="col">
        <ng-template
            [ngIf]="type === 'calculations'
                && (attribute === 'modified'
                || attribute === 'valid_from'
                || attribute === 'valid_until')"
            [ngIfElse]="elseBlock">
            <input
                *ngIf="type === 'calculations'
                    && (attribute === 'modified'
                    || attribute === 'valid_from'
                    || attribute === 'valid_until'
                    || attribute === '')"
                class="form-control input-sm py-0"
                [(ngModel)]="value"
                [owlDateTimeTrigger]="dtPicker2"
                [owlDateTime]="dtPicker2"
                [disabled]="!type"
            >
            <owl-date-time #dtPicker2></owl-date-time>
        </ng-template>
        <ng-template #elseBlock><ng-template [ngIf]="type === 'created by'" [ngIfElse]="elseBlock2">
            <div class="input-group">
                    <select class="form-control form-select input-sm py-0 col-5"
                        [(ngModel)]="value">
                        <option value="" selected></option>
                        <option *ngFor="let id of identityList" [value]="id.id">{{id.name}}&nbsp;</option>                            
                    </select>
                    <input type="text" class="form-control input-sm py-0" style="border-radius:0;" [(ngModel)]="value">
                </div>
            </ng-template>
            <ng-template #elseBlock2>
                <input
                    type="text"
                    class="form-control input-sm py-0"
                    [(ngModel)]="value"
                    [disabled]="!type"
                    title="Match Value"
                >
            </ng-template>
        </ng-template>           
    </div>
    <div class="col-1">
        <button (click)="remove_me()" class="btn btn-fa-sm btn-danger" title="Click to remove this filter.">
            <fa-icon [icon]="faTrash"></fa-icon>
        </button>
    </div>
</div>